import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableIcon } from 'components/Icon/Icon';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './EmptyTable.module.scss';

const T_PREFIX = 'list';

const EmptyTable = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.TABLE);

    return (
        <div className={styles.emptyTableContainer}>
            <EmptyTableIcon width={188} height={188} />
            <p className={styles.text}>{t(`${T_PREFIX}.emptyTable`)}</p>
        </div>
    );
};

EmptyTable.propTypes = {
};

EmptyTable.defaultProps = {
};

export default EmptyTable;
