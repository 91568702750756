import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SellBundleModal from 'components/bundles/SellBundleModal';
import { Table } from 'components/Common/NewTable/Table';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { useDialog } from 'hooks/useDialog';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { SentIcon } from 'components/Icon/Icon';
import { useTableSelection } from 'hooks/useTableSelection';
import { MESSAGES_ADD_PAGE } from 'const/CLIENT_URL';
import { useHistory } from 'react-router';
import { useConfirmDialog } from '../../../../../../hooks/useConfirmDialog';

import { SoldBundlesHeader } from '../../../../../../components/bundles/SoldBundlesHeader';
import { SoldBundlesTableRow } from '../../../../../../components/bundles/SoldBundlesTableRow';
import { SoldBundlesTableColumns } from '../../../../../../components/bundles/SoldBundlesTableColumns';
import { SoldBundlesFooter } from '../../../../../../components/bundles/SoldBundlesFooter';
import { ConfirmationModal } from '../../../../../../components';
import { SoldBundleEditFormDialog } from '../../../../../../components/bundles/SoldBundleEditFormDialog';

import * as BUNDLES_ACTIONS from '../../../../../../store/actions/bundles';

import * as BUNDLES_SELECTORS from '../../../../../../store/selectors/bundles';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const ITEMS_PER_PAGE = 10;

const COLUMNS_COUNT = 12;

const T_PREFIX = 'sold';
const T_DIALOGS = `${T_PREFIX}.dialogs`;

const SoldBundles = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const history = useHistory();

    const dispatch = useDispatch();
    const {
        page,
        items: soldBundles,
        search,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        hideArchived,
    } = useSelector(BUNDLES_SELECTORS.soldBundlesSelector);

    const editDialog = useConfirmDialog({
        defaultState: false,
    });

    const archiveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: useCallback((soldBundleId) => {
            dispatch(BUNDLES_ACTIONS.archiveSoldBundlesItem({ soldBundleId }));
        }, [dispatch]),
    });

    const sellBundleModal = useDialog(false);

    const handleEdit = useCallback(({ soldBundle }) => {
        editDialog.onSetData(soldBundle);
        editDialog.onShow();
    }, [editDialog.onSetData, editDialog.onShow]);

    const handleArchive = useCallback(({ soldBundleId }) => {
        archiveConfirmDialog.onSetData(soldBundleId);
        archiveConfirmDialog.onShow();
    }, [archiveConfirmDialog.onSetData, archiveConfirmDialog.onShow]);

    const handleShowAll = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPage({ page: 0 }));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPageFirst());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPage({ page: nextPage }));
    }, [dispatch]);

    const handleSearch = useCallback(({ search: nextSearch }) => {
        dispatch(BUNDLES_ACTIONS.setSoldBundlesSearch({ search: nextSearch }));
    }, [dispatch]);

    const soldBundlesList = useMemo(() => (
        (!showAll && soldBundles.length > ITEMS_PER_PAGE)
            ? soldBundles.slice(0, Math.min(ITEMS_PER_PAGE, soldBundles.length))
            : soldBundles
    ), [showAll, soldBundles]);

    const handleToggleHideArchived = useCallback(() => {
        dispatch(BUNDLES_ACTIONS.toggleSoldBundlesHideArchived());
    }, [dispatch]);

    useEffect(() => {
        dispatch(BUNDLES_ACTIONS.getSoldBundles());
    }, [
        dispatch,
        page,
        search,
        hideArchived,
    ]);

    const tableSelection = useTableSelection({
        itemsOnPageRecordWithId: soldBundlesList,
        onGetTotalItemsWithId: async () => dispatch(BUNDLES_ACTIONS.getSoldBundlesOptions()),
        totalCount: itemsCount,
    });

    const selectedBundles = tableSelection.selected;

    useEffect(() => {
        tableSelection.deselectAll();
        tableSelection.resetTotalItemsWithId();
    }, [search, hideArchived]);

    const handleSendMessage = useCallback(async () => {
        const clientsIds = selectedBundles.map(({ client }) => Number(client.id));
        const selectedClientsIds = Array.from(new Set(clientsIds));
        history.push(MESSAGES_ADD_PAGE, { selectedClientsIds });
    }, [selectedBundles, history]);

    const paidSelected = useMemo(() => selectedBundles.filter(({ paid }) => paid), [selectedBundles]);
    const unpaidSelected = useMemo(() => selectedBundles.filter(({ paid }) => !paid), [selectedBundles]);

    return (
        <React.Fragment>
            <SoldBundlesHeader
                search={search}
                hideArchived={hideArchived}
                onSearch={handleSearch}
                onToggleHideArchived={handleToggleHideArchived}
                onToggleSellBundleModal={sellBundleModal.onShow}
            />
            <Container>
                <Content loading={loading}>
                    <Table
                        multiselectPanel={{
                            selectedCount: tableSelection.selectedCount,
                            totalCount: tableSelection.totalCount,
                            onSelectAll: tableSelection.selectAll,
                            onDeselectAll: tableSelection.deselectAll,
                            loading: tableSelection.loading,
                            actions: [{
                                label: tc('placeholders.sendMessage'),
                                onClick: handleSendMessage,
                                icon: <SentIcon />,
                                loading: tableSelection.loading,
                            }],
                            info: selectedBundles.length ? [
                                { label: t('sold.table.header.statuses.paid'), count: paidSelected.length },
                                { label: t('sold.table.header.statuses.unpaid'), count: unpaidSelected.length },
                            ] : [],
                        }}
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader selected={tableSelection.currentPageAllSelected} onSelect={tableSelection.toggleAllOnCurrentPage}>
                            <SoldBundlesTableColumns />
                        </TableHeader>
                        <TableBody>
                            {soldBundlesList.map((soldBundle) => (
                                <SoldBundlesTableRow
                                    key={soldBundle?.id}
                                    soldBundle={soldBundle}
                                    onEdit={handleEdit}
                                    onArchive={handleArchive}
                                    selected={tableSelection.isSelected(soldBundle)}
                                    onSelect={() => tableSelection.toggle(soldBundle)}

                                />
                            ))}
                        </TableBody>
                    </Table>
                    <SoldBundlesFooter
                        search={search}
                        onAdd={sellBundleModal.onShow}
                        onSearch={handleSearch}
                    />
                </Content>
                <ConfirmationModal
                    isShow={archiveConfirmDialog.visible}
                    titleText={t(`${T_DIALOGS}.archive.title`)}
                    bodyText={t(`${T_DIALOGS}.archive.text`)}
                    deleteText={t(`${T_DIALOGS}.archive.actions.confirm`)}
                    dismissText={t(`${T_DIALOGS}.archive.actions.reject`)}
                    confirmAction={archiveConfirmDialog.onConfirm}
                    hide={archiveConfirmDialog.onReject}
                />
                <SoldBundleEditFormDialog
                    visible={editDialog.visible}
                    soldBundle={editDialog.data}
                    onClose={editDialog.onClose}
                />
                {sellBundleModal.visible && (
                    <SellBundleModal onClose={sellBundleModal.onClose} />
                )}
            </Container>
        </React.Fragment>
    );
};

export default SoldBundles;
