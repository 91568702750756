const ACTIVITY_LIST = 'ACTIVITY_LIST';
export const ACTIVITY_LIST_CLEAR = `${ACTIVITY_LIST}:CLEAR`;

export const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
export const GET_ACTIVITY_LIST_TRIGGER = `${GET_ACTIVITY_LIST}.TRIGGER`;
export const GET_ACTIVITY_LIST_RESOLVED = `${GET_ACTIVITY_LIST}.RESOLVED`;
export const GET_ACTIVITY_LIST_REJECTED = `${GET_ACTIVITY_LIST}.REJECTED`;

const ACTIVITY_LIST_PAGE = `${ACTIVITY_LIST}:PAGE`;
export const ACTIVITY_LIST_PAGE_SET = `${ACTIVITY_LIST_PAGE}:SET`;
export const ACTIVITY_LIST_PAGE_SET_FIRST = `${ACTIVITY_LIST_PAGE_SET}:FIRST`;
export const ACTIVITY_LIST_PAGE_SET_LAST = `${ACTIVITY_LIST_PAGE_SET}:LAST`;
export const ACTIVITY_LIST_PAGE_SET_PREV = `${ACTIVITY_LIST_PAGE_SET}:PREV`;
export const ACTIVITY_LIST_PAGE_SET_NEXT = `${ACTIVITY_LIST_PAGE_SET}:NEXT`;
export const ACTIVITY_LIST_SHOW_ALL = `${ACTIVITY_LIST}:SHOW_ALL`;
const ACTIVITY_LIST_SEARCH = `${ACTIVITY_LIST}:SEARCH`;
export const ACTIVITY_LIST_SEARCH_SET = `${ACTIVITY_LIST_SEARCH}:SET`;
const ACTIVITY_LIST_SORT_OPTIONS = `${ACTIVITY_LIST}:SORT_OPTIONS`;
export const ACTIVITY_LIST_SORT_OPTIONS_SET = `${ACTIVITY_LIST_SORT_OPTIONS}:SET`;

export const ACTIVITY_LIST_LOCATION = `${ACTIVITY_LIST}:LOCATION`;
export const ACTIVITY_LIST_LOCATION_SET = `${ACTIVITY_LIST_LOCATION}:SET`;

export const ACTIVITY_LIST_TYPES = `${ACTIVITY_LIST}:TYPE`;
export const ACTIVITY_LIST_TYPES_SET = `${ACTIVITY_LIST_TYPES}:SET`;

export const ACTIVITY_LIST_ACTIONS = `${ACTIVITY_LIST}:ACTIONS`;
export const ACTIVITY_LIST_ACTIONS_SET = `${ACTIVITY_LIST_ACTIONS}:SET`;

export const ACTIVITY_LIST_SELECTED_DATE = `${ACTIVITY_LIST}:SELECTED_DATE`;
export const ACTIVITY_LIST_SELECTED_DATE_SET = `${ACTIVITY_LIST_SELECTED_DATE}:SET`;
