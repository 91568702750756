import { Image } from 'components/Common/Image';
import { Row } from 'components/Common/Row';
import { Header } from 'components/Common/Typography/Header';
import { FIRST_LEVEL_NAV_HEIGHT as DESKTOP_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { FIRST_LEVEL_NAV_HEIGHT as MOBILE_HEIGHT } from 'const/navigation/MOBILE_NAV';
import { useMobile } from 'hooks/useMobile';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

function CompanyBadge({ size = 48, name, logo }) {
    const isMobile = useMobile();

    return (
        <Row style={{ height: isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT }} gap={12}>
            {logo ? (
                <Image
                    className="d-block rounded-circle"
                    src={logo}
                    alt=""
                    style={{
                        width: size,
                        minWidth: size,
                        height: size,
                    }}
                />
            ) : (
                <Skeleton
                    width={size}
                    height={size}
                    circle
                />
            )}

            {!isMobile && (
                name ? (
                    <Header noWrap ellipsis>{name}</Header>
                ) : (
                    <div className="ml-3 mb-0 d-none d-lg-block">
                        <Skeleton
                            width={150}
                            height={17}
                        />
                    </div>
                )
            )}
        </Row>
    );
}

export default CompanyBadge;
