import React from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Actions

// Components
import { LogoIcon } from 'components/Icon/Icon';
import {
    Container, Row, Col, Form,
} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import LocalesDropdown from 'components/LocalesDropdown/LocalesDropdown';
import { Button } from 'components/Common/Button';
import { Image } from 'components/Common/Image/Image';
import { CheckBox } from 'components/Common/CheckBox';
import { tokensSelector } from '../../store/selectors/auth';
import * as actions from '../../store/actions';

// Images
import login from '../../static/images/login.jpg';

import { SIGN_IN_PAGE } from '../../const/e2e/TEST_ATTRIBUTES';

const SignIn = () => {
    const { accessToken, refreshToken } = useSelector(tokensSelector);
    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const {
        values, touched, errors, handleChange, handleSubmit, isSubmitting,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true,
        },
        onSubmit: ({ email, password, remember }, { setErrors, setSubmitting }) => {
            dispatch(actions.signIn(email, password, remember))
                .then(() => {
                    addToast(t('auth.signedInSuccessfully'), {
                        appearance: 'success',
                    });
                })
                .catch(({ message, errors }) => {
                    if (errors) {
                        setErrors(errors);
                        return;
                    }

                    addToast(message, {
                        appearance: 'error',
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const { t } = useTranslation();

    if (!!refreshToken && !!accessToken) {
        return <Redirect to="/agenda" />;
    }

    return (
        <Container className="d-flex flex-column justify-content-center align-items-end">
            <div className="w-100 bg-white rounded shadow-container">
                <Row>
                    <Col md={6} className="d-none d-md-block pr-md-0">
                        <Image className="w-100 h-100 object-fit-cover rounded-left" src={login} alt="" />
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column justify-content-center pl-md-0">
                        <div className="d-flex flex-column justify-content-center h-100 p-3 pt-5 px-md-4 px-lg-5 pt-md-5 pb-md-0">
                            <LogoIcon className="mb-4" />

                            <h1>
                                {t('signInRoute.signIn')}
                            </h1>

                            <Form className="mt-3" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label data-testid={SIGN_IN_PAGE.emailFieldTitleSelector}>
                                        {t('signInRoute.email')}
                                    </Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange}
                                        data-testid={SIGN_IN_PAGE.emailFieldInputSelector}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label data-testid={SIGN_IN_PAGE.passwordFieldTitleSelector}>
                                        {t('signInRoute.password')}
                                    </Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isInvalid={touched.password && !!errors.password}
                                        data-testid={SIGN_IN_PAGE.passwordFieldInputSelector}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="rememberMe">
                                            <CheckBox
                                                name="remember"
                                                label={t('signInRoute.rememberMe')}
                                                checked={values.remember}
                                                onChange={handleChange}
                                                data-testid={SIGN_IN_PAGE.rememberMeCheckboxTitleSelector}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col className="text-right">
                                        <Link
                                            className="text-dark font-size-14 font-weight-bold text-decoration-none"
                                            to="/reset-password"
                                            data-testid={SIGN_IN_PAGE.forgotPasswordButtonSelector}
                                        >
                                            {t('signInRoute.forgotPassword')}
                                        </Link>
                                    </Col>
                                </Row>

                                <Button
                                    color="yellow"
                                    type="submit"
                                    data-testid={SIGN_IN_PAGE.signInButtonSelector}
                                    loading={isSubmitting}
                                >
                                    {t('signInRoute.signIn')}
                                </Button>
                            </Form>
                        </div>

                        <div className="d-flex flex-direction-row justify-content-end align-items-end px-3 px-md-4 px-lg-5 py-4 pt-md-0">
                            <LocalesDropdown />
                        </div>
                    </Col>
                </Row>
            </div>

            <span className="small text-muted mt-2">{process.env.VERSION}</span>
        </Container>
    );
};

export default SignIn;
