import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextSubHeader } from 'components/Layout/TextSubHeader';
import SearchInput2 from 'components/Common/SearchInput2';
import { Button } from 'components/Common/Button';
import { useMobile } from 'hooks/useMobile';
import { Switch } from '../../index';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'sold.header';

const SoldBundlesHeader = (props) => {
    const {
        search,
        hideArchived,
        onSearch,
        onToggleHideArchived,
        onToggleSellBundleModal,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const isMobile = useMobile();

    const handleSearch = useCallback((nextSearch) => {
        onSearch({ search: nextSearch });
    }, [onSearch]);

    return (
        <TextSubHeader
            text={t(`${T_PREFIX}.title`)}
            after={[
                !isMobile && <SearchInput2 searchValue={search} onSearch={handleSearch} />,
                <Switch
                    checked={hideArchived}
                    onChange={onToggleHideArchived}
                    label={t(`${T_PREFIX}.actions.hideArchived`)}
                />,
            ]}
            rightActions={(
                !isMobile && (
                    <Button
                        onClick={onToggleSellBundleModal}
                    >
                        {t(`${T_PREFIX}.actions.sell`)}
                    </Button>
                )
            )}
        />

    );
};

SoldBundlesHeader.propTypes = {
    search: PropTypes.string,
    hideArchived: PropTypes.bool,
    onSearch: PropTypes.func,
    onToggleHideArchived: PropTypes.func,
    onToggleSellBundleModal: PropTypes.func,
};

SoldBundlesHeader.defaultProps = {
    search: '',
    hideArchived: false,
    onSearch: emptyFunc,
    onToggleHideArchived: emptyFunc,
    onToggleSellBundleModal: emptyFunc,
};

export default SoldBundlesHeader;
