import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components/Common/IconButton';
import { Plus } from 'components/Icon/Icon';
import { Footer, SearchInput } from '../../index';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './SoldBundlesFooter.module.scss';

const SoldBundlesFooter = (props) => {
    const {
        search,
        onAdd,
        onSearch,
    } = props;

    const handleSearch = useCallback((nextSearch) => {
        onSearch({ search: nextSearch });
    }, [onSearch]);

    return (
        <div className="mb-sm-4 mb-lg-0 mb-lg-0 pb-5 pt-3 pt-lg-4 pb-lg-2">
            <Footer>
                <div className="d-flex d-lg-none flex-row">
                    <SearchInput
                        searchValue={search}
                        onSearch={handleSearch}
                    />
                    <div className={styles.divider} />
                    <IconButton
                        type="button"
                        color="white"
                        size={60}
                        onClick={onAdd}
                    >
                        <Plus />
                    </IconButton>
                </div>
            </Footer>
        </div>
    );
};

SoldBundlesFooter.propTypes = {
    search: PropTypes.string,
    onAdd: PropTypes.func,
    onSearch: PropTypes.func,
};

SoldBundlesFooter.defaultProps = {
    search: '',
    onAdd: emptyFunc,
    onSearch: emptyFunc,
};

export default SoldBundlesFooter;
