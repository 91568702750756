import React, {
    useState, useCallback, useEffect, useMemo,
} from 'react';
import {
    Dropdown, Form, InputGroup, Modal,
} from 'react-bootstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Common/InfoTooltip';
import SubscriptionDataTransformations from 'services/subscriptionDataTransformations';
import { isInvalid, getError } from 'services/validationService';
import { useDialog } from 'hooks/useDialog';

import {
    styles as selectStyles,
    theme as selectTheme,
    dangerTheme as selectDangerTheme,
} from 'styles/select';

import { checkSubscription, getSellSubscriptionData, sellSubscription } from 'store/actions';
import * as CLIENTS_ACTIONS from 'store/actions/clients';
import { sellSubscriptionModalSelector } from 'store/selectors/subscriptions';
import { SOLD_SUBSCRIPTION_PAYMENT_TYPE } from 'const/subscriptions/SOLD_SUBSCRIPTION_PAYMENT_TYPE';
import { CalendarIcon } from 'components/Icon/Icon';
import { DateInput, PhoneInput } from 'components';
import { Button } from 'components/Common/Button';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { isValidIBAN } from 'helpers/validation/iban';
import ClientsSelect from 'components/clients/ClientsSelect/ClientsSelect';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';

import { ConfirmDialog } from 'components/Common/Dialogs/ConfirmDialog';
import { generateRandomString } from 'helpers/string/generateRandomString';
import { useToasts } from 'react-toast-notifications';
import * as styles from './SellSubscriptionModal.module.scss';

const NOTE_MAX_LENGTH = 200;
const IBAN_MAX_LENGTH = 64;
const ACCOUNT_HOLDER_MAX_LENGTH = 64;

function SellSubscriptionModal({ onClose, defaultClient }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isClientDisabled = Boolean(defaultClient?.id);

    const formikInitialValues = useMemo(() => ({
        subscription: null,
        startDate: null,
        endDate: null,
        client: {
            clientId: defaultClient?.id || '',
            clientName: defaultClient?.fullName?.firstName || '',
            clientLastName: defaultClient?.fullName?.lastName || '',
            clientPhone: defaultClient?.contactInfo?.phone || '',
            clientEmail: defaultClient?.contactInfo?.email || '',
        },
        note: '',
        paymentType: SOLD_SUBSCRIPTION_PAYMENT_TYPE.FIRST_PAYMENT,
        iban: '',
        ibanOwner: '',
    }), [defaultClient]);

    const validationSchema = yup.object({
        subscription: yup.mixed().required(t('validationErrors.cannotBeEmpty', {
            name: t('subscriptions.sellSubscriptionModal.subscriptionName'),
        })),
        startDate: yup.mixed().required(t('validationErrors.cannotBeEmpty', {
            name: t('subscriptions.sellSubscriptionModal.startDate.label'),
        })),
        endDate: yup.object().nullable().when('startDate', (startDate, schema) => {
            if (startDate) {
                return schema.test({
                    name: generateRandomString(),
                    test: (endDate) => !endDate || endDate?.isAfter(startDate),
                    message: t('validationErrors.endAfterStart'),
                });
            }
            return schema;
        }),

        client: yup.mixed().required(t('validationErrors.cannotBeEmpty', {
            name: t('addBookingModal.client'),
        })),
        paymentType: yup.mixed().required(t('validationErrors.cannotBeEmpty', {
            name: t('subscriptions.sellSubscriptionModal.paymentType'),
        })),
        iban: yup.string().nullable()
            .when(['paymentType'], {
                is: (paymentType) => paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN && !fullClientItem?.maskedIban,
                then: yup.string().trim().required(t('validationErrors.cannotBeEmpty', {
                    name: t('subscriptions.sellSubscriptionModal.ibanNumber.label'),
                })).max(
                    IBAN_MAX_LENGTH,
                    t('validationErrors.maxLength', {
                        length: IBAN_MAX_LENGTH,
                        name: t('subscriptions.sellSubscriptionModal.ibanNumber.label'),
                    }),
                )
                    .test({
                        name: 'is-valid-iban',
                        message: t('subscriptions.sellSubscriptionModal.ibanNumber.invalid'),
                        exclusive: true,
                        test: (value) => !value || isValidIBAN(value),
                    }),
            }),
        ibanOwner: yup.string().nullable()
            .when(['paymentType'], {
                is: (paymentType) => paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN && !fullClientItem?.maskedIban,
                then: yup.string().trim().required(t('validationErrors.cannotBeEmpty', {
                    name: t('subscriptions.sellSubscriptionModal.accountHolder.label'),
                })).max(
                    ACCOUNT_HOLDER_MAX_LENGTH,
                    t('validationErrors.maxLength', {
                        length: ACCOUNT_HOLDER_MAX_LENGTH,
                        name: t('subscriptions.sellSubscriptionModal.accountHolder.label'),
                    }),
                ),
            }),
    });

    const { subscriptions } = useSelector(sellSubscriptionModalSelector);
    const { subscriptionPaymentTypes } = useSelector(({ company }) => company);

    const addNewClientDialog = useDialog(false);
    const confirmSellSameSubscriptionDialog = useDialog(false);
    const { addToast } = useToasts();

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: (valuesToSubmit, { setErrors, setSubmitting }) => {
            const {
                subscription: { value },
                startDate,
                endDate,
                client: {
                    clientId,
                },
            } = valuesToSubmit;

            if (clientId === '') {
                setErrors({ client: t('validationErrors.clientNotSelected') });
                setSubmitting(false);
                return;
            }
            setSubmitting(true);

            const formData = new FormData();
            formData.append('clientId', clientId);
            formData.append('startDate', startDate.startOf('day').unix());
            if (endDate) {
                formData.append('endDate', endDate.startOf('day').unix());
            }

            dispatch(checkSubscription({
                subscriptionId: value,
                formData,
                onSuccess: ({ result }) => {
                    if (result) {
                        confirmSellSameSubscriptionDialog.onShow();
                        setSubmitting(false);
                    } else {
                        handleSellSubscription();
                    }
                },
            }));
        },
    });

    const {
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setSubmitting,
        setErrors,
        values,
        errors,
        touched,
        isSubmitting,
    } = formik;

    const handleSellSubscription = useCallback(() => {
        const {
            subscription: { value },
            startDate,
            endDate,
            client: {
                clientId,
                clientName,
                clientEmail,
                clientPhone,
            },
            note,
            paymentType,
            iban,
            ibanOwner,
        } = values;

        const payload = SubscriptionDataTransformations.transformSellSubscriptionModalDataIntoPayload({
            startDate: startDate.startOf('day').unix(),
            endDate: endDate ? endDate.startOf('day').unix() : null,
            clientId,
            clientName,
            clientEmail,
            clientPhone: clientPhone.split(' ').join(''),
            note,
            paymentType: paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL ? 'manual' : 'automatic',
            iban: iban && paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN ? iban.trim() : null,
            ibanOwner: ibanOwner && paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN ? ibanOwner.trim() : null,
            mandateFirstPayment: paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN,
        });
        setSubmitting(true);

        dispatch(sellSubscription({
            subscription: value, payload, onDone: onClose, clientId,
        }))
            .catch(({ message, errors }) => {
                confirmSellSameSubscriptionDialog.onClose();
                setSubmitting(false);
                if (message) {
                    addToast(message, {
                        appearance: 'error',
                    });
                } else {
                    setErrors(errors || {});
                }
            });
    }, [onClose, values, setSubmitting, dispatch]);

    const isClientSelected = Boolean(values.client?.clientId);
    const isSubscriptionSelected = Boolean(values.subscription);

    const onSubscriptionChange = useCallback((selected) => {
        setFieldValue('subscription', selected);

        if (selected.isFree || !isAutomaticPaymentAvailable) {
            setFieldValue('paymentType', SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL);
        }
    }, []);

    const onStartDateChange = useCallback((date) => setFieldValue('startDate', date), []);
    const onEndDateChange = useCallback((date) => setFieldValue('endDate', date), []);
    const onClientPhoneChange = useCallback((clientPhone) => setFieldValue('client.clientPhone', clientPhone), []);
    const onClientEmailChange = useCallback((event) => setFieldValue('client.clientEmail', event.target.value), []);
    const onPaymentChange = useCallback((paymentType) => () => setFieldValue('paymentType', paymentType), []);

    const handleChangeTextField = useCallback(({ field }) => (e) => {
        const { target: { value } } = e;
        setFieldTouched(field, true);
        setFieldValue(field, value);
    }, [setFieldValue, setFieldTouched]);

    const [fullClientItem, setFullClientItem] = useState(null);

    const isAutomaticPaymentAvailable = Boolean(
        subscriptionPaymentTypes?.automatic
        && !values?.subscription?.isFree
        && values.client?.clientEmail,
    );

    useEffect(() => {
        const handleGetClientItem = async (clientId) => {
            const { client } = await dispatch(
                CLIENTS_ACTIONS.getClientSilentItem({ clientId }),
            );
            setFullClientItem(client);
        };

        if (values.client?.clientId) {
            handleGetClientItem(values.client.clientId);
        }
    }, [values.client?.clientId]);

    useEffect(() => {
        if (fullClientItem && fullClientItem?.maskedIban) {
            setFieldValue('maskedIban', fullClientItem?.maskedIban);
            setFieldValue('ibanOwner', fullClientItem?.ibanOwner);
            setFieldValue('iban', '');
            setFieldTouched('iban', false);
            if (isAutomaticPaymentAvailable) {
                setFieldValue('paymentType', SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN);
            }
        } else {
            setFieldValue('maskedIban', '');
            setFieldValue('ibanOwner', '');
            setFieldValue('iban', '');
        }
    }, [fullClientItem, isAutomaticPaymentAvailable]);

    const subscriptionIsInvalid = isInvalid('subscription', errors, touched);
    const startDateIsInvalid = isInvalid('startDate', errors, touched);
    const endDateIsInvalid = isInvalid('endDate', errors, touched);
    const paymentIsInvalid = isInvalid('paymentType', errors, touched);
    const isClientPhoneInvalid = touched.client?.clientPhone && errors.client?.clientPhone;
    const isClientEmailInvalid = touched.client?.clientEmail && errors.client?.clientEmail;
    const isIbanInvalid = !values.maskedIban && isInvalid('iban', errors, touched);
    const isIbanOwnerInvalid = isInvalid('ibanOwner', errors, touched);

    const clientNameValue = useMemo(() => (
        {
            value: `${values.client?.clientName}_${values.client?.clientEmail}_${values.client?.clientPhone}`,
            label: `${values.client?.clientName} ${values.client?.clientLastName}`,
        }
    ), [
        values.client?.clientName,
        values.client?.clientEmail,
        values.client?.clientPhone,
        values.client?.clientLastName,
    ]);

    useEffect(() => {
        dispatch(getSellSubscriptionData());
    }, [dispatch]);

    const isNotManualPayment = values?.paymentType !== SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL;
    const parsedPrice = parseFloat(values?.subscription?.price?.replace(',', '.'));
    const isNotZeroPrice = parsedPrice !== 0;
    const showNote = isNotManualPayment && isNotZeroPrice;
    const isIbanPayment = values?.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN;

    return (
        <React.Fragment>
            <Modal
                centered
                size="lg"
                show={!addNewClientDialog.visible && !confirmSellSameSubscriptionDialog.visible}
                onHide={onClose}
            >
                <Form onSubmit={handleSubmit}>
                    <DialogHeader
                        className={styles.modalHeader}
                        closeButton
                    >
                        <Modal.Title className="mb-0 font-weight-700">
                            {t('subscriptions.sellSubscriptionModal.title')}
                        </Modal.Title>
                    </DialogHeader>

                    <DialogBody>
                        <Form.Group>
                            <Form.Label>
                                {t('subscriptions.sellSubscriptionModal.subscriptionName')}
                            </Form.Label>

                            <Select
                                name="subscription"
                                placeholder={t('subscriptions.sellSubscriptionModal.selectSubscription')}
                                styles={selectStyles}
                                isClearable
                                isInvalid={subscriptionIsInvalid}
                                theme={subscriptionIsInvalid ? selectDangerTheme : selectTheme}
                                options={subscriptions}
                                value={values.subscription}
                                onChange={onSubscriptionChange}
                            />

                            <Form.Control.Feedback
                                type="invalid"
                                className={subscriptionIsInvalid && 'd-flex'}
                            >
                                {getError('subscription', errors)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {isSubscriptionSelected && (
                            <React.Fragment>
                                <Form.Group>
                                    <Form.Label>
                                        {t('subscriptions.sellSubscriptionModal.description')}
                                    </Form.Label>

                                    <Form.Control
                                        as="input"
                                        disabled
                                        value={values.subscription?.description}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>
                                        {t('subscriptions.sellSubscriptionModal.price')}
                                    </Form.Label>

                                    <Form.Control
                                        as="input"
                                        disabled
                                        value={values.subscription?.price}
                                    />
                                </Form.Group>
                            </React.Fragment>
                        )}

                        <Row gap={16} stretched align="start">
                            <Form.Group>
                                <Form.Label className="d-flex align-items-center">
                                    {t('subscriptions.sellSubscriptionModal.startDate.label')}
                                    <InfoTooltip
                                        text={t('subscriptions.sellSubscriptionModal.startDate.tooltip')}
                                        placement="bottom"
                                    />
                                </Form.Label>

                                <InputGroup>
                                    <DateInput
                                        name="startDate"
                                        placeholder={t('placeholders.selectDate')}
                                        isInvalid={startDateIsInvalid}
                                        value={values.startDate}
                                        onChange={onStartDateChange}
                                    />

                                    <InputGroup.Append>
                                        <InputGroup.Text className={classNames({
                                            'border-danger text-danger': startDateIsInvalid,
                                            'text-muted': startDateIsInvalid,
                                        })}
                                        >
                                            <CalendarIcon className={styles.calendarIcon} width={18} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Form.Control.Feedback
                                    type="invalid"
                                    className={startDateIsInvalid && 'd-flex'}
                                >
                                    {getError('startDate', errors)}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="d-flex align-items-center">
                                    {t('subscriptions.sellSubscriptionModal.endDate.label')}
                                    <InfoTooltip
                                        text={t('subscriptions.sellSubscriptionModal.endDate.tooltip')}
                                        placement="bottom"
                                    />
                                </Form.Label>

                                <InputGroup>
                                    <DateInput
                                        name="endDate"
                                        placeholder={t('placeholders.selectDate')}
                                        isInvalid={endDateIsInvalid}
                                        value={values.endDate}
                                        onChange={onEndDateChange}
                                        onClear={() => onEndDateChange(null)}
                                        minDate={values.startDate?.clone().add(1, 'day')}
                                    />

                                    <InputGroup.Append>
                                        <InputGroup.Text className={classNames({
                                            'border-danger text-danger': endDateIsInvalid,
                                            'text-muted': endDateIsInvalid,
                                        })}
                                        >
                                            <CalendarIcon className={styles.calendarIcon} width={18} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Form.Control.Feedback
                                    type="invalid"
                                    className={endDateIsInvalid && 'd-flex'}
                                >
                                    {getError('endDate', errors)}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Dropdown.Divider />
                        <div className={styles.clientHeaderWrapper}>
                            <div className={styles.clientTitle}>
                                {t('addBookingModal.client')}
                            </div>
                        </div>

                        <Form.Group>
                            {isClientDisabled ? (
                                <React.Fragment>
                                    <Form.Label>
                                        {t('subscriptions.sellSubscriptionModal.client')}
                                    </Form.Label>
                                    <Form.Control
                                        name="client.name"
                                        value={clientNameValue.label}
                                        disabled
                                    />
                                </React.Fragment>
                            ) : (
                                <ClientsSelect
                                    formik={formik}
                                    title={(
                                        <Form.Label>
                                            {t('subscriptions.sellSubscriptionModal.client')}
                                        </Form.Label>
                                    )}
                                />
                            )}
                        </Form.Group>

                        {isClientSelected && (
                            <Row stretched gap={16} align="start">
                                <Column gap={8} stretched>
                                    <Form.Label>
                                        {t('subscriptions.sellSubscriptionModal.contactNumber')}
                                    </Form.Label>

                                    <PhoneInput
                                        disabled={isClientDisabled || !values.client?.isNew}
                                        name="client.clientPhone"
                                        value={values.client.clientPhone}
                                        onChange={onClientPhoneChange}
                                        isInvalid={isClientPhoneInvalid}
                                    />
                                </Column>
                                <Column gap={8} stretched>
                                    <Form.Label>
                                        {t('subscriptions.sellSubscriptionModal.email')}
                                    </Form.Label>

                                    <Form.Control
                                        disabled={isClientDisabled || (values.client?.clientName && !values.client?.isNew)}
                                        name="client.clientEmail"
                                        value={values.client.clientEmail}
                                        onChange={onClientEmailChange}
                                        isInvalid={isClientEmailInvalid}
                                    />
                                </Column>
                            </Row>
                        )}

                        {isSubscriptionSelected && (
                            <React.Fragment>
                                <Dropdown.Divider />
                                <Column gap={4}>
                                    <span className="font-size-22 font-weight-700">
                                        {t('subscriptions.sellSubscriptionModal.paymentType.label')}
                                    </span>
                                    {isAutomaticPaymentAvailable && (
                                        <span className="font-size-14 font-weight-600 text-muted">
                                            {t('subscriptions.sellSubscriptionModal.paymentType.tooltip')}
                                        </span>
                                    )}
                                </Column>
                                <Row gap={32} align="center" padding={16} wrap>
                                    {isAutomaticPaymentAvailable && (
                                        <React.Fragment>
                                            <Form.Check
                                                id="paypro"
                                                type="radio"
                                                name="payment-radio"
                                                checked={values?.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.FIRST_PAYMENT}
                                                label={(
                                                    <Form.Label className="d-flex align-items-center m-0">
                                                        {t('subscriptions.sellSubscriptionModal.automatic.label')}
                                                        <InfoTooltip
                                                            text={t('subscriptions.sellSubscriptionModal.automatic.tooltip')}
                                                            placement="bottom"
                                                        />
                                                    </Form.Label>
                                                )}
                                                onChange={onPaymentChange(SOLD_SUBSCRIPTION_PAYMENT_TYPE.FIRST_PAYMENT)}
                                            />
                                            <Form.Check
                                                id="iban"
                                                type="radio"
                                                name="payment-radio"
                                                checked={values?.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN}
                                                label={(
                                                    <Form.Label className="d-flex align-items-center m-0">
                                                        {t('subscriptions.sellSubscriptionModal.iban.label')}
                                                        <InfoTooltip
                                                            text={t('subscriptions.sellSubscriptionModal.iban.tooltip')}
                                                            placement="bottom"
                                                        />
                                                    </Form.Label>
                                                )}
                                                onChange={onPaymentChange(SOLD_SUBSCRIPTION_PAYMENT_TYPE.IBAN)}
                                            />
                                        </React.Fragment>
                                    )}

                                    <Form.Check
                                        id="manual"
                                        type="radio"
                                        name="payment-radio"
                                        checked={values?.paymentType === SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL}
                                        label={(
                                            <Form.Label className="d-flex align-items-center m-0">
                                                {t('subscriptions.sellSubscriptionModal.manual.label')}
                                                <InfoTooltip
                                                    text={t('subscriptions.sellSubscriptionModal.manual.tooltip')}
                                                    placement="bottom"
                                                />
                                            </Form.Label>
                                        )}
                                        onChange={onPaymentChange(SOLD_SUBSCRIPTION_PAYMENT_TYPE.MANUAL)}
                                    />
                                </Row>
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={paymentIsInvalid && 'd-flex'}
                                >
                                    {getError('paymentType', errors)}
                                </Form.Control.Feedback>
                                {
                                    isIbanPayment && (
                                        <React.Fragment>
                                            <Form.Group>
                                                <Form.Label>
                                                    {t('subscriptions.sellSubscriptionModal.ibanNumber.label')}
                                                </Form.Label>
                                                <Form.Control
                                                    type="string"
                                                    name={values.maskedIban ? 'maskedIban' : 'iban'}
                                                    value={values.maskedIban || values.iban || ''}
                                                    onChange={handleChangeTextField({ field: 'iban' })}
                                                    isInvalid={isIbanInvalid}
                                                    placeholder={t('subscriptions.sellSubscriptionModal.ibanNumber.placeholder')}
                                                    disabled={Boolean(values.maskedIban)}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className={isIbanInvalid && 'd-flex'}
                                                >
                                                    {getError('iban', errors)}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>
                                                    {t('subscriptions.sellSubscriptionModal.accountHolder.label')}
                                                </Form.Label>
                                                <Form.Control
                                                    type="string"
                                                    name="ibanOwner"
                                                    value={values.ibanOwner || ''}
                                                    onChange={handleChangeTextField({ field: 'ibanOwner' })}
                                                    isInvalid={isIbanOwnerInvalid}
                                                    placeholder={t('subscriptions.sellSubscriptionModal.accountHolder.placeholder')}
                                                    disabled={Boolean(values.maskedIban)}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className={isIbanOwnerInvalid && 'd-flex'}
                                                >
                                                    {getError('ibanOwner', errors)}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </React.Fragment>
                                    )
                                }
                                {showNote && (
                                    <React.Fragment>
                                        <Dropdown.Divider />
                                        <Form.Group>
                                            <Form.Label htmlFor="note" className="d-flex align-items-center">
                                                {t('subscriptions.sellSubscriptionModal.note.label')}
                                                <InfoTooltip
                                                    text={t('subscriptions.sellSubscriptionModal.note.tooltip')}
                                                    placement="bottom"
                                                />
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="note"
                                                value={values.note}
                                                onChange={handleChange}
                                                maxLength={NOTE_MAX_LENGTH}
                                            />
                                        </Form.Group>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}

                    </DialogBody>

                    <DialogFooter>
                        <Row stretched justify="between" gap={16}>
                            <Button
                                color="outline"
                                onClick={onClose}
                            >
                                {t('placeholders.cancel')}
                            </Button>
                            <Button
                                color="yellow"
                                type="submit"
                                loading={isSubmitting}
                            >
                                {t('placeholders.sellSubscription')}
                            </Button>
                        </Row>
                    </DialogFooter>
                </Form>
            </Modal>
            {
                confirmSellSameSubscriptionDialog.visible && (
                    <ConfirmDialog
                        size="md"
                        text={t('subscriptions.sellSubscriptionModal.confirmSellSameSubscriptionDialog.text')}
                        title={t('subscriptions.sellSubscriptionModal.confirmSellSameSubscriptionDialog.title')}
                        visible={confirmSellSameSubscriptionDialog.visible}
                        onClose={confirmSellSameSubscriptionDialog.onClose}
                        onReject={confirmSellSameSubscriptionDialog.onClose}
                        onConfirm={handleSellSubscription}
                        rejectProps={{ label: t('placeholders.no') }}
                        confirmProps={{
                            label: t('placeholders.yes'),
                            isLoading: isSubmitting,
                        }}
                    />
                )
            }
        </React.Fragment>
    );
}

export default SellSubscriptionModal;
