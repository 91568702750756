import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';
import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { Column } from 'components/Common/Column';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMobile } from 'hooks/useMobile';
import { ActivityPageMobileFooter } from 'components/Activity/ActivityPageMobileFooter';
import { ActivityTableBodyRow } from 'components/Activity/ActivityTableBodyRow';
import { ActivityTableHeader } from 'components/Activity/ActivityTableHeader';
import { Row } from 'components/Common/Row';
import { PeriodPicker } from 'components/Common/PeriodPicker';
import LocationsSelect from 'components/Activity/LocationsSelect/LocationsSelect';
import SearchInput from 'components/Common/SearchInput2';
import { TypesWindowedSelect } from 'components/Activity/TypesWindowedSelect';
import { ActionsWindowedSelect } from 'components/Activity/ActionsWindowedSelect';
import * as CLIENTS_ACTIONS from '../../../../../../store/actions/clients/tables/activity';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';

const COLUMNS_COUNT = 9;

const schema = yup.object({
    types: yup.array().of(yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
    })),
    actions: yup.array().of(yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
    })),
});

function ClientActivityTable() {
    const { clientId } = useParams();

    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);

    const formik = useFormik({
        initialValues: {
            types: [],
            actions: [],
        },
        validationSchema: schema,
    });

    const {
        page,
        items: activityList,
        showAll,
        isLoading,
        itemsCount,
        pagesCount,
        itemsPerPage,
        search,
        sortOptions,
        types,
        actions,
        location,
        date,
    } = useSelector(CLIENTS_SELECTORS.clientActivityListSelector);

    const isMobile = useMobile();

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.showAllActivityList());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListPageFirst());
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_ACTIONS.setActivityListPage({ page: nextPage }));
    }, [dispatch]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(CLIENTS_ACTIONS.setActivityListSearch({ search: nextSearch }));
    }, [dispatch]);

    const handleLocationSelect = useCallback((nextLocation) => {
        dispatch(CLIENTS_ACTIONS.setActivityListLocation({ location: nextLocation?.value || null }));
    }, [dispatch]);

    const handleTypeSelect = useCallback((nextType) => {
        const newType = {
            id: nextType,
            name: t(`activity.header.filters.types.${nextType}`),
        };
        if (formik.values.types.find((type) => type.id === newType.id)) {
            formik.setFieldValue('types', formik.values.types.filter((type) => type.id !== newType.id));
        } else {
            formik.setFieldValue('types', [...formik.values.types, newType]);
        }
    }, [dispatch, formik.values.types, t]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListTypes({ types: formik.values.types }));
    }, [dispatch, formik.values.types]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.setActivityListActions({ actions: formik.values.actions }));
    }, [dispatch, formik.values.actions]);

    const handleChangeSelectedDate = useCallback((date) => {
        dispatch(CLIENTS_ACTIONS.setActivityListSelectedDate(date));
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getActivityList({ clientId }));
    }, [
        dispatch,
        page,
        search,
        types,
        actions,
        location,
        date,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    useEffect(() => () => dispatch(CLIENTS_ACTIONS.clearActivityList()), []);

    const handleCellClick = useCallback((sortBy) => {
        if (sortOptions.sortBy === sortBy) {
            dispatch(CLIENTS_ACTIONS.setActivityListSortOptions({
                sortOptions: {
                    orderBy: sortOptions.orderBy * -1,
                },
            }));
        } else {
            dispatch(CLIENTS_ACTIONS.setActivityListSortOptions({
                sortOptions: {
                    sortBy,
                    orderBy: 1,
                },
            }));
        }
    }, [sortOptions]);

    return (
        <Column gap={32} stretched>
            {!isMobile
                && (
                    <Row stretched gap={16} justify="end">
                        <PeriodPicker
                            initDateFrom={date.dateFrom}
                            initDateTo={date.dateTo}
                            initPeriod={date.period}
                            onChange={handleChangeSelectedDate}
                        />
                        <LocationsSelect placeholder={t('activity.header.locationSelect.placeholder')} onSelect={handleLocationSelect} defaultSelected={location} />
                        <ActionsWindowedSelect formik={formik} />
                        <TypesWindowedSelect
                            formik={formik}
                        />
                        <SearchInput
                            onSearch={handleSearch}
                            searchValue={search}
                            stretched={false}
                        />
                    </Row>
                )}
            <Content loading={isLoading}>
                <Table
                    footer={{
                        page,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: COLUMNS_COUNT,
                        itemsPerPage,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                >
                    <TableHeader>
                        <ActivityTableHeader onCellClick={handleCellClick} sortOptions={sortOptions} />
                    </TableHeader>
                    <TableBody>
                        {activityList?.map((log) => (
                            <ActivityTableBodyRow
                                key={log.id}
                                log={log}
                                isClientPage
                            />
                        ))}
                    </TableBody>
                </Table>
            </Content>
            <ActivityPageMobileFooter searchValue={search} onSearch={handleSearch} formik={formik} handleTypeSelect={handleTypeSelect} />
        </Column>
    );
}

export default ClientActivityTable;
