import {
    put, select, takeLatest,
} from 'redux-saga/effects';

// Actions
import _ from 'lodash';
import * as actions from '../actions';

export function* refreshPermissions() {
    const userRole = yield select((state) => state.auth.userRole);
    const employeeType = yield select((state) => state.auth.employeeType);

    yield put(actions.setPermissions(_.camelCase(userRole), employeeType));
}

export const permissionsSaga = [
    takeLatest(actions.refreshPermissions, refreshPermissions),
];
