export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCEEDED = 'GET_GROUPS_SUCCEEDED';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';

export const GET_GROUP_SESSIONS = 'GET_GROUP_SESSIONS';
export const GET_GROUP_SESSIONS_SUCCEEDED = 'GET_GROUP_SESSIONS_SUCCEEDED';
export const GET_GROUP_SESSIONS_FAILED = 'GET_GROUP_SESSIONS_FAILED';

export const SET_GROUPS_FILTER = 'SET_GROUPS_FILTER';

export const GET_GROUP_SESSION_BOOKINGS = 'GET_GROUP_SESSION_BOOKINGS';
export const GET_GROUP_SESSION_BOOKINGS_SUCCEEDED = 'GET_GROUP_SESSION_BOOKINGS_SUCCEEDED';
export const GET_GROUP_SESSION_BOOKINGS_FAILED = 'GET_GROUP_SESSION_BOOKINGS_FAILED';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCEEDED = 'ADD_GROUP_SUCCEEDED';
export const ADD_GROUP_FAILED = 'ADD_GROUP_FAILED';

export const EDIT_GROUP = 'EDIT_GROUP';
export const EDIT_GROUP_SUCCEEDED = 'EDIT_GROUP_SUCCEEDED';
export const EDIT_GROUP_FAILED = 'EDIT_GROUP_FAILED';

export const OVERRIDE_GROUP_SESSION = 'OVERRIDE_GROUP_SESSION';
export const OVERRIDE_GROUP_SESSION_SUCCEEDED = 'OVERRIDE_GROUP_SESSION_SUCCEEDED';
export const OVERRIDE_GROUP_SESSION_FAILED = 'OVERRIDE_GROUP_SESSION_FAILED';

export const CANCEL_GROUP_SESSION = 'CANCEL_GROUP_SESSION';
export const CANCEL_GROUP_SESSION_SUCCEEDED = 'CANCEL_GROUP_SESSION_SUCCEEDED';
export const CANCEL_GROUP_SESSION_FAILED = 'CANCEL_GROUP_SESSION_FAILED';

export const DELETE_GROUP_SESSION = 'DELETE_GROUP_SESSION';
export const DELETE_GROUP_SESSION_SUCCEEDED = 'DELETE_GROUP_SESSION_SUCCEEDED';
export const DELETE_GROUP_SESSION_FAILED = 'DELETE_GROUP_SESSION_FAILED';

export const CHECK_GROUP_SESSION = 'CHECK_GROUP_SESSION';
