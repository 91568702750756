import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';

import WindowedSelect, { components } from 'react-windowed-select';
import TextButton from 'components/Common/TextButton/TextButton';
import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';
import { CheckBox } from 'components/Common/CheckBox';
import * as styles from './TypesWindowedSelect.module.scss';

const PRODUCT_TYPES = ['service', 'bundle', 'subscription'];

const TypesWindowedSelect = ({ formik, stretched }) => {
    const {
        values, setFieldValue, errors, touched,
    } = formik;

    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const [value, setValue] = useState(null);

    const selectTypesOptions = useMemo(() => [
        { value: null, label: '' },
        ...PRODUCT_TYPES.map((type, index) => ({
            value: type,
            label: t(`activity.header.filters.types.${type}`),
            index,
        })),
    ], [t]);

    const valuesTypesIdsSet = useMemo(() => new Set(values?.types?.map((c) => c.id)), [values?.types?.length]);

    const isAllItemsSelected = values?.types.length === PRODUCT_TYPES.length;

    const handleSetValues = useCallback((types) => {
        const valies = types.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (types.length > 100) {
            valies.push({ value: -1, label: tc('weekRoute.more', { count: types.length - 100 }) });
        }
        setValue(valies);
    }, []);

    useEffect(() => {
        handleSetValues(values.types);
    }, [values.types.length]);

    const handleChangeAllTypesSelect = useCallback(({ clear }) => {
        let types = [];
        if (isAllItemsSelected || clear) {
            setFieldValue('types', types);
        } else {
            types = PRODUCT_TYPES.map((c) => ({
                id: c,
                name: t(`activity.header.filters.types.${c}`),
            }));
            setFieldValue('types', types);
        }
        handleSetValues(types);
    }, [isAllItemsSelected]);

    const handleChangeTypeSelect = useCallback((type) => {
        if (!type.value) {
            return;
        }
        let types = [];
        const isChecked = valuesTypesIdsSet.has(type.value);
        if (!isChecked) {
            types = [...values.types, { id: type.value, name: type.label }];
            setFieldValue('types', types);
        } else {
            types = values.types.filter((c) => c.id !== type.value);
            setFieldValue('types', types);
        }

        handleSetValues(types);
    }, [values?.types.length, valuesTypesIdsSet]);

    const handleChangeTypesSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllTypesSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeTypeSelect(triggeredAction.removedValue);
                return;
            }
            const types = values.types.slice(0, 100);
            setFieldValue('types', types);
            handleSetValues(types);
        }
        handleChangeTypeSelect(triggeredAction.option);
    }, [handleChangeTypeSelect, handleChangeAllTypesSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllItemsSelected) {
            e.preventDefault();
            setFieldValue('types', []);
        }
    }, [isAllItemsSelected]);

    const isTypesSelectInvalid = !!touched.types && !!errors.types;

    return (
        <div className={styles.root} style={{ width: stretched ? '100%' : 'auto' }}>
            <WindowedSelect
                id="types"
                name="types"
                styles={selectWithGrayItems}
                theme={isTypesSelectInvalid ? dangerTheme : theme}
                options={selectTypesOptions}
                value={value}
                onChange={handleChangeTypesSelect}
                isMulti
                isClearable
                backspaceRemovesValue
                onKeyDown={onKeyDownHandle}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={t('activity.header.filters.types.placeholder')}
                components={{
                    MultiValue: values.types.length === PRODUCT_TYPES.length
                        ? (props) => (
                            props.index === 0
                                ? t('activity.header.filters.types.placeholder')
                                : null
                        )
                    // eslint-disable-next-line react/no-unstable-nested-components
                        : (props) => <components.MultiValue {...props} />,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Option: ({
                        innerRef, innerProps, data,
                    }) => {
                        const isChecked = valuesTypesIdsSet.has(data.value);

                        return (
                            <div
                                ref={innerRef}
                                {...innerProps}
                                className={styles.option}
                            >
                                {data.value === null
                                    ? (
                                        <TextButton color="yellow" onClick={handleChangeAllTypesSelect}>
                                            {t('activity.header.filters.types.placeholder')}
                                        </TextButton>
                                    )
                                    : (
                                        <CheckBox
                                            checked={isChecked}
                                            label={data.label}
                                        />
                                    )}
                            </div>
                        );
                    },
                }}
            />

            <Form.Control.Feedback
                className={isTypesSelectInvalid && 'd-block'}
                type="invalid"
            >
                {errors.types}
            </Form.Control.Feedback>
        </div>
    );
};

export default TypesWindowedSelect;
