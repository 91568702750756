/* eslint-disable import/prefer-default-export */
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import { ACTIVITY_SORT_BY_TYPE } from 'const/activity/ACTIVITY_SORT_BY_TYPE';
import moment from 'moment';
import * as ACTIONS from '../../../actions/clients/tables/activity/actionTypes';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    sortOptions: {
        sortBy: ACTIVITY_SORT_BY_TYPE.START_DATE,
        orderBy: 1,
    },
    showAll: false,
    isLoading: false,
    itemsCount: 0,
    pagesCount: 0,
    itemsPerPage: 10,
    types: [],
    actions: [],
    location: null,
    date: {
        period: PERIODS_MAP.month,
        dateFrom: moment().startOf(PERIODS_MAP.month),
        dateTo: moment().endOf(PERIODS_MAP.month),
    },
};

export const clientActivityListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (ACTIONS.GET_CLIENTS_ACTIVITY_LIST_RESOLVED): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
            isLoading: false,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_CLEAR): {
        return INITIAL_STATE;
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_SHOW_ALL): {
        return {
            ...state,
            page: 0,
            showAll: true,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_SEARCH_SET): {
        const { search } = payload;

        return {
            ...state,
            search,
        };
    }
    case (ACTIONS.GET_CLIENTS_ACTIVITY_LIST_TRIGGER): {
        return {
            ...state,
            isLoading: true,
        };
    }
    case (ACTIONS.GET_CLIENTS_ACTIVITY_LIST_REJECTED): {
        return {
            ...state,
            isLoading: false,
        };
    }
    case ACTIONS.CLIENTS_ACTIVITY_LIST_SORT_OPTIONS_SET: {
        const { sortOptions: { sortBy, orderBy } } = payload;

        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    case ACTIONS.CLIENTS_ACTIVITY_LIST_LOCATION_SET: {
        const { location } = payload;

        return {
            ...state,
            location,
        };
    }
    case ACTIONS.CLIENTS_ACTIVITY_LIST_TYPES_SET: {
        const { types: activityTypes } = payload;

        return {
            ...state,
            types: activityTypes,
        };
    }
    case ACTIONS.CLIENTS_ACTIVITY_LIST_ACTIONS_SET: {
        const { actions } = payload;

        return {
            ...state,
            actions,
        };
    }
    case (ACTIONS.CLIENTS_ACTIVITY_LIST_SELECTED_DATE_SET): {
        const { dateFrom, dateTo, period } = payload;

        return {
            ...state,
            date: {
                period,
                dateFrom,
                dateTo,
            },
        };
    }
    default:
        return state;
    }
};
