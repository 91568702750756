import React, { useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { Column } from 'components/Common/Column';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useTranslation } from 'react-i18next';
import { useMobile } from 'hooks/useMobile';
import { Row } from 'components/Common/Row';
import { PeriodPicker } from 'components/Common/PeriodPicker';
import { InvoicesPageMobileFooter } from 'components/Financials/Invoices/InvoicesPageMobileFooter';
import { InvoicesTableHeader } from 'components/Financials/Invoices/InvoicesTableHeader';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { InvoicesTableBodyRow } from 'components/Financials/Invoices/InvoicesTableBodyRow';
import { Badge } from 'components/Common/Badge';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import SearchInput from 'components/Common/SearchInput2';
import * as INVOICES_ACTIONS from 'store/actions/financials/invoices';
import sentry from 'services/sentry';
import { useMultisafe } from 'hooks/useMultisafe';
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';
import * as CLIENTS_INVOICES from '../../../../../../store/actions/clients/tables/invoices';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 9;

function ClientInvoicesTable() {
    const { clientId } = useParams();

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const dispatch = useDispatch();
    const isMobile = useMobile();

    const {
        page,
        items: invoices,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        filters,
        search,
    } = useSelector(CLIENTS_SELECTORS.clientInvoicesListSelector);

    const hideClearingStatus = useMultisafe();

    const invoicesList = useMemo(() => (
        (!showAll && invoices.length > ITEMS_PER_PAGE)
            ? invoices.slice(0, ITEMS_PER_PAGE)
            : invoices
    ), [showAll, invoices]);

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLIENTS_INVOICES.getClientInvoices({ clientId }));
    }, [
        dispatch,
        page,
        showAll,
        filters,
        search,
    ]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesSearch({ search: nextSearch }));
    }, [dispatch]);

    useEffect(() => () => {
        dispatch(CLIENTS_INVOICES.clearClientInvoicesFilters());
    }, []);

    const handleChangeStatusFilter = useCallback((value) => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesFilters({
            filters: {
                status: filters.status === value
                    ? null
                    : value,
            },
        }));
    }, [dispatch, filters.status]);

    const handleChangeDateFilter = useCallback(({ dateFrom, dateTo }) => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesFilters({
            filters: {
                from: dateFrom.format('X'),
                to: dateTo.format('X'),
            },
        }));
    }, [dispatch]);

    const handleSetInvoicesFilters = useCallback(({ filters }) => {
        dispatch(CLIENTS_INVOICES.setClientInvoicesFilters({ filters }));
    }, [dispatch]);

    const handleInvoicesCreateCredit = useCallback((invoiceId) => {
        const onSuccess = () => {
            dispatch(CLIENTS_INVOICES.getClientInvoices({ clientId }));
        };
        dispatch(INVOICES_ACTIONS.createInvoiceCredit({ invoiceId, onSuccess }));
    }, [dispatch]);

    const handleChangeStatus = useCallback((invoiceId) => {
        if (invoiceId) {
            const onSuccess = () => {
                dispatch(CLIENTS_INVOICES.getClientInvoices({ clientId }));
            };
            dispatch(INVOICES_ACTIONS.toggleInvoicePaymentStatus({ invoiceId, onSuccess })).catch(sentry.ignore);
        }
    }, [dispatch]);

    return (
        <Column gap={32} stretched>
            <Row stretched gap={16} justify="end">
                {
                    !isMobile && (
                        <Row gap={12}>
                            <Badge
                                onClick={() => handleChangeStatusFilter(INVOICE_STATUS.PAID)}
                                size="small"
                                color={filters.status === INVOICE_STATUS.PAID ? 'yellow' : 'gray'}
                            >
                                {t('list.header.filtrationButtons.paid.label')}
                            </Badge>

                            <Badge
                                onClick={() => handleChangeStatusFilter(INVOICE_STATUS.UNPAID)}
                                size="small"
                                color={filters.status === INVOICE_STATUS.UNPAID ? 'yellow' : 'gray'}
                            >
                                {t('list.header.filtrationButtons.unpaid.label')}
                            </Badge>

                            <Badge
                                onClick={() => handleChangeStatusFilter(INVOICE_STATUS.DRAFT)}
                                size="small"
                                color={filters.status === INVOICE_STATUS.DRAFT ? 'yellow' : 'gray'}
                            >
                                {t('list.header.filtrationButtons.draft.label')}
                            </Badge>

                        </Row>
                    )
                }
                {
                    !isMobile && (
                        <PeriodPicker
                            initDateFrom={moment.unix(filters.from)}
                            initDateTo={moment.unix(filters.to)}
                            initPeriod={PERIODS_MAP.month}
                            onChange={handleChangeDateFilter}
                        />
                    )
                }
                {
                    !isMobile && (
                        <SearchInput
                            onSearch={handleSearch}
                            searchValue={search}
                            stretched={false}
                        />
                    )
                }
            </Row>
            <Content loading={loading}>
                <Table
                    footer={{
                        page,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: COLUMNS_COUNT,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                >
                    <TableHeader>
                        <InvoicesTableHeader hideClearingStatus={hideClearingStatus} />
                    </TableHeader>
                    <TableBody>
                        {invoicesList.map((invoice) => (
                            <ListTableBodyRow key={invoice.id} data-testid={`data-test-invoice-list-row-${invoice.fullNumber}`}>
                                <InvoicesTableBodyRow
                                    invoice={invoice}
                                    hideClearingStatus={hideClearingStatus}
                                    onInvoicesCreateCredit={handleInvoicesCreateCredit}
                                    onInvoiceChangeStatus={handleChangeStatus}
                                    onRefresh={() => dispatch(CLIENTS_INVOICES.getClientInvoices({ clientId }))}
                                    isClientPage
                                />
                            </ListTableBodyRow>
                        ))}
                    </TableBody>
                </Table>
                <InvoicesPageMobileFooter
                    searchValue={search}
                    onSearch={handleSearch}
                    filters={filters}
                    onSetInvoicesFilters={handleSetInvoicesFilters}
                    isClientPage
                />
            </Content>
        </Column>
    );
}

export default ClientInvoicesTable;
