// extracted by mini-css-extract-plugin
var _1 = "AddClientsDialog_body__5NaXX";
var _2 = "AddClientsDialog_clientsSelect__59mjh";
var _3 = "AddClientsDialog_footer__7RE9Q";
var _4 = "AddClientsDialog_grayColor__3REk7";
var _5 = "AddClientsDialog_messageName__SQn60";
var _6 = "AddClientsDialog_option__xSDXi";
var _7 = "AddClientsDialog_sendAlsoToEmailTooltip__66o0Z";
var _8 = "AddClientsDialog_yellowColor__R-kex";
export { _1 as "body", _2 as "clientsSelect", _3 as "footer", _4 as "grayColor", _5 as "messageName", _6 as "option", _7 as "sendAlsoToEmailTooltip", _8 as "yellowColor" }
