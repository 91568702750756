import { createSelector } from 'reselect';

export const financialsSelector = (state) => state.financials;

export const invoicesListSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.invoices,
);

export const payoutsListSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.payouts,
);

export const clearingsListSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.clearings,
);
export const multisafePayoutsListSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.multisafePayouts,
);

export const invoicesSavingSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.invoices.saving,
);

export const customInvoicesLoadingSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.invoices.customInvoicesLoading,
);

export const customInvoicesSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.invoices.customInvoices,
);

export const customInvoiceLoadingSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.invoices.customInvoiceLoading,
);

export const customInvoiceSelector = createSelector(
    financialsSelector,
    (financialsState) => financialsState.invoices.customInvoice,
);
