import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import SearchInput2 from 'components/Common/SearchInput2';
import { Button } from 'components/Common/Button';
import Container from 'components/Layout/Container/Container';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import Switch from 'components/Switch/Switch';
import { Badge } from 'components/Common/Badge';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'components/Common/Tooltip';
import SalesInfo from '../SalesInfo';
import { SubscriptionsSoldTitleFilter } from '../SubscriptionsSoldTitleFilter';
import { SubscriptionsSoldStatusFilter } from '../SubscriptionsSoldStatusFilter';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

const buttonTitleOptions = {
    list: 'placeholders.addSubscription',
    sold: 'placeholders.sellSubscription',
};

const pageTitleOptions = {
    list: 'subscriptions.pageTitle.subscriptionsList',
    sold: 'subscriptions.pageTitle.soldSubscriptions',
};

function SubscriptionsHeader(props) {
    const {
        subscriptionsStatus,
        showSalesInfo,
        searchValue,
        onSearch,
        onButtonClickHandler,
        exportButton,
        showSubscriptionsFilter,
        onIdFilterChange,
        onStatusFilterChange,
        hideInactive,
        handleTogglehHideInactive,
    } = props;
    const { t } = useTranslation();
    const { t: ts } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { pathname } = useLocation();
    const [currentSubRoute] = pathname.split('/').slice(-1);
    const isMobile = useMobile();

    return (
        <React.Fragment>
            <TextSubHeader
                text={(
                    <Row gap={8}>
                        {t(pageTitleOptions[currentSubRoute])}
                        {showSalesInfo
                            && (
                                <Tooltip
                                    tooltip={t('subscriptions.salesInfo.totalNumber.tooltip')}
                                    placement="bottom"
                                    forButton
                                >
                                    <Badge color="gray" size="extra-small">
                                        {subscriptionsStatus?.activeCount ?? <Skeleton height={16} width={21} />}
                                    </Badge>
                                </Tooltip>
                            )}
                    </Row>
                )}
                after={
                    !isMobile && <SearchInput2 searchValue={searchValue} onSearch={onSearch} />
                }
                rightActions={[exportButton,
                    !isMobile && (
                        <Button
                            onClick={onButtonClickHandler}
                        >
                            {t(buttonTitleOptions[currentSubRoute])}
                        </Button>
                    ),
                ]}
            />
            {(showSubscriptionsFilter || showSalesInfo) && (
                <Container>
                    {!isMobile
                        && (
                            <Column gap={32} stretched>
                                <SalesInfo
                                    subscriptionsStatus={subscriptionsStatus}
                                    showSalesInfo={showSalesInfo}
                                />
                                {showSubscriptionsFilter && (
                                    <Row gap={16} wrap>
                                        <Header>
                                            {ts('sold.filters.title')}
                                        </Header>
                                        <SubscriptionsSoldStatusFilter
                                            onChange={onStatusFilterChange}
                                        />
                                        <SubscriptionsSoldTitleFilter
                                            onChange={onIdFilterChange}
                                        />
                                        <Switch
                                            checked={hideInactive}
                                            onChange={handleTogglehHideInactive}
                                            label={ts('sold.filters.hideArchived')}
                                        />
                                    </Row>
                                )}
                            </Column>
                        )}
                    {isMobile
                        && (
                            <SalesInfo
                                subscriptionsStatus={subscriptionsStatus}
                                showSalesInfo={showSalesInfo}
                            />
                        )}
                </Container>
            )}
        </React.Fragment>
    );
}

SubscriptionsHeader.propTypes = {
    subscriptionsStatus: PropTypes.object,
    showSalesInfo: PropTypes.bool,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    onButtonClickHandler: PropTypes.func,
    exportButton: PropTypes.node,
    showSubscriptionsFilter: PropTypes.bool,
    onIdFilterChange: PropTypes.func,
    onStatusFilterChange: PropTypes.func,
    hideInactive: PropTypes.bool,
    handleTogglehHideInactive: PropTypes.func,
};

export default SubscriptionsHeader;
