import ActivityList from './list';

import {
    ACTIVITY_LOGS_PAGE,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: ActivityList,
        exact: true,
        path: ACTIVITY_LOGS_PAGE,
    },
];

export default routes;
