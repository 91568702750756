import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    ManualIcon,
    Pen2Icon, Sent2Icon, ShoppingCartIcon,
} from 'components/Icon/Icon';

import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { FINANCIALS_INVOICES_ADD_PAGE, MESSAGES_ADD_PAGE } from 'const/CLIENT_URL';
import { Dropdown } from 'react-bootstrap';
import { Row } from 'components/Common/Row';
import history from 'services/history';
import { BackButton } from 'components/Common/BackButton';
import { useMobile } from 'hooks/useMobile';
import { IconButton } from 'components/Common/IconButton';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'profile.header';

const ClientPageHeader = (props) => {
    const {
        client,
        onBack,
        onEdit,
        onSellBundle,
        onSellSubscription,
    } = props;

    const { fullName } = client || {};
    const { firstName, lastName } = fullName || {};
    const isMobile = useMobile();

    const fullNameLabel = `${firstName} ${lastName}`;

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const handleEdit = useCallback(() => {
        if (!client.id) {
            return;
        }
        onEdit({ clientId: client.id });
    }, [client.id, onEdit]);

    const handleSellService = useCallback((type) => {
        if (!client.id) {
            return;
        }
        switch (type) {
        case 'bundle':
            onSellBundle({ clientId: client.id });
            break;
        case 'subscription':
            onSellSubscription({ clientId: client.id });
            break;
        default:
            break;
        }
    }, [client.id, onSellBundle, onSellSubscription]);

    const AddButton = isMobile ? (
        <IconButton
            id="sendMessage"
            color="outline"
            onClick={() => {
                history.push(MESSAGES_ADD_PAGE, { selectedClientsIds: [client.id] });
            }}
            size={40}
        >
            <Sent2Icon />
        </IconButton>
    ) : (
        <Button
            id="sendMessage"
            color="outline"
            before={<Sent2Icon />}
            onClick={() => {
                history.push(MESSAGES_ADD_PAGE, { selectedClientsIds: [client.id] });
            }}
        >
            {t(`${T_PREFIX}.actions.sendMessage.label`)}
        </Button>
    );

    const EditButton = isMobile ? (
        <IconButton
            color="outline"
            onClick={handleEdit}
            size={40}
        >
            <Pen2Icon />
        </IconButton>
    ) : (
        <Button
            color="outline"
            onClick={handleEdit}
            before={<Pen2Icon />}
        >
            {t(`${T_PREFIX}.actions.edit.label`)}
        </Button>
    );

    const InvoiceButton = isMobile ? (
        <IconButton
            color="outline"
            onClick={() => {
                history.push(FINANCIALS_INVOICES_ADD_PAGE, { selectedClientId: client.id });
            }}
            size={40}
        >
            <ManualIcon />
        </IconButton>
    ) : (
        <Button
            color="outline"
            onClick={() => {
                history.push(FINANCIALS_INVOICES_ADD_PAGE, { selectedClientId: client.id });
            }}
            before={<ManualIcon />}
        >
            {t(`${T_PREFIX}.actions.createInvoice.label`)}
        </Button>
    );

    return (
        <TextSubHeader
            text={fullNameLabel}
            before={(
                <BackButton
                    onClick={onBack}
                />
            )}
            rightActions={(
                [
                    AddButton,
                    EditButton,
                    InvoiceButton,
                    <Dropdown
                        onSelect={handleSellService}
                    >
                        <Dropdown.Toggle
                            style={{ height: '40px' }}
                            variant="primary no-arrow p-20px"
                        >
                            <Row gap={8}>
                                <ShoppingCartIcon />
                                <span>{t(`${T_PREFIX}.actions.sellService.label`)}</span>
                            </Row>
                        </Dropdown.Toggle>
                        <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
                            {['bundle', 'subscription'].map((type) => (
                                <Dropdown.Item
                                    key={`route-${type}`}
                                    eventKey={type}
                                >
                                    {t(`${T_PREFIX}.actions.${type}.label`)}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>]
            )}
        />
    );
};

ClientPageHeader.propTypes = {
    client: PropTypes.object,
    onBack: PropTypes.func,
    onEdit: PropTypes.func,
    onSellBundle: PropTypes.func,
    onSellSubscription: PropTypes.func,
};

ClientPageHeader.defaultProps = {
    client: null,
    onBack: emptyFunc,
    onEdit: emptyFunc,
    onSellBundle: emptyFunc,
    onSellSubscription: emptyFunc,
};

export default ClientPageHeader;
