import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { instantFormValidation } from 'helpers/input/instantFieldValidation';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as COMPANY_SELECTORS from 'store/selectors/company';

import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';

import { getError, isInvalid } from 'services/validationService';
import { Trash3Icon } from 'components/Icon/Icon';
import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { useSelector } from 'react-redux';
import { handleKeyDownOnPriceInput } from 'helpers/input/handleKeyDownOnNumberInput';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { CHAR_SYMBOL } from 'const/string/CHAR_SYMBOL';
import ProductsSelect from 'components/products/ProductsSelect/ProductsSelect';
import { productsListSelector } from 'store/selectors/products';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { IconButton } from 'components/Common/IconButton';
import NumberInput from 'components/Common/NumberInput';
import { Column } from 'components/Common/Column';
import { Label } from 'components/Common/Typography/Label';
import { InvoiceProductDeleteDialog } from '../../InvoiceProductDeleteDialog';

const isSame = (initialProduct, product) => initialProduct && product
    && initialProduct.name === product.name
    && initialProduct.price === product.price
    && initialProduct.vatValue === product.vatValue;

function InvoiceProductFormGroup(props) {
    const {
        index,
        product,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        onDelete,
        isDeleteDisabled,
    } = props;

    const [deleteProduct, setDeleteProduct] = useState(null);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);
    const products = useSelector(productsListSelector);

    const initialProductData = useRef(null);

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    useEffect(() => {
        const initialProduct = initialProductData.current;

        if (initialProduct) {
            if (String(product.id).startsWith('new-') && isSame(initialProduct, product)) {
                setFieldValue(`products[${index}].id`, initialProduct.id);
                return;
            }

            if (!String(product.id).startsWith('new-') && !isSame(initialProduct, product)) {
                setFieldValue(`products[${index}].id`, `new-${initialProduct.id}`);
                return;
            }
        }

        if (!String(product.id).startsWith('new-')) {
            initialProductData.current = product;
        }
    }, [product]);

    const handleQuantityChange = useCallback((value) => {
        setFieldValue(`products[${index}].quantity`, value);
    }, [setFieldValue]);

    const handlePriceChange = useCallback((e) => {
        const { target } = e;
        const { value } = target;
        if ([
            CHAR_SYMBOL.MINUS,
            CHAR_SYMBOL.PRICE.SEPARATOR.DOT,
        ].includes(value)) {
            return;
        }
        if (value === '') {
            setFieldValue(`products[${index}].price`, '');
            return;
        }
        const val = Math.min(Math.max(INVOICE_PROP.PRICE.MIN, value), INVOICE_PROP.PRICE.MAX);
        const muv = Math.trunc((val * 100).toFixed(2)) / 100;
        setFieldValue(`products[${index}].price`, muv);
    }, [setFieldValue, setFieldTouched]);

    const handleDelete = useCallback(() => {
        onDelete({ id: product.id, index });
    }, [onDelete, product.id, index]);

    const handleDeleteProduct = useCallback(() => {
        setDeleteProduct(null);
    }, []);

    const handleVATChange = useCallback((selected) => {
        setFieldValue(`products[${index}].vatValue`, selected.value);
    }, [setFieldValue, setFieldTouched, index]);

    const validationHandler = instantFormValidation({ setFieldTouched, touched });

    return (
        <>
            <Row
                className="mt-3 pb-3"
            >
                <Form.Group
                    as={Col}
                    sm={12}
                    xl={4}
                >
                    <ProductsSelect
                        product={product}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        products={products}
                        index={index}
                        onDelete={setDeleteProduct}
                    />
                </Form.Group>

                <Col>
                    <Row>
                        <Form.Group
                            as={Col}
                        >
                            <Column gap={0}>
                                <Label htmlFor={`quantity-${index}`} paddingBottom>
                                    {t('add.products.quantity.label')}
                                </Label>
                                <NumberInput
                                    id={`quantity-${index}`}
                                    value={product.quantity}
                                    onChange={handleQuantityChange}
                                    isInvalid={isInvalid(`products[${index}].quantity`, errors, touched)}
                                    max={INVOICE_PROP.QUANTITY.MAX}
                                    min={INVOICE_PROP.QUANTITY.MIN}
                                />

                                <Form.Control.Feedback
                                    type="invalid"
                                    className={classNames({
                                        'd-flex': isInvalid(`products[${index}].quantity`, errors, touched),
                                    })}
                                >
                                    {getError(`products[${index}].quantity`, errors)}
                                </Form.Control.Feedback>
                            </Column>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                        >
                            <Label htmlFor={`price-${index}`} paddingBottom>
                                {t('add.products.price.label')}
                            </Label>
                            <Form.Control
                                id={`price-${index}`}
                                type="number"
                                name="price"
                                value={product.price}
                                isInvalid={isInvalid(`products[${index}].price`, errors, touched)}
                                onChange={validationHandler(handlePriceChange)}
                                onKeyDown={handleKeyDownOnPriceInput}
                            />

                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-flex': isInvalid(`products[${index}].price`, errors, touched),
                                })}
                            >
                                {getError(`products[${index}].price`, errors)}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Form.Group
                    as={Col}
                    sm={12}
                    lg={3}
                    xl={2}
                >
                    <Label htmlFor={`vatValue-${index}`} paddingBottom>
                        {t('add.products.vatValue.label')}
                    </Label>
                    <Select
                        id={`vatValue-${index}`}
                        styles={selectStyles}
                        theme={selectTheme}
                        name="vatValue"
                        value={getVATRateSelectOptions(enableBusinessMode).find(({ value }) => value === product.vatValue)}
                        options={getVATRateSelectOptions(enableBusinessMode)}
                        onChange={handleVATChange}
                    />
                </Form.Group>
                <Col
                    sm={12}
                    lg={3}
                    xl={2}
                    className="d-flex mt-lg-4"
                    style={{ paddingTop: 10 }}
                >
                    <div className="flex-grow-1">
                        <div className="d-flex flex-column">
                            <Label htmlFor={`total-${index}`} style={{ lineHeight: 1 }} weight="light" color="gray">
                                {t('add.products.total.label')}
                            </Label>
                            <Label id={`total-${index}`}>
                                {formatPrice.toEuroWithComma({ amount: (Number(product.price) * Number(product.quantity)) })}
                            </Label>
                        </div>
                    </div>
                    <IconButton
                        type="button"
                        color="gray"
                        onClick={handleDelete}
                        size={36}
                        disabled={isDeleteDisabled}
                    >
                        <Trash3Icon />
                    </IconButton>
                </Col>
            </Row>
            <InvoiceProductDeleteDialog productId={deleteProduct} onClose={handleDeleteProduct} />
        </>
    );
}

export default InvoiceProductFormGroup;
