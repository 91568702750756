const prefix = '@SUBSCRIPTIONS';

export const ADD_SUBSCRIPTION = `${prefix}__ADD_SUBSCRIPTION`;
export const GET_SUBSCRIPTIONS_LIST = `${prefix}__GET_SUBSCRIPTIONS_LIST`;
export const SET_SUBSCRIPTIONS_LIST = `${prefix}__SET_SUBSCRIPTIONS_LIST`;
export const LOAD_SUBSCRIPTIONS_LIST_OPTIONS = `${prefix}__LOAD_SUBSCRIPTIONS_LIST_OPTIONS`;
export const DELETE_SUBSCRIPTION = `${prefix}__DELETE_SUBSCRIPTION`;
export const GET_SOLD_SUBSCRIPTIONS_LIST = `${prefix}__GET_SOLD_SUBSCRIPTIONS_LIST`;
export const SET_SOLD_SUBSCRIPTIONS_LIST = `${prefix}__SET_SOLD_SUBSCRIPTIONS_LIST`;
export const SET_IS_DATA_LOADED = `${prefix}__SET_IS_DATA_LOADED`;
export const SET_PAGES_COUNT = `${prefix}__SET_PAGES_COUNT`;
export const SET_ITEMS_COUNT = `${prefix}__SET_ITEMS_COUNT`;
export const TOGGLE_SUBSCRIPTION_STATUS = `${prefix}__TOGGLE_SUBSCRIPTION_STATUS`;
export const UPDATE_SUBSCRIPTION_STATUS = `${prefix}__UPDATE_SUBSCRIPTION_STATUS`;
export const EDIT_SUBSCRIPTION = `${prefix}__EDIT_SUBSCRIPTION`;
export const SET_SHOW_ALL = `${prefix}__SET_SHOW_ALL`;
export const SET_CURRENT_PAGE = `${prefix}__SET_CURRENT_PAGE`;
export const SET_SEARCH_VALUE = `${prefix}__SET_SEARCH_VALUE`;
export const SET_STATUS_FILTER = `${prefix}__SET_STATUS_FILTER`;
export const SET_ID_FILTER = `${prefix}__SET_ID_FILTER`;
export const GET_SUBSCRIPTIONS_OPTIONS = `${prefix}__GET_SUBSCRIPTIONS_OPTIONS`;
export const SET_SUBSCRIPTIONS_OPTIONS = `${prefix}__SET_SUBSCRIPTIONS_OPTIONS`;
export const GET_SELL_SUBSCRIPTION_DATA = `${prefix}__GET_SELL_SUBSCRIPTION_DATA`;
export const SET_SELL_SUBSCRIPTION_DATA = `${prefix}__SET_SELL_SUBSCRIPTION_DATA`;
export const SELL_SUBSCRIPTION = `${prefix}__SELL_SUBSCRIPTION`;
export const CHANGE_SOLD_SUBSCRIPTION = `${prefix}__CHANGE_SOLD_SUBSCRIPTION`;
export const RESET_SELL_SUBSCRIPTION = `${prefix}__RESET_SELL_SUBSCRIPTION`;
export const SET_SOLD_SUBSCRIPTIONS_STATUS = `${prefix}__SET_SOLD_SUBSCRIPTIONS_STATUS`;
export const GET_ALL_SUBSCRIPTIONS = `${prefix}__GET_ALL_SUBSCRIPTIONS`;
export const TOGGLE_SUBSCRIPTION_PAYMENT_STATUS = `${prefix}__TOGGLE_SUBSCRIPTION_PAYMENT_STATUS`;
export const UPDATE_SUBSCRIPTION_PAYMENT_INFO = `${prefix}__UPDATE_SUBSCRIPTION_PAYMENT_INFO`;
export const TOGGLE_USER_LOCK = `${prefix}__TOGGLE_USER_LOCK`;
export const UPDATE_SUBSCRIPTION_USER_INFO = `${prefix}__UPDATE_SUBSCRIPTION_USER_INFO`;
export const SET_SOLD_SUBSCRIPTION_BOOKINGS = `${prefix}__SET_SOLD_SUBSCRIPTION_BOOKINGS`;
export const TOGGLE_CANCEL_SOLD_SUBSCRIPTION_MODAL = `${prefix}__TOGGLE_CANCEL_SOLD_SUBSCRIPTION_MODAL`;
export const TOGGLE_CANCEL_SOLD_SUBSCRIPTION_WITH_BOOKINGS_MODAL = `${prefix}__TOGGLE_CANCEL_SOLD_SUBSCRIPTION_WITH_BOOKINGS_MODAL`;
export const SET_CANCEL_SOLD_SUBSCRIPTION_END_DATE = `${prefix}__SET_CANCEL_SOLD_SUBSCRIPTION_END_DATE`;
export const CANCEL_SOLD_SUBSCRIPTION = `${prefix}__CANCEL_SOLD_SUBSCRIPTION`;
export const RESET_CANCEL_SOLD_SUBSCRIPTION = `${prefix}__RESET_CANCEL_SOLD_SUBSCRIPTION`;
export const RESET_SUBSCRIPTIONS_REDUCER = `${prefix}__RESET_SUBSCRIPTIONS`;
export const OPEN_EDIT_SUBSCRIPTION_USAGES_MODAL = `${prefix}__OPEN_EDIT_SUBSCRIPTION_USAGES_MODAL`;
export const GET_SUBSCRIPTION_USAGES_LIST = `${prefix}__GET_SUBSCRIPTION_USAGES_LIST`;
export const SET_SUBSCRIPTION_USAGES_LIST = `${prefix}__SET_SUBSCRIPTION_USAGES_LIST`;
export const RESET_EDIT_SUBSCRIPTION_USAGES = `${prefix}__RESET_EDIT_SUBSCRIPTION_USAGES`;
export const GET_SUBSCRIPTION_PERIODS_USAGES = `${prefix}__GET_SUBSCRIPTION_PERIODS_USAGES`;
export const SOLD_SUBSCRIPTIONS_ITEM_RESEND_PAYMENT = `${prefix}__SOLD_SUBSCRIPTIONS_ITEM_RESEND_PAYMENT`;
export const SHOW_BOOKINGS_TO_CANCELING_MODAL = `${prefix}__SHOW_BOOKINGS_TO_CANCELING_MODAL`;
export const RESET_BOOKINGS_TO_CANCELING = `${prefix}__RESET_BOOKINGS_TO_CANCELING`;
export const RESET_CHANGE_SOLD_SUBSCRIPTION = `${prefix}__RESET_CHANGE_SOLD_SUBSCRIPTION`;

const SOLD_SUBSCRIPTIONS = `${prefix}/SOLD_SUBSCRIPTIONS`;
const SOLD_SUBSCRIPTIONS_ITEM = `${SOLD_SUBSCRIPTIONS}:ITEM`;
export const SOLD_SUBSCRIPTIONS_ITEM_PAUSE = `${SOLD_SUBSCRIPTIONS_ITEM}:PAUSE`;
export const SOLD_SUBSCRIPTIONS_ITEM_RESUME = `${SOLD_SUBSCRIPTIONS_ITEM}:RESUME`;
export const SOLD_SUBSCRIPTIONS_ITEM_REMOVE = `${SOLD_SUBSCRIPTIONS_ITEM}:REMOVE`;
const SOLD_SUBSCRIPTIONS_ITEM_BOOKINGS = `${SOLD_SUBSCRIPTIONS_ITEM}:BOOKINGS`;
export const SOLD_SUBSCRIPTIONS_ITEM_BOOKINGS_GET = `${SOLD_SUBSCRIPTIONS_ITEM_BOOKINGS}:GET`;
export const SOLD_SUBSCRIBTIONS_REQEST_EXPORT = `${SOLD_SUBSCRIPTIONS}:EXPORT`;

export const SET_SOLD_IS_DATA_LOADED = `${prefix}__SET_SOLD_IS_DATA_LOADED`;
export const SET_SOLD_SHOW_ALL = `${prefix}__SET_SOLD_SHOW_ALL`;
export const SET_SOLD_CURRENT_PAGE = `${prefix}__SET_SOLD_CURRENT_PAGE`;
export const SET_SOLD_SEARCH_VALUE = `${prefix}__SET_SOLD_SEARCH_VALUE`;
export const RESET_SOLD_SUBSCRIPTIONS_REDUCER = `${prefix}__RESET_SOLD_SUBSCRIPTIONS`;

export const CHECK_SUBSCRIPTION = `${prefix}__CHECK_SUBSCRIPTION`;

export const GET_SOLD_SUBSCRIPTIONS_LIST_OPTIONS = `${prefix}__GET_SOLD_SUBSCRIPTIONS_LIST_OPTIONS`;
export const SET_SOLD_HIDE_INACTIVE = `${prefix}__SET_SOLD_HIDE_INACTIVE`;

export const GET_SOLD_SUBSCRIPTIONS_STATISTICS = `${prefix}__GET_SOLD_SUBSCRIPTIONS_STATISTICS`;
