import {
    put, select, call, takeEvery,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

// Actions
import moment from 'moment';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import camelize from 'camelize';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import * as actions from '../actions';

import * as TOAST_ACTIONS from '../actions/toast';

// Utils
import axios from '../../services/axios';
import sentry from '../../services/sentry';

import { HTTP_METHOD } from '../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../const/http/HTTP_STATUS_CODE';

import {
    API_ADMIN_SHOP_BLOCK_EDIT_ROUTE,
    API_ADMIN_SHOP_BLOCK_DELETE_ROUTE,
    API_ADMIN_PRODUCTS_ROUTE,
    API_ADMIN_PRODUCTS_ARCHIVE_ID_ROUTE,
} from '../../const/API_URL';

export function* addTimeBlock(action) {
    const { block } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();
        if (block.time.wholeDay) {
            data.append('from', block.time.from.clone().startOf('day').unix().toString());
            data.append('to', block.time.from.clone().endOf('day').unix().toString());
        } else {
            data.append('from', block.time.from.unix());
            data.append('to', block.time.to.unix());
        }
        data.append('note', block.note);

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/${block.product.id}/add/block`,
            data,
            headers: defaultHeaders,
        });

        const { id } = resp.data.result;

        yield put(actions.addTimeBlockSucceeded({
            ...block,
            id,
        }));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.result && err.response.data.result.errors) {
            const { errors } = err.response.data.result;

            yield call(rejectPromiseAction, action, {
                errors: {
                    time: {
                        from: errors.from,
                        to: errors.to,
                    },
                    note: errors.note,
                },
                message: null,
            });
            return;
        }

        if (err.response && err.response.data && err.response.data.result) {
            const { message } = err.response.data.result;
            yield call(rejectPromiseAction, action, {
                errors: null,
                message,
            });
            return;
        }

        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

export function* getProduct(action) {
    try {
        const { productId, type } = action.payload;
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const { data: { result } } = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/get`,
            params: { productId, type },
            headers: defaultHeaders,
        });

        const product = {
            id: result.id,
            resourceId: result.resource_id,
            name: result.name,
            description: result.description,
            image: result.photo_url,
            maintenanceMode: result.maintenance_mode,
            timeslotRecalc: result.timeslot_recalc,
            canSendEmail: result.can_send_email,
            hasOwnSchedule: result.has_own_schedule,
            address: {
                street: result.street,
                zip: result.zip,
                city: result.city,
                country: result.country,
                latitude: result.latitude,
                longitude: result.longitude,
            },
            sessions: result.group_sessions?.map((item) => ({
                address: {
                    street: item.street,
                    zip: item.zip,
                    city: item.city,
                    country: item.country,
                    latitude: item.latitude,
                    longitude: item.longitude,
                },
                contactNumber: item.contact_number,
                id: item.id,
                isCancelled: item.isCancelled,
                places: {
                    show: item.show_sessions_left,
                    total: item.total,
                },
                price: Number(item.cost),
                product: {
                    id: item.employee_id,
                    name: item.employee_name,
                    type: 'employee',
                },
                service: {
                    id: item.serviceId,
                    name: item.serviceName,
                },
                time: {
                    from: moment(item.time * 1000).isoWeekday(moment(item.weekday, 'dddd').isoWeekday()).valueOf(),
                    to: moment(item.time * 1000).isoWeekday(moment(item.weekday, 'dddd').isoWeekday()).add(item.duration, 'minutes').valueOf(),
                },
            })),
            workingDays: result.working_days,
            services: result.services ?? [],
            startDate: result.start_date ? result.start_date * 1000 : null,
            endDate: result.end_date ? result.end_date * 1000 : null,
            futureBooking: result.future_booking ? result.future_booking * 1000 : null,
            employee: result?.employee,
            videoURL: result?.video_url,
            showSessionsLeft: result?.show_sessions_left,
            allowLateBooking: result?.allow_late_booking,
            street: result?.street,
            zip: result?.zip,
            city: result?.city,
            country: result?.country,
            latitude: result?.latitude,
            longitude: result?.longitude,
            enableWaitlist: result?.enable_waitlist,
        };

        yield put(actions.getProductByIdSucceeded(product, type));

        yield call(resolvePromiseAction, action, result);
    } catch (e) {
        yield put(actions.getProductByIdFailed());

        yield call(rejectPromiseAction, action);
    }
}

export function* editTimeBlock(action) {
    const { block } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopId = yield select((state) => state.shop.id);

        const blockData = new FormData();
        if (block.time.wholeDay) {
            blockData.append('from', block.time.from.clone().startOf('day').unix().toString());
            blockData.append('to', block.time.from.clone().endOf('day').unix().toString());
        } else {
            blockData.append('from', block.time.from.unix());
            blockData.append('to', block.time.to.unix());
        }
        blockData.append('note', block.note);

        const res = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_SHOP_BLOCK_EDIT_ROUTE({ shopId, productId: block.product.id, blockId: block.blockId }),
            data: blockData,
            headers: defaultHeaders,
        });

        const { code } = res.data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(actions.editTimeBlockSucceeded({
                ...block,
                id: res.data.result.id,
            }));
            yield call(resolvePromiseAction, action);
            yield put(TOAST_ACTIONS.showToast({
                message: 'blockModal.edit.success',
                appearance: 'success',
            }));
        }
    } catch (err) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'blockModal.edit.error',
                appearance: 'error',
            }));
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

function* deleteTimeBlock(action) {
    const { payload } = action;
    const { block } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    const shopId = yield select((state) => state.shop.id);

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_SHOP_BLOCK_DELETE_ROUTE({ shopId, productId: block.product.id, blockId: block.blockId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'blockModal.delete.success',
                appearance: 'success',
            }));
            yield put(actions.deleteTimeBlockSucceeded({
                ...block,
            }));
            yield call(resolvePromiseAction, action);
        }
    } catch (error) {
        sentry.error(error);
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'blockModal.delete.error',
                appearance: 'error',
            }));
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

// API_ADMIN_PRODUCTS_ROUTE
export function* getProducts(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_PRODUCTS_ROUTE,
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const products = camelize(resp.data.result);
        yield call(resolvePromiseAction, action, products);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export function* deleteProduct(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_PRODUCTS_ARCHIVE_ID_ROUTE({ productId: action.payload.id }),
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        yield call(resolvePromiseAction, action, action.payload);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export const productsSaga = [
    takeEvery(actions.addTimeBlock, addTimeBlock),
    takeEvery(actions.getProductById, getProduct),
    takeEvery(actions.editTimeBlock, editTimeBlock),
    takeEvery(actions.deleteTimeBlock, deleteTimeBlock),
    takeEvery(actions.getProducts, getProducts),
    takeEvery(actions.deleteProduct, deleteProduct),
];
