import React, { useEffect, useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ClientsSelect from 'components/clients/ClientsSelect/ClientsSelect';
import { DateInput } from 'components/Common/DateInput';
import { CalendarIcon } from 'components/Icon/Icon';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import classNames from 'classnames';
import { getError, isInvalid } from 'services/validationService';
import { Row } from 'components/Common/Row';

function InvoicesInfoFormGroup({ formik, fixedClientId }) {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const {
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
    } = formik;

    const total = useMemo(
        () => values.products.reduce((acc, product) => {
            const price = Number(product.price);
            const quantity = Number(product.quantity);
            const productTotal = price * quantity;
            return acc + productTotal;
        }, 0),
        [values.products],
    );

    useEffect(() => {
        if (values.status === INVOICE_STATUS.UNPAID && !total) {
            setFieldValue('status', INVOICE_STATUS.DRAFT);
        }
    }, [values.status, total]);

    return (
        <div className="d-flex flex-column flex-lg-row justify-content-between px-4 px-lg-0 mb-3">
            <Col className="px-2 pl-lg-0 pr-lg-3">
                <ClientsSelect formik={formik} fixedClientId={fixedClientId} />
                <Form.Group>
                    <Form.Label htmlFor="note" className="d-flex">
                        {t('add.note.label')}
                        <InfoTooltip
                            text={t('add.note.tooltip')}
                            placement="top"
                        />
                    </Form.Label>
                    <Form.Control
                        id="note"
                        as="textarea"
                        rows={3}
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        maxLength={INVOICE_PROP.NOTE.MAX_LENGTH}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-flex': isInvalid('note', errors, touched),
                        })}
                    >
                        {getError('note', errors)}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col className="px-2 pr-lg-0 pl-lg-3">
                <div className="d-flex flex-column flex-lg-row justify-content-between px-lg-0 mb-3">
                    <Form.Group className="w-100">
                        <Form.Label htmlFor="issueDate">
                            {t('add.issueDate.label')}
                        </Form.Label>
                        <DateInput
                            id="issueDate"
                            name="issueDate"
                            value={values.issueDate}
                            className="text-nowrap"
                            iconComponent={CalendarIcon}
                            isDisabled
                            format="DD.MM.YYYY"
                        />
                    </Form.Group>
                    <Form.Group className="w-100 pl-lg-3">
                        <Form.Label htmlFor="dueDate">
                            {t('add.dueDate.label')}
                        </Form.Label>
                        <DateInput
                            id="dueDate"
                            name="dueDate"
                            value={values.dueDate}
                            className="text-nowrap"
                            iconComponent={CalendarIcon}
                            isDisabled
                            format="DD.MM.YYYY"
                        />
                    </Form.Group>
                </div>
                <div style={{ marginTop: 28 }}>
                    <span
                        className="d-flex align-items-center mb-4 font-weight-600"
                    >
                        {t('add.status.label')}
                    </span>
                    <Row gap={16} wrap>
                        <Form.Check
                            id="draft"
                            name="status"
                            type="radio"
                            label={(
                                <Form.Label
                                    className="d-flex align-items-center m-0 cursor-pointer font-weight-normal"
                                    htmlFor="draft"
                                >
                                    {t('add.status.draft.label')}
                                    <span className="text-muted ml-2">
                                        {t('add.status.draft.subLabel')}
                                    </span>
                                    <InfoTooltip
                                        text={t('add.status.draft.tooltip')}
                                        placement="bottom"
                                    />
                                </Form.Label>
                            )}
                            checked={values.status === INVOICE_STATUS.DRAFT}
                            value={INVOICE_STATUS.DRAFT}
                            onChange={handleChange}
                        />
                        {Boolean(values.client?.clientEmail) && (
                            <Form.Check
                                id="unpaid"
                                name="status"
                                type="radio"
                                label={(
                                    <Form.Label
                                        className="d-flex align-items-center m-0 cursor-pointer font-weight-normal"
                                        htmlFor="unpaid"
                                    >
                                        {t('add.status.unpaid.label')}
                                        <span className="text-muted ml-2">
                                            {t('add.status.unpaid.subLabel')}
                                        </span>
                                        <InfoTooltip
                                            text={t('add.status.unpaid.tooltip')}
                                            placement="bottom"
                                        />
                                    </Form.Label>
                                )}
                                checked={values.status === INVOICE_STATUS.UNPAID}
                                value={INVOICE_STATUS.UNPAID}
                                onChange={handleChange}
                                className="font-weight-normal"
                                disabled={!total}
                            />
                        )}
                        <Form.Check
                            id="paid"
                            name="status"
                            type="radio"
                            label={(
                                <Form.Label
                                    className="d-flex align-items-center m-0 cursor-pointer font-weight-normal"
                                    htmlFor="paid"
                                >
                                    {t('add.status.paid.label')}
                                    <span className="text-muted ml-2">
                                        {t('add.status.paid.subLabel')}
                                    </span>
                                    <InfoTooltip
                                        text={t('add.status.paid.tooltip')}
                                        placement="bottom"
                                    />
                                </Form.Label>
                            )}
                            checked={values.status === INVOICE_STATUS.PAID}
                            value={INVOICE_STATUS.PAID}
                            onChange={handleChange}
                            className="font-weight-normal"
                        />
                    </Row>
                </div>
            </Col>
        </div>
    );
}

export default InvoicesInfoFormGroup;
