import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components/Common/IconButton';
import { Plus } from 'components/Icon/Icon';
import { Footer, SearchInput } from '../../index';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { BUNDLES_ADD_PAGE } from '../../../const/CLIENT_URL';

import * as styles from './BundlesListFooter.module.scss';

const BundlesListFooter = (props) => {
    const {
        search,
        onAdd,
        onSearch,
    } = props;

    const handleSearch = useCallback((nextSearch) => {
        onSearch({ search: nextSearch });
    }, [onSearch]);

    return (
        <Footer>
            <div className="d-flex d-lg-none flex-row">
                <SearchInput
                    searchValue={search}
                    onSearch={handleSearch}
                />
                <div className={styles.divider} />
                <IconButton
                    type="button"
                    href={BUNDLES_ADD_PAGE}
                    color="white"
                    size={60}
                    onClick={onAdd}
                >
                    <Plus />
                </IconButton>
            </div>
        </Footer>
    );
};

BundlesListFooter.propTypes = {
    search: PropTypes.string,
    onAdd: PropTypes.func,
    onSearch: PropTypes.func,
};

BundlesListFooter.defaultProps = {
    search: '',
    onAdd: emptyFunc,
    onSearch: emptyFunc,
};

export default BundlesListFooter;
