// extracted by mini-css-extract-plugin
var _1 = "Typography_black__Y7Eb2";
var _2 = "Typography_blue__jKl4D";
var _3 = "Typography_bold__3Fp6H";
var _4 = "Typography_breakWord__Vjl+2";
var _5 = "Typography_capitalize__H8Dst";
var _6 = "Typography_center__B6Tkl";
var _7 = "Typography_ellipsis__b-drt";
var _8 = "Typography_gray__Oa0uJ";
var _9 = "Typography_green__wilRe";
var _a = "Typography_italic__PQbqn";
var _b = "Typography_left__4FPIr";
var _c = "Typography_light__3VyvF";
var _d = "Typography_lineThrough__h5vwM";
var _e = "Typography_noWrap__EAOyf";
var _f = "Typography_normal__qycfz";
var _10 = "Typography_pointer__8O9sa";
var _11 = "Typography_red__VS8Xc";
var _12 = "Typography_right__p+6je";
var _13 = "Typography_typography__7bcu+";
var _14 = "Typography_uppercase__iV6GP";
var _15 = "Typography_yellow__pSXZB";
export { _1 as "black", _2 as "blue", _3 as "bold", _4 as "breakWord", _5 as "capitalize", _6 as "center", _7 as "ellipsis", _8 as "gray", _9 as "green", _a as "italic", _b as "left", _c as "light", _d as "lineThrough", _e as "noWrap", _f as "normal", _10 as "pointer", _11 as "red", _12 as "right", _13 as "typography", _14 as "uppercase", _15 as "yellow" }
