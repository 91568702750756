import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/Common/IconButton/IconButton';
import SearchInput from 'components/SearchInput';
import { useDialog } from 'hooks/useDialog';

import Footer from 'components/Layout/Footer/Footer';
import { FilterIcon } from 'components/Icon/Icon';
import { ActivityMobileFiltersDialog } from '../ActivityMobileFiltersDialog';

import * as styles from './ActivityPageMobileFooter.module.scss';

const ActivityPageMobileFooter = (props) => {
    const {
        searchValue, onSearch, formik, handleTypeSelect,
    } = props;

    const invoicesMobileFiltersDialog = useDialog(false);

    return (
        <React.Fragment>
            <Footer className={styles.mobileButtonsContainer}>
                <SearchInput
                    searchValue={searchValue}
                    onSearch={onSearch}
                />
                <div className={styles.footerDivider} />

                <IconButton
                    color="white"
                    size={60}
                    onClick={invoicesMobileFiltersDialog.onShow}
                    data-testid="data-test-filter-button"
                >
                    <FilterIcon />
                </IconButton>
                <div className={styles.footerDivider} />
            </Footer>
            <ActivityMobileFiltersDialog
                isVisible={invoicesMobileFiltersDialog.visible}
                onClose={invoicesMobileFiltersDialog.onClose}
                formik={formik}
                handleTypeSelect={handleTypeSelect}
            />
        </React.Fragment>
    );
};

ActivityPageMobileFooter.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    formik: PropTypes.object,
    handleTypeSelect: PropTypes.func,
};

ActivityPageMobileFooter.defaultProps = {
    searchValue: '',
    onSearch: PropTypes.emptyFunc,
    formik: {},
    handleTypeSelect: PropTypes.emptyFunc,
};

export default ActivityPageMobileFooter;
