import React from 'react';
import times from 'lodash/times';
import {
    bool, object, number, string,
} from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HEADER } from 'const/e2e/TEST_ATTRIBUTES';
import { roleSelector } from 'store/selectors/auth';
import { useMultisafe } from 'hooks/useMultisafe';
import NavigationService from '../../../services/navigationService';
import { permissionsPresetSelector } from '../../../store/selectors/permissions';

function SubNavigation(props) {
    const {
        isDataLoaded,
        parentRoute,
        subRoutes: predefinedSubRoutes = null,
        productsList,
    } = props;
    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);
    const { role } = useSelector(roleSelector);
    const isMultisafe = useMultisafe();
    const subRoutes = predefinedSubRoutes
        || NavigationService.getSubNavigationByMainRoute(parentRoute, sections, preset, productsList, role, isMultisafe);
    const { t } = useTranslation();
    const className = 'flex-nowrap h-100';

    if (!isDataLoaded) {
        return (
            <Nav className={className}>
                {
                    times(subRoutes.length, (index) => (
                        <div
                            key={`skeleton-section-${index}`}
                            style={{
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 3,
                                borderBottomColor: 'transparent',
                                margin: '1rem 0',
                            }}
                        >
                            <Skeleton height={16} width={75} />
                        </div>
                    ))
                }
            </Nav>
        );
    }

    return (
        <Nav className={className}>
            {
                subRoutes.map(({ directsTo, translation, exact }) => (
                    <Nav.Link
                        key={directsTo}
                        as={NavLink}
                        to={directsTo}
                        exact={exact}
                        className="text-nowrap"
                        data-testid={`${HEADER.subNavigationSelector}_${translation}`}
                        replace
                    >
                        {t(translation)}
                    </Nav.Link>
                ))
            }
        </Nav>
    );
}

SubNavigation.propTypes = {
    date: object,
    isDataLoaded: bool,
    parentRoute: string,
    shopId: number,
    withQuery: bool,
};

export default SubNavigation;
