const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
const isLocal = appEnvironment === 'local';
const isDevelopment = appEnvironment === 'develop' || isLocal;
const appBaseUrl = process.env.REACT_APP_BASE_URL;
const appApiUsername = process.env.REACT_APP_API_USERNAME;
const appApiPassword = process.env.REACT_APP_API_PASSWORD;
const appIntrercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
const appSentryDsn = process.env.REACT_APP_SENTRY_DSN;
const appWebsocketUrl = process.env.REACT_APP_WEBSOCKET_URL;

export const ENVS = {
    isLocal,
    isDevelopment,
    appBaseUrl,
    appApiUsername,
    appApiPassword,
    appIntrercomAppId,
    appSentryDsn,
    appEnvironment,
    appWebsocketUrl,
};
