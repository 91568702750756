import { combineReducers } from 'redux';

import { multisafePayoutsListReducer } from './multisafePayoutsList';
import { invoicesListReducer } from './invoicesList';
import { payoutsListReducer } from './payoutsList';
import { clearingsListReducer } from './clearingsList';

export const financialsReducer = combineReducers({
    invoices: invoicesListReducer,
    payouts: payoutsListReducer,
    clearings: clearingsListReducer,
    multisafePayouts: multisafePayoutsListReducer,
});
