import React from 'react';

// Hooks
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import { Popover } from 'components/Common/Popover';
import { Image } from 'components/Common/Image/Image';
import { Content } from 'components/Common/Content';
import { Header } from 'components/Common/Typography/Header';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Label } from 'components/Common/Typography/Label';
import { Row } from 'components/Common/Row';
import { Column } from 'components/Common/Column';
import { IconButton } from 'components/Common/IconButton';
import momentGMT0Time from 'helpers/time/momentGMT0Time';
import { useIsEmployee } from 'components/hooks/useRole';
import { ProductModal } from '..';
import { ArrowSmallLeft, ArrowSmallRight, ClockIcon } from '../Icon/Icon';

// Styles
import * as styles from './Timeline.module.scss';

const PopoverBody = ({
    vertical = true, buttonVisible, buttonText, titleText, extraRecordsText, product, type,
}) => {
    const { t } = useTranslation();
    const isEmployee = useIsEmployee();

    return (
        <Content loading={!product}>
            <Row wrap={!vertical} gap={16} stretched padding={8} spacing={8} align="start" justify="center">
                <Column gap={8} align="center" stretched={!vertical}>
                    <Image
                        className="w-100"
                        src={product.image}
                        alt={product.name}
                    />
                    {buttonVisible && (
                        <Button
                            stretched
                            color="yellow"
                            href={`/agenda/${type}/edit/${product.id}`}
                        >
                            {buttonText}
                        </Button>
                    )}
                </Column>
                {product.services.length !== 0 && (
                    <Column
                        gap={16}
                        stretched
                        align={vertical ? 'start' : 'center'}
                    >
                        <Column
                            gap={8}
                            stretched
                            align={vertical ? 'start' : 'center'}
                        >
                            <Header breakWord>{titleText}</Header>
                            {product.services.slice(0, 4).map((service) => (
                                <Column
                                    key={service.id}
                                    gap={0}
                                    align={vertical ? 'start' : 'center'}
                                >
                                    <SubHeader breakWord>{service.name}</SubHeader>
                                    <Row gap={8}>
                                        <Row gap={8}>
                                            <Label color="gray">
                                                <ClockIcon
                                                    width={14}
                                                    height={14}
                                                    strokeWidth={2.5}
                                                    className="position-relative mr-1"
                                                />
                                            </Label>
                                            <Label color="gray">
                                                {typeof service.minutes === 'number'
                                                    ? `${service.minutes} min`
                                                    : service.length}
                                            </Label>
                                        </Row>

                                        <Label color="gray">
                                            {formatPrice.toEuroWithComma({ amount: service.price })}
                                        </Label>
                                    </Row>
                                </Column>
                            ))}
                            {product.services.length > 4 && (
                                <Label weight="light">{extraRecordsText(product.services.length - 4)}</Label>
                            )}

                        </Column>
                        {product.lastSeen && !isEmployee && (
                            <Label color="black" weight="light">
                                {`${t('agendaRoute.lastSeen')}: ${momentGMT0Time(product.lastSeen).format('DD.MM.YYYY HH:mm')}`}
                            </Label>
                        )}
                    </Column>
                )}
            </Row>
        </Content>
    );
};

const TimelineHeader = ({
    globalDate, year, view = 'day', dataType = 'objects', index, setIndex, products, showScroll, windowWidth, objectsVisible,
}) => {
    const { t } = useTranslation();

    const { locale } = useSelector((state) => state.locales);

    const { banEdit } = useSelector(permissionsResourceSelector);

    const [popoverModalData, setPopoverModalData] = useState(null);

    return (
        <React.Fragment>
            {view === 'day' && products && _.times(Math.min(objectsVisible, products.length), (i) => (
                <div
                    key={`product-title-${i}`}
                    className={classNames(styles.productTitle, 'd-flex align-items-center px-2')}
                    style={{
                        gridColumn: i + 3,
                        minWidth: 200,
                        top: showScroll ? 0 : undefined,
                    }}
                    data-testid="data-test-timeline-header"
                >
                    {i === 0 && index !== 0 ? (
                        <IconButton
                            data-testid="backArrow"
                            color="transparent"
                            onClick={() => setIndex(Math.max(index - 1, 0))}
                        >
                            <ArrowSmallLeft />
                        </IconButton>
                    ) : <div style={{ width: 32, height: 32 }} />}

                    <Popover
                        small={!products[i + index]?.services.length}
                        noArrow={false}
                        content={(
                            <PopoverBody
                                product={products[i + index]}
                                titleText={t('agendaRoute.prices')}
                                buttonText={t('agendaRoute.edit')}
                                buttonVisible={!banEdit}
                                extraRecordsText={(number) => t('agendaRoute.morePrices', { number })}
                                type={dataType}
                            />
                        )}
                    >
                        {({ ref, onClick }) => (
                            <span
                                ref={ref}
                                className="flex-grow-1 text-center text-break cursor-pointer"
                                onClick={() => (windowWidth < 768 ? setPopoverModalData(products[i + index]) : onClick())}
                            >
                                {products[i + index]?.name}
                            </span>
                        )}
                    </Popover>

                    {i === Math.min(objectsVisible, products.length) - 1 && index < products.length - Math.min(products.length, objectsVisible) ? (
                        <IconButton
                            data-testid="nextArrow"
                            color="transparent"
                            onClick={() => setIndex(Math.min(products.length - Math.min(products.length, objectsVisible), index + 1))}
                        >
                            <ArrowSmallRight />
                        </IconButton>
                    ) : <div style={{ width: 32, height: 32 }} />}
                </div>
            ))}

            {view === 'day' && !products && _.times(4, (i) => (
                <div
                    key={`product-title-${i}`}
                    className={classNames(styles.productTitle, 'd-flex align-items-center px-2')}
                    style={{ gridColumn: i + 3 }}
                    data-testid="data-test-timeline-header"
                >
                    <div className="flex-grow-1 text-center px-4">
                        <Skeleton width={185} height={22} />
                    </div>
                </div>
            ))}

            {view === 'week' && _.times(7, (i) => (
                <div
                    key={`weekday-${i}`}
                    className={classNames(
                        styles.productTitle,
                        moment().isSame(globalDate.clone().isoWeekday(i + 2), 'day') && styles.selectedNext,
                        moment().isSame(globalDate.clone().isoWeekday(i + 1), 'day') && styles.selected,
                        'd-flex align-items-center px-2',
                    )}
                    style={{ gridColumn: i + 3, gridRowStart: 1 }}
                    data-testid="data-test-timeline-header"
                >
                    <div style={{ width: 24, height: 25.98 }} />
                    <div className="flex-grow-1 d-flex flex-column">
                        <span className="text-center text-muted text-uppercase font-size-12 font-weight-normal">
                            {globalDate.clone().locale(locale).isoWeekday(i + 1).format('ddd')}
                        </span>
                        <span className="text-center font-size-14 mt-n1">
                            {globalDate.clone().isoWeekday(i + 1).format('D.MM')}
                        </span>
                    </div>
                    <div style={{ width: 24, height: 25.98 }} />
                </div>
            ))}

            {view === 'month' && _.times(7, (i) => (
                <div
                    key={`weekday-${i}`}
                    className={classNames(styles.productTitle, styles.month, 'd-none d-lg-flex align-items-center px-2')}
                    style={{ gridColumn: i + 1 }}
                    data-testid="data-test-timeline-header"
                >
                    <div style={{ width: 24, height: 25.98 }} />
                    <span className="flex-grow-1 text-center">
                        {moment().locale(locale).year(year).isoWeekday(i + 1)
                            .format('ddd')}
                    </span>
                    <div style={{ width: 24, height: 25.98 }} />
                </div>
            ))}

            {popoverModalData && (
                <ProductModal
                    data={popoverModalData}
                    type={dataType}
                    hide={() => setPopoverModalData(null)}
                    cancelButtonTitle={t('agendaRoute.close')}
                    editButtonTitle={t('agendaRoute.edit')}
                    buttonVisible={!banEdit}
                >
                    <PopoverBody
                        buttonVisible={false}
                        vertical={false}
                        product={popoverModalData}
                        titleText={t('agendaRoute.prices')}
                        buttonText={t('agendaRoute.edit')}
                        extraRecordsText={(number) => t('agendaRoute.morePrices', { number })}
                        type={dataType}
                    />
                </ProductModal>
            )}
        </React.Fragment>
    );
};

export default TimelineHeader;
