import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, InputGroup } from 'react-bootstrap';

import { ArrowSmallDownIcon, ArrowSmallUpIcon } from 'components/Icon/Icon';
import { useDidUpdateEffect } from 'components/hooks';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './NumberInput.module.scss';
import { Column } from '../Column';
import { Button } from '../Button';

function NumberInput(props) {
    const {
        id,
        name,
        value,
        onChange,
        className,
        placeholder,
        isInvalid,
        toFixed,
        step,
        min,
        max,
        withoutButtons,
        disabled,
        prepend,
    } = props;

    const [input, setInput] = useState(value);

    useEffect(() => {
        if (parseFloat(input) !== parseFloat(value)) {
            setInput(value);
        }
    }, [value]);

    useDidUpdateEffect(() => {
        if (input === '') {
            onChange(input);
            return;
        }

        if (isNaN(parseFloat(input))) {
            onChange(undefined);
            return;
        }

        onChange(toFixed ? parseFloat(input) : parseInt(input, 10));
    }, [input]);

    const onChangeInput = useCallback((e) => {
        const { value } = e.target;

        if (value === '') {
            setInput(value);
            return;
        }

        let nextValue = value;

        nextValue = nextValue.replace(/[\.,]+/g, '.');

        // if first symbol is dot, we should add 0 before it
        if (value === '.') {
            nextValue = '0.';
        }

        // if we have 0 at the beginning of the number and we have more than 1 symbol, and the second symbol is not a dot, we should remove 0
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            nextValue = value.slice(1);
        }

        if (value.length > 1 && value.at(-1) === '-') {
            nextValue = value.slice(0, -1);
        }

        // if we dont have toFixed value, we should allow only numbers and minus
        if (toFixed) {
            nextValue = nextValue.replace(/[^0-9\.\-]/g, '');
        } else {
            nextValue = nextValue.replace(/[^0-9\-]/g, '');
        }
        nextValue = nextValue.replace(/\-+/g, '-');

        // if at this moment count of digits after dot is more than toFixed value, we should not change the value
        if (toFixed && nextValue.split('.')[1]?.length > toFixed) {
            return;
        }

        const nextValueFloat = parseFloat(nextValue);

        if (max !== undefined && nextValueFloat > max) {
            setInput(max);
            return;
        }

        if (min !== undefined && nextValueFloat < min) {
            setInput(min);
            return;
        }

        setInput(nextValue);
    }, [value, max, min]);

    const onIncrementValue = useCallback(() => {
        const nextValue = value + step;

        if (max !== undefined && nextValue > max) {
            onChange(max);
            return;
        }

        if (min !== undefined && nextValue < min) {
            onChange(min);
            return;
        }

        onChange(nextValue);
    }, [value, step, max, onChange]);

    const onDecrementValue = useCallback(() => {
        const nextValue = value - step;

        if (min !== undefined && nextValue < min) {
            onChange(min);
            return;
        }

        if (max !== undefined && nextValue > max) {
            onChange(max);
            return;
        }

        onChange(nextValue);
    }, [value, step, min, onChange]);

    return (
        <InputGroup
            className={classNames(
                styles.numberInputContainer,
                {
                    [styles.invalid]: isInvalid,
                    'is-invalid': isInvalid,
                },
                className,
            )}
        >
            {prepend && (
                <InputGroup.Prepend>
                    <InputGroup.Text
                        className={styles.prependInput}
                    >
                        {prepend}
                    </InputGroup.Text>
                </InputGroup.Prepend>
            )}
            <Form.Control
                type="string"
                name={name || id}
                value={input}
                onChange={onChangeInput}
                isInvalid={isInvalid}
                placeholder={placeholder}
                className={styles.numberInputInput}
                disabled={disabled}
                data-testid={props['data-testid']}
            />
            {!withoutButtons && (
                <div
                    className="position-absolute"
                    style={{ top: 0, right: 0, height: 38 }}
                >
                    <Column gap={0}>
                        <Button
                            color="outline"
                            size="small"
                            style={{
                                borderTop: 'none',
                                borderRight: 'none',
                                height: 19,
                                minHeight: 19,
                            }}
                            className="border-radius-0"
                            onClick={onIncrementValue}
                            disabled={disabled || (max !== undefined && value >= max)}
                            data-testid={`${props['data-testid']}-increment`}

                        >
                            <ArrowSmallUpIcon height="16" width="16" />
                        </Button>
                        <Button
                            color="outline"
                            size="small"
                            style={{
                                borderTop: 'none',
                                borderBottom: 'none',
                                borderRight: 'none',
                                height: 19,
                                minHeight: 19,
                            }}
                            className="border-radius-0"
                            onClick={onDecrementValue}
                            disabled={disabled || (min !== undefined && value <= min)}
                            data-testid={`${props['data-testid']}-decrement`}

                        >
                            <ArrowSmallDownIcon height="16" width="16" />
                        </Button>
                    </Column>
                </div>
            )}
        </InputGroup>
    );
}

NumberInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    isInvalid: PropTypes.bool,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    withoutButtons: PropTypes.bool,
    disabled: PropTypes.bool,
    toFixed: PropTypes.number,
    'data-testid': PropTypes.string,
    prepend: PropTypes.string,
};

NumberInput.defaultProps = {
    value: 0,
    onChange: emptyFunc,
    isInvalid: false,
    step: 1,
    withoutButtons: false,
    min: 0,
    disabled: false,
    toFixed: 0,
    'data-testid': 'data-test-number-input',
    prepend: '',
};

export default NumberInput;
