import React, {
    useState, useMemo, useEffect, useRef,
} from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
    useHistory, useParams, Redirect,
} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Store
import * as actions from 'store/actions';

// Components
import {
    Form, Row as BootstrapRow, Col, Dropdown,
} from 'react-bootstrap';
import Select from 'react-select';
import {
    Footer, FileUpload, Switch, Schedule, WorkBreaks, PhoneInput, ConfirmationModal,
} from 'components';

// Styles

// Utils
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { Button } from 'components/Common/Button';
import { useMobile } from 'hooks/useMobile';
import Container from 'components/Layout/Container/Container';
import { BackButton } from 'components/Common/BackButton';
import { productsLoadersSelector } from 'store/selectors/products';
import { Label } from 'components/Common/Typography/Label';
import { Row } from 'components/Common/Row';
import TextButton from 'components/Common/TextButton/TextButton';
import { Column } from 'components/Common/Column';

import { Content } from 'components/Common/Content';
import { CheckBox } from 'components/Common/CheckBox';
import NumberInput from 'components/Common/NumberInput';
import { styles as selectStyles, dangerTheme, theme } from '../../../../../styles/select';
import * as styles from './Add.module.scss';

import * as SERVICES_ACTIONS from '../../../../../store/actions/services';

import * as SERVICES_SELECTORS from '../../../../../store/selectors/services';

const MAXIMUM_DAYS = 1000;

const Add = () => {
    const { t } = useTranslation();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        image: yup.mixed().required(t('addEmployeeRoute.pleaseSelectEmployeeImage')),
        firstName: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEmployeeRoute.firstName') })),
        lastName: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEmployeeRoute.lastName') })),
        description: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addEmployeeRoute.description') })),
        services: yup.array().of(yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        })).notRequired(),
        canLogin: yup.boolean().required(),
        email: yup
            .string()
            .email(t('validationErrors.invalidEmailAddress')),
        contactNumber: yup
            .string()
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, t('validationErrors.invalidPhoneNumber')),
        employeeType: yup
            .string()
            .when('canLogin', {
                is: true,
                then: (employeeType) => employeeType
                    .oneOf(['basic', 'plus', 'full'])
                    .required(),
            }),
        sendWelcome: yup
            .boolean()
            .when('canLogin', {
                is: true,
                then: (sendWelcome) => sendWelcome
                    .required(),
            }),

        futureBooking: yup.number()
            .positive(t('validationErrors.mustBePositiveNumber', { name: t('addGroupRoute.days') }))
            .max(MAXIMUM_DAYS, t('validationErrors.mustBeNoLongerThen', { field: 'Future bookings', days: MAXIMUM_DAYS })),
        hasOwnSchedule: yup.boolean().required(),
        schedule: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.object({
                    enabled: yup.boolean(),
                    from: yup.mixed().when(`schedule.${moment().isoWeekday(i).format('dddd')}.to`, (to, from) => (to ? from.required() : from.nullable())),
                    to: yup.mixed().when(`schedule.${moment().isoWeekday(i).format('dddd')}.from`, (from, to) => (from ? to.required() : to.nullable())),
                }).nullable(),
            }), {})),
        hasWorkBreaks: yup.boolean().required(),
        workBreaks: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.array(yup.object({
                    from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('addBookingModal.field') })),
                    to: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('addBookingModal.field') })),
                })),
            }), {})),
        canEditOwnAgenda: yup.boolean().required(),
        canOpenServices: yup.boolean().required(),
        canOpenClients: yup.boolean().required(),
        canOpenMarketing: yup.boolean().required(),
        canOpenFinancials: yup.boolean().required(),
        canOpenAccessLogs: yup.boolean().required(),
        canOpenCompanySettings: yup.boolean().required(),
    })).current;

    const { id } = useParams();
    const isMobile = useMobile();
    const isWrapRows = useMobile(1300);
    const [loading, setLoading] = useState(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeletingObject, setIsDeletingObject] = useState(false);

    const { loaded: isShopLoaded, id: shopID } = useSelector((state) => state.shop);
    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const avaliableServices = useMemo(() => companyServices.filter(({ archived }) => !archived), [companyServices]);
    const workingHours = useSelector((state) => state.shop?.workingDays);
    const { banAdd, banEdit, banDelete } = useSelector(permissionsResourceSelector);
    const isLoaded = useSelector(productsLoadersSelector());

    const dispatch = useDispatch();

    const employee = useSelector((state) => state.employees.employees.find((obj) => obj.id === Number(id)));

    const initialValues = useMemo(() => ({
        image: employee?.image ?? null,
        firstName: employee?.employee?.first_name ?? '',
        lastName: employee?.employee?.last_name ?? '',
        description: employee?.description ?? '',
        services: employee?.services ?? [],
        canLogin: employee?.employee?.make_login ?? false,
        email: employee?.employee?.email ?? '',
        isTimeslotRecalc: employee?.timeslotRecalc ?? true,
        contactNumber: employee?.employee?.contact_number ?? '',
        employeeType: employee?.employee?.employee_type ?? 'basic',
        futureBooking: employee?.futureBooking
            ? moment(employee?.futureBooking).startOf('day')
                .diff(moment().startOf('day'), 'days')
            : '',
        sendWelcome: false,
        hasOwnSchedule: employee?.hasOwnSchedule ?? false,
        schedule: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((schedule, day) => {
            const workBreak = employee?.workingDays?.[day.toLowerCase()];

            return {
                ...schedule,
                [day]: workBreak?.from && workBreak?.to ? {
                    enabled: true,
                    from: moment(workBreak.from, 'HH:mm'),
                    to: moment(workBreak.to, 'HH:mm'),
                } : {
                    enabled: false,
                    from: workingHours && moment(workingHours[day.toLowerCase()].from, 'HH:mm'),
                    to: workingHours && moment(workingHours[day.toLowerCase()].to, 'HH:mm'),
                },
            };
        }, {}),
        hasWorkBreaks: !!employee?.workBreaks?.length,
        workBreaks: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((breaks, day) => ({
            ...breaks,
            [day]: employee?.workBreaks
                .filter(({ weekday }) => weekday.toLowerCase() === day.toLowerCase())
                .map(({ from, to }) => ({
                    from: moment(from, 'HH:mm'),
                    to: moment(to, 'HH:mm'),
                })) ?? [],
        }), {}),
        canEditOwnAgenda: employee?.employee?.can_edit_own_agenda ?? false,
        canOpenServices: employee?.employee?.can_open_services ?? false,
        canOpenClients: employee?.employee?.can_open_clients ?? false,
        canOpenMarketing: employee?.employee?.can_open_marketing ?? false,
        canOpenFinancials: employee?.employee?.can_open_financials ?? false,
        canOpenAccessLogs: employee?.employee?.can_open_access_logs ?? false,
        canOpenCompanySettings: employee?.employee?.can_open_company_settings ?? false,
    }), [workingHours, employee]);

    const { addToast } = useToasts();

    const {
        handleSubmit, handleChange, setFieldValue, values, isSubmitting, touched, errors,
    } = useFormik({
        validationSchema: schema,
        initialValues,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            const schedule = Object.entries(values.schedule).map(([day, { enabled, from, to }]) => [
                day,
                {
                    from: enabled ? from : null,
                    to: enabled ? to : null,
                },
            ]).reduce((schedule, [day, value]) => ({
                ...schedule,
                [day]: value,
            }), {});

            const toast = !id
                ? t('addEmployeeRoute.employeeAddedSuccessfully')
                : t('addEmployeeRoute.employeeEditedSuccessfully');

            const handler = !id ? actions.addEmployee({
                employee: {
                    ...values,
                    schedule,
                },
            }) : actions.editEmployee({
                employee: {
                    ...values,
                    schedule,
                    id: Number(id),
                },
            });

            dispatch(handler)
                .then(() => {
                    addToast(toast, {
                        appearance: 'success',
                    });
                    push('/agenda/employees');
                })
                .catch(({ message, errors }) => {
                    if (errors?.schedule || errors?.workBreaks) {
                        const error = errors?.schedule || errors?.workBreaks;
                        addToast(error, {
                            appearance: 'error',
                        });
                    }
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                        });
                    } else {
                        setErrors(errors || {});
                    }

                    setSubmitting(false);
                });
        },
        enableReinitialize: true,
    });

    const { push } = useHistory();

    useEffect(() => {
        if (!shopID || !isShopLoaded || !isLoaded.employees) {
            return;
        }

        if (id) {
            setLoading(true);
            dispatch(actions.getProductById({ productId: id, type: 'employee' }))
                .catch(() => push('/agenda/employees'))
                .finally(() => setLoading(false));
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded, isLoaded.employees]);

    const onDeleteHandler = async () => {
        if (!id || banDelete || isDeletingObject) {
            return;
        }

        setIsDeletingObject(true);
        try {
            await dispatch(actions.deleteEmployee(id));
            addToast(t('addEmployeeRoute.employeeDeletedSuccessfully'), {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
            push('/agenda/employees');
        } catch (error) {
            if (!error?.message) return;
            addToast(error.message, {
                appearance: 'error',
            });
        } finally {
            setIsDeletingObject(false);
        }
    };

    if (banAdd || banEdit) {
        return <Redirect to="/agenda/employees" />;
    }

    const isBasic = values.employeeType === 'basic';
    const isPlus = values.employeeType === 'plus';

    // const enabledEditOwnAgenda = !isBasic;
    const enabledOpenServices = !isBasic;
    const enabledOpenClients = !isBasic;
    const enabledOpenMarketing = !isBasic;
    const enabledOpenFinancials = !isBasic;
    const enabledOpenAccessLogs = !isBasic;
    const enabledOpenCompanySettings = !isBasic && !isPlus;

    useEffect(() => {
        setFieldValue('canOpenServices', enabledOpenServices ? values.canOpenServices : false);
        setFieldValue('canOpenClients', enabledOpenClients ? values.canOpenClients : false);
        setFieldValue('canOpenMarketing', enabledOpenMarketing ? values.canOpenMarketing : false);
        setFieldValue('canOpenFinancials', enabledOpenFinancials ? values.canOpenFinancials : false);
        setFieldValue('canOpenAccessLogs', enabledOpenAccessLogs ? values.canOpenAccessLogs : false);
        setFieldValue('canOpenCompanySettings', enabledOpenCompanySettings ? values.canOpenCompanySettings : false);
    }, [values.employeeType]);

    const canShowHeaderDeleteButton = Boolean(id && !banDelete && !isMobile);

    return (
        <React.Fragment>
            <TextSubHeader
                text={!id ? t('addEmployeeRoute.addNewEmployee') : t('addEmployeeRoute.editEmployee')}
                before={(
                    <BackButton
                        href="/agenda/employees"
                    />
                )}
                rightActions={canShowHeaderDeleteButton && (
                    <Button
                        onClick={() => setDeleteModalVisible(true)}
                        color="red"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            />
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Content loading={loading}>
                        <BootstrapRow className="w-100">
                            <Col xs={12} md={6} lg={6} xl={3}>
                                <FileUpload
                                    name="image"
                                    label={(touched.image && errors.image) || t('addEmployeeRoute.uploadEmployeeImage')}
                                    isInvalid={touched.image && !!errors.image}
                                    onChange={(file) => setFieldValue('image', file)}
                                    defaultValue={values.image}
                                    square
                                    loading={loading}
                                />
                            </Col>

                            <Col xs={12} md={6} lg={6} xl={9} className="mt-4 mt-md-0">
                                <BootstrapRow>
                                    <Col xs={12} lg={6}>
                                        <Form.Group>
                                            <Label paddingBottom>
                                                {t('addEmployeeRoute.firstName')}
                                            </Label>
                                            <Form.Control
                                                name="firstName"
                                                type="text"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                isInvalid={touched.firstName && !!errors.firstName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} lg={6}>
                                        <Form.Group>
                                            <Label paddingBottom>
                                                {t('addEmployeeRoute.lastName')}
                                            </Label>
                                            <Form.Control
                                                name="lastName"
                                                type="text"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                isInvalid={touched.lastName && !!errors.lastName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </BootstrapRow>

                                <Form.Group>
                                    <Label paddingBottom>
                                        {t('addEmployeeRoute.description')}
                                    </Label>
                                    <Form.Control
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={touched.description && !!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <CheckBox
                                        id="isTimeslotRecalc"
                                        name="isTimeslotRecalc"
                                        label={t('addEmployeeRoute.timeslotRecalc')}
                                        checked={values.isTimeslotRecalc}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Row justify="between" stretched padding={8}>
                                        <Label tooltip={t('addEmployeeRoute.services.tooltip')}>
                                            {t('addEmployeeRoute.services.label')}
                                        </Label>
                                        <Row gap={8}>
                                            <TextButton
                                                name="selectAll"
                                                color="gray"
                                                onClick={() => setFieldValue('services', avaliableServices.map(({ id, name }) => ({
                                                    id,
                                                    name,
                                                })))}
                                            >
                                                {t('addEmployeeRoute.selectAll')}
                                            </TextButton>

                                            <TextButton
                                                name="removeAll"
                                                color="gray"
                                                onClick={() => setFieldValue('services', [])}
                                            >
                                                {t('addEmployeeRoute.removeAll')}
                                            </TextButton>
                                        </Row>
                                    </Row>
                                    <Select
                                        name="services"
                                        styles={selectStyles}
                                        theme={touched.services && !!errors.services ? dangerTheme : theme}
                                        options={avaliableServices.map(({ id, name }) => ({ value: id, label: name }))}
                                        value={values.services.map(({ id, name }) => ({
                                            value: id,
                                            label: name,
                                        }))}
                                        onChange={(selected) => {
                                            if (!selected) {
                                                return setFieldValue('services', []);
                                            }
                                            setFieldValue('services', selected.map(({ value, label }) => ({
                                                id: value,
                                                name: label,
                                            })));
                                        }}
                                        isMulti
                                        isClearable={false}
                                        placeholder={t('placeholders.select')}
                                    />
                                    <Form.Control.Feedback
                                        className={touched.services && !!errors.services && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.services}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-0">
                                    <Label paddingBottom tooltip={t('addEmployeeRoute.howLongBookingsCanBeMade.tooltip')}>
                                        {t('addEmployeeRoute.howLongBookingsCanBeMade.label')}
                                    </Label>

                                    <NumberInput
                                        name="futureBooking"
                                        value={values.futureBooking}
                                        onChange={(value) => setFieldValue('futureBooking', value)}
                                        isInvalid={!!touched.futureBooking && !!errors.futureBooking}
                                        prepend={t('addEmployeeRoute.days')}
                                        min={0}
                                        max={MAXIMUM_DAYS}
                                        step={1}
                                    />
                                    <Form.Control.Feedback
                                        className={!!touched.futureBooking && !!errors.futureBooking && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.futureBooking}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </BootstrapRow>

                        <Row padding={16} stretched>
                            <Column stretched gap={32}>
                                <Dropdown.Divider className="w-100" />
                                <Column gap={8} stretched>
                                    <Form.Group className="d-flex align-items-center">
                                        <Switch
                                            name="canLogin"
                                            checked={values.canLogin}
                                            onChange={(canLogin) => {
                                                setFieldValue('canLogin', canLogin);
                                                if (id) return;
                                                setFieldValue('sendWelcome', true);
                                            }}
                                            disabled={employee?.employee?.banned}
                                            label={t('addEmployeeRoute.makeLogin')}
                                        />
                                    </Form.Group>
                                    {values.canLogin && (
                                        <Row stretched gap={32} align="stretch" wrap={isWrapRows}>
                                            <Column gap={8} stretched>
                                                <Form.Group>
                                                    <Label paddingBottom>
                                                        {t('addEmployeeRoute.emailAddress')}
                                                    </Label>
                                                    <Form.Control
                                                        name="email"
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={touched.email && !!errors.email}
                                                        disabled={(values.canLogin && employee?.employee?.email) || employee?.employee?.banned}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Label paddingBottom>{t('addEmployeeRoute.contactNumber')}</Label>
                                                    <PhoneInput
                                                        name="contactNumber"
                                                        value={values.contactNumber}
                                                        onChange={(contactNumber) => setFieldValue('contactNumber', contactNumber)}
                                                        isInvalid={touched.contactNumber && !!errors.contactNumber}
                                                        disabled={employee?.employee?.banned}
                                                    />
                                                    <Form.Control.Feedback
                                                        className={touched.contactNumber && !!errors.contactNumber && 'd-block'}
                                                        type="invalid"
                                                    >
                                                        {errors.contactNumber}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <CheckBox
                                                    id="sendWelcome"
                                                    name="sendWelcome"
                                                    label={!id ? t('addEmployeeRoute.sendWelcome') : t('addEmployeeRoute.resetPassword')}
                                                    checked={values.sendWelcome}
                                                    onChange={handleChange}
                                                    disabled={employee?.employee?.banned}
                                                />
                                            </Column>
                                            <div className={styles.divider} />
                                            <Column gap={0} stretched>
                                                <Label paddingBottom>
                                                    {t('addEmployeeRoute.employeeType')}
                                                </Label>
                                                <Column gap={16}>
                                                    {['basic', 'plus', 'full'].map((type) => (
                                                        <Form.Check
                                                            key={type}
                                                            id={`employeeType.${type}`}
                                                            type="radio"
                                                            name={type}
                                                            label={<Label tooltip={t(`addEmployeeRoute.${type}.tooltip`)}>{t(`addEmployeeRoute.${type}.label`)}</Label>}
                                                            checked={values.employeeType === type}
                                                            onChange={() => setFieldValue('employeeType', type)}
                                                            disabled={employee?.employee?.banned}
                                                        />
                                                    ))}
                                                </Column>
                                            </Column>
                                            <div className={styles.divider} />
                                            <Column gap={0} stretched>
                                                <Label paddingBottom tooltip={t('addEmployeeRoute.pageAccessManagement.tooltip')}>
                                                    {t('addEmployeeRoute.pageAccessManagement.label')}
                                                </Label>
                                                <Row gap={16} align="start" wrap={isWrapRows} stretched justify="between">
                                                    <Column gap={16}>
                                                        {/*

                                                        Limitations (toggles disabled and greyed out)
For Basic employee - all toggles are switched off and greyed out. Can’t be enabled.
For Plus employee - “Company settings” toggle is switched off and grey out, it can’t be enabled.
For Full employee - all toggles are available.

                                                        <Switch
                                                            name="canEditOwnAgenda"
                                                            checked={values.canEditOwnAgenda}
                                                            onChange={(checked) => setFieldValue('canEditOwnAgenda', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.editOwnAgenda.label')}
                                                            disabled={!enabledEditOwnAgenda}
                                                        /> */}
                                                        <Switch
                                                            name="canOpenServices"
                                                            checked={values.canOpenServices}
                                                            onChange={(checked) => setFieldValue('canOpenServices', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.services.label')}
                                                            disabled={!enabledOpenServices}
                                                        />
                                                        <Switch
                                                            name="canOpenClients"
                                                            checked={values.canOpenClients}
                                                            onChange={(checked) => setFieldValue('canOpenClients', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.clients.label')}
                                                            disabled={!enabledOpenClients}
                                                        />
                                                        <Switch
                                                            name="canOpenMarketing"
                                                            checked={values.canOpenMarketing}
                                                            onChange={(checked) => setFieldValue('canOpenMarketing', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.marketing.label')}
                                                            disabled={!enabledOpenMarketing}
                                                        />
                                                    </Column>
                                                    <Column gap={16}>
                                                        <Switch
                                                            name="canOpenFinancials"
                                                            checked={values.canOpenFinancials}
                                                            onChange={(checked) => setFieldValue('canOpenFinancials', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.financials.label')}
                                                            disabled={!enabledOpenFinancials}
                                                        />
                                                        <Switch
                                                            name="canOpenAccessLogs"
                                                            checked={values.canOpenAccessLogs}
                                                            onChange={(checked) => setFieldValue('canOpenAccessLogs', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.accessLogs.label')}
                                                            disabled={!enabledOpenAccessLogs}
                                                        />
                                                        <Switch
                                                            name="canOpenCompanySettings"
                                                            checked={values.canOpenCompanySettings}
                                                            onChange={(checked) => setFieldValue('canOpenCompanySettings', checked)}
                                                            label={t('addEmployeeRoute.pageAccessManagement.companySettings.label')}
                                                            disabled={!enabledOpenCompanySettings}
                                                        />
                                                    </Column>
                                                </Row>
                                            </Column>

                                        </Row>
                                    )}
                                </Column>
                                <Dropdown.Divider className="w-100" />
                                <Column gap={8} stretched>
                                    <Form.Group className="d-flex align-items-center">
                                        <Switch
                                            name="hasOwnSchedule"
                                            checked={values.hasOwnSchedule}
                                            onChange={(hasOwnSchedule) => setFieldValue('hasOwnSchedule', hasOwnSchedule)}
                                            label={t('addEmployeeRoute.hasOwnSchedule')}
                                        />
                                    </Form.Group>

                                    {(values.hasOwnSchedule) && (
                                        <Schedule
                                            name="schedule"
                                            value={values.schedule}
                                            onChange={({
                                                type, day, enabled, from, to,
                                            }) => {
                                                if (type === 'UPDATE') {
                                                    setFieldValue('schedule', {
                                                        ...values.schedule,
                                                        [day]: {
                                                            enabled: enabled !== null && enabled !== undefined ? enabled : values.schedule[day].enabled,
                                                            from: from || values.schedule[day].from,
                                                            to: to || values.schedule[day].to,
                                                        },
                                                    });
                                                } else {
                                                    const mondayDay = values.schedule.Monday;
                                                    if (!mondayDay?.enabled) return;

                                                    setFieldValue('schedule', _.times(7)
                                                        .map((i) => moment().isoWeekday(i).format('dddd'))
                                                        .reduce((schedule, day) => ({
                                                            ...schedule,
                                                            [day]: { ...mondayDay },
                                                        }), {}));
                                                }
                                            }}
                                        />
                                    )}
                                </Column>
                                <Dropdown.Divider className="w-100" />

                                <Column gap={8} stretched>
                                    <Form.Group className="d-flex align-items-center">
                                        <Switch
                                            name="hasWorkBreaks"
                                            checked={values.hasWorkBreaks}
                                            onChange={(hasWorkBreaks) => setFieldValue('hasWorkBreaks', hasWorkBreaks)}
                                            label={t('addEmployeeRoute.workBreaks')}
                                        />
                                    </Form.Group>

                                    {(values.hasWorkBreaks) && (
                                        <WorkBreaks
                                            name="workBreaks"
                                            value={values.workBreaks}
                                            onChange={({
                                                type, day, workBreak, index, value,
                                            }) => {
                                                switch (type) {
                                                case 'ADD':
                                                    setFieldValue('workBreaks', {
                                                        ...values.workBreaks,
                                                        [day]: [
                                                            ...values.workBreaks[day],
                                                            workBreak,
                                                        ],
                                                    });
                                                    break;
                                                case 'REMOVE':
                                                    setFieldValue('workBreaks', {
                                                        ...values.workBreaks,
                                                        [day]: values.workBreaks[day].filter((_, i) => i !== index),
                                                    });
                                                    break;
                                                case 'UPDATE':
                                                    setFieldValue('workBreaks', {
                                                        ...values.workBreaks,
                                                        [day]: values.workBreaks[day].map((el, i) => (i !== index ? el : workBreak)),
                                                    });
                                                    break;
                                                default:
                                                    setFieldValue('workBreaks', value);
                                                    break;
                                                }
                                            }}
                                            errors={errors.workBreaks}
                                            touched={touched.workBreaks}
                                            workingHours={workingHours}
                                        />
                                    )}
                                </Column>
                            </Column>
                        </Row>
                        <Row justify="between" stretched padding={16}>
                            <Button
                                color="outline"
                                href="/agenda/employees"
                            >
                                {t('addEmployeeRoute.cancel')}
                            </Button>

                            <Button type="submit" loading={isSubmitting}>
                                {t(`addEmployeeRoute.${!id ? 'addEmployee' : 'save'}`)}
                            </Button>
                        </Row>
                    </Content>
                </Container>
            </Form>

            <Footer className="d-flex d-lg-none justify-content-between">
                {Boolean(id && !banDelete) && (
                    <Button
                        onClick={() => {
                            if (banDelete) return;
                            setDeleteModalVisible(true);
                        }}
                        color="red"
                        size="extra-large"
                        className="rounded-0 px-3 px-sm-5"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            </Footer>

            <ConfirmationModal
                isShow={deleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                loading={isDeletingObject}
                confirmAction={onDeleteHandler}
                titleText={t('addEmployeeRoute.deleteEmployee')}
                bodyText={t('addEmployeeRoute.areYouSureYouWantToDeleteEmployee')}
                deleteText={t('addObjectRoute.delete')}
            />
        </React.Fragment>
    );
};

export default Add;
