import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as CLIENTS_TYPES from './types';

export const getClientEmails = createPromiseAction(CLIENTS_TYPES.CLIENTS_EMAILS_GET);

export const setClientEmailsPage = ({ page }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET,
    payload: { page },
});

export const setClientEmailsPageFirst = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_FIRST,
});

export const setClientEmailsPageLast = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_LAST,
});

export const setClientEmailsPagePrev = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_PREV,
});

export const setClientEmailsPageNext = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_NEXT,
});

export const setClientEmailsFilters = ({ filters }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_FILTERS_SET,
    payload: {
        filters,
    },
});

export const clearClientEmailsFilters = () => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_FILTERS_CLEAR,
});

export const setClientEmailsSortOptions = ({ sortOptions }) => ({
    type: CLIENTS_TYPES.CLIENT_ITEM_EMAILS_SORT_OPTIONS_SET,
    payload: { sortOptions },
});
