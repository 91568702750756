import Logs from './logs';

const routes = [
    {
        component: Logs,
        exact: false,
        path: '/activity/logs',
    },
];

export default routes;
