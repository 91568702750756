import React from 'react';

const ArrowDiagonalDown = ({ width = '24', height = '24' }) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.36653 6.58525C5.36652 6.21706 5.06805 5.91858 4.69986 5.91858C4.33167 5.91858 4.03319 6.21706 4.03319 6.58525V11.2993C4.03319 11.6675 4.33167 11.966 4.69986 11.966L9.4139 11.966C9.78209 11.966 10.0806 11.6675 10.0806 11.2993C10.0806 10.9311 9.78209 10.6326 9.4139 10.6326L6.30936 10.6326L11.7709 5.17103C12.0313 4.91068 12.0313 4.48857 11.7709 4.22822C11.5106 3.96788 11.0885 3.96788 10.8281 4.22822L5.36652 9.68984L5.36653 6.58525Z" fill="currentColor" />
    </svg>

);

export default ArrowDiagonalDown;
