import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as INVOICES_TYPES from './types';

export const getInvoices = () => ({
    type: INVOICES_TYPES.INVOICES_GET,
});

export const setInvoices = ({
    invoices,
    itemsCount,
    pagesCount,
    totalRevenue,
    appyBeePay,
    manual,
    exportEmail,
}) => ({
    type: INVOICES_TYPES.INVOICES_SET,
    payload: {
        invoices,
        itemsCount,
        pagesCount,
        totalRevenue,
        appyBeePay,
        manual,
        exportEmail,
    },
});

export const setInvoicesPage = ({ page }) => ({
    type: INVOICES_TYPES.INVOICES_PAGE_SET,
    payload: { page },
});

export const setInvoicesPageFirst = () => ({
    type: INVOICES_TYPES.INVOICES_PAGE_SET_FIRST,
});

export const setInvoicesPageLast = () => ({
    type: INVOICES_TYPES.INVOICES_PAGE_SET_LAST,
});

export const setInvoicesPagePrev = () => ({
    type: INVOICES_TYPES.INVOICES_PAGE_SET_PREV,
});

export const setInvoicesPageNext = () => ({
    type: INVOICES_TYPES.INVOICES_PAGE_SET_NEXT,
});

export const setInvoicesSearch = ({ search }) => ({
    type: INVOICES_TYPES.INVOICES_SEARCH_SET,
    payload: { search },
});

export const setInvoicesLoading = ({ loading }) => ({
    type: INVOICES_TYPES.INVOICES_LOADING_SET,
    payload: { loading },
});

export const getInvoicesAmount = ({ invoicesAmountData, onSuccess, onError }) => ({
    type: INVOICES_TYPES.INVOICES_GET_AMOUNT,
    payload: {
        invoicesAmountData,
        onSuccess,
        onError,
    },
});

export const exportInvoices = ({ exportInvoicesData, onSuccess, onError }) => ({
    type: INVOICES_TYPES.INVOICES_EXPORT,
    payload: {
        exportInvoicesData,
        onSuccess,
        onError,
    },
});

export const setExportInProgress = ({ isExportInProgress }) => ({
    type: INVOICES_TYPES.INVOICES_EXPORT_IN_PROGRESS,
    payload: {
        isExportInProgress,
    },
});

export const checkInvoiceCreditStatus = ({ invoiceId, onSuccess }) => ({
    type: INVOICES_TYPES.INVOICES_ITEM_CHECK_CREDIT_STATUS,
    payload: {
        invoiceId,
        onSuccess,
    },
});

export const createInvoiceCredit = ({ invoiceId, onSuccess }) => ({
    type: INVOICES_TYPES.INVOICES_ITEM_CREATE_CREDIT,
    payload: {
        invoiceId, onSuccess,
    },
});

export const setInvoicesFilters = ({ filters }) => ({
    type: INVOICES_TYPES.INVOICES_FILTERS_SET,
    payload: {
        filters,
    },
});

export const clearInvoicesFilters = () => ({
    type: INVOICES_TYPES.INVOICES_FILTERS_CLEAR,
});

export const setExportEmail = ({ email }) => ({
    type: INVOICES_TYPES.INVOICES_EXPORT_EMAIL_SET,
    payload: {
        email,
    },
});

export const setExportPeriod = ({ period }) => ({
    type: INVOICES_TYPES.INVOICES_EXPORT_PERIOD_SET,
    payload: {
        exportPeriod: period,
    },
});

export const addCustomInvoice = createPromiseAction(INVOICES_TYPES.ADD_CUSTOM_INVOICE);
export const getCustomInvoices = createPromiseAction(INVOICES_TYPES.GET_CUSTOM_INVOICES);
export const editCustomInvoice = createPromiseAction(INVOICES_TYPES.EDIT_CUSTOM_INVOICE);
export const deleteCustomInvoice = createPromiseAction(INVOICES_TYPES.DELETE_CUSTOM_INVOICE);
export const getCustomInvoice = createPromiseAction(INVOICES_TYPES.GET_CUSTOM_INVOICE);

export const toggleInvoicePaymentStatus = createPromiseAction(INVOICES_TYPES.TOGGLE_INVOICE_PAYMENT_STATUS);
export const resendCustomInvoice = createPromiseAction(INVOICES_TYPES.RESEND_CUSTOM_INVOICE);
