import * as TYPES from '../../actions/access/actionTypes';

const INITIAL_STATE = {
    item: null,
    isLoading: false,
    isSavingProcess: false,
};

export const accessProfileItemReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case TYPES.GET_ACCESS_PROFILE_RESOLVED: {
        const { profile } = payload;
        return {
            ...state,
            item: profile,
            isLoading: false,
        };
    }
    case TYPES.GET_ACCESS_PROFILE_TRIGGER: {
        return {
            ...state,
            item: null,
            isLoading: true,
        };
    }
    case TYPES.GET_ACCESS_PROFILE_REJECTED: {
        return {
            ...state,
            isLoading: false,
        };
    }
    case TYPES.CREATE_ACCESS_PROFILE_REJECTED:
    case TYPES.SAVE_ACCESS_PROFILE_REJECTED: {
        return {
            ...state,
            isSavingProcess: false,
        };
    }
    case TYPES.CREATE_ACCESS_PROFILE_RESOLVED:
    case TYPES.SAVE_ACCESS_PROFILE_RESOLVED: {
        return {
            ...state,
            ...INITIAL_STATE,
        };
    }
    case TYPES.CREATE_ACCESS_PROFILE_TRIGGER:
    case TYPES.SAVE_ACCESS_PROFILE_TRIGGER: {
        return {
            ...state,
            isSavingProcess: true,
        };
    }
    default:
        return state;
    }
};
