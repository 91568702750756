import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { Label } from 'components/Common/Typography/Label';
import NumberInput from 'components/Common/NumberInput';
import { BUNDLE_PROP } from 'const/bundles/BUNDLE_PROP';
import { CheckBox } from 'components/Common/CheckBox';
import { useMobile } from 'hooks/useMobile';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'addOrEdit.form.fields.maxUsages';

const BundleMaxUsagesFormRow = (props) => {
    const {
        sold,
        values,
        validation,
        onChangeMaxUsagesValue,
        onToggleMaxUsagesUnlimited,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const isMobile = useMobile();

    return (
        <Row className="w-100">
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Label paddingBottom>
                        {t(`${T_PREFIX}.value.label`)}
                    </Label>

                    <NumberInput
                        name="maxUsages.value"
                        value={values.maxUsages.value}
                        onChange={onChangeMaxUsagesValue}
                        isInvalid={validation?.maxUsages?.value?.isInvalid}
                        disabled={values.maxUsages.unlimited || sold}
                        min={BUNDLE_PROP.MAX_USAGES.VALUE.MIN}
                        max={BUNDLE_PROP.MAX_USAGES.VALUE.MAX}
                        step={BUNDLE_PROP.MAX_USAGES.VALUE.STEP}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.maxUsages?.value?.isInvalid,
                        })}
                    >
                        {validation?.maxUsages?.value?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={8}
                className="m-0 p-0 align-self-start pt-4"
            >
                <Form.Group
                    as={Col}
                >
                    <CheckBox
                        id="maxUsages.unlimited"
                        name="maxUsages.unlimited"
                        label={t(`${T_PREFIX}.unlimited.label`)}
                        checked={values.maxUsages.unlimited}
                        disabled={sold}
                        onChange={onToggleMaxUsagesUnlimited}
                        paddingTop={!isMobile}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.maxUsages?.unlimited?.isInvalid,
                        })}
                    >
                        {validation?.maxUsages?.unlimited?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleMaxUsagesFormRow.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onChangeMaxUsagesValue: PropTypes.func,
    onToggleMaxUsagesUnlimited: PropTypes.func,
};

BundleMaxUsagesFormRow.defaultProps = {
    sold: false,
    validation: null,
    onChangeMaxUsagesValue: emptyFunc,
    onToggleMaxUsagesUnlimited: emptyFunc,
};

export default BundleMaxUsagesFormRow;
