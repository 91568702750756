import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ACCESS_SELECTORS from 'store/selectors/access';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { ACCESS_PROFILES_PAGE } from 'const/CLIENT_PATH';
import Container from 'components/Layout/Container/Container';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
import { Column } from 'components/Common/Column';
import { Button } from 'components/Common/Button';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Content } from 'components/Common/Content';
import { createAccessProfile, getAccessProfile, saveAccessProfile } from 'store/actions';
import { BackButton } from 'components/Common/BackButton';
import { GatesWindowedSelect } from 'components/Access/Profiles/GatesWindowedSelect';
import { Panel } from 'components/Common/Panel';
import { ProfilesAccessRules } from 'components/Access/Profiles/ProfilesAccessRules';
import { Label } from 'components/Common/Typography/Label';
import * as styles from './page.module.scss';

const PROFILE_NAME_MAX = 128;

const DEFAULT_PROFILE = {
    name: '',
    gates: [],
    accessRules: {
        subscriptions: [],
        bundles: [],
    },
    rule: 'booking',
    deductUsage: false,
};

const ProfileAdd = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { profileId } = useParams();

    const isEditMode = !!profileId;

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const profile = useSelector(ACCESS_SELECTORS.accessProfileSelector);

    const { isSavingProcess, isLoading, item } = profile;

    const initialValues = useMemo(() => (isEditMode ? ({
        name: item?.name || DEFAULT_PROFILE.name,
        gates: item?.gates || DEFAULT_PROFILE.gates,
        accessRules: {
            subscriptions: item?.subscriptions || DEFAULT_PROFILE.accessRules.subscriptions,
            bundles: item?.bundles || DEFAULT_PROFILE.accessRules.bundles,
        },
        rule: item?.rule || DEFAULT_PROFILE.rule,
        deductUsage: item?.deductUsage || false,
    }) : DEFAULT_PROFILE), [isEditMode, item]);

    const bundlesSchema = yup.array().of(yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
    }));

    const subscriptionsSchema = yup.array().of(yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
    }));

    const accessRules = yup.object().shape(
        {
            bundles: bundlesSchema.when('subscriptions', {
                is: (subscriptions) => !subscriptions || subscriptions.length === 0,
                then: bundlesSchema.required(tc('validationErrors.cannotBeEmpty', { name: t('profiles.add.accessRules.bundles.label') })),
                otherwise: bundlesSchema,
            }),
            subscriptions: subscriptionsSchema.when('bundles', {
                is: (bundles) => !bundles || bundles.length === 0,
                then: subscriptionsSchema.required(tc('validationErrors.cannotBeEmpty', { name: t('profiles.add.accessRules.subscriptions.label') })),
                otherwise: subscriptionsSchema,
            }),
        },
        [['bundles', 'subscriptions']],
    );

    const validationSchema = useMemo(() => yup.object({
        name: yup.string().required(tc('validationErrors.cannotBeEmpty', { name: t('profiles.add.name.label') }))
            .max(
                PROFILE_NAME_MAX,
                t('validationErrors.maxLength', {
                    value: PROFILE_NAME_MAX,
                }),
            ),
        gates: yup.array().of(yup.object({
            id: yup.string().required(),
            name: yup.string().required(),
        })),
        accessRules,
    }), [t]);

    const handleDoneRedirect = useCallback(() => {
        history.push(ACCESS_PROFILES_PAGE);
    }, [history]);

    const onSubmit = useCallback((profile) => {
        const data = new FormData();
        data.append('name', profile.name);
        profile.accessRules.subscriptions.forEach((subscription, index) => {
            data.append(`subscriptions[${index}]`, subscription.id);
        });
        profile.accessRules.bundles.forEach((bundle, index) => {
            data.append(`bundles[${index}]`, bundle.id);
        });
        profile.gates.forEach((gate, index) => {
            data.append(`gates[${index}]`, gate.id);
        });
        data.append('rule', profile.rule);
        data.append('deductUsage', Number(profile.deductUsage));

        if (isEditMode) {
            dispatch(saveAccessProfile({ data, profileId, onDone: handleDoneRedirect }));
            return;
        }
        dispatch(createAccessProfile({ data, onDone: handleDoneRedirect }));
    }, [dispatch]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const {
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
    } = formik;

    const hasAnyChanges = useMemo(
        () => JSON.stringify(values) !== JSON.stringify(initialValues),
        [values, initialValues],
    );

    const onSubmitHandler = useCallback(debounce(() => {
        handleSubmit();
    }, 500), [handleSubmit]);

    useEffect(() => {
        if (isEditMode) {
            dispatch(getAccessProfile({ profileId }));
        }
    }, [
        dispatch,
        profileId,
    ]);

    return (
        <React.Fragment>
            <TextSubHeader
                before={(
                    <BackButton
                        href={ACCESS_PROFILES_PAGE}
                    />
                )}
                text={isEditMode ? t('profiles.edit.title') : t('profiles.add.title')}
            />
            <Container>
                <Content loading={isLoading} maxWidth={1322}>
                    <Column gap={32} stretched>
                        <Column gap={32} stretched>
                            <Column gap={12} stretched>
                                <Label htmlFor="name">
                                    {t('profiles.add.name.label')}
                                </Label>
                                <Form.Control
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={touched.name && !!errors.name}
                                    placeholder={t('profiles.add.name.placeholder')}
                                    maxLength={PROFILE_NAME_MAX}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Column>
                            <Column gap={12} stretched>
                                <Label htmlFor="gates">
                                    {t('profiles.add.applyToGates.label')}
                                </Label>
                                <GatesWindowedSelect formik={formik} />
                            </Column>
                        </Column>
                        <Panel stretched padding={32} gap={32} color="secondary">
                            <ProfilesAccessRules formik={formik} />
                        </Panel>
                    </Column>
                </Content>
            </Container>
            <div className={styles.spacer} />
            <div className={styles.actionsButtonsWrapper}>
                <Button
                    disabled={isSavingProcess || isLoading}
                    color="outline"
                    href={ACCESS_PROFILES_PAGE}
                >
                    {t('profiles.action.cancel')}
                </Button>
                <Button
                    onClick={onSubmitHandler}
                    disabled={!hasAnyChanges}
                    loading={isSavingProcess || isLoading}
                >
                    {t('profiles.action.save')}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default ProfileAdd;
