import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    SubscriptionsHeader,
    SubscriptionsFooter,
    SellSubscriptionModal,
} from 'components';

import { SoldSubscriptionsTableColumns } from 'components/subscriptions/SoldSubscriptionsTableColumns';
import { SoldSubscriptionsTableRow } from 'components/subscriptions/SoldSubscriptionsTableRow';

import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { Button } from 'components/Common/Button';
import { useDialog } from 'hooks/useDialog';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { useTableSelection } from 'hooks/useTableSelection';
import { SentIcon } from 'components/Icon/Icon';
import { SOLD_SUBSCRIPTION_STATUS } from 'const/subscriptions/SOLD_SUBSCRIPTION_STATUS';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { MESSAGES_ADD_PAGE } from '../../../../../../const/CLIENT_URL';

import * as SUBSCRIPTIONS_ACTIONS from '../../../../../../store/actions/subscriptions';

import * as SUBSCRIPTIONS_SELECTORS from '../../../../../../store/selectors/subscriptions';

function SoldSubscriptions() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t: tc } = useTranslation();
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);

    const {
        subscriptionsStatus,
        isDataLoaded,
        soldSubscriptionsList,
        searchValue,
        idFilter,
        statusFilter,
        hideInactive,
    } = useSelector(SUBSCRIPTIONS_SELECTORS.soldSubscriptionsListPageSelector);
    const {
        currentPage, itemsCount, pagesCount, showAll,
    } = useSelector(
        SUBSCRIPTIONS_SELECTORS.soldSubscriptionsTableFooterSelector,
    );

    const sellSeubscriptionModal = useDialog(false);

    const handleSearch = useCallback((search) => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldSearchValue(search));
    }, [dispatch]);

    const handleStatusFilterChange = useCallback((filter) => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setStatusFilter(filter));
    }, [dispatch]);

    const handleIdFilterChange = useCallback((filter) => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setIdFilter(filter));
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldShowAll(true));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(currentPage - 1));
    }, [dispatch, currentPage]);

    const handleNextPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(currentPage + 1));
    }, [dispatch, currentPage]);

    const handleLastPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(pagesCount));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(1));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldShowAll(false));
    }, [dispatch]);

    const handleChangePage = useCallback(
        ({ page: nextPage }) => {
            dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(nextPage));
        },
        [dispatch],
    );

    useEffect(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.getSoldSubscriptionsStatistics());
    }, [dispatch]);

    useEffect(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.getSoldSubscriptionsList());
        dispatch(SUBSCRIPTIONS_ACTIONS.resetBookingsToCanceling());
    }, [dispatch, currentPage, searchValue, idFilter, statusFilter, hideInactive]);

    useEffect(
        () => () => {
            // on page unmount - clear the search value in the store
            dispatch(SUBSCRIPTIONS_ACTIONS.resetSoldSubscriptionsReducer());
        },
        [dispatch],
    );

    const onExport = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.reqestExportSoldSubscribtions());
    }, [dispatch]);

    useEffect(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.getSubscriptionsOptions());
    }, []);

    const tableSelection = useTableSelection({
        itemsOnPageRecordWithId: soldSubscriptionsList,
        totalCount: itemsCount,
        onGetTotalItemsWithId: async () => dispatch(SUBSCRIPTIONS_ACTIONS.getSoldSubscriptionsListOptions()),
    });

    const selectedSubscriptions = tableSelection.selected;

    useEffect(() => {
        tableSelection.resetTotalItemsWithId();
        tableSelection.deselectAll();
    }, [searchValue, idFilter, statusFilter, hideInactive]);

    const handleSendMessage = useCallback(async () => {
        const clientsIds = selectedSubscriptions.map(({ clientId }) => Number(clientId));
        const selectedClientsIds = Array.from(new Set(clientsIds));
        history.push(MESSAGES_ADD_PAGE, { selectedClientsIds });
    }, [selectedSubscriptions, history]);

    const closedSelected = useMemo(() => selectedSubscriptions.filter(({ subscriptionStatus }) => subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.CLOSED), [selectedSubscriptions]);
    const pausedSelected = useMemo(
        () => selectedSubscriptions.filter(
            ({ pause, payments, subscriptionStatus }) => subscriptionStatus !== SOLD_SUBSCRIPTION_STATUS.CLOSED
    && (pause?.status || (subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN && payments?.at(-1)?.paymentPaused)),
        ),
        [selectedSubscriptions],
    );
    const activeSelected = useMemo(() => selectedSubscriptions.filter(
        ({ pause, payments, subscriptionStatus }) => subscriptionStatus !== SOLD_SUBSCRIPTION_STATUS.CLOSED
    && !pause?.status && !(subscriptionStatus === SOLD_SUBSCRIPTION_STATUS.OPEN && payments?.at(-1)?.paymentPaused),
    ), [selectedSubscriptions]);

    const handleTogglehHideInactive = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldHideInactive(!hideInactive));
    }, [dispatch, hideInactive]);

    return (
        <React.Fragment>
            <SubscriptionsHeader
                showSalesInfo
                showSubscriptionsFilter
                subscriptionsStatus={subscriptionsStatus}
                onButtonClickHandler={sellSeubscriptionModal.onShow}
                searchValue={searchValue}
                onSearch={handleSearch}
                exportButton={
                    soldSubscriptionsList.length ? (
                        <Button
                            color="outline"
                            onClick={onExport}
                        >
                            {tc('placeholders.export')}
                        </Button>
                    ) : null
                }
                onIdFilterChange={handleIdFilterChange}
                onStatusFilterChange={handleStatusFilterChange}
                hideInactive={hideInactive}
                handleTogglehHideInactive={handleTogglehHideInactive}
            />
            <Container>
                <Content loading={!isDataLoaded}>
                    <Table
                        multiselectPanel={{
                            selectedCount: tableSelection.selectedCount,
                            totalCount: tableSelection.totalCount,
                            onSelectAll: tableSelection.selectAll,
                            onDeselectAll: tableSelection.deselectAll,
                            loading: tableSelection.loading,
                            actions: [{
                                label: tc('placeholders.sendMessage'),
                                onClick: handleSendMessage,
                                icon: <SentIcon />,
                                loading: tableSelection.loading,
                            }],
                            info: selectedSubscriptions.length ? [
                                { label: t('sold.table.header.statuses.active'), count: activeSelected.length },
                                { label: t('sold.table.header.statuses.paused'), count: pausedSelected.length },
                                { label: t('sold.table.header.statuses.ended'), count: closedSelected.length },
                            ] : [],
                        }}
                        footer={{
                            page: currentPage,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: 13,
                            itemsPerPage: 10,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader selected={tableSelection.currentPageAllSelected} onSelect={tableSelection.toggleAllOnCurrentPage}>
                            <SoldSubscriptionsTableColumns />
                        </TableHeader>
                        <TableBody>
                            {soldSubscriptionsList?.map((soldSubscription) => (
                                <SoldSubscriptionsTableRow
                                    key={soldSubscription?.id}
                                    soldSubscription={soldSubscription}
                                    selected={tableSelection.isSelected(soldSubscription)}
                                    onSelect={() => tableSelection.toggle(soldSubscription)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <SubscriptionsFooter
                        onButtonClickHandler={sellSeubscriptionModal.onShow}
                        onSearch={handleSearch}
                    />
                </Content>
            </Container>
            {
                sellSeubscriptionModal.visible && (
                    <SellSubscriptionModal
                        onClose={sellSeubscriptionModal.onClose}
                    />
                )
            }
        </React.Fragment>
    );
}

export default SoldSubscriptions;
