import React, { useCallback, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import * as PRODUCTS_ACTIONS from 'store/actions/products';
import * as INVOICES_ACTIONS from 'store/actions/financials/invoices';

import InvoicesAddEditFormFooter from 'components/Financials/Invoices/InvoicesAddEditFormFooter';
import InvoicesAddEditFormHeader from 'components/Financials/Invoices/InvoicesAddEditFormHeader';
import InvoicesInfoFormGroup from 'components/Financials/Invoices/InvoicesInfoFormGroup';
import moment from 'moment';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import InvoiceProductsFormGroup from 'components/Financials/Invoices/InvoiceProductsFormGroup';
import { invoicesSavingSelector } from 'store/selectors/financilas';
import { productsListLoadingSelector } from 'store/selectors/products';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import { ConfirmationModal } from 'components';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { Content } from 'components/Common/Content';
import sentry from 'services/sentry';
import { productInitialData } from '../editInvoice/page';

const formikInitialValues = {
    client: null,
    note: '',
    issueDate: moment(),
    dueDate: moment().add(2, 'weeks'),
    status: INVOICE_PROP.STATUS.DEFAULT,
    products: [productInitialData()],
};

function AddNewSubscription() {
    const { t: tc } = useTranslation();
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const dispatch = useDispatch();
    const saving = useSelector(invoicesSavingSelector);
    const loading = useSelector(productsListLoadingSelector);
    const history = useHistory();
    const fixedClientId = history.location.state?.selectedClientId || null;

    useEffect(() => {
        dispatch(PRODUCTS_ACTIONS.getProducts());
    }, [dispatch]);

    const validationSchema = useMemo(() => yup.object({
        client: yup.mixed().required(tc('validationErrors.cannotBeEmpty', {
            name: tc('addBookingModal.client'),
        })),
        note: yup.string()
            .trim()
            .max(
                INVOICE_PROP.NOTE.MAX_LENGTH,
                tc('validationErrors.maxLength', {
                    name: t('add.note.label'),
                    length: INVOICE_PROP.NOTE.MAX_LENGTH,
                }),
            ),
        issueDate: yup.date()
            .required(tc('validationErrors.cannotBeEmpty', {
                name: t('add.issueDate.label'),
            }))
            .max(yup.ref('dueDate'), tc('validationErrors.dateOrAfter')),
        dueDate: yup.date()
            .required(tc('validationErrors.cannotBeEmpty', {
                name: t('add.dueDate.label'),
            }))
            .min(yup.ref('issueDate'), tc('validationErrors.dateOrBefore')),
        products: yup.array()
            .of(yup.object({
                name: yup.string()
                    .trim()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.name.label'),
                    })),
                price: yup.number()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.price.label'),
                    })),
                vatValue: yup.string()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.vatValue.label'),
                    })),
                quantity: yup.number()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.quantity.label'),
                    })),
            }))
            .min(1, tc('validationErrors.cannotBeEmpty', {
                name: t('add.products.label'),
            })),
    }), [t]);

    const handleSave = useCallback((invoice) => {
        const data = new FormData();
        data.append('clientId', invoice.client.clientId);
        data.append('note', invoice.note);
        data.append('issueDate', invoice.issueDate.unix());
        data.append('dueDate', invoice.dueDate.unix());
        data.append('status', invoice.status === INVOICE_STATUS.UNPAID ? 'open' : invoice.status);

        const products = invoice.products.filter(({ id }) => !String(id).startsWith('new-'));
        const productsToCreate = invoice.products.filter(({ id }) => String(id).startsWith('new-'));
        if (products.length) {
            products.forEach((c, i) => data.append(`products[${i}]`, JSON.stringify({
                id: c.id, quantity: c.quantity, price: c.price, vatValue: c.vatValue,
            })));
        }
        if (productsToCreate.length) {
            productsToCreate.forEach((c, i) => data.append(`productsToCreate[${i}]`, JSON.stringify({
                name: c.name, quantity: c.quantity, price: c.price, vatValue: c.vatValue,
            })));
        }

        dispatch(INVOICES_ACTIONS.addCustomInvoice({ data, history })).catch(sentry.ignore);
    }, [dispatch, history]);

    const saveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: handleSave,
    });

    const onSubmit = useCallback((invoice) => {
        if (invoice.status === INVOICE_STATUS.PAID || invoice.status === INVOICE_STATUS.UNPAID) {
            saveConfirmDialog.onSetData(invoice);
            saveConfirmDialog.onShow();
        } else {
            handleSave(invoice);
        }
    }, [handleSave, saveConfirmDialog]);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
    });

    const { values, handleSubmit } = formik;

    return (
        <React.Fragment>
            <InvoicesAddEditFormHeader />
            <Form onSubmit={handleSubmit} className="d-flex flex-grow-1 flex-column">
                <Content loading={loading}>
                    <div className="d-flex flex-column px-lg-5 align-self-stretch">

                        <InvoicesInfoFormGroup formik={formik} fixedClientId={fixedClientId} />
                        <InvoiceProductsFormGroup
                            formik={formik}
                        />

                    </div>
                    <InvoicesAddEditFormFooter disabled={!values.products.length} loading={saving} />
                    {saveConfirmDialog.visible && (
                        <ConfirmationModal
                            isShow={saveConfirmDialog.visible}
                            titleText={tc('placeholders.createInvoice')}
                            bodyText={(
                                <Trans
                                    t={t}
                                    i18nKey="add.saveDialog.body.text"
                                    values={{
                                        name: `${values.client?.clientName} ${values.client?.clientLastName}`,
                                        status: t(`add.status.${values.status}.label`),
                                    }}
                                />
                            )}
                            deleteText={t('add.saveDialog.actions.save')}
                            dismissText={t('add.saveDialog.actions.cancel')}
                            confirmAction={saveConfirmDialog.onConfirm}
                            hide={saveConfirmDialog.onReject}
                        />
                    )}
                </Content>
            </Form>
        </React.Fragment>
    );
}

export default AddNewSubscription;
