import React from 'react';

const Info = ({ width = 24, height = 24, className }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 3C7.0374 3 3 7.0374 3 12C3 16.9626 7.0374 21 12 21C16.9626 21 21 16.9626 21 12C21 7.0374 16.9626 3 12 3ZM12 19.2C8.0301 19.2 4.8 15.9699 4.8 12C4.8 8.0301 8.0301 4.8 12 4.8C15.9699 4.8 19.2 8.0301 19.2 12C19.2 15.9699 15.9699 19.2 12 19.2Z" fill="currentColor" />
        <path d="M11.1 12C11.1 11.5029 11.5029 11.1 12 11.1C12.4971 11.1 12.9 11.5029 12.9 12V15.6C12.9 16.0971 12.4971 16.5 12 16.5C11.5029 16.5 11.1 16.0971 11.1 15.6V12ZM11.1 8.4C11.1 7.90294 11.5029 7.5 12 7.5C12.4971 7.5 12.9 7.90294 12.9 8.4C12.9 8.89706 12.4971 9.3 12 9.3C11.5029 9.3 11.1 8.89706 11.1 8.4Z" fill="currentColor" />
    </svg>

);

export default Info;
