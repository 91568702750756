import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextSubHeader } from 'components/Layout/TextSubHeader';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { ClearingsInfoPanel } from '../ClearingsInfoPanel';

const ClearingsPageHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.COMMON);

    return (
        <React.Fragment>
            <TextSubHeader text={t('navigation.clearings')} />
            <ClearingsInfoPanel />
        </React.Fragment>
    );
};

ClearingsPageHeader.propTypes = {
    onClearing: PropTypes.func,
};

ClearingsPageHeader.defaultProps = {
    onClearing: PropTypes.emptyFunc,
};

export default ClearingsPageHeader;
