import React from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon / Calendar">
            <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M9.23179 4.05914C9.23179 3.50686 8.78407 3.05914 8.23179 3.05914C7.6795 3.05914 7.23179 3.50686 7.23179 4.05914V4.9457H7.1333C4.92416 4.9457 3.1333 6.73656 3.1333 8.9457V16.9041C3.1333 18.2848 3.83286 19.5021 4.89686 20.221C5.16171 20.3999 5.44915 20.5479 5.754 20.6599C6.18398 20.8179 6.6486 20.9042 7.13336 20.9042H16.8649C19.0741 20.9042 20.8649 19.1133 20.8649 16.9042L20.8649 8.9457C20.8649 6.73656 19.074 4.9457 16.8649 4.9457H16.7677V4.05914C16.7677 3.50686 16.32 3.05914 15.7677 3.05914C15.2154 3.05914 14.7677 3.50686 14.7677 4.05914V4.9457H9.23179V4.05914ZM18.8649 10.2652V8.9457C18.8649 7.84113 17.9694 6.9457 16.8649 6.9457H16.7677V7.32963C16.7677 7.88191 16.32 8.32963 15.7677 8.32963C15.2154 8.32963 14.7677 7.88191 14.7677 7.32963V6.9457H9.23179V7.32963C9.23179 7.88191 8.78407 8.32963 8.23179 8.32963C7.6795 8.32963 7.23179 7.88191 7.23179 7.32963V6.9457H7.1333C6.02873 6.9457 5.1333 7.84113 5.1333 8.9457V10.2652H18.8649Z" fill="currentColor" />
        </g>
    </svg>

);

Calendar.propTypes = {
    className: PropTypes.string,
};

Calendar.defaultProps = {
    className: '',
};

export default Calendar;
