import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { Column } from 'components/Common/Column';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ClientEmailsTableHeaderRow } from 'components/clients/ClientPage/ClientTables/ClientEmails/ClientEmailsTableHeaderRow';
import { ClientEmailsTableBodyRow } from 'components/clients/ClientPage/ClientTables/ClientEmails/ClientEmailsTableBodyRow';
import { Row } from 'components/Common/Row';
import { PeriodPicker } from 'components/Common/PeriodPicker';
import moment from 'moment';
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import { ClientEmailsStatusFilter } from 'components/clients/ClientPage/ClientTables/ClientEmails/ClientEmailsStatusFilter';
import { ENVS } from 'services/envs';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useMobile } from 'hooks/useMobile';
import { Badge } from 'components/Common/Badge';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';
import * as CLIENTS_EMAILS from '../../../../../../store/actions/clients/tables/emails';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 5 + Number(ENVS.isDevelopment);

function ClientEmailsTable() {
    const { clientId } = useParams();

    const dispatch = useDispatch();
    const isMobile = useMobile();

    const {
        page,
        items: emails,
        showAll,
        loading,
        itemsCount,
        pagesCount,
        filters,
        sortOptions,
    } = useSelector(CLIENTS_SELECTORS.clientEmailsListSelector);

    const emailsList = useMemo(() => (
        (!showAll && emails.length > ITEMS_PER_PAGE)
            ? emails.slice(0, ITEMS_PER_PAGE)
            : emails
    ), [showAll, emails]);

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_EMAILS.setClientEmailsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(CLIENTS_EMAILS.getClientEmails({ clientId }));
    }, [
        dispatch,
        page,
        showAll,
        filters,
        sortOptions,
    ]);

    const handleCellClick = useCallback((sortBy) => {
        if (sortOptions.sortBy === sortBy) {
            dispatch(CLIENTS_EMAILS.setClientEmailsSortOptions({
                sortOptions: {
                    orderBy: sortOptions.orderBy * -1,
                },
            }));
        } else {
            dispatch(CLIENTS_EMAILS.setClientEmailsSortOptions({
                sortOptions: {
                    sortBy,
                    orderBy: 1,
                },
            }));
        }
    }, [dispatch, sortOptions]);

    const handleChangeStatusFilter = useCallback((value) => {
        dispatch(CLIENTS_EMAILS.setClientEmailsFilters({
            filters: {
                statusFilter: value,
            },
        }));
    }, [dispatch]);

    const handleChangeDateFilter = useCallback(({ dateFrom, dateTo }) => {
        dispatch(CLIENTS_EMAILS.setClientEmailsFilters({
            filters: {
                from: dateFrom.format('X'),
                to: dateTo.format('X'),
            },
        }));
    }, [dispatch]);

    useEffect(() => () => {
        dispatch(CLIENTS_EMAILS.clearClientEmailsFilters());
    }, []);

    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);

    return (
        <Column gap={32} stretched>
            <Row stretched gap={16} justify="between" wrap={isMobile}>
                <Badge size="extra-small" color="black">
                    {t('discountAndLoyalty.beta.label')}
                </Badge>
                <Row gap={16} stretched justify="end">
                    <PeriodPicker
                        initDateFrom={moment.unix(filters.from)}
                        initDateTo={moment.unix(filters.to)}
                        initPeriod={PERIODS_MAP.month}
                        onChange={handleChangeDateFilter}
                    />
                    <ClientEmailsStatusFilter onChange={handleChangeStatusFilter} />
                </Row>
            </Row>
            <Content loading={loading}>
                <Table
                    footer={{
                        page,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: COLUMNS_COUNT,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                >
                    <TableHeader data-testid="data-test-emails-list-header">
                        <ClientEmailsTableHeaderRow onCellClick={handleCellClick} sortOptions={sortOptions} />
                    </TableHeader>
                    <TableBody>
                        {emailsList.map((email) => (
                            <ListTableBodyRow key={email.id} data-testid={`data-test-emails-list-row-${email.id}`}>
                                <ClientEmailsTableBodyRow
                                    email={email}
                                />
                            </ListTableBodyRow>
                        ))}
                    </TableBody>
                </Table>
            </Content>
        </Column>
    );
}

export default ClientEmailsTable;
