/* eslint-disable import/prefer-default-export */
import { utc } from 'moment';
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import * as CLIENTS_TYPES from '../../../actions/clients/tables/invoices/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    totalRevenue: {},
    appyBeePay: {},
    manual: {},
    filters: {
        from: utc().startOf(PERIODS_MAP.month).format('X'),
        to: utc().endOf(PERIODS_MAP.month).format('X'),
    },
    search: '',
};

export const clientInvoicesListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLIENTS_TYPES.CLIENTS_INVOICES_GET_RESOLVED): {
        const {
            invoices,
            itemsCount,
            pagesCount,
            totalRevenue,
            appyBeePay,
            manual,
            exportEmail,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: invoices,
            page,
            itemsCount,
            pagesCount,
            totalRevenue,
            appyBeePay,
            manual,
            exportEmail,
            loading: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_GET_TRIGGER): {
        return {
            ...state,
            loading: true,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_GET_REJECTED): {
        return {
            ...state,
            loading: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_SEARCH_SET): {
        const { search } = payload;
        return {
            ...state,
            search,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_INVOICES_FILTERS_SET): {
        const { filters } = payload;

        const newFilters = {
            ...state.filters,
            ...filters,
        };

        let hasNewFilters = false;
        Object.keys(filters).forEach((key) => {
            if (state.filters[key] !== filters[key]) {
                hasNewFilters = true;
            }

            if (!filters[key] && filters[key] !== 0) {
                delete newFilters[key];
            }
        });

        return {
            ...state,
            filters: hasNewFilters ? newFilters : state.filters,
        };
    }
    case CLIENTS_TYPES.CLIENTS_INVOICES_FILTERS_CLEAR: {
        return {
            ...state,
            filters: INITIAL_STATE.filters,
            search: '',
        };
    }
    default:
        return state;
    }
};
