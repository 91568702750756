import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { SoldSubscriptionsTableColumns } from 'components/subscriptions/SoldSubscriptionsTableColumns';
import { SoldSubscriptionsTableRow } from 'components/subscriptions/SoldSubscriptionsTableRow';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import * as CLIENTS_ACTIONS from 'store/actions/clients/tables/subscriptions';
import * as SUBSCRIPTIONS_ACTIONS from 'store/actions/subscriptions';
import { Column } from 'components/Common/Column';
import { Switch } from 'components';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useTranslation } from 'react-i18next';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 10;

function ClientBundlesTable() {
    const { clientId } = useParams();

    const dispatch = useDispatch();

    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const {
        currentPage,
        items: subscriptions,
        showAll,
        isDataLoaded,
        itemsCount,
        pagesCount,
        hideInactive,
    } = useSelector(CLIENTS_SELECTORS.clientSubscriptionsSelector);

    const handleShowAll = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldShowAll(true));
    }, [dispatch]);

    const handlePrevPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(currentPage - 1));
    }, [dispatch, currentPage]);

    const handleNextPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(currentPage + 1));
    }, [dispatch, currentPage]);

    const handleLastPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(pagesCount));
    }, [dispatch, pagesCount]);

    const handleFirstPage = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(1));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldShowAll(false));
    }, [dispatch]);

    const handleChangePage = useCallback(
        ({ page: nextPage }) => {
            dispatch(SUBSCRIPTIONS_ACTIONS.setSoldCurrentPage(nextPage));
        },
        [dispatch],
    );

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientSubscriptions({ clientId }));
    }, [dispatch, currentPage, hideInactive]);

    useEffect(
        () => () => {
            // on page unmount - clear the search value in the store
            dispatch(SUBSCRIPTIONS_ACTIONS.resetSoldSubscriptionsReducer());
        },
        [dispatch],
    );

    const handleTogglehHideInactive = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.setSoldHideInactive(!hideInactive));
    }, [dispatch, hideInactive]);

    return (
        <Column stretched gap={32}>
            <Switch
                checked={hideInactive}
                onChange={handleTogglehHideInactive}
                label={t('subscriptions.list.header.actions.hideArchived')}
            />
            <Content loading={!isDataLoaded}>
                <Table
                    footer={{
                        page: currentPage,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: COLUMNS_COUNT,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                >
                    <TableHeader>
                        <SoldSubscriptionsTableColumns isClientPage />
                    </TableHeader>
                    <TableBody>
                        {subscriptions.map((soldSubscription) => (
                            <SoldSubscriptionsTableRow
                                key={soldSubscription?.id}
                                soldSubscription={soldSubscription}
                                isClientPage
                            />
                        ))}
                    </TableBody>
                </Table>
            </Content>
        </Column>
    );
}

export default ClientBundlesTable;
