import {
    put, call, select, takeEvery,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import camelize from 'camelize';

import axios from '../../services/axios';
import sentry from '../../services/sentry';
import { retryWithRefreshToken } from '../../helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from '../../helpers/http/getDefaultHeaders';

import * as actions from '../actions';
import * as COMPANY_ACTIONS from '../actions/company';
import * as TOAST_ACTIONS from '../actions/toast';

import { HTTP_STATUS_CODE } from '../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_COMPANY_SETTINGS_GENERAL_ROUTE,
    API_ADMIN_COMPANY_SETTINGS_ROUTE,
    API_ADMIN_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_ROUTE,
    API_ADMIN_COMPANY_SETTINGS_SHOP_ROUTE,
    API_ADMIN_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_ROUTE,
    API_ADMIN_COMPANY_QR_CODE_ROUTE,
} from '../../const/API_URL';

export function* getCompanyDetails(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const { id: shopID, name: shopName } = yield select((state) => state.shop);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/company/details',
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            yield put(actions.getCompanyDetailsFailed());
            return;
        }
        const details = camelize(resp.data.result);
        yield call(resolvePromiseAction, action, details);
        yield put(actions.getCompanyDetailsSucceeded(details));
        yield put(actions.getUserDetails());
        if (!shopID || !shopName) return;
        yield put(actions.getShopDetails({ id: shopID, name: shopName }));
    } catch (err) {
        let message = null;
        if (err.response?.status === 403) {
            message = err.response?.data?.result?.message;
            if (message) {
                yield put(actions.showToast({
                    message,
                    appearance: 'error',
                    isCustom: true,
                }));
            }
            yield put(actions.signOutSucceeded());
            return;
        }
        if (err.response && err.response.data && err.response.data.result) {
            message = err.response.data.result.message;
        }

        yield call(rejectPromiseAction, action, message);
        yield put(actions.getCompanyDetailsFailed());
    }
}

export function* getCompanyClients(action) {
    const { query = '' } = action.payload ?? {};

    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/company/clients?filter=${query}`,
            headers: defaultHeaders,
        });

        const clients = resp.data.result.map(({
            firstName: name, firstName, lastName, email, contactNumber, note, client_id: id,
        }) => ({
            id,
            name,
            firstName,
            lastName,
            email,
            contactNumber,
            note,
        }));
        yield call(resolvePromiseAction, action, clients);
    } catch (err) {
        let message = null;
        if (err.response && err.response.data && err.response.data.result) {
            message = err.response.data.result.message;
        } else {
            sentry.error(err);
        }

        yield call(rejectPromiseAction, action, message);
    }
}

export function* getCompanySettings(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_COMPANY_SETTINGS_ROUTE,
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const settings = camelize(resp.data.result);
        yield call(resolvePromiseAction, action, settings);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'settings.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export function* getCompanyQRCode(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_COMPANY_QR_CODE_ROUTE,
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const qrCode = camelize(resp.data.result);
        yield call(resolvePromiseAction, action, qrCode);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'settings.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export function* setCompanySettingsGeneral(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const { data } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_COMPANY_SETTINGS_GENERAL_ROUTE,
            data,
            headers: defaultHeaders,
        });
        if (resp.data?.result) {
            yield put(COMPANY_ACTIONS.getCompanySettings());
            yield call(resolvePromiseAction, action);
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            const errorMessages = error?.response?.data?.result?.message?.replace(':', '꞉');
            yield put(actions.showToast({
                message: errorMessages ?? 'settings.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

function* setCompanySettingsShop(action) {
    const { data } = action.payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_COMPANY_SETTINGS_SHOP_ROUTE,
            headers: defaultHeaders,
            data,
        });

        if (resp.data?.result) {
            yield put(COMPANY_ACTIONS.getCompanySettings());
            yield call(resolvePromiseAction, action);
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        const errors = error?.response?.data?.result?.errors;
        if (errors) {
            yield put(COMPANY_ACTIONS.setCompanySettingsShopErrors({ errors }));
        }

        yield call(rejectPromiseAction, action);
        function* handleFail() {
            const errorMessages = error?.response?.data?.result?.message?.replace(':', '꞉');
            yield put(actions.showToast({
                message: errorMessages ?? 'settings.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* setCompanySettingsDisplayAndNotifications(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const { data } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_ROUTE,
            data,
            headers: defaultHeaders,
        });

        if (resp.data?.result) {
            yield put(COMPANY_ACTIONS.getCompanySettings());
            yield call(resolvePromiseAction, action);
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            const errorMessages = error?.response?.data?.result?.message?.replace(':', '꞉');
            yield put(actions.showToast({
                message: errorMessages ?? 'settings.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export function* setCompanySettingsDiscountAndLoyalty(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const { data } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_ROUTE,
            data,
            headers: defaultHeaders,
        });

        if (resp.data?.result) {
            yield put(COMPANY_ACTIONS.getCompanySettings());
            yield call(resolvePromiseAction, action);
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        function* handleFail() {
            const errorMessages = error?.response?.data?.result?.message?.replace(':', '꞉');
            yield put(actions.showToast({
                message: errorMessages ?? 'settings.edit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export const companySaga = [
    takeEvery(actions.getCompanyDetails, getCompanyDetails),
    takeEvery(actions.getCompanyClients, getCompanyClients),
    takeEvery(actions.getCompanySettings, getCompanySettings),
    takeEvery(actions.getCompanyQRCode, getCompanyQRCode),
    takeEvery(actions.setCompanySettingsGeneral, setCompanySettingsGeneral),
    takeEvery(actions.setCompanySettingsDisplayAndNotifications, setCompanySettingsDisplayAndNotifications),
    takeEvery(actions.setCompanySettingsShop, setCompanySettingsShop),
    takeEvery(actions.setCompanySettingsDiscountAndLoyalty, setCompanySettingsDiscountAndLoyalty),
];
