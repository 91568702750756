import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { Dropdown, Form } from 'react-bootstrap';

import { SETTINGS_COMPANY_LOCATIONS_ADD_PAGE } from 'const/CLIENT_URL';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import Map from 'components/Map/Map';
import {
    setCompanySettingsShop,
    setCompanySettingsShopErrors,
    getAddressByCoordinates,
    getCoordinatesByAddress,
} from 'store/actions';
import sentry from 'services/sentry';
import { Button } from 'components/Common/Button';
import { hoursFromToSchema } from 'helpers/validation/hoursFromToSchema';
import { generateRandomString } from '../../../../../../helpers/string/generateRandomString';

import PhoneInput from '../../../../../../components/PhoneInput/PhoneInput';
import NumberInput from '../../../../../../components/Common/NumberInput';
import Schedule from '../../../../../../components/NewSchedule/Schedule';
// import { WorkBreaks } from '../../../../../../components/NewWorkBreaks';
import { DateCardList } from '../../../../../../components/DateCardList';

import * as styles from './page.module.scss';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const MAX_LOCATION_NAME_LENGTH = 64;
const MAX_STREET_LENGTH = 250;
const MAX_ZIP_CODE_LENGTH = 10;
const MAX_CITY_LENGTH = 32;
const MAX_COUNTRY_LENGTH = 32;
const MAX_PHONE_LENGTH = 24;
const MAX_EMAIL_LENGTH = 128;
const MAX_WEBSITE_LENGTH = 128;
const MAX_CLOSED_DATES_NAME_LENGTH = 128;
const MAX_ADJUSTED_OPENING_HOURS_NAME_LENGTH = 128;
const MIN_CANCELLATION_WINDOW = 0;
const MAX_CANCELLATION_WINDOW = 168;
const MIN_NO_PAY_BOOKING_LIMIT = 0;
const MAX_NO_PAY_BOOKING_LIMIT = 100;
const MIN_ZERO_PRICE_BOOKING_LIMIT = 1;
const MAX_ZERO_PRICE_BOOKING_LIMIT = 100;
const MIN_ADVANCE_BOOKING_LIMIT = 1;
const MAX_ADVANCE_BOOKING_LIMIT = 100;

const T_PREFIX = 'locations.location';

const getFormData = (shopId, data) => {
    const formData = new FormData();

    if (shopId) {
        formData.append('shopId', shopId);
    }

    formData.append('name', data.locationName);
    formData.append('street', data.street);
    formData.append('zip', data.zipCode);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('contactNumber', data.phone);
    formData.append('contactEmail', data.email);
    formData.append('website', data.website);
    formData.append('cancellationPolicy', data.cancellationWindow);
    formData.append('maxOpenBookingsCash', data.noPayBookingLimit);
    formData.append('maxOpenBookingsDiscount', data.zeroPriceBookingLimit);
    formData.append('maxOpenBookingsUpfront', data.advanceBookingLimit);
    Object.entries(data.openingHours ?? []).forEach(([key, value]) => {
        formData.append(
            `periods[${key}]`,
            JSON.stringify({
                from: value.from.format('HH:mm'),
                to: value.to.format('HH:mm'),
            }),
        );
    });

    // Object.entries(data.workBreaks).forEach(([key, value]) => {
    //     formData.append(`workBreaks[${key}]`, JSON.stringify(value));
    // });

    const specialDays = [...(data.closedDates ?? []), ...(data.adjustedOpeningHours ?? [])].map((date) => {
        const formattedDate = {
            name: date.name,
            date: date.startDate.format('DD/MM'),
            id: date.id,
        };

        if (date.startTime && date.endTime) {
            Object.assign(formattedDate, {
                openingTime: date.startTime.format('HH:mm'),
                closingTime: date.endTime.format('HH:mm'),
            });
        }

        return formattedDate;
    });

    const specialDaysToSave = specialDays.filter(({ id }) => !String(id).startsWith('new-'));
    const specialDaysToCreate = specialDays.filter(({ id }) => String(id).startsWith('new-'));
    if (specialDaysToSave.length) {
        specialDaysToSave.forEach((c, i) => formData.append(`specialDaysToSave[${i}]`, c.id));
    }
    if (specialDaysToCreate.length) {
        specialDaysToCreate.forEach((c, i) => formData.append(`specialDaysToCreate[${i}]`, JSON.stringify(c)));
    }

    return formData;
};

const Location = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const dispatch = useDispatch();
    const {
        isSavingProcess,
        shops,
        settingsShopErrors,
    } = useSelector((state) => state.company);
    const { id } = useParams();
    const history = useHistory();

    const shopIdRef = useRef(id); // so that it is not cached in formik

    const location = useMemo(() => (
        shops.find((shop) => id && shop.id === Number(id))
    ), [shops, id]);

    if (!location) {
        history.push(SETTINGS_COMPANY_LOCATIONS_ADD_PAGE);
    }

    const validationSchema = useMemo(() => yup.object({
        locationName: yup
            .string()
            .required(t(`${T_PREFIX}.errors.required`, { name: t(`${T_PREFIX}.locationName.label`) }))
            .max(
                MAX_LOCATION_NAME_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: 'Location name', length: MAX_LOCATION_NAME_LENGTH }),
            ),
        street: yup
            .string()
            .required(t(`${T_PREFIX}.errors.required`, { name: t(`${T_PREFIX}.address.street.label`) }))
            .max(
                MAX_STREET_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.address.street.label`), length: MAX_STREET_LENGTH }),
            ),
        zipCode: yup
            .string()
            .required(t(`${T_PREFIX}.errors.required`, { name: t(`${T_PREFIX}.address.zipCode.label`) }))
            .max(
                MAX_ZIP_CODE_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.address.zipCode.label`), length: MAX_ZIP_CODE_LENGTH }),
            ),
        city: yup
            .string()
            .required(t(`${T_PREFIX}.errors.required`, { name: t(`${T_PREFIX}.address.city.label`) }))
            .max(
                MAX_CITY_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.address.city.label`), length: MAX_CITY_LENGTH }),
            ),
        country: yup
            .string()
            .required(t(`${T_PREFIX}.errors.required`, { name: t(`${T_PREFIX}.address.country.label`) }))
            .max(
                MAX_COUNTRY_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.address.country.label`), length: MAX_COUNTRY_LENGTH }),
            ),
        phone: yup
            .string()
            .required(t(`${T_PREFIX}.errors.required`, { name: t(`${T_PREFIX}.contacts.phone.label`) }))
            .max(
                MAX_PHONE_LENGTH + 1,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.contacts.phone.label`), length: MAX_PHONE_LENGTH }),
            ),
        email: yup
            .string()
            .max(
                MAX_EMAIL_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.contacts.email.label`), length: MAX_EMAIL_LENGTH }),
            ),
        website: yup
            .string()
            .max(
                MAX_WEBSITE_LENGTH,
                t(`${T_PREFIX}.errors.maxLength`, { name: t(`${T_PREFIX}.contacts.website.label`), length: MAX_WEBSITE_LENGTH }),
            ),
        cancellationWindow: yup
            .number()
            .required(t(`${T_PREFIX}.errors.required`), { name: t(`${T_PREFIX}.bookings.cancellationWindow.label`) })
            .min(
                MIN_CANCELLATION_WINDOW,
                t(`${T_PREFIX}.errors.minValue`, { name: t(`${T_PREFIX}.bookings.cancellationWindow.label`), value: MIN_CANCELLATION_WINDOW }),
            )
            .max(
                MAX_CANCELLATION_WINDOW,
                t(`${T_PREFIX}.errors.maxValue`, { name: t(`${T_PREFIX}.bookings.cancellationWindow.label`), value: MAX_CANCELLATION_WINDOW }),
            ),
        noPayBookingLimit: yup
            .number()
            .required(t(`${T_PREFIX}.errors.required`), { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.noPayBookingLimit.label`) })
            .min(
                MIN_NO_PAY_BOOKING_LIMIT,
                t(`${T_PREFIX}.errors.minValue`, { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.noPayBookingLimit.label`), value: MIN_NO_PAY_BOOKING_LIMIT }),
            )
            .max(
                MAX_NO_PAY_BOOKING_LIMIT,
                t(`${T_PREFIX}.errors.maxValue`, { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.noPayBookingLimit.label`), value: MAX_NO_PAY_BOOKING_LIMIT }),
            ),
        zeroPriceBookingLimit: yup
            .number()
            .required(t(`${T_PREFIX}.errors.required`), { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.zeroPriceBookingLimit.label`) })
            .min(
                MIN_ZERO_PRICE_BOOKING_LIMIT,
                t(`${T_PREFIX}.errors.minValue`, { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.zeroPriceBookingLimit.label`), value: MIN_ZERO_PRICE_BOOKING_LIMIT }),
            )
            .max(
                MAX_ZERO_PRICE_BOOKING_LIMIT,
                t(`${T_PREFIX}.errors.maxValue`, { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.zeroPriceBookingLimit.label`), value: MAX_ZERO_PRICE_BOOKING_LIMIT }),
            ),
        advanceBookingLimit: yup
            .number()
            .required(t(`${T_PREFIX}.errors.required`), { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.advanceBookingLimit.label`) })
            .min(
                MIN_ADVANCE_BOOKING_LIMIT,
                t(`${T_PREFIX}.errors.minValue`, { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.advanceBookingLimit.label`), value: MIN_ADVANCE_BOOKING_LIMIT }),
            )
            .max(
                MAX_ADVANCE_BOOKING_LIMIT,
                t(`${T_PREFIX}.errors.maxValue`, { name: t(`${T_PREFIX}.bookings.maxNumOpenBookings.advanceBookingLimit.label`), value: MAX_ADVANCE_BOOKING_LIMIT }),
            ),
        openingHours: yup.object({
            Monday: hoursFromToSchema().default(undefined),
            Tuesday: hoursFromToSchema().default(undefined),
            Wednesday: hoursFromToSchema().default(undefined),
            Thursday: hoursFromToSchema().default(undefined),
            Friday: hoursFromToSchema().default(undefined),
            Saturday: hoursFromToSchema().default(undefined),
            Sunday: hoursFromToSchema().default(undefined),
        }),
        // workBreaks: yup.object({
        //     Monday: yup.array(hoursFromToSchema().default(undefined)),
        //     Tuesday: yup.array(hoursFromToSchema().default(undefined)),
        //     Wednesday: yup.array(hoursFromToSchema().default(undefined)),
        //     Thursday: yup.array(hoursFromToSchema().default(undefined)),
        //     Friday: yup.array(hoursFromToSchema().default(undefined)),
        //     Saturday: yup.array(hoursFromToSchema().default(undefined)),
        //     Sunday: yup.array(hoursFromToSchema().default(undefined)),
        // }),
        closedDates: yup.array(
            yup.object({
                name: yup
                    .string()
                    .required(t(`${T_PREFIX}.errors.requiredWithoutOption`))
                    .max(
                        MAX_CLOSED_DATES_NAME_LENGTH,
                        t(`${T_PREFIX}.errors.maxLengthWithoutOption`, { length: MAX_CLOSED_DATES_NAME_LENGTH }),
                    ),
                startDate: yup.mixed().required(),
                id: yup.string().default(undefined),
            }).default(undefined),
        ),
        adjustedOpeningHours: yup.array(
            yup.object({
                name: yup
                    .string()
                    .required(t(`${T_PREFIX}.errors.requiredWithoutOption`))
                    .max(
                        MAX_ADJUSTED_OPENING_HOURS_NAME_LENGTH,
                        t(`${T_PREFIX}.errors.maxLengthWithoutOption`, { length: MAX_ADJUSTED_OPENING_HOURS_NAME_LENGTH }),
                    ),
                startDate: yup.mixed().required(),
                startTime: yup.mixed().required(),
                endTime: yup.mixed().required().when((startTime, schema) => schema.test({
                    name: generateRandomString(),
                    test: (endTime) => startTime.isSameOrBefore(endTime),
                })),
                id: yup.string().default(undefined),
            }).default(undefined),
        ),
    }), [t]);

    const initialValues = useMemo(() => ({
        locationName: location?.name ?? '',
        street: location?.street ?? '',
        zipCode: location?.zip ?? '',
        city: location?.city ?? '',
        country: location?.country ?? '',
        latitude: location?.latitude ?? 0,
        longitude: location?.longitude ?? 0,
        phone: location?.contactNumber ?? '',
        email: location?.contactEmail ?? '',
        website: location?.website ?? '',
        cancellationWindow: location?.cancellationPolicy ?? 3,
        noPayBookingLimit: location?.maxOpenBookingsCash ?? 100,
        zeroPriceBookingLimit: location?.maxOpenBookingsDiscount ?? 10,
        advanceBookingLimit: location?.maxOpenBookingsUpfront ?? 5,
        openingHours: location
            ? Object.fromEntries(
                Object.entries(location?.workingDays ?? {})
                    .filter(([_, value]) => value.from && value.to)
                    .map(([key, value]) => [
                        key,
                        {
                            from: moment().set({
                                hours: value.from.split(':')[0],
                                minutes: value.from.split(':')[1],
                            }),
                            to: moment().set({
                                hours: value.to.split(':')[0],
                                minutes: value.to.split(':')[1],
                            }),
                        },
                    ]),
            )
            : {},
        // workBreaksEnable: Boolean(location?.workBreaks?.length),
        // workBreaks: location?.workBreaks ?? {},
        ...(location?.specialDays?.reduce((result, day) => {
            if (day.isWorkingDay) {
                result.adjustedOpeningHours.push({
                    name: day.name ?? '',
                    startDate: moment().set({
                        date: day.date.split('/')[0],
                        month: day.date.split('/')[1] - 1,
                    }),
                    startTime: moment(day.openingTime),
                    endTime: moment(day.closingTime),
                    id: day.id,
                });
            } else {
                result.closedDates.push({
                    name: day.name ?? '',
                    startDate: moment().set({
                        date: day.date.split('/')[0],
                        month: day.date.split('/')[1] - 1,
                    }),
                    id: day.id,
                });
            }

            return result;
        }, { closedDates: [], adjustedOpeningHours: [] })),
    }), [location]);

    const onSubmit = useCallback((values) => {
        const data = getFormData(shopIdRef.current, values);
        dispatch(setCompanySettingsShop({ data })).catch(sentry.ignore);
    }, [dispatch]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        setValues,
        setFieldError,
        values,
        touched,
        errors,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const [isGettingAddress, setGettingAddress] = useState(false);
    const [isGettingCoordinates, setGettingCoordinates] = useState(false);
    const [center, setCenter] = useState(null);

    useEffect(() => {
        if (isNaN(values.latitude) || isNaN(values.longitude)) {
            return;
        }

        setCenter({
            latitude: values.latitude,
            longitude: values.longitude,
        });
    }, [values.latitude, values.longitude]);

    const hasAnyChanges = useMemo(
        () => {
            const isSwitchLocationMoment = id !== shopIdRef.current;
            if (isSwitchLocationMoment) {
                return false;
            }

            const initValue = JSON.stringify([...getFormData(shopIdRef.current, initialValues).entries()]);
            const currentValue = JSON.stringify([...getFormData(shopIdRef.current, values).entries()]);

            return initValue !== currentValue;
        },
        [values, initialValues, id],
    );

    const closedDatesErrors = useMemo(
        () => errors.closedDates?.map((el, i) => touched.closedDates?.[i] && el),
        [errors.closedDates, touched.closedDates],
    );

    const adjustedOpeningHoursErrors = useMemo(
        () => errors.adjustedOpeningHours?.map((el, i) => touched.adjustedOpeningHours?.[i] && el),
        [errors.adjustedOpeningHours, touched.adjustedOpeningHours],
    );

    const handleDiscard = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const onSubmitHandler = useCallback((e) => {
        e.preventDefault();
        handleSubmit();
    }, [handleSubmit, values]);

    const onChangeOpeningHours = useCallback((newValues) => {
        setFieldValue('openingHours', newValues);
    }, []);

    // const onChangeWorkBreaks = useCallback((newValues) => {
    //     setFieldValue('workBreaks', newValues);
    // }, []);

    const onChangeClosedDates = useCallback((newValues) => {
        setFieldValue('closedDates', newValues);
    }, []);

    const onChangeAdjustedOpeningHours = useCallback((newValues) => {
        setFieldValue('adjustedOpeningHours', newValues);
    }, []);

    const handleSetAdress = useCallback((address) => {
        setFieldValue('street', address.street || '');
        setFieldValue('zipCode', address.zip || '');
        setFieldValue('city', address.city || '');
        setFieldValue('country', address.country || '');
    }, []);

    const handleSetCoordinates = useCallback((coordinates) => {
        setFieldValue('latitude', coordinates.latitude || 0);
        setFieldValue('longitude', coordinates.longitude || 0);
        setCenter({
            latitude: coordinates.latitude || 0,
            longitude: coordinates.longitude || 0,
        });
    }, []);

    const handlePlaceMarkerOnMap = useCallback(async () => {
        setGettingCoordinates(true);
        try {
            const coordinates = await dispatch(getCoordinatesByAddress({
                street: values.street || '',
                zip: values.zipCode || '',
                city: values.city || '',
                country: values.country || '',
            }));
            handleSetCoordinates(coordinates);
        } catch (e) {
            sentry.error(e);
        } finally {
            setGettingCoordinates(false);
        }
    }, [values]);

    const handleFillAddressByMarkerPosition = useCallback(async () => {
        setGettingAddress(true);
        try {
            const address = await dispatch(getAddressByCoordinates({
                latitude: values.latitude || 0,
                longitude: values.longitude || 0,
            }));
            handleSetAdress(address);
        } catch (e) {
            sentry.error(e);
        } finally {
            setGettingAddress(false);
        }
    }, [values]);

    useEffect(() => {
        if (!Object.keys(settingsShopErrors).length) {
            return;
        }

        if (settingsShopErrors.contactEmail) {
            setFieldError('email', t(`${T_PREFIX}.contacts.email.errors.notValid`));
        }
        if (settingsShopErrors.website) {
            setFieldError('website', t(`${T_PREFIX}.contacts.website.errors.notValid`));
        }

        dispatch(setCompanySettingsShopErrors({ errors: {} }));
    }, [settingsShopErrors]);

    useEffect(() => {
        shopIdRef.current = id;
        resetForm();
        setValues(initialValues);
    }, [id]);

    return (
        <Form
            onSubmit={onSubmitHandler}
            className={styles.form}
        >
            <Form.Group className="m-0">
                <Form.Label className={styles.label}>
                    {t(`${T_PREFIX}.locationName.label`)}
                </Form.Label>

                <Form.Control
                    type="text"
                    name="locationName"
                    value={values.locationName}
                    onChange={handleChange}
                    isInvalid={touched.locationName && !!errors.locationName}
                />

                <Form.Control.Feedback type="invalid">
                    {errors.locationName}
                </Form.Control.Feedback>
            </Form.Group>

            <Dropdown.Divider className={styles.divider} />

            <div className={styles.groupHeader}>
                {t(`${T_PREFIX}.address.header.label`)}
            </div>

            <div className={classNames(styles.multipleGroupWrapper, styles.addressGroupWrapper)}>
                <div className={styles.addressWrapper}>
                    <Form.Group className="m-0">
                        <Form.Label className={styles.label}>
                            {t(`${T_PREFIX}.address.street.label`)}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            name="street"
                            value={values.street}
                            onChange={handleChange}
                            isInvalid={touched.street && !!errors.street}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.street}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div className={styles.zipAndCityWrapper}>
                        <Form.Group className="m-0">
                            <Form.Label className={styles.label}>
                                {t(`${T_PREFIX}.address.zipCode.label`)}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                name="zipCode"
                                value={values.zipCode}
                                onChange={handleChange}
                                isInvalid={touched.zipCode && !!errors.zipCode}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.zipCode}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="m-0">
                            <Form.Label className={styles.label}>
                                {t(`${T_PREFIX}.address.city.label`)}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isInvalid={touched.city && !!errors.city}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <Form.Group className="m-0">
                        <Form.Label className={styles.label}>
                            {t(`${T_PREFIX}.address.country.label`)}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            isInvalid={touched.country && !!errors.country}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.country}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className={styles.addressActionsWrapper}>
                    <Button
                        type="button"
                        name="placeMarker"
                        onClick={handlePlaceMarkerOnMap}
                        loading={isGettingCoordinates}
                        stretched
                    >
                        {t(`${T_PREFIX}.address.placeMarkerOnMap.label`)}
                    </Button>
                    <Button
                        type="button"
                        name="fillAddress"
                        onClick={handleFillAddressByMarkerPosition}
                        loading={isGettingAddress}
                        stretched
                    >
                        {t(`${T_PREFIX}.address.fillAddressByMarkerPosition.label`)}
                    </Button>
                </div>
                <div className={styles.mapWrapper}>
                    {center ? (
                        <Map
                            name="map"
                            className="h-100"
                            center={center}
                            markerPosition={{
                                latitude: values.latitude,
                                longitude: values.longitude,
                            }}
                            onMarkerPositionChange={handleSetCoordinates}
                        />
                    ) : <div className="h-100" style={{ minHeight: 400 }} />}
                </div>
            </div>

            <Dropdown.Divider className={styles.divider} />

            <div className={styles.groupHeader}>
                {t(`${T_PREFIX}.contacts.header.label`)}
            </div>

            <div className={classNames(styles.multipleGroupWrapper, styles.contactsGroupWrapper)}>
                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.contacts.phone.label`)}
                    </Form.Label>

                    <PhoneInput
                        name="phone"
                        value={values.phone}
                        onChange={(value) => setFieldValue('phone', value)}
                        isInvalid={touched.phone && !!errors.phone}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.phone}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.contacts.email.label`)}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={touched.email && !!errors.email}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.contacts.website.label`)}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        name="website"
                        value={values.website}
                        onChange={handleChange}
                        isInvalid={touched.website && !!errors.website}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.website}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>

            <Dropdown.Divider className={styles.divider} />

            <div className={styles.groupHeader}>
                {t(`${T_PREFIX}.bookings.header.label`)}
            </div>

            <div className={classNames(styles.multipleGroupWrapper, styles.bookingsGroupWrapper)}>
                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.bookings.cancellationWindow.label`)}

                        <InfoTooltip
                            text={t(`${T_PREFIX}.bookings.cancellationWindow.tooltip`)}
                            placement="top"
                        />
                    </Form.Label>
                    <NumberInput
                        name="cancellationWindow"
                        value={values.cancellationWindow}
                        onChange={(value) => setFieldValue('cancellationWindow', value)}
                        isInvalid={touched.cancellationWindow && !!errors.cancellationWindow}
                        max={MAX_CANCELLATION_WINDOW}
                        min={MIN_CANCELLATION_WINDOW}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.cancellationWindow}
                    </Form.Control.Feedback>
                </Form.Group>

                <div className={styles.groupSubHeader}>
                    {t(`${T_PREFIX}.bookings.maxNumOpenBookings.label`)}
                </div>

                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.bookings.maxNumOpenBookings.noPayBookingLimit.label`)}

                        <InfoTooltip
                            text={t(`${T_PREFIX}.bookings.maxNumOpenBookings.noPayBookingLimit.tooltip`)}
                            placement="top"
                        />
                    </Form.Label>

                    <NumberInput
                        name="noPayBookingLimit"
                        value={values.noPayBookingLimit}
                        onChange={(value) => setFieldValue('noPayBookingLimit', value)}
                        isInvalid={touched.noPayBookingLimit && !!errors.noPayBookingLimit}
                        max={MAX_NO_PAY_BOOKING_LIMIT}
                        min={MIN_NO_PAY_BOOKING_LIMIT}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.noPayBookingLimit}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.bookings.maxNumOpenBookings.zeroPriceBookingLimit.label`)}
                    </Form.Label>

                    <NumberInput
                        name="zeroPriceBookingLimit"
                        value={values.zeroPriceBookingLimit}
                        onChange={(value) => setFieldValue('zeroPriceBookingLimit', value)}
                        isInvalid={touched.zeroPriceBookingLimit && !!errors.zeroPriceBookingLimit}
                        max={MAX_ZERO_PRICE_BOOKING_LIMIT}
                        min={MIN_ZERO_PRICE_BOOKING_LIMIT}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.zeroPriceBookingLimit}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="m-0">
                    <Form.Label className={styles.label}>
                        {t(`${T_PREFIX}.bookings.maxNumOpenBookings.advanceBookingLimit.label`)}
                    </Form.Label>

                    <NumberInput
                        name="advanceBookingLimit"
                        value={values.advanceBookingLimit}
                        onChange={(value) => setFieldValue('advanceBookingLimit', value)}
                        isInvalid={touched.advanceBookingLimit && !!errors.advanceBookingLimit}
                        max={MAX_ADVANCE_BOOKING_LIMIT}
                        min={MIN_ADVANCE_BOOKING_LIMIT}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.advanceBookingLimit}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>

            <Dropdown.Divider className={styles.divider} />

            <div className={styles.groupHeader}>
                { t(`${T_PREFIX}.openingHours.header.label`)}
            </div>
            <Schedule
                values={values.openingHours}
                copyMondeyToAllDaysLabel={t(`${T_PREFIX}.openingHours.header.actions.copyMondayToAllDays.label`)}
                defaultFrom={moment().set({ hours: 9, minutes: 0 })}
                defaultTo={moment().set({ hours: 22, minutes: 30 })}
                onChange={onChangeOpeningHours}
            />

            {/* <Form.Label className={styles.workBreaksLabel}>
                <CheckBox
                    name="workBreaksEnable"
                    value={values.workBreaksEnable}
                    onChange={handleChange}
                    label={t(`${T_PREFIX}.openingHours.workBreaks.label`)}
                />
            </Form.Label>

            {values.workBreaksEnable && (
                <WorkBreaks
                    values={values.workBreaks}
                    onChange={onChangeWorkBreaks}
                    className={styles.workBreaks}
                />
            )} */}

            <Dropdown.Divider className={styles.divider} />

            <DateCardList
                title={t(`${T_PREFIX}.closedDates.header.label`)}
                addButtonLabel={t(`${T_PREFIX}.closedDates.header.actions.add.label`)}
                values={values.closedDates}
                withoutTimeFields
                errors={closedDatesErrors}
                onChange={onChangeClosedDates}
            />

            <Dropdown.Divider className={styles.divider} />

            <DateCardList
                title={t(`${T_PREFIX}.adjustedOpeningHours.header.label`)}
                addButtonLabel={t(`${T_PREFIX}.adjustedOpeningHours.header.actions.add.label`)}
                values={values.adjustedOpeningHours}
                errors={adjustedOpeningHoursErrors}
                onChange={onChangeAdjustedOpeningHours}
                className="mb-5"
            />

            <div className={styles.actionsButtonsWrapper}>
                <Button
                    color="outline"
                    disabled={!hasAnyChanges}
                    onClick={handleDiscard}
                >
                    {t('actions.discard')}
                </Button>

                <Button
                    type="submit"
                    disabled={!hasAnyChanges}
                    loading={isSavingProcess}
                >
                    {t('actions.save')}
                </Button>
            </div>
        </Form>
    );
};

export default Location;
