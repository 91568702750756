import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMobile } from 'hooks/useMobile';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import * as ACTIVITY_ACTIONS from 'store/actions/activity';
import * as ACTIVITY_SELECTORS from 'store/selectors/activity';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { ActivityTableBodyRow } from 'components/Activity/ActivityTableBodyRow';
import { ActivityTableHeader } from 'components/Activity/ActivityTableHeader';
import SearchInput2 from 'components/Common/SearchInput2';
import LocationsSelect from 'components/Activity/LocationsSelect/LocationsSelect';
import { PeriodPicker } from 'components/Common/PeriodPicker';
import { ActivityPageMobileFooter } from 'components/Activity/ActivityPageMobileFooter';
import { TypesWindowedSelect } from 'components/Activity/TypesWindowedSelect';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ActionsWindowedSelect from 'components/Activity/ActionsWindowedSelect/ActionsWindowedSelect';

const COLUMNS_COUNT = 9;

const schema = yup.object({
    types: yup.array().of(yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
    })),
    actions: yup.array().of(yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
    })),
});

const ActivityList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);

    const formik = useFormik({
        initialValues: {
            types: [],
            actions: [],
        },
        validationSchema: schema,
    });

    const {
        page,
        items: activityList,
        showAll,
        isLoading,
        itemsCount,
        pagesCount,
        itemsPerPage,
        search,
        sortOptions,
        types,
        actions,
        location,
        date,
    } = useSelector(ACTIVITY_SELECTORS.activityListSelector);

    const isMobile = useMobile();

    const handlePrevPage = useCallback(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(ACTIVITY_ACTIONS.showAllActivityList());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListPageFirst());
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(ACTIVITY_ACTIONS.setActivityListPage({ page: nextPage }));
    }, [dispatch]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(ACTIVITY_ACTIONS.setActivityListSearch({ search: nextSearch }));
    }, [dispatch]);

    const handleLocationSelect = useCallback((nextLocation) => {
        dispatch(ACTIVITY_ACTIONS.setActivityListLocation({ location: nextLocation?.value || null }));
    }, [dispatch]);

    const handleTypeSelect = useCallback((nextType) => {
        const newType = {
            id: nextType,
            name: t(`activity.header.filters.types.${nextType}`),
        };
        if (formik.values.types.find((type) => type.id === newType.id)) {
            formik.setFieldValue('types', formik.values.types.filter((type) => type.id !== newType.id));
        } else {
            formik.setFieldValue('types', [...formik.values.types, newType]);
        }
    }, [dispatch, formik.values.types, t]);

    useEffect(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListTypes({ types: formik.values.types }));
    }, [dispatch, formik.values.types]);

    useEffect(() => {
        dispatch(ACTIVITY_ACTIONS.setActivityListActions({ actions: formik.values.actions }));
    }, [dispatch, formik.values.actions]);

    const handleChangeSelectedDate = useCallback((date) => {
        dispatch(ACTIVITY_ACTIONS.setActivityListSelectedDate(date));
    }, [dispatch]);

    useEffect(() => {
        dispatch(ACTIVITY_ACTIONS.getActivityList());
    }, [
        dispatch,
        page,
        search,
        types,
        actions,
        location,
        date,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    useEffect(() => () => dispatch(ACTIVITY_ACTIONS.clearActivityList()), []);

    const handleCellClick = useCallback((sortBy) => {
        if (sortOptions.sortBy === sortBy) {
            dispatch(ACTIVITY_ACTIONS.setActivityListSortOptions({
                sortOptions: {
                    orderBy: sortOptions.orderBy * -1,
                },
            }));
        } else {
            dispatch(ACTIVITY_ACTIONS.setActivityListSortOptions({
                sortOptions: {
                    sortBy,
                    orderBy: 1,
                },
            }));
        }
    }, [sortOptions]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('activity.title')}
                rightActions={!isMobile && [
                    <PeriodPicker
                        initDateFrom={date.dateFrom}
                        initDateTo={date.dateTo}
                        initPeriod={date.period}
                        onChange={handleChangeSelectedDate}
                    />,
                    <LocationsSelect placeholder={t('activity.header.locationSelect.placeholder')} onSelect={handleLocationSelect} defaultSelected={location} />,
                    <ActionsWindowedSelect formik={formik} />,
                    <TypesWindowedSelect
                        formik={formik}
                    />,
                    <SearchInput2
                        onSearch={handleSearch}
                        searchValue={search}
                        stretched={false}
                    />,
                ]}
            />
            <Container>
                <Content loading={isLoading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <ActivityTableHeader
                                onCellClick={handleCellClick}
                                sortOptions={sortOptions}
                            />
                        </TableHeader>
                        <TableBody>
                            {activityList?.map((log) => (
                                <ActivityTableBodyRow
                                    key={log.id}
                                    log={log}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </Content>
                <ActivityPageMobileFooter searchValue={search} onSearch={handleSearch} formik={formik} handleTypeSelect={handleTypeSelect} />
            </Container>
        </React.Fragment>

    );
};

export default ActivityList;
