import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { ClockIcon } from 'components/Icon/Icon';
import {
    cancelSoldSubscription,
    resetCancelSoldSubscription,
} from 'store/actions';
import { cancelSoldSubscriptionSelector } from 'store/selectors/subscriptions';
import { Button } from 'components/Common/Button';
import moment from 'moment';
import * as styles from './CancelSubscriptionModal.module.scss';

function CancelSoldSubscriptionWithBookingsModal() {
    const { locale } = useSelector((state) => state.locales);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        endDate,
        subscriptionBookings,
    } = useSelector(cancelSoldSubscriptionSelector);

    const onCloseModal = () => dispatch(resetCancelSoldSubscription());
    const onCancelSubscriptionAndBookings = () => dispatch(cancelSoldSubscription({ endDate, cancelBookings: true }));

    return (
        <Modal
            className={styles.modal}
            centered
            size="lg"
            onHide={onCloseModal}
            show
        >
            <Modal.Header
                className={styles.modalHeader}
                closeButton
                onHide={onCloseModal}
            >
                <Modal.Title>
                    {t('subscriptions.cancelSoldSubscriptionModals.cancelSubscription')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 className="text-center mb-5">
                    <span>
                        {t('subscriptions.cancelSoldSubscriptionModals.activeBookings')}
                    </span>
                </h3>
                {
                    subscriptionBookings.map(({
                        id, is_recurring, solarium_name, datetime,
                    }) => (
                        <div
                            key={id}
                            className="d-flex flex-column flex-sm-row justify-content-between mb-2"
                        >
                            <div className={styles.booking}>
                                {solarium_name}
&nbsp;
                                {is_recurring && (<ClockIcon width={14} height={14} />)}
                            </div>
                            <div
                                className="pl-3"
                                style={{ color: '#818E9C' }}
                            >
                                {moment.unix(datetime).locale(locale).format('DD MMM YYYY, hh:mm')}
                            </div>
                        </div>
                    ))
                }
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    color="outline"
                    onClick={onCloseModal}
                >
                    {t('placeholders.back')}
                </Button>
                <Button
                    color="red"
                    onClick={onCancelSubscriptionAndBookings}
                >
                    {t('placeholders.yes')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CancelSoldSubscriptionWithBookingsModal;
