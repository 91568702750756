import React from 'react';

// Hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Components
import { Modal } from 'react-bootstrap';

// Store
import * as actions from 'store/actions';

// Utils
import moment from 'moment';
import classNames from 'classnames';
import _, { uniq } from 'lodash';
import { permissionsEventSelector, permissionsResourceSelector } from 'store/selectors/permissions';
import { Button } from 'components/Common/Button';
import TextButton from 'components/Common/TextButton/TextButton';
import { Content } from 'components/Common/Content';
import { Row } from 'components/Common/Row';
import { Label } from 'components/Common/Typography/Label';
import { useMobile } from 'hooks/useMobile';
import { BookingPaymentType, Event, Feedback } from '..';
import { getFullName } from '../../helpers/services/getFullName';
import { CLIENTS_LIST_ID_PAGE, EVENTS_EDIT_ID_PAGE, MESSAGES_ADD_PAGE } from '../../const/CLIENT_URL';

const EventDetailsModal = (
    {
        clickedEventID,
        setClickedEventID,
        showBookingModal,
        clickedBookingID,
        cancelModalVisible,
        setClickedBookingID,
        setCancelModalVisible,
    },
) => {
    const isMobile = useMobile();
    const bookings = useSelector((state) => state.events.bookings);
    const event = useSelector((state) => (state.events.events || []).find(({ resourceId }) => resourceId === clickedEventID));

    const { loadingEventBookings } = useSelector((state) => state.events);
    const eventParticipantsSeatsSum = event?.participants?.reduce((acc, { seats }) => (acc + seats), 0) ?? 0;
    const {
        banCancel,
        banBooking,
        banSendNotification,
    } = useSelector(permissionsEventSelector);
    const { banEdit } = useSelector(permissionsResourceSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        if (!clickedEventID) return;

        dispatch(actions.getEventBookings({ id: clickedEventID }));
    }, [eventParticipantsSeatsSum]);

    return (
        <Modal
            centered
            size="lg"
            show={clickedEventID && !showBookingModal && !clickedBookingID && !cancelModalVisible}
            onHide={() => setClickedEventID(null)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="mb-0 font-weight-600" data-testid="data-test-event-details-modal-title">
                    {t('eventsRoute.eventDetails')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Content loading={loadingEventBookings || !event || !bookings}>
                    <h3 className="font-weight-normal pb-3">{event && moment(event?.time.from).format('D MMM YYYY')}</h3>

                    <Event
                        event={event}
                        showParticipants={false}
                    />

                    <div className="d-flex align-items-center mt-4">
                        <h4 className="mb-0 font-size-20 font-weight-bold">
                            {t('eventsRoute.seats')}
                        </h4>
                        <span className="ml-2 font-weight-bold font-size-16 text-muted" data-testid="data-test-event-details-seats">
                            {event && `${eventParticipantsSeatsSum ?? 0}/${event.totalPlaces}`}
                        </span>
                    </div>

                    {bookings?.map(({
                        id,
                        client,
                        feedback,
                        payment: {
                            paid,
                            isInProgress,
                            usedBundle,
                            finalCost,
                            type,
                        },
                        notPresent,
                        seats,
                        time: {
                            from,
                        },
                    }, index) => (
                        <div
                            key={`participant-${index}`}
                            className={classNames({
                                'border-danger': !usedBundle && !isInProgress && !paid && finalCost !== 0,
                                'border-success': usedBundle || (!isInProgress && paid) || finalCost === 0,
                                'border-info': !usedBundle && isInProgress,
                                'border-dark': notPresent,
                            }, 'd-flex flex-sm-row flex-column justify-content-between mt-3 pl-3 border-left border-5 w-100')}
                            data-testid={`data-test-row-${getFullName({ client })}`}
                        >
                            <Row gap={8} justify="between" stretched wrap={isMobile}>
                                <Row gap={8} align="center">
                                    <TextButton
                                        color="black"
                                        noPadding
                                        disabled={!client.id}
                                        href={CLIENTS_LIST_ID_PAGE({ clientId: client.id })}
                                        data-testid="data-test-event-details-client-name"
                                    >
                                        {getFullName({ client })}
                                    </TextButton>
                                    {(type || feedback) && (
                                        <Row gap={8}>
                                            {type && (
                                                <BookingPaymentType
                                                    className="text-muted"
                                                    width={24}
                                                    type={type}
                                                />
                                            )}
                                            {feedback && (
                                                <Feedback
                                                    feedback={feedback}
                                                    size={24}
                                                />
                                            )}
                                        </Row>
                                    )}
                                    {!isMobile && (
                                        <TextButton
                                            color="gray"
                                            onClick={() => setClickedBookingID({ id, timestamp: from })}
                                            strong
                                            data-testid="data-test-event-details-show-info"
                                            noPadding
                                        >
                                            {t('groupSchedule.showInfo')}
                                        </TextButton>
                                    )}
                                </Row>
                                <Row gap={8} align="center">
                                    <Label color="gray" data-testid="data-test-event-details-seats">
                                        {t('groupDetailsModal.seat', { count: seats ?? 1 })}
                                    </Label>
                                    <Label data-testid="data-test-event-details-client-phone">
                                        {client?.contactNumber}
                                    </Label>
                                </Row>
                                {isMobile && (
                                    <TextButton
                                        uppercase
                                        noPadding
                                        color="gray"
                                        strong
                                        onClick={() => setClickedBookingID({ id, timestamp: from })}
                                        data-testid="data-test-event-details-show-info"
                                    >
                                        {t('groupSchedule.showInfo')}
                                    </TextButton>
                                )}
                            </Row>
                        </div>
                    ))}

                    <div className={`d-flex justify-content-${event?.isCancelled ? 'end' : 'between'} mt-3 w-100`}>
                        {!event?.isCancelled && !banBooking && (
                            <Button
                                color="outline"
                                size="small"
                                onClick={() => {
                                    dispatch(actions.setAddBookingDetails({
                                        time: moment(event.time.from),
                                        product: {
                                            id: event.resourceId,
                                            name: event.name,
                                            type: 'event',
                                            eventId: event.id,
                                        },
                                        service: {
                                            id: event.service.id,
                                            name: event.service.name,
                                        },
                                    }));
                                }}
                                disabled={(eventParticipantsSeatsSum === event?.totalPlaces) || banBooking}
                                data-testid="data-test-event-details-add-booking"
                            >
                                {t('agendaRoute.addBooking')}
                            </Button>
                        )}
                        {!banSendNotification && (
                            <Button
                                disabled={banSendNotification || !eventParticipantsSeatsSum}
                                id="sendMessage"
                                size="small"
                                onClick={() => {
                                    const selectedClientsIds = uniq(bookings?.map(({ client }) => client.id));
                                    history.push(MESSAGES_ADD_PAGE, { selectedClientsIds });
                                }}
                                data-testid="data-test-event-details-send-message"
                            >
                                {t('groupDetailsModal.sendMessage')}
                            </Button>
                        )}
                    </div>
                    {!banCancel && (
                        <div className="d-flex justify-content-end mt-4 w-100">
                            <Button
                                color={event?.isCancelled ? 'green' : 'red'}
                                size="small"
                                onClick={() => {
                                    if (banCancel) return;

                                    setCancelModalVisible(true);
                                }}
                                disabled={banCancel}
                                data-testid="data-test-event-details-cancel"
                            >
                                {t(`eventsRoute.${event?.isCancelled ? 'reactivate' : 'cancel'}`)}
                                {' '}
                                {t('eventsRoute.event')}
                            </Button>
                        </div>
                    )}
                </Content>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    data-testid="close-event-modal"
                    color="outline"
                    onClick={() => setClickedEventID(null)}
                >
                    {t('eventsRoute.close')}
                </Button>
                {!banEdit && (
                    <div className="mx-n2">
                        <Button
                            className="mx-2"
                            href={EVENTS_EDIT_ID_PAGE({ eventId: event?.resourceId })}
                            data-testid="data-test-event-details-edit"
                        >
                            {t('groupDetailsModal.edit')}
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default EventDetailsModal;
