import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AllowRouteWrapper, LayoutContainer, PortalSubHeader } from 'components';

import { useSelector, useDispatch } from 'react-redux';
import routes from './scenes';
import { permissionsPresetSelector } from '../../store/selectors/permissions';

import * as SERVICES_ACTIONS from '../../store/actions/services';

const Services = ({ roles, accessKey }) => {
    const dispatch = useDispatch();
    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);

    useEffect(() => () => {
        dispatch(SERVICES_ACTIONS.setServicesSearch({ search: '' }));
        dispatch(SERVICES_ACTIONS.toggleServicesHideArchived({ hideArchived: true }));
        dispatch(SERVICES_ACTIONS.setServicesPageFirst());
    }, []);

    return (
        <LayoutContainer>
            <PortalSubHeader roles={roles} accessKey={accessKey} />

            <Switch>
                {routes.map(({ component, exact, path }) => (
                    <AllowRouteWrapper
                        key={path}
                        sections={sections}
                        path={path}
                        permissionSections={preset?.section}
                    >
                        <Route
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    </AllowRouteWrapper>
                ))}
                <Redirect to={routes[0].path} />
            </Switch>
        </LayoutContainer>
    );
};

export default Services;
