import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'react-bootstrap';
import { deleteCustomInvoice } from 'store/actions';

import { Button } from 'components/Common/Button';
import * as styles from './InvoiceDraftDeleteDialog.module.scss';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'deleteDialog';
const T_BODY = `${T_PREFIX}.body`;
const T_ACTIONS = `${T_PREFIX}.actions`;

const InvoiceDraftDeleteDialog = (props) => {
    const { invoiceId, onClose } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const dispatch = useDispatch();

    const onDeleteHandle = useCallback(() => {
        dispatch(deleteCustomInvoice({ bill: invoiceId }));
        onClose(true);
    });

    const onCloseHandle = useCallback(() => {
        onClose(false);
    });

    return (
        <Modal
            centered
            size="xs"
            show={Boolean(invoiceId)}
            onHide={onCloseHandle}
        >
            <Modal.Header
                closeButton
                onHide={onCloseHandle}
                className={styles.header}
            >
                <Modal.Title>
                    {t(`${T_PREFIX}.header`)}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles.body}>
                {t(`${T_BODY}.text`)}
            </Modal.Body>

            <Modal.Footer className={styles.footer}>
                <Button
                    color="outline"
                    onClick={onCloseHandle}
                >
                    {t(`${T_ACTIONS}.cancel`)}
                </Button>

                <Button
                    color="red"
                    onClick={onDeleteHandle}
                >
                    {t(`${T_ACTIONS}.delete`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InvoiceDraftDeleteDialog;
