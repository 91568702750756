import {
    call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { rejectPromiseAction, resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as CLIENTS_TYPES from '../../actions/clients/types';
import * as CLIENTS_ACTIONS from '../../actions/clients';

import * as TOAST_ACTIONS from '../../actions/toast';

import axios from '../../../services/axios';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_CLIENT_ID_ROUTE,
    API_ADMIN_CLIENT_EDIT_ID_ROUTE,
    API_ADMIN_CLIENT_SEND_UPDATE_IBAN_LINK_ID_ROUTE,
    API_ADMIN_CLIENT_UPDATE_IBAN_ID_ROUTE,
} from '../../../const/API_URL';

function* getClientItem(action) {
    const { payload } = action;
    const { clientId } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const item = camelize(result);
            const client = {
                id: item.id,
                fullName: {
                    firstName: item.firstName,
                    lastName: item.lastName,
                },
                contactInfo: {
                    phone: item.contactNumber,
                    email: item.email,
                    address: {
                        street: item.address,
                        zip: item.zip,
                        city: item.city,
                    },
                },
                registered: item.registered,
                dateOfBirth: item.dateOfBirth,
                companyName: item.clientCompanyName,
                note: item.note,
                avatarUrl: item.avatarUrl,
                loyaltyPoints: item.loyaltyPoints,
                disallowPaymentInShop: item.disallowPaymentInShop,
                block: item.blocked,
                monthlyInvoicing: item.monthlyInvoicing,
                maskedIban: item.maskedIban,
                ibanOwner: item.ibanOwner,
                hadMandate: item.hadMandate,

                iban: item.iban,
                country: item.country,
                cocNumber: item.cocNumber,
                vatNumber: item.vatNumber,

                accessCardCode: item.accessCardCode,
            };
            yield call(resolvePromiseAction, action, { client });
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'clients.getItem.error',
                    appearance: 'error',
                }),
            );
        }
        yield call(rejectPromiseAction, action);
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* editClientItem(action) {
    const { payload } = action;
    const {
        clientId,
        data,
        onSuccess,
        onError,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_EDIT_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            data,
        });
        const { data: resData } = res;
        const { code } = resData;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(CLIENTS_ACTIONS.getClients());
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.edit.success',
                appearance: 'success',
            }));
            if (onSuccess) {
                yield call(onSuccess);
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.errors?.email
                    ? 'clients.edit.emailPhoneError'
                    : 'clients.edit.error',
                insertion: { error: error?.response?.data?.result?.errors?.email },
                appearance: 'error',
            }));

            onError?.({
                ...error?.response?.data?.result?.errors,
                phone: error?.response?.data?.result?.errors?.contactNumber,
            });
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* sendUpdateIbanLink(action) {
    const { payload } = action;
    const {
        clientId,
        onSuccess,
        onError,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_SEND_UPDATE_IBAN_LINK_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
        });
        const { code } = res.data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.sendUpdateIbanLink.success',
                appearance: 'success',
            }));
            yield call(resolvePromiseAction, action);
            if (onSuccess) {
                yield call(onSuccess);
            }
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'clients.sendUpdateIbanLink.error',
                    appearance: 'error',
                }),
            );
            onError?.(error?.response?.data?.result?.errors);
        }
        yield call(rejectPromiseAction, action);
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* updateIban(action) {
    const { payload } = action;
    const {
        clientId,
        onSuccess,
        onError,
        data,
    } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_CLIENT_UPDATE_IBAN_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            data,
        });
        const { code } = res.data;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.updateIban.success',
                appearance: 'success',
            }));
            yield call(resolvePromiseAction, action);
            if (onSuccess) {
                yield call(onSuccess);
            }
            return;
        }
        yield call(rejectPromiseAction, action);
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'clients.updateIban.error',
                    appearance: 'error',
                }),
            );
            onError?.(error?.response?.data?.result?.errors);
        }
        yield call(rejectPromiseAction, action);
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const clientItemSaga = [
    takeEvery(CLIENTS_ACTIONS.getClientItem, getClientItem),
    takeLatest(CLIENTS_ACTIONS.getClientSilentItem, getClientItem),
    takeEvery(CLIENTS_TYPES.CLIENTS_ITEM_EDIT, editClientItem),
    takeLatest(CLIENTS_ACTIONS.sendUpdateIbanLink, sendUpdateIbanLink),
    takeLatest(CLIENTS_ACTIONS.updateIban, updateIban),
];
