import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyTable } from 'components/Common/NewTable/EmptyTable';
import * as ACCESS_ACTIONS from 'store/actions/access';
import * as ACCESS_SELECTORS from 'store/selectors/access';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { Button } from 'components/Common/Button';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ACCESS_PROFILES_EDIT_ITEM_PAGE, ACCESS_PROFILES_ADD_PAGE } from 'const/CLIENT_URL';
import { ProfileCard } from 'components/Access/Profiles/ProfileCard';
import { Header } from 'components/Common/Typography/Header';
import * as styles from './page.module.scss';

const ProfilesList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const history = useHistory();

    const {
        items: profiles,
        isLoading,
        itemsCount,
    } = useSelector(ACCESS_SELECTORS.accessProfilesListSelector);

    const handleShowDetails = useCallback(({ profile }) => {
        history.push(ACCESS_PROFILES_EDIT_ITEM_PAGE({ id: profile.id }));
    }, []);

    const handleDelete = useCallback(({ profile }) => {
        const onDone = () => {
            dispatch(ACCESS_ACTIONS.getAccessProfiles());
        };
        dispatch(ACCESS_ACTIONS.deleteAccessProfile({ profileId: profile.id, onDone }));
    }, []);

    useEffect(() => {
        dispatch(ACCESS_ACTIONS.getAccessProfiles());
    }, [
        dispatch,
    ]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('profiles.list.title')}
                rightActions={(
                    <Button href={ACCESS_PROFILES_ADD_PAGE}>
                        {t('profiles.action.add')}
                    </Button>
                )}
                after={!isLoading && (
                    <Header color="gray">
                        {`(${itemsCount})`}
                    </Header>
                )}
            />
            <Container>
                <Content loading={isLoading}>
                    <div className={styles.cardsGrid}>
                        {profiles?.map((profile) => (
                            <ProfileCard key={profile.id} onEdit={() => handleShowDetails({ profile })} onDelete={() => handleDelete({ profile })} profile={profile} />
                        ))}
                    </div>
                    {!itemsCount && (
                        <EmptyTable />
                    )}
                </Content>
            </Container>
        </React.Fragment>

    );
};

export default ProfilesList;
