import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';

const T_PREFIX = 'subscriptions.tableHeaders';

function SubscriptionsListTableColumns() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.title`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.period`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.price`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="center"
                    name={t(`${T_PREFIX}.status`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.actions`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
}

SubscriptionsListTableColumns.propTypes = {};

SubscriptionsListTableColumns.defaultProps = {};

export default SubscriptionsListTableColumns;
