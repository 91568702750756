import React from 'react';

import { ClientReviewsTableHeader } from 'components/clients/ClientPage/ClientTables/ClientReviews/ClientReviewsTableHeader';
import { ClientReviewsTableRow } from 'components/clients/ClientPage/ClientTables/ClientReviews/ClientReviewsTableRow';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { Column } from 'components/Common/Column';

function ClientReviewsTable() {
    return (
        <Column stretched gap={32}>
            <Table>
                <TableHeader>
                    <ClientReviewsTableHeader />
                </TableHeader>
                <TableBody>
                    <ListTableBodyRow>
                        <ClientReviewsTableRow />
                    </ListTableBodyRow>
                </TableBody>
            </Table>
        </Column>
    );
}

export default ClientReviewsTable;
