import React, { useMemo } from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Actions
import { Row, Col } from 'react-bootstrap';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AllowRouteWrapper, LayoutContainer } from 'components';
import { Sidebar, AgendaActions } from 'components/agenda';
import moment from 'moment';
import {
    filterSelector,
    productListToFilterSelector,
    productsLoadersSelector,
    productAllDataSelector,
} from 'store/selectors/products';
import {
    userDefaultTabSelector,
    userSoundNotificationsEnabledSelector,
} from 'store/selectors/user';
import { TailSpin } from 'react-loader-spinner';
import { roleSelector } from 'store/selectors/auth';
import { permissionsSectionsSelector, permissionsShopSelector } from 'store/selectors/permissions';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AGENDA } from 'const/CLIENT_URL';
import * as actions from '../../../store/actions';

// Components
import { PortalSubHeader } from '../../../components/Layout/PortalSubHeader';

// Routes
import NoProducts from '../AgendaGroup/NoProducts/NoProducts';
import Objects from '../AgendaGroup/Objects/Objects';
import Events from '../AgendaGroup/Events/Events';
import Employees from '../AgendaGroup/Employees/Employees';
import Groups from '../AgendaGroup/Groups/Groups';

const productTypes = [{
    name: 'objects',
    tabName: 'objects',
    Component: Objects,
}, {
    name: 'employees',
    tabName: 'employees',
    Component: Employees,
}, {
    name: 'groups',
    tabName: 'group_sessions',
    Component: Groups,
}, {
    name: 'events',
    tabName: 'events',
    Component: Events,
}];

const LoadingComponent = () => (
    <div style={{
        display: 'flex',
        flex: '1 1 auto',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    >
        <div
            style={{
                width: '100px',
                height: '100px',
            }}
        >
            <TailSpin
                color="black"
                width={100}
                height={100}
            />
        </div>
    </div>
);

const Agenda = ({ roles, accessKey }) => {
    const { t } = useTranslation();

    const { params: { type } } = useRouteMatch('/agenda/:type') || { params: {} };

    const filters = useSelector(filterSelector(type));
    const productsToFilter = useSelector(productListToFilterSelector(type));
    const isLoaded = useSelector(productsLoadersSelector());
    const soundNotificationsEnabled = useSelector(userSoundNotificationsEnabledSelector);
    const defaultTab = useSelector(userDefaultTabSelector);
    const isProductLoaded = isLoaded[type];
    const isAgendaActionsLoaded = soundNotificationsEnabled !== null && defaultTab !== null;
    const { sessionsLoading, getGroupsLoading } = useSelector((state) => state.groups);
    const products = useSelector(productAllDataSelector());
    const history = useHistory();

    const defaultProductType = useMemo(() => (productTypes.find((productType) => productType.tabName === defaultTab) ?? productTypes[0]), [defaultTab]);
    const avaliableDefaultTabs = useMemo(() => (productTypes.map(({ tabName }) => tabName)), []);

    const { role } = useSelector(roleSelector);
    const { sections } = useSelector(({ company }) => company);
    const { banSwitchShop } = useSelector(permissionsShopSelector);
    const {
        allowObject, allowEmployee, allowGroup, allowEvent,
    } = useSelector(permissionsSectionsSelector);

    const initialDate = useSelector(({ app }) => moment(app.date));
    const shops = useSelector((state) => state.company.shops);

    const { loaded: isShopLoaded, id: shopID, name: shopName } = useSelector((state) => state.shop);

    const dispatch = useDispatch();

    const [calendarDate, setCalendarDate] = useState(initialDate);

    const [date, setDate] = useState(initialDate);

    const dateCloned = useMemo(() => date.clone(), [date]);

    const [month, setMonth] = useState(initialDate.month());
    const [year, setYear] = useState(initialDate.year());
    const [week, setWeek] = useState(initialDate.isoWeek());

    const [showOptionsModal, setShowOptionsModal] = useState(false);

    const { addToast } = useToasts();

    useEffect(() => {
        if (isLoaded.objects || !shopID || !isShopLoaded) {
            return;
        }

        dispatch(actions.getObjects());
    }, [isLoaded.objects, shopID, isShopLoaded]);

    useEffect(() => {
        if (isLoaded.employees || !shopID || !isShopLoaded) {
            return;
        }

        dispatch(actions.getEmployees());
    }, [isLoaded.employees, shopID, isShopLoaded]);

    useEffect(() => {
        if (isLoaded.groups || !shopID || !isShopLoaded) {
            return;
        }

        if (getGroupsLoading) {
            return;
        }

        const from = date.clone().startOf('isoWeek').unix();
        const to = date.clone().endOf('isoWeek').unix();

        dispatch(actions.getGroups());
        dispatch(actions.getGroupSessions(from, to));
    }, [isLoaded.groups, shopID, isShopLoaded]);

    useEffect(() => {
        if (isLoaded.events || !shopID || !isShopLoaded) {
            return;
        }

        dispatch(actions.getEvents());
    }, [isLoaded.events, shopID, isShopLoaded]);

    useEffect(() => {
        if (!shops) return;
        if (shopID === shops[0].id) return;
        dispatch(actions.selectShop({ id: shopID ?? shops[0].id, name: shopName ?? shops[0].name }))
            .catch((message) => {
                if (!message) {
                    return;
                }

                addToast(message, {
                    appearance: 'error',
                });
            });
    }, [shops]);

    useEffect(() => {
        dispatch(actions.dateChanged(date.unix() * 1000));

        if ((date.month() !== calendarDate.month()) || date.year() !== calendarDate.year()) {
            setCalendarDate((prevState) => prevState.clone().month(date.month()).year(date.year()));
        }
    }, [date]);

    const timespanMatch = useRouteMatch('/agenda/:type/:timespan');

    const viewType = timespanMatch ? timespanMatch.params.timespan : (type === 'groups' ? 'week' : 'day');

    const filterDispatchHandler = (id, isChecked) => {
        if (type === 'objects') {
            return dispatch(actions.setObjectFilter(id, isChecked));
        }
        if (type === 'employees') {
            return dispatch(actions.setEmployeeFilter(id, isChecked));
        }
        return dispatch(actions.setGroupsFilter(id, isChecked));
    };

    const onSelectDate = (date) => {
        const newDate = date.locale('en');
        setCalendarDate(newDate);
        setDate(newDate);
        setWeek(newDate.isoWeek());
        setMonth(newDate.month());
        setYear(newDate.year());
    };

    const onChangeTimespan = (month, year) => {
        setCalendarDate((prevDate) => prevDate.clone().month(month).year(year));
    };

    const onSelectShop = (id) => {
        if (id === shopID) {
            return;
        }

        const onDone = () => {
            history.push(AGENDA);
        };

        dispatch(actions.selectShop({ id: parseInt(id), name: shops.find((shop) => shop.id === parseInt(id)).name, onDone }));
    };

    const handleDefaultTabSelect = (tab) => dispatch(actions.setDefaultTab(tab));
    const handleSoundNotificationsToggle = (enabled) => dispatch(actions.toggleSoundNotifications(enabled));

    const hasSidebar = (
        viewType !== 'add'
        && viewType !== 'edit'
        && ((productsToFilter && filters) || type === 'events'));

    return (
        <LayoutContainer>
            <PortalSubHeader
                date={date}
                isDropdownDataLoaded={Boolean(shopName && shops)}
                isNavigationDataLoaded={isProductLoaded}
                dropdownOptions={shops}
                onDropdownItemSelect={onSelectShop}
                shopId={shopID}
                shopName={shopName}
                showDropdown={!banSwitchShop}
                productsList={products}
                after={(
                    <AgendaActions
                        isDataLoaded={isAgendaActionsLoaded}
                        avaliableDefaultTabs={avaliableDefaultTabs}
                        onDefaultTabSelect={handleDefaultTabSelect}
                        defaultTab={defaultTab}
                        soundNotificationsEnabled={soundNotificationsEnabled}
                        onSoundNotificationsToggle={handleSoundNotificationsToggle}
                    />
                )}
                roles={roles}
                accessKey={accessKey}
            />
            <Row noGutters className="flex-grow-1 pb-4 pb-md-0 flex-nowrap">
                {!isProductLoaded || hasSidebar && (
                    <Sidebar
                        title={t(`agendaRoute.${type === 'groups' ? 'employees' : type}`)}
                        type={type}
                        products={productsToFilter}
                        filters={filters}
                        onFilterClick={(id, isChecked) => filterDispatchHandler(id, isChecked)}
                        viewType={viewType}
                        date={date}
                        month={calendarDate.month()}
                        year={calendarDate.year()}
                        onSelectDate={onSelectDate}
                        onChangeTimespan={onChangeTimespan}
                        showFiltersModal={showOptionsModal}
                        setShowFiltersModal={setShowOptionsModal}
                        showDropdown={!banSwitchShop}
                        isDropdownDataLoaded={Boolean(shopName && shops)}
                        dropdownOptions={shops}
                        onDropdownItemSelect={onSelectShop}
                        shopId={shopID}
                        shopName={shopName}
                    />
                )}

                <Col
                    className={classNames('border-left', hasSidebar && 'px-3 py-lg-5 py-3')}
                    style={{
                        width: 'calc(100% - 300px)',
                    }}
                >
                    <Switch>
                        {/* eslint-disable-next-line react/no-unstable-nested-components */}
                        <Route exact path="/agenda/" component={() => <Redirect to={`/agenda/${defaultProductType.name}`} />} />
                        {productTypes.map(({ name, Component }) => (
                            <AllowRouteWrapper
                                key={name}
                                path={`/agenda/${name}`}
                                sections={sections}
                            >
                                <Route
                                    key={name}
                                    path={`/agenda/${name}`}
                                    render={() => {
                                        const isGroups = name === 'groups';
                                        const isObjects = name === 'objects';
                                        const isEmployees = name === 'employees';
                                        const isEvents = name === 'events';

                                        if (!isProductLoaded) {
                                            return (
                                                <LoadingComponent />
                                            );
                                        }

                                        const isLoading = isGroups ? sessionsLoading : !isProductLoaded;

                                        const isProductExist = isGroups
                                            ? !!Object.keys(products[name].products)?.length
                                            : !!products[name].products?.length;

                                        const isCurrentRangeProductExist = !isLoading
                                        && ((isGroups ? !!products[name].sessions?.length : isProductExist));

                                        let NoProductComponent = null;

                                        if (!isCurrentRangeProductExist) {
                                            NoProductComponent = <NoProducts withAdd={role !== 'employee'} />;
                                        }

                                        const isResourceAllowed = () => {
                                            if (isObjects) {
                                                return allowObject;
                                            }
                                            if (isEmployees) {
                                                return allowEmployee;
                                            }
                                            if (isGroups) {
                                                return allowGroup;
                                            }
                                            if (isEvents) {
                                                return allowEvent;
                                            }
                                            return true;
                                        };

                                        return (
                                            <Component
                                                isAllowed={isResourceAllowed()}
                                                NoProductComponent={NoProductComponent}
                                                isProductExist={isProductExist}
                                                LoadingComponent={isLoading && <LoadingComponent />}
                                                type={type}
                                                viewType={viewType}
                                                date={dateCloned}
                                                week={week}
                                                month={month}
                                                year={year}
                                                setDate={setDate}
                                                setCalendarsDate={setCalendarDate}
                                                setWeek={setWeek}
                                                setMonth={setMonth}
                                                setYear={setYear}
                                                setShowOptionsModal={setShowOptionsModal}
                                            />
                                        );
                                    }}
                                />
                            </AllowRouteWrapper>
                        ))}
                    </Switch>
                </Col>
            </Row>
        </LayoutContainer>
    );
};

export default Agenda;
