import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as COMPANY_ACTIONS from 'store/actions/company';
import { companyQRCodeLoadedSelector } from 'store/selectors/company';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import routes from './scenes';

const InviteClients = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const dispatch = useDispatch();
    const companyQRCodeLoaded = useSelector(companyQRCodeLoadedSelector);

    useEffect(() => {
        dispatch(COMPANY_ACTIONS.getCompanyQRCode());
        dispatch(COMPANY_ACTIONS.getCompanySettings());
    }, [dispatch]);

    return (
        <React.Fragment>
            <TextSubHeader text={t('inviteClients.title')} description={t('inviteClients.description')} />
            <Container>
                <Content loading={!companyQRCodeLoaded}>
                    <Switch>
                        {
                            routes.map(({ component, exact, path }) => (
                                <Route
                                    key={path}
                                    component={component}
                                    exact={exact}
                                    path={path}
                                />
                            ))
                        }

                        <Redirect to={routes[0].path} />
                    </Switch>
                </Content>
            </Container>
        </React.Fragment>
    );
};

export default InviteClients;
