import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'components/Common/Button';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from 'components/Icon/Icon';
import { Image } from 'components/Common/Image/Image';
import LogoImage from './LogoImage.png';

import * as styles from './PdfPoster.module.scss';

export const PdfPoster = ({
    loading, logo, name, appColor, qrCode,
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust:exact; -webkit-filter:opacity(1); } }',
    });

    return (
        <React.Fragment>
            <Button
                onClick={handlePrint}
                loading={loading}
                before={<DownloadIcon />}
                color="yellow"
            >
                {t('actions.downloadPoster')}
            </Button>
            <div className={styles.hide}>
                <div className={styles.container} ref={componentRef}>
                    <div className={styles.header}>
                        <div className={styles.logo}>
                            <Image src={logo} height="100%" />
                        </div>
                        <div className={styles.name}>{name}</div>
                    </div>
                    <div className={styles.qrCodeContainer}>
                        <Image src={qrCode} height="100%" />
                        <div className={styles.scanForBooking} style={{ backgroundColor: appColor }}>
                            {t('inviteClients.poster.scanForBooking')}
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Image src={LogoImage} height={56} width={56} />
                        Powered by www.appybee.nl
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
