import React from 'react';

import { Footer, SearchInput } from 'components';

import { IconButton } from 'components/Common/IconButton';
import { Plus } from 'components/Icon/Icon';
import * as styles from './SubscriptionsFooter.module.scss';

function SubscriptionsFooter(props) {
    const {
        searchValue,
        onSearch,
        onButtonClickHandler,
    } = props;

    return (
        <Footer>
            <div className="d-flex d-lg-none flex-row">
                <SearchInput
                    searchValue={searchValue}
                    onSearch={onSearch}
                />
                <div className={styles.divider} />
                <IconButton
                    type="button"
                    color="white"
                    size={60}
                    onClick={onButtonClickHandler}
                >
                    <Plus />
                </IconButton>
            </div>
        </Footer>
    );
}

export default SubscriptionsFooter;
