import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    EMPLOYEES_ADD_PAGE, EVENTS_ADD_PAGE, GROUPS_ADD_PAGE, OBJECTS_ADD_PAGE,
} from 'const/CLIENT_URL';
import { Question } from '../../../../components/Icon/Icon';

const NoProducts = ({ withAdd }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className="text-primary">
                <Question width={48} />
            </div>

            <p className="mt-3 text-center font-weight-bold">
                {t(`noProductsRoute.noProductsFound${!withAdd ? 'Short' : ''}`)}
            </p>
            {withAdd && (
                <Dropdown>
                    <Dropdown.Toggle>
                        {t('noProductsRoute.addProduct')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={OBJECTS_ADD_PAGE}>
                            {t('noProductsRoute.addObject')}
                        </Dropdown.Item>

                        <Dropdown.Item as={Link} to={EMPLOYEES_ADD_PAGE}>
                            {t('noProductsRoute.addEmployee')}
                        </Dropdown.Item>

                        <Dropdown.Item as={Link} to={GROUPS_ADD_PAGE}>
                            {t('noProductsRoute.addGroupSession')}
                        </Dropdown.Item>

                        <Dropdown.Item as={Link} to={EVENTS_ADD_PAGE}>
                            {t('noProductsRoute.addEvent')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
};

export default NoProducts;
