import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import * as styles from './TableHeaderCell.module.scss';

const TableHeaderCell = (props) => {
    const {
        children,
        colSpan,
    } = props;

    const style = useMemo(() => ({
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
        width: props.width,
    }), [props.minWidth, props.maxWidth]);

    return (
        <th
            className={styles.headerCell}
            colSpan={colSpan}
            style={style}
        >
            {children}
        </th>
    );
};

TableHeaderCell.propTypes = {
    children: PropTypes.any,
    colSpan: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    width: PropTypes.number,
};

TableHeaderCell.defaultProps = {
    children: null,
    colSpan: null,
};

export default TableHeaderCell;
