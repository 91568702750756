import React from 'react';

const arrowSmallUp = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M17.5767 15.2071C17.1862 15.5976 16.553 15.5976 16.1625 15.2071L11.8696 10.9142L7.57674 15.2071C7.18621 15.5976 6.55305 15.5976 6.16252 15.2071C5.772 14.8166 5.772 14.1834 6.16252 13.7929L11.1625 8.79289C11.553 8.40237 12.1862 8.40237 12.5767 8.79289L17.5767 13.7929C17.9673 14.1834 17.9673 14.8166 17.5767 15.2071Z" fill="currentColor" />
    </svg>

);

export default arrowSmallUp;
