import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { setMessagesSortOptions } from '../../../../store/actions';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { MESSAGES_SORT_BY_TYPE } from '../../../../const/marketing/MESSAGES_SORT_BY_TYPE';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const MessagesTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.MESSAGES);
    const { sortOptions } = useSelector((state) => state.messages.messages);
    const dispatch = useDispatch();

    const handleCellClick = useCallback((sortBy) => {
        if (sortOptions.sortBy === sortBy) {
            dispatch(setMessagesSortOptions({
                sortOptions: {
                    orderBy: sortOptions.orderBy * -1,
                },
            }));
        } else {
            dispatch(setMessagesSortOptions({
                sortOptions: {
                    sortBy,
                    orderBy: 1,
                },
            }));
        }
    }, [sortOptions]);

    return (
        <React.Fragment>
            <TableHeaderCell
                minWidth={200}
            >
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.title`)}
                    active={sortOptions.sortBy === MESSAGES_SORT_BY_TYPE.TITLE}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(MESSAGES_SORT_BY_TYPE.TITLE)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.startDate`)}
                    active={sortOptions.sortBy === MESSAGES_SORT_BY_TYPE.START_DATE}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(MESSAGES_SORT_BY_TYPE.START_DATE)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.endDate`)}
                    active={sortOptions.sortBy === MESSAGES_SORT_BY_TYPE.END_DATE}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(MESSAGES_SORT_BY_TYPE.END_DATE)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.clients`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.status`)}
                    active={sortOptions.sortBy === MESSAGES_SORT_BY_TYPE.STATUS}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(MESSAGES_SORT_BY_TYPE.STATUS)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.sendTo`)}
                />
            </TableHeaderCell>
            <TableHeaderCell />
        </React.Fragment>
    );
};

MessagesTableHeader.propTypes = {};

MessagesTableHeader.defaultProps = {};

export default MessagesTableHeader;
