import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from 'components/Common/Content';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { Column } from 'components/Common/Column';
import { LogsListFilter } from 'components/Access/Logs/LogsListFilter';
import { LogsTableHeader } from 'components/Access/Logs/LogsTableHeader';
import { LogsTableBodyRow } from 'components/Access/Logs/LogsTableBodyRow';
import * as CLIENTS_ACTIONS from '../../../../../../store/actions/clients/tables/access';
import * as CLIENTS_SELECTORS from '../../../../../../store/selectors/clients';

const COLUMNS_COUNT = 8;

function ClientAccessTable() {
    const { clientId } = useParams();

    const dispatch = useDispatch();

    const {
        page,
        items: logs,
        showAll,
        isLoading,
        itemsCount,
        pagesCount,
        itemsPerPage,
        search,
        sortOptions,
        date,
        filter,
        hasNewItems,
    } = useSelector(CLIENTS_SELECTORS.clientAccessLogsListSelector);

    const handlePrevPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.showAllAccessLogs());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsPageFirst());
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsPage({ page: nextPage }));
    }, [dispatch]);

    const handleChangeSelectedDate = useCallback((date) => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsSelectedDate(date));
    }, [dispatch]);

    const handleChangeFilter = useCallback((nextFilter) => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsFilter({ filter: filter === nextFilter ? null : nextFilter }));
    }, [dispatch, filter]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(CLIENTS_ACTIONS.setAccessLogsSearch({ search: nextSearch }));
    }, [dispatch]);

    const handleHasNewItemsRefresh = useCallback(() => {
        handleFirstPage();
        handleSearch('');
    }, [handleFirstPage, handleSearch]);

    useEffect(() => {
        dispatch(CLIENTS_ACTIONS.getClientAccessLogs({ clientId }));
    }, [
        dispatch,
        filter,
        page,
        date,
        search,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    useEffect(() => () => dispatch(CLIENTS_ACTIONS.clearAccessLogs()), []);

    return (
        <Column gap={32} stretched>
            <LogsListFilter
                searchValue={search}
                onSearch={handleSearch}
                onChangeDate={handleChangeSelectedDate}
                date={date}
                filter={filter}
                onChangeFilter={handleChangeFilter}
            />
            <Content loading={isLoading}>
                <Table
                    hasNewItems={hasNewItems}
                    onNewItemsClick={handleHasNewItemsRefresh}
                    footer={{
                        page,
                        showAll,
                        itemsCount,
                        pagesCount,
                        columnsCount: COLUMNS_COUNT,
                        itemsPerPage,
                        onShowAll: handleShowAll,
                        onPrevPage: handlePrevPage,
                        onNextPage: handleNextPage,
                        onLastPage: handleLastPage,
                        onFirstPage: handleFirstPage,
                        onShowPages: handleShowPages,
                        onChangePage: handleChangePage,
                    }}
                >
                    <TableHeader>
                        <LogsTableHeader />
                    </TableHeader>
                    <TableBody>
                        {logs?.map((log, i) => (
                            <LogsTableBodyRow
                                key={`${log.createdAt}_${i}`}
                                log={log}
                                isClientPage
                            />
                        ))}
                    </TableBody>
                </Table>
            </Content>
        </Column>
    );
}

export default ClientAccessTable;
