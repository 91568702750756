// extracted by mini-css-extract-plugin
var _1 = "SoldSubscriptionInfoTableCells_clientCell__o2hHf";
var _2 = "SoldSubscriptionInfoTableCells_manual__6B4cl";
var _3 = "SoldSubscriptionInfoTableCells_paid__SLrln";
var _4 = "SoldSubscriptionInfoTableCells_pauseStatus__URC+L";
var _5 = "SoldSubscriptionInfoTableCells_paused__vCEmE";
var _6 = "SoldSubscriptionInfoTableCells_pending__YfZqm";
var _7 = "SoldSubscriptionInfoTableCells_statusCell__7sK-N";
var _8 = "SoldSubscriptionInfoTableCells_titleCell__s7BVk";
var _9 = "SoldSubscriptionInfoTableCells_titleStatus__SzXA0";
var _a = "SoldSubscriptionInfoTableCells_unpaid__Lr6YF";
export { _1 as "clientCell", _2 as "manual", _3 as "paid", _4 as "pauseStatus", _5 as "paused", _6 as "pending", _7 as "statusCell", _8 as "titleCell", _9 as "titleStatus", _a as "unpaid" }
