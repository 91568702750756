import { OverlayTrigger, Popover as BootstrapPopover } from 'react-bootstrap';
import React, {
    useId,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Popover = ({
    content, children, placement = 'bottom', onHide, show, onToggle, noArrow, small,
}) => {
    const id = useId();

    return (
        <OverlayTrigger
            rootClose
            trigger={show === undefined ? 'click' : show}
            placement={placement}
            onToggle={onToggle}
            onExited={onHide}
            show={show}
            overlay={(
                <BootstrapPopover
                    content={false}
                    id={id}
                    className={classNames('popover', noArrow && 'no-arrow', small && 'small')}
                >
                    {content}
                </BootstrapPopover>
            )}
        >
            {children}
        </OverlayTrigger>
    );
};

Popover.propTypes = {
    show: PropTypes.bool,
    onToggle: PropTypes.func,
    content: PropTypes.node.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.func,
    ]).isRequired,
    placement: PropTypes.string,
    onHide: PropTypes.func,
    small: PropTypes.bool,
    noArrow: PropTypes.bool,
};

Popover.defaultProps = {
    show: undefined,
    onToggle: null,
    placement: 'bottom',
    onHide: null,
    small: true,
    noArrow: true,
};
