import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { rejectPromiseAction, resolvePromiseAction } from '@adobe/redux-saga-promise';
import { FINANCIALS_INVOICES_LIST_PAGE } from 'const/CLIENT_URL';
import { getDefaultHeaders } from '../../../helpers/http/getDefaultHeaders';
import * as INVOICES_TYPES from '../../actions/financials/invoices/types';
import * as INVOICES_ACTIONS from '../../actions/financials/invoices';
import * as FINANCIALS_SELECTORS from '../../selectors/financilas';
import * as actions from '../../actions';

import * as TOAST_ACTIONS from '../../actions/toast';

import axios from '../../../services/axios';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_COMPANY_INVOICE_LIST_ROUTE,
    API_ADMIN_COMPANY_INVOICE_AMOUNT_ROUTE,
    API_ADMIN_COMPANY_INVOICE_EXPORT_ROUTE,
    API_ADMIN_COMPANY_INVOICE_ITEM_CREATE_CREDIT_ROUTE,
    API_ADMIN_COMPANY_INVOICE_ITEM_CHECK_CREDIT_ROUTE,
    API_ADMIN_CUSTOM_INVOICES_ROUTE,
    API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE,
    API_ADMIN_COMPANY_INVOICE_TOGGLE_STATUS_ROUTE,
    API_ADMIN_COMPANY_INVOICE_ITEM_RESEND_ROUTE,
} from '../../../const/API_URL';

function* getInvoices(action) {
    const { page, filters, search } = yield select(FINANCIALS_SELECTORS.invoicesListSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(INVOICES_ACTIONS.setInvoicesLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_INVOICE_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page,
                search,
                ...filters,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
                totalRevenue,
                appyBeePay,
                manual,
                exportEmail,
            } = result;

            const invoices = items.map(camelize);

            yield put(INVOICES_ACTIONS.setInvoices({
                invoices,
                itemsCount,
                pagesCount,
                totalRevenue,
                appyBeePay,
                manual,
                exportEmail,
            }));
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(INVOICES_ACTIONS.setInvoicesLoading({ loading: false }));
    }
}

function* getInvoicesAmount(action) {
    const { payload } = action;
    const { invoicesAmountData, onSuccess, onError } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(INVOICES_ACTIONS.setExportInProgress({ isExportInProgress: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_INVOICE_AMOUNT_ROUTE,
            headers: defaultHeaders,
            params: invoicesAmountData,
        });
        const { data: resData } = res;
        const { code } = resData;
        if (code === HTTP_STATUS_CODE.OK && resData.result) {
            if (onSuccess) {
                yield call(onSuccess);
            }
        } else {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.getAmount.warning',
                appearance: 'warning',
            }));
            if (onError) {
                yield call(onError);
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.getAmount.error',
                appearance: 'error',
            }));
            if (onError) {
                yield call(onError);
            }
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(INVOICES_ACTIONS.setExportInProgress({ isExportInProgress: false }));
    }
}

function* exportInvoices(action) {
    const { payload } = action;
    const { exportInvoicesData, onSuccess, onError } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.POST,
            url: API_ADMIN_COMPANY_INVOICE_EXPORT_ROUTE,
            headers: defaultHeaders,
            params: exportInvoicesData,
        });
        const { data: resData } = res;
        const { code } = resData;
        if (code === HTTP_STATUS_CODE.OK) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.export.success',
                insertion: { email: exportInvoicesData.email },
                appearance: 'success',
            }));

            yield put(INVOICES_ACTIONS.setExportEmail({ email: exportInvoicesData.email }));

            if (onSuccess) {
                yield call(onSuccess);
            }
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.export.error',
                appearance: 'error',
            }));
            if (onError) {
                yield call(onError);
            }
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* checkCreditStatus(action) {
    const { payload } = action;
    const { invoiceId, onSuccess } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_INVOICE_ITEM_CHECK_CREDIT_ROUTE({ invoiceId }),
            headers: defaultHeaders,
        });

        const { data: resData } = res;
        const { code, result } = resData;
        if (code === HTTP_STATUS_CODE.OK) {
            if (typeof onSuccess === 'function') {
                onSuccess({ result });
            }

            if (result) {
                yield put(TOAST_ACTIONS.showToast({
                    message: 'invoices.checkCreditStatus.alreadyCreated',
                    appearance: 'error',
                }));
            }
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.checkCreditStatus.alreadyCreated',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* createCredit(action) {
    const { payload } = action;
    const { invoiceId, onSuccess } = payload;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const result = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_INVOICE_ITEM_CREATE_CREDIT_ROUTE({ invoiceId }),
            headers: defaultHeaders,
        });

        const { data: resultData } = result;

        if (resultData.code === HTTP_STATUS_CODE.OK) {
            onSuccess?.();
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.createCredit.success',
                appearance: 'success',
            }));
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.createCredit.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* getCustomInvoices(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_CUSTOM_INVOICES_ROUTE,
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const products = camelize(resp.data.result);
        yield call(resolvePromiseAction, action, products);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export function* addCustomInvoice(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { data, history } = action.payload;

    try {
        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_CUSTOM_INVOICES_ROUTE,
            headers: defaultHeaders,
            data,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const products = camelize(resp.data.result);

        if (history.length > 1) {
            history.goBack();
        } else {
            history.push(FINANCIALS_INVOICES_LIST_PAGE);
        }

        yield put(TOAST_ACTIONS.showToast({
            message: 'invoices.createCustomInvoice.success',
            appearance: 'success',
        }));

        yield call(resolvePromiseAction, action, products);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export function* editCustomInvoice(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { data, history, bill } = action.payload;

    try {
        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE({ bill }),
            headers: defaultHeaders,
            data,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const products = camelize(resp.data.result);

        if (history.length > 1) {
            history.goBack();
        } else {
            history.push(FINANCIALS_INVOICES_LIST_PAGE);
        }

        yield put(TOAST_ACTIONS.showToast({
            message: 'invoices.createCustomInvoice.success',
            appearance: 'success',
        }));

        yield call(resolvePromiseAction, action, products);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.add.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

// API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE
export function* getCustomInvoice(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const { bill } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE({ bill }),
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const products = camelize(resp.data.result);
        yield call(resolvePromiseAction, action, products);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

// API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE
export function* deleteCustomInvoice(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const { bill } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'DELETE',
            url: API_ADMIN_CUSTOM_INVOICES_BILL_ROUTE({ bill }),
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const products = camelize(resp.data.result);

        yield call(resolvePromiseAction, action, products);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'products.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

// API_ADMIN_COMPANY_INVOICE_TOGGLE_STATUS_ROUTE
export function* toggleInvoicePaymentStatus(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const { invoiceId, onSuccess } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: API_ADMIN_COMPANY_INVOICE_TOGGLE_STATUS_ROUTE({ bill: invoiceId }),
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const invoices = camelize(resp.data.result);
        onSuccess?.();
        yield call(resolvePromiseAction, action, invoices);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.message || 'invoices.toggle.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

// API_ADMIN_COMPANY_INVOICE_ITEM_RESEND_ROUTE
export function* resendCustomInvoice(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const { bill } = action.payload;

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_COMPANY_INVOICE_ITEM_RESEND_ROUTE({ invoiceId: bill }),
            headers: defaultHeaders,
        });

        if (!resp.data?.result) {
            yield call(rejectPromiseAction, action);
            return;
        }
        const invoices = camelize(resp.data.result);

        yield put(TOAST_ACTIONS.showToast({
            message: 'invoices.resend.success',
            appearance: 'success',
        }));
        yield call(resolvePromiseAction, action, invoices);
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: error?.response?.data?.result?.message || 'invoices.resend.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export const invoicesListSaga = [
    takeLatest(INVOICES_TYPES.INVOICES_GET, getInvoices),
    takeLatest(INVOICES_TYPES.INVOICES_GET_AMOUNT, getInvoicesAmount),
    takeLatest(INVOICES_TYPES.INVOICES_EXPORT, exportInvoices),
    takeLatest(INVOICES_TYPES.INVOICES_ITEM_CHECK_CREDIT_STATUS, checkCreditStatus),
    takeLatest(INVOICES_TYPES.INVOICES_ITEM_CREATE_CREDIT, createCredit),
    takeLatest(actions.addCustomInvoice, addCustomInvoice),
    takeLatest(actions.editCustomInvoice, editCustomInvoice),
    takeLatest(actions.getCustomInvoices, getCustomInvoices),
    takeLatest(actions.getCustomInvoice, getCustomInvoice),
    takeLatest(actions.deleteCustomInvoice, deleteCustomInvoice),
    takeLatest(actions.toggleInvoicePaymentStatus, toggleInvoicePaymentStatus),
    takeLatest(actions.resendCustomInvoice, resendCustomInvoice),
];
