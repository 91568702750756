import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ArrowSmallLeft, ArrowSmallRight } from 'components/Icon/Icon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Dropdown } from 'react-bootstrap';

import { Row } from 'components/Common/Row';
import { IconButton } from 'components/Common/IconButton';
import { Label } from 'components/Common/Typography/Label';
import { Button } from 'components/Common/Button';
import { useDialog } from '../../hooks/useDialog';

import { CalendarIcon, Close } from '../Icon/Icon';

import * as styles from './MonthYearCalendar.module.scss';

import { LOCALE_NAMESPACE } from '../../const/translations/LOCALE_NAMESPACE';
import { TIME_PERIOD } from '../../const/clients/TIME_PERIOD';

const MONTHS = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

const MonthYearCalendar = (props) => {
    const {
        month = +moment().format('M'),
        year = +moment().format('YYYY'),
        period = TIME_PERIOD.MONTH,
        maxYear = +moment().format('YYYY') + 1,
        minYear = 2020,
        onChange,
    } = props;

    const dropdown = useDialog(false);
    const { t } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const [displayedYear, setDisplayedYear] = useState(year);
    const [selectedYear, setSelectedYear] = useState(year);
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [selectedPeriod, setSelectedPeriod] = useState(period);

    const calendarRef = useRef(null);

    const years = useMemo(() => Array.from(
        { length: maxYear - minYear + 1 },
        (_, i) => minYear + i,
    ), [maxYear, minYear]);

    const nextDisplayedYear = useCallback(() => {
        setDisplayedYear((prevYear) => Math.min(prevYear + 1, maxYear));
    }, []);

    const prevDisplayedYear = useCallback(() => {
        setDisplayedYear((prevYear) => Math.max(prevYear - 1, minYear));
    }, []);

    const nextMonth = useCallback(() => {
        if (selectedMonth === 12) {
            if (selectedYear === maxYear) {
                return;
            }

            setDisplayedYear(selectedYear + 1);
            setSelectedYear(selectedYear + 1);
            setSelectedMonth(1);
            return;
        }

        setSelectedMonth(selectedMonth + 1);
    }, [selectedMonth, selectedYear, maxYear]);

    const prevMonth = useCallback(() => {
        if (selectedMonth === 1) {
            if (selectedYear === minYear) {
                return;
            }

            setDisplayedYear(selectedYear - 1);
            setSelectedYear(selectedYear - 1);
            setSelectedMonth(12);
            return;
        }

        setSelectedMonth(selectedMonth - 1);
    }, [selectedMonth, selectedYear, minYear]);

    const nextYear = useCallback(() => {
        setSelectedYear((prevYear) => Math.min(prevYear + 1, maxYear));
    }, []);

    const prevYear = useCallback(() => {
        setSelectedYear((prevYear) => Math.max(prevYear - 1, minYear));
    }, []);

    const handleSelectMonth = useCallback((month) => {
        setSelectedMonth(month);
        setSelectedYear(displayedYear);
        dropdown.onClose();
    }, [displayedYear]);

    const handleSelectYear = useCallback((year) => {
        setSelectedYear(year);
        dropdown.onClose();
    }, []);

    useEffect(() => {
        const handleClickOutsideElement = (e) => {
            if (calendarRef.current.contains(e.target)) {
                return;
            }

            dropdown.onClose();
        };

        document.addEventListener('click', handleClickOutsideElement);

        return () => {
            document.removeEventListener('click', handleClickOutsideElement);
        };
    }, []);

    useEffect(() => {
        onChange({
            year: selectedYear,
            month: selectedMonth,
            period: selectedPeriod,
        });
    }, [selectedMonth, selectedYear, selectedPeriod]);

    return (
        <Row gap={8}>
            <Row gap={8}>
                <Row gap={0}>
                    <IconButton
                        size={32}
                        color="outline"
                        onClick={period === TIME_PERIOD.MONTH
                            ? prevMonth
                            : prevYear}
                        group
                    >
                        <ArrowSmallLeft />
                    </IconButton>

                    <IconButton
                        size={32}
                        color="outline"
                        onClick={period === TIME_PERIOD.MONTH
                            ? nextMonth
                            : nextYear}
                        group
                    >
                        <ArrowSmallRight />
                    </IconButton>
                </Row>

                <Dropdown>
                    <Dropdown.Toggle
                        variant="outline-light"
                        className={styles.periodDropdownToggle}
                    >
                        {t(`monthYearCalendar.periods.${selectedPeriod}`)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.periodDropdownMenu}>
                        <Dropdown.Item
                            onClick={() => setSelectedPeriod(TIME_PERIOD.MONTH)}
                            className={styles.periodDropdownMenuItem}
                        >
                            {t('monthYearCalendar.periods.month')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => setSelectedPeriod(TIME_PERIOD.YEAR)}
                            className={styles.periodDropdownMenuItem}
                        >
                            {t('monthYearCalendar.periods.year')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <div
                    ref={calendarRef}
                >
                    <IconButton
                        size={32}
                        color={dropdown.visible ? 'yellow' : 'outline'}
                        onClick={dropdown.onToggle}
                    >
                        <CalendarIcon />
                    </IconButton>

                    {dropdown.visible && (
                        <div
                            className={classNames(
                                styles.dropdown,
                                { [styles.autoWidth]: selectedPeriod === TIME_PERIOD.YEAR },
                            )}
                        >
                            <div className={styles.modalHeader}>
                                <div>
                                    {t('monthYearCalendar.title.label')}
                                </div>

                                <button
                                    onClick={dropdown.onClose}
                                    className={styles.closeButton}
                                >
                                    <Close />
                                </button>
                            </div>

                            {selectedPeriod === TIME_PERIOD.MONTH && (
                                <div className={styles.dropdownHeader}>
                                    <IconButton
                                        size={32}
                                        color="white"
                                        onClick={prevDisplayedYear}
                                    >
                                        <ArrowSmallLeft />
                                    </IconButton>

                                    {displayedYear}

                                    <IconButton
                                        onClick={nextDisplayedYear}
                                        size={32}
                                        color="white"
                                    >
                                        <ArrowSmallRight />
                                    </IconButton>
                                </div>
                            )}

                            {selectedPeriod === TIME_PERIOD.MONTH && (
                                <div className={styles.dropdownBody}>
                                    {MONTHS.map((month, index) => {
                                        const isActive = (
                                            index + 1 === selectedMonth
                                        && displayedYear === selectedYear
                                        );

                                        return (
                                            <Button
                                                key={`${month}-${year}`}
                                                onClick={() => handleSelectMonth(index + 1)}
                                                color={isActive ? 'yellow' : 'transparent'}
                                                capitalize
                                                size="small"
                                                style={{ width: 70 }}
                                            >
                                                {t(`date.month.${month}`).slice(0, 3)}
                                            </Button>
                                        );
                                    })}
                                </div>
                            )}

                            {selectedPeriod === TIME_PERIOD.YEAR && (
                                <div className={classNames(styles.dropdownBody, styles.dropdownBodyColumn)}>
                                    {years.map((year) => {
                                        const isActive = year === selectedYear;

                                        return (
                                            <Button
                                                key={year}
                                                onClick={() => handleSelectYear(year)}
                                                color={isActive ? 'yellow' : 'transparent'}
                                                capitalize
                                                size="small"
                                                style={{ width: 70 }}
                                            >
                                                {year}
                                            </Button>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {selectedPeriod === TIME_PERIOD.MONTH && (
                    <Label capitalize>
                        {`${t(`date.month.${MONTHS[selectedMonth - 1]}`)}, `}
                    </Label>
                )}
                <Label>
                    {selectedYear}
                </Label>
            </Row>
        </Row>
    );
};

export default MonthYearCalendar;
