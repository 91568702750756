import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SubNavigation } from 'components';
import NavigationService from 'services/navigationService';
import { THIRD_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { Row } from 'components/Common/Row';
import { useSubRoute } from 'hooks/useSubRoute';
import routes from './scenes';
import * as styles from './page.module.scss';

const subNavigationVisibleRoutes = ['list', 'sold'];

function Subscriptions() {
    const subNavigation = NavigationService.getSubscriptionsSubNavigation();
    const [currentSubRoute] = useSubRoute();

    return (
        <React.Fragment>
            {
                subNavigationVisibleRoutes.includes(currentSubRoute) && (
                    <div className={styles.withBorderBottom}>
                        <Row gap={32} spacing={32} style={{ height: THIRD_LEVEL_NAV_HEIGHT }}>
                            <SubNavigation
                                isDataLoaded
                                subRoutes={subNavigation}
                                withQuery={false}
                            />
                        </Row>
                    </div>
                )
            }
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    ))
                }

                <Redirect to={routes[0].path} />
            </Switch>
        </React.Fragment>
    );
}

export default Subscriptions;
