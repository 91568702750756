import React from 'react';

const Close = ({ width = '24', height = '24', className }) => (
    <svg
        className={className}
        height={height}
        width={width}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M5.29296 5.29283C5.68352 4.90234 6.31669 4.9024 6.70717 5.29296L11.999 10.5858L17.293 5.29283C17.6835 4.90234 18.3167 4.9024 18.7072 5.29296C19.0977 5.68352 19.0976 6.31669 18.707 6.70717L13.4137 11.9995L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5853 12.0005L5.29283 6.70704C4.90234 6.31648 4.9024 5.68331 5.29296 5.29283Z" fill="currentColor" />
    </svg>

);

export default Close;
