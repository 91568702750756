import { get } from 'lodash';

class SubscriptionsServicesOptionsService {
    static getAlreadySelectedServices(formValues) {
        const services = get(formValues, 'services', []);

        return services.reduce((acc, value) => {
            const { name } = value;

            return [...acc, ...name];
        }, []);
    }

    static getAvailableServicesOptions(servicesAll, formValues) {
        const services = servicesAll && servicesAll.flatMap((service) => (service?.subOptions?.length ? service.subOptions : service));
        const alreadySelectedServices = SubscriptionsServicesOptionsService.getAlreadySelectedServices(formValues);
        const servicesOptions = services.filter(({ archived }) => !archived).map(({ id, name }) => ({
            label: name,
            value: id,
        }));

        return servicesOptions.filter(({ value }) => !alreadySelectedServices.find((alreadySelectedService) => alreadySelectedService.value === value));
    }
}

export default SubscriptionsServicesOptionsService;
