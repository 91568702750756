import { PAYMENT_SYSTEM_TYPE } from 'const/payments/PAYMENT_SYSTEM_TYPE';
import { useSelector } from 'react-redux';

export const useMultisafe = () => {
    const { preferredPaymentSystem } = useSelector((state) => state.company);

    const isMultisafe = preferredPaymentSystem === PAYMENT_SYSTEM_TYPE.MULTISAFE_PAY;

    return isMultisafe;
};
