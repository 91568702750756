const PREFIX = '@@CLIENTS/';
const CLIENT_ITEM = `${PREFIX}:CLIENT:ITEM`;

const CLIENT_ITEM_EMAILS = `${CLIENT_ITEM}:EMAILS`;
export const CLIENTS_EMAILS_GET = `${CLIENT_ITEM_EMAILS}:GET`;
export const CLIENTS_EMAILS_GET_TRIGGER = `${CLIENTS_EMAILS_GET}.TRIGGER`;
export const CLIENTS_EMAILS_GET_RESOLVED = `${CLIENTS_EMAILS_GET}.RESOLVED`;
export const CLIENTS_EMAILS_GET_REJECTED = `${CLIENTS_EMAILS_GET}.REJECTED`;
const CLIENT_ITEM_EMAILS_PAGE = `${CLIENT_ITEM_EMAILS}:PAGE`;
export const CLIENT_ITEM_EMAILS_PAGE_SET = `${CLIENT_ITEM_EMAILS_PAGE}:SET`;
export const CLIENT_ITEM_EMAILS_PAGE_SET_FIRST = `${CLIENT_ITEM_EMAILS_PAGE}:FIRST`;
export const CLIENT_ITEM_EMAILS_PAGE_SET_LAST = `${CLIENT_ITEM_EMAILS_PAGE}:LAST`;
export const CLIENT_ITEM_EMAILS_PAGE_SET_PREV = `${CLIENT_ITEM_EMAILS_PAGE}:PREV`;
export const CLIENT_ITEM_EMAILS_PAGE_SET_NEXT = `${CLIENT_ITEM_EMAILS_PAGE}:NEXT`;
export const CLIENT_ITEM_EMAILS_FILTERS_SET = `${CLIENT_ITEM_EMAILS}:FILTERS:SET`;
export const CLIENT_ITEM_EMAILS_FILTERS_CLEAR = `${CLIENT_ITEM_EMAILS}:FILTERS:CLEAR`;
export const CLIENT_ITEM_EMAILS_SORT_OPTIONS_SET = `${CLIENT_ITEM_EMAILS}:SORT_OPTIONS:SET`;
