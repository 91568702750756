import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Form } from 'react-bootstrap';

// Styles

// Utils
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import IconButton from 'components/Common/IconButton/IconButton';
import { Button } from 'components/Common/Button';
import { HourInput } from 'components/Common/HourInput';
import * as styles from './WorkBreaks.module.scss';
import { Plus, Trash3Icon } from '../Icon/Icon';

const WorkBreaks = ({
    className, value, onChange, errors, touched, workingHours,
}) => {
    const { locale } = useSelector((state) => state.locales);

    const { t } = useTranslation();

    return (
        <div className={classNames(styles.container, className, 'border-top border-right border-light')}>
            {_.times(7, (i) => (
                <div key={`weekday-${i}`} className={classNames(styles.head, 'border-left border-light')}>
                    <span className="font-size-14">
                        {moment().locale(locale).isoWeekday(i + 1).format('dddd')
                            .substring(0, 3)}
                    </span>
                </div>
            ))}

            {_.times(7, (i) => (
                <div key={`weekday-content-${i}`} className="border-bottom border-left border-light">
                    {value[moment().isoWeekday(i + 1).format('dddd')].map(({ from, to }, j) => (
                        <div key={`workbreak-${i}-${j}`} className="border-top border-light" style={{ padding: 20 }}>
                            <div className="position-relative mb-3">
                                <HourInput
                                    allowAllHours
                                    data-testid="from"
                                    value={from}
                                    placeholder={t('workBreaks.breakStart')}
                                    onChange={(from) => onChange({
                                        type: 'UPDATE',
                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                        workBreak: {
                                            from,
                                            to,
                                        },
                                        index: j,
                                    })}
                                    isInvalid={!!touched && !!touched[moment().isoWeekday(i + 1).format('dddd')] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j].from && !!errors && !!errors[moment().isoWeekday(i + 1).format('dddd')] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j].from}
                                    max={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.to}
                                    min={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.from}

                                />
                                <Form.Control.Feedback
                                    className={!!touched && !!touched[moment().isoWeekday(i + 1).format('dddd')] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j].from && !!errors && !!errors[moment().isoWeekday(i + 1).format('dddd')] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j].from && 'd-block'}
                                    type="invalid"
                                >
                                    {!!touched && !!touched[moment().isoWeekday(i + 1).format('dddd')] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j].from && !!errors && !!errors[moment().isoWeekday(i + 1).format('dddd')] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].from}
                                </Form.Control.Feedback>
                            </div>

                            <div className="position-relative mb-2">
                                <HourInput
                                    allowAllHours
                                    data-testid="to"
                                    value={to}
                                    placeholder={t('workBreaks.breakEnd')}
                                    onChange={(to) => onChange({
                                        type: 'UPDATE',
                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                        workBreak: {
                                            from,
                                            to,
                                        },
                                        index: j,
                                    })}
                                    isInvalid={!!touched && !!touched[moment().isoWeekday(i + 1).format('dddd')] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j].to && !!errors && !!errors[moment().isoWeekday(i + 1).format('dddd')] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j].to}
                                    max={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.to}
                                    min={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.from}
                                />

                                <Form.Control.Feedback
                                    className={!!touched && !!touched[moment().isoWeekday(i + 1).format('dddd')] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j].to && !!errors && !!errors[moment().isoWeekday(i + 1).format('dddd')] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j].to && 'd-block'}
                                    type="invalid"
                                >
                                    {!!touched && !!touched[moment().isoWeekday(i + 1).format('dddd')] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j] && !!touched[moment().isoWeekday(i + 1).format('dddd')][j].to && !!errors && !!errors[moment().isoWeekday(i + 1).format('dddd')] && !!errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].to}
                                </Form.Control.Feedback>
                            </div>

                            <div className="d-flex justify-content-center">
                                <Button
                                    size="small"
                                    data-testid="remove"
                                    onClick={() => onChange({
                                        type: 'REMOVE',
                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                        index: j,
                                    })}
                                    color="gray"
                                    stretched
                                >
                                    <Trash3Icon width={20} />
                                </Button>
                            </div>
                        </div>
                    ))}

                    <div className="py-4 d-flex justify-content-center border-top border-light">
                        <IconButton
                            data-testid="add"
                            size={40}
                            onClick={() => onChange({
                                type: 'ADD',
                                day: moment().isoWeekday(i + 1).format('dddd'),
                                workBreak: { from: null, to: null },
                            })}
                        >
                            <Plus />
                        </IconButton>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WorkBreaks;
