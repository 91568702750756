import React from 'react';

const ArrowSmallRightDouble = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M7.49379 17.6123L12.2322 12.5L7.4938 7.3877C7.15277 7.01975 6.57076 7.01975 6.22973 7.3877C5.92342 7.71817 5.92343 8.22883 6.22973 8.5593L9.88218 12.5L6.22973 16.4407C5.92342 16.7712 5.92342 17.2818 6.22973 17.6123C6.57076 17.9802 7.15276 17.9802 7.49379 17.6123Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4938 17.6123L18.2322 12.5L13.4938 7.3877C13.1528 7.01976 12.5708 7.01975 12.2297 7.3877C11.9234 7.71817 11.9234 8.22883 12.2297 8.55931L15.8822 12.5L12.2297 16.4407C11.9234 16.7712 11.9234 17.2818 12.2297 17.6123C12.5708 17.9802 13.1528 17.9802 13.4938 17.6123Z" fill="currentColor" />
    </svg>

);

export default ArrowSmallRightDouble;
