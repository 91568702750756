import React from 'react';
import { useLocation } from 'react-router';
import PropTypes, { object } from 'prop-types';
import { SECOND_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { useMobile } from 'hooks/useMobile';
import NavigationService from 'services/navigationService';
import { Row } from 'components/Common/Row';
import { useSelector } from 'react-redux';
import { roleSelector } from 'store/selectors/auth';
import { userAccessKeysSelector } from 'store/selectors/user';
import ShopsDropdown from '../../ShopsDropdown/ShopsDropdown';
import SubNavigation from '../../Navigation/SubNavigation/SubNavigation';
import * as styles from './PortalSubHeader.module.scss';

function PortalSubHeader(props) {
    const {
        date,
        isDropdownDataLoaded,
        isNavigationDataLoaded,
        dropdownOptions,
        onDropdownItemSelect,
        shopId,
        shopName,
        showDropdown,
        withQuery,
        productsList,
        after,
        roles,
        accessKey,
    } = props;
    const { pathname } = useLocation();
    const parentRoute = NavigationService.getParentRoute(pathname);
    const { role } = useSelector(roleSelector);
    const accessKeys = useSelector(userAccessKeysSelector);
    const isMobile = useMobile();
    const canShowRoute = NavigationService.checkRolesAndAccessKeys({
        role,
        accessKeys,
        roles,
        accessKey,
    });

    const hasSubheader = !isMobile && showDropdown || !isMobile;

    if (!hasSubheader || !canShowRoute) {
        return null;
    }

    return (
        <div className="shadow-nav">
            <Row stretched gap={0}>
                {!isMobile && showDropdown && (
                    <div className={styles.shopsDropdown}>
                        <ShopsDropdown
                            isDataLoaded={isDropdownDataLoaded}
                            options={dropdownOptions}
                            onSelect={onDropdownItemSelect}
                            shopId={shopId}
                            shopName={shopName}
                        />
                    </div>
                )}
                <Row spacing={32} gap={32} stretched>
                    <Row gap={32} style={{ height: SECOND_LEVEL_NAV_HEIGHT }} stretched>
                        {!isMobile && (
                            <SubNavigation
                                productsList={productsList}
                                date={date}
                                isDataLoaded={isNavigationDataLoaded}
                                parentRoute={parentRoute}
                                shopId={shopId}
                                withQuery={withQuery}
                            />
                        )}
                    </Row>
                    {after}
                </Row>
            </Row>
        </div>
    );
}

PortalSubHeader.propTypes = {
    date: object,
    isDropdownDataLoaded: PropTypes.bool,
    isNavigationDataLoaded: PropTypes.bool,
    dropdownOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    onDropdownItemSelect: PropTypes.func,
    shopId: PropTypes.number,
    shopName: PropTypes.string,
    showDropdown: PropTypes.bool,
    after: PropTypes.node,
};

PortalSubHeader.defaultProps = {
    date: null,
    isDropdownDataLoaded: true,
    isNavigationDataLoaded: true,
    dropdownOptions: [],
    onDropdownItemSelect: () => {},
    shopId: null,
    shopName: '',
    showDropdown: false,
    after: null,
};

export default PortalSubHeader;
