import {
    call,
    fork,
    select,
    take,
    takeEvery,
    delay,
    put,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

// Actions
import { WORKER_TYPES } from 'const/WORKER_TYPES';
import { safeJsonParse } from 'helpers/json/safeJsonParse';
import { ACCESS_LOGS_SEARCH_PARAM_GATE_ID } from 'const/access/ACCESS_LOGS';
import WorkersController from '../../controllers/WorkersController';
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';
import { getSocket, openConnection } from '../../services/socket-io';
import sentry from '../../services/sentry';

function createSocketChannel() {
    const socket = getSocket();

    return eventChannel((emitter) => {
        const emitConnected = () => emitter({ type: 'CONNECTED' });
        const emitReconnected = () => emitter({ type: 'RECONNECT' });
        const emitDisconnected = () => emitter({ type: 'DISCONNECT' });
        const emitTriggerBookings = (payload) => emitter({
            type: 'TRIGGER_BOOKINGS_REFRESH',
            payload: JSON.parse(payload),
        });
        const emitNotifyBookingNew = (payload) => emitter({
            type: 'NOTIFICATION_BOOKING_NEW',
            payload: JSON.parse(payload),
        });
        const emitNotifyBookingCancel = (payload) => emitter({
            type: 'NOTIFICATION_BOOKING_CANCEL',
            payload: JSON.parse(payload),
        });
        const emitTriggerGates = (payload) => emitter({
            type: 'TRIGGER_GATES_REFRESH',
            payload: safeJsonParse(payload),
        });

        socket.on('connect', emitConnected);
        socket.on('reconnect', emitReconnected);
        socket.on('disconnect', emitDisconnected);
        socket.on('TRIGGER_BOOKINGS_REFRESH', emitTriggerBookings);
        socket.on('NOTIFICATION_BOOKING_NEW', emitNotifyBookingNew);
        socket.on('NOTIFICATION_BOOKING_CANCEL', emitNotifyBookingCancel);
        socket.on('TRIGGER_GATES_REFRESH', emitTriggerGates);

        return () => {
            socket.off('connect', emitConnected);
            socket.off('reconnect', emitReconnected);
            socket.off('disconnect', emitDisconnected);
            socket.off('TRIGGER_BOOKINGS_REFRESH', emitTriggerBookings);
            socket.off('NOTIFICATION_BOOKING_NEW', emitNotifyBookingNew);
            socket.off('NOTIFICATION_BOOKING_CANCEL', emitNotifyBookingCancel);
            socket.off('TRIGGER_GATES_REFRESH', emitTriggerGates);
        };
    });
}

function destroySocket() {
    const socket = getSocket();
    socket.close();
}

function* handleMessage({ type, payload }) {
    const socket = getSocket();

    const email = yield select((state) => state.user.email);
    const companyAPIKey = yield select((state) => state.company.apiKey);
    const storeShopId = yield select((state) => state.shop?.id);

    switch (type) {
    case 'CONNECTED': {
        yield* auth();
        break;
    }
    case 'RECONNECT': {
        if (!email || !companyAPIKey) {
            socket.close();
        }
        break;
    }
    case 'DISCONNECT': {
        break;
    }
    case 'TRIGGER_BOOKINGS_REFRESH': {
        const { productType, shopId } = payload;

        if (!productType || !shopId) return;

        const productTypeUrl = productType === 'basic' ? 'objects' : `${productType}s`;

        if (Number(storeShopId) !== Number(shopId)) return;

        if (!window.location.pathname.includes(productTypeUrl)) return;

        const { from, to } = yield select(
            (state) => state.app.bookingsFetchRange,
        );

        function* action() {
            if (productTypeUrl === 'objects' || productTypeUrl === 'employees') {
                return yield put(actions.getBookings(from, to, productType));
            }

            if (productTypeUrl === 'groups') {
                return yield put(actions.getGroupSessions(from, to, true));
            }

            if (productTypeUrl === 'events') {
                return yield put(actions.getEvents());
            }
        }

        yield delay(500);
        yield* action();

        break;
    }
    case 'NOTIFICATION_BOOKING_NEW': {
        const { shopId } = payload;
        if (Number(storeShopId) !== Number(shopId)) return;
        WorkersController.emit(WORKER_TYPES.SOUND_WORKER, {
            command: 'NOTIFY_BOOKING_NEW',
            data: {
                notifyId: shopId,
            },
        });

        break;
    }
    case 'NOTIFICATION_BOOKING_CANCEL': {
        const { shopId } = payload;
        if (Number(storeShopId) !== Number(shopId)) return;
        WorkersController.emit(WORKER_TYPES.SOUND_WORKER, {
            command: 'NOTIFY_BOOKING_CANCEL',
            data: {
                notifyId: shopId,
            },
        });

        break;
    }
    case 'TRIGGER_GATES_REFRESH': {
        const log = safeJsonParse(payload);
        const gateId = new URLSearchParams(window.location.search).get(ACCESS_LOGS_SEARCH_PARAM_GATE_ID);
        if (!gateId || Number(log?.gate.id) === Number(gateId)) {
            yield put(actions.getAccessStatistics({ silent: true, gateId }));
            yield put(actions.appendAccessLog({ log }));
        }
        break;
    }
    default:
        break;
    }
}

function* connectWebSocket() {
    yield delay(1000);
    openConnection();
    const channel = yield call(createSocketChannel);

    while (true) {
        try {
            const action = yield take(channel);
            yield fork(handleMessage, action);
        } catch (err) {
            sentry.error(err);
        }
    }
}

function* auth() {
    try {
        const socket = getSocket();

        const email = yield select((state) => state.user.email);
        const companyAPIKey = yield select((state) => state.company.apiKey);

        if (!email || !companyAPIKey) return;

        socket.emit(
            'authenticate',
            JSON.stringify({ email, company_api_key: companyAPIKey }),
        );
    } catch (err) {
        sentry.error(err);
    }
}

function* closeWebSocket() {
    try {
        yield call(destroySocket);
    } catch (err) {
        sentry.error(err);
    }
}

export const websocketSaga = [
    takeEvery(actionTypes.OPEN_WEBSOCKET_CONNECTION, connectWebSocket),
    takeEvery(actionTypes.CLOSE_WEBSOCKET_CONNECTION, closeWebSocket),
];
