import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Column } from '../Column';
import { Popover } from '../Popover';
import { Button } from '../Button';

import * as styles from './ActionSheet.module.scss';
import { Tooltip } from '../Tooltip';

const ActionSheet = ({ children, content }) => {
    const [show, setShow] = useState(undefined);

    return (
        <Popover
            placement="auto-end"
            show={show}
            onToggle={setShow}
            onHide={() => setShow(undefined)}
            content={(
                <div onClick={() => setShow(false)} className={styles.actionSheet}>
                    <Column gap={0} spacing={8}>
                        {content}
                    </Column>
                </div>
            )}
        >
            {children}
        </Popover>
    );
};

ActionSheet.propTypes = Popover.propTypes;
ActionSheet.defaultProps = Popover.defaultProps;

const ActionSheetItem = (props) => (
    <Tooltip tooltip={props.tooltip} placement="left" forButton isDisabled={!props.tooltip} stretched>
        <Button {...props} stretched className={styles.actionSheetItem} />
    </Tooltip>
);
ActionSheetItem.propTypes = {
    ...Button.propTypes,
    tooltip: PropTypes.string,
};
ActionSheetItem.defaultProps = {
    ...Button.defaultProps,
    tooltip: null,
    color: 'white',
};

export default Object.assign(ActionSheet, { Item: ActionSheetItem });
