import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import NumberInput from 'components/Common/NumberInput';
import { useBool } from '../../../../../../hooks/useBool';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';
import { generateRandomString } from '../../../../../../helpers/string/generateRandomString';
import { getError, isInvalid } from '../../../../../../services/validationService';

import DateInput from '../../../../../DateInput/DateInput';

import { CalendarIcon } from '../../../../../Icon/Icon';

import * as CLIENT_ACTIONS from '../../../../../../store/actions/clients/tables/bundles';

import { SOLD_BUNDLE_PROP } from '../../../../../../const/bundles/SOLD_BUNDLE_PROP';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'clientBundlesTable.dialogs.edit';
const T_BODY = `${T_PREFIX}.body`;
const T_FIELDS = `${T_BODY}.form.fields`;

const initialValues = {
    endsAt: {
        value: moment(new Date(SOLD_BUNDLE_PROP.ENDS_AT.VALUE.DEFAULT)),
        unlimited: SOLD_BUNDLE_PROP.ENDS_AT.UNLIMITED.DEFAULT,
    },
    purchasedAt: moment(new Date(SOLD_BUNDLE_PROP.PURCHASED_AT.DEFAULT)),
    maxUsages: {
        value: SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.DEFAULT,
        unlimited: SOLD_BUNDLE_PROP.MAX_USAGES.UNLIMITED.DEFAULT,
    },
};

const DATA_TRANSFORMER = {
    send: (data) => {
        const formData = new FormData();
        formData.append('endsAt', data.endsAt.value.unix());
        formData.append('unlimitedPeriod', Number(data.endsAt.unlimited));
        if (data.maxUsages.value) {
            formData.append('maxUsages', data.maxUsages.value);
        }
        formData.append('unlimitedUsages', Number(data.maxUsages.unlimited));
        return formData;
    },
};

const ClientBundleEditFormDialog = (props) => {
    const {
        visible,
        bundle,
        onClose,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.USER);

    const hasErrors = useBool(false);

    const dispatch = useDispatch();

    const validationSchema = useMemo(() => yup.object({
        endsAt: yup.object({
            unlimited: yup.bool(),
            value: yup.mixed()
                .when('unlimited', {
                    is: true,
                    then: yup.mixed().nullable(true),
                    otherwise: yup.mixed()
                        .when('purchasedAt', (purchasedAt, schema) => schema.test({
                            name: generateRandomString(),
                            test: (endsAt) => endsAt?.isSameOrAfter(purchasedAt),
                            message: t(`${T_FIELDS}.endsAt.value.validation.afterPurchase`, {
                                date: purchasedAt?.format('DD.MM.YY'),
                            }),
                        })).test({
                            name: generateRandomString(),
                            test: (endsAt) => {
                                const today = moment(new Date()).startOf('date');
                                return endsAt?.isSameOrAfter(today);
                            },
                            message: t(`${T_FIELDS}.endsAt.value.validation.todayOrAfter`),
                        })
                        .required(t(`${T_FIELDS}.endsAt.value.validation.required`)),
                }),
        }),
        maxUsages: yup.object({
            unlimited: yup.bool(),
            value: yup.number()
                .when('unlimited', {
                    is: true,
                    then: yup.number().nullable(true),
                    otherwise: yup.number()
                        .min(
                            SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
                            t(`${T_FIELDS}.maxUsages.value.validation.min`, {
                                value: SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MIN,
                            }),
                        )
                        .max(
                            SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
                            t(`${T_FIELDS}.maxUsages.value.validation.max`, {
                                value: SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MAX,
                            }),
                        )
                        .required(t(`${T_FIELDS}.maxUsages.value.validation.required`)),
                }),
        }),
    }), [t]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: useCallback((values) => {
            if (!bundle?.id) {
                return;
            }
            const transformedValues = DATA_TRANSFORMER.send(values);
            dispatch(CLIENT_ACTIONS.editClientBundlesItem({
                bundleId: bundle.id,
                bundle: transformedValues,
            }));
            onClose();
        }, [bundle?.id, dispatch, onClose]),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setTouched,
    } = formik;

    const validation = useMemo(() => {
        hasErrors.onFalse();
        return Object.keys(values).reduce((res, k) => {
            const value = values[k];
            let subValidation;
            const objects = ['maxUsages', 'endsAt'];
            if (objects.includes(k)) {
                subValidation = Object.keys(value).reduce((res1, kk) => {
                    const invalid = isInvalid(`${k}.${kk}`, errors, touched);
                    if (invalid) {
                        hasErrors.onTrue();
                    }
                    return {
                        ...res1,
                        [kk]: {
                            isInvalid: invalid,
                            error: getError(`${k}.${kk}`, errors),
                        },
                    };
                }, {});
            } else {
                const invalid = isInvalid(k, errors, touched);
                if (invalid) {
                    hasErrors.onTrue();
                }
                subValidation = {
                    isInvalid: invalid,
                    error: getError(k, errors),
                };
            }
            return {
                ...res,
                [k]: subValidation,
            };
        }, {});
    }, [values, errors, touched]);

    const handleChangeEndsAt = useCallback((endsAt) => {
        setFieldTouched('endsAt.value', true);
        setFieldValue('endsAt.value', endsAt);
    }, [setFieldValue, setFieldTouched]);

    useEffect(() => {
        if (!visible || !bundle) {
            return;
        }

        setTouched({});

        setFieldValue('purchasedAt', moment.unix(bundle.purchasedAt));
        setFieldValue(
            'endsAt.value',
            bundle.endsAt
                ? moment.unix(bundle.endsAt)
                : moment(),
        );
        setFieldValue('endsAt.unlimited', !bundle.endsAt);
        setFieldValue('maxUsages.value', bundle.maxUsages ?? '');
        setFieldValue('maxUsages.unlimited', bundle.maxUsages === null);
    }, [visible, bundle]);

    useEffect(() => {
        if (values.maxUsages.unlimited) {
            setFieldValue('maxUsages.value', '');
        }
    }, [values.maxUsages.unlimited]);

    return (
        <Modal
            show={visible}
            centered
            restoreFocus={false}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t(`${T_PREFIX}.header.title`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Container
                        fluid
                        className="m-0 p-0"
                    >
                        <Row className="m-0 p-0">
                            <Col
                                xs={12}
                                className="m-0 p-0"
                            >
                                <Form.Group>
                                    <Form.Label>
                                        {t(`${T_FIELDS}.endsAt.value.label`)}
                                    </Form.Label>
                                    <InputGroup>
                                        <DateInput
                                            isDisabled={values.endsAt.unlimited}
                                            value={values.endsAt.unlimited ? null : values.endsAt.value}
                                            onChange={handleChangeEndsAt}
                                            isInvalid={validation?.endAt?.value?.isInvalid}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <CalendarIcon width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': validation?.endsAt?.value?.isInvalid,
                                        })}
                                    >
                                        {validation?.endsAt?.value?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="m-0 p-0">
                            <Col
                                xs={12}
                                className="m-0 p-0"
                            >
                                <Form.Group>
                                    <CheckBox
                                        id="endsAt.unlimited"
                                        name="endsAt.unlimited"
                                        label={t(`${T_FIELDS}.endsAt.unlimited.label`)}
                                        checked={values.endsAt.unlimited}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': validation?.endsAt?.unlimited?.isInvalid,
                                        })}
                                    >
                                        {validation?.endsAt?.unlimited?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="m-0 p-0">
                            <Col
                                xs={12}
                                className="m-0 p-0"
                            >
                                <Form.Group>
                                    <Form.Label>
                                        {t(`${T_FIELDS}.maxUsages.value.label`)}
                                    </Form.Label>
                                    <NumberInput
                                        name="maxUsages.value"
                                        value={values.maxUsages.value}
                                        onChange={(value) => setFieldValue('maxUsages.value', value)}
                                        isInvalid={validation?.maxUsages?.value?.isInvalid}
                                        min={SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MIN}
                                        max={SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.MAX}
                                        step={SOLD_BUNDLE_PROP.MAX_USAGES.VALUE.STEP}
                                        disabled={values.maxUsages.unlimited}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': validation?.maxUsages?.value?.isInvalid,
                                        })}
                                    >
                                        {validation?.maxUsages?.value?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="m-0 p-0">
                            <Col
                                xs={12}
                                className="m-0 p-0"
                            >
                                <Form.Group>
                                    <CheckBox
                                        id="maxUsages.unlimited"
                                        name="maxUsages.unlimited"
                                        label={t(`${T_FIELDS}.maxUsages.unlimited.label`)}
                                        checked={values.maxUsages.unlimited}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': validation?.maxUsages?.unlimited?.isInvalid,
                                        })}
                                    >
                                        {validation?.maxUsages?.unlimited?.error}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row
                            className="m-0 p-0 justify-content-between flex-nowrap"
                        >
                            <Button
                                color="outline"
                                onClick={onClose}
                            >
                                {t(`${T_BODY}.actions.cancel`)}
                            </Button>
                            <Button
                                type="submit"
                                disabled={hasErrors.value}
                            >
                                {t(`${T_BODY}.actions.save`)}
                            </Button>
                        </Row>
                    </Container>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

ClientBundleEditFormDialog.propTypes = {
    visible: PropTypes.bool,
    bundle: PropTypes.object,
    onClose: PropTypes.func,
};

ClientBundleEditFormDialog.defaultProps = {
    visible: false,
    bundle: null,
    onClose: emptyFunc,
};

export default ClientBundleEditFormDialog;
