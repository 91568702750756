import React from 'react';

const Mail = ({ className, width = '25', height = '24' }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.62939 4.5C5.83375 4.5 5.07068 4.81607 4.50807 5.37868C3.94546 5.94129 3.62939 6.70435 3.62939 7.5V7.8015L12.6294 12.648L21.6294 7.803V7.5C21.6294 6.70435 21.3133 5.94129 20.7507 5.37868C20.1881 4.81607 19.425 4.5 18.6294 4.5H6.62939ZM21.6294 9.5055L12.9849 14.16C12.8756 14.2188 12.7535 14.2496 12.6294 14.2496C12.5053 14.2496 12.3832 14.2188 12.2739 14.16L3.62939 9.5055V16.5C3.62939 17.2957 3.94546 18.0587 4.50807 18.6213C5.07068 19.1839 5.83375 19.5 6.62939 19.5H18.6294C19.425 19.5 20.1881 19.1839 20.7507 18.6213C21.3133 18.0587 21.6294 17.2957 21.6294 16.5V9.5055Z" fill="currentColor" />
    </svg>

);

export default Mail;
