import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Label } from 'components/Common/Typography/Label';
import TextButton from 'components/Common/TextButton/TextButton';
import { Button } from 'components/Common/Button';
import { Row } from 'components/Common/Row';
import { IconButton } from 'components/Common/IconButton';
import { Close } from 'components/Icon/Icon';
import SubHeader from 'components/Common/Typography/SubHeader/SubHeader';
import { Tooltip } from 'components/Common/Tooltip';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useMobile } from 'hooks/useMobile';
import * as styles from './TableMultiselectPanel.module.scss';

const TableMultiselectPanel = (props) => {
    const {
        selectedCount,
        totalCount,
        onSelectAll,
        onDeselectAll,
        actions,
        info,
        loading,
    } = props;

    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const { t } = useTranslation(LOCALE_NAMESPACE.TABLE);

    const isMobile = useMobile();

    const CancelButton = useMemo(() => (selectedCount > 0 ? (
        <div>
            <Tooltip
                tooltip={tc('placeholders.cancel')}
                placement="top"
                forButton
            >
                <IconButton
                    onClick={onDeselectAll}
                    color="transparent"
                    loading={loading}
                >
                    <Close />
                </IconButton>
            </Tooltip>
        </div>
    ) : null), [selectedCount, onDeselectAll, loading]);

    const infoString = useMemo(() => info?.filter((item) => item.count > 0).map((item) => (`${item.label} (${item.count})`)).join(' · '), [info]);

    return (
        <div
            className={styles.root}
        >
            <Row gap={8} wrap>
                <Row gap={8} wrap>
                    <Row stretched={isMobile} justify="between">
                        <SubHeader>
                            {selectedCount > 1 ? `${selectedCount} ${t('header.itemsSelected')}` : `${selectedCount} ${t('header.itemSelected')}`}
                        </SubHeader>
                        {isMobile && CancelButton}
                    </Row>
                    {!!infoString && (
                        <Label color="gray">
                            {infoString}
                        </Label>
                    )}
                </Row>
                {selectedCount !== totalCount && (
                    <TextButton
                        color="yellow"
                        onClick={onSelectAll}
                        noPadding={isMobile}
                        disabled={loading}
                    >
                        {`${tc('placeholders.selectAll')} (${totalCount})`}
                    </TextButton>
                )}
            </Row>
            <Row gap={16} wrap>
                {actions.length > 0 && (
                    <Row gap={8} wrap>
                        {actions.map((action) => (
                            <Button
                                key={action.label}
                                onClick={action.onClick}
                                before={action.icon}
                                uppercase
                                size="small"
                                color="white"
                                loading={action.loading || loading}
                            >
                                {action.label}
                            </Button>
                        ))}
                    </Row>
                )}
                {!isMobile && CancelButton}
            </Row>
        </div>
    );
};

// multiselectPanel = { selectedCount, onSelectAll, onDeselectAll, actions, info }

TableMultiselectPanel.propTypes = {
    selectedCount: PropTypes.number,
    totalCount: PropTypes.number,
    onSelectAll: PropTypes.func,
    onDeselectAll: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.node,
        label: PropTypes.string,
        onClick: PropTypes.func,
    })),
    info: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
    })),
    loading: PropTypes.bool,
};

TableMultiselectPanel.defaultProps = {
    selectedCount: 0,
    totalCount: 0,
    onSelectAll: () => { },
    onDeselectAll: () => { },
    actions: [],
    info: [],
    loading: false,
};

export default TableMultiselectPanel;
