import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';

// Styles

// Utils
import emojiFlags from 'emoji-flags';
import emoji from 'react-easy-emoji';
import classNames from 'classnames';
import _ from 'lodash';
import countries from './countries';
import { phoneSelect, dangerTheme, theme } from '../../styles/select';
import { PhoneIcon } from '../Icon/Icon';

const PhoneInput = ({
    disabled = false, value, onChange, variant, isInvalid, defaultCountry = 'nl',
}) => {
    const [inputValue, setInputValue] = useState('');
    const [country, setCountry] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        const country = value.startsWith('+') ? countries.find((country) => value.startsWith(`+${country[3]}`)) : countries.find((country) => country[2] === defaultCountry);
        setCountry(country);
        setInputValue(country && value.startsWith('+') ? value.substring(country[3].length + 1, value.length) : value);
    }, [value]);

    if (disabled) {
        return (
            <Form.Group>
                <Form.Control
                    disabled
                    type="text"
                    value={value ?? ''}
                />
            </Form.Group>
        );
    }

    return (
        <InputGroup style={{ width: '100%' }}>
            <Select
                styles={phoneSelect}
                theme={isInvalid || variant === 'danger' ? dangerTheme : theme}
                options={countries.map((country) => ({
                    value: country && country[2],
                    label: (
                        <div className="d-flex flex-row align-items-center">
                            <span className="position-relative" style={{ top: 1.5, fontSize: 20 }}>
                                {emoji(emojiFlags.countryCode(country[2].toUpperCase())
                                    ? emojiFlags.countryCode(country[2].toUpperCase()).emoji
                                    : '')}
                            </span>
                            <span className="ml-1">
                                {country[0]}
                                {' '}
                                (+
                                {country[3]}
                                )
                            </span>
                        </div>
                    ),
                }))}
                value={{
                    value: country && country[2],
                    label: country && (
                        <div className="d-flex flex-row align-items-center">
                            <span className="position-relative" style={{ top: 1.5, fontSize: 20 }}>
                                {emoji(emojiFlags.countryCode(country[2].toUpperCase())
                                    ? emojiFlags.countryCode(country[2].toUpperCase()).emoji
                                    : '')}
                            </span>
                            <span className="ml-1">
                                +
                                {country[3]}
                            </span>
                        </div>
                    ),
                }}
                onChange={({ value }) => {
                    const country = countries.find((c) => c[2] === value);
                    setCountry(country);
                    if (onChange) {
                        onChange(`+${country && country[3]} ${inputValue}`);
                    }
                }}
                placeholder={t('placeholders.select')}
            />
            <InputMask
                mask={_.times(24, () => '9').join('')}
                maskPlaceholder={null}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    if (onChange) {
                        onChange(`+${country && country[3]} ${e.target.value}`);
                    }
                }}
                isInvalid={isInvalid || variant === 'danger'}
            >
                <Form.Control type="text" />
            </InputMask>

            <InputGroup.Append>
                <InputGroup.Text
                    className={classNames({
                        'border-danger text-danger': isInvalid || variant === 'danger',
                        'text-muted': !isInvalid && variant !== 'danger',
                    }, 'border-left-0')}
                >
                    <PhoneIcon width={18} />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );
};

export default PhoneInput;
