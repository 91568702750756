import React, {
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isInvalid, getError } from 'services/validationService';
import { WindowedMenuList } from 'react-windowed-select';
import { components } from 'react-select';

import {
    styles, dangerTheme, theme,
} from 'styles/select';
import classNames from 'classnames';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { productInitialData } from 'pages/financials/scenes/invoices/scenes/editInvoice/page';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Label } from 'components/Common/Typography/Label';
import { IconButton } from 'components/Common/IconButton';
import { Close } from 'components/Icon/Icon';
import { SimpleCell } from 'components/SimpleCell';
import CreatableSelect from 'react-select/creatable';

const MAX_PRODUCT_NAME = 250;

function ProductsSelect({
    product,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    products,
    index,
    onDelete,
}) {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const ref = useRef(null);

    const resetFieldsError = useCallback(() => {
        setFieldTouched(`products[${index}].name`, false, false);
        setFieldTouched(`products[${index}].price`, false, false);
        setFieldTouched(`products[${index}].vatValue`, false, false);
        setFieldTouched(`products[${index}].quantity`, false, false);
    }, []);

    const onProductCreateOption = useCallback((name) => {
        resetFieldsError();
        const productInitial = productInitialData();
        setFieldValue(`products[${index}].id`, productInitial.id);
        setFieldValue(`products[${index}].name`, name);
        setFieldValue(`products[${index}].price`, product.price ?? productInitial.price);
        setFieldValue(`products[${index}].vatValue`, product.vatValue ?? productInitial.vatValue);
        setFieldValue(`products[${index}].quantity`, product.quantity ?? productInitial.quantity);
    }, [product]);

    const onProductChange = useCallback((selected) => {
        resetFieldsError();
        if (!selected) {
            const productInitial = productInitialData();
            setFieldValue(`products[${index}].id`, productInitial.id);
            setFieldValue(`products[${index}].name`, product.name ?? productInitial.name);
            setFieldValue(`products[${index}].price`, product.price ?? productInitial.price);
            setFieldValue(`products[${index}].vatValue`, product.vatValue ?? productInitial.vatValue);
            setFieldValue(`products[${index}].quantity`, product.quantity ?? productInitial.quantity);
            return;
        }
        const {
            id, name, price, vatValue,
        } = selected;
        setFieldValue(`products[${index}].id`, id);
        setFieldValue(`products[${index}].name`, name);
        setFieldValue(`products[${index}].price`, price);
        setFieldValue(`products[${index}].vatValue`, vatValue);
        setFieldValue(`products[${index}].quantity`, product.quantity);
    }, [product]);

    const getProductFormatOptionLabel = useCallback((
        { value, label, info },
    ) => (
        <SimpleCell
            subHeader={info}
            rightAction={(
                Boolean(info) && (
                    <IconButton
                        color="transparent"
                        size={32}
                        onClick={(e) => {
                            ref.current.blur();
                            e.stopPropagation();
                            onDelete(value);
                        }}
                        data-testid="data-test-product-remove-button"
                    >
                        <Close height={20} width={20} />
                    </IconButton>
                )
            )}
            hoverAction
        >
            {label}
        </SimpleCell>
    ), [onDelete]);

    const productNameValue = useMemo(() => (
        {
            value: product.id,
            label: product.name,
        }
    ), [
        product,
    ]);

    const options = useMemo(() => (
        products.map((product) => ({
            ...product,
            value: product.id,
            label: product.name,
            info: `${formatPrice.toEuroWithComma({ amount: product.price })} · ${t('add.products.vat.label', { vat: product.vatValue })}`,
        }))
    ), [products]);

    const filterOption = useCallback((option, query) => option.label.toLowerCase().includes(query.toLowerCase()), []);

    return (
        <React.Fragment>
            <Label htmlFor={`name-${index}`} paddingBottom>
                {t('add.products.name.label')}
            </Label>
            <CreatableSelect
                ref={ref}
                id={`name-${index}`}
                isSearchable
                options={options}
                name={`products[${index}].name`}
                styles={styles}
                theme={isInvalid(`products[${index}].name`, errors, touched) ? dangerTheme : theme}
                value={productNameValue}
                onCreateOption={onProductCreateOption}
                onChange={onProductChange}
                formatOptionLabel={getProductFormatOptionLabel}
                filterOption={filterOption}
                cacheOptions
                defaultOptions
                formatCreateLabel={(inputValue) => t('add.products.create.label', { name: inputValue })}
                components={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Input: (props) => (
                        <components.Input {...props} maxLength={MAX_PRODUCT_NAME} />
                    ),
                    MenuList: WindowedMenuList,
                }}
            />
            <Form.Control.Feedback
                type="invalid"
                className={classNames({
                    'd-flex': isInvalid(`products[${index}].name`, errors, touched),
                })}
            >
                {getError(`products[${index}].name`, errors)}
            </Form.Control.Feedback>
        </React.Fragment>
    );
}

export default ProductsSelect;
