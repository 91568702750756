import Agenda from 'routes/DashboardGroup/Agenda/Agenda';
import Access from './access';
import Statistics from './statistics';
import Financials from './financials';
import Services from './services';
import Marketing from './marketing';
import { ClientsPage } from './clients';
import Settings from './settings';
import SignUp from './signUp';
import SignIn from './signIn';
import ResetPassword from './resetPassword';
import ResetPasswordSuccess from './resetPasswordSuccess';
import SetNewPassword from './setNewPassword';
import Activity from './activity';
import { routesKeys } from './routes';
import { roles } from './roles';

export const routesObject = {
    [routesKeys.activity]: {
        component: Activity,
        authRequired: true,
        exact: false,
        path: '/activity',
        roles: roles.activity,
        accessKey: 'canOpenActivity',
    },
    [routesKeys.access]: {
        component: Access,
        authRequired: true,
        exact: false,
        path: '/access',
        roles: roles.access,
        accessKey: 'canOpenAccessLogs',
    },
    [routesKeys.agenda]: {
        component: Agenda,
        authRequired: true,
        exact: false,
        path: '/agenda',
        roles: roles.agenda,
        accessKey: 'canOpenAgenda',
    },
    [routesKeys.services]: {
        component: Services,
        authRequired: true,
        exact: false,
        path: '/services',
        roles: roles.services,
        accessKey: 'canOpenServices',
    },
    [routesKeys.clients]: {
        component: ClientsPage,
        authRequired: true,
        exact: false,
        path: '/clients',
        roles: roles.clients,
        accessKey: 'canOpenClients',
    },
    [routesKeys.marketing]: {
        component: Marketing,
        authRequired: true,
        exact: false,
        path: '/marketing',
        roles: roles.marketing,
        accessKey: 'canOpenMarketing',
    },
    [routesKeys.statistics]: {
        component: Statistics,
        authRequired: true,
        exact: true,
        path: '/statistics',
        roles: roles.statistics,
        accessKey: 'canOpenStatistics',
    },
    [routesKeys.financials]: {
        component: Financials,
        authRequired: true,
        exact: false,
        path: '/financials',
        roles: roles.financials,
        accessKey: 'canOpenFinancials',
    },
    [routesKeys.settings]: {
        component: Settings,
        authRequired: true,
        exact: false,
        path: '/settings',
        roles: roles.settings,
        accessKey: 'canOpenCompanySettings',
    },
    [routesKeys.signUp]: {
        component: SignUp,
        authRequired: false,
        exact: true,
        path: '/sign-up',
        roles: roles.signUp,
        accessKey: 'canOpenSignUp',
    },
    [routesKeys.signIn]: {
        component: SignIn,
        authRequired: false,
        exact: true,
        path: '/sign-in',
        roles: roles.signIn,
        accessKey: 'canOpenSignIn',
    },
    [routesKeys.resetPassword]: {
        component: ResetPassword,
        authRequired: false,
        exact: true,
        path: '/reset-password',
        roles: roles.resetPassword,
        accessKey: 'canOpenResetPassword',
    },
    [routesKeys.resetPasswordSuccess]: {
        component: ResetPasswordSuccess,
        authRequired: false,
        exact: true,
        path: '/reset-password-success',
        roles: roles.resetPasswordSuccess,
        accessKey: 'canOpenResetPasswordSuccess',
    },
    [routesKeys.setNewPassword]: {
        component: SetNewPassword,
        authRequired: false,
        exact: true,
        path: '/set-new-password',
        roles: roles.setNewPassword,
        accessKey: 'canOpenSetNewPassword',
    },
};

export const routesArray = Object.values(routesObject);
