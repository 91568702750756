/* eslint-disable no-underscore-dangle */
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store } from '../store/createStore';
import { refreshToken, queueFailedAction } from '../store/actions';
import { ENVS } from './envs';
import { sessionStorage } from './sessionStorage';

const instance = axios.create({
    baseURL: (ENVS.isDevelopment && sessionStorage.getItem('appBaseUrl')) || ENVS.appBaseUrl,
    auth: {
        username: (ENVS.isDevelopment && sessionStorage.getItem('appApiUsername')) || ENVS.appApiUsername,
        password: (ENVS.isDevelopment && sessionStorage.getItem('appApiPassword')) || ENVS.appApiPassword,
    },
    adapter: 'fetch',
});

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        try {
            if (!error.response) {
                return Promise.reject('Network Error');
            }

            if (error.response.status === 401 && !error.config._retry) {
                // eslint-disable-next-line no-param-reassign
                error.config._retry = true;
                const data = await store.dispatch(refreshToken());
                const { accessToken: newAccessToken } = data;
                const tokenType = error.config.headers['X-Auth-Token'].split(' ')[0];
                // eslint-disable-next-line no-param-reassign
                error.config.headers['X-Auth-Token'] = `${tokenType} ${newAccessToken}`;
                return instance(error.config);
            }
            return Promise.reject(error);
        } catch (error) {
            store.dispatch(queueFailedAction({ action: error.config._action }));
        }
    },
);

export default instance;
