import React, {
    useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import {
    Col, Modal, Row,
} from 'react-bootstrap';
import moment from 'moment';
import classNames from 'classnames';

import { formatPrice } from 'helpers/formatters/formatPrice';
import TextButton from 'components/Common/TextButton/TextButton';
import IconButton from 'components/Common/IconButton/IconButton';
import { Button } from 'components/Common/Button';
import { Content } from 'components/Common/Content';
import { useBool } from '../../hooks/useBool';
import { useDialog } from '../../hooks/useDialog';
import { getFullName } from '../../helpers/services/getFullName';

import { Time, Trash3Icon } from '../Icon/Icon';
import { ConfirmationModal } from '..';

import * as styles from './BookingModal.module.scss';

import * as actions from '../../store/actions';
import { permissionsBookingSelector } from '../../store/selectors/permissions';

import { PAYMENT_TYPE } from '../../const/payments/PAYMENT_TYPE';
import { PRODUCT_TYPE } from '../../const/product/PRODUCT_TYPE';
import { PAYMENT_SYSTEM_TYPE } from '../../const/payments/PAYMENT_SYSTEM_TYPE';
import { CLIENTS_LIST_ID_PAGE } from '../../const/CLIENT_URL';

const NOT_IDEAL_PAYMENTS = [PAYMENT_TYPE.CASH, PAYMENT_TYPE.SUBSCRIPTION, PAYMENT_TYPE.BUNDLE, PAYMENT_TYPE.DISCOUNT];

const BookingModal = ({
    bookingIDWithTimestamp, show, onHide, onEditChange, productType = 'object',
}) => {
    const bookings = useSelector((state) => state[`${productType}s`].bookings);
    const { cancellationFee } = useSelector((state) => state.company);

    const dispatch = useDispatch();

    const confirmationChangeStatusPaid = useDialog(false);

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isPresentModal, setPresentModal] = useState(false);
    const [isDeletingRecurringBooking, setDeletingRecurringBooking] = useState(false);
    const [isDeletingBooking, setDeletingBooking] = useState(false);
    const [isOmittingBooking, setOmittingBooking] = useState(false);
    const [isChangingPayBooking, setChangingPayBooking] = useState(false);

    const booking = bookings && bookings.find(({ id, time }) => id === bookingIDWithTimestamp?.id && time.from === bookingIDWithTimestamp?.timestamp);

    const {
        banEdit,
        banDelete,
        banSetPaid,
        banSetPresent,
    } = useSelector(permissionsBookingSelector(booking?.recurrence.enabled));

    const { addToast } = useToasts();

    const { t, i18n } = useTranslation();

    const isWithCancellationFee = useBool(true);
    const isNeedWithCancellationFeeParam = useBool(false);

    const isBookingPaid = booking?.payment?.paid;
    const isEditable = (
        !banEdit
        && ![PRODUCT_TYPE.GROUP, PRODUCT_TYPE.EVENT].includes(productType)
    );

    const generalBanDelete = (timestamp) => {
        if (!booking?.recurrence.enabled && banDelete) return true;
        if (booking?.recurrence.enabled
            && timestamp
            && banDelete.oneRecurring
        ) return true;
        if (booking?.recurrence.enabled
            && !timestamp
            && banDelete.wholeRecurring
        ) return true;

        return false;
    };

    const onDeleteHandler = async (timestamp) => {
        if (
            generalBanDelete(timestamp)
            || isDeletingBooking
            || isDeletingRecurringBooking
        ) {
            return;
        }

        const loadingHandler = timestamp ? setDeletingBooking : setDeletingRecurringBooking;
        const label = t(`bookingModal.${timestamp ? 'b' : 'recurringB'}ookingHasBeenSuccessfullyDeleted`);
        loadingHandler(true);
        const data = {
            booking,
            timestamp,
        };
        if (isNeedWithCancellationFeeParam.value) {
            data.isWithCancellationFee = isWithCancellationFee.value;
        }

        try {
            await dispatch(actions.deleteBooking(data));
            addToast(label, {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
            onHide();
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            loadingHandler(false);
        }
    };

    const onOmitHandler = async () => {
        if (banSetPresent || isOmittingBooking) return;

        setOmittingBooking(true);
        try {
            await dispatch(actions.omitBooking({
                bookingID: booking.id,
                timestamp: booking.time.from / 1000,
            }));
            addToast(t('bookingModal.bookingHasBeenOmitted'), {
                appearance: 'success',
            });
            setPresentModal(false);
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        } finally {
            setOmittingBooking(false);
        }
    };

    const isCanChange = useMemo(() => (
        !banSetPaid
        && booking?.payment?.finalCost
        // '-' is a temporary solution for the case when the payment type is not set, but the booking is maid and we need to change the status
        && [PAYMENT_TYPE.SUBSCRIPTION, PAYMENT_TYPE.CASH, PAYMENT_TYPE.IDEAL, '-'].includes(booking?.payment?.type)
    ), [
        banSetPaid,
        booking?.payment?.finalCost,
        booking?.payment?.type,
    ]);

    const onChangePayHandler = async () => {
        setChangingPayBooking(true);
        try {
            await dispatch(actions.changePayStatus({
                bookingID: booking.id,
                timestamp: booking.time.from / 1000,
                paymentStatus: isBookingPaid ? 'open' : 'paid',
            }));
            addToast(t('addBookingModal.payStatusChangedSuccessfully'), {
                appearance: 'success',
            });
        } catch ({ message }) {
            addToast(message, {
                appearance: 'error',
            });
        }
        setChangingPayBooking(false);
        confirmationChangeStatusPaid.onClose();
    };

    useEffect(() => {
        if (!booking) {
            onHide();
        }
    }, [booking]);

    if (!booking) {
        return null;
    }

    const {
        time: {
            from,
        },
        payment: {
            type,
            paymentSystem,
            finalCost,
            paid,
        },
        cancellationPolicy,
    } = booking;

    const confirmCancelText = (() => {
        const isFreeBooking = !finalCost || !paid;
        const isBundleOrSubscriptionPaymentType = [PAYMENT_TYPE.BUNDLE, PAYMENT_TYPE.SUBSCRIPTION].includes(type);
        const hasCancellationFee = !NOT_IDEAL_PAYMENTS.includes(type.toLowerCase());
        const isInsideCancellationPolicy = moment.unix(from / 1000).diff(moment(), 'hours') > (cancellationPolicy ?? 0);
        const isCashPaymentType = type === PAYMENT_TYPE.CASH;

        if ((isFreeBooking || !hasCancellationFee) && isInsideCancellationPolicy) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {isBundleOrSubscriptionPaymentType ? t('bookingModal.cancelConfirmation.freeBookingRefund') : t('bookingModal.cancelConfirmation.freeBooking')}
                </p>
            );
        }

        if ((isFreeBooking && !isBundleOrSubscriptionPaymentType) || isCashPaymentType) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {t('bookingModal.cancelConfirmation.freeBooking')}
                </p>
            );
        }

        if (hasCancellationFee && paymentSystem === PAYMENT_SYSTEM_TYPE.PAY_PRO) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {t('bookingModal.payProWarning')}
                </p>
            );
        }

        if (isInsideCancellationPolicy) {
            return (
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {hasCancellationFee ? (
                        t(
                            'bookingModal.cancelConfirmation.insideCancellationPolicy',
                            { price: cancellationFee ?? 0 },
                        )
                    ) : t('bookingModal.cancelConfirmation.freeBooking')}
                </p>
            );
        }

        if (!isNeedWithCancellationFeeParam.value) {
            isNeedWithCancellationFeeParam.onTrue();
        }
        return (
            <React.Fragment>
                <p className={classNames('mb-2', styles.confirmationModalText)}>
                    {t(
                        'bookingModal.cancelConfirmation.outsideCancellationPolicy.title',
                        { price: cancellationFee ?? 0 },
                    )}
                </p>

                <label className="mb-2 d-flex align-items-center cursor-pointer">
                    <input
                        type="radio"
                        checked={isWithCancellationFee.value}
                        onClick={isWithCancellationFee.onTrue}
                        className={styles.confirmationModalRadio}
                    />
                    {hasCancellationFee ? (
                        t(
                            'bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withCancelationFee',
                            { price: cancellationFee ?? 0 },
                        )
                    ) : t('bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withRefund')}
                </label>

                <label className="d-flex align-items-center cursor-pointer">
                    <input
                        type="radio"
                        checked={!isWithCancellationFee.value}
                        onClick={isWithCancellationFee.onFalse}
                        className={styles.confirmationModalRadio}
                    />
                    {hasCancellationFee
                        ? t('bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withoutCancelationFee')
                        : t('bookingModal.cancelConfirmation.outsideCancellationPolicy.options.withoutRefund')}
                </label>
            </React.Fragment>
        );
    })();

    const isBookingStatusPaid = isBookingPaid || (booking?.payment?.finalCost === 0 && booking?.payment?.type !== 'subscription');
    const paymentType = `paymentTypes.${booking?.payment.type.toLowerCase()}`;

    return (
        <React.Fragment>
            <Modal
                centered
                scrollable
                size="lg"
                show={
                    show
                    && !isDeleteModalVisible
                    && !isPresentModal
                    && !confirmationChangeStatusPaid.visible
                }
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0 font-weight-600" data-testid="data-test-booking-modal-title">
                        {t('bookingModal.title')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="border-bottom py-3">
                        <Row className="position-relative">
                            {booking && booking.recurrence.enabled && (
                                <span
                                    className={classNames(
                                        styles.recurrenceIcon,
                                        'position-absolute text-muted',
                                    )}
                                >
                                    <Time width={24} height={24} />
                                </span>
                            )}
                            <Col xs={12} sm={4}>
                                <span className="text-muted">{t('bookingModal.bookingTime')}</span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-time">
                                    {booking && moment(booking.time.from).format('HH:mm DD/MM/YYYY')}
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <div className="my-n1 border-bottom py-3">
                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">{t('bookingModal.clientName')}</span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <TextButton
                                    color="black"
                                    disabled={!booking?.client?.id}
                                    href={CLIENTS_LIST_ID_PAGE({ clientId: booking.client.id })}
                                    data-testid="data-test-booking-client-name"
                                >
                                    {booking && getFullName({ client: booking.client })}
                                </TextButton>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">{t('bookingModal.contactNumber')}</span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-contact-number">
                                    {booking && booking.client.contactNumber}
                                </span>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">{t('bookingModal.email')}</span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-email">
                                    {booking && booking.client.email}
                                </span>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">{t('bookingModal.note')}</span>
                            </Col>

                            <Col xs={12} sm={8} className={styles.wordBreak}>
                                <span data-testid="data-test-booking-note">
                                    {booking.client.note ? booking.client.note : '-'}
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <div className="my-n1 py-3 border-bottom">
                        {booking?.product?.name && (
                            <Row className="my-1">
                                <Col xs={12} sm={4}>
                                    <span className="text-muted">
                                        {t('bookingModal.product')}
                                    </span>
                                </Col>

                                <Col xs={12} sm={8}>
                                    <span data-testid="data-test-booking-product-name">
                                        {booking.product.name}
                                    </span>
                                </Col>
                            </Row>
                        )}

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">
                                    {t('bookingModal.package')}
                                </span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-package-name">
                                    {booking && booking.service.name}
                                </span>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">
                                    {t('bookingModal.paymentType')}
                                </span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-payment-type">
                                    {i18n.exists(paymentType) ? t(paymentType) : (booking?.payment.type || '-')}
                                </span>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">
                                    {t('bookingModal.paidUpfront')}
                                </span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-paid-upfront">
                                    {formatPrice.toEuroWithComma({ amount: booking?.payment.paidUpfront })}
                                </span>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">
                                    {t('bookingModal.finalCost')}
                                </span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-final-cost">
                                    {formatPrice.toEuroWithComma({ amount: booking?.payment.finalCost })}
                                </span>
                            </Col>
                        </Row>

                        <Row className="my-1">
                            <Col xs={12} sm={4}>
                                <span className="text-muted">
                                    {t('bookingModal.remainingCost')}
                                </span>
                            </Col>

                            <Col xs={12} sm={8}>
                                <span data-testid="data-test-booking-remaining-cost">
                                    {formatPrice.toEuroWithComma({ amount: booking?.payment.remainingCost })}
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <div className="my-n1 pt-3">
                        <Row className="my-1">
                            <Col
                                xs={12}
                                sm={4}
                                className="d-flex align-items-center"
                            >
                                <Content loading={isChangingPayBooking}>
                                    <TextButton
                                        color={isBookingStatusPaid ? 'green' : 'red'}
                                        disabled={!isCanChange}
                                        onClick={confirmationChangeStatusPaid.onShow}
                                        noPadding
                                        data-testid="data-test-booking-change-pay-status"
                                    >
                                        {t(`addBookingModal.${isBookingStatusPaid ? 'paid' : 'unpaid'}`)}
                                    </TextButton>
                                </Content>

                            </Col>
                        </Row>
                    </div>
                </Modal.Body>

                <Modal.Footer className="d-flex">
                    <div className="flex-grow-1 d-none d-lg-block">
                        <Button
                            color="outline"
                            onClick={onHide}
                            data-testid="data-test-booking-modal-cancel-button"
                        >
                            {t('bookingModal.cancel')}
                        </Button>
                    </div>
                    <div className="d-flex flex-wrap flex-grow-1 flex-lg-grow-0 mx-n2">
                        {Boolean(booking?.alreadyStarted) && (
                            <Button
                                color="black"
                                className="mb-3 mb-lg-0 mx-2 flex-basis-100 flex-lg-basis-auto"
                                onClick={() => setPresentModal(true)}
                                disabled={booking?.notPresent || banSetPresent}
                                data-testid="data-test-booking-modal-not-present-button"
                            >
                                {t('bookingModal.notPresentInShop')}
                            </Button>
                        )}

                        <div className="d-flex px-2 flex-basis-50 flex-lg-basis-auto">
                            <Button
                                stretched
                                onClick={/* istanbul ignore next */ () => onEditChange(true)}
                                disabled={!isEditable}
                                data-testid="data-test-booking-modal-edit-button"
                            >
                                {t('bookingModal.editBooking')}
                            </Button>
                        </div>

                        <div className="d-flex px-2 flex-basis-50 flex-lg-basis-auto">
                            <IconButton
                                color="red"
                                size={40}
                                onClick={() => setDeleteModalVisible(true)}
                                data-testid="data-test-booking-modal-delete-button"
                            >
                                <Trash3Icon />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <ConfirmationModal
                isShow={isDeleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                titleText={t('bookingModal.deleteBooking')}
                bodyText={(
                    <React.Fragment>
                        {confirmCancelText}

                        {booking?.hasPast && (
                            <p className="mb-0 text-muted">
                                *
                                {t('bookingModal.hasLastNote')}
                            </p>
                        )}
                    </React.Fragment>
                )}
                customFooter={(
                    <Modal.Footer>
                        <div className="flex-grow-1">
                            <Button
                                color="outline"
                                onClick={() => setDeleteModalVisible(false)}
                                data-testid="data-test-booking-modal-delete-cancel-button"
                            >
                                {t('bookingModal.noLeave')}
                            </Button>
                        </div>

                        <div className="mx-n2 d-flex flex-row-reverse">
                            {booking?.recurrence.enabled && (
                                <Button
                                    color="red"
                                    className="mx-2"
                                    onClick={() => onDeleteHandler()}
                                    disabled={generalBanDelete()}
                                    loading={isDeletingRecurringBooking}
                                    data-testid="data-test-booking-modal-delete-recurring-button"
                                >
                                    {t('bookingModal.deleteRecurringBooking')}
                                </Button>
                            )}

                            <Button
                                color="black"
                                className="mx-2"
                                onClick={() => onDeleteHandler(booking.time.from / 1000)}
                                disabled={generalBanDelete(booking.time.from / 1000)}
                                loading={isDeletingBooking}
                                data-testid="data-test-booking-modal-delete-button"
                            >
                                {t(booking?.recurrence.enabled
                                    ? 'bookingModal.deleteBookingCurrent'
                                    : 'bookingModal.yesCancel')}
                            </Button>
                        </div>
                    </Modal.Footer>
                )}
            />

            <ConfirmationModal
                isShow={isPresentModal}
                hide={() => setPresentModal(false)}
                loading={isOmittingBooking}
                confirmAction={onOmitHandler}
                deleteColor="black"
                titleText={t('bookingModal.notPresentInShop')}
                bodyText={t('bookingModal.areYouSureYouWantToOmitBooking')}
                deleteText={t('bookingModal.omit')}
            />

            <ConfirmationModal
                size="xs"
                isShow={confirmationChangeStatusPaid.visible}
                bodyText={t('bookingModal.confirmationChangeStatusPaid.bodyText')}
                hide={confirmationChangeStatusPaid.onClose}
                deleteText={t('bookingModal.confirmationChangeStatusPaid.yes')}
                dismissText={t('bookingModal.confirmationChangeStatusPaid.no')}
                confirmAction={onChangePayHandler}
            />
        </React.Fragment>
    );
};

export default BookingModal;
