import React, {
    useState, useEffect, useMemo, useRef,
} from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import {
    Redirect, useHistory, useParams,
} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

// Actions
import * as actions from 'store/actions';

// Components
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Formik } from 'formik';
import FileUpload from 'components/FileUpload/FileUpload';
import Switch from 'components/Switch/Switch';
import Schedule from 'components/Schedule/Schedule';
import WorkBreaks from 'components/WorkBreaks/WorkBreaks';
import { ConfirmationModal, Footer } from 'components';
import Skeleton from 'react-loading-skeleton';

// Styles

// Utils
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { styles, theme, dangerTheme } from 'styles/select';

import * as SERVICES_ACTIONS from 'store/actions/services';

import * as SERVICES_SELECTORS from 'store/selectors/services';
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import Container from 'components/Layout/Container/Container';
import { useMobile } from 'hooks/useMobile';
import { BackButton } from 'components/Common/BackButton';
import { CheckBox } from 'components/Common/CheckBox';
import { Label } from 'components/Common/Typography/Label';
import NumberInput from 'components/Common/NumberInput';

const MAXIMUM_DAYS = 1000;

const FieldLoader = ({ height = 38 }) => (
    <div className="position-relative" style={{ top: -3, marginBottom: -4 }}>
        <Skeleton
            width="100%"
            height={height}
        />
    </div>
);

const Add = () => {
    const { t } = useTranslation();
    const isMobile = useMobile();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        image: yup.mixed().required(t('addObjectRoute.pleaseSelectProductImage')),
        name: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addObjectRoute.objectName') })),
        description: yup.string().required(t('validationErrors.cannotBeEmpty', { name: t('addObjectRoute.description') })),
        services: yup.array().of(yup.object({
            id: yup.number().required(),
            name: yup.string().required(),
        })).required(t('validationErrors.pleasePickOneOrMore', { name: t('addObjectRoute.services').toLowerCase() })),
        isMaintenanceMode: yup.boolean().required(),
        hasOwnSchedule: yup.boolean().required(),
        schedule: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.object({
                    enabled: yup.boolean(),
                    from: yup.mixed().when(`schedule.${moment().isoWeekday(i).format('dddd')}.to`, (to, from) => (to ? from.required() : from.nullable())),
                    to: yup.mixed().when(`schedule.${moment().isoWeekday(i).format('dddd')}.from`, (from, to) => (from ? to.required() : to.nullable())),
                }).nullable(),
            }), {})),
        hasWorkBreaks: yup.boolean().required(),
        workBreaks: yup
            .object(_.times(7).reduce((schedule, i) => ({
                ...schedule,
                [moment().isoWeekday(i).format('dddd')]: yup.array(yup.object({
                    from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('workBreaks.breakStart') })),
                    to: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('workBreaks.breakEnd') })),
                })),
            }), {})),
        futureBooking: yup.number()
            .positive(t('validationErrors.mustBePositiveNumber', { name: t('addGroupRoute.days') }))
            .max(MAXIMUM_DAYS, t('validationErrors.mustBeNoLongerThen', { field: 'Future bookings', days: MAXIMUM_DAYS })),
    })).current;

    const { id } = useParams();

    const [loading, setLoading] = useState(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeletingObject, setIsDeletingObject] = useState(false);

    const { loaded: isShopLoaded, id: shopID } = useSelector((state) => state.shop);
    const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const avaliableServices = useMemo(() => companyServices.filter(({ archived }) => !archived), [companyServices]);
    const workingHours = useSelector((state) => state.shop?.workingDays);
    const { banAdd, banEdit, banDelete } = useSelector(permissionsResourceSelector);

    const dispatch = useDispatch();

    const object = useSelector((state) => state.objects.objects.find((obj) => obj.id === Number(id)));

    const initialValues = useMemo(() => ({
        image: object?.image ?? null,
        name: object?.name ?? '',
        description: object?.description ?? '',
        services: object?.parentServices ?? [],
        isMaintenanceMode: object?.maintenanceMode ?? false,
        isTimeslotRecalc: object?.timeslotRecalc ?? false,
        schedule: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((schedule, day) => {
            const workBreak = object?.workingDays?.[day.toLowerCase()];

            return {
                ...schedule,
                [day]: workBreak?.from && workBreak?.to ? {
                    enabled: true,
                    from: moment(workBreak.from, 'HH:mm'),
                    to: moment(workBreak.to, 'HH:mm'),
                } : {
                    enabled: false,
                    from: workingHours && moment(workingHours[day.toLowerCase()].from, 'HH:mm'),
                    to: workingHours && moment(workingHours[day.toLowerCase()].to, 'HH:mm'),
                },
            };
        }, {}),
        hasOwnSchedule: object?.hasOwnSchedule ?? false,
        workBreaks: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((breaks, day) => ({
            ...breaks,
            [day]: object?.workBreaks
                .filter(({ weekday }) => weekday.toLowerCase() === day.toLowerCase())
                .map(({ from, to }) => ({
                    from: moment(from, 'HH:mm'),
                    to: moment(to, 'HH:mm'),
                })) ?? [],
        }), {}),
        hasWorkBreaks: !!object?.workBreaks?.length,
        futureBooking: object?.futureBooking
            ? moment(object?.futureBooking).startOf('day')
                .diff(moment().startOf('day'), 'days')
            : '',
    }), [workingHours, object]);

    const { push } = useHistory();

    useEffect(() => {
        if (!shopID || !isShopLoaded) {
            return;
        }

        if (id) {
            setLoading(true);
            dispatch(actions.getProductById({ productId: id, type: 'basic' }))
                .catch(() => push('/agenda/objects'))
                .finally(() => setLoading(false));
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded]);

    const { addToast } = useToasts();

    const onDeleteHandler = async () => {
        if (!id || banDelete || isDeletingObject) {
            return;
        }

        setIsDeletingObject(true);
        try {
            await dispatch(actions.deleteObject(id));
            addToast(t('addObjectRoute.objectDeletedSuccessfully'), {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
            push('/agenda/objects');
        } catch (error) {
            if (!error?.message) return;
            addToast(error.message, {
                appearance: 'error',
            });
        } finally {
            setIsDeletingObject(false);
        }
    };

    if (banAdd || banEdit) {
        return <Redirect to="/agenda/objects" />;
    }

    return (
        <React.Fragment>
            <TextSubHeader
                text={!id ? t('addObjectRoute.addNewObject') : t('addObjectRoute.editObject')}
                before={(
                    <BackButton
                        href="/agenda/objects"
                    />
                )}
                rightActions={
                    id && !banDelete && !isMobile && (
                        <Button
                            onClick={() => {
                                if (banDelete) return;
                                setDeleteModalVisible(true);
                            }}
                            color="red"
                        >
                            {t('agendaRoute.deleteResource')}
                        </Button>
                    )
                }
            />
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    const schedule = Object.entries(values.schedule).map(([day, { enabled, from, to }]) => [
                        day,
                        {
                            from: enabled ? from : null,
                            to: enabled ? to : null,
                        },
                    ]).reduce((schedule, [day, value]) => ({
                        ...schedule,
                        [day]: value,
                    }), {});

                    const toast = !id
                        ? t('addObjectRoute.objectAddedSuccessfully')
                        : t('addObjectRoute.objectEditedSuccessfully');

                    const handler = !id ? actions.addObject({
                        object: {
                            ...values,
                            schedule,
                        },
                    }) : actions.editObject({
                        object: {
                            ...values,
                            schedule,
                            id: Number(id),
                        },
                    });

                    dispatch(handler)
                        .then(() => {
                            addToast(toast, {
                                appearance: 'success',
                            });
                            push('/agenda/objects');
                        })
                        .catch(({ message, errors }) => {
                            if (errors?.schedule || errors?.workBreaks) {
                                const error = errors?.schedule || errors?.workBreaks;
                                addToast(error, {
                                    appearance: 'error',
                                });
                            }
                            if (message) {
                                addToast((message), {
                                    appearance: 'error',
                                });
                            } else {
                                setErrors(errors || {});
                            }
                            setSubmitting(false);
                        });
                }}
                enableReinitialize
            >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    isSubmitting,
                    touched,
                    errors,
                }) => (
                    <Container>
                        <Form noValidate onSubmit={handleSubmit} className="w-100">
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={3}>
                                    <FileUpload
                                        name="image"
                                        label={(touched.image && errors.image) || t('addObjectRoute.uploadProductImage')}
                                        isInvalid={touched.image && !!errors.image}
                                        defaultValue={values.image}
                                        onChange={(file) => setFieldValue('image', file)}
                                        square
                                        loading={loading}
                                    />
                                </Col>

                                <Col xs={12} md={6} lg={6} xl={9} className="mt-4 mt-lg-0">
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addObjectRoute.objectName')}
                                        </Form.Label>

                                        {!loading && (
                                            <Form.Control
                                                name="name"
                                                type="text"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={touched.name && !!errors.name}
                                            />
                                        )}

                                        {loading && <FieldLoader />}

                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {t('addObjectRoute.description')}
                                        </Form.Label>

                                        {!loading && (
                                            <Form.Control
                                                name="description"
                                                as="textarea"
                                                rows={3}
                                                value={values.description}
                                                onChange={handleChange}
                                                isInvalid={touched.description && !!errors.description}
                                            />
                                        )}

                                        {loading && <FieldLoader />}

                                        <Form.Control.Feedback
                                            type="invalid"
                                        >
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group>
                                        <div className="d-flex align-items-center mb-2">
                                            <Form.Label className="d-flex mb-0 flex-grow-1">
                                                {t('addObjectRoute.services.label')}
                                                <InfoTooltip
                                                    text={t('addObjectRoute.services.tooltip')}
                                                />
                                            </Form.Label>

                                            {!loading && (
                                                <React.Fragment>
                                                    <button
                                                        name="selectAll"
                                                        className="bg-transparent border-0 p-0 font-size-14 text-dark-gray mr-2"
                                                        type="button"
                                                        onClick={() => setFieldValue('services', avaliableServices.map(({ id, name }) => ({
                                                            id,
                                                            name,
                                                        })))}
                                                    >
                                                        {t('addObjectRoute.selectAll')}
                                                    </button>

                                                    <button
                                                        name="removeAll"
                                                        className="bg-transparent border-0 p-0 font-size-14 text-dark-gray"
                                                        type="button"
                                                        onClick={() => setFieldValue('services', [])}
                                                    >
                                                        {t('addObjectRoute.removeAll')}
                                                    </button>
                                                </React.Fragment>
                                            )}
                                        </div>

                                        {!loading && (
                                            <Select
                                                name="services"
                                                styles={styles}
                                                theme={touched.services && !!errors.services ? dangerTheme : theme}
                                                options={avaliableServices.map(({ id, name }) => ({
                                                    value: id,
                                                    label: name,
                                                }))}
                                                value={values.services.map(({ id, name }) => ({
                                                    value: id,
                                                    label: name,
                                                }))}
                                                onChange={(selected) => setFieldValue('services', selected ? selected.map(({ value, label }) => ({
                                                    id: value,
                                                    name: label,
                                                })) : [])}
                                                isMulti
                                                isClearable={false}
                                                placeholder={t('placeholders.select')}
                                            />
                                        )}

                                        {loading && <FieldLoader />}

                                        <Form.Control.Feedback
                                            className={touched.services && errors.services && 'd-block'}
                                            type="invalid"
                                        >
                                            {errors.services}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group>
                                        {!loading && (
                                            <CheckBox
                                                id="isMaintenanceMode"
                                                name="isMaintenanceMode"
                                                label={t('addObjectRoute.maintenanceMode')}
                                                checked={values.isMaintenanceMode}
                                                onChange={handleChange}
                                            />
                                        )}

                                        {loading && <FieldLoader />}
                                    </Form.Group>

                                    <Form.Group>
                                        {!loading && (
                                            <CheckBox
                                                id="isTimeslotRecalc"
                                                name="isTimeslotRecalc"
                                                label={t('addObjectRoute.timeslotRecalc')}
                                                checked={values.isTimeslotRecalc}
                                                onChange={handleChange}
                                            />
                                        )}

                                        {loading && <FieldLoader />}
                                    </Form.Group>

                                    <Form.Group>
                                        <Label paddingBottom tooltip={t('addGroupRoute.howLongBookingsCanBeMade.tooltip')}>
                                            {t('addGroupRoute.howLongBookingsCanBeMade.label')}
                                        </Label>

                                        <NumberInput
                                            name="futureBooking"
                                            value={values.futureBooking}
                                            onChange={(value) => setFieldValue('futureBooking', value)}
                                            isInvalid={!!touched.futureBooking && !!errors.futureBooking}
                                            prepend={t('addGroupRoute.days')}
                                            min={0}
                                            max={MAXIMUM_DAYS}
                                            step={1}
                                        />
                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    {!loading && (
                                        <Form.Group className="d-flex align-items-center">
                                            <Switch
                                                name="hasOwnSchedule"
                                                checked={values.hasOwnSchedule}
                                                onChange={(hasOwnSchedule) => setFieldValue('hasOwnSchedule', hasOwnSchedule)}
                                            />
                                            <Form.Label className="ml-3 mb-0 font-weight-normal">
                                                {t('addObjectRoute.hasOwnSchedule')}
                                            </Form.Label>
                                        </Form.Group>

                                    )}

                                    {loading && <FieldLoader />}

                                    {(!loading && values.hasOwnSchedule) && (
                                        <div className="mb-2">
                                            <Schedule
                                                name="schedule"
                                                value={values.schedule}
                                                onChange={({
                                                    type, day, enabled, from, to,
                                                }) => {
                                                    if (type === 'UPDATE') {
                                                        setFieldValue('schedule', {
                                                            ...values.schedule,
                                                            [day]: {
                                                                enabled: enabled !== null && enabled !== undefined ? enabled : values.schedule[day].enabled,
                                                                from: from ?? values.schedule[day].from,
                                                                to: to ?? values.schedule[day].to,
                                                            },
                                                        });
                                                    } else {
                                                        const mondayDay = values.schedule.Monday;
                                                        if (!mondayDay?.enabled) return;

                                                        setFieldValue('schedule', _.times(7)
                                                            .map((i) => moment().isoWeekday(i).format('dddd'))
                                                            .reduce((schedule, day) => ({
                                                                ...schedule,
                                                                [day]: { ...mondayDay },
                                                            }), {}));
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col>
                                    {!loading && (
                                        <Form.Group className="d-flex align-items-center">
                                            <Switch
                                                name="hasWorkBreaks"
                                                checked={values.hasWorkBreaks}
                                                onChange={(hasWorkBreaks) => setFieldValue('hasWorkBreaks', hasWorkBreaks)}
                                            />
                                            <Form.Label className="ml-3 mb-0 font-weight-normal">
                                                {t('addObjectRoute.workBreaks')}
                                            </Form.Label>
                                        </Form.Group>
                                    )}

                                    {loading && <FieldLoader />}

                                    {(!loading && values.hasWorkBreaks) && (
                                        <WorkBreaks
                                            name="workBreaks"
                                            className="mt-4"
                                            value={values.workBreaks}
                                            onChange={({
                                                type, day, workBreak, index, value,
                                            }) => {
                                                switch (type) {
                                                case 'ADD':
                                                    setFieldValue('workBreaks', {
                                                        ...values.workBreaks,
                                                        [day]: [
                                                            ...values.workBreaks[day],
                                                            workBreak,
                                                        ],
                                                    });
                                                    break;
                                                case 'REMOVE':
                                                    setFieldValue('workBreaks', {
                                                        ...values.workBreaks,
                                                        [day]: values.workBreaks[day].filter((_, i) => i !== index),
                                                    });
                                                    break;
                                                case 'UPDATE':
                                                    setFieldValue('workBreaks', {
                                                        ...values.workBreaks,
                                                        [day]: values.workBreaks[day].map((el, i) => (i !== index ? el : workBreak)),
                                                    });
                                                    break;
                                                default:
                                                    setFieldValue('workBreaks', value);
                                                    break;
                                                }
                                            }}
                                            errors={errors.workBreaks}
                                            touched={touched.workBreaks}
                                            workingHours={workingHours}
                                        />
                                    )}
                                </Col>
                            </Row>

                            <div
                                className="d-flex align-items-center border-top pt-3 mt-3"
                            >
                                <Button
                                    color="outline"
                                    href="/agenda/objects"
                                >
                                    {t('addObjectRoute.cancel')}
                                </Button>

                                <div className="d-flex justify-content-end flex-grow-1">
                                    <Button type="submit" loading={isSubmitting}>
                                        {t(`addObjectRoute.${!id ? 'add' : 'edit'}Object`)}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Container>
                )}
            </Formik>

            <Footer className="d-flex d-lg-none justify-content-between">
                {Boolean(id && !banDelete) && (
                    <Button
                        onClick={() => {
                            if (banDelete) return;
                            setDeleteModalVisible(true);
                        }}
                        color="red"
                        size="extra-large"
                        className="rounded-0 px-3 px-sm-5"
                    >
                        {t('agendaRoute.deleteResource')}
                    </Button>
                )}
            </Footer>

            <ConfirmationModal
                isShow={deleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                loading={isDeletingObject}
                confirmAction={onDeleteHandler}
                titleText={t('addObjectRoute.deleteObject')}
                bodyText={t('addObjectRoute.areYouSureYouWantToDeleteObject')}
                deleteText={t('addObjectRoute.delete')}
            />
        </React.Fragment>
    );
};

export default Add;
