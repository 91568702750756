// extracted by mini-css-extract-plugin
var _1 = "Column_center__H4Lj6";
var _2 = "Column_column__ThHo5";
var _3 = "Column_end__bPGJn";
var _4 = "Column_gap0__7K-pn";
var _5 = "Column_gap10__e8BWw";
var _6 = "Column_gap12__6KHB1";
var _7 = "Column_gap16__TiZCJ";
var _8 = "Column_gap32__vLLfv";
var _9 = "Column_gap4__IJ2aa";
var _a = "Column_gap8__x7yzE";
var _b = "Column_justifyAround__760XY";
var _c = "Column_justifyBetween__Jl0qW";
var _d = "Column_justifyCenter__RJ+Xr";
var _e = "Column_justifyEnd__C+-ko";
var _f = "Column_justifyStart__O7x-O";
var _10 = "Column_padding0__TMAuD";
var _11 = "Column_padding12__TffsL";
var _12 = "Column_padding16__9B04H";
var _13 = "Column_padding32__8AYe8";
var _14 = "Column_padding4__wUFAw";
var _15 = "Column_padding8__sS4-c";
var _16 = "Column_shrink__gzloY";
var _17 = "Column_spacing0__GX-ik";
var _18 = "Column_spacing12__ah-GY";
var _19 = "Column_spacing16__gXEuH";
var _1a = "Column_spacing32__348f2";
var _1b = "Column_spacing4__p66HV";
var _1c = "Column_spacing8__7MP4c";
var _1d = "Column_start__ftNs8";
var _1e = "Column_stretched__O29cx";
export { _1 as "center", _2 as "column", _3 as "end", _4 as "gap0", _5 as "gap10", _6 as "gap12", _7 as "gap16", _8 as "gap32", _9 as "gap4", _a as "gap8", _b as "justifyAround", _c as "justifyBetween", _d as "justifyCenter", _e as "justifyEnd", _f as "justifyStart", _10 as "padding0", _11 as "padding12", _12 as "padding16", _13 as "padding32", _14 as "padding4", _15 as "padding8", _16 as "shrink", _17 as "spacing0", _18 as "spacing12", _19 as "spacing16", _1a as "spacing32", _1b as "spacing4", _1c as "spacing8", _1d as "start", _1e as "stretched" }
