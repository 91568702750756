import React from 'react';

// Styles
import classNames from 'classnames';
import * as styles from './Footer.module.scss';

// Utils

const Footer = ({ children, className }) => (
    <React.Fragment>
        <div className={styles.spacer} />
        <div className={classNames(styles.container, className, 'bg-white')}>
            {children}
        </div>
    </React.Fragment>
);

export default Footer;
