import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CheckBox } from 'components/Common/CheckBox';
import * as styles from './ListTableBodyRow.module.scss';
import { ListTableBodyCell } from '../ListTableBodyCell';

const ListTableBodyRow = (props) => {
    const {
        children,
        className,
        onClick,
        onSelect,
        selected,
    } = props;

    return (
        <tr className={classNames(styles.bodyRow, Boolean(onClick) && styles.interactive, className)} onClick={onClick || undefined} data-testid={props['data-testid']}>
            {onSelect && (
                <ListTableBodyCell>
                    <CheckBox
                        checked={selected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={onSelect}
                        data-testid={props['select-data-testid']}
                    />
                </ListTableBodyCell>
            )}
            {children}
        </tr>
    );
};

ListTableBodyRow.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    'data-testid': PropTypes.string,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    'select-data-testid': PropTypes.string,
};

ListTableBodyRow.defaultProps = {
    children: null,
    className: '',
    'data-testid': 'data-test-list-table-body-row',
    onSelect: null,
    selected: false,
    'select-data-testid': 'data-test-list-table-body-row-select',
};

export default ListTableBodyRow;
