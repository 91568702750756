import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AllowRouteWrapper, SubNavigation } from 'components';
import { THIRD_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { Row } from 'components/Common/Row';
import { useSelector } from 'react-redux';
import { useMultisafe } from 'hooks/useMultisafe';
import { permissionsPresetSelector } from 'store/selectors/permissions';
import NavigationService from 'services/navigationService';
import * as styles from './page.module.scss';
import routes from './scenes';

function Accounting() {
    const subNavigation = NavigationService.getAccountingSubNavigation();
    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);

    const isMultisafe = useMultisafe();

    const filteredRoutes = useMemo(() => routes.filter((subRoute) => (subRoute.multisafe === undefined ? true : (isMultisafe && subRoute.multisafe) || (!isMultisafe && !subRoute.multisafe))), [isMultisafe]);
    const filteredSubNavigation = useMemo(() => subNavigation.filter((subRoute) => (subRoute.multisafe === undefined ? true : (isMultisafe && subRoute.multisafe) || (!isMultisafe && !subRoute.multisafe))), [isMultisafe]);

    return (
        <React.Fragment>
            <div className={styles.withBorderBottom}>
                <Row gap={32} spacing={32} style={{ height: THIRD_LEVEL_NAV_HEIGHT }}>
                    <SubNavigation
                        isDataLoaded
                        subRoutes={filteredSubNavigation}
                        withQuery={false}
                    />
                </Row>
            </div>
            <Switch>
                {
                    filteredRoutes.map(({ component, exact, path }) => (
                        <AllowRouteWrapper
                            key={path}
                            path={path}
                            sections={sections}
                            permissionSections={preset?.section}
                        >
                            <Route
                                key={path}
                                path={path}
                                exact={exact}
                                component={component}
                            />
                        </AllowRouteWrapper>
                    ))
                }
                <Redirect to={filteredRoutes[0].path} />
            </Switch>
        </React.Fragment>
    );
}

export default Accounting;
