import {
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';

import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import axios from '../../../services/axios';
import SubscriptionDataTransformations from '../../../services/subscriptionDataTransformations';

import * as actions from '../../actions';
import * as actionTypes from '../../actions/actionTypes';

import * as SUBSCRIPTIONS_SELECTORS from '../../selectors/subscriptions';

import {
    showToast,
    setIsDataLoaded,
    setSubscriptionsList,
    setItemsCount,
    setPagesCount,
    updateSubscriptionStatus,
    updateSubscriptionPaymentInfo,
    setSellSubscriptionData,
    resetSellSubscription,
    setSubscriptionsOptions,
    getSubscriptionsList as getSubscriptionsListAction,
} from '../../actions';
import * as TOAST_ACTIONS from '../../actions/toast';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_SUBSCRIPTION_LIST_ROUTE,
} from '../../../const/API_URL';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

export function* addSubscription(action) {
    const { payload, history, onFinal } = action;

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: '/api/admin/subscription/create',
            headers: defaultHeaders,
            data: payload,
        });

        const {
            data: { code },
        } = yield call(request);

        if (code === 200) {
            history.push('/services/subscriptions/list');
            yield put(showToast({
                message: 'subscriptionAddSuccess',
                appearance: 'success',
            }));
        }
    } catch (error) {
        yield put(showToast({
            message: 'subscriptionAddError',
            appearance: 'error',
        }));
        yield put(showToast({
            message: error?.response?.data?.result?.message ?? '',
            appearance: 'error',
            isCustom: true,
        }));
    }

    onFinal?.();
}

export function* getSubscriptionsList(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { currentPage, searchValue } = yield select(SUBSCRIPTIONS_SELECTORS.subscriptionListsQuerySelector);

    yield put(setIsDataLoaded(false));

    try {
        const request = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/subscription/list',
            headers: defaultHeaders,
            params: {
                page: currentPage,
                search: searchValue,
            },
        });

        const {
            data: {
                result: {
                    itemsCount,
                    pagesCount,
                    subscriptions,
                },
            },
        } = yield call(request);
        const parsedData = SubscriptionDataTransformations.transformResponseIntoFormData(subscriptions);

        yield put(setSubscriptionsList(parsedData));
        yield put(setPagesCount(pagesCount));
        yield put(setItemsCount(itemsCount));
    } catch (error) {
        yield put(showToast({
            message: 'subscriptionListError',
            appearance: 'error',
        }));
    } finally {
        yield put(setIsDataLoaded(true));
    }
}

export function* getSubscriptionsOptions(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield axios.request({
            _action: action,
            method: 'GET',
            url: API_ADMIN_SUBSCRIPTION_LIST_ROUTE,
            headers: defaultHeaders,
        });

        const { subscriptions } = res?.data?.result ?? {};
        if (subscriptions?.length) {
            yield put(setSubscriptionsOptions(
                subscriptions.map((subscription) => ({
                    id: subscription.id,
                    title: subscription.name,
                })),
            ));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(showToast({
                message: 'subscriptionListError',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* loadSubscriptionsListOptions(action) {
    const { search } = action.payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_SUBSCRIPTION_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page: 0,
                search,
            },
        });

        const { data } = res;
        const { code, result } = data;

        if (code === HTTP_STATUS_CODE.OK) {
            const parsedData = SubscriptionDataTransformations.transformResponseIntoFormData(result.subscriptions);
            yield call(resolvePromiseAction, action, parsedData);
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(showToast({
                message: 'subscriptionListError',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* deleteSubscription(action) {
    const { subscriptionId } = action.payload;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: 'DELETE',
            url: `/api/admin/subscription/delete/${subscriptionId}`,
            headers: defaultHeaders,
        });
        const { data } = res;
        const { code } = data;
        if (code === 200) {
            yield put(TOAST_ACTIONS.showToast({
                message: 'subscriptionsArchiveSuccess',
                appearance: 'success',
            }));
            yield put(getSubscriptionsListAction());
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'subscriptionsArchiveError',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === 401) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export function* toggleSubscriptionStatus(action) {
    const { subscriptionId } = action;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'PATCH',
            url: `/api/admin/subscription/toggle-status/${subscriptionId}`,
            headers: defaultHeaders,
        });

        const {
            data: {
                code,
                result: { newStatus },
            },
        } = yield call(request);
        if (code === 200) {
            yield put(updateSubscriptionStatus(
                subscriptionId,
                newStatus === 1,
            ));
        }
    } catch (err) {
        yield put(showToast({
            message: 'subscriptionsToggleError',
            appearance: 'error',
        }));
    }
}

export function* editSubscription(action) {
    const {
        payload, id, history, onFinal,
    } = action;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/subscription/edit/${id}`,
            headers: defaultHeaders,
            data: payload,
        });

        const {
            data: { code },
        } = yield call(request);

        if (code === 200) {
            history.push('/services/subscriptions/list');
            yield put(showToast({
                message: 'subscriptionEditSuccess',
                appearance: 'success',
            }));
        }
    } catch (error) {
        yield put(showToast({
            message: 'subscriptionEditError',
            appearance: 'error',
        }));
        yield put(showToast({
            message: error?.response?.data?.result?.message ?? '',
            appearance: 'error',
            isCustom: true,
        }));
    }

    onFinal?.();
}

export function* getSellSubscriptionData(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        /*
            The reason why we have a separate request instead of triggering getSubscriptionsList saga
            is we need to get all subscriptions ignoring the pages count and search filter
         */
        const subscriptionsRequest = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/subscription/list',
            headers: defaultHeaders,
            params: {
                page: 0,
            },
        });

        const clientsRequest = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/company/clients',
            headers: defaultHeaders,
        });

        const subscriptionsResponse = yield call(subscriptionsRequest);
        const clientsResponse = yield call(clientsRequest);
        const subscriptions = get(subscriptionsResponse, 'data.result.subscriptions', []);
        const clients = get(clientsResponse, 'data.result', []);
        /*
            In this case there is no need to parse subscriptions for them to be form like
        */
        yield put(setSellSubscriptionData(clients, subscriptions));
    } catch (err) {
        yield put(showToast({
            message: get(err, 'response.data.result.message', ''),
            appearance: 'error',
            isCustom: true,
        }));
        yield put(showToast({
            message: 'subscriptionSellModalReopen',
            appearance: 'warning',
        }));
        resetSellSubscription();
    }
}

export function* getAllSubscriptions(action) {
    const defaultHeaders = yield getDefaultHeaders();

    try {
        /*
            The reason why we have a separate request instead of triggering getSubscriptionsList saga
            is we need to get all subscriptions ignoring the pages count and search filter
         */
        const subscriptionsRequest = () => axios.request({
            _action: action,
            method: 'GET',
            url: '/api/admin/subscription/list',
            headers: defaultHeaders,
            params: {
                page: 0,
            },
        });

        const subscriptionsResponse = yield call(subscriptionsRequest);
        const subscriptions = get(subscriptionsResponse, 'data.result.subscriptions', []);

        yield call(resolvePromiseAction, action, subscriptions);
    } catch (err) {
        yield put(showToast({
            message: get(err, 'response.data.result.message', ''),
            appearance: 'error',
            isCustom: true,
        }));
    }
}

export function* toggleSubscriptionPaymentStatus(action) {
    const { subscriptionId, paymentId } = action;
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const request = () => axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/subscription/payment/toggle-status/${paymentId}`,
            headers: defaultHeaders,
        });

        const {
            data: {
                result: {
                    paymentStatus,
                    subscription_status: subscriptionStatus,
                    unpaid_counter: unpaidCounter,
                    is_manually_set: isManuallySet,
                },
            },
        } = yield call(request);

        yield put(updateSubscriptionPaymentInfo({
            subscriptionId,
            paymentId,
            paymentStatus,
            subscriptionStatus,
            unpaidCounter,
            isManuallySet,
        }));
    } catch (err) {
        yield put(showToast({
            message: get(err, 'response.data.result.message', ''),
            appearance: 'error',
            isCustom: true,
        }));
    }
}

export const subscriptionsListSaga = [
    takeEvery(actionTypes.ADD_SUBSCRIPTION, addSubscription),
    takeEvery(actionTypes.GET_SUBSCRIPTIONS_LIST, getSubscriptionsList),
    takeEvery(actionTypes.GET_SUBSCRIPTIONS_OPTIONS, getSubscriptionsOptions),
    takeEvery(actions.loadSubscriptionsListOptions, loadSubscriptionsListOptions),
    takeEvery(actions.deleteSubscription, deleteSubscription),
    takeEvery(actionTypes.TOGGLE_SUBSCRIPTION_STATUS, toggleSubscriptionStatus),
    takeEvery(actionTypes.EDIT_SUBSCRIPTION, editSubscription),
    takeEvery(actionTypes.GET_SELL_SUBSCRIPTION_DATA, getSellSubscriptionData),
    takeEvery(actionTypes.TOGGLE_SUBSCRIPTION_PAYMENT_STATUS, toggleSubscriptionPaymentStatus),
    takeLatest(actions.getAllSubscriptions, getAllSubscriptions),
];
