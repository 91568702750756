import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import _ from 'lodash';
import TextButton from 'components/Common/TextButton/TextButton';
import { Row } from 'components/Common/Row';
import { useMobile } from 'hooks/useMobile';
import { Label } from 'components/Common/Typography/Label';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Button } from 'components/Common/Button';
import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { useHistory } from 'react-router-dom';
import { permissionsGroupSessionSelector } from 'store/selectors/permissions';
import { Feedback, BookingPaymentType } from '..';
import {
    Plus,
    SentIcon,
    Time,
} from '../Icon/Icon';
import { getFullName } from '../../helpers/services/getFullName';
import { CLIENTS_LIST_ID_PAGE, MESSAGES_ADD_PAGE } from '../../const/CLIENT_URL';

const SpotsBooked = ({
    onBookingClick,
    bookings,
    onAddBooking,
    addBookingDisabled,
    total,
    available,
}) => {
    const isMobile = useMobile();
    const { t } = useTranslation();
    const { banSendNotification } = useSelector(permissionsGroupSessionSelector);

    const history = useHistory();

    return (
        <React.Fragment>
            <Row stretched justify="between" wrap={isMobile}>
                <Row gap={8}>
                    <Header>{t('groupDetailsModal.seats')}</Header>
                    <SubHeader color="gray" weight="bold" data-testid="data-test-total-seats">
                        {`${total - available}/${total}`}
                    </SubHeader>
                </Row>
                <Row gap={8} stretched={isMobile}>
                    <Button
                        stretched={isMobile}
                        color="outline"
                        size="small"
                        disabled={addBookingDisabled}
                        onClick={onAddBooking}
                        before={<Plus height={14} width={14} />}
                    >
                        {t('agendaRoute.addBooking')}
                    </Button>
                    <Button
                        stretched={isMobile}
                        disabled={banSendNotification || !bookings?.length}
                        id="sendMessage"
                        size="small"
                        onClick={() => history.push(MESSAGES_ADD_PAGE, { selectedClientsIds: uniq(bookings?.map(({ client }) => client.id)) })}
                        before={<SentIcon />}
                    >
                        {t('groupDetailsModal.sendMessage')}
                    </Button>
                </Row>
            </Row>
            <Column gap={0} stretched>
                {bookings?.map((
                    {
                        id,
                        client,
                        payment: {
                            paid,
                            isInProgress,
                            usedBundle,
                            finalCost,
                            type,
                        },
                        feedback,
                        seats,
                        recurrence,
                        notPresent,
                        time: {
                            from,
                        },
                    },
                ) => (
                    <div
                        key={`booking-${id}`}
                        className={classNames({
                            'border-danger': !usedBundle && !isInProgress && !paid && finalCost !== 0,
                            'border-success': usedBundle || (!isInProgress && paid) || finalCost === 0,
                            'border-info': !usedBundle && isInProgress,
                            'border-dark': notPresent,
                        }, 'd-flex flex-sm-row flex-column justify-content-between mt-3 pl-3 border-left border-5 w-100')}
                        data-testid={`data-test-row-${getFullName({ client })}`}
                    >
                        <Row gap={8} justify="between" stretched wrap={isMobile}>
                            <Row gap={8} align="center">
                                <TextButton
                                    color="black"
                                    noPadding
                                    disabled={!client.id}
                                    href={CLIENTS_LIST_ID_PAGE({ clientId: client.id })}
                                    data-testid="data-test-client"
                                >
                                    {getFullName({ client })}
                                </TextButton>
                                {(type || feedback) && (
                                    <Row gap={8}>
                                        {type && (
                                            <BookingPaymentType
                                                className="text-muted"
                                                width={24}
                                                type={type}
                                            />
                                        )}
                                        {feedback && (
                                            <Feedback
                                                feedback={feedback}
                                                size={24}
                                            />
                                        )}
                                    </Row>
                                )}
                                <TextButton
                                    onClick={() => onBookingClick({ id, timestamp: from })}
                                    data-testid="data-test-show-info"
                                    color="gray"
                                    strong
                                    noPadding
                                >
                                    {t('groupSchedule.showInfo')}
                                </TextButton>
                            </Row>
                            <Row gap={16} align="center">
                                {recurrence?.enabled && <Time width={14} height={14} />}
                                <Label color="gray" data-testid="data-test-seats">
                                    {t('groupDetailsModal.seat', { count: seats ?? 1 })}
                                </Label>
                                <Label data-testid="data-test-contact-number">
                                    {client?.contactNumber}
                                </Label>
                            </Row>
                        </Row>
                    </div>
                ))}
            </Column>
        </React.Fragment>
    );
};

export default SpotsBooked;
