import React from 'react';

// Hooks
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { tokensSelector } from 'store/selectors/auth';
import { userSelector } from 'store/selectors/user';

// Components
import { SkeletonTheme } from 'react-loading-skeleton';
import {
    Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { AllowRouteWrapper, AuthRoute, ToastManager } from 'components';

// History
import history from 'services/history';

// Utils
import moment from 'moment-timezone';
import { intercomService } from 'services/intercomService';
import { routesArray } from 'pages';
import { SIGN_IN } from 'const/CLIENT_URL';
import { Content } from 'components/Common/Content';
import { useMobile } from 'hooks/useMobile';
import { useWorkerController } from './hooks/useWorkerController';
import * as actions from './store/actions';
import { permissionsPresetSelector } from './store/selectors/permissions';
import sentry from './services/sentry';

moment.tz.setDefault('Etc/GMT0');
moment.locale('en');

const App = () => {
    const isMobile = useMobile();
    const isAppLoaded = useSelector((state) => state.app.loaded);
    const { accessToken, refreshToken } = useSelector(tokensSelector);
    const {
        email, fullName, loginDate, intercomHash,
    } = useSelector(userSelector);
    const { loaded: isCompanyLoaded, sections } = useSelector((state) => state.company);
    const preset = useSelector(permissionsPresetSelector);

    useWorkerController();

    const dispatch = useDispatch();

    // TODO all effects below can happen in something like initSaga
    useEffect(() => {
        if (!refreshToken || !!accessToken) {
            return;
        }

        dispatch(actions.refreshToken());
    }, [accessToken, refreshToken]);

    useEffect(() => {
        if (!email || isMobile) {
            return;
        }

        intercomService(fullName, intercomHash, email, loginDate);
        sentry.setUser({ email });
    }, [email]);

    useEffect(() => {
        if (!isAppLoaded || !accessToken || isCompanyLoaded) {
            return;
        }

        dispatch(actions.getCompanyDetails()).catch(sentry.error);
    }, [isAppLoaded, accessToken, isCompanyLoaded]);

    useEffect(() => {
        if (!isAppLoaded || !accessToken) {
            return;
        }

        dispatch(actions.refreshPermissions());
    }, [isAppLoaded, accessToken]);

    const oldTokens = refreshToken && !accessToken;
    // wait for email and default tab loading
    const userNotLoaded = accessToken && !email;
    const shouldShowLoader = !isAppLoaded || oldTokens || userNotLoaded;

    return (
        <SkeletonTheme color="#E5EBF0" highlightColor="#D8E1EA">
            <Content loading={shouldShowLoader}>
                <ToastManager />
                <Router history={history}>
                    <Switch>
                        {
                            routesArray
                                .map((route) => {
                                    const {
                                        authRequired, component, exact, path, roles, accessKey,
                                    } = route;

                                    const RouteComponent = authRequired ? AuthRoute : Route;

                                    return (
                                        <AllowRouteWrapper
                                            key={path}
                                            path={path}
                                            sections={sections}
                                            permissionSections={preset?.section}
                                        >
                                            <RouteComponent
                                                roles={roles}
                                                accessKey={accessKey}
                                                component={component}
                                                exact={exact}
                                                path={path}
                                            />
                                        </AllowRouteWrapper>
                                    );
                                })
                        }
                        <Redirect to={SIGN_IN} />
                    </Switch>
                </Router>
            </Content>
        </SkeletonTheme>
    );
};

export default App;
