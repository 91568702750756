import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { clientsListReducer } from './clientsList';
import { clientItemReducer } from './clientItem';
import { clientBookingsListReducer } from './clientTables/clientBookings';
import { clientBundlesListReducer } from './clientTables/clientBundles';
import { clientReviewsListReducer } from './clientTables/clientReviews';
import { clientNotesListReducer } from './clientTables/clientNotes';
import { soldSubscriptionsReducer } from '../subscriptions/soldSubscriptions';
import { clientAccessLogsListReducer } from './clientTables/clientAccessLogs';
import { clientActivityListReducer } from './clientTables/clientActivity';
import { clientInvoicesListReducer } from './clientTables/clientInvoices';
import { clientEmailsListReducer } from './clientTables/clientEmails';

export const clientsReducer = combineReducers({
    clients: clientsListReducer,
    clientItem: clientItemReducer,
    clientBookings: clientBookingsListReducer,
    clientBundles: clientBundlesListReducer,
    clientSubscriptions: soldSubscriptionsReducer,
    clientReviews: clientReviewsListReducer,
    clientAccessLogs: clientAccessLogsListReducer,
    clientActivity: clientActivityListReducer,
    clientInvoices: clientInvoicesListReducer,
    clientEmails: clientEmailsListReducer,
    clientNotes: persistReducer({
        key: 'clientNotes',
        storage,
        whitelist: ['persistCreateItem'],
    }, clientNotesListReducer),
});
