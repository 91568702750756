import React from 'react';
import PropTypes from 'prop-types';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';

import { unix } from 'moment/moment';
import { Badge } from 'components/Common/Badge';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { EMAIL_STATUS } from 'const/email/EMAIL_STATUS';
import { ENVS } from 'services/envs';

const getStatusColor = (status) => {
    switch (status) {
    case EMAIL_STATUS.DELIVERED:
        return 'blue';
    case EMAIL_STATUS.OPENED:
        return 'green';
    case EMAIL_STATUS.CLICKED:
        return 'green';
    case EMAIL_STATUS.BOUNCED:
        return 'red';
    case EMAIL_STATUS.COMPLAINED:
        return 'red';
    case EMAIL_STATUS.REJECTED:
        return 'red';
    case EMAIL_STATUS.DELIVERY_DELAYED:
        return 'yellow';
    case EMAIL_STATUS.RENDERING_FAILED:
        return 'red';
    case EMAIL_STATUS.UNSUBSCRIBED:
        return 'red';
    default:
        return 'gray';
    }
};

const ClientEmailsTableBodyRow = (props) => {
    const {
        email,
    } = props;

    const { t, i18n } = useTranslation(LOCALE_NAMESPACE.USER);
    const emailStatus = `clientEmailsTable.statuses.values.${email?.status?.toLowerCase()}`;

    return (
        <React.Fragment>
            <ListTableBodyCell data-testid="data-test-emails-table-body-created-at">
                {unix(email.createdAt).format('DD.MM.YYYY HH:mm')}
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-emails-table-body-updated-at">
                {unix(email.updatedAt).format('DD.MM.YYYY HH:mm')}
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-emails-table-body-status">
                <Badge color={getStatusColor(email.status)} size="small" data-testid="data-test-emails-table-body-status-badge">
                    {i18n.exists(emailStatus, { ns: LOCALE_NAMESPACE.USER }) ? t(emailStatus) : (email.status || '-')}
                </Badge>
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-emails-table-body-details">
                {email.reason ?? '-'}
            </ListTableBodyCell>
            <ListTableBodyCell data-testid="data-test-emails-table-body-subject">
                {email.subject}
            </ListTableBodyCell>
            {ENVS.isDevelopment && (
                <ListTableBodyCell data-testid="data-test-emails-table-body-message-id">
                    {email.messageId}
                </ListTableBodyCell>
            )}
        </React.Fragment>
    );
};

ClientEmailsTableBodyRow.propTypes = {
    email: PropTypes.object,
};

ClientEmailsTableBodyRow.defaultProps = {
    email: null,
};

export default ClientEmailsTableBodyRow;
