import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import classNames from 'classnames';

import { Button } from 'components/Common/Button';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientNotesFooter.module.scss';

const T_PREFIX = 'common.table.footer';

const ClientNotesFooter = (props) => {
    const {
        page,
        showAll,
        pagesCount,
        itemsPerPage,
        onShowAll,
        onPrevPage,
        onNextPage,
        onLastPage,
        onFirstPage,
        onShowPages,
        onChangePage,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const showPrev = page > 1;
    const showNext = page < pagesCount;

    const paginationPages = useMemo(() => {
        if (!page || !pagesCount) {
            return [];
        }
        let firstPage = 1;
        let lastPage = Math.min(pagesCount, 5);
        if (page > 2 && page < pagesCount - 2) {
            firstPage = page - 2;
            lastPage = Math.min(page + 2, pagesCount);
        }
        if (page > 2 && page >= pagesCount - 2) {
            firstPage = Math.max(pagesCount - 4, 1);
            lastPage = pagesCount;
        }
        return range(firstPage, lastPage + 1);
    }, [page, pagesCount]);

    return (
        <Row className="m-0 p-0 justify-content-between align-items-center">
            <Col className="m-0 p-0">
                <Row className="m-0 p-0 justify-content-start align-items-center">
                    <Col
                        xs="auto"
                        className="m-0 p-0"
                    >
                        <span className="mr-2">
                            {t(`${T_PREFIX}.show.show`)}
                            :
                        </span>
                    </Col>
                    <Row className="m-0 p-0">
                        <Button
                            size="small"
                            group
                            color={showAll ? 'outline' : 'yellow'}
                            onClick={onShowPages}
                        >
                            {itemsPerPage}
                        </Button>
                        <Button
                            size="small"
                            group
                            color={showAll ? 'yellow' : 'outline'}
                            onClick={onShowAll}
                        >
                            {t(`${T_PREFIX}.show.all`)}
                        </Button>
                    </Row>
                </Row>
            </Col>
            <Col className="m-0 p-0">
                {!showAll && pagesCount > 1 && (
                    <Pagination
                        className={classNames(
                            'm-0 ml-auto',
                            styles.pagination,
                        )}
                    >
                        {showPrev && (
                            <React.Fragment>
                                <Pagination.First onClick={onFirstPage} />
                                <Pagination.Prev onClick={onPrevPage} />
                            </React.Fragment>
                        )}
                        {
                            paginationPages.map((p) => (
                                <Pagination.Item
                                    key={p}
                                    active={p === page}
                                    onClick={() => onChangePage({ page: p })}
                                >
                                    {p}
                                </Pagination.Item>
                            ))
                        }
                        {showNext && (
                            <React.Fragment>
                                <Pagination.Next onClick={onNextPage} />
                                <Pagination.Last onClick={onLastPage} />
                            </React.Fragment>
                        )}
                    </Pagination>
                )}
            </Col>
        </Row>
    );
};

ClientNotesFooter.propTypes = {
    page: PropTypes.number,
    showAll: PropTypes.bool,
    pagesCount: PropTypes.number,
    itemsCount: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onShowAll: PropTypes.func,
    onPrevPage: PropTypes.func,
    onNextPage: PropTypes.func,
    onLastPage: PropTypes.func,
    onFirstPage: PropTypes.func,
    onShowPages: PropTypes.func,
    onChangePage: PropTypes.func,
};

ClientNotesFooter.defaultProps = {
    page: 1,
    showAll: false,
    pagesCount: 0,
    itemsCount: 0,
    itemsPerPage: 0,
    onShowAll: emptyFunc,
    onPrevPage: emptyFunc,
    onNextPage: emptyFunc,
    onLastPage: emptyFunc,
    onFirstPage: emptyFunc,
    onShowPages: emptyFunc,
    onChangePage: emptyFunc,
};

export default ClientNotesFooter;
