import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'components/Common/Column';
import { BundleNameAndServicesFormRow } from './BundleNameAndServicesFormRow';
import { BundleMaxUsagesFormRow } from './BundleMaxUsagesFormRow';
import { BundleValidityFormRow } from './BundleValidityFormRow';
import { BundlePriceFormRow } from './BundlePriceFormRow';
import { BundleDescriptionFormRow } from './BundleDescriptionFormRow';
import { BundleStatusFormRow } from './BundleStatusFormRow';
import { BundleTrialFormRow } from './BundleTrialFormRow';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

const AddOrEditBundleForm = (props) => {
    const {
        sold,
        values,
        services,
        validation,
        onChangeName,
        onChangePrice,
        onToggleTrial,
        onToggleStatus,
        onChangeVatRate,
        onChangeServices,
        onChangeDescription,
        onChangeValidityUnit,
        onChangeValidityValue,
        onChangeMaxUsagesValue,
        onToggleValidityUnlimited,
        onToggleMaxUsagesUnlimited,
    } = props;

    return (
        <Column gap={0} stretched>
            <BundleNameAndServicesFormRow
                sold={sold}
                values={values}
                services={services}
                validation={validation}
                onChangeName={onChangeName}
                onChangeServices={onChangeServices}
            />
            <BundleMaxUsagesFormRow
                sold={sold}
                values={values}
                validation={validation}
                onChangeMaxUsagesValue={onChangeMaxUsagesValue}
                onToggleMaxUsagesUnlimited={onToggleMaxUsagesUnlimited}
            />
            <BundleValidityFormRow
                sold={sold}
                values={values}
                validation={validation}
                onChangeValidityUnit={onChangeValidityUnit}
                onChangeValidityValue={onChangeValidityValue}
                onToggleValidityUnlimited={onToggleValidityUnlimited}
            />
            <BundlePriceFormRow
                sold={sold}
                values={values}
                validation={validation}
                onChangePrice={onChangePrice}
                onChangeVatRate={onChangeVatRate}
            />
            <BundleDescriptionFormRow
                sold={sold}
                values={values}
                validation={validation}
                onChangeDescription={onChangeDescription}
            />
            <BundleStatusFormRow
                values={values}
                validation={validation}
                onToggleStatus={onToggleStatus}
            />
            <BundleTrialFormRow
                values={values}
                validation={validation}
                onToggleTrial={onToggleTrial}
            />
        </Column>
    );
};

AddOrEditBundleForm.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    services: PropTypes.array,
    validation: PropTypes.object,
    onChangeName: PropTypes.func,
    onChangePrice: PropTypes.func,
    onToggleTrial: PropTypes.func,
    onToggleStatus: PropTypes.func,
    onChangeVatRate: PropTypes.func,
    onChangeServices: PropTypes.func,
    onChangeDescription: PropTypes.func,
    onChangeValidityUnit: PropTypes.func,
    onChangeValidityValue: PropTypes.func,
    onChangeMaxUsagesValue: PropTypes.func,
    onToggleValidityUnlimited: PropTypes.func,
    onToggleMaxUsagesUnlimited: PropTypes.func,
};

AddOrEditBundleForm.defaultProps = {
    sold: false,
    services: [],
    validation: null,
    onChangeName: emptyFunc,
    onChangePrice: emptyFunc,
    onToggleTrial: emptyFunc,
    onToggleStatus: emptyFunc,
    onChangeVatRate: emptyFunc,
    onChangeServices: emptyFunc,
    onChangeDescription: emptyFunc,
    onChangeValidityUnit: emptyFunc,
    onChangeValidityValue: emptyFunc,
    onChangeMaxUsagesValue: emptyFunc,
    onToggleValidityUnlimited: emptyFunc,
    onToggleMaxUsagesUnlimited: emptyFunc,
};

export default AddOrEditBundleForm;
