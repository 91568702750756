import { ComingSoon } from 'components/ComingSoon';
import { roles } from 'pages/roles';
import BookingsPage from './bookings';
import BundlesPage from './bundles';
import SubscriptionsPage from './subscriptions';
import InvoicesPage from './invoices';
import ReviewsPage from './reviews';
import NotesPage from './notes';
import InfoPage from './info/page';
import AccessPage from './access';
import ActivityPage from './activity';
import EmailsPage from './emails';

import {
    CLIENTS_LIST_ID_BOOKINGS_PATH,
    CLIENTS_LIST_ID_BUNDLES_PATH,
    CLIENTS_LIST_ID_SUBSCRIPTIONS_PATH,
    CLIENTS_LIST_ID_INVOICES_PATH,
    CLIENTS_LIST_ID_REVIEWS_PATH,
    CLIENTS_LIST_ID_COMMUNICATION_PATH,
    CLIENTS_LIST_ID_NOTES_PATH,
    CLIENTS_LIST_ID_INFO_PATH,
    CLIENTS_LIST_ID_ACCESS_PATH,
    CLIENTS_LIST_ID_ACTIVITY_PATH,
    CLIENTS_LIST_ID_EMAILS_PATH,
} from '../../../../../const/CLIENT_PATH';

const routes = [
    {
        component: InfoPage,
        exact: true,
        path: CLIENTS_LIST_ID_INFO_PATH,
        mobileOnly: true,
        accessKey: undefined,
        roles: undefined,
        section: undefined,
    },
    {
        component: NotesPage,
        exact: true,
        path: CLIENTS_LIST_ID_NOTES_PATH,
        accessKey: undefined,
        roles: undefined,
        section: undefined,
    },
    {
        component: BookingsPage,
        exact: true,
        path: CLIENTS_LIST_ID_BOOKINGS_PATH,
        accessKey: 'canOpenServices',
        roles: roles.services,
        section: undefined,
    },
    {
        component: BundlesPage,
        exact: true,
        path: CLIENTS_LIST_ID_BUNDLES_PATH,
        accessKey: 'canOpenServices',
        roles: roles.services,
        section: undefined,
    },
    {
        component: SubscriptionsPage,
        exact: true,
        path: CLIENTS_LIST_ID_SUBSCRIPTIONS_PATH,
        accessKey: 'canOpenServices',
        roles: roles.services,
        section: undefined,
    },
    {
        component: AccessPage,
        exact: true,
        path: CLIENTS_LIST_ID_ACCESS_PATH,
        accessKey: 'canOpenAccessLogs',
        roles: roles.access,
        section: 'access',
    },
    {
        component: ActivityPage,
        exact: true,
        path: CLIENTS_LIST_ID_ACTIVITY_PATH,
        accessKey: 'canOpenActivity',
        roles: roles.activity,
        section: undefined,
    },
    {
        component: InvoicesPage,
        exact: true,
        path: CLIENTS_LIST_ID_INVOICES_PATH,
        accessKey: 'canOpenFinancials',
        roles: roles.financials,
        section: 'financials',
    },
    {
        component: EmailsPage,
        exact: true,
        path: CLIENTS_LIST_ID_EMAILS_PATH,
        accessKey: undefined,
        roles: undefined,
        section: undefined,
    },
    {
        component: ReviewsPage,
        exact: true,
        path: CLIENTS_LIST_ID_REVIEWS_PATH,
        accessKey: undefined,
        roles: undefined,
        section: undefined,
    },
    {
        component: ComingSoon,
        exact: true,
        path: CLIENTS_LIST_ID_COMMUNICATION_PATH,
        accessKey: undefined,
        roles: undefined,
        section: undefined,
    },
];

export default routes;
