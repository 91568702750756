import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form, Dropdown,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import * as COMPANY_ACTIONS from 'store/actions/company';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { debounce } from 'lodash';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { getTimezonesArray } from 'helpers/time/getTimezonesArray';
import Select from 'react-select';
import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';
import { FormInput } from 'components/Common/FormInput';
import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { Header } from 'components/Common/Typography/Header';
import NumberInput from 'components/Common/NumberInput';
import * as styles from './page.module.scss';

const T_PREFIX = 'displayAndNotifications';
const MAX_SERVICES_TAB_NAME_LENGTH = 50;
const MAX_GROUPS_TAB_NAME_LENGTH = 50;
const MAX_EVENTS_TAB_NAME_LENGTH = 50;
const MAX_BUNDLES_TAB_NAME_LENGTH = 50;
const MAX_SUBSCRIPTIONS_TAB_NAME_LENGTH = 50;
const MIN_DELTA_TIME = 0;
const MAX_DELTA_TIME = 40320;

const MAX_WIDTH = 270;

const DisplayAndNotifications = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const dispatch = useDispatch();
    const {
        isSavingProcess,
        // loaded,
        enableInvoiceSendingForAutomaticPayments,
        enableInvoiceSendingForServices,
        enableInvoiceSendingForBundles,
        showReviews,
        rateEnabled,
        enableBookingNotifications,
        enableBookingEmployeeNotifications,
        deltaTime,
        overwriteProductNames,
        servicesTabName,
        groupsTabName,
        eventsTabName,
        bundlesTabName,
        subscriptionTabName,
        timezone,
    } = useSelector(COMPANY_SELECTORS.companySettingsDisplayAndNotificationsSelector);

    const initialValues = {
        enableInvoiceSendingForAutomaticPayments: enableInvoiceSendingForAutomaticPayments || false,
        enableInvoiceSendingForServices: enableInvoiceSendingForServices || false,
        enableInvoiceSendingForBundles: enableInvoiceSendingForBundles || false,
        showReviews: showReviews || false,
        rateEnabled: rateEnabled || false,
        enableBookingNotifications: enableBookingNotifications || false,
        enableBookingEmployeeNotifications: enableBookingEmployeeNotifications || false,
        deltaTime: deltaTime || MIN_DELTA_TIME,
        overwriteProductNames: overwriteProductNames || false,
        servicesTabName: servicesTabName || '',
        groupsTabName: groupsTabName || '',
        eventsTabName: eventsTabName || '',
        bundlesTabName: bundlesTabName || '',
        subscriptionTabName: subscriptionTabName || '',
        timezone,
    };

    const validationSchema = useMemo(() => yup.object({
        enableInvoiceSendingForAutomaticPayments: yup
            .boolean(),
        enableInvoiceSendingForBundles: yup
            .boolean(),
        enableInvoiceSendingForServices: yup
            .boolean(),
        showReviews: yup
            .boolean(),
        rateEnabled: yup
            .boolean(),
        enableBookingNotifications: yup
            .boolean(),
        enableBookingEmployeeNotifications: yup
            .boolean(),
        deltaTime: yup
            .number()
            .min(MIN_DELTA_TIME, t(`${T_PREFIX}.bookingLeadTime.errors.min`, { min: MIN_DELTA_TIME }))
            .max(MAX_DELTA_TIME, t(`${T_PREFIX}.bookingLeadTime.errors.max`, { max: MAX_DELTA_TIME })),
        overwriteProductNames: yup
            .boolean(),
        servicesTabName: yup
            .string()
            .max(MAX_SERVICES_TAB_NAME_LENGTH, t(`${T_PREFIX}.servicesTabName.errors.maxLength`, { length: MAX_SERVICES_TAB_NAME_LENGTH }))
            .when('overwriteProductNames', {
                is: true,
                then: yup.string().required(t(`${T_PREFIX}.servicesTabName.errors.required`)),
            }),
        groupsTabName: yup
            .string()
            .max(MAX_GROUPS_TAB_NAME_LENGTH, t(`${T_PREFIX}.groupsTabName.errors.maxLength`, { length: MAX_GROUPS_TAB_NAME_LENGTH }))
            .when('overwriteProductNames', {
                is: true,
                then: yup.string().required(t(`${T_PREFIX}.groupsTabName.errors.required`)),
            }),
        eventsTabName: yup
            .string()
            .max(MAX_EVENTS_TAB_NAME_LENGTH, t(`${T_PREFIX}.eventsTabName.errors.maxLength`, { length: MAX_EVENTS_TAB_NAME_LENGTH }))
            .when('overwriteProductNames', {
                is: true,
                then: yup.string().required(t(`${T_PREFIX}.eventsTabName.errors.required`)),
            }),
        bundlesTabName: yup
            .string()
            .max(MAX_BUNDLES_TAB_NAME_LENGTH, t(`${T_PREFIX}.bundlesTabName.errors.maxLength`, { length: MAX_BUNDLES_TAB_NAME_LENGTH }))
            .when('overwriteProductNames', {
                is: true,
                then: yup.string().required(t(`${T_PREFIX}.bundlesTabName.errors.required`)),
            }),
        subscriptionTabName: yup
            .string()
            .max(MAX_BUNDLES_TAB_NAME_LENGTH, t(`${T_PREFIX}.subscriptionTabName.errors.maxLength`, { length: MAX_SUBSCRIPTIONS_TAB_NAME_LENGTH }))
            .when('overwriteProductNames', {
                is: true,
                then: yup.string().required(t(`${T_PREFIX}.subscriptionTabName.errors.required`)),
            }),
        timezone: yup
            .string(),
    }), [t]);

    const onSubmit = useCallback((company) => {
        const data = new FormData();
        data.append('enableInvoiceSendingForAutomaticPayments', company.enableInvoiceSendingForAutomaticPayments);
        data.append('enableInvoiceSendingForServices', company.enableInvoiceSendingForServices);
        data.append('enableInvoiceSendingForBundles', company.enableInvoiceSendingForBundles);
        data.append('showReviews', company.showReviews);
        data.append('rateEnabled', company.rateEnabled);
        data.append('enableBookingNotifications', company.enableBookingNotifications);
        data.append('enableBookingEmployeeNotifications', company.enableBookingEmployeeNotifications);
        data.append('deltaTime', company.deltaTime || 0);
        data.append('timezone', company.timezone);
        data.append('overwriteProductNames', company.overwriteProductNames);
        data.append('servicesTabName', company.overwriteProductNames ? company.servicesTabName : initialValues.servicesTabName);
        data.append('groupsTabName', company.overwriteProductNames ? company.groupsTabName : initialValues.groupsTabName);
        data.append('eventsTabName', company.overwriteProductNames ? company.eventsTabName : initialValues.eventsTabName);
        data.append('bundlesTabName', company.overwriteProductNames ? company.bundlesTabName : initialValues.bundlesTabName);
        data.append('subscriptionTabName', company.overwriteProductNames ? company.subscriptionTabName : initialValues.subscriptionTabName);

        dispatch(COMPANY_ACTIONS.setCompanySettingsDisplayAndNotifications({ data }));

        if (!company.deltaTime) {
            setFieldValue('deltaTime', 0);
        }
        if (!company.overwriteProductNames) {
            setFieldValue('servicesTabName', initialValues.servicesTabName);
            setFieldValue('groupsTabName', initialValues.groupsTabName);
            setFieldValue('eventsTabName', initialValues.eventsTabName);
            setFieldValue('bundlesTabName', initialValues.bundlesTabName);
            setFieldValue('subscriptionTabName', initialValues.subscriptionTabName);
        }
    }, [dispatch,
        initialValues.servicesTabName,
        initialValues.groupsTabName,
        initialValues.eventsTabName,
        initialValues.bundlesTabName,
        initialValues.subscriptionTabName,
    ]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        errors,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const hasAnyChanges = useMemo(
        () => JSON.stringify(values) !== JSON.stringify(initialValues),
        [values, initialValues],
    );

    const handleDiscard = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const timezonesOptions = useMemo(() => getTimezonesArray(values.timezone), [values.timezone]);
    const selectedTimezone = useMemo(() => timezonesOptions.find((t) => t.value === values.timezone), [timezonesOptions, values.timezone]);
    const handleTimezoneChange = useCallback((selected) => {
        setFieldValue('timezone', selected.value);
        setFieldTouched('timezone', selected.value);
    }, [handleChange, timezonesOptions]);

    const onSubmitHandler = useCallback(debounce(() => {
        handleSubmit();
    }, 500), [handleSubmit]);

    return (
        <Form className={styles.form}>
            <Form.Group>
                <Form.Label className="d-flex align-items-center m-0 font-size-20 font-weight-600">
                    {t(`${T_PREFIX}.title`)}

                </Form.Label>
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="rateEnabled"
                    label={t(`${T_PREFIX}.allowClientsToLeaveReviews.label`)}
                    checked={values.rateEnabled}
                    onChange={handleChange}
                    isInvalid={touched.rateEnabled && !!errors.rateEnabled}
                />
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="showReviews"
                    label={t(`${T_PREFIX}.showClientReviews.label`)}
                    checked={values.showReviews}
                    onChange={handleChange}
                    isInvalid={touched.showReviews && !!errors.showReviews}
                />
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="enableInvoiceSendingForAutomaticPayments"
                    label={t(`${T_PREFIX}.sendInvoices.label`)}
                    checked={values.enableInvoiceSendingForAutomaticPayments}
                    onChange={handleChange}
                    isInvalid={touched.enableInvoiceSendingForAutomaticPayments && !!errors.enableInvoiceSendingForAutomaticPayments}
                />
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="enableInvoiceSendingForServices"
                    label={t(`${T_PREFIX}.sendInvoicesForServices.label`)}
                    checked={values.enableInvoiceSendingForServices}
                    onChange={handleChange}
                    isInvalid={touched.enableInvoiceSendingForServices && !!errors.enableInvoiceSendingForServices}
                />
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="enableInvoiceSendingForBundles"
                    label={t(`${T_PREFIX}.sendInvoicesForBundles.label`)}
                    checked={values.enableInvoiceSendingForBundles}
                    onChange={handleChange}
                    isInvalid={touched.enableInvoiceSendingForBundles && !!errors.enableInvoiceSendingForBundles}
                />
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="enableBookingNotifications"
                    label={t(`${T_PREFIX}.sendBookingNotificationsAdmin.label`)}
                    checked={values.enableBookingNotifications}
                    onChange={handleChange}
                    isInvalid={touched.enableBookingNotifications && !!errors.enableBookingNotifications}
                />
            </Form.Group>
            <Form.Group>
                <CheckBox
                    name="enableBookingEmployeeNotifications"
                    label={t(`${T_PREFIX}.sendBookingNotificationsEmployees.label`)}
                    checked={values.enableBookingEmployeeNotifications}
                    onChange={handleChange}
                    isInvalid={touched.enableBookingEmployeeNotifications && !!errors.enableBookingEmployeeNotifications}
                />
            </Form.Group>

            <Form.Group className="my-4" style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label>
                    {t(`${T_PREFIX}.timezone.label`)}
                </Form.Label>
                <Select
                    name="timezone"
                    isSearchable={false}
                    onChange={handleTimezoneChange}
                    value={selectedTimezone}
                    options={timezonesOptions}
                    styles={selectStyles}
                    theme={selectTheme}
                />
            </Form.Group>
            <Dropdown.Divider className="my-4" />

            <Form.Group>
                <Form.Label className="d-flex align-items-center m-0 font-size-20 font-weight-600">
                    {t(`${T_PREFIX}.bookingsAndRegistrations.label`)}
                </Form.Label>
            </Form.Group>

            <Form.Group style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label className="d-flex align-items-center">
                    {t(`${T_PREFIX}.bookingLeadTime.label`)}
                    <InfoTooltip
                        text={t(`${T_PREFIX}.bookingLeadTime.tooltip`)}
                        placement="bottom"
                    />
                </Form.Label>
                <NumberInput
                    onChange={(value) => setFieldValue('deltaTime', value)}
                    value={values.deltaTime}
                    isInvalid={touched.deltaTime && !!errors.deltaTime}
                    error={errors.deltaTime}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.deltaTime}
                </Form.Control.Feedback>
            </Form.Group>

            <Dropdown.Divider className="my-4" />
            <Form.Group>
                <CheckBox
                    name="overwriteProductNames"
                    tooltip={t(`${T_PREFIX}.customPageNames.tooltip`)}
                    label={t(`${T_PREFIX}.customPageNames.label`)}
                    checked={values.overwriteProductNames}
                    onChange={handleChange}
                    isInvalid={touched.overwriteProductNames && !!errors.overwriteProductNames}
                    component={Header}
                />
            </Form.Group>

            <Form.Group style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label>
                    {t(`${T_PREFIX}.servicesTabName.label`)}
                </Form.Label>
                <FormInput
                    name="servicesTabName"
                    type="text"
                    value={values.servicesTabName}
                    onChange={handleChange}
                    disabled={!values.overwriteProductNames}
                    isInvalid={touched.servicesTabName && !!errors.servicesTabName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.servicesTabName}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label>
                    {t(`${T_PREFIX}.groupsTabName.label`)}
                </Form.Label>
                <FormInput
                    name="groupsTabName"
                    type="text"
                    value={values.groupsTabName}
                    onChange={handleChange}
                    disabled={!values.overwriteProductNames}
                    isInvalid={touched.groupsTabName && !!errors.groupsTabName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.groupsTabName}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label>
                    {t(`${T_PREFIX}.eventsTabName.label`)}
                </Form.Label>
                <FormInput
                    name="eventsTabName"
                    type="text"
                    value={values.eventsTabName}
                    onChange={handleChange}
                    disabled={!values.overwriteProductNames}
                    isInvalid={touched.eventsTabName && !!errors.eventsTabName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.eventsTabName}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label>
                    {t(`${T_PREFIX}.bundlesTabName.label`)}
                </Form.Label>
                <FormInput
                    name="bundlesTabName"
                    type="text"
                    value={values.bundlesTabName}
                    onChange={handleChange}
                    disabled={!values.overwriteProductNames}
                    isInvalid={touched.bundlesTabName && !!errors.bundlesTabName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.bundlesTabName}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ maxWidth: MAX_WIDTH }}>
                <Form.Label>
                    {t(`${T_PREFIX}.subscriptionTabName.label`)}
                </Form.Label>
                <FormInput
                    name="subscriptionTabName"
                    type="text"
                    value={values.subscriptionTabName}
                    onChange={handleChange}
                    disabled={!values.overwriteProductNames}
                    isInvalid={touched.subscriptionTabName && !!errors.subscriptionTabName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.subscriptionTabName}
                </Form.Control.Feedback>
            </Form.Group>

            <div className={styles.actionsButtonsWrapper}>
                <Button
                    disabled={!hasAnyChanges || isSavingProcess}
                    color="outline"
                    onClick={handleDiscard}
                >
                    {t('actions.discard')}
                </Button>
                <Button
                    onClick={onSubmitHandler}
                    disabled={!hasAnyChanges}
                    loading={isSavingProcess}
                >
                    {t('actions.save')}
                </Button>
            </div>
        </Form>
    );
};

export default DisplayAndNotifications;
