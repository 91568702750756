import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AllowRouteWrapper, LayoutContainer, PortalSubHeader } from 'components';
import { useMultisafe } from 'hooks/useMultisafe';
import routes from './scenes';
import { permissionsPresetSelector } from '../../store/selectors/permissions';

import * as INVOICES_ACTIONS from '../../store/actions/financials/invoices';

function Financials({ roles, accessKey }) {
    const dispatch = useDispatch();

    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);

    const isMultisafe = useMultisafe();

    const filteredRoutes = useMemo(() => routes.filter((subRoute) => (subRoute.multisafe === undefined ? true : (isMultisafe && subRoute.multisafe) || (!isMultisafe && !subRoute.multisafe))), [isMultisafe]);

    useEffect(() => () => {
        dispatch(INVOICES_ACTIONS.setInvoicesPageFirst());
    }, [dispatch]);

    return (
        <LayoutContainer>
            <PortalSubHeader roles={roles} accessKey={accessKey} />
            <Switch>
                {
                    filteredRoutes.map(({ component, exact, path }) => (
                        <AllowRouteWrapper
                            key={path}
                            path={path}
                            sections={sections}
                            permissionSections={preset?.section}
                        >
                            <Route
                                key={path}
                                path={path}
                                exact={exact}
                                component={component}
                            />
                        </AllowRouteWrapper>
                    ))
                }
                <Redirect to={filteredRoutes[0].path} />
            </Switch>
        </LayoutContainer>
    );
}

export default Financials;
