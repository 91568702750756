import React from 'react';
import {
    Form,
    InputGroup,
    Modal,
} from 'react-bootstrap';
import * as yup from 'yup';
import moment from 'moment';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { DateInput } from 'components';
import { CalendarIcon, InfoIcon } from 'components/Icon/Icon';
import { getError, isInvalid } from 'services/validationService';
import {
    cancelSoldSubscription,
    resetCancelSoldSubscription,
} from 'store/actions';
import { cancelSoldSubscriptionSelector } from 'store/selectors/subscriptions';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { useToasts } from 'react-toast-notifications';
import * as styles from './CancelSubscriptionModal.module.scss';

const getWarning = ({ showNotLastDayOfMonthWarning, showEndDateBeforePaymentEnd }) => {
    if (showNotLastDayOfMonthWarning) { return 'subscriptions.cancelSoldSubscriptionModals.cancelSubscriptionWarning'; }
    if (showEndDateBeforePaymentEnd) { return 'subscriptions.cancelSoldSubscriptionModals.showEndDateBeforePaymentEnd'; }
};

function CancelSoldSubscriptionModal() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        subscriptionStartDate, nextDebitDate, paymentType, price, nextSubscriptionDate, nextSubscriptionPrice,
    } = useSelector(cancelSoldSubscriptionSelector);

    const validationSchema = yup.object({
        endDate: yup.mixed()
            .test({
                test: (value) => value?.isSameOrAfter(moment().startOf('day')),
                message: t('validationErrors.dateCurrentOrFuture'),
            })
            .test({
                test: (value) => value?.isSameOrAfter(moment.unix(subscriptionStartDate)),
                message: t('validationErrors.cancelBeforeStart'),
            }),
        notNotifyClient: yup.boolean().notRequired(),
    });

    const { addToast } = useToasts();
    const {
        setErrors,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        setSubmitting,
        values: {
            endDate,
            notNotifyClient,
        },
        errors,
        touched,
        isSubmitting,
    } = useFormik({
        initialValues: {
            subscriptionStartDate,
            endDate: null,
            notNotifyClient: false,
        },
        validationSchema,
        onSubmit: (values) => {
            const { endDate, notNotifyClient } = values;
            const unixEndDate = endDate.unix();
            setSubmitting(true);
            dispatch(cancelSoldSubscription({ endDate: unixEndDate, notNotifyClient })).catch(({ message, errors }) => {
                setSubmitting(false);
                if (message) {
                    addToast(message, {
                        appearance: 'error',
                    });
                } else {
                    setErrors(errors || {});
                }
            }).finally(() => {
                setSubmitting(false);
            });
        },
    });

    const endDateIsInvalid = isInvalid('endDate', errors, touched);

    const isAutomaticPayment = paymentType === 'automatic';

    const momentNextDebitDate = moment.unix(nextDebitDate).startOf('month').add(-1, 'month');

    const startDate = moment().isAfter(momentNextDebitDate, 'day') ? moment() : momentNextDebitDate;

    const daysFromStartToEnd = moment(endDate).diff(moment(endDate).startOf('month'), 'day') + 1;
    const daysInEndMonth = moment(endDate).daysInMonth();
    const currentPrice = formatPrice.parseEuroWithComma({ price });
    const isNewSubscription = nextSubscriptionDate && endDate?.isSameOrAfter(moment.unix(nextSubscriptionDate), 'day');

    const lastPrice = isNewSubscription ? nextSubscriptionPrice : currentPrice;
    const amount = (daysFromStartToEnd / daysInEndMonth) * lastPrice;

    const isAfterNextPayment = endDate?.isSameOrAfter(moment.unix(nextDebitDate), 'day');
    const hasEndDate = !!endDate;
    // there are three or fewer days left until the end of this month
    const isThreeOrFewerDaysUntilThisMonth = startDate.clone().endOf('month').diff(moment(), 'day') < 3;
    const followingMonth = startDate.clone().add(Number(!!isThreeOrFewerDaysUntilThisMonth), 'months');
    const isBeforeEndCurrentMonth = moment(endDate).isSameOrBefore(moment(endDate).endOf('month'), 'day');
    // the end date is before the last day of the following month
    const isBeforeEndFollowindMonth = moment(endDate).isSameOrBefore(followingMonth.clone().endOf('month'), 'day');

    const showNotLastDayOfMonthWarning = isAutomaticPayment && hasEndDate && !endDateIsInvalid && isAfterNextPayment && !isBeforeEndFollowindMonth;
    const showEndDateBeforePaymentEnd = isAutomaticPayment && hasEndDate && !endDateIsInvalid && (isBeforeEndCurrentMonth || isThreeOrFewerDaysUntilThisMonth && isBeforeEndFollowindMonth);

    const hasWarning = showNotLastDayOfMonthWarning || showEndDateBeforePaymentEnd;

    const onEndDateChange = (date) => {
        setFieldTouched('endDate', true);
        setFieldValue('endDate', date);
    };
    const onCloseModal = () => dispatch(resetCancelSoldSubscription());

    const endofmonth = followingMonth.clone().endOf('month').format('DD/MM/YYYY');

    return (
        <Modal
            className={styles.modal}
            centered
            size="md"
            onHide={onCloseModal}
            show
        >
            <Form
                className="d-flex flex-column"
                style={{
                    flex: '1 1 auto',
                }}
                onSubmit={handleSubmit}
            >
                <Modal.Header
                    className={classNames(styles.modalHeader, 'p-4')}
                    closeButton
                    onHide={onCloseModal}
                >
                    <Modal.Title className="m-0">
                        {t('subscriptions.cancelSoldSubscriptionModals.cancelSubscription')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column p-4">
                    <div className="font-size-20 font-weight-bold mb-4">
                        {t('subscriptions.cancelSoldSubscriptionModals.areYouSure')}
                    </div>
                    <div className="mb-4">
                        <Form.Label className="font-size-18">
                            {t('subscriptions.cancelSoldSubscriptionModals.selectEndDate')}
                        </Form.Label>
                        <InputGroup>
                            <DateInput
                                name="endDate"
                                placeholder={t('placeholders.selectDate')}
                                isInvalid={endDateIsInvalid}
                                value={endDate}
                                onChange={onEndDateChange}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text className={classNames({
                                    'border-danger text-danger': endDateIsInvalid,
                                    'text-muted': endDateIsInvalid,
                                })}
                                >
                                    <CalendarIcon
                                        className={styles.calendarIcon}
                                        width={18}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <Form.Control.Feedback
                            type="invalid"
                            className={endDateIsInvalid && 'd-flex'}
                        >
                            {getError('endDate', errors)}
                        </Form.Control.Feedback>
                    </div>
                    <div className="my-1">
                        <CheckBox
                            onChange={handleChange}
                            name="notNotifyClient"
                            label={t('subscriptions.cancelSoldSubscriptionModals.notNotifyClient')}
                            value={notNotifyClient}
                        />
                    </div>
                    {hasWarning && (
                        <div className={classNames(styles.warn, 'd-flex p-3 mt-4')}>
                            <div className="mr-2">
                                <InfoIcon />
                            </div>
                            <div
                                className="font-size-14 font-weight-600"
                                style={{ lineHeight: 1.2 }}
                            >
                                <Trans
                                    t={t}
                                    i18nKey={getWarning({ showNotLastDayOfMonthWarning, showEndDateBeforePaymentEnd })}
                                    values={{
                                        date: endDate.format('DD/MM/YYYY'),
                                        amount: formatPrice.toEuroWithComma({ amount }),
                                        endofmonth,
                                        interpolation: { escapeValue: false },
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between p-4">
                    <Button
                        color="outline"
                        onClick={onCloseModal}
                        disabled={isSubmitting}
                    >
                        {t('placeholders.back')}
                    </Button>
                    <Button
                        type="submit"
                        color="red"
                        loading={isSubmitting}
                    >
                        {t('placeholders.yes')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default CancelSoldSubscriptionModal;
