import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

function ShopsDropdown(props) {
    const {
        isDataLoaded,
        options,
        onSelect,
        shopId,
        shopName,
    } = props;

    if (!isDataLoaded) {
        return (
            <div
                className="d-flex align-items-center h-100 text-dark text-left border-0 rounded-0 text-reset font-size-16"
            >
                <Skeleton
                    width="100%"
                    height={16}
                />
            </div>
        );
    }

    return (
        <Dropdown
            data-testid="shopsDropdown"
            className="h-100 w-100"
            onSelect={onSelect}
        >
            <Dropdown.Toggle
                variant="outline-secondary"
                className="w-100 h-100 text-dark text-left border-0 rounded-0 text-reset font-size-16 arrow-right"
                style={{ paddingLeft: 32, paddingRight: 16 }}
            >
                <span className="w-100 text-truncate">{shopName}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {options.map(({ id, name }) => (
                    <Dropdown.Item
                        key={`shop-${id}`}
                        active={id === shopId}
                        eventKey={id}
                    >
                        {name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

ShopsDropdown.propTypes = {
    // eslint-disable-next-line react/require-default-props
    isDataLoaded: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    onSelect: PropTypes.func,
    shopId: PropTypes.number,
    shopName: PropTypes.string,
};

export default ShopsDropdown;
