const PREFIX = '@@INVOICES/';

const INVOICES = `${PREFIX}INVOICES`;
export const INVOICES_GET = `${INVOICES}:GET`;
export const INVOICES_SET = `${INVOICES}:SET`;
export const INVOICES_GET_AMOUNT = `${INVOICES_GET}:AMOUNT`;
export const INVOICES_EXPORT = `${INVOICES}:EXPORT`;
export const INVOICES_EXPORT_IN_PROGRESS = `${INVOICES_EXPORT}:IN_PROGRESS`;
const INVOICES_PAGE = `${INVOICES}:PAGE`;
export const INVOICES_PAGE_SET = `${INVOICES_PAGE}:SET`;
export const INVOICES_PAGE_SET_FIRST = `${INVOICES_PAGE}:FIRST`;
export const INVOICES_PAGE_SET_LAST = `${INVOICES_PAGE}:LAST`;
export const INVOICES_PAGE_SET_PREV = `${INVOICES_PAGE}:PREV`;
export const INVOICES_PAGE_SET_NEXT = `${INVOICES_PAGE}:NEXT`;
const INVOICES_SEARCH = `${INVOICES}:SEARCH`;
export const INVOICES_SEARCH_SET = `${INVOICES_SEARCH}:SET`;
const INVOICES_LOADING = `${INVOICES}:LOADING`;
export const INVOICES_LOADING_SET = `${INVOICES_LOADING}:SET`;
const INVOICES_FILTERS = `${INVOICES}:FILTERS`;
export const INVOICES_FILTERS_SET = `${INVOICES_FILTERS}:SET`;
export const INVOICES_FILTERS_CLEAR = `${INVOICES_FILTERS}:CLEAR`;
const INVOICES_ITEM = `${INVOICES}:ITEM`;
export const INVOICES_ITEM_CHECK_CREDIT_STATUS = `${INVOICES_ITEM}:CHECK_CREDIT_STATUS`;
export const INVOICES_ITEM_CREATE_CREDIT = `${INVOICES_ITEM}:CREATE_CREDIT`;
const INVOICES_EXPORT_EMAIL = `${INVOICES}:EXPORT_EMAIL`;
export const INVOICES_EXPORT_EMAIL_SET = `${INVOICES_EXPORT_EMAIL}:SET`;
const INVOICES_EXPORT_PERIOD = `${INVOICES}:EXPORT_PERIOD`;
export const INVOICES_EXPORT_PERIOD_SET = `${INVOICES_EXPORT_PERIOD}:SET`;

export const GET_CUSTOM_INVOICES = 'GET_CUSTOM_INVOICES';
export const GET_CUSTOM_INVOICES_TRIGGER = `${GET_CUSTOM_INVOICES}.TRIGGER`;
export const GET_CUSTOM_INVOICES_RESOLVED = `${GET_CUSTOM_INVOICES}.RESOLVED`;
export const GET_CUSTOM_INVOICES_REJECTED = `${GET_CUSTOM_INVOICES}.REJECTED`;

export const ADD_CUSTOM_INVOICE = 'ADD_CUSTOM_INVOICE';
export const ADD_CUSTOM_INVOICE_TRIGGER = `${ADD_CUSTOM_INVOICE}.TRIGGER`;
export const ADD_CUSTOM_INVOICE_RESOLVED = `${ADD_CUSTOM_INVOICE}.RESOLVED`;
export const ADD_CUSTOM_INVOICE_REJECTED = `${ADD_CUSTOM_INVOICE}.REJECTED`;

export const EDIT_CUSTOM_INVOICE = 'EDIT_CUSTOM_INVOICE';
export const EDIT_CUSTOM_INVOICE_TRIGGER = `${EDIT_CUSTOM_INVOICE}.TRIGGER`;
export const EDIT_CUSTOM_INVOICE_RESOLVED = `${EDIT_CUSTOM_INVOICE}.RESOLVED`;
export const EDIT_CUSTOM_INVOICE_REJECTED = `${EDIT_CUSTOM_INVOICE}.REJECTED`;

export const DELETE_CUSTOM_INVOICE = 'DELETE_CUSTOM_INVOICE';
export const DELETE_CUSTOM_INVOICE_TRIGGER = `${DELETE_CUSTOM_INVOICE}.TRIGGER`;
export const DELETE_CUSTOM_INVOICE_RESOLVED = `${DELETE_CUSTOM_INVOICE}.RESOLVED`;
export const DELETE_CUSTOM_INVOICE_REJECTED = `${DELETE_CUSTOM_INVOICE}.REJECTED`;

export const GET_CUSTOM_INVOICE = 'GET_CUSTOM_INVOICE';
export const GET_CUSTOM_INVOICE_TRIGGER = `${GET_CUSTOM_INVOICE}.TRIGGER`;
export const GET_CUSTOM_INVOICE_RESOLVED = `${GET_CUSTOM_INVOICE}.RESOLVED`;
export const GET_CUSTOM_INVOICE_REJECTED = `${GET_CUSTOM_INVOICE}.REJECTED`;

export const TOGGLE_INVOICE_PAYMENT_STATUS = 'TOGGLE_INVOICE_PAYMENT_STATUS';
export const TOGGLE_INVOICE_PAYMENT_STATUS_TRIGGER = `${TOGGLE_INVOICE_PAYMENT_STATUS}.TRIGGER`;
export const TOGGLE_INVOICE_PAYMENT_STATUS_RESOLVED = `${TOGGLE_INVOICE_PAYMENT_STATUS}.RESOLVED`;
export const TOGGLE_INVOICE_PAYMENT_STATUS_REJECTED = `${TOGGLE_INVOICE_PAYMENT_STATUS}.REJECTED`;

export const RESEND_CUSTOM_INVOICE = 'RESEND_CUSTOM_INVOICE';
export const RESEND_CUSTOM_INVOICE_TRIGGER = `${RESEND_CUSTOM_INVOICE}.TRIGGER`;
export const RESEND_CUSTOM_INVOICE_RESOLVED = `${RESEND_CUSTOM_INVOICE}.RESOLVED`;
export const RESEND_CUSTOM_INVOICE_REJECTED = `${RESEND_CUSTOM_INVOICE}.REJECTED`;
