import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { Button } from 'components/Common/Button';
import { CopyIcon } from 'components/Icon/Icon';
import { useMobile } from 'hooks/useMobile';
import TextButton from 'components/Common/TextButton/TextButton';
import { Column } from 'components/Common/Column';
import { CheckBox } from 'components/Common/CheckBox';
import { emptyFunc } from '../../helpers/function/emptyFunc';

import { HourInput } from '../Common/HourInput';

import * as styles from './Schedule.module.scss';

import { LOCALE_NAMESPACE } from '../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'schedule';

const Schedule = (props) => {
    const {
        values,
        copyMondeyToAllDaysLabel,
        minTime,
        maxTime,
        defaultFrom,
        defaultTo,
        onChange,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const { locale } = useSelector((state) => state.locales);
    const isMobile = useMobile(768);

    const onCopyMondayToAllDays = useCallback(() => {
        const newValues = { ...values };

        const mondayValue = newValues.monday;

        if (mondayValue) {
            _.times(7, (i) => {
                const dayName = moment().locale('en').isoWeekday(i + 1).format('dddd')
                    .toLowerCase();
                if (dayName === 'monday') {
                    return;
                }

                newValues[dayName] = {
                    from: values.monday.from.clone(),
                    to: values.monday.to.clone(),
                };
            });
        } else {
            Object.keys(newValues).forEach((key) => delete newValues[key]);
        }

        onChange(newValues);
    }, [values]);

    const onToggleSelectDay = useCallback(({ day }) => () => {
        const newValues = { ...values };
        const { from = defaultFrom, to = defaultTo } = newValues[day] ?? {};

        if (newValues[day]) {
            delete newValues[day];
        } else {
            newValues[day] = { from, to };
        }

        onChange(newValues);
    }, [values, defaultFrom, defaultTo]);

    const onChangeTime = useCallback(({ day, name }) => (value) => {
        const newValues = { ...values };

        newValues[day] = {
            ...newValues[day],
            [name]: value,
        };

        onChange(newValues);
    }, [values]);

    return (
        <Column gap={12} padding={0} stretched>
            <div className={styles.table}>
                {_.times(7, (i) => {
                    const dayName = moment().locale('en').isoWeekday(i + 1).format('dddd')
                        .toLowerCase();
                    const day = values[dayName];
                    const selected = Boolean(day);

                    return (
                        <div
                            key={`weekday-${i}`}
                            className={styles.tableColumn}
                        >
                            <div className={styles.headCell}>
                                <CheckBox
                                    checked={selected}
                                    onChange={onToggleSelectDay({ day: dayName })}
                                    label={moment().locale(locale).isoWeekday(i + 1).format('ddd')}
                                />
                            </div>

                            {selected
                                ? (
                                    <React.Fragment>
                                        <HourInput
                                            value={day?.from}
                                            timespan={15}
                                            min={minTime}
                                            max={day?.to.format('HH:mm')}
                                            onChange={onChangeTime({ day: dayName, name: 'from' })}
                                            className={styles.bodyCell}
                                        />

                                        <HourInput
                                            value={day?.to}
                                            timespan={15}
                                            min={day?.from.format('HH:mm')}
                                            max={maxTime}
                                            onChange={onChangeTime({ day: dayName, name: 'to' })}
                                            className={styles.bodyCell}
                                        />
                                    </React.Fragment>
                                )
                                : (
                                    <React.Fragment>
                                        <span className={classNames(styles.bodyCell, styles.bodyCellEmpty)}>
                                            —:—
                                        </span>

                                        <div />

                                        <span className={classNames(styles.bodyCell, styles.bodyCellEmpty)}>
                                            —:—
                                        </span>
                                    </React.Fragment>
                                )}

                            {i === 0 && (
                                isMobile && (
                                    <div className={styles.bodyCell}>
                                        <TextButton
                                            type="button"
                                            onClick={onCopyMondayToAllDays}
                                            color="yellow"
                                            before={<CopyIcon />}
                                        >
                                            {copyMondeyToAllDaysLabel ?? t(`${T_PREFIX}.header.actions.copyMondayToAllDays.label`)}
                                        </TextButton>
                                    </div>
                                )
                            )}
                        </div>
                    );
                })}
            </div>

            {!isMobile && (
                <Button
                    type="button"
                    onClick={onCopyMondayToAllDays}
                    color="yellow"
                    size="small"
                    before={<CopyIcon />}
                >
                    {copyMondeyToAllDaysLabel ?? t(`${T_PREFIX}.header.actions.copyMondayToAllDays.label`)}
                </Button>
            )}
        </Column>
    );
};

Schedule.propTypes = {
    values: PropTypes.object,
    title: PropTypes.string,
    copyMondeyToAllDaysLabel: PropTypes.string,
    minTime: PropTypes.string,
    maxTime: PropTypes.string,
    defaultFrom: PropTypes.object,
    defaultTo: PropTypes.object,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

Schedule.defaultProps = {
    values: {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {},
    },
    minTime: '00:00',
    maxTime: '23:59',
    defaultFrom: moment().startOf('day'),
    defaultTo: moment().endOf('day'),
    onChange: emptyFunc,
};

export default Schedule;
