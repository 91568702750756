import React, { useState, useEffect, useMemo } from 'react';

// Components
import { Form, Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'components/Common/Tooltip';
import { Button } from 'components/Common/Button';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { CheckBox } from 'components/Common/CheckBox';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Column } from 'components/Common/Column';
import { PRODUCT_TYPE } from 'const/product/PRODUCT_TYPE';

const NOT_NOTIFY_CLIENT_SHOWN = [PRODUCT_TYPE.EMPLOYEE, PRODUCT_TYPE.OBJECT];

const AddBookingConfirmModal = ({
    isShow,
    hide,
    loading,
    confirmAction,
    paymentOpts,
    restrictUnpaidBookings,
    recurrence,
    price,
    productType,
}) => {
    const { t } = useTranslation();

    const [bookingConfirmOption, setBookingConfirmOption] = useState(null);

    const bookingConfirmOpts = useMemo(() => {
        const result = [
            {
                name: 'subscription',
                label: t('addBookingConfirmModal.fromSubscription.label'),
                disabledTooltip: t('addBookingConfirmModal.fromSubscription.tooltip'),
                type: 'subscription',
                paid: true,
            },
            {
                name: 'bundle',
                label: t('addBookingConfirmModal.fromBundle.label'),
                disabledTooltip: t('addBookingConfirmModal.fromBundle.tooltip'),
                type: 'bundle',
                paid: true,
            },
        ];
        if (price === 0) {
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.manual.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.manual.tooltip'),
                isDisabled: restrictUnpaidBookings,
                type: 'cash',
                paid: true,
            });
        } else if (recurrence) {
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.unpaid.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.unpaid.tooltip'),
                type: 'cash',
                paid: false,
            });
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.paid.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.paid.tooltip'),
                type: 'cash',
                paid: true,
            });
        } else {
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.ideal.label'),
                isDisabled: restrictUnpaidBookings,
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.ideal.tooltip'),
                type: 'ideal',
                paid: false,
            });
            result.push({
                name: 'cash',
                label: t('addBookingConfirmModal.cash.label'),
                disabledTooltip: t('addBookingConfirmModal.yesDisabledTooltip'),
                tooltip: t('addBookingConfirmModal.cash.tooltip'),
                type: 'cash',
                paid: true,
            });
        }
        return result;
    }, [restrictUnpaidBookings, price, recurrence, t]);

    useEffect(() => {
        if (paymentOpts?.length) {
            // bookingConfirmOpts as priority order, find first option that exists in paymentOpts
            bookingConfirmOpts.every((option) => {
                const paymentOpt = paymentOpts.find((opt) => opt.payment_type === option.name && !option.isDisabled);

                if (paymentOpt) {
                    setBookingConfirmOption({
                        id: paymentOpt.id,
                        name: paymentOpt.payment_type,
                        type: option.type,
                        paid: option.paid,
                        notNotifyClient: false,
                    });

                    return false;
                }

                return true;
            });
        }
    }, [paymentOpts, bookingConfirmOpts]);

    const handleChange = (bookingConfirmOpt) => {
        const option = paymentOpts.find((opt) => opt.payment_type === bookingConfirmOpt.name);
        if (!option) return;
        setBookingConfirmOption({
            id: option.id,
            name: option.payment_type,
            type: bookingConfirmOpt.type,
            paid: bookingConfirmOpt.paid,
            notNotifyClient: false,
        });
    };

    const notNotifyClientShown = NOT_NOTIFY_CLIENT_SHOWN.includes(productType) && price === 0;

    return (
        <Modal size="lg" show={isShow} onHide={hide} centered>
            <Modal.Header closeButton>
                <Modal.Title className="mb-0 font-weight-600">
                    {t('addBookingConfirmModal.title')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Column gap={16} stretched>
                    <SubHeader>{t('addBookingConfirmModal.bookingWillBe')}</SubHeader>
                    <Column gap={0} stretched>
                        {bookingConfirmOpts
                            .map((option, i) => {
                                const disabled = !paymentOpts?.find((opt) => opt.payment_type === option.name) || option.isDisabled;
                                return (
                                    <Form.Group
                                        key={option.name + i}
                                    >
                                        <Form.Check
                                            type="radio"
                                            id={option.name + i}
                                            label={(
                                                <Tooltip
                                                    tooltip={option.disabledTooltip}
                                                    placement="right-end"
                                                    isDisabled={!disabled}
                                                >
                                                    <Form.Label className="m-0 d-flex align-items-center" htmlFor={option.name + i}>
                                                        {option.label}
                                                        {option.tooltip && !disabled && (
                                                            <InfoTooltip
                                                                text={option.tooltip}
                                                                disabled={option.tooltip}
                                                                placement="bottom"
                                                            />
                                                        )}
                                                    </Form.Label>
                                                </Tooltip>
                                            )}
                                            name={option.name}
                                            checked={bookingConfirmOption?.name === option.name && bookingConfirmOption?.paid === option.paid}
                                            onChange={() => handleChange(option)}
                                            disabled={disabled}
                                        />
                                    </Form.Group>
                                );
                            })}
                        {Boolean(notNotifyClientShown && bookingConfirmOption) && (
                            <CheckBox
                                label={t('addBookingConfirmModal.notNotifyClient')}
                                checked={bookingConfirmOption?.notNotifyClient}
                                onChange={() => setBookingConfirmOption((prev) => ({ ...prev, notNotifyClient: !prev.notNotifyClient }))}
                            />
                        )}
                    </Column>
                </Column>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    color="outline"
                    onClick={hide}
                >
                    {t('addBookingConfirmModal.back')}
                </Button>
                <Tooltip
                    tooltip={t('addBookingConfirmModal.yesDisabledTooltip')}
                    placement="top"
                    isDisabled={!!bookingConfirmOption}
                    forButton
                >
                    <Button
                        color="yellow"
                        onClick={() => confirmAction({ option: bookingConfirmOption })}
                        disabled={loading || !bookingConfirmOption}
                        loading={loading}
                    >
                        {t('addBookingConfirmModal.yes')}
                    </Button>
                </Tooltip>

            </Modal.Footer>
        </Modal>
    );
};

export default AddBookingConfirmModal;
