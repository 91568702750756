import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useSelector } from 'react-redux';
import { ACTIVITY_SORT_BY_TYPE } from 'const/activity/ACTIVITY_SORT_BY_TYPE';
import { companySelector } from 'store/selectors/company';
import PropTypes from 'prop-types';

const T_PREFIX = 'activity.list.table.header.fields';

const ActivityTableHeader = ({ onCellClick, sortOptions }) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);

    const { shops } = useSelector(companySelector);

    const canSortByLocation = shops?.length > 1;

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-timestamp"
                    name={t(`${T_PREFIX}.timestamp`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.TIMESTAMP}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(ACTIVITY_SORT_BY_TYPE.TIMESTAMP)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-location"
                    name={t(`${T_PREFIX}.location`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.LOCATION}
                    ascending={sortOptions.orderBy === -1}
                    onClick={canSortByLocation ? () => onCellClick(ACTIVITY_SORT_BY_TYPE.LOCATION) : undefined}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-employee"
                    name={t(`${T_PREFIX}.employee`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.EMPLOYEE}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(ACTIVITY_SORT_BY_TYPE.EMPLOYEE)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-client"
                    name={t(`${T_PREFIX}.client`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.CLIENT}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(ACTIVITY_SORT_BY_TYPE.CLIENT)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-product-type"
                    name={t(`${T_PREFIX}.productType`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.PRODUCT_TYPE}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(ACTIVITY_SORT_BY_TYPE.PRODUCT_TYPE)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-product-name"
                    name={t(`${T_PREFIX}.productName`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.PRODUCT_NAME}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(ACTIVITY_SORT_BY_TYPE.PRODUCT_NAME)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-action"
                    name={t(`${T_PREFIX}.action`)}
                    active={sortOptions.sortBy === ACTIVITY_SORT_BY_TYPE.ACTION}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(ACTIVITY_SORT_BY_TYPE.ACTION)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-details"
                    name={t(`${T_PREFIX}.details`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-activity-list-table-header-cell-reason"
                    name={t(`${T_PREFIX}.reason`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
};

ActivityTableHeader.propTypes = {
    onCellClick: PropTypes.func,
    sortOptions: PropTypes.object.isRequired,
};

ActivityTableHeader.defaultProps = {
    onCellClick: () => {},
};

export default ActivityTableHeader;
