import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import classNames from 'classnames';
import moment from 'moment';
import { BlockModal } from 'components';
import { ClockIcon } from '../Icon/Icon';
import { generateRandomString } from '../../helpers/string/generateRandomString';

// Styles
import * as styles from './Block.module.scss';

// Utils

const Block = (props) => {
    const {
        type,
        className,
        style,
        blockId,
        productBlock,
        from,
        to,
        comment,
        multiply,
    } = props;

    const timeblocksCovered = Math.round(moment.duration(moment(to).diff(moment(from))).as('minutes').toFixed(0) / 15);

    const { t } = useTranslation();

    const [showBlockModal, setShowBlockModal] = useState(false);
    const [blockKey, setBlockKey] = useState(generateRandomString());

    const shortText = (text, value) => {
        if (!text) return '';
        return text.length > value ? `${text.substring(0, value - 3)}...` : text;
    };

    const handleBlockClick = () => {
        setShowBlockModal(true);
        setBlockKey(generateRandomString());
    };

    return (
        <React.Fragment>
            <div
                className={classNames(
                    className,
                    styles.container,
                    `d-flex flex-${timeblocksCovered <= 2 ? 'row' : 'column'} justify-content-${timeblocksCovered <= 2 ? 'around' : 'center'} align-items-center p-2`,
                )}
                style={{ ...style, zIndex: 10 }}
                onClick={handleBlockClick}
            >
                <div className="d-flex flex-column align-items-center">
                    <h5 className="mb-0">{t('block.blocked')}</h5>
                    <div className="d-flex flex-column align-items-center text-muted text-center">
                        {multiply
                            ? <span>{`${from.format('DD MMM HH:mm')} - ${to.format('DD MMM HH:mm')}`}</span>
                            : <span>{`${from.format('HH:mm')} - ${to.format('HH:mm')}`}</span>}
                    </div>
                </div>
                {!multiply && (
                    <span className="ml-2 text-muted">
                        <ClockIcon width={18} />
                        <span className="ml-1">
                            {moment.duration(moment(to).diff(moment(from))).as('minutes').toFixed(0)}
                            {' '}
                            min
                        </span>
                    </span>
                )}

                {(comment && !timeblocksCovered <= 2) && (
                    <p className="px-4 my-2 text-center text-break">
                        {shortText(comment, timeblocksCovered * 30)}
                    </p>
                )}
            </div>
            <BlockModal
                key={blockKey}
                type={type}
                show={showBlockModal}
                onHide={() => setShowBlockModal(false)}
                actionType="edit"
                blockId={blockId}
                productBlock={productBlock}
                from={from}
                to={to}
                note={comment}
            />
        </React.Fragment>
    );
};

export default Block;
