import React from 'react';

// Hooks
import { useState, useEffect } from 'react';

// Components
import moment from 'moment';
import classNames from 'classnames';
import { Popover } from 'components/Common/Popover';
import { IconButton } from 'components/Common/IconButton';
import { Close } from 'components/Icon/Icon';
import Calendar from '../Calendar/Calendar';

import * as styles from './DateInput.module.scss';
// Utils

const DateInput1 = (
    {
        className,
        value,
        format = 'DD/MM/YYYY',
        placeholder,
        onChange,
        onClear,
        isInvalid,
        isDisabled = false,
        minDate = null,
        maxDate = null,
        placement = 'bottom-start',
    },
) => {
    const [show, setShow] = useState(undefined);
    const [month, setMonth] = useState((value || moment()).month());
    const [year, setYear] = useState((value || moment()).year());

    useEffect(() => {
        if (value) {
            setYear(value.year());
            setMonth(value.month());
        }
    }, [value]);

    const handleClear = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClear?.();
    };

    return (
        <Popover
            placement={placement}
            show={show}
            onToggle={setShow}
            onHide={() => setShow(undefined)}
            content={(
                <div style={{ width: 300 }}>
                    <Calendar
                        selectedDate={value}
                        onSelectDate={(date) => {
                            setShow(false);
                            if (!value) {
                                onChange(date?.clone());
                                return;
                            }

                            onChange(date?.clone().hours(value.hours()).minutes(value.minutes()) ?? value);
                        }}
                        month={month}
                        year={year}
                        onChangeTimespan={(month, year) => {
                            setMonth(month);
                            setYear(year);
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            )}
        >
            {({ ref, onClick }) => (
                <button
                    ref={ref}
                    type="button"
                    className={classNames(className, 'form-control text-left', { 'is-invalid': isInvalid })}
                    onClick={onClick}
                    disabled={isDisabled}
                    data-testid="data-test-date-input"
                >
                    {value ? value.format(format) : <span className="text-muted">{placeholder}</span>}

                    {Boolean(value && onClear) && (
                        <div className={styles.clear}>
                            <IconButton
                                color="transparent"
                                size={24}
                                onClick={handleClear}
                                data-testid="data-test-date-input-clear"
                            >
                                <Close width={16} height={16} />
                            </IconButton>
                        </div>
                    )}
                </button>
            )}
        </Popover>
    );
};

export default DateInput1;
