import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form, Dropdown, Row, Col,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { debounce } from 'lodash';

import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { Header } from 'components/Common/Typography/Header';
import NumberInput from 'components/Common/NumberInput';
import { Badge } from 'components/Common/Badge';
import { Column } from 'components/Common/Column';
import { Tooltip } from '../../../../../../components/Common/Tooltip';

import * as styles from './page.module.scss';

import * as COMPANY_ACTIONS from '../../../../../../store/actions/company';
import * as COMPANY_SELECTORS from '../../../../../../store/selectors/company';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'discountAndLoyalty';
const MIN_BIRTHDAY_DISCOUNT = 1;
const MAX_BIRTHDAY_DISCOUNT = 100;
const MIN_BEFORE_PERIOD = 0;
const MAX_BIRTHDAY_PERIOD = 183;
const MIN_AFTER_PERIODE = 0;
const MAX_AFTER_PERIODE = 183;
const MIN_DISCOUNT_PERCENT = 1;
const MAX_DISCOUNT_PERCENT = 100;
const MIN_POINTS_FOR_DISCOUNT = 1;
const MAX_POINTS_FOR_DISCOUNT = 99999;
const MIN_POINTS_FOR_ONLINE = 1;
const MAX_POINTS_FOR_ONLINE = 99999;

const DiscountAndLoyalty = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const dispatch = useDispatch();
    const {
        isSavingProcess,
        // loaded,
        enableBirthdayModule,
        birthdayDiscount,
        beforePeriod,
        afterPeriod,
        enableLoyaltyProgram,
        discountPercent,
        pointsForDiscount,
        pointsForOnline,
    } = useSelector(COMPANY_SELECTORS.companySettingsDiscountAndLoyaltySelector);

    const initialValues = {
        enableBirthdayModule: enableBirthdayModule || false,
        birthdayDiscount: birthdayDiscount || MIN_BIRTHDAY_DISCOUNT,
        beforePeriod: beforePeriod || MIN_BEFORE_PERIOD,
        afterPeriod: afterPeriod || MIN_AFTER_PERIODE,
        enableLoyaltyProgram: enableLoyaltyProgram || false,
        discountPercent: discountPercent || MIN_DISCOUNT_PERCENT,
        pointsForDiscount: pointsForDiscount || MIN_POINTS_FOR_DISCOUNT,
        pointsForOnline: pointsForOnline || MIN_POINTS_FOR_ONLINE,
    };

    const validationSchema = useMemo(() => yup.object({
        enableBirthdayModule: yup
            .boolean(),
        birthdayDiscount: yup
            .number()
            .min(MIN_BIRTHDAY_DISCOUNT, t(`${T_PREFIX}.birthdayDiscount.errors.min`, { min: MIN_BIRTHDAY_DISCOUNT }))
            .max(MAX_BIRTHDAY_DISCOUNT, t(`${T_PREFIX}.birthdayDiscount.errors.max`, { max: MAX_BIRTHDAY_DISCOUNT }))
            .when('enableBirthdayModule', {
                is: true,
                then: yup.number().required(t(`${T_PREFIX}.birthdayDiscount.errors.required`)),
            }),
        beforePeriod: yup
            .number()
            .min(MIN_BEFORE_PERIOD, t(`${T_PREFIX}.beforePeriod.errors.min`, { min: MIN_BEFORE_PERIOD }))
            .max(MAX_BIRTHDAY_PERIOD, t(`${T_PREFIX}.beforePeriod.errors.max`, { max: MAX_BIRTHDAY_PERIOD }))
            .when('enableBirthdayModule', {
                is: true,
                then: yup.number().required(t(`${T_PREFIX}.beforePeriod.errors.required`)),
            }),
        afterPeriod: yup
            .number()
            .min(MIN_AFTER_PERIODE, t(`${T_PREFIX}.afterPeriod.errors.min`, { min: MIN_AFTER_PERIODE }))
            .max(MAX_AFTER_PERIODE, t(`${T_PREFIX}.afterPeriod.errors.max`, { max: MAX_AFTER_PERIODE }))
            .when('enableBirthdayModule', {
                is: true,
                then: yup.number().required(t(`${T_PREFIX}.afterPeriod.errors.required`)),
            }),
        enableLoyaltyProgram: yup
            .boolean(),
        discountPercent: yup
            .number()
            .min(MIN_DISCOUNT_PERCENT, t(`${T_PREFIX}.discountPercent.errors.min`, { min: MIN_DISCOUNT_PERCENT }))
            .max(MAX_DISCOUNT_PERCENT, t(`${T_PREFIX}.discountPercent.errors.max`, { max: MAX_DISCOUNT_PERCENT }))
            .when('enableLoyaltyProgram', {
                is: true,
                then: yup.number().required(t(`${T_PREFIX}.discountPercent.errors.required`)),
            }),
        pointsForDiscount: yup
            .number()
            .min(MIN_POINTS_FOR_DISCOUNT, t(`${T_PREFIX}.pointsForDiscount.errors.min`, { min: MIN_POINTS_FOR_DISCOUNT }))
            .max(MAX_POINTS_FOR_DISCOUNT, t(`${T_PREFIX}.pointsForDiscount.errors.max`, { max: MAX_POINTS_FOR_DISCOUNT }))
            .when('enableLoyaltyProgram', {
                is: true,
                then: yup.number().required(t(`${T_PREFIX}.pointsForDiscount.errors.required`)),
            }),
        pointsForOnline: yup
            .number()
            .min(MIN_POINTS_FOR_ONLINE, t(`${T_PREFIX}.pointsForOnline.errors.min`, { min: MIN_POINTS_FOR_ONLINE }))
            .max(MAX_POINTS_FOR_ONLINE, t(`${T_PREFIX}.pointsForOnline.errors.max`, { max: MAX_POINTS_FOR_ONLINE }))
            .when('enableLoyaltyProgram', {
                is: true,
                then: yup.number().required(t(`${T_PREFIX}.pointsForOnline.errors.required`)),
            }),
    }), [t]);

    const onSubmit = useCallback((company) => {
        const data = new FormData();
        data.append('enableBirthdayModule', company.enableBirthdayModule);
        data.append('birthdayDiscount', company.enableBirthdayModule ? company.birthdayDiscount : initialValues.birthdayDiscount);
        data.append('beforePeriod', company.enableBirthdayModule ? company.beforePeriod : initialValues.beforePeriod);
        data.append('afterPeriod', company.enableBirthdayModule ? company.afterPeriod : initialValues.afterPeriod);
        data.append('enableLoyaltyProgram', company.enableLoyaltyProgram);
        data.append('discountPercent', company.enableLoyaltyProgram ? company.discountPercent : initialValues.discountPercent);
        data.append('pointsForDiscount', company.enableLoyaltyProgram ? company.pointsForDiscount : initialValues.pointsForDiscount);
        data.append('pointsForOnline', company.enableLoyaltyProgram ? company.pointsForOnline : initialValues.pointsForOnline);

        dispatch(COMPANY_ACTIONS.setCompanySettingsDiscountAndLoyalty({ data }));

        if (!company.enableBirthdayModule) {
            setFieldValue('birthdayDiscount', initialValues.birthdayDiscount);
            setFieldValue('beforePeriod', initialValues.beforePeriod);
            setFieldValue('afterPeriod', initialValues.afterPeriod);
        }
        if (!company.enableLoyaltyProgram) {
            setFieldValue('discountPercent', initialValues.discountPercent);
            setFieldValue('pointsForDiscount', initialValues.pointsForDiscount);
            setFieldValue('pointsForOnline', initialValues.pointsForOnline);
        }
    }, [dispatch,
        initialValues.birthdayDiscount,
        initialValues.beforePeriod,
        initialValues.afterPeriod,
        initialValues.discountPercent,
        initialValues.pointsForDiscount,
        initialValues.pointsForOnline,
    ]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        // setFieldTouched,
        values,
        touched,
        errors,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const hasAnyChanges = useMemo(
        () => JSON.stringify(values) !== JSON.stringify(initialValues),
        [values, initialValues],
    );

    const handleDiscard = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const onSubmitHandler = useCallback(debounce(() => {
        handleSubmit();
    }, 500), [handleSubmit]);

    return (
        <Form className={styles.form}>
            <Column gap={16}>
                <Tooltip
                    tooltip={t(`${T_PREFIX}.beta.tooltip`)}
                    placement="bottom"
                    forButton
                >
                    <Badge size="extra-small" color="black">
                        {t(`${T_PREFIX}.beta.label`)}
                    </Badge>
                </Tooltip>

                <Form.Group>
                    <CheckBox
                        name="enableBirthdayModule"
                        label={t(`${T_PREFIX}.enableBirthdayModule.label`)}
                        checked={values.enableBirthdayModule}
                        onChange={handleChange}
                        isInvalid={touched.enableBirthdayModule && !!errors.enableBirthdayModule}
                        component={Header}
                    />
                </Form.Group>
            </Column>

            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label className="d-inline-block text-truncate w-100">
                            {t(`${T_PREFIX}.birthdayDiscount.label`)}
                        </Form.Label>
                        <NumberInput
                            onChange={(value) => setFieldValue('birthdayDiscount', value)}
                            value={values.birthdayDiscount}
                            isInvalid={touched.birthdayDiscount && !!errors.birthdayDiscount}
                            disabled={!values.enableBirthdayModule}
                            min={MIN_BIRTHDAY_DISCOUNT}
                            max={MAX_BIRTHDAY_DISCOUNT}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.birthdayDiscount}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label className="d-inline-block text-truncate w-100">
                            {t(`${T_PREFIX}.beforePeriod.label`)}
                        </Form.Label>
                        <NumberInput
                            onChange={(value) => setFieldValue('beforePeriod', value)}
                            value={values.beforePeriod}
                            isInvalid={touched.beforePeriod && !!errors.beforePeriod}
                            disabled={!values.enableBirthdayModule}
                            min={MIN_BEFORE_PERIOD}
                            max={MAX_BIRTHDAY_PERIOD}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.beforePeriod}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label className="d-inline-block text-truncate w-100">
                            {t(`${T_PREFIX}.afterPeriod.label`)}
                        </Form.Label>
                        <NumberInput
                            onChange={(value) => setFieldValue('afterPeriod', value)}
                            value={values.afterPeriod}
                            isInvalid={touched.afterPeriod && !!errors.afterPeriod}
                            disabled={!values.enableBirthdayModule}
                            min={MIN_AFTER_PERIODE}
                            max={MAX_AFTER_PERIODE}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.afterPeriod}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Dropdown.Divider className="my-4" />

            <Form.Group>
                <CheckBox
                    name="enableLoyaltyProgram"
                    label={t(`${T_PREFIX}.enableLoyaltyProgram.label`)}
                    checked={values.enableLoyaltyProgram}
                    onChange={handleChange}
                    isInvalid={touched.enableLoyaltyProgram && !!errors.enableLoyaltyProgram}
                    component={Header}
                />
            </Form.Group>

            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label className="d-inline-block text-truncate w-100">
                            {t(`${T_PREFIX}.discountPercent.label`)}
                        </Form.Label>
                        <NumberInput
                            onChange={(value) => setFieldValue('discountPercent', value)}
                            value={values.discountPercent}
                            isInvalid={touched.discountPercent && !!errors.discountPercent}
                            disabled={!values.enableLoyaltyProgram}
                            min={MIN_DISCOUNT_PERCENT}
                            max={MAX_DISCOUNT_PERCENT}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.discountPercent}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label className="d-inline-block text-truncate w-100">
                            {t(`${T_PREFIX}.pointsForOnline.label`)}
                        </Form.Label>
                        <NumberInput
                            onChange={(value) => setFieldValue('pointsForOnline', value)}
                            value={values.pointsForOnline}
                            isInvalid={touched.pointsForOnline && !!errors.pointsForOnline}
                            disabled={!values.enableLoyaltyProgram}
                            min={MIN_POINTS_FOR_ONLINE}
                            max={MAX_POINTS_FOR_ONLINE}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.pointsForOnline}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label className="d-inline-block text-truncate w-100">
                            {t(`${T_PREFIX}.pointsForDiscount.label`)}
                        </Form.Label>
                        <NumberInput
                            onChange={(value) => setFieldValue('pointsForDiscount', value)}
                            value={values.pointsForDiscount}
                            isInvalid={touched.pointsForDiscount && !!errors.pointsForDiscount}
                            disabled={!values.enableLoyaltyProgram}
                            min={MIN_POINTS_FOR_DISCOUNT}
                            max={MAX_POINTS_FOR_DISCOUNT}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.pointsForDiscount}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <div className={styles.actionsButtonsWrapper}>
                <Button
                    disabled={!hasAnyChanges || isSavingProcess}
                    color="outline"
                    onClick={handleDiscard}
                >
                    {t('actions.discard')}
                </Button>
                <Button
                    onClick={onSubmitHandler}
                    disabled={!hasAnyChanges}
                    loading={isSavingProcess}
                >
                    {t('actions.save')}
                </Button>
            </div>
        </Form>
    );
};

export default DiscountAndLoyalty;
