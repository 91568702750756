import React from 'react';

// Components
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import Edit from '../GroupsGroup/Edit/Edit';
import Calendars from '../GroupsGroup/Calendars/Calendars';

const Groups = (
    {
        type,
        viewType,
        date,
        week,
        month,
        year,
        setDate,
        setCalendarsDate,
        setWeek,
        setMonth,
        setYear,
        setShowOptionsModal,
        NoProductComponent,
        LoadingComponent,
        isProductExist,
        isAllowed,
    },
) => (
    <Switch>
        <Route path="/agenda/groups/add" component={Edit} />
        <Route path="/agenda/groups/edit/:id" component={Edit} />
        <Route render={(props) => {
            if (isAllowed === 'never'
                || ((isAllowed === 'hasOwn' || isAllowed === 'hasAny') && !isProductExist)
            ) {
                return <Redirect to="/agenda/events" />;
            }
            // Calendar uses to fetch data, and as hacky fix we can only hide it
            return (
                <React.Fragment>
                    {(LoadingComponent || NoProductComponent) && (
                        <div
                            className="bg-white h-100 w-100"
                            style={{
                                position: 'absolute',
                                zIndex: '860', // to overflow z-index 850 from GroupsTimeline
                            }}
                        >
                            {LoadingComponent || NoProductComponent}
                        </div>
                    )}
                    <Calendars
                        {...props}
                        type={type}
                        viewType={viewType}
                        date={date}
                        week={week}
                        month={month}
                        year={year}
                        setDate={setDate}
                        setCalendarsDate={setCalendarsDate}
                        setWeek={setWeek}
                        setMonth={setMonth}
                        setYear={setYear}
                        setShowOptionsModal={setShowOptionsModal}
                    />
                </React.Fragment>
            );
        }}
        />
    </Switch>
);

export default Groups;
