import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/Common/Button';
import { useSubRoute } from 'hooks/useSubRoute';

const submitButtonTranslationOptions = {
    add: 'placeholders.addNew',
    edit: 'placeholders.saveChanges',
};

function SubscriptionAddEditFormFooter(props) {
    const { services, withDynamic, isSubmitting } = props;
    const [currentSubRoute] = useSubRoute({ withDynamic });
    const { t } = useTranslation();

    return (
        <div
            className="px-lg-5 w-100"
            style={{
                borderTop: '1px solid #E5EBF0',
            }}
        >
            <div className="d-flex justify-content-between mb-6 pt-3 pb-4 pt-lg-4 align-items-center">
                <Button
                    href="/services/subscriptions/list"
                    color="outline"
                >
                    {t('placeholders.cancel')}
                </Button>
                <Button
                    type="submit"
                    color="yellow"
                    disabled={!services.length || isSubmitting}
                >
                    {t(submitButtonTranslationOptions[currentSubRoute])}
                </Button>
            </div>
        </div>
    );
}

SubscriptionAddEditFormFooter.defaultProps = {
    withDynamic: false,
};

export default SubscriptionAddEditFormFooter;
