const PREFIX = '@@PAYOUTS/';

const PAYOUTS = `${PREFIX}PAYOUTS`;
export const PAYOUTS_GET = `${PAYOUTS}:GET`;
export const PAYOUTS_SET = `${PAYOUTS}:SET`;
export const PAYOUTS_DOWNLOAD_PAYOUT = `${PAYOUTS}:DOWNLOAD_PAYOUT`;
const PAYOUTS_PAGE = `${PAYOUTS}:PAGE`;
export const PAYOUTS_PAGE_SET = `${PAYOUTS_PAGE}:SET`;
export const PAYOUTS_PAGE_SET_FIRST = `${PAYOUTS_PAGE}:FIRST`;
export const PAYOUTS_PAGE_SET_LAST = `${PAYOUTS_PAGE}:LAST`;
export const PAYOUTS_PAGE_SET_PREV = `${PAYOUTS_PAGE}:PREV`;
export const PAYOUTS_PAGE_SET_NEXT = `${PAYOUTS_PAGE}:NEXT`;
const PAYOUTS_LOADING = `${PAYOUTS}:LOADING`;
export const PAYOUTS_LOADING_SET = `${PAYOUTS_LOADING}:SET`;
const PAYOUTS_SORT_OPTIONS = `${PAYOUTS}:SORT_OPTIONS`;
export const PAYOUTS_SORT_OPTIONS_SET = `${PAYOUTS_SORT_OPTIONS}:SET`;

const PAYOUT = `${PREFIX}PAYOUT`;
const PAYOUT_SELECTED_ID = `${PAYOUT}:SELECTED_ID`;
export const PAYOUT_SELECTED_ID_SET = `${PAYOUT_SELECTED_ID}:SET`;
const PAYOUT_TRANSACTIONS = `${PAYOUT}:TRANSACTIONS`;
export const PAYOUT_TRANSACTIONS_GET = `${PAYOUT_TRANSACTIONS}:GET`;
export const PAYOUT_TRANSACTIONS_SET = `${PAYOUT_TRANSACTIONS}:SET`;
const PAYOUT_TRANSACTIONS_LOADING = `${PAYOUT}:TRANSACTIONS_LOADING`;
export const PAYOUT_TRANSACTIONS_LOADING_SET = `${PAYOUT_TRANSACTIONS_LOADING}:SET`;
const PAYOUT_TRANSACTIONS_TYPE = `${PAYOUT}:TRANSACTIONS_TYPE`;
export const PAYOUT_TRANSACTIONS_TYPE_SET = `${PAYOUT_TRANSACTIONS_TYPE}:SET`;
const PAYOUT_TRANSACTIONS_PAGE = `${PAYOUT_TRANSACTIONS}:PAGE`;
export const PAYOUT_TRANSACTIONS_PAGE_SET = `${PAYOUT_TRANSACTIONS_PAGE}:SET`;
export const PAYOUT_TRANSACTIONS_PAGE_SET_FIRST = `${PAYOUT_TRANSACTIONS_PAGE}:FIRST`;
export const PAYOUT_TRANSACTIONS_PAGE_SET_LAST = `${PAYOUT_TRANSACTIONS_PAGE}:LAST`;
export const PAYOUT_TRANSACTIONS_PAGE_SET_PREV = `${PAYOUT_TRANSACTIONS_PAGE}:PREV`;
export const PAYOUT_TRANSACTIONS_PAGE_SET_NEXT = `${PAYOUT_TRANSACTIONS_PAGE}:NEXT`;
