import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SettingsCompanyNavigation } from 'components';

import NavigationService from 'services/navigationService';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { EditIcon } from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as COMPANY_ACTIONS from 'store/actions/company';
import { companySettingsLoadedSelector } from 'store/selectors/company';
import { Content } from 'components/Common/Content';
import routes from './scenes';

import * as styles from './page.module.scss';

const Company = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { shops } = useSelector(({ company }) => company);
    const companySettingsLoaded = useSelector(companySettingsLoadedSelector);

    const subNavigation = NavigationService.getSettingsCompanySubNavigation({ shops });

    useEffect(() => {
        dispatch(COMPANY_ACTIONS.getCompanySettings());
    }, [dispatch]);

    return (
        <React.Fragment>
            <TextSubHeader text={t('navigation.editCompany')} before={<EditIcon />} />
            <div className={styles.container}>
                <div className={styles.navigation}>
                    <SettingsCompanyNavigation
                        isDataLoaded
                        subRoutes={subNavigation}
                        withQuery={false}
                    />
                </div>
                <Content loading={!companySettingsLoaded}>
                    <div className={styles.content}>
                        <Switch>
                            {
                                routes.map(({ component, exact, path }) => (
                                    <Route
                                        key={path}
                                        component={component}
                                        exact={exact}
                                        path={path}
                                    />
                                ))
                            }

                            <Redirect to={routes[0].path} />
                        </Switch>
                    </div>
                </Content>
            </div>
        </React.Fragment>
    );
};

export default Company;
