import { clientsListSaga } from './clientsList';
import { clientItemSaga } from './clientItem';
import { clientBookingsSaga } from './clientTables/clientBookings';
import { clientBundlesSaga } from './clientTables/clientBundles';
import { clientSubscriptionSaga } from './clientTables/clientSubscriptions';
import { clientNotesSaga } from './clientTables/clientNotes';
import { clientAccessLogsSaga } from './clientTables/clientAccessLogs';
import { clientActivitySaga } from './clientTables/clientActivity';
import { clientInvoicesSaga } from './clientTables/clientInvoices';
import { clientEmailsSaga } from './clientTables/clientEmails';

export const clientsSaga = [
    ...clientsListSaga,
    ...clientItemSaga,
    ...clientBookingsSaga,
    ...clientBundlesSaga,
    ...clientSubscriptionSaga,
    ...clientNotesSaga,
    ...clientAccessLogsSaga,
    ...clientActivitySaga,
    ...clientInvoicesSaga,
    ...clientEmailsSaga,
];
