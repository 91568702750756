import {
    call, put, select, takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import { PAYOUT_TRANSACTION_TYPE } from 'const/payouts/PAYOUT_TRANSACTION_TYPE';
import { downloadFile } from '../../../helpers/file/downloadFile';
import { getDefaultHeaders } from '../../../helpers/http/getDefaultHeaders';
import * as PAYOUTS_TYPES from '../../actions/financials/payouts/types';
import * as PAYOUTS_ACTIONS from '../../actions/financials/payouts';
import * as FINANCIALS_SELECTORS from '../../selectors/financilas';

import * as TOAST_ACTIONS from '../../actions/toast';

import axios from '../../../services/axios';

import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_COMPANY_PAYOUT_LIST_ROUTE,
    API_ADMIN_COMPANY_PAYOUT_TRANSACTIONS_ROUTE,
    API_ADMIN_COMPANY_DOWNLOAD_PAYOUT,
} from '../../../const/API_URL';

function* getPayouts(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { page, sortOptions } = yield select(
        FINANCIALS_SELECTORS.payoutsListSelector,
    );

    try {
        yield put(PAYOUTS_ACTIONS.setPayoutsLoading({ loading: true }));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_PAYOUT_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page,
                ...sortOptions,
            },
        });

        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const { items, itemsCount, pagesCount } = result;
            const payouts = items.map((item) => camelize(item));
            yield put(
                PAYOUTS_ACTIONS.setPayouts({ items: payouts, itemsCount, pagesCount }),
            );
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'payouts.get.error',
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(PAYOUTS_ACTIONS.setPayoutsLoading({ loading: false }));
    }
}

function* downloadPayout(action) {
    const {
        payload: { payoutId, system },
    } = action;
    const isNula = system === 'nula';
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_DOWNLOAD_PAYOUT({ payoutId }),
            headers: defaultHeaders,
            ...(isNula ? {
                responseType: 'arraybuffer',
                responseEncoding: 'binary',
            } : {}),
        });

        const { data, status } = res;
        if (status === HTTP_STATUS_CODE.OK) {
            if (!isNula) {
                downloadFile({ url: data });
            } else {
                const url = window.URL.createObjectURL(
                    new Blob([data], { type: 'application/pdf' }),
                );
                downloadFile({ url });
            }
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'payouts.download.error',
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

function* getPayoutTransactions(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { payoutSelectedId, payoutTransactionsPage, payoutTransactionsType } = yield select(FINANCIALS_SELECTORS.payoutsListSelector);

    try {
        yield put(
            PAYOUTS_ACTIONS.setPayoutTransactionsLoading({
                payoutTransactionsLoading: true,
            }),
        );
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_PAYOUT_TRANSACTIONS_ROUTE({
                payoutId: payoutSelectedId,
            }),
            headers: defaultHeaders,
            params: {
                page: payoutTransactionsPage,
                type: payoutTransactionsType,
            },
        });

        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const { items, itemsCount, pagesCount } = result;
            const payoutTransactions = items.map((item) => {
                const transaction = camelize(item);
                switch (payoutTransactionsType) {
                case PAYOUT_TRANSACTION_TYPE.DEBIT:
                    return {
                        id: transaction.paymentId,
                        client: {
                            id: transaction.client.id,
                            firstName: transaction.client.firstName,
                            lastName: transaction.client.lastName,
                        },
                        subscriptionName: transaction.subscriptionName,
                        date: transaction.date,
                        paymentType: transaction.paymentType,
                        amount: transaction.profit,
                    };
                case PAYOUT_TRANSACTION_TYPE.PAYMENTS:
                case PAYOUT_TRANSACTION_TYPE.REFUNDS:
                default:
                    return {
                        id: transaction.paymentId,
                        client: {
                            id: transaction.client.id,
                            firstName: transaction.client.firstName,
                            lastName: transaction.client.lastName,
                        },
                        resourceName: transaction.resourceName,
                        serviceName: transaction.serviceName,
                        date: transaction.date,
                        serviceDate: transaction.serviceDate,
                        paymentType: transaction.paymentType,
                        amount: transaction.profit,
                    };
                }
            });
            yield put(
                PAYOUTS_ACTIONS.setPayoutTransactions({
                    items: payoutTransactions,
                    itemsCount,
                    pagesCount,
                }),
            );
        }
    } catch (error) {
    // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(
                TOAST_ACTIONS.showToast({
                    message: 'payouts.transactions.get.error',
                    appearance: 'error',
                }),
            );
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(
            PAYOUTS_ACTIONS.setPayoutTransactionsLoading({
                payoutTransactionsLoading: false,
            }),
        );
    }
}

export const payoutsListSaga = [
    takeLatest(PAYOUTS_TYPES.PAYOUTS_GET, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_DOWNLOAD_PAYOUT, downloadPayout),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_PAGE_SET, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_PAGE_SET_FIRST, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_PAGE_SET_LAST, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_PAGE_SET_NEXT, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_PAGE_SET_PREV, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUTS_SORT_OPTIONS_SET, getPayouts),
    takeLatest(PAYOUTS_TYPES.PAYOUT_SELECTED_ID_SET, getPayoutTransactions),
    takeLatest(PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_TYPE_SET, getPayoutTransactions),
    takeLatest(PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET, getPayoutTransactions),
    takeLatest(
        PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_FIRST,
        getPayoutTransactions,
    ),
    takeLatest(
        PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_LAST,
        getPayoutTransactions,
    ),
    takeLatest(
        PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_PREV,
        getPayoutTransactions,
    ),
    takeLatest(
        PAYOUTS_TYPES.PAYOUT_TRANSACTIONS_PAGE_SET_NEXT,
        getPayoutTransactions,
    ),
];
