import {
    put, call, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import camelize from 'camelize';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'const/time/DEFAULT_TIME_FORMAT';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import sentry from '../../services/sentry';

// Actions
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';

// Utils
import axios from '../../services/axios';
import { convertWorkBreaks } from '../../helpers';

export function* getEmployees(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/product/employee`,
            headers: defaultHeaders,
        });

        const employees = Object.values(resp.data.result).map((
            {
                id,
                name,
                disabled_days: disabledDays,
                work_breaks: workBreaks,
                time_blocks: timeBlocks,
                has_own_schedule: hasOwnSchedule,
                working_days: workingDays,
                special_days: specialDays,
                photo_url: image,
                services,
                last_seen: lastSeen,
            },
        ) => ({
            id,
            name,
            image,
            disabledDays: disabledDays.map((day) => (day + 1) % 7),
            workBreaks,
            timeBlocks,
            hasOwnSchedule,
            lastSeen,
            workingDays,
            specialDays: specialDays.reduce((acc, day) => {
                const currentDay = moment(day.date, 'DD/MM');
                const openingTime = moment.utc(day.opening_time);
                const closingTime = moment.utc(day.closing_time);
                return {
                    ...acc,
                    [currentDay.format(DEFAULT_DATE_FORMAT)]: {
                        isWorkingDay: day.is_working_day,
                        from: openingTime.format('HH:mm'),
                        to: closingTime.format('HH:mm'),
                    },
                };
            }, {}),
            services: camelize(services).map((service) => ({
                id: service.id,
                name: service.name,
                description: service.description,
                length: service.minutes,
                price: service.price,
                category: service.category,
                bookableType: service.bookableType,
                minutes: service.minutes,
                bookingFrequency: service.bookingFrequency,
                subOptions: service.subOptions ?? [],
                restrictUnpaidBookings: service.restrictUnpaidBookings,
                archived: service.archived,
            })),
        }));

        yield put(actions.getEmployeesSucceeded(employees));
    } catch (err) {
        yield put(actions.getEmployeesFailed());
    }
}

export function* editEmployee(action) {
    const { employee } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const hasOwnSchedule = employee?.hasOwnSchedule && Object.values(employee.schedule).some(({ from, to }) => from && to);
        const hasWorkBreaks = employee?.hasWorkBreaks && Object.values(employee.workBreaks).some((array) => !!array.length);

        const data = new FormData();
        data.append('productId', employee.id);
        data.append('image', employee.image);
        data.append('firstName', employee.firstName);
        data.append('lastName', employee.lastName);
        data.append('description', employee.description);
        data.append('timeslotsRecalc', employee.isTimeslotRecalc ? 1 : 0);
        if (employee.services.length) {
            employee.services.map(({ id }, i) => data.append(`services[${i}]`, id));
        }
        data.append('makeLogin', (Number(employee.canLogin)).toString());
        if ((Number(employee.canLogin))) {
            data.append('employeeType', employee.employeeType);
        }
        if (employee.futureBooking) { // future bookings in weeks
            data.append('futureBookingsInDays', employee.futureBooking);
        }
        data.append('email', employee.email);
        data.append('phone', employee.contactNumber);
        data.append('sendWelcome', (Number(employee.sendWelcome)).toString());
        data.append('hasOwnSchedule', (Number(hasOwnSchedule)).toString());
        if (hasOwnSchedule) {
            Object.entries(employee.schedule).map(([day, schedule]) => {
                if (!schedule || !schedule.from || !schedule.to) {
                    return;
                }

                data.append(`schedule[${day.toLowerCase()}][from]`, schedule.from.unix());
                data.append(`schedule[${day.toLowerCase()}][to]`, schedule.to.unix());
            });
        }
        data.append('hasWorkBreaks', (hasWorkBreaks ? 1 : 0).toString());
        if (hasWorkBreaks) {
            Object.entries(employee.workBreaks).map(([day, workBreaks]) => {
                if (!workBreaks) {
                    return;
                }

                workBreaks.map(({ from, to }, i) => {
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][from]`, from.unix());
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][to]`, to.unix());
                });
            });
        }
        data.append('canEditOwnAgenda', (Number(employee.canEditOwnAgenda)).toString());
        data.append('canOpenServices', (Number(employee.canOpenServices)).toString());
        data.append('canOpenClients', (Number(employee.canOpenClients)).toString());
        data.append('canOpenMarketing', (Number(employee.canOpenMarketing)).toString());
        data.append('canOpenFinancials', (Number(employee.canOpenFinancials)).toString());
        data.append('canOpenAccessLogs', (Number(employee.canOpenAccessLogs)).toString());
        data.append('canOpenCompanySettings', (Number(employee.canOpenCompanySettings)).toString());

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/edit/employee`,
            data,
            headers: defaultHeaders,
        });

        const newEmployee = { ...employee };
        // convert {Moday: [obj, obj], Tuesday: [obj]} => [obj, obj]
        if (Object.keys(newEmployee.workBreaks).length > 0) {
            newEmployee.workBreaks = convertWorkBreaks(newEmployee.workBreaks);
        }

        newEmployee.workingDays = newEmployee?.hasOwnSchedule && Object.entries(newEmployee.schedule).reduce((breaks, [day, item]) => {
            if (item?.from && item?.to) {
                return {
                    ...breaks,
                    [day.toLowerCase()]: {
                        from: item.from.format('HH:mm'),
                        to: item.to.format('HH:mm'),
                    },
                };
            }
            return breaks;
        }, {});

        delete newEmployee.schedule;

        yield put(actions.editEmployeeSucceeded(newEmployee));

        yield put(actions.getEmployees());

        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;

            const customErrorWorkBreak = Object.keys(errors).find((error) => error.toLowerCase().includes('workbreaks'));
            const customErrorSchedule = Object.keys(errors).find((error) => error.toLowerCase().includes('schedule'));

            yield put(actions.editEmployeeFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    image: errors.image,
                    name: errors.name,
                    description: errors.description,
                    isMaintenanceMode: errors.maintenanceMode,
                    services: errors.services,
                    email: errors.email,
                    contactNumber: errors.phone,
                    sendWelcome: errors.sendWelcome,
                    schedule: errors.hasOwnSchedule || errors.schedule || errors[customErrorSchedule],
                    workBreaks: errors.hasWorkBreaks || errors.workBreaks || errors[customErrorWorkBreak],
                },
            });
            return;
        }
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.editEmployeeFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.editEmployeeFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* addEmployee(action) {
    const { employee } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const hasOwnSchedule = employee?.hasOwnSchedule && Object.values(employee.schedule).some(({ from, to }) => from && to);
        const hasWorkBreaks = employee?.hasWorkBreaks && Object.values(employee.workBreaks).some((array) => !!array.length);

        const data = new FormData();
        data.append('image', employee.image);
        data.append('firstName', employee.firstName);
        data.append('lastName', employee.lastName);
        data.append('description', employee.description);
        if (employee.services.length) {
            employee.services.map(({ id }, i) => data.append(`services[${i}]`, id));
        }
        data.append('timeslotsRecalc', employee.isTimeslotRecalc ? 1 : 0);
        data.append('makeLogin', (Number(employee.canLogin)).toString());
        if ((Number(employee.canLogin))) {
            data.append('employeeType', employee.employeeType);
        }
        if (employee.futureBooking) { // future bookings in weeks
            data.append('futureBookingsInDays', employee.futureBooking);
        }
        data.append('email', employee.email);
        data.append('phone', employee.contactNumber);
        data.append('sendWelcome', (Number(employee.sendWelcome)).toString());
        data.append('hasOwnSchedule', (Number(hasOwnSchedule)).toString());
        if (hasOwnSchedule) {
            Object.entries(employee.schedule).map(([day, schedule]) => {
                if (!schedule || !schedule.from || !schedule.to) {
                    return;
                }

                data.append(`schedule[${day.toLowerCase()}][from]`, schedule.from.unix());
                data.append(`schedule[${day.toLowerCase()}][to]`, schedule.to.unix());
            });
        }
        data.append('hasWorkBreaks', (hasWorkBreaks ? 1 : 0).toString());
        if (hasWorkBreaks) {
            Object.entries(employee.workBreaks).map(([day, workBreaks]) => {
                if (!workBreaks) {
                    return;
                }

                workBreaks.map(({ from, to }, i) => {
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][from]`, from.unix());
                    data.append(`workBreaks[${day.toLowerCase()}][${i}][to]`, to.unix());
                });
            });
        }
        data.append('canEditOwnAgenda', (Number(employee.canEditOwnAgenda)).toString());
        data.append('canOpenServices', (Number(employee.canOpenServices)).toString());
        data.append('canOpenClients', (Number(employee.canOpenClients)).toString());
        data.append('canOpenMarketing', (Number(employee.canOpenMarketing)).toString());
        data.append('canOpenFinancials', (Number(employee.canOpenFinancials)).toString());
        data.append('canOpenAccessLogs', (Number(employee.canOpenAccessLogs)).toString());
        data.append('canOpenCompanySettings', (Number(employee.canOpenCompanySettings)).toString());

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/add/employee`,
            data,
            headers: defaultHeaders,
        });

        const result = camelize(resp.data.result);

        employee.id = result.id;
        employee.name = result.name;
        employee.disabledDays = [];
        employee.timeBlocks = [];

        // convert {Moday: [obj, obj], Tuesday: [obj]} => [obj, obj]

        const newEmployee = { ...employee };

        if (Object.keys(newEmployee.workBreaks).length > 0) {
            newEmployee.workBreaks = convertWorkBreaks(newEmployee.workBreaks);
        }

        newEmployee.workingDays = newEmployee?.hasOwnSchedule && Object.entries(newEmployee.schedule).reduce((breaks, [day, item]) => {
            if (item?.from && item?.to) {
                return {
                    ...breaks,
                    [day.toLowerCase()]: {
                        from: item.from.format('HH:mm'),
                        to: item.to.format('HH:mm'),
                    },
                };
            }
            return breaks;
        }, {});

        yield put(actions.addEmployeeSucceeded(newEmployee));

        yield put(actions.getEmployees());

        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.result && err.response.data.result.errors) {
            const { errors } = err.response.data.result;

            const customErrorWorkBreak = Object.keys(errors).find((error) => error.toLowerCase().includes('workbreaks'));
            const customErrorSchedule = Object.keys(errors).find((error) => error.toLowerCase().includes('schedule'));

            yield put(actions.addEmployeeFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    image: errors.image,
                    firstName: errors.firstName,
                    lastName: errors.lastName,
                    description: errors.description,
                    services: errors.services,
                    canLogin: errors.makeLogin,
                    email: errors.email,
                    contactNumber: errors.phone,
                    sendWelcome: errors.sendWelcome,
                    schedule: errors.hasOwnSchedule || errors.schedule || errors[customErrorSchedule],
                    workBreaks: errors.hasWorkBreaks || errors.workBreaks || errors[customErrorWorkBreak],
                },
            });
            return;
        } if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.addEmployeeFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }
        sentry.error(err);

        yield put(actions.addEmployeeFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* deleteEmployee(action) {
    const id = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/delete/${id}`,
            headers: defaultHeaders,
        });

        yield put(actions.deleteEmployeeSucceeded(id));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.deleteEmployeeFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.deleteEmployeeFailed());
        yield call(rejectPromiseAction, action);
    }
}

export const employeesSaga = [
    takeLatest(actionTypes.GET_EMPLOYEES, getEmployees),
    takeEvery(actions.addEmployee, addEmployee),
    takeEvery(actions.editEmployee, editEmployee),
    takeEvery(actions.deleteEmployee, deleteEmployee),
];
