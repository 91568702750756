import React, { useEffect } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import classNames from 'classnames';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { ClockIcon, Time } from '../Icon/Icon';
import { BookingPaymentType, Feedback, GroupBooking } from '..';
import { getFullName } from '../../helpers/services/getFullName';

// Utils

const BookingsListModal = ({
    hour, show, granularity, onHide, dataType = 'objects', onBookingClick, onGroupSessionClick,
}) => {
    const { locale } = useSelector((state) => state.locales);

    const bookings = useSelector((state) => {
        if (dataType === 'groups') {
            return (state.groups.sessions || [])
                .filter(({ time: { from } }) => moment(from).startOf(granularity).isSame(hour, granularity))
                .filter((product) => !!state[dataType].filters[product.product?.id] || !product.product?.id)
                .sort(({ time: { from: a } }, { time: { from: b } }) => a - b);
        }

        return state[dataType] && state[dataType].bookings && state[dataType].bookings
            .filter((booking) => state[dataType]?.filters && state[dataType]?.filters[booking.product.id])
            .filter(({ time: { from } }) => moment(from).startOf(granularity).isSame(hour, granularity))
            .sort(({ time: { from: a } }, { time: { from: b } }) => a - b);
    });

    useEffect(() => {
        if (!bookings?.length) {
            onHide();
        }
    }, [bookings?.length]);

    return (
        <Modal scrollable size="lg" show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className="mb-0 font-weight-600">
                    {hour && hour.clone().locale(locale).format('ddd, DD MMM YYYY')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {bookings?.map((booking) => {
                    if (dataType !== 'objects' && dataType !== 'employees') {
                        return (
                            <GroupBooking
                                key={`booking-${booking.id}`}
                                booking={booking}
                                collapseText={false}
                                onClick={onGroupSessionClick}
                            />
                        );
                    }

                    const {
                        id,
                        service,
                        payment: {
                            finalCost,
                            isInProgress,
                            paid,
                        },
                        time: {
                            from,
                            to,
                        },
                        client,
                        notPresent,
                    } = booking;

                    return (
                        <Row key={`booking-${id}`} className="mb-4">
                            <Col xs={3}>
                                <p className="d-flex flex-column flex-wrap flex-md-row align-items-start align-items-md-center text-muted">
                                    <span className="text-primary font-weight-bold font-size-20">{moment(from).format('HH:mm')}</span>
                                    <span className="ml-0 ml-md-1">{` - ${moment(to).format('HH:mm')}`}</span>
                                </p>
                                {booking?.feedback && (
                                    <Feedback
                                        feedback={booking.feedback}
                                        size={26}
                                        classNames="d-block mt-2"
                                    />
                                )}
                            </Col>

                            <Col
                                xs={9}
                                className={classNames({
                                    'border-success': paid,
                                    'border-danger': !paid,
                                    'border-dark': notPresent,
                                    'border-info': isInProgress,
                                }, 'border-left border-5 cursor-pointer')}
                                onClick={() => onBookingClick({ id, timestamp: from })}
                            >
                                <div className="d-flex justify-content-between">
                                    <p className="mb-1">
                                        <span className="font-weight-bold text-break mr-2">{service.name}</span>
                                        <span className="text-dark-gray">{booking.product.name}</span>
                                    </p>
                                    <div className="d-flex align-items-center">
                                        {booking?.payment.type && (
                                            <BookingPaymentType
                                                className="text-muted ml-2"
                                                type={booking.payment.type}
                                                width={16}
                                            />
                                        )}
                                        {booking.recurrence?.enabled && (
                                            <Time
                                                width={14}
                                                height={14}
                                                className="text-muted ml-2"
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex mx-n2 mb-1">
                                    <span className="text-muted font-size-14 font-weight-bold mx-2">
                                        <ClockIcon width={16} className="mr-2" />
                                        {`${moment.duration(moment(to).diff(moment(from))).asMinutes()} min`}
                                    </span>
                                    <span className="text-muted font-size-14 font-weight-bold mx-2">
                                        {formatPrice.toEuroWithComma({ amount: finalCost })}
                                    </span>
                                </div>

                                <div className="d-flex mx-n2">
                                    <span className="font-size-14 font-weight-bold mx-2">{client.contactNumber}</span>
                                    <span className="text-muted font-size-14 font-weight-bold mx-2">{getFullName({ client })}</span>
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </Modal.Body>
        </Modal>
    );
};

export default BookingsListModal;
