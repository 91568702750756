import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ACCESS_SELECTORS from 'store/selectors/access';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { ACCESS_GATES_PAGE } from 'const/CLIENT_PATH';
import Container from 'components/Layout/Container/Container';
import { /* Dropdown, */ Form } from 'react-bootstrap';
import * as yup from 'yup';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
// import { InfoTooltip } from 'components/Common/InfoTooltip';
// import { Switch } from 'components';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import Select from 'react-select';
import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';
import { Button } from 'components/Common/Button';

import { GateStatusBlock } from 'components/Access/Gates/GateStatusBlock';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Content } from 'components/Common/Content';
import { getAccessGate, saveAccessGate, toggleStatusAccessGate } from 'store/actions';
// import ACCESS_GATES_DIRECTION from 'const/access/ACCESS_GATES_DIRECTION';
import { companySelector } from 'store/selectors/company';
// import { Panel } from 'components/Common/Panel';
import moment from 'moment';
// import { hoursFromToSchema } from 'helpers/validation/hoursFromToSchema';
// import GatesGeneralAccessRules from 'components/Access/Gates/GatesGeneralAccessRules/GatesGeneralAccessRules';
import * as SERVICES_ACTIONS from 'store/actions/services';
// import * as SERVICES_SELECTORS from 'store/selectors/services';
import { generateRandomString } from 'helpers/string/generateRandomString';
// import { GatesCustomAccessRules } from 'components/Access/Gates/GatesCustomAccessRules';
import { useMobile } from 'hooks/useMobile';
import { BackButton } from 'components/Common/BackButton';
import { ProfilesWindowedSelect } from 'components/Access/Gates/ProfilesWindowedSelect';
import * as styles from './page.module.scss';

const getAccessRuleInitialValue = (item, custom = false) => ({
    id: generateRandomString(),
    enabled: custom,
    dailyEntryLimit: 'unlimited',
    accessRule: 'booking',
    openingHours: item
        ? Object.fromEntries(
            Object.entries(item?.workingDays ?? {})
                .filter(([_, value]) => value.from && value.to)
                .map(([key, value]) => [
                    key,
                    {
                        from: moment().set({
                            hours: value.from.split(':')[0],
                            minutes: value.from.split(':')[1],
                        }),
                        to: moment().set({
                            hours: value.to.split(':')[0],
                            minutes: value.to.split(':')[1],
                        }),
                    },
                ]),
        )
        : {},
    workBreaksEnable: Boolean(item?.workBreaks?.length),
    workBreaks: item?.workBreaks ?? {},
    ...(custom ? {
        services: [],
        bundles: [],
        subscriptions: [],
    } : {}),
});

// const getAccessRuleValidationSchema = () => yup.object({
//     openingHours: yup.object({
//         Monday: hoursFromToSchema().default(undefined),
//         Tuesday: hoursFromToSchema().default(undefined),
//         Wednesday: hoursFromToSchema().default(undefined),
//         Thursday: hoursFromToSchema().default(undefined),
//         Friday: hoursFromToSchema().default(undefined),
//         Saturday: hoursFromToSchema().default(undefined),
//         Sunday: hoursFromToSchema().default(undefined),
//     }),
//     workBreaks: yup.object({
//         Monday: yup.array(hoursFromToSchema().default(undefined)),
//         Tuesday: yup.array(hoursFromToSchema().default(undefined)),
//         Wednesday: yup.array(hoursFromToSchema().default(undefined)),
//         Thursday: yup.array(hoursFromToSchema().default(undefined)),
//         Friday: yup.array(hoursFromToSchema().default(undefined)),
//         Saturday: yup.array(hoursFromToSchema().default(undefined)),
//         Sunday: yup.array(hoursFromToSchema().default(undefined)),
//     }),
// });

const GateEdit = () => {
    const dispatch = useDispatch();
    const { gateId } = useParams();
    const history = useHistory();
    const isMobile = useMobile();

    useEffect(() => {
        if (!isNaN(gateId)) {
            dispatch(getAccessGate({ gateId }));
            dispatch(SERVICES_ACTIONS.getCompanyServices());
        }
    }, [
        dispatch,
        gateId,
    ]);

    // const { items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const gate = useSelector(ACCESS_SELECTORS.accessGateSelector);
    const { shops } = useSelector(companySelector);

    const locations = useMemo(() => shops.map((shop) => ({
        label: shop.name,
        value: shop.id,
    })), [shops]);

    const { isSavingProcess, isLoading, item } = gate;

    const initialValues = useMemo(() => ({
        name: item?.name || '',
        status: item?.status,
        direction: item?.direction,
        location: { value: item?.shop?.id, label: item?.shop?.name },
        profiles: item?.profiles || [],
        general: getAccessRuleInitialValue(item),
        customs: [],
    }), [item]);

    const validationSchema = useMemo(() => yup.object({
        name: yup.string().required(tc('validationErrors.cannotBeEmpty', { name: t('gates.item.gateName.label') })),
        profiles: yup.array().of(yup.object({
            id: yup.string().required(),
            name: yup.string().required(),
        })),
        // general: getAccessRuleValidationSchema(),
        // customs: yup.array().of(getAccessRuleValidationSchema()),
    }), [t]);

    const handleDoneRedirect = useCallback(() => {
        history.push(ACCESS_GATES_PAGE);
    }, [history]);

    const onSubmit = useCallback((gate) => {
        const data = new FormData();
        data.append('name', gate.name);
        data.append('direction', gate.direction);
        data.append('location', gate.location.value);
        gate.profiles.forEach((profile, index) => {
            data.append(`profiles[${index}]`, profile.id);
        });

        // Object.entries(data.openingHours ?? []).forEach(([key, value]) => {
        //     gate.append(
        //         `periods[${key}]`,
        //         JSON.stringify({
        //             from: value.from.format('HH:mm'),
        //             to: value.to.format('HH:mm'),
        //         }),
        //     );
        // });

        // Object.entries(gate.workBreaks).forEach(([key, value]) => {
        //     data.append(`workBreaks[${key}]`, JSON.stringify(value));
        // });

        dispatch(saveAccessGate({ data, gateId: item.id, onDone: handleDoneRedirect }));
    }, [dispatch, item?.id]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const {
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        // setFieldTouched,
        setFieldValue,
    } = formik;

    const handleChangeLocation = useCallback((location) => {
        setFieldValue('location', {
            value: location.value,
            label: location.label,
        });
    }, []);

    const hasAnyChanges = useMemo(
        () => JSON.stringify(values) !== JSON.stringify(initialValues),
        [values, initialValues],
    );

    const onSubmitHandler = useCallback(debounce(() => {
        handleSubmit();
    }, 500), [handleSubmit]);

    const handleToggleStatus = () => {
        dispatch(toggleStatusAccessGate({ gateId: item.id }));
    };

    // const handleChangeGeneralAccessRule = (checked) => {
    //     handleChange({ target: { name: 'general.enabled', value: checked } });
    // };

    // const handleChangeCustomAccessRule = (index) => (checked) => {
    //     handleChange({ target: { name: `customs.[${index}].enabled`, value: checked } });
    // };

    // const handleDeleteCustomAccessRule = (id) => () => {
    //     const newCustoms = values.customs.filter((custom) => custom.id !== id);
    //     setFieldValue('customs', newCustoms);
    // };

    // const handleAddCustomAccessRule = () => {
    //     const { length } = values.customs || [];
    //     setFieldValue(`customs.[${length}]`, getAccessRuleInitialValue(null, true));
    // };

    return (
        <React.Fragment>
            <TextSubHeader
                before={(
                    <BackButton
                        href={ACCESS_GATES_PAGE}
                    />
                )}
                text={item?.name || ''}
            />
            <Container>
                <Content loading={isLoading}>
                    <Column stretched gap={32}>
                        <Form.Label className={styles.title}>
                            {t('gates.item.gateInformation.label')}
                        </Form.Label>
                        <Row gap={32} stretched align="start" wrap={isMobile}>
                            <Column stretched gap={32}>
                                <Column gap={12} stretched>
                                    <Form.Label htmlFor="name" className={styles.label}>
                                        {t('gates.item.gateName.label')}
                                    </Form.Label>
                                    <Form.Control
                                        id="name"
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Column>
                                <Column gap={12} stretched>
                                    <Form.Label htmlFor="location" className={styles.label}>
                                        {t('gates.item.location.label')}
                                    </Form.Label>
                                    <Select
                                        id="location"
                                        styles={selectStyles}
                                        theme={selectTheme}
                                        name="location"
                                        placeholder={t('gates.item.location.placeholder')}
                                        value={values.location}
                                        options={locations}
                                        onChange={handleChangeLocation}
                                    />
                                </Column>
                                <Column gap={12} stretched>
                                    <Form.Label htmlFor="customProfiles" className={styles.label}>
                                        {t('gates.item.customProfiles.label')}
                                    </Form.Label>
                                    <ProfilesWindowedSelect formik={formik} />
                                </Column>

                                {/* <Column gap={12}>
                                    <Form.Label htmlFor="direction" className={styles.label}>
                                        {t('gates.item.gateType.label')}
                                    </Form.Label>

                                    <Form.Check
                                        id={ACCESS_GATES_DIRECTION.IN}
                                        type="radio"
                                        name="direction"
                                        value={ACCESS_GATES_DIRECTION.IN}
                                        label={(
                                            <Form.Label htmlFor={ACCESS_GATES_DIRECTION.IN} className={styles.label}>
                                                {t('gates.item.gateType.in.label')}

                                            </Form.Label>
                                        )}
                                        checked={values.direction === ACCESS_GATES_DIRECTION.IN}
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        id={ACCESS_GATES_DIRECTION.OUT}
                                        type="radio"
                                        name="direction"
                                        value={ACCESS_GATES_DIRECTION.OUT}
                                        label={(
                                            <Form.Label htmlFor={ACCESS_GATES_DIRECTION.OUT} className={styles.label}>
                                                {t('gates.item.gateType.out.label')}

                                            </Form.Label>
                                        )}
                                        checked={values.direction === ACCESS_GATES_DIRECTION.OUT}
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        id={ACCESS_GATES_DIRECTION.IN_OUT}
                                        type="radio"
                                        name="direction"
                                        value={ACCESS_GATES_DIRECTION.IN_OUT}
                                        label={(
                                            <Form.Label htmlFor={ACCESS_GATES_DIRECTION.IN_OUT} className={styles.label}>
                                                {t('gates.item.gateType.inOut.label')}

                                            </Form.Label>
                                        )}
                                        checked={values.direction === ACCESS_GATES_DIRECTION.IN_OUT}
                                        onChange={handleChange}
                                    />
                                </Column> */}
                            </Column>
                            <Column stretched>
                                {values.status && <GateStatusBlock status={values.status} onToggle={handleToggleStatus} />}
                            </Column>
                        </Row>
                        {/* <Dropdown.Divider />
                        <Panel stretched padding={32} gap={32} color="secondary">
                            <Row gap={12}>
                                <Switch
                                    id="general.enabled"
                                    checked={values.general.enabled}
                                    onChange={handleChangeGeneralAccessRule}
                                />
                                <Form.Label className={styles.title} htmlFor="general.enabled">
                                    {t('gates.item.generalAccessRule.label')}
                                    <InfoTooltip
                                        text={t('gates.item.generalAccessRule.tooltip')}
                                        placement="top"
                                    />
                                </Form.Label>
                            </Row>
                            {
                                values.general.enabled && (
                                    <GatesGeneralAccessRules
                                        namespace="general"
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        namespaceValues={values.general}
                                        handleChange={handleChange}
                                        errors={errors}
                                    />
                                )
                            }
                        </Panel>
                        <Dropdown.Divider />
                        {values.customs.map((custom, index) => (
                            <div key={index}>
                                <Panel stretched padding={32} gap={32} color="secondary">
                                    <Row gap={12} stretched justify="between">
                                        <Row gap={12}>
                                            <Switch
                                                id={`customs[${index}].enabled`}
                                                checked={values.customs[index].enabled}
                                                onChange={handleChangeCustomAccessRule(index)}
                                            />
                                            <Form.Label
                                                className={styles.title}
                                                htmlFor={`customs[${index}].enabled`}
                                            >
                                                {t('gates.item.customAccessRule.label')}
                                                <InfoTooltip
                                                    text={t('gates.item.customAccessRule.tooltip')}
                                                    placement="top"
                                                />
                                            </Form.Label>
                                        </Row>
                                        <IconButton color="transparent" onClick={handleDeleteCustomAccessRule(custom.id)}>
                                            <Trash3Icon />
                                        </IconButton>
                                    </Row>
                                    {
                                        values.customs[index].enabled && (
                                            <GatesCustomAccessRules
                                                companyServices={companyServices}
                                                namespace={`customs[${index}]`}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                touched={touched}
                                                namespaceValues={values.customs[index]}
                                                handleChange={handleChange}
                                                errors={errors}
                                            />
                                        )
                                    }
                                </Panel>
                                <Dropdown.Divider />
                            </div>
                        ))}
                        <Row>
                            <Button color="outline" before={<Plus />} onClick={handleAddCustomAccessRule}>
                                {t('gates.action.addCustomRule.label')}
                            </Button>
                            <InfoTooltip
                                text={t('gates.action.addCustomRule.tooltip')}
                                placement="top"
                            />
                        </Row> */}
                    </Column>
                </Content>
            </Container>
            <div className={styles.spacer} />
            <div className={styles.actionsButtonsWrapper}>
                <Button
                    disabled={isSavingProcess || isLoading}
                    color="outline"
                    href={ACCESS_GATES_PAGE}
                >
                    {t('gates.action.cancel')}
                </Button>
                <Button
                    onClick={onSubmitHandler}
                    disabled={!hasAnyChanges}
                    loading={isSavingProcess || isLoading}
                >
                    {t('gates.action.save')}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default GateEdit;
