import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getClientAccessLogs = createPromiseAction(actionTypes.GET_CLIENTS_ACCESS_LOGS);

export const setAccessLogsSortOptions = ({ sortOptions }) => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_SORT_OPTIONS_SET,
    payload: { sortOptions },
});

export const showAllAccessLogs = () => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_SHOW_ALL,
});

export const setAccessLogsPage = ({ page }) => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_PAGE_SET,
    payload: { page },
});

export const setAccessLogsPageFirst = () => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_PAGE_SET_FIRST,
});

export const setAccessLogsPageLast = () => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_PAGE_SET_LAST,
});

export const setAccessLogsPagePrev = () => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_PAGE_SET_PREV,
});

export const setAccessLogsPageNext = () => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_PAGE_SET_NEXT,
});

export const clearAccessLogs = () => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_CLEAR,
});

export const setAccessLogsSearch = ({ search }) => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_SEARCH_SET,
    payload: { search },
});

export const setAccessLogsSelectedDate = ({ dateFrom, dateTo, period }) => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_SELECTED_DATE_SET,
    payload: { dateFrom, dateTo, period },
});

export const setAccessLogsFilter = ({ filter }) => ({
    type: actionTypes.CLIENTS_ACCESS_LOGS_FILTER_SET,
    payload: { filter },
});
