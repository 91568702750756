import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as MULTISAFE_PAYOUTS_TYPES from './types';

export const getMultisafePayouts = createPromiseAction(MULTISAFE_PAYOUTS_TYPES.GET_MULTISAFE_PAYMENTS);

export const setmultisafePayoutsPage = ({ page }) => ({
    type: MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET,
    payload: { page },
});

export const setmultisafePayoutsPageFirst = () => ({
    type: MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_FIRST,
});

export const setmultisafePayoutsPageLast = () => ({
    type: MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_LAST,
});

export const setmultisafePayoutsPagePrev = () => ({
    type: MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_PREV,
});

export const setmultisafePayoutsPageNext = () => ({
    type: MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_NEXT,
});
