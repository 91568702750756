import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatPrice } from 'helpers/formatters/formatPrice';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';

const MultisafePayoutsTableBodyRow = (props) => {
    const { payout } = props;
    const {
        transactionId,
        completed,
        amount,
    } = payout || {};
    const clearingDate = completed && moment(completed).isValid()
        ? moment(completed).format('DD MMM YYYY HH:mm')
        : '-';

    return (
        <React.Fragment>
            <ListTableBodyCell
                className="text-muted"
            >
                {transactionId}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
            >
                {clearingDate}
            </ListTableBodyCell>
            <ListTableBodyCell
                className="text-muted"
                align="right"
            >
                {formatPrice.toEuroWithComma({ amount: amount / 100 }) }
            </ListTableBodyCell>
        </React.Fragment>
    );
};

MultisafePayoutsTableBodyRow.propTypes = {
    payout: PropTypes.object,
};

MultisafePayoutsTableBodyRow.defaultProps = {
    payout: null,
};

export default MultisafePayoutsTableBodyRow;
