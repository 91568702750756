import { all } from 'redux-saga/effects';

import { appSaga } from './app';
import { authSaga } from './auth';
import { bundlesSaga } from './bundles';
import { bookingsSaga } from './bookings';
import { companySaga } from './company';
import { employeesSaga } from './employees';
import { eventsSaga } from './events';
import { groupsSaga } from './groups';
import { localesSaga } from './locales';
import { mapsSaga } from './maps';
import { categoriesSaga } from './categories';
import { objectsSaga } from './objects';
import { productsSaga } from './products';
import { servicesSaga } from './services';
import { shopSaga } from './shop';
import { subscriptionsSaga } from './subscriptions';
import { userSaga } from './user';
import { websocketSaga } from './websocket';
import { clientsSaga } from './clients';
import { financialsSaga } from './financials';
import { messagesSaga } from './messages';
import { accessSaga } from './access';
import { activitySaga } from './activity';
import { permissionsSaga } from './permissions';

export function* saga() {
    yield all([
        ...appSaga,
        ...accessSaga,
        ...authSaga,
        ...bundlesSaga,
        ...bookingsSaga,
        ...categoriesSaga,
        ...companySaga,
        ...employeesSaga,
        ...eventsSaga,
        ...groupsSaga,
        ...localesSaga,
        ...mapsSaga,
        ...objectsSaga,
        ...productsSaga,
        ...servicesSaga,
        ...shopSaga,
        ...subscriptionsSaga,
        ...userSaga,
        ...websocketSaga,
        ...clientsSaga,
        ...financialsSaga,
        ...messagesSaga,
        ...activitySaga,
        ...permissionsSaga,
    ]);
}
