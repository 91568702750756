import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import {
    Calendar, GroupDetailsModal, Timeline, BookingsListModal,
} from 'components';

// Utils
import moment from 'moment';
import { useMobile } from 'hooks/useMobile';
import { GROUP_DETAILS_MODAL_TAB } from 'const/group/GROUP_DETAILS_MODAL_TAB';

const Month = ({
    month, year, setMonth, setYear, getGroupSessionsHandler,
}) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const workingDays = useSelector((state) => isShopLoaded && state.shop.workingDays);

    const groupsSessions = useSelector((state) => state.groups.sessions);

    const [isModalVisible, setModalVisible] = useState(false);
    const [clickedDay, setClickedDay] = useState(null);
    const [clickedGroupSessionID, setClickedGroupSessionID] = useState(null);
    const [tab, setTab] = useState(GROUP_DETAILS_MODAL_TAB.SpotsBooked);

    const isMobile = useMobile();

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        getGroupSessionsHandler();
    }, [isShopLoaded, month, year]);

    return (
        <React.Fragment>
            {isMobile
                ? (
                    <Calendar
                        month={month}
                        year={year}
                        selectedDate={moment()}
                        products={groupsSessions}
                        workingDays={workingDays}
                        onSelectDate={(day) => {
                            setClickedDay(day);
                            setTab(GROUP_DETAILS_MODAL_TAB.SpotsBooked);
                            setModalVisible(true);
                        }}
                        onChangeTimespan={(month, year) => {
                            setMonth(month);
                            setYear(year);
                        }}
                    />
                ) : (
                    <Timeline
                        className="mt-4 px-0 d-grid"
                        month={month}
                        year={year}
                        view="month"
                        dataType="groups"
                        onClick={(day) => {
                            setClickedDay(day);
                            setModalVisible(true);
                        }}
                    />
                )}

            {clickedGroupSessionID && (
                <GroupDetailsModal
                    groupSessionIDWithTimeStamp={clickedGroupSessionID}
                    show={clickedGroupSessionID}
                    onHide={() => setClickedGroupSessionID(null)}
                    tab={tab}
                    onTabChange={setTab}
                />
            )}

            <BookingsListModal
                hour={clickedDay}
                show={isModalVisible && !clickedGroupSessionID}
                granularity="day"
                onHide={() => setModalVisible(false)}
                onGroupSessionClick={({ id, timestamp }) => setClickedGroupSessionID({ id, timestamp })}
                dataType="groups"
            />
        </React.Fragment>
    );
};

export default Month;
