import React, { useCallback } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Styles

// Utils
import classNames from 'classnames';
import moment from 'moment';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Image } from 'components/Common/Image/Image';
import { Badge } from 'components/Common/Badge';
import { Row } from 'components/Common/Row';
import { Column } from 'components/Common/Column';
import SubHeader from 'components/Common/Typography/SubHeader/SubHeader';
import { Label } from 'components/Common/Typography/Label';
import { useMobile } from 'hooks/useMobile';
import { getFullName } from 'helpers/services/getFullName';
import { Button } from 'components/Common/Button';
import { copyText } from 'helpers/string/copyText';
import { useToasts } from 'react-toast-notifications';
import * as styles from './Event.module.scss';
import {
    CreditCard, ClockIcon, Users, PinIcon, Box,
    LinkIcon,
} from '../Icon/Icon';

const Event = ({
    event, onClick, showParticipants = true,
}) => {
    const isMobile = useMobile();
    const { addToast } = useToasts();
    const eventParticipantsSeatsSum = event?.participants.reduce((acc, { seats }) => (acc + seats), 0);
    const { t } = useTranslation();

    if (!event) {
        return null;
    }
    const hasBadge = event.isCancelled || eventParticipantsSeatsSum === event.totalPlaces;

    const handleCopy = useCallback((e) => {
        e.stopPropagation();
        copyText(event.widgetUrl);
        addToast(t('event.link.copied'), {
            appearance: 'success',
            autoDismiss: true,
        });
    }, [addToast, t]);

    return (
        <Row
            stretched
            onClick={onClick}
            align="start"
            data-testid={`data-test-event-card-${moment(event.date).format('YYYY-MM-DD')}-${event.name}`}
            wrap={isMobile}
            gap={16}
        >
            <Column>
                <Image className={styles.image} src={event.image} alt={event?.name} />
            </Column>

            <Column stretched gap={16}>
                <Column gap={0}>
                    <Row gap={16}>
                        {event.name && (
                            <SubHeader lineThrough={event.isCancelled} weight="light" data-testid="data-test-event-card-name">
                                {event.name}
                            </SubHeader>
                        )}
                        {hasBadge && (
                            <Row gap={8}>
                                {event.isCancelled && (
                                    <Badge size="extra-small" color="red" data-testid="data-test-event-card-badge-cancelled">
                                        {t('event.cancelled')}
                                    </Badge>
                                )}
                                {eventParticipantsSeatsSum === event.totalPlaces && (
                                    <Badge size="extra-small" color="green" data-testid="data-test-event-card-badge-full">
                                        {t('event.full')}
                                    </Badge>
                                )}
                            </Row>
                        )}
                    </Row>
                    {event.subName && (
                        <Label weight="bold" color="gray" data-testid="data-test-event-card-subname">
                            {event.subName}
                        </Label>
                    )}

                    {event?.service?.name && (
                        <Label weight="bold" color="gray" data-testid="event-service">
                            {event.service.name}
                        </Label>
                    )}
                </Column>
                <Column gap={0}>
                    <Row gap={24}>
                        <Label weight="bold" color="gray">
                            <CreditCard className="mr-1" width={18} />
                            <span data-testid="data-test-event-card-price">
                                {formatPrice.toEuroWithComma({ amount: event.price })}
                            </span>
                        </Label>

                        <Label weight="bold" color="gray">
                            <ClockIcon className="mr-1" width={18} />
                            <span data-testid="data-test-event-card-time">
                                {t('event.start', { hour: moment(event.time.from).format('HH:mm') })}
                            </span>
                        </Label>

                        {showParticipants && (
                            <Label weight="bold" color="gray">
                                <Users className="mr-1" width={18} />
                                <span data-testid="data-test-event-card-places-available">
                                    {`${eventParticipantsSeatsSum}/${event.totalPlaces}`}
                                </span>
                            </Label>
                        )}
                    </Row>

                    <Label weight="bold" color="gray">
                        <PinIcon className="mr-1" width={18} />
                        <span data-testid="data-test-event-card-address">
                            {`${event.address.street}, ${event.address.zip} ${event.address.city}`}
                        </span>
                    </Label>
                </Column>
                {Boolean(showParticipants && event.widgetUrl) && (
                    <Button color="outline" before={<LinkIcon />} onClick={handleCopy} data-testid="data-test-event-card-copy-link">
                        {t('event.link.copy')}
                    </Button>
                )}
            </Column>

            {showParticipants && (
                <Column gap={0} align="end">
                    <ul className={styles.participants}>
                        {event.participants.slice(0, 4).map(({
                            client,
                            boughtWithBundle,
                            payment: {
                                paid,
                                isInProgress,
                                usedBundle,
                                finalCost,
                            },
                            notPresent,
                        }, i) => (
                            <li
                                key={`participant-${i}`}
                                className={classNames(styles.participant, {
                                    [styles.notPaid]: !isInProgress && !paid && finalCost,
                                    [styles.paid]: (!isInProgress && paid) || !finalCost,
                                    [styles.inProgress]: isInProgress && !usedBundle,
                                    [styles.notPresent]: notPresent,
                                })}
                            >
                                {boughtWithBundle && <Box className="text-muted" width={16} />}
                                {client && (
                                    <Label italic weight="light" noWrap ellipsis data-testid={`data-test-event-card-participant-${getFullName({ client })}`}>
                                        {getFullName({ client })}
                                    </Label>
                                )}
                            </li>
                        ))}
                    </ul>
                    {event.participants.length > 4 && (
                        <Label weight="bold" data-testid="data-test-event-card-more-participants">
                            {t('event.more', { count: event.participants.length - 4 })}
                        </Label>
                    )}
                </Column>
            )}
        </Row>
    );
};

export default Event;
