import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import NavigationService from 'services/navigationService';
import * as actions from 'store/actions';

import { HEADER } from 'const/e2e/TEST_ATTRIBUTES';
import { roleSelector } from 'store/selectors/auth';
import { LocalesDropdown } from 'components';
import { userAccessKeysSelector } from 'store/selectors/user';

function SettingsDropdownMenu() {
    const { role } = useSelector(roleSelector);
    const accessKeys = useSelector(userAccessKeysSelector);
    const settingsNavigation = NavigationService.getSettingsNavigation({ role, accessKeys });
    const hasSettingsNavigation = settingsNavigation.length > 0;
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { t } = useTranslation();

    return (
        <Dropdown.Menu>
            {hasSettingsNavigation && (
                <React.Fragment>
                    <Dropdown.Header>
                        <span className="font-size-14 font-weight-bold text-uppercase">
                            {t('dashboardRoute.settings')}
                        </span>
                    </Dropdown.Header>
                    {settingsNavigation.map(({ directsTo, translation }) => (
                        <Dropdown.Item key={directsTo} as={NavLink} to={directsTo}>
                            <span>{t(translation)}</span>
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                </React.Fragment>
            )}
            <Dropdown.Item
                data-testid={HEADER.logoutButtonSelector}
                onClick={() => {
                    dispatch(actions.signOut());
                    addToast(t('auth.signedOutSuccessfully'), {
                        appearance: 'success',
                    });
                }}
            >
                <span>{t('dashboardRoute.logout')}</span>
            </Dropdown.Item>
            <div style={{ padding: '12px 20px' }}>
                <LocalesDropdown />
            </div>
        </Dropdown.Menu>
    );
}

export default SettingsDropdownMenu;
