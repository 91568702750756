import React from 'react';

const ArrowSmallRight = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M8.66252 17.7071C8.272 17.3166 8.272 16.6834 8.66252 16.2929L12.9554 12L8.66252 7.70711C8.272 7.31658 8.272 6.68342 8.66252 6.29289C9.05305 5.90237 9.68621 5.90237 10.0767 6.29289L15.0767 11.2929C15.4673 11.6834 15.4673 12.3166 15.0767 12.7071L10.0767 17.7071C9.68621 18.0976 9.05305 18.0976 8.66252 17.7071Z" fill="currentColor" />
    </svg>

);

export default ArrowSmallRight;
