export const ACTIVITY_ACTION_TYPE = {
    BOOKED: 'booked',
    ENDED: 'ended',
    EDITED: 'edited',
    RESUMED: 'resumed',
    PAUSED: 'paused',
    PURCHASED: 'purchased',
    CANCELLED: 'cancelled',
    DELETED: 'deleted',
    REGISTERED: 'registered',
};
