// extracted by mini-css-extract-plugin
var _1 = "LogsOverviewInfo_container__-j8PJ";
var _2 = "LogsOverviewInfo_info__m5Hxy";
var _3 = "LogsOverviewInfo_labelError__hlFBw";
var _4 = "LogsOverviewInfo_labelGreen__kXfSY";
var _5 = "LogsOverviewInfo_period__LLEDM";
var _6 = "LogsOverviewInfo_summaryNumber__uV3JB";
var _7 = "LogsOverviewInfo_summaryText__Soc5r";
var _8 = "LogsOverviewInfo_title__KBl0+";
var _9 = "LogsOverviewInfo_userContainer__smLN6";
export { _1 as "container", _2 as "info", _3 as "labelError", _4 as "labelGreen", _5 as "period", _6 as "summaryNumber", _7 as "summaryText", _8 as "title", _9 as "userContainer" }
