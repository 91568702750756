import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Label } from 'components/Common/Typography/Label';
import NumberInput from 'components/Common/NumberInput';
import { CheckBox } from 'components/Common/CheckBox';
import { useMobile } from 'hooks/useMobile';
import {
    dangerTheme as selectDangerTheme,
    styles as selectStyles,
    theme as selectTheme,
} from '../../../../styles/select';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { BUNDLE_PROP } from '../../../../const/bundles/BUNDLE_PROP';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'addOrEdit.form.fields.validity';

const BundleValidityFormRow = (props) => {
    const {
        sold,
        values,
        validation,
        onChangeValidityUnit,
        onChangeValidityValue,
        onToggleValidityUnlimited,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const isMobile = useMobile();

    const timeUnitSelectTheme = validation?.validity?.unit?.isInvalid ? selectDangerTheme : selectTheme;

    const timeUnitValue = useMemo(() => ({
        value: values.validity.unit,
        label: t(`${T_PREFIX}.units.${values.validity.unit}`, {
            count: values.validity.value,
        }),
    }), [values.validity.unit, values.validity.value]);

    const timeUnitOptions = useMemo(() => (
        BUNDLE_PROP.VALIDITY.UNIT.ENUM.map((unit) => ({
            value: unit,
            label: t(`${T_PREFIX}.units.${unit}`, {
                count: values.validity.value,
            }),
        }))
    ), [t, values.validity.value]);

    return (
        <Row className="w-100">
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Label paddingBottom>
                        {t(`${T_PREFIX}.value.label`)}
                    </Label>
                    <NumberInput
                        name="validity.value"
                        value={values.validity.value}
                        disabled={values.validity.unlimited || sold}
                        isInvalid={validation?.validity?.value?.isInvalid}
                        onChange={onChangeValidityValue}
                        min={BUNDLE_PROP.VALIDITY.VALUE.MIN}
                        max={BUNDLE_PROP.VALIDITY.VALUE.MAX}
                        step={BUNDLE_PROP.VALIDITY.VALUE.STEP}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.validity?.value?.isInvalid,
                        })}
                    >
                        {validation?.validity?.value?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <Label paddingBottom>
                        {t(`${T_PREFIX}.unit.label`)}
                    </Label>
                    <Select
                        name="validity.unit"
                        value={timeUnitValue}
                        theme={timeUnitSelectTheme}
                        styles={selectStyles}
                        options={timeUnitOptions}
                        isDisabled={values.validity.unlimited || sold}
                        onChange={onChangeValidityUnit}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.validity?.unit?.isInvalid,
                        })}
                    >
                        {validation?.validity?.unit?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col
                xs={12}
                lg={4}
                className="m-0 p-0 align-self-start pt-4"
            >
                <Form.Group
                    as={Col}
                >
                    <CheckBox
                        id="validity.unlimited"
                        name="validity.unlimited"
                        label={t(`${T_PREFIX}.unlimited.label`)}
                        checked={values.validity.unlimited}
                        disabled={sold}
                        isInvalid={validation?.validity?.unlimited?.isInvalid}
                        onChange={onToggleValidityUnlimited}
                        paddingTop={!isMobile}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.validity?.unlimited?.isInvalid,
                        })}
                    >
                        {validation?.validity?.unlimited?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleValidityFormRow.propTypes = {
    sold: PropTypes.bool,
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onChangeValidityUnit: PropTypes.func,
    onChangeValidityValue: PropTypes.func,
    onToggleValidityUnlimited: PropTypes.func,
};

BundleValidityFormRow.defaultProps = {
    sold: false,
    validation: null,
    onChangeValidityUnit: emptyFunc,
    onChangeValidityValue: emptyFunc,
    onToggleValidityUnlimited: emptyFunc,
};

export default BundleValidityFormRow;
