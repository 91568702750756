import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import {
    Pen2Icon, Trash3Icon,
} from '../../../../../Icon/Icon';
import { Tooltip } from '../../../../../Common/Tooltip';

import { formatPrice } from '../../../../../../helpers/formatters/formatPrice';
import { emptyFunc } from '../../../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientBundlesTableBodyRow.module.scss';

const UNBREAKABLE_SPACE = '\xa0';

const INFINITY_SYMBOL = '∞';

const formatDate = ({ date }) => (
    Boolean(date) && moment.unix(date).format(`DD[${UNBREAKABLE_SPACE}]MMM[${UNBREAKABLE_SPACE}]YYYY`)
);

const T_PREFIX = 'clientBundlesTable.row';

const ClientBundlesTableBodyRow = (props) => {
    const {
        bundle,
        onEdit,
        onArchive,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.USER);
    const { t: tc, i18n } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const isEnded = bundle.isEnded || bundle.usages === bundle.maxUsages;

    const bookings = useMemo(() => {
        const { usages, maxUsages } = bundle || {};
        const unlimited = maxUsages === null;
        const maxString = unlimited ? INFINITY_SYMBOL : maxUsages;
        return `${usages}${UNBREAKABLE_SPACE}/${UNBREAKABLE_SPACE}${maxString}`.trim() || '';
    }, [bundle?.bookings]);

    const handleEdit = useCallback(() => {
        if (!bundle?.id) {
            return;
        }
        onEdit({ bundle });
    }, [onEdit, bundle]);

    const handleArchive = useCallback(() => {
        if (!bundle?.id) {
            return;
        }
        onArchive({ bundleId: bundle.id });
    }, [onArchive, bundle?.id]);

    const paymentType = `paymentTypes.${bundle?.paymentType.toLowerCase()}`;

    return (
        <ListTableBodyRow className={classNames(isEnded && styles.ended)}>
            <ListTableBodyCell className={styles.services}>
                {bundle?.services?.map?.((s) => (
                    <div key={s.id}>
                        <Tooltip
                            tooltip={s.name}
                            placement="top-start"
                        >
                            <span>
                                {s.name}
                            </span>
                        </Tooltip>
                        <br />
                    </div>
                ))}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <span className={styles.bundle}>
                    {bundle?.name}
                </span>
            </ListTableBodyCell>
            <ListTableBodyCell>
                {bookings}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {formatDate({ date: bundle?.dateTime })}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {formatDate({ date: bundle?.endsAt }) || INFINITY_SYMBOL}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {i18n.exists(paymentType) ? tc(paymentType) : (bundle?.paymentType || '-')}
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                {formatPrice.toEuroWithComma({ amount: bundle?.profit })}
            </ListTableBodyCell>
            <ListTableBodyCell>
                {!bundle.deleted && !isEnded && (
                    <Row gap={8}>
                        <Tooltip
                            tooltip={t(`${T_PREFIX}.actions.edit`)}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                color="gray"
                                disabled={!bundle?.id}
                                size={32}
                                onClick={handleEdit}

                            >
                                <Pen2Icon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            tooltip={t(`${T_PREFIX}.actions.archive`)}
                            placement="top"
                            forButton
                        >
                            <IconButton
                                color="gray"
                                disabled={!bundle?.id}
                                size={32}
                                onClick={handleArchive}

                            >
                                <Trash3Icon />
                            </IconButton>
                        </Tooltip>
                    </Row>
                )}
            </ListTableBodyCell>
        </ListTableBodyRow>
    );
};

ClientBundlesTableBodyRow.propTypes = {
    soldBundle: PropTypes.object,
    onEdit: PropTypes.func,
    onArchive: PropTypes.func,
};

ClientBundlesTableBodyRow.defaultProps = {
    soldBundle: null,
    onEdit: emptyFunc,
    onArchive: emptyFunc,
};

export default ClientBundlesTableBodyRow;
