import React from 'react';

// Components
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import { TimeUnitsWrapper } from 'components/agenda';
import { GROUPS_ROOT_PAGE } from 'const/CLIENT_URL';
import Add from '../EmployeesGroup/Add/Add';

const Employees = (
    {
        type,
        viewType,
        date,
        week,
        month,
        year,
        setDate,
        setCalendarsDate,
        setWeek,
        setMonth,
        setYear,
        setShowOptionsModal,
        NoProductComponent,
        isProductExist,
        isAllowed,
    },
) => (
    <Switch>
        <Route path="/agenda/employees/add" component={Add} />
        <Route path="/agenda/employees/edit/:id" component={Add} />
        <Route render={() => {
            if (isAllowed === 'never'
                || ((isAllowed === 'hasOwn' || isAllowed === 'hasAny') && !isProductExist)
            ) {
                return <Redirect to={GROUPS_ROOT_PAGE} />;
            }

            return NoProductComponent ?? (
                <TimeUnitsWrapper
                    type={type}
                    viewType={viewType}
                    date={date}
                    week={week}
                    month={month}
                    year={year}
                    setDate={setDate}
                    setCalendarsDate={setCalendarsDate}
                    setWeek={setWeek}
                    setMonth={setMonth}
                    setYear={setYear}
                    setShowOptionsModal={setShowOptionsModal}
                />
            );
        }}
        />
    </Switch>
);

export default Employees;
