import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { debounce } from 'lodash';
import * as styles from './TextButton.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    red: styles.red,
    green: styles.green,
};

const TextButton = (props) => {
    const className = useMemo(() => classNames(
        styles.button,
        colorClasses[props.color],
        props.capitalize && styles.capitalize,
        props.uppercase && styles.uppercase,
        props.noPadding && styles.noPadding,
        props.disabled && styles.disabled,
        props.href ? styles.link : '',
        props.strong ? styles.strong : '',
    ), [props.color, props.capitalize, props.uppercase, props.noPadding, props.disabled, props.href, props.strong]);

    const Content = useMemo(() => (
        <React.Fragment>
            {props.before && (
                <div className={styles.before}>
                    { props.before }
                </div>
            )}
            {props.children}
            {props.after && <span className={colorClasses[props.afterColor]}>{props.after}</span>}
        </React.Fragment>
    ), [props.before, props.children, props.after, props.afterColor]);

    if (props.download && props.href) {
        return (
            <a
                onClick={props.onClick}
                className={className}
                href={props.href}
                download={props.download}
                type={props.type}
                data-testid={props['data-testid']}
            >
                {Content}
            </a>
        );
    }

    if (props.href) {
        return (
            <Link
                onClick={props.onClick}
                className={className}
                to={props.href}
                target={props.newTab ? '_blank' : '_self'}
                type={props.type}
                data-testid={props['data-testid']}
            >
                {Content}
            </Link>
        );
    }

    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <button
            onClick={debouncedOnClick}
            className={className}
            type={props.type}
            data-testid={props['data-testid']}
        >
            {Content}
        </button>
    );
};

TextButton.propTypes = {
    color: PropTypes.string,
    after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    afterColor: PropTypes.string,
    capitalize: PropTypes.bool,
    uppercase: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    href: PropTypes.string,
    newTab: PropTypes.bool,
    noPadding: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    'data-testid': PropTypes.string,
    target: PropTypes.string,
    string: PropTypes.bool,
    download: PropTypes.string,
};

TextButton.defaultProps = {
    color: 'yellow',
    afterColor: 'black',
    capitalize: false,
    uppercase: false,
    noPadding: false,
    disabled: false,
    type: 'button',
    'data-testid': 'data-test-text-button',
};

export default TextButton;
