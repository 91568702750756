import * as actionTypes from '../actions/actionTypes';

const initialState = {
    apiKey: null,
    loaded: false,
    logo: null,
    name: null,
    shops: null,
    settingsShopErrors: {},
    subscriptionPaymentTypes: {},
    balance: null,
    nextClearingDate: null,
    cancellationFee: 0,
    timezone: 'Etc/GMT0',
    preferredPaymentSystem: null,

    companySettingsLoaded: false,
    companyQRCodeLoaded: false,

    qrCode: {
        en: null,
        nl: null,
        de: null,
    },
};

const mergeShops = (shops, newShops) => {
    const compare = (a, b) => a.id === b.id;

    const mergedShops = (newShops || []).map((newShop) => {
        const shop = (shops || []).find((s) => compare(s, newShop)) || {};
        return {
            ...shop,
            ...newShop,
        };
    });

    return mergedShops;
};

const transformSectionKeys = (sections) => Object.entries(sections).reduce(
    (acc, [key, value]) => ({
        ...acc,
        [`${key === 'schedule' ? 'agenda' : key}`]: value,
    }),
    {},
);

export const company = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.SIGN_IN_SUCCEEDED:
        const { companyAPIKey: apiKey } = payload;
        return {
            ...state,
            apiKey,
        };
    case `${actionTypes.GET_COMPANY_DETAILS}.TRIGGER`:
        return {
            ...state,
            loaded: false,
        };
    case actionTypes.GET_COMPANY_DETAILS_SUCCEEDED: {
        const {
            details: {
                logoUrl: logo,
                name,
                description,
                appColor,
                licenseLink,
                instagramLink,
                facebookLink,
                sections,
                shops,
                subscriptionPaymentTypes,
                balance,
                cancellationFee,
                isRequiredClientAddress,
                timezone,
                enableBusinessMode,
                accessEnabled,
                accessCardsEnabled,
                slug,
                preferredPaymentSystem,
                waitlistEnabled,
                showMultisafepayBanner,
                multisafepayLink,
            },
        } = payload;

        return {
            ...state,
            loaded: true,
            logo,
            name,
            description,
            appColor,
            licenseLink,
            instagramLink,
            facebookLink,
            sections: transformSectionKeys(sections),
            shops: mergeShops(state.shops, shops),
            subscriptionPaymentTypes,
            balance,
            nextClearingDate: balance.nextClearing.date,
            cancellationFee,
            isRequiredClientAddress,
            timezone,
            enableBusinessMode,
            accessEnabled: Boolean(accessEnabled),
            accessCardsEnabled: Boolean(accessCardsEnabled),
            slug,
            preferredPaymentSystem,
            waitlistEnabled: Boolean(waitlistEnabled),
            showMultisafepayBanner,
            multisafepayLink,
        };
    }
    case actionTypes.GET_COMPANY_DETAILS_FAILED: {
        return {
            ...state,
            loaded: false,
            logo: null,
            name: null,
            shops: null,
        };
    }
    case actionTypes.GET_COMPANY_SETTINGS_TRIGGER: {
        return {
            ...state,
            companySettingsLoaded: false,
        };
    }
    case actionTypes.GET_COMPANY_SETTINGS_RESOLVED: {
        const {
            logoUrl: logo,
            shops,
            ...settings
        } = payload.payload;

        return {
            ...state,
            ...settings,
            shops: mergeShops(state.shops, shops),
            logo,
            companySettingsLoaded: true,
            loaded: true,
        };
    }
    case actionTypes.GET_COMPANY_QR_CODE_TRIGGER: {
        return {
            ...state,
            companyQRCodeLoaded: false,
        };
    }
    case actionTypes.GET_COMPANY_QR_CODE_RESOLVED: {
        const { en, nl, de } = payload.payload;

        return {
            ...state,
            qrCode: {
                ...state.qrCode,
                en,
                nl,
                de,
            },
            companyQRCodeLoaded: true,
        };
    }
    case actionTypes.SET_COMPANY_SETTINGS_GENERAL_RESOLVED:
    case actionTypes.SET_COMPANY_SETTINGS_SHOP_RESOLVED:
    case actionTypes.SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_RESOLVED:
    case actionTypes.SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_RESOLVED: {
        return {
            ...state,
            isSavingProcess: false,
        };
    }
    case actionTypes.SET_COMPANY_SETTINGS_GENERAL_TRIGGER:
    case actionTypes.SET_COMPANY_SETTINGS_SHOP_TRIGGER:
    case actionTypes.SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_TRIGGER:
    case actionTypes.SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_TRIGGER: {
        return {
            ...state,
            isSavingProcess: true,
        };
    }
    case actionTypes.SET_COMPANY_SETTINGS_GENERAL_REJECTED:
    case actionTypes.SET_COMPANY_SETTINGS_SHOP_REJECTED:
    case actionTypes.SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_REJECTED:
    case actionTypes.SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_REJECTED: {
        return {
            ...state,
            isSavingProcess: false,
        };
    }
    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    case actionTypes.SET_COMPANY_SETTINGS_SHOP_ERRORS:
        const errors = payload.payload;

        return {
            ...state,
            settingsShopErrors: errors,
        };
    default:
        return state;
    }
};
