import { useSelector } from 'react-redux';
import { roleSelector } from 'store/selectors/auth';

export const useIsEmployee = () => {
    const { role } = useSelector(roleSelector);
    return role === 'employee';
};

export const useIsAdmin = () => {
    const { role } = useSelector(roleSelector);
    return role === 'admin' || role === 'company_owner';
};
