export const EMAILS_SORT_BY_TYPE = {
    ID: 'id',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    STATUS: 'status',
    SUBJECT: 'subject',
    MESSAGE_ID: 'messageId',
    REASON: 'reason',
    BOUNCE_TYPE: 'bounceType',
    SHOP: 'shop',
};
