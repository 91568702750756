import React from 'react';

import { ClientDataBlock } from 'components/clients/ClientPage/ClientDataBlock';
import { ProfileNula } from 'components/clients/ClientPage/ProfileNula';
import * as CLIENTS_SELECTORS from 'store/selectors/clients';
import * as COMPANY_SELECTORS from 'store/selectors/company';
import { useSelector } from 'react-redux';

import { Column } from 'components/Common/Column';

const ClientInfoTable = () => {
    const { item: client } = useSelector(CLIENTS_SELECTORS.clientItemSelector);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);

    return (
        <Column stretched gap={32}>
            <ClientDataBlock client={client} />
            {enableBusinessMode && <ProfileNula client={client} />}
        </Column>
    );
};

export default ClientInfoTable;
