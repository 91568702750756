import React from 'react';

const ArrowSmallLeft = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M15.2071 17.7071C15.5976 17.3166 15.5976 16.6834 15.2071 16.2929L10.9142 12L15.2071 7.70711C15.5976 7.31658 15.5976 6.68342 15.2071 6.29289C14.8166 5.90237 14.1834 5.90237 13.7929 6.29289L8.79289 11.2929C8.40237 11.6834 8.40237 12.3166 8.79289 12.7071L13.7929 17.7071C14.1834 18.0976 14.8166 18.0976 15.2071 17.7071Z" fill="currentColor" />
    </svg>

);

export default ArrowSmallLeft;
