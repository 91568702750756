import React, {
    useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { LogsTableHeader } from 'components/Access/Logs/LogsTableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { LogsTableBodyRow } from 'components/Access/Logs/LogsTableBodyRow';
import * as ACCESS_ACTIONS from 'store/actions/access';
import * as ACCESS_SELECTORS from 'store/selectors/access';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import LogsOverviewInfo from 'components/Access/Logs/LogsOverviewInfo/LogsOverviewInfo';
import { Column } from 'components/Common/Column';
import LogsListFilter from 'components/Access/Logs/LogsListFilter/LogsListFilter';
import useSearchParams from 'hooks/useSearchParams';
import GatesSelect from 'components/Access/Gates/GatesSelect/GatesSelect';
import { ACCESS_LOGS_SEARCH_PARAM_GATE_ID } from 'const/access/ACCESS_LOGS';

const COLUMNS_COUNT = 6;

const LogsList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);

    const [searchParams, setSearchParams] = useSearchParams();

    const handleChangeGate = (selected) => {
        setSearchParams({ [ACCESS_LOGS_SEARCH_PARAM_GATE_ID]: selected?.value });
    };

    const {
        page,
        items: logs,
        showAll,
        isLoading,
        itemsCount,
        pagesCount,
        itemsPerPage,
        search,
        sortOptions,
        date,
        filter,
        hasNewItems,
    } = useSelector(ACCESS_SELECTORS.accessLogsListSelector);

    const {
        item: itemStatistics,
        date: dateStatistics,
        isLoading: isLoadingStatistics,
    } = useSelector(ACCESS_SELECTORS.accessLogsStatisticsSelector);

    const [defaultSelectedGate, setDefaultSelectedGate] = useState(null);

    useEffect(() => {
        const gateId = searchParams.get(ACCESS_LOGS_SEARCH_PARAM_GATE_ID);
        if (gateId) {
            const run = async () => {
                const response = await new Promise((resolve, reject) => {
                    dispatch(ACCESS_ACTIONS.getAccessGate({ gateId }))
                        .then((gates) => resolve(gates))
                        .catch(reject);
                });

                if (response.gate) {
                    setDefaultSelectedGate({ value: response.gate.id, label: response.gate.name });
                }
            };
            run();
        }
    }, []);

    const handlePrevPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessLogsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessLogsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessLogsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessLogsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(ACCESS_ACTIONS.showAllAccessLogs());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessLogsPageFirst());
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(ACCESS_ACTIONS.setAccessLogsPage({ page: nextPage }));
    }, [dispatch]);

    const handleChangeSelectedDate = useCallback((date) => {
        dispatch(ACCESS_ACTIONS.setAccessLogsSelectedDate(date));
    }, [dispatch]);

    const handleChangeStatisticsSelectedDate = useCallback((date) => {
        dispatch(ACCESS_ACTIONS.settAccessStatisticsSelectedDate(date));
    }, [dispatch]);

    const handleChangeFilter = useCallback((nextFilter) => {
        dispatch(ACCESS_ACTIONS.setAccessLogsFilter({ filter: filter === nextFilter ? null : nextFilter }));
    }, [dispatch, filter]);

    const handleSearch = useCallback((nextSearch) => {
        dispatch(ACCESS_ACTIONS.setAccessLogsSearch({ search: nextSearch }));
    }, [dispatch]);

    const handleHasNewItemsRefresh = useCallback(() => {
        handleFirstPage();
        handleSearch('');
    }, [handleFirstPage, handleSearch]);

    useEffect(() => {
        dispatch(ACCESS_ACTIONS.getAccessStatistics({ gateId: searchParams.get(ACCESS_LOGS_SEARCH_PARAM_GATE_ID) }));
    }, [
        dispatch,
        dateStatistics,
        searchParams,
    ]);

    useEffect(() => {
        dispatch(ACCESS_ACTIONS.getAccessLogs({ gateId: searchParams.get(ACCESS_LOGS_SEARCH_PARAM_GATE_ID) }));
    }, [
        dispatch,
        filter,
        page,
        date,
        search,
        searchParams,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    useEffect(() => () => dispatch(ACCESS_ACTIONS.clearAccessLogs()), []);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('logs.title')}
                rightActions={(
                    <GatesSelect
                        placeholder={t('logs.action.allGates')}
                        onSelect={handleChangeGate}
                        defaultSelected={defaultSelectedGate}
                    />
                )}
            />
            <Container>
                <Column gap={32} stretched>
                    <LogsOverviewInfo
                        statistics={itemStatistics}
                        onChangeDate={handleChangeStatisticsSelectedDate}
                        date={dateStatistics}
                        loading={isLoadingStatistics}
                    />
                    <Column gap={32} stretched>
                        <LogsListFilter
                            searchValue={search}
                            onSearch={handleSearch}
                            onChangeDate={handleChangeSelectedDate}
                            date={date}
                            filter={filter}
                            onChangeFilter={handleChangeFilter}
                        />
                        <Content loading={isLoading}>
                            <Table
                                hasNewItems={hasNewItems}
                                onNewItemsClick={handleHasNewItemsRefresh}
                                footer={{
                                    page,
                                    showAll,
                                    itemsCount,
                                    pagesCount,
                                    columnsCount: COLUMNS_COUNT,
                                    itemsPerPage,
                                    onShowAll: handleShowAll,
                                    onPrevPage: handlePrevPage,
                                    onNextPage: handleNextPage,
                                    onLastPage: handleLastPage,
                                    onFirstPage: handleFirstPage,
                                    onShowPages: handleShowPages,
                                    onChangePage: handleChangePage,
                                }}
                            >
                                <TableHeader>
                                    <LogsTableHeader />
                                </TableHeader>
                                <TableBody>
                                    {logs?.map((log, i) => (
                                        <LogsTableBodyRow
                                            key={`${log.createdAt}_${i}`}
                                            log={log}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Content>
                    </Column>
                </Column>
            </Container>
        </React.Fragment>

    );
};

export default LogsList;
