import React from 'react';

const ArrowSmallLeftDouble = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7384 17.6123L12 12.5L16.7384 7.3877C17.0794 7.01975 17.6614 7.01975 18.0024 7.3877C18.3087 7.71817 18.3087 8.22883 18.0024 8.5593L14.35 12.5L18.0024 16.4407C18.3087 16.7712 18.3087 17.2818 18.0024 17.6123C17.6614 17.9802 17.0794 17.9802 16.7384 17.6123Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.7384 17.6123L6 12.5L10.7384 7.3877C11.0794 7.01976 11.6614 7.01975 12.0024 7.3877C12.3087 7.71817 12.3087 8.22883 12.0024 8.55931L8.34998 12.5L12.0024 16.4407C12.3087 16.7712 12.3087 17.2818 12.0024 17.6123C11.6614 17.9802 11.0794 17.9802 10.7384 17.6123Z" fill="currentColor" />
    </svg>
);

export default ArrowSmallLeftDouble;
