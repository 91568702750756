import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
    items: [],
    isDataLoaded: false,
    hideInactive: true,
    currentPage: 1,
    pagesCount: 0,
    itemsCount: 0,
    searchValue: '',
    statusFilter: [],
    idFilter: [],
    subscriptionsOptions: [],
    showAll: false,
    clientId: null,
    changeSoldSubscription: {
        newSubscriptionId: null,
        bookingsToCanceling: [],
    },
    cancelSoldSubscription: {
        subscriptionId: null,
        showCancelModal: false,
        showCancelWithBookingsModal: false,
        showSpinner: false,
        endDate: null,
        subscriptionBookings: [],
    },
    sellSubscription: {
        clients: [],
        subscriptions: [],
    },
    editSubscriptionUsages: {
        paymentStartDate: null,
        subscriptionId: null,
        showEditUsagesModal: false,
        usages: {},
        formInitialData: null,
    },
    subscriptionsStatus: null,
};

export const updateSubscriptionPaymentInfo = (subscription, paymentInfo) => {
    const {
        subscriptionStatus,
        unpaidCounter,
        paymentId,
        paymentStatus,
        isManuallySet,
    } = paymentInfo;
    const { payments } = subscription;

    const parsedPaymentStatus = paymentStatus !== 'open';
    const updatedPayments = payments.map((payment) => {
        if (payment.paymentId === paymentId) {
            return {
                ...payment,
                paymentStatus: parsedPaymentStatus,
                isManuallySet,
            };
        }

        return payment;
    });

    return ({
        ...subscription,
        subscriptionStatus,
        unpaidCounter,
        payments: updatedPayments,
    });
};

const getUpdatedWithUserInfoSoldSubscriptions = (soldSubscriptions, subscriptionUserInfo) => {
    const { clientId, clientIsBlocked } = subscriptionUserInfo;

    return soldSubscriptions.reduce((acc, soldSubscription) => {
        if (soldSubscription.clientId === clientId) {
            // eslint-disable-next-line no-param-reassign
            soldSubscription.clientIsBlocked = Boolean(clientIsBlocked);
        }

        return [...acc, soldSubscription];
    }, []);
};

export const soldSubscriptionsReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.RESET_SOLD_SUBSCRIPTIONS_REDUCER:
        return {
            ...initialState,
        };
    case actionTypes.SET_SOLD_IS_DATA_LOADED:
        const { value } = payload;

        return ({
            ...state,
            isDataLoaded: value,
        });
    case actionTypes.SET_SOLD_SUBSCRIPTIONS_LIST: {
        const {
            subscriptions,
            itemsCount,
            pagesCount,
            clientId,
        } = payload;
        const page = Math.min(state.page, (pagesCount ?? state.pagesCount) || 1);
        return {
            ...state,
            items: subscriptions,
            page,
            itemsCount: itemsCount ?? state.itemsCount,
            pagesCount: pagesCount ?? state.pagesCount,
            clientId: clientId ?? initialState.clientId,
        };
    }
    case actionTypes.SET_SOLD_SHOW_ALL:
        const { showAll } = payload;
        const page = showAll ? 0 : 1;

        return ({
            ...state,
            showAll,
            currentPage: page,
        });
    case actionTypes.SET_SOLD_CURRENT_PAGE:
        const { currentPage } = payload;

        return ({
            ...state,
            currentPage,
        });
    case actionTypes.SET_SOLD_SEARCH_VALUE:
        const { searchValue } = payload;

        return ({
            ...state,
            currentPage: state.currentPage && 1,
            searchValue,
        });
    case actionTypes.SET_ID_FILTER:
        const { idFilter } = payload;

        return ({
            ...state,
            currentPage: state.showAll ? 0 : 1,
            idFilter,
        });
    case actionTypes.SET_STATUS_FILTER:
        const { statusFilter } = payload;

        return ({
            ...state,
            currentPage: state.showAll ? 0 : 1,
            statusFilter: statusFilter ?? [],
        });
    case actionTypes.SET_SUBSCRIPTIONS_OPTIONS:
        const { subscriptionsOptions } = payload;
        return ({
            ...state,
            subscriptionsOptions,
        });
    case actionTypes.SET_SELL_SUBSCRIPTION_DATA:
        return ({
            ...state,
            sellSubscription: {
                ...state.sellSubscription,
                clients: payload.clients,
                subscriptions: payload.subscriptions,
            },
        });
    case actionTypes.RESET_SELL_SUBSCRIPTION:
        return ({
            ...state,
            sellSubscription: {
                clients: [],
                subscriptions: [],
            },
        });
    case actionTypes.SET_SOLD_SUBSCRIPTIONS_STATUS:
        const { subscriptionsStatus } = payload;

        return ({
            ...state,
            subscriptionsStatus,
        });
    case actionTypes.UPDATE_SUBSCRIPTION_PAYMENT_INFO:
        const {
            subscriptionPaymentInfo: {
                subscriptionId: sId,
                ...paymentInfo
            },
        } = payload;
        const { items: soldSubscriptions } = state;
        const [targetSubscription] = soldSubscriptions.filter(({ id }) => id === sId);
        const targetSubscriptionIndex = soldSubscriptions.findIndex(({ id }) => id === sId);
        const updatedSubscription = updateSubscriptionPaymentInfo(targetSubscription, paymentInfo);
        soldSubscriptions.splice(targetSubscriptionIndex, 1, updatedSubscription);

        return ({
            ...state,
        });
    case actionTypes.UPDATE_SUBSCRIPTION_USER_INFO:
        const {
            subscriptionUserInfo,
        } = payload;

        const updatedSoldSubscriptions = getUpdatedWithUserInfoSoldSubscriptions(state.items, subscriptionUserInfo);

        return ({
            ...state,
            items: updatedSoldSubscriptions,
        });
    case actionTypes.TOGGLE_CANCEL_SOLD_SUBSCRIPTION_MODAL:
        return ({
            ...state,
            cancelSoldSubscription: {
                ...state.cancelSoldSubscription,
                showCancelModal: payload.showCancelModal,
                subscriptionId: payload.subscriptionId,
                subscriptionStartDate: payload.subscriptionStartDate,
                nextSubscriptionDate: payload.nextSubscriptionDate,
                nextSubscriptionPrice: payload.nextSubscriptionPrice,
                payments: payload.payments,
                nextDebitDate: payload.nextDebitDate,
                subscriptionStatus: payload.subscriptionStatus,
                paymentType: payload.paymentType,
                price: payload.price,
            },
        });
    case actionTypes.TOGGLE_CANCEL_SOLD_SUBSCRIPTION_WITH_BOOKINGS_MODAL:
        return ({
            ...state,
            cancelSoldSubscription: {
                ...state.cancelSoldSubscription,
                showCancelWithBookingsModal: payload.showCancelWithBookingsModal,
                subscriptionId: payload.subscriptionId,
                paymentType: payload.paymentType,
            },
        });
    case actionTypes.SET_SOLD_SUBSCRIPTION_BOOKINGS:
        const { subscriptionBookings } = payload;
        return ({
            ...state,
            cancelSoldSubscription: {
                ...state.cancelSoldSubscription,
                subscriptionBookings,
            },
        });
    case actionTypes.SET_CANCEL_SOLD_SUBSCRIPTION_END_DATE:
        return ({
            ...state,
            cancelSoldSubscription: {
                ...state.cancelSoldSubscription,
                endDate: payload.endDate,
            },
        });
    case actionTypes.RESET_CANCEL_SOLD_SUBSCRIPTION:
        return ({
            ...state,
            cancelSoldSubscription: {
                showCancelModal: false,
                showCancelWithBookingsModal: false,
                endDate: null,
                subscriptionBookings: [],
            },
        });

    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    case actionTypes.OPEN_EDIT_SUBSCRIPTION_USAGES_MODAL:
        return ({
            ...state,
            editSubscriptionUsages: {
                ...state.editSubscriptionUsages,
                subscriptionId: payload.subscriptionId,
                paymentStartDate: payload.paymentStartDate,
                showEditUsagesModal: true,
            },
        });
    case actionTypes.SET_SUBSCRIPTION_USAGES_LIST:
        return ({
            ...state,
            editSubscriptionUsages: {
                ...state.editSubscriptionUsages,
                usages: payload.usages,
                formInitialState: payload.formInitialState,
            },
        });
    case actionTypes.RESET_EDIT_SUBSCRIPTION_USAGES:
        return ({
            ...state,
            editSubscriptionUsages: {
                subscriptionId: null,
                showEditUsagesModal: false,
                usages: {},
                formInitialState: null,
            },
        });
    case actionTypes.SHOW_BOOKINGS_TO_CANCELING_MODAL:
        return ({
            ...state,
            changeSoldSubscription: {
                newSubscriptionId: payload.newSubscriptionId,
                bookingsToCanceling: payload.bookings,
            },
        });
    case actionTypes.RESET_BOOKINGS_TO_CANCELING:
        return ({
            ...state,
            changeSoldSubscription: {
                ...state.changeSoldSubscription,
                bookingsToCanceling: [],
            },
        });
    case actionTypes.RESET_CHANGE_SOLD_SUBSCRIPTION:
        return ({
            ...state,
            changeSoldSubscription: {
                newSubscriptionId: null,
                bookingsToCanceling: [],
            },
        });
    case actionTypes.SET_SOLD_HIDE_INACTIVE:
        return ({
            ...state,
            hideInactive: payload.hideInactive,
        });
    default:
        return state;
    }
};
