import ACCESS_GATES_STATUS from 'const/access/ACCESS_GATES_STATUS';
import * as TYPES from '../../actions/access/actionTypes';

const INITIAL_STATE = {
    item: null,
    isLoading: false,
    isSavingProcess: false,
};

export const accessGateItemReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case TYPES.GET_ACCESS_GATE_RESOLVED: {
        const { gate } = payload;
        return {
            ...state,
            item: gate,
            isLoading: false,
        };
    }
    case TYPES.GET_ACCESS_GATE_TRIGGER: {
        return {
            ...state,
            item: null,
            isLoading: true,
        };
    }
    case TYPES.GET_ACCESS_GATE_REJECTED: {
        return {
            ...state,
            isLoading: false,
        };
    }
    case TYPES.SAVE_ACCESS_GATE_RESOLVED:
    case TYPES.SAVE_ACCESS_GATE_REJECTED: {
        return {
            ...state,
            isSavingProcess: false,
        };
    }
    case TYPES.SAVE_ACCESS_GATE_TRIGGER: {
        return {
            ...state,
            isSavingProcess: true,
        };
    }
    case TYPES.TOGGLE_STATUS_ACCESS_GATE_RESOLVED: {
        return {
            ...state,
            item: {
                ...state.item,
                status: state.item.status === ACCESS_GATES_STATUS.ACTIVE ? ACCESS_GATES_STATUS.INACTIVE : ACCESS_GATES_STATUS.ACTIVE,
            },
        };
    }
    default:
        return state;
    }
};
