import React from 'react';
import classNames from 'classnames';
import times from 'lodash/times';
import {
    bool, object, number, string,
} from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { roleSelector } from 'store/selectors/auth';
import { useMultisafe } from 'hooks/useMultisafe';
import NavigationService from '../../../services/navigationService';

import * as styles from './SubNavigationHorisontal.module.scss';

import { permissionsPresetSelector } from '../../../store/selectors/permissions';

function SubNavigationHorisontal(props) {
    const {
        isDataLoaded,
        parentRoute,
        subRoutes: predefinedSubRoutes = null,
        productsList,
        navClassNames,
        navLinksClassNames,
        navSubLinksClassNames,
    } = props;
    const { sections } = useSelector(({ company }) => company);
    const preset = useSelector(permissionsPresetSelector);
    const { role } = useSelector(roleSelector);
    const isMultisafe = useMultisafe();
    const subRoutes = predefinedSubRoutes
        || NavigationService.getSubNavigationByMainRoute(parentRoute, sections, preset, productsList, role, isMultisafe);
    const { t } = useTranslation();

    if (!isDataLoaded) {
        return (
            <Nav
                className="flex-column"
            >
                {
                    times(subRoutes.length, (index) => (
                        <div
                            key={`skeleton-section-${index}`}
                            style={{
                                padding: '1.157rem 0.5rem',
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 3,
                                borderBottomColor: 'transparent',
                            }}
                        >
                            <Skeleton height={16} width={75} />
                        </div>
                    ))
                }
            </Nav>
        );
    }

    return (
        <Nav className={classNames('flex-column', navClassNames)}>
            {
                subRoutes.map(({
                    directsTo, translation, exact, subRoutes,
                }) => (
                    <div key={directsTo}>
                        <Nav.Link
                            key={directsTo}
                            as={NavLink}
                            to={directsTo}
                            exact={exact}
                            className={navLinksClassNames}
                        >
                            {t(translation)}
                        </Nav.Link>

                        {subRoutes && (
                            <div className={classNames(styles.navSubLinksWrapper)}>
                                {subRoutes.map(({ directsTo, translation, exact }) => (
                                    <Nav.Link
                                        key={directsTo}
                                        as={NavLink}
                                        to={directsTo}
                                        exact={exact}
                                        className={classNames(
                                            styles.navSubLink,
                                            navSubLinksClassNames,
                                        )}
                                    >
                                        {t(translation)}
                                    </Nav.Link>
                                ))}
                            </div>
                        )}
                    </div>
                ))
            }
        </Nav>
    );
}

SubNavigationHorisontal.propTypes = {
    date: object,
    isDataLoaded: bool,
    parentRoute: string,
    shopId: number,
    withQuery: bool,
};

export default SubNavigationHorisontal;
