import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { Column } from 'components/Common/Column';
import { CornerRightLine } from 'components/Icon/Icon';
import { Header } from 'components/Common/Typography/Header';
import { Label } from 'components/Common/Typography/Label';
import { useMobile } from 'hooks/useMobile';
import { Row } from 'components/Common/Row';
import { CheckBox } from 'components/Common/CheckBox';
import * as styles from './ProfilesAccessRules.module.scss';
import ProfileSubscriptionSelect from '../ProfileSubscriptionSelect';
import ProfileBundleSelect from '../ProfileBundleSelect';

const ProfilesAccessRules = ({ formik }) => {
    const {
        values,
        setFieldValue,
        handleChange,
    } = formik;
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const isMobile = useMobile();

    const handleWalkInSchedule = useCallback((e) => {
        const { value } = e.target;
        setFieldValue('rule', value);
    }, []);

    const handleDeductUsageChange = useCallback((e) => {
        const { target } = e;
        const { checked } = target;
        setFieldValue('deductUsage', checked);
    }, [setFieldValue]);

    const walkInSchedule = values.rule === 'walk_in_match' || values.rule === 'walk_in_always';

    return (
        <Column gap={32} stretched>
            <Column gap={32} stretched>
                <Header after={(
                    <InfoTooltip
                        text={t('profiles.add.accessRules.tooltip')}
                        placement="top"
                    />
                )}
                >
                    {t('profiles.add.accessRules.label')}
                </Header>
                <Row stretched gap={32} wrap={isMobile}>
                    <ProfileSubscriptionSelect
                        namespace="accessRules.subscriptions"
                        namespaceValues={values.accessRules.subscriptions}
                        setFieldValue={setFieldValue}
                        touched={formik.touched}
                        errors={formik.errors}
                    />
                    <ProfileBundleSelect
                        namespace="accessRules.bundles"
                        namespaceValues={values.accessRules.bundles}
                        setFieldValue={setFieldValue}
                        touched={formik.touched}
                        errors={formik.errors}
                    />
                </Row>
                <CheckBox
                    id="deductUsage"
                    name="deductUsage"
                    checked={values.deductUsage}
                    onChange={handleDeductUsageChange}
                    label={t('profiles.add.accessRules.deductUsage.label')}
                    tooltip={t('profiles.add.accessRules.deductUsage.tooltip')}
                />
                <Column gap={12} stretched>
                    <Label>
                        {t('profiles.add.accessRules.accessRulesForBundlesAndSubscriptions.label')}
                    </Label>
                    <Form.Check
                        id="booking"
                        type="radio"
                        name="rule"
                        value="booking"
                        label={(
                            <Label
                                htmlFor="booking"
                                tooltip={t('profiles.add.accessRules.booking.tooltip')}
                            >
                                {t('profiles.add.accessRules.booking.label')}
                            </Label>
                        )}
                        checked={values.rule === 'booking'}
                        onChange={handleChange}
                    />
                    <Form.Check
                        id="walkInSchedule"
                        type="radio"
                        name="rule"
                        value="walk_in_match"
                        label={(
                            <Label
                                htmlFor="walkInSchedule"
                                tooltip={t('profiles.add.accessRules.walkIn.tooltip')}
                            >
                                {t('profiles.add.accessRules.walkIn.label')}
                            </Label>
                        )}
                        checked={walkInSchedule}
                        onChange={handleWalkInSchedule}
                    />
                </Column>
            </Column>
            {(walkInSchedule) && (
                <Column padding={32} stretched>
                    <div className={styles.cornerRightLine}>
                        <CornerRightLine />
                    </div>
                    <Column gap={12}>
                        <Label>
                            {t('profiles.add.accessRules.walkInSchedule.label')}
                        </Label>
                        <Form.Check
                            id="matchCompanyWorkTime"
                            type="radio"
                            name="walkInSchedule"
                            value="walk_in_match"
                            label={(
                                <Label
                                    htmlFor="matchCompanyWorkTime"
                                    tooltip={t('profiles.add.accessRules.walkInSchedule.matchCompanyWorkTime.tooltip')}
                                >
                                    {t('profiles.add.accessRules.walkInSchedule.matchCompanyWorkTime.label')}
                                </Label>
                            )}
                            checked={values.rule === 'walk_in_match'}
                            onChange={handleWalkInSchedule}
                        />
                        <Form.Check
                            id="alwaysOpen"
                            type="radio"
                            name="walkInSchedule"
                            value="walk_in_always"
                            label={(
                                <Label
                                    htmlFor="alwaysOpen"
                                    tooltip={t('profiles.add.accessRules.walkInSchedule.alwaysOpen.tooltip')}
                                >
                                    {t('profiles.add.accessRules.walkInSchedule.alwaysOpen.label')}
                                </Label>
                            )}
                            checked={values.rule === 'walk_in_always'}
                            onChange={handleWalkInSchedule}
                        />
                    </Column>
                </Column>
            )}
        </Column>
    );
};

export default ProfilesAccessRules;
