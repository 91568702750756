import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';

import * as PRODUCTS_ACTIONS from 'store/actions/products';
import * as INVOICES_ACTIONS from 'store/actions/financials/invoices';

import InvoicesAddEditFormFooter from 'components/Financials/Invoices/InvoicesAddEditFormFooter';
import InvoicesAddEditFormHeader from 'components/Financials/Invoices/InvoicesAddEditFormHeader';
import InvoicesInfoFormGroup from 'components/Financials/Invoices/InvoicesInfoFormGroup';
import { INVOICE_PROP } from 'const/financials/invoices/INVOICE_PROP';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import InvoiceProductsFormGroup from 'components/Financials/Invoices/InvoiceProductsFormGroup';
import { generateRandomString } from 'helpers/string/generateRandomString';
import { customInvoiceLoadingSelector, customInvoiceSelector, invoicesSavingSelector } from 'store/selectors/financilas';
import moment from 'moment';
import { INVOICE_STATUS } from 'const/financials/invoices/INVOICE_STATUS';
import InvoiceDraftDeleteDialog from 'components/Financials/Invoices/InvoiceDraftDeleteDialog';
import { debounce } from 'lodash';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { ConfirmationModal } from 'components';
import { Content } from 'components/Common/Content';
import { FINANCIALS_INVOICES_LIST_PAGE } from 'const/CLIENT_URL';

export const productInitialData = () => ({
    name: '',
    price: '',
    vatValue: INVOICE_PROP.VAT_RATE.DEFAULT,
    quantity: INVOICE_PROP.QUANTITY.DEFAULT,
    id: `new-${generateRandomString()}`,
});

function AddNewSubscription() {
    const { t: tc } = useTranslation();
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const [deleteInvoice, setDeleteInvoice] = useState(null);

    const { invoiceId } = useParams();

    const dispatch = useDispatch();
    const saving = useSelector(invoicesSavingSelector);
    const loading = useSelector(customInvoiceLoadingSelector);
    const customInvoice = useSelector(customInvoiceSelector);

    const formikInitialValues = useMemo(() => (customInvoice ? ({
        client: {
            clientId: customInvoice?.client.id,
            clientName: customInvoice?.client.firstName,
            clientLastName: customInvoice?.client.lastName,
            clientEmail: customInvoice?.client.email,
        },
        note: customInvoice?.note,
        issueDate: moment(), // moment.unix(customInvoice?.issueDate),
        dueDate: moment().add(2, 'weeks'), // moment.unix(customInvoice?.dueDate),
        status: customInvoice?.status,
        products: customInvoice?.customInvoiceProducts.map(({
            product, quantity, price, vatValue,
        }) => ({
            ...product, price, vatValue, quantity,
        })),
    }) : null), [customInvoice]);

    useEffect(() => {
        dispatch(INVOICES_ACTIONS.getCustomInvoice({ bill: invoiceId }));
        dispatch(PRODUCTS_ACTIONS.getProducts());
    }, [dispatch]);

    const history = useHistory();

    const validationSchema = useMemo(() => yup.object({
        client: yup.mixed().required(tc('validationErrors.cannotBeEmpty', {
            name: tc('addBookingModal.client'),
        })),
        note: yup.string()
            .trim()
            .max(
                INVOICE_PROP.NOTE.MAX_LENGTH,
                tc('validationErrors.maxLength', {
                    name: t('add.note.label'),
                    length: INVOICE_PROP.NOTE.MAX_LENGTH,
                }),
            ),
        issueDate: yup.date()
            .required(tc('validationErrors.cannotBeEmpty', {
                name: t('add.issueDate.label'),
            }))
            .max(yup.ref('dueDate'), tc('validationErrors.dateOrAfter')),
        dueDate: yup.date()
            .required(tc('validationErrors.cannotBeEmpty', {
                name: t('add.dueDate.label'),
            }))
            .min(yup.ref('issueDate'), tc('validationErrors.dateOrBefore')),
        products: yup.array()
            .of(yup.object({
                name: yup.string()
                    .trim()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.name.label'),
                    })),
                price: yup.number()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.price.label'),
                    })),
                vatValue: yup.string()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.vatValue.label'),
                    })),
                quantity: yup.number()
                    .required(tc('validationErrors.cannotBeEmpty', {
                        name: t('add.products.quantity.label'),
                    })),
            }))
            .min(1, tc('validationErrors.cannotBeEmpty', {
                name: t('add.products.label'),
            })),
    }), [t]);

    const handleEdit = useCallback((invoice) => {
        const data = new FormData();
        data.append('clientId', invoice.client.clientId);
        data.append('note', invoice.note);
        data.append('issueDate', invoice.issueDate.unix());
        data.append('dueDate', invoice.dueDate.unix());
        data.append('status', invoice.status === INVOICE_STATUS.UNPAID ? 'open' : invoice.status);

        const products = invoice.products.filter(({ id }) => !String(id).startsWith('new-'));
        const productsToCreate = invoice.products.filter(({ id }) => String(id).startsWith('new-'));
        if (products.length) {
            products.forEach((c, i) => data.append(`products[${i}]`, JSON.stringify({
                id: c.id, quantity: c.quantity, price: c.price, vatValue: c.vatValue,
            })));
        }
        if (productsToCreate.length) {
            productsToCreate.forEach((c, i) => data.append(`productsToCreate[${i}]`, JSON.stringify({
                name: c.name, quantity: c.quantity, price: c.price, vatValue: c.vatValue,
            })));
        }

        dispatch(INVOICES_ACTIONS.editCustomInvoice({ data, history, bill: invoiceId }));
    }, [dispatch, history]);

    const saveConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: handleEdit,
    });

    const onSubmit = useCallback((invoice) => {
        if (invoice.status === INVOICE_STATUS.PAID || invoice.status === INVOICE_STATUS.UNPAID) {
            saveConfirmDialog.onSetData(invoice);
            saveConfirmDialog.onShow();
        } else {
            handleEdit(invoice);
        }
    }, [handleEdit, saveConfirmDialog]);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
    });

    const handleDelete = useCallback(debounce(() => {
        setDeleteInvoice(invoiceId);
    }, 250), [invoiceId]);

    const { values, handleSubmit } = formik;

    const handleDeleteClose = (status) => {
        if (status) {
            if (history.length > 1) {
                history.goBack();
            } else {
                history.push(FINANCIALS_INVOICES_LIST_PAGE);
            }
        }
        setDeleteInvoice(null);
    };

    return (
        <React.Fragment>
            <InvoicesAddEditFormHeader withDynamic />
            <Form onSubmit={handleSubmit} className="d-flex flex-grow-1 flex-column">
                <Content loading={loading || !formikInitialValues || !values}>
                    <div className="d-flex flex-column px-lg-5 align-self-stretch">
                        <InvoicesInfoFormGroup formik={formik} isEdit />
                        <InvoiceProductsFormGroup
                            formik={formik}
                        />
                    </div>
                    <InvoicesAddEditFormFooter withDynamic disabled={!values?.products.length} loading={saving} onDelete={handleDelete} />
                    <InvoiceDraftDeleteDialog invoiceId={deleteInvoice} onClose={handleDeleteClose} />
                    {saveConfirmDialog.visible && (
                        <ConfirmationModal
                            isShow={saveConfirmDialog.visible}
                            titleText={tc('placeholders.saveChanges')}
                            bodyText={(
                                <Trans
                                    t={t}
                                    i18nKey="add.saveDialog.body.text"
                                    values={{
                                        name: `${values.client?.clientName} ${values.client?.clientLastName}`,
                                        status: t(`add.status.${values.status}.label`),
                                    }}
                                />
                            )}
                            deleteText={t('add.saveDialog.actions.save')}
                            dismissText={t('add.saveDialog.actions.cancel')}
                            confirmAction={saveConfirmDialog.onConfirm}
                            hide={saveConfirmDialog.onReject}
                        />
                    )}
                </Content>
            </Form>
        </React.Fragment>
    );
}

export default AddNewSubscription;
