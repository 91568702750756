import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { generateRandomString } from 'helpers/string/generateRandomString';
import Check from 'components/Icon/icons/check';
import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { Label } from '../Typography/Label';
import { Row } from '../Row';
import { IconButton } from '../IconButton';

const CheckBox = (props) => {
    const {
        id,
        name,
        checked,
        isInvalid,
        onClick,
        onChange,
        label,
        disabled,
        tooltip,
        paddingTop,
        component: LabelComponent,
    } = props;

    const [isChecked, setIsChecked] = useState(checked);
    const [inputRef, setInputRef] = useState(null);

    useEffect(() => {
        if (checked !== isChecked) {
            setIsChecked(checked);
        }
    }, [checked]);

    const handleInputChange = (e) => {
        setIsChecked(e.target.checked);
        onChange(e);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        inputRef?.click();
    };

    const identifier = useMemo(() => id || name || generateRandomString(), [id, name]);

    const color = useMemo(() => {
        if (isInvalid) {
            if (isChecked) {
                return 'red';
            }
            return 'outlineRed';
        }
        if (isChecked) {
            return 'yellow';
        }
        return 'outline';
    }, [isInvalid, isChecked]);

    return (
        <Row gap={16} paddingTop={paddingTop}>
            <IconButton size={24} onClick={handleClick} disabled={disabled} color={color}>
                {isChecked ? <Check /> : null}
            </IconButton>
            <input
                ref={setInputRef}
                id={identifier}
                name={name}
                type="checkbox"
                checked={isChecked}
                onClick={onClick}
                onChange={handleInputChange}
                disabled={disabled}
                data-testid={props['data-testid']}
                hidden
            />
            {label && <LabelComponent htmlFor={identifier} color={disabled ? 'gray' : 'black'} tooltip={tooltip} pointer>{label}</LabelComponent>}
        </Row>
    );
};

CheckBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    'data-testid': PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    component: PropTypes.elementType,
    isInvalid: PropTypes.bool,
};

CheckBox.defaultProps = {
    id: '',
    name: '',
    checked: false,
    onClick: emptyFunc,
    onChange: emptyFunc,
    'data-testid': 'data-test-checkbox',
    label: '',
    disabled: false,
    tooltip: '',
    component: Label,
    isInvalid: false,
};

export default CheckBox;
