/* eslint-disable import/prefer-default-export */
import { utc } from 'moment';
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import { EMAILS_SORT_BY_TYPE } from 'const/email/EMAILS_SORT_BY_TYPE';
import * as CLIENTS_TYPES from '../../../actions/clients/tables/emails/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
    filters: {
        from: utc().startOf(PERIODS_MAP.month).format('X'),
        to: utc().endOf(PERIODS_MAP.month).format('X'),
        statusFilter: null,
    },
    sortOptions: {
        sortBy: EMAILS_SORT_BY_TYPE.CREATED_AT,
        orderBy: -1,
    },
};

export const clientEmailsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (CLIENTS_TYPES.CLIENTS_EMAILS_GET_RESOLVED): {
        const {
            emails,
            itemsCount,
            pagesCount,
        } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: emails,
            page,
            itemsCount,
            pagesCount,
            loading: false,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_EMAILS_GET_TRIGGER): {
        return {
            ...state,
            loading: true,
        };
    }
    case (CLIENTS_TYPES.CLIENTS_EMAILS_GET_REJECTED): {
        return {
            ...state,
            loading: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_EMAILS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (CLIENTS_TYPES.CLIENT_ITEM_EMAILS_FILTERS_SET): {
        const { filters } = payload;

        const newFilters = {
            ...state.filters,
            ...filters,
        };

        let hasNewFilters = false;
        Object.keys(filters).forEach((key) => {
            if (state.filters[key] !== filters[key]) {
                hasNewFilters = true;
            }

            if (!filters[key] && filters[key] !== 0) {
                delete newFilters[key];
            }
        });

        return {
            ...state,
            filters: hasNewFilters ? newFilters : state.filters,
        };
    }
    case CLIENTS_TYPES.CLIENT_ITEM_EMAILS_FILTERS_CLEAR: {
        return {
            ...state,
            filters: INITIAL_STATE.filters,
        };
    }
    case CLIENTS_TYPES.CLIENT_ITEM_EMAILS_SORT_OPTIONS_SET: {
        const { sortOptions: { sortBy, orderBy } } = payload;

        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    default:
        return state;
    }
};
