import io from 'socket.io-client';
import { ENVS } from './envs';
import { sessionStorage } from './sessionStorage';

let socket;

export const openConnection = () => {
    const appWebsocketUrl = (ENVS.isDevelopment && sessionStorage.getItem('appWebsocketUrl')) || ENVS.appWebsocketUrl;
    socket = io(`${appWebsocketUrl}/admin`, {
        path: '/ws',
        transports: ['websocket'],
    });
};

export const getSocket = () => socket;
