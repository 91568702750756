// extracted by mini-css-extract-plugin
var _1 = "ClientsListTableRow_addressCell__pR8MQ";
var _2 = "ClientsListTableRow_avatarCell__k3Zzx";
var _3 = "ClientsListTableRow_cell__ddYaq";
var _4 = "ClientsListTableRow_centeredContent__lDyOQ";
var _5 = "ClientsListTableRow_checkBox__fmKh-";
var _6 = "ClientsListTableRow_emailCell__Gn-Xg";
var _7 = "ClientsListTableRow_greenField__S9Mpb";
var _8 = "ClientsListTableRow_icon__1QRRe";
var _9 = "ClientsListTableRow_infoWithIcon__8-oG3";
var _a = "ClientsListTableRow_nameCell__gJvS6";
var _b = "ClientsListTableRow_redField__Vobqv";
var _c = "ClientsListTableRow_shrinkable__wlhme";
export { _1 as "addressCell", _2 as "avatarCell", _3 as "cell", _4 as "centeredContent", _5 as "checkBox", _6 as "emailCell", _7 as "greenField", _8 as "icon", _9 as "infoWithIcon", _a as "nameCell", _b as "redField", _c as "shrinkable" }
