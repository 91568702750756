import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';

import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as CLIENTS_ACTIONS from '../../../actions/clients/tables/emails';
import * as CLIENTS_SELECTORS from '../../../selectors/clients';

import * as TOAST_ACTIONS from '../../../actions/toast';

import { HTTP_METHOD } from '../../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../../const/http/HTTP_STATUS_CODE';
import {
    API_ADMIN_COMPANY_EMAILS_ID_ROUTE,
} from '../../../../const/API_URL';

function* getClientEmails(action) {
    const { payload } = action;
    const { clientId } = payload;

    const { page, filters, sortOptions } = yield select(CLIENTS_SELECTORS.clientEmailsListSelector);

    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_EMAILS_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: {
                page,
                ...filters,
                sortBy: sortOptions.sortBy,
                sortType: sortOptions.orderBy === 1 ? 'ASC' : 'DESC',
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
            } = result;
            const emails = items.map(camelize);

            yield call(resolvePromiseAction, action, {
                emails,
                itemsCount,
                pagesCount,
            });
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'emails.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const clientEmailsSaga = [
    takeLatest(CLIENTS_ACTIONS.getClientEmails, getClientEmails),
];
