import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { debounce } from 'lodash';
import { TailSpin } from 'react-loader-spinner';
import * as styles from './IconButton.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    white: styles.white,
    outline: styles.outline,
    red: styles.red,
    transparent: styles.transparent,
    outlineRed: styles.outlineRed,
};

const sizeClasses = {
    24: styles.size24,
    32: styles.size32,
    36: styles.size36,
    42: styles.size42,
    40: styles.size40,
    60: styles.size60,
};

const colorLoaderNames = {
    yellow: 'white',
    black: 'white',
    gray: 'black',
    outline: 'black',
    red: 'white',
    green: 'white',
    white: 'black',
    transparent: 'black',
    outlineRed: 'red',
};

const IconButton = forwardRef((props, ref) => {
    const className = useMemo(() => classNames(
        styles.button,
        colorClasses[props.color],
        sizeClasses[props.size],
        props.disabled ? styles.disabled : '',
        props.stretched ? styles.stretched : '',
        props.group ? styles.group : '',
        props.href ? styles.link : '',
    ), [props.color, props.size, props.disabled, props.stretched, props.group, props.href]);

    const isDisabledStyle = (props.disabled || props.loading);

    const Content = useMemo(() => (props.loading ? (
        <TailSpin
            color={isDisabledStyle ? 'black' : colorLoaderNames[props.color]}
            width={18}
            height={18}
        />
    )
        : props.children), [props.children, props.loading, props.color]);

    if (props.download && props.href) {
        return (
            <a
                className={className}
                ref={ref}
                download={props.download}
                data-testid={props['data-testid']}
                href={props.href}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                style={props.style}
            >
                {Content}
            </a>
        );
    }

    if (props.href) {
        return (
            <Link
                className={className}
                ref={ref}
                download={props.download}
                data-testid={props['data-testid']}
                to={props.href}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                style={props.style}
            >
                {Content}
            </Link>
        );
    }

    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    return (
        <button
            ref={ref}
            onClick={debouncedOnClick}
            disabled={props.disabled}
            type={props.type}
            data-testid={props['data-testid']}
            className={className}
            style={props.style}
        >
            {Content}
        </button>
    );
});

IconButton.propTypes = {
    style: PropTypes.object,
    color: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    group: PropTypes.bool,
    href: PropTypes.string,
    size: PropTypes.number,
    disabled: PropTypes.bool,
    download: PropTypes.bool,
    stretched: PropTypes.bool,
    type: PropTypes.string,
    loading: PropTypes.bool,
    'data-testid': PropTypes.string,
};

IconButton.defaultProps = {
    style: {},
    type: 'button',
    color: 'yellow',
    size: 32,
    stretched: false,
    'data-testid': 'data-test-icon-button',
    loading: false,
};

export default IconButton;
