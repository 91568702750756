import React from 'react';

const Plus = ({
    fill = 'currentColor', secondaryFill = fill, strokeWidth = 2, width = '24', height = '24', className, style,
}) => (
    <svg className={className} style={style} height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill={secondaryFill} stroke={secondaryFill} strokeLinecap="square" strokeWidth={strokeWidth}>
            <line fill="none" stroke={fill} x1="12" x2="12" y1="2" y2="22" />
            <line fill="none" stroke={fill} x1="22" x2="2" y1="12" y2="12" />
        </g>
    </svg>
);

export default Plus;
