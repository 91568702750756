import React, { useCallback, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { roleSelector } from 'store/selectors/auth';
import { permissionsPresetSelector } from 'store/selectors/permissions';

import AnimatedBurger from 'components/AnimatedBurger/AnimatedBurger';
import CompanyBadge from 'components/CompanyBadge/CompanyBadge';
import MainNavigation, { ProfileDropdown } from 'components/Navigation/MainNavigation/MainNavigation';
import { Tooltip } from 'components/Common/Tooltip';

import { HelpQuestion } from 'components/Icon/Icon';

import { productAllDataSelector } from 'store/selectors/products';
import { useMobile } from 'hooks/useMobile';
import { userAccessKeysSelector } from 'store/selectors/user';
import { Row } from 'components/Common/Row';
import * as styles from './PortalHeader.module.scss';

const HELP_URL = 'http://help.appybee.nl';

export const PortalHeader = () => {
    const { role } = useSelector(roleSelector);
    const accessKeys = useSelector(userAccessKeysSelector);
    const preset = useSelector(permissionsPresetSelector);
    const { sections } = useSelector(({ company }) => company);
    const { logo, name } = useSelector((state) => state.company);
    const products = useSelector(productAllDataSelector());
    const isMobile = useMobile();

    const { t } = useTranslation();

    const [isExpanded, setExpanded] = useState(false);

    const removeFocus = useCallback((event) => { event.currentTarget.blur(); }, []);

    return (
        <Navbar
            collapseOnSelect
            className="bg-white border-bottom py-0 px-0"
            expand="lg"
            expanded={isExpanded}
        >
            <Row spacing={isMobile ? 12 : 32} gap={32} stretched justify="between">
                <Row gap={isMobile ? 12 : 32} stretched justify={isMobile ? 'between' : 'start'} wrap={isMobile}>
                    <Row gap={12} style={{ minWidth: 0, width: isMobile ? undefined : 267 }}>
                        <CompanyBadge size={isMobile ? 40 : 48} name={name} logo={logo} />
                        {isMobile && <ProfileDropdown role={role} isMobile={isMobile} onlyAvatar={isMobile} />}
                    </Row>
                    <MainNavigation
                        role={role}
                        sections={sections}
                        preset={preset}
                        productsList={products}
                        onClose={() => setExpanded(false)}
                        isMobile={isMobile}
                        accessKeys={accessKeys}
                    />
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={() => setExpanded(!isExpanded)}
                    >
                        <AnimatedBurger active={isExpanded} size={16} />
                    </Navbar.Toggle>
                </Row>
                {!isMobile && (
                    <Row gap={12}>
                        <ProfileDropdown role={role} isMobile={isMobile} onlyAvatar={isMobile} />
                        <Tooltip
                            tooltip={t('help')}
                            placement="bottom"
                        >
                            <a
                                href={HELP_URL}
                                onClick={removeFocus}
                                target="__about"
                                className={styles.helpQuestionIcon}
                            >
                                <HelpQuestion />
                            </a>
                        </Tooltip>
                    </Row>
                )}
            </Row>
        </Navbar>
    );
};
