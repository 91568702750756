import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from '../actionTypes';

export const addTimeBlock = createPromiseAction(actionTypes.ADD_TIME_BLOCK);
export const addTimeBlockSucceeded = (block) => ({
    type: actionTypes.ADD_TIME_BLOCK_SUCCEEDED,
    block,
});

export const getProductById = createPromiseAction(actionTypes.PRODUCTS_GET_BY_ID);
export const getProductByIdSucceeded = (product, type) => ({
    type: actionTypes.PRODUCTS_GET_BY_ID_SUCCESS,
    item: { product, type },
});
export const getProductByIdFailed = () => ({ type: actionTypes.PRODUCTS_GET_BY_ID_ERROR });

export const deleteTimeBlock = createPromiseAction(actionTypes.DELETE_TIME_BLOCK);
export const deleteTimeBlockSucceeded = (block) => ({
    type: actionTypes.DELETE_TIME_BLOCK_SUCCEEDED,
    block,
});

export const editTimeBlock = createPromiseAction(actionTypes.EDIT_TIME_BLOCK);
export const editTimeBlockSucceeded = (block) => ({
    type: actionTypes.EDIT_TIME_BLOCK_SUCCEEDED,
    block,
});

export const getProducts = createPromiseAction(actionTypes.GET_PRODUCTS);
export const deleteProduct = createPromiseAction(actionTypes.DELETE_PRODUCT);
