import * as actionTypes from '../../actions/actionTypes';

/* [
    {
      "id": 28,
      "name": "Cola Cherry",
      "price": 2.25,
      "vat_value": 12
    }
  ]
*/

const initialState = {
    loaded: false,
    loading: false,
    saving: false,

    products: [],
};

export const productsListReducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.GET_PRODUCTS_TRIGGER:
        return {
            ...state,
            loaded: false,
            loading: true,
        };
    case actionTypes.GET_PRODUCTS_REJECTED:
        return {
            ...state,
            loaded: false,
            loading: false,
        };
    case actionTypes.GET_PRODUCTS_RESOLVED: {
        const products = payload.payload;
        return {
            ...state,
            products,
            loaded: true,
            loading: false,
        };
    }
    case actionTypes.ADD_PRODUCT_TRIGGER:
        return {
            ...state,
            saving: true,
        };
    case actionTypes.ADD_PRODUCT_REJECTED:
        return {
            ...state,
            saving: false,
        };
    case actionTypes.ADD_PRODUCT_RESOLVED: {
        return {
            ...state,
            products: [...state.products, payload],
            saving: false,
        };
    }
    case actionTypes.DELETE_PRODUCT_TRIGGER:
        return {
            ...state,
            saving: true,
        };
    case actionTypes.DELETE_PRODUCT_REJECTED:
        return {
            ...state,
            saving: false,
        };
    case actionTypes.DELETE_PRODUCT_RESOLVED: {
        const { id } = payload.payload;
        return {
            ...state,
            products: state.products.filter((product) => product.id !== id),
            saving: false,
        };
    }
    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    default:
        return state;
    }
};
