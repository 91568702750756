import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../Pagination';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

const ListTableMobileFooter = (props) => {
    const {
        page,
        showAll,
        pagesCount,
        itemsCount,
        itemsPerPage,
        onShowAll,
        onPrevPage,
        onNextPage,
        onLastPage,
        onFirstPage,
        onShowPages,
        onChangePage,
    } = props;

    return (
        <Pagination
            showAll={showAll}
            page={page}
            pagesCount={pagesCount}
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
            onShowAll={onShowAll}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onLastPage={onLastPage}
            onFirstPage={onFirstPage}
            onShowPages={onShowPages}
            onChangePage={onChangePage}
            paginatorOnSeparateLine
        />
    );
};

ListTableMobileFooter.propTypes = {
    page: PropTypes.number,
    showAll: PropTypes.bool,
    pagesCount: PropTypes.number,
    itemsCount: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onShowAll: PropTypes.func,
    onPrevPage: PropTypes.func,
    onNextPage: PropTypes.func,
    onLastPage: PropTypes.func,
    onFirstPage: PropTypes.func,
    onShowPages: PropTypes.func,
    onChangePage: PropTypes.func,
};

ListTableMobileFooter.defaultProps = {
    page: 1,
    showAll: false,
    pagesCount: 0,
    itemsCount: 0,
    itemsPerPage: 0,
    onShowAll: emptyFunc,
    onPrevPage: emptyFunc,
    onNextPage: emptyFunc,
    onLastPage: emptyFunc,
    onFirstPage: emptyFunc,
    onShowPages: emptyFunc,
    onChangePage: emptyFunc,
};

export default ListTableMobileFooter;
