export const sessionStorage = {
    getItem: (key) => {
        try {
            return window.sessionStorage.getItem(key);
        } catch (error) {
            return null;
        }
    },
    setItem: (key, value) => {
        try {
            window.sessionStorage.setItem(key, value);
        } catch (error) {
            return null;
        }
    },
    removeItem: (key) => {
        try {
            window.sessionStorage.removeItem(key);
        } catch (error) {
            return null;
        }
    },
    clear: () => {
        try {
            window.sessionStorage.clear();
        } catch (error) {
            return null;
        }
    },
};
