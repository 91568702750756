import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';

const T_PREFIX = 'subscriptions.tableHeaders';

function SoldSubscriptionsTableColumns({ isClientPage }) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TableHeaderCell />
            {!isClientPage && (
                <TableHeaderCell>
                    <ListTableHeaderCell
                        name={t(`${T_PREFIX}.client`)}
                    />
                </TableHeaderCell>
            )}
            {!isClientPage && (
                <TableHeaderCell>
                    <ListTableHeaderCell
                        name={t(`${T_PREFIX}.age`)}
                    />
                </TableHeaderCell>
            )}
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.subscriptionTitle`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.contractDate`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.nextDebitDate`)}
                    wrap
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.price`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.type`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.payment`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.status`)}
                />
            </TableHeaderCell>
            <TableHeaderCell />
        </React.Fragment>
    );
}
SoldSubscriptionsTableColumns.propTypes = {
    isClientPage: PropTypes.bool,
};

SoldSubscriptionsTableColumns.defaultProps = {
    isClientPage: false,
};

export default SoldSubscriptionsTableColumns;
