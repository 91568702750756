import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    styles as selectStyles,
    theme as selectTheme,
} from 'styles/select';

import Select from 'react-select';
import { companySelector } from 'store/selectors/company';

import classNames from 'classnames';
import * as styles from './LocationsSelect.module.scss';

function LocationsSelect({
    placeholder, onSelect, defaultSelected, stretched,
}) {
    const { shops } = useSelector(companySelector);

    const locations = useMemo(() => shops.map((shop) => ({
        label: shop.name,
        value: shop.id,
    })), [shops]);

    const value = defaultSelected ? locations.find((location) => location.value === defaultSelected) : null;

    return (
        <Select
            id="location"
            className={classNames(styles.container, stretched && 'w-100')}
            styles={selectStyles}
            theme={selectTheme}
            name="location"
            placeholder={placeholder}
            value={value}
            options={locations}
            onChange={onSelect}
            isClearable
        />
    );
}

export default LocationsSelect;
