import React from 'react';

// Hooks
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import * as actions from 'store/actions';

// Components
import { GroupsTimeline, GroupDetailsModal } from 'components';
import { GROUP_DETAILS_MODAL_TAB } from 'const/group/GROUP_DETAILS_MODAL_TAB';

const Week = ({ globalDate, week, year }) => {
    const isShopLoaded = useSelector((state) => state.shop.loaded);
    const dispatch = useDispatch();

    const [clickedGroupSessionID, setClickedGroupSessionID] = useState(null);
    const [showGroupDetailsModal, setShowGroupDetailsModal] = useState(false);
    const [tab, setTab] = useState(GROUP_DETAILS_MODAL_TAB.SpotsBooked);

    useEffect(() => {
        if (!isShopLoaded) {
            return;
        }

        const from = globalDate.clone().startOf('isoWeek').unix();
        const to = globalDate.clone().endOf('isoWeek').unix();

        dispatch(actions.getGroupSessions(from, to));
    }, [isShopLoaded, week, year]);

    return (
        <React.Fragment>
            <GroupsTimeline
                className="mt-4 px-0"
                globalDate={globalDate.clone()}
                week={week}
                year={year}
                onGroupSessionClick={({ id, timestamp }) => {
                    setClickedGroupSessionID({ id, timestamp });
                    setTab(GROUP_DETAILS_MODAL_TAB.SpotsBooked);
                    setShowGroupDetailsModal(true);
                }}
            />
            {showGroupDetailsModal && (
                <GroupDetailsModal
                    groupSessionIDWithTimeStamp={clickedGroupSessionID}
                    week={week}
                    year={year}
                    show={showGroupDetailsModal}
                    onHide={() => setShowGroupDetailsModal(false)}
                    tab={tab}
                    onTabChange={setTab}
                />
            )}
        </React.Fragment>
    );
};

export default Week;
