import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile } from 'hooks/useMobile';
import { Row } from 'components/Common/Row';
import { Button } from 'components/Common/Button';
import { Label } from 'components/Common/Typography/Label';
import { Image } from 'components/Common/Image/Image';
import mspLogo from 'static/images/msp_logo.png';
import { ArrowDiagonalUpIcon, AttentionIcon } from 'components/Icon/Icon';
import { useSelector } from 'react-redux';
import { companyMspSelector } from 'store/selectors/company';
import * as styles from './PortalMspAlert.module.scss';

export const PortalMspAlert = () => {
    const isMobile = useMobile();
    const {
        multisafepayLink,
        showMultisafepayBanner,
    } = useSelector(companyMspSelector);

    const { t } = useTranslation();

    if (!showMultisafepayBanner) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Row spacing={isMobile ? 12 : 24} padding={8} gap={16} stretched justify="center">
                <div className={styles.msp}>
                    <div className={styles.attentionIcon}>
                        <AttentionIcon />
                    </div>
                    <Image src={mspLogo} height={32} width={32} />
                </div>
                <Label>
                    {t('mspAlert.text')}
                </Label>
                <Button size="small" after={<ArrowDiagonalUpIcon height={20} width={20} />} href={multisafepayLink} external target="_blank" rel="noopener noreferrer">
                    {isMobile ? t('mspAlert.action.mobile') : t('mspAlert.action.web')}
                </Button>
            </Row>
        </div>
    );
};
