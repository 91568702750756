import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { Label } from 'components/Common/Typography/Label';
import NumberInput from 'components/Common/NumberInput';

import { SERVICE_PROP } from '../../../const/services/SERVICE_PROP';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { getError, isInvalid } from '../../../services/validationService';

import { styles, theme } from '../../../styles/select';

const T_PREFIX = 'addServiceRoute';
const T_FIELDS = `${T_PREFIX}.form.fields`;
const T_TABS = `${T_PREFIX}.tabs`;

const BOOKING_FREQUENCY_OPTIONS = range(
    SERVICE_PROP.BOOKING_FREQUENCY.MIN,
    SERVICE_PROP.BOOKING_FREQUENCY.MAX + 1,
    SERVICE_PROP.BOOKING_FREQUENCY.STEP,
).map((v) => ({
    value: v,
    label: v,
}));

const SubOptionForm = (props) => {
    const {
        vatRate,
        subOption,
        formState,
        onDelete,
        onChangeName,
        onChangeTime,
        onChangePrice,
        onChangeSeatsAmount,
        onChangeAdditionalTime,
        onChangeBookingFrequency,
    } = props;

    const { t } = useTranslation();

    const { errors, touched } = formState;

    const validationState = useMemo(() => (
        Object.keys(subOption).reduce((res, k) => ({
            ...res,
            [k]: {
                isInvalid: isInvalid(`subOptions[${subOption.key}].${k}`, errors, touched),
                error: getError(`subOptions[${subOption.key}].${k}`, errors),
            },
        }), [])
    ), [errors, touched, subOption.key]);

    const priceWithoutVat = (subOption.price / (1 + vatRate / 100))
        .toFixed(SERVICE_PROP.PRICE.TO_FIXED);

    const bookingFrequencyValue = useMemo(() => ({
        value: subOption.bookingFrequency,
        label: subOption.bookingFrequency,
    }), [subOption.bookingFrequency]);

    const handleDelete = useCallback(() => {
        onDelete({ key: subOption.key });
    }, [onDelete, subOption.key]);

    const handleChangeName = useCallback((e) => {
        const { target: { value } } = e;
        onChangeName({ key: subOption.key, name: value });
    }, [onChangeName, subOption.key]);

    const handleChangeTime = useCallback((value) => {
        onChangeTime({ key: subOption.key, time: value });
    }, [onChangeTime, subOption.key]);

    const handleChangePrice = useCallback((value) => {
        onChangePrice({ key: subOption.key, price: value });
    }, [onChangePrice, subOption.key]);

    const handleChangeSeatsAmount = useCallback((value) => {
        onChangeSeatsAmount({ key: subOption.key, seatsAmount: value });
    }, [onChangeSeatsAmount, subOption.key]);

    const handleChangeAdditionalTime = useCallback((value) => {
        onChangeAdditionalTime({ key: subOption.key, addTime: value });
    }, [onChangeAdditionalTime, subOption.key]);

    const handleChangeBookingFrequency = useCallback(({ value }) => {
        onChangeBookingFrequency({ key: subOption.key, bookingFrequency: value });
    }, [onChangeBookingFrequency, subOption.key]);

    return (
        <div className="py-4 border-bottom border-gray-200 w-100">
            <Row>
                <Col xs={12} lg={4}>
                    <Form.Group>
                        <Label paddingBottom tooltip={t(`${T_FIELDS}.name.tooltip`)}>
                            {t(`${T_FIELDS}.name.label`)}
                        </Label>
                        <Form.Control
                            value={subOption.name}
                            onChange={handleChangeName}
                            isInvalid={validationState.name?.isInvalid}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.name?.isInvalid,
                            })}
                        >
                            {validationState.name?.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={4}>
                    <Label paddingBottom>
                        {t(`${T_FIELDS}.price.label`)}
                    </Label>
                    <InputGroup className="mb-3">
                        <NumberInput
                            name="price"
                            value={subOption.price}
                            onChange={handleChangePrice}
                            isInvalid={validationState.price?.isInvalid}
                            min={SERVICE_PROP.PRICE.MIN}
                            max={SERVICE_PROP.PRICE.MAX}
                            step={SERVICE_PROP.PRICE.STEP}
                            toFixed={SERVICE_PROP.PRICE.TO_FIXED}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.price?.isInvalid,
                            })}
                        >
                            {validationState.price?.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col xs={12} lg={4}>
                    <Form.Group>
                        <Label paddingBottom>
                            {t(`${T_FIELDS}.priceWithoutVat.label`)}
                        </Label>
                        <Form.Control
                            value={priceWithoutVat}
                            disabled
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={3}>
                    <Label paddingBottom tooltip={t(`${T_FIELDS}.time.tooltip`)}>
                        {t(`${T_FIELDS}.time.label`)}
                    </Label>
                    <NumberInput
                        name="time"
                        value={subOption.time}
                        onChange={handleChangeTime}
                        isInvalid={validationState.time?.isInvalid}
                        min={SERVICE_PROP.TIME.MIN}
                        step={SERVICE_PROP.TIME.STEP}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <Form.Group>
                        <Label paddingBottom tooltip={t(`${T_FIELDS}.bookingFrequency.tooltip`)}>
                            {t(`${T_FIELDS}.bookingFrequency.label`)}
                        </Label>
                        <Select
                            styles={styles}
                            theme={theme}
                            options={BOOKING_FREQUENCY_OPTIONS}
                            value={bookingFrequencyValue}
                            onChange={handleChangeBookingFrequency}
                            placeholder={t('placeholders.select')}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className={classNames({
                                'd-flex': validationState.bookingFrequency?.isInvalid,
                            })}
                        >
                            {validationState.bookingFrequency?.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={3}>
                    <Label paddingBottom tooltip={t(`${T_FIELDS}.addTime.tooltip`)}>
                        {t(`${T_FIELDS}.addTime.label`)}
                    </Label>
                    <NumberInput
                        name="addTime"
                        value={subOption.addTime}
                        onChange={handleChangeAdditionalTime}
                        isInvalid={validationState.addTime?.isInvalid}
                        min={SERVICE_PROP.ADDITIONAL_TIME.MIN}
                        max={SERVICE_PROP.ADDITIONAL_TIME.MAX}
                        step={SERVICE_PROP.ADDITIONAL_TIME.STEP}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <Label paddingBottom tooltip={t(`${T_FIELDS}.seatsAmount.tooltip`)}>
                        {t(`${T_FIELDS}.seatsAmount.label`)}
                    </Label>
                    <NumberInput
                        name="seatsAmount"
                        value={subOption.seatsAmount}
                        onChange={handleChangeSeatsAmount}
                        isInvalid={validationState.seatsAmount?.isInvalid}
                        min={SERVICE_PROP.SEATS_AMOUNT.MIN}
                        step={SERVICE_PROP.SEATS_AMOUNT.STEP}
                    />
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <Button
                    type="button"
                    color="red"
                    size="small"
                    onClick={handleDelete}
                >
                    {t(`${T_TABS}.subOptions.actions.delete`)}
                </Button>
            </div>
        </div>
    );
};

SubOptionForm.propTypes = {
    vatRate: PropTypes.number.isRequired,
    subOption: PropTypes.object.isRequired,
    formState: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onChangeName: PropTypes.func,
    onChangeTime: PropTypes.func,
    onChangePrice: PropTypes.func,
    onChangeSeatsAmount: PropTypes.func,
    onChangeAdditionalTime: PropTypes.func,
    onChangeBookingFrequency: PropTypes.func,
};

SubOptionForm.defaultProps = {
    onDelete: emptyFunc,
    onChangeName: emptyFunc,
    onChangeTime: emptyFunc,
    onChangePrice: emptyFunc,
    onChangeSeatsAmount: emptyFunc,
    onChangeAdditionalTime: emptyFunc,
    onChangeBookingFrequency: emptyFunc,
};

export default SubOptionForm;
