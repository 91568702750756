import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Sort } from 'components/Icon/Icon';

import * as styles from './ListTableHeaderCell.module.scss';

const Component = (props) => (props?.onClick ? (
    <button
        className={classNames(styles.wrapper, props?.align && styles[props?.align])}
        disabled={!props?.onClick}
        onClick={props?.onClick}
        data-testid={`${props['data-testid']}-button`}
    >
        {props?.children}
    </button>
) : (
    <div
        className={classNames(styles.wrapper, props?.align && styles[props?.align])}
        data-testid={props['data-testid']}
    >
        {props?.children}
    </div>
));

const ListTableHeaderCell = (props) => {
    const {
        name,
        active,
        ascending,
        onClick,
        align,
        wrap,
        'data-testid': dataTestId,
    } = props;

    return (
        <Component onClick={onClick} align={align} data-testid={dataTestId}>
            <span className={classNames(styles.name, align && styles[align], wrap && styles.wrap)} data-testid={`${dataTestId}-label`}>
                {name}
            </span>

            {!!onClick && (
                <Sort
                    data={`${dataTestId}-sort-${active ? (ascending ? 'up' : 'down') : 'none'}`}
                    className={styles.iconSort}
                    upClassName={classNames(active && !ascending && styles.active)}
                    downClassName={classNames(active && ascending && styles.active)}
                />
            )}
        </Component>
    );
};

ListTableHeaderCell.defaultProps = {
    name: '',
    active: false,
    ascending: true,
    onClick: null,
    align: 'left',
    wrap: true,
    'data-testid': 'data-test-list-table-header-cell',
};

ListTableHeaderCell.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    active: PropTypes.bool,
    ascending: PropTypes.bool,
    onClick: PropTypes.func,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    wrap: PropTypes.bool,
    'data-testid': PropTypes.string,
};

export default ListTableHeaderCell;
