export const routesKeys = {
    activity: 'activity',
    access: 'access',
    agenda: 'agenda',
    services: 'services',
    clients: 'clients',
    marketing: 'marketing',
    statistics: 'statistics',
    financials: 'financials',
    settings: 'settings',
    signUp: 'signUp',
    signIn: 'signIn',
    resetPassword: 'resetPassword',
    resetPasswordSuccess: 'resetPasswordSuccess',
    setNewPassword: 'setNewPassword',
};
