import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { formatPrice } from 'helpers/formatters/formatPrice';
import Container from 'components/Layout/Container/Container';
import { Row } from 'components/Common/Row';
import { Panel } from 'components/Common/Panel';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { useMobile } from 'hooks/useMobile';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Column } from 'components/Common/Column';
import { Divider } from 'components/Common/Divider';
import { toCapitalize } from '../../../../helpers/string/toCapitalize';

import { BalanceIcon, PendingBalanceIcon } from '../../../Icon/Icon';

import * as styles from './ClearingsInfoPanel.module.scss';

import { companySelector } from '../../../../store/selectors/company';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.header';

const ClearingsInfoPanel = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLEARINGS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const isMobile = useMobile();

    const {
        balance,
        nextClearingDate,
    } = useSelector(companySelector);

    const bookBalance = balance?.balance ? balance.balance.toFixed(2) : 0;
    const nextClearing = balance?.nextClearing?.amount ? balance.nextClearing.amount.toFixed(2) : 0;
    const pending = [bookBalance, nextClearing].includes(0)
        ? 0
        : (bookBalance - nextClearing).toFixed(2);

    const clearingDate = useMemo(() => {
        if (!nextClearingDate) {
            return null;
        }

        const date = moment(nextClearingDate).locale('en');
        const weekDay = tc(`date.weekDay.${date.format('dddd').toLocaleLowerCase()}`);
        const month = tc(`date.month.${date.format('MMMM').toLocaleLowerCase()}`);
        const day = date.format('DD');

        return `${toCapitalize(weekDay)} ${day} ${toCapitalize(month)}`;
    }, [nextClearingDate, tc]);

    return (
        <Container>
            <Panel color="primary" padding={0}>
                <Row stretched wrap={isMobile}>
                    <Row stretched gap={16} padding={24} spacing={24}>
                        <BalanceIcon className={styles.balanceIcon} />
                        <Column gap={8}>
                            <Row gap={0}>
                                <SubHeader color="gray">
                                    {t(`${T_PREFIX}.companyInfo.bookBalance.label`)}
                                </SubHeader>
                                <InfoTooltip
                                    text={t(`${T_PREFIX}.companyInfo.bookBalance.tooltip`)}
                                    placement="bottom"
                                />
                            </Row>
                            <SubHeader weight="bold">
                                {formatPrice.toEuroWithComma({ amount: bookBalance }) }
                            </SubHeader>
                        </Column>
                        <Column gap={8}>
                            <Row gap={0}>
                                <SubHeader color="gray">
                                    {t(`${T_PREFIX}.companyInfo.pending.label`)}
                                </SubHeader>
                                <InfoTooltip
                                    text={t(`${T_PREFIX}.companyInfo.pending.tooltip`)}
                                    placement="bottom"
                                />
                            </Row>
                            <SubHeader weight="bold">
                                {formatPrice.toEuroWithComma({ amount: pending }) }
                            </SubHeader>
                        </Column>
                    </Row>

                    <Divider padding={0} horisontal={isMobile} />

                    <Row stretched gap={16} padding={24} spacing={24}>
                        <PendingBalanceIcon className={classNames(styles.balanceIcon, styles.grayColor)} />

                        <Column gap={8}>
                            <Row gap={0}>
                                <SubHeader color="gray">
                                    {t(`${T_PREFIX}.companyInfo.nextClearing.label`)}
                                </SubHeader>
                                <InfoTooltip
                                    text={t(`${T_PREFIX}.companyInfo.nextClearing.tooltip`)}
                                    placement="bottom"
                                />
                            </Row>
                            <SubHeader weight="bold">
                                <Row gap={8}>
                                    {formatPrice.toEuroWithComma({ amount: nextClearing }) }
                                    {clearingDate && (
                                        <React.Fragment>
                                            <span className={styles.point}>
                                                ·
                                            </span>
                                            <span className={styles.date}>
                                                {clearingDate}
                                            </span>
                                        </React.Fragment>
                                    )}
                                </Row>
                            </SubHeader>

                        </Column>
                    </Row>
                </Row>
            </Panel>
        </Container>
    );
};

ClearingsInfoPanel.propTypes = {
    onClearing: PropTypes.func,
};

ClearingsInfoPanel.defaultProps = {
    onClearing: PropTypes.emptyFunc,
};

export default ClearingsInfoPanel;
