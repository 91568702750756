import {
    useCallback, useMemo, useState,
} from 'react';

export const useTableSelection = ({
    itemsOnPageRecordWithId,
    totalItemsWithId: _totalItemsWithId,
    initialSelected = [],
    totalCount,
    onGetTotalItemsWithId,
}) => {
    const [totalItemsWithId, setTotalItemsWithId] = useState(_totalItemsWithId || null);
    const [loading, setLoading] = useState(false);

    const getTotalItemsWithId = useCallback(async () => {
        if (totalItemsWithId.length) {
            return totalItemsWithId;
        }
        setLoading(true);
        const items = await onGetTotalItemsWithId();
        setTotalItemsWithId(items);
        setLoading(false);
        return items;
    }, [totalItemsWithId]);

    const itemsIdsOnPage = useMemo(() => itemsOnPageRecordWithId.map(({ id }) => id) || [], [itemsOnPageRecordWithId]);
    const itemsIdsTotal = useMemo(() => totalItemsWithId?.map(({ id }) => id) || [], [totalItemsWithId]);

    // we can select only on the current page
    const [selected, setSelected] = useState(initialSelected.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
    }, {}));

    const allSelected = useMemo(() => Boolean(itemsIdsTotal.length && itemsIdsTotal.every((i) => selected[i])), [itemsIdsTotal, selected]);
    const currentPageAllSelected = useMemo(() => Boolean(itemsIdsOnPage.length && itemsIdsOnPage.every((i) => selected[i])), [itemsIdsOnPage, selected]);

    const isSelected = useCallback(
        (item) => allSelected || currentPageAllSelected || selected[item.id],
        [allSelected, currentPageAllSelected, selected],
    );

    const selectedLength = useMemo(() => Object.keys(selected).length, [selected]);

    const select = useCallback((item) => {
        if (loading) {
            return;
        }

        setSelected((prev) => {
            const newState = { ...prev, [item.id]: item };
            return newState;
        });
    }, [totalCount, itemsIdsOnPage, loading]);

    const deselect = useCallback((item) => {
        setSelected((prev) => {
            const newState = { ...prev };
            delete newState[item.id];
            return newState;
        });
    }, [loading]);

    const toggle = useCallback((item) => {
        if (currentPageAllSelected) {
            const newSelected = itemsOnPageRecordWithId.reduce((acc, i) => {
                if (i.id !== item.id) {
                    acc[i.id] = i;
                }
                return acc;
            }, {});
            setSelected((prev) => {
                const newState = { ...prev };
                delete newState[item.id];
                return ({ ...newState, ...newSelected });
            });
            return;
        }

        setSelected((prev) => {
            const newState = { ...prev };
            if (newState[item.id]) {
                delete newState[item.id];
            } else {
                newState[item.id] = item;
            }
            return newState;
        });
    }, [itemsOnPageRecordWithId, currentPageAllSelected, itemsIdsOnPage, loading]);

    const selectAll = useCallback(async () => {
        const items = await getTotalItemsWithId();
        const newSelected = items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        setSelected(newSelected);
    }, [itemsIdsTotal, totalItemsWithId]);

    const deselectAll = useCallback(() => {
        setSelected({});
    }, [loading]);

    const selectAllOnCurrentPage = useCallback(() => {
        const newSelected = itemsOnPageRecordWithId.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        setSelected((prev) => ({ ...prev, ...newSelected }));
    }, [itemsOnPageRecordWithId, loading]);

    const toggleAll = useCallback(() => {
        if (allSelected) {
            deselectAll();
        } else {
            selectAll();
        }
    }, [allSelected, selectAll, deselectAll, loading]);

    const deselectAllOnCurrentPage = useCallback(() => {
        setSelected((prev) => {
            const newState = { ...prev };
            itemsIdsOnPage.forEach((id) => {
                delete newState[id];
            });
            return newState;
        });
    }, [itemsIdsOnPage, loading]);

    const toggleAllOnCurrentPage = useCallback(() => {
        if (currentPageAllSelected) {
            deselectAllOnCurrentPage();
        } else {
            selectAllOnCurrentPage();
        }
    }, [currentPageAllSelected, selectAllOnCurrentPage, deselectAllOnCurrentPage, loading]);

    const resetTotalItemsWithId = useCallback(() => {
        setTotalItemsWithId([]);
    }, [loading]);

    return {
        totalItemsWithId,
        selected: Object.values(selected),
        allSelected,
        currentPageAllSelected,
        selectedCount: allSelected ? totalCount : (currentPageAllSelected ? itemsIdsOnPage.length : selectedLength),
        totalCount,
        loading,
        isSelected,
        toggle,
        select,
        deselect,
        selectAll,
        deselectAll,
        selectAllOnCurrentPage,
        deselectAllOnCurrentPage,
        toggleAll,
        toggleAllOnCurrentPage,
        resetTotalItemsWithId,
    };
};
