import { createPromiseAction } from '@adobe/redux-saga-promise';
import * as INVOICES_TYPES from './types';

export const getClientInvoices = createPromiseAction(INVOICES_TYPES.CLIENTS_INVOICES_GET);

export const setClientInvoices = ({
    invoices,
    itemsCount,
    pagesCount,
    totalRevenue,
    appyBeePay,
    manual,
    exportEmail,
}) => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_SET,
    payload: {
        invoices,
        itemsCount,
        pagesCount,
        totalRevenue,
        appyBeePay,
        manual,
        exportEmail,
    },
});

export const setClientInvoicesPage = ({ page }) => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_PAGE_SET,
    payload: { page },
});

export const setClientInvoicesPageFirst = () => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_PAGE_SET_FIRST,
});

export const setClientInvoicesPageLast = () => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_PAGE_SET_LAST,
});

export const setClientInvoicesPagePrev = () => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_PAGE_SET_PREV,
});

export const setClientInvoicesPageNext = () => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_PAGE_SET_NEXT,
});

export const setClientInvoicesSearch = ({ search }) => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_SEARCH_SET,
    payload: { search },
});

export const setClientInvoicesLoading = ({ loading }) => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_LOADING_SET,
    payload: { loading },
});

export const setClientInvoicesFilters = ({ filters }) => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_FILTERS_SET,
    payload: {
        filters,
    },
});

export const clearClientInvoicesFilters = () => ({
    type: INVOICES_TYPES.CLIENTS_INVOICES_FILTERS_CLEAR,
});
