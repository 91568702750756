import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import camelize from 'camelize';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import {
    API_ADMIN_CLIENT_INVOICES_ID_ROUTE,
} from 'const/API_URL';
import { HTTP_METHOD } from 'const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from 'const/http/HTTP_STATUS_CODE';
import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { resolvePromiseAction } from '@adobe/redux-saga-promise';
import * as TOAST_ACTIONS from '../../../actions/toast';
import * as ACTIONS_SELECTORS from '../../../selectors/clients';
import * as CLIENTS_ACTIONS from '../../../actions/clients/tables/invoices';

function* getClientInvoices(action) {
    const { clientId } = action.payload || {};
    const { page, filters, search } = yield select(ACTIONS_SELECTORS.clientInvoicesListSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_INVOICES_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: {
                page,
                search,
                ...filters,
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
                totalRevenue,
                appyBeePay,
                manual,
                exportEmail,
            } = result;

            const invoices = items.map(camelize);

            yield call(resolvePromiseAction, action, {
                invoices,
                itemsCount,
                pagesCount,
                totalRevenue,
                appyBeePay,
                manual,
                exportEmail,
            });
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'invoices.get.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    }
}

export const clientInvoicesSaga = [
    takeLatest(CLIENTS_ACTIONS.getClientInvoices, getClientInvoices),
];
