const PREFIX = '@SERVICES/';

const SERVICES = `${PREFIX}SERVICES`;
export const SERVICES_GET = `${SERVICES}:GET`;
export const SERVICES_SET = `${SERVICES}:SET`;
const SERVICES_ITEM = `${SERVICES}:ITEM`;
export const SERVICES_ITEM_ADD = `${SERVICES_ITEM}:ADD`;
export const SERVICES_ITEM_EDIT = `${SERVICES_ITEM}:EDIT`;
export const SERVICES_ITEM_REMOVE = `${SERVICES_ITEM}:REMOVE`;
export const SERVICES_ITEM_CHANGE_POSITION = `${SERVICES_ITEM}:CHANGE_POSITION`;
const SERVICES_ITEM_RANDOMLY_SELECT = `${SERVICES_ITEM}:RANDOMLY_SELECT`;
export const SERVICES_ITEM_RANDOMLY_SELECT_TOGGLE = `${SERVICES_ITEM_RANDOMLY_SELECT}:TOGGLE`;
export const SERVICES_ITEM_RANDOMLY_SELECT_SET = `${SERVICES_ITEM_RANDOMLY_SELECT}:SET`;
const SERVICES_ITEM_ARCHIVE = `${SERVICES_ITEM}:ARCHIVE`;
export const SERVICES_ITEM_ARCHIVE_TOGGLE = `${SERVICES_ITEM_ARCHIVE}:TOGGLE`;
export const SERVICES_ITEM_ARCHIVE_SET = `${SERVICES_ITEM_ARCHIVE}:SET`;
const SERVICES_PAGE = `${SERVICES}:PAGE`;
export const SERVICES_PAGE_SET = `${SERVICES_PAGE}:SET`;
export const SERVICES_PAGE_SET_FIRST = `${SERVICES_PAGE_SET}:FIRST`;
export const SERVICES_PAGE_SET_LAST = `${SERVICES_PAGE_SET}:LAST`;
export const SERVICES_PAGE_SET_PREV = `${SERVICES_PAGE_SET}:PREV`;
export const SERVICES_PAGE_SET_NEXT = `${SERVICES_PAGE_SET}:NEXT`;
const SERVICES_SEARCH = `${SERVICES}:SEARCH`;
export const SERVICES_SEARCH_SET = `${SERVICES_SEARCH}:SET`;
const SERVICES_LOADING = `${SERVICES}:LOADING`;
export const SERVICES_LOADING_SET = `${SERVICES_LOADING}:SET`;
export const SERVICES_HIDE_ARCHIVED_TOGGLE = `${SERVICES}:HIDE_ARCHIVED_TOGGLE`;

const COMPANY_SERVICES = `${PREFIX}COMPANY_SERVICES`;
export const COMPANY_SERVICES_GET = `${COMPANY_SERVICES}:GET`;
export const COMPANY_SERVICES_SET = `${COMPANY_SERVICES}:SET`;
const COMPANY_SERVICES_LOADING = `${COMPANY_SERVICES}:LOADING`;
export const COMPANY_SERVICES_LOADING_SET = `${COMPANY_SERVICES_LOADING}:SET`;

const SERVICE_ITEM = `${PREFIX}SERVICE_ITEM`;
export const SERVICE_ITEM_GET = `${SERVICE_ITEM}:GET`;
export const SERVICE_ITEM_SET = `${SERVICE_ITEM}:SET`;
const SERVICE_ITEM_LOADING = `${SERVICE_ITEM}:LOADING`;
export const SERVICE_ITEM_LOADING_SET = `${SERVICE_ITEM_LOADING}:SET`;
