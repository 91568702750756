import Invoices from './invoices';
import Payments from './payments';
import Accounting from './accounting';

const routes = [
    {
        component: Accounting,
        exact: false,
        path: '/financials/accounting',
    },
    {
        component: Invoices,
        exact: false,
        path: '/financials/invoices',
    },
    {
        component: Payments,
        exact: true,
        multisafe: false,
        path: '/financials/payments',
    },
];

export default routes;
