import React, { useCallback, useMemo, useState } from 'react';

// Components
import {
    InputGroup, Form,

} from 'react-bootstrap';
import Select from 'react-select';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Styles

// Utils
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { IconButton } from 'components/Common/IconButton';
import { Row } from 'components/Common/Row';
import NumberInput from 'components/Common/NumberInput';
import { Label } from 'components/Common/Typography/Label';
import { Column } from 'components/Common/Column';
import { CheckBox } from 'components/Common/CheckBox';
import { Tooltip } from 'components/Common/Tooltip';
import { Popover } from 'components/Common/Popover';
import { Button } from 'components/Common/Button';
import { Content } from 'components/Common/Content';
import { checkGroupSession } from 'store/actions';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { HourInput } from 'components/Common/HourInput';
import { styles as selectStyles, theme } from '../../styles/select';
import * as styles from './GroupSchedule.module.scss';
import {
    ClockIcon, CopyIcon, Plus, Trash3Icon,
} from '../Icon/Icon';

const GroupSchedule = ({
    shopId,
    productId,
    value,
    services,
    onChange,
    errors,
    loading = false,
}) => {
    const dispatch = useDispatch();

    const deleteConfirmDialog = useConfirmDialog({
        defaultState: false,
        onConfirm: (payload) => onChange(payload),
    });
    const { locale } = useSelector((state) => state.locales);
    const employees = useSelector((state) => state.employees.employees);
    const workingHours = useSelector((state) => state.shop?.workingDays);
    const [showDuplicate, setShowDuplicate] = useState(undefined);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { t } = useTranslation();

    const daysIndexes = useMemo(() => _.times(7), []);

    const [duplicate, setDuplicate] = useState({});

    const handleDuplicate = useCallback(() => {
        setShowDuplicate(false);
        if (loading || Object.keys(duplicate).length === 0) {
            return;
        }

        const entries = Object.entries(duplicate).filter(([, value]) => value !== null);
        const duplicated = entries[0][1];
        const duplicateDay = moment().isoWeekday(Number(duplicated.day) + 1).format('dddd');
        const duplicateSession = duplicated.session;

        onChange({
            type: 'DUPLICATE',
            days: entries.map(([key]) => moment().isoWeekday(Number(key) + 1).format('dddd')),
            duplicateDay,
            duplicateSession,
        });

        setDuplicate({});
    }, [duplicate, loading, onChange]);

    const handleCheckGroupSession = useCallback((payload) => {
        const currentValue = value[payload.day][payload.index];
        if (!currentValue?.id) {
            onChange(payload);
            return;
        }
        setDeleteLoading(true);
        const formData = new FormData();
        formData.append('productId', productId);
        formData.append(`groupSessions[${payload.day.toLowerCase()}][${payload.index}][id]`, currentValue.id);
        formData.append(`groupSessions[${payload.day.toLowerCase()}][${payload.index}][timestamp]`, currentValue.from.unix());
        formData.append(`groupSessions[${payload.day.toLowerCase()}][${payload.index}][maxRegistrations]`, currentValue.maxRegistrations);
        formData.append(`groupSessions[${payload.day.toLowerCase()}][${payload.index}][showSessionsLeft]`, currentValue.showNumberOfRegistrations);
        formData.append(`groupSessions[${payload.day.toLowerCase()}][${payload.index}][service]`, currentValue.service.id);

        if (currentValue.employee?.id) {
            formData.append(`groupSessions[${payload.day.toLowerCase()}][${payload.index}][employee]`, currentValue.employee.id);
        }

        dispatch(checkGroupSession({
            shopId,
            formData,
            onSuccess: ({ result }) => {
                if (result) {
                    deleteConfirmDialog.onSetData(payload);
                    deleteConfirmDialog.onShow();
                } else {
                    onChange(payload);
                }
            },
            onFinal: () => setDeleteLoading(false),
        }));
    }, [dispatch, productId, shopId, onChange]);

    return (
        <div className={classNames(styles.container, 'border-top border-right border-light')}>
            {daysIndexes.map((i) => (
                <div key={`weekday-${i}`} className={classNames(styles.column, 'd-flex flex-column')}>
                    <div className={classNames(styles.head, 'border-left border-light')}>
                        <span className="font-size-14">{moment().locale(locale).isoWeekday(i + 1).format('dddd')}</span>
                    </div>

                    <div className="flex-grow-1 border-bottom border-left border-light">
                        {(loading ? _.times(i % 2 === 0 ? 0 : 1) : value[moment().isoWeekday(i + 1).format('dddd')]).map((session, j) => (
                            <div key={`schedule-${i}-${j}`} className="border-top border-light">
                                <Row stretched padding={16}>
                                    <Content loading={!session}>
                                        <Column stretched gap={12} padding={12}>
                                            <Column stretched gap={8}>
                                                <Row justify="between" stretched>
                                                    <Label>
                                                        {t('groupSchedule.startTime')}
                                                    </Label>
                                                    {!loading && (
                                                        <Row gap={8}>
                                                            <Popover
                                                                onHide={() => {
                                                                    setShowDuplicate(undefined);
                                                                    setDuplicate({});
                                                                }}
                                                                show={showDuplicate}
                                                                content={(
                                                                    <Column padding={16} gap={8} spacing={16}>
                                                                        {daysIndexes.map((duplicateIndex) => (
                                                                            <Row key={`duplicate-${duplicateIndex}`}>
                                                                                <CheckBox
                                                                                    checked={Boolean(duplicate[duplicateIndex])}
                                                                                    onChange={(e) => setDuplicate((prev) => ({ ...prev, [duplicateIndex]: e.target.checked ? { day: i, session: j } : null }))}
                                                                                    label={moment().locale(locale).isoWeekday(duplicateIndex + 1).format('dddd')}
                                                                                />
                                                                            </Row>
                                                                        ))}
                                                                        <Row justify="between" gap={8}>
                                                                            <Button
                                                                                color="gray"
                                                                                size="small"
                                                                                onClick={() => setShowDuplicate(false)}
                                                                            >
                                                                                {t('placeholders.cancel')}
                                                                            </Button>
                                                                            <Button
                                                                                size="small"
                                                                                onClick={handleDuplicate}
                                                                            >
                                                                                {t('placeholders.duplicate')}
                                                                            </Button>
                                                                        </Row>
                                                                    </Column>

                                                                )}
                                                            >
                                                                {({ ref, onClick }) => (
                                                                    <Tooltip tooltip={t('placeholders.duplicate')} forButton>
                                                                        <IconButton
                                                                            data-testid="remove"
                                                                            color="transparent"
                                                                            ref={ref}
                                                                            onClick={onClick}
                                                                        >
                                                                            <CopyIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </Popover>
                                                            <IconButton
                                                                data-testid="remove"
                                                                color="transparent"
                                                                onClick={() => handleCheckGroupSession({
                                                                    type: 'REMOVE',
                                                                    day: moment().isoWeekday(i + 1).format('dddd'),
                                                                    index: j,
                                                                })}
                                                                loading={deleteLoading}
                                                            >
                                                                <Trash3Icon />
                                                            </IconButton>
                                                        </Row>
                                                    )}
                                                </Row>
                                                <InputGroup>
                                                    <HourInput
                                                        allowAllHours
                                                        value={session.from}
                                                        timespan={15}
                                                        placeholder={t('groupSchedule.selectTime')}
                                                        onChange={(from) => onChange({
                                                            type: 'UPDATE',
                                                            day: moment().isoWeekday(i + 1).format('dddd'),
                                                            index: j,
                                                            from,
                                                        })}
                                                        max={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.to}
                                                        min={workingHours?.[moment().isoWeekday(i + 1).format('dddd').toLowerCase()]?.from}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="text-muted">
                                                            <ClockIcon width={18} />
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>

                                                <Form.Control.Feedback
                                                    className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].from && 'd-block'}
                                                    type="invalid"
                                                >
                                                    {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].from}
                                                </Form.Control.Feedback>
                                            </Column>

                                            <Column stretched gap={8}>
                                                <Label>
                                                    {t('groupSchedule.maxRegistrations')}
                                                </Label>
                                                <NumberInput
                                                    name="maxRegistrations"
                                                    value={session.maxRegistrations}
                                                    min={0}
                                                    max={9999}
                                                    onChange={(value) => onChange({
                                                        type: 'UPDATE',
                                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                                        index: j,
                                                        maxRegistrations: Number(value),
                                                    })}
                                                    data-testid="data-test-maxRegistrations"
                                                />
                                                <Form.Control.Feedback
                                                    className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].maxRegistrations && 'd-block'}
                                                    type="invalid"
                                                >
                                                    {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].maxRegistrations}
                                                </Form.Control.Feedback>
                                            </Column>
                                            <CheckBox
                                                data-testid="showNumberOfRegistrations"
                                                id={`showNumberOfRegistrations_${i}_${j}`}
                                                checked={session.showNumberOfRegistrations}
                                                onChange={(e) => onChange({
                                                    type: 'UPDATE',
                                                    day: moment().isoWeekday(i + 1).format('dddd'),
                                                    index: j,
                                                    showNumberOfRegistrations: e.target.checked,
                                                })}
                                                label={t('groupSchedule.showTheNumberOfRegistrations')}
                                            />
                                            <Column stretched gap={8}>
                                                <Label>
                                                    {t('groupSchedule.service')}
                                                </Label>
                                                <Select
                                                    data-testid="service"
                                                    styles={selectStyles}
                                                    theme={theme}
                                                    options={services.map(({ id, name }) => ({
                                                        value: id,
                                                        label: name,
                                                    }))}
                                                    value={session.service ? {
                                                        value: session.service.id,
                                                        label: session.service.name,
                                                    } : null}
                                                    onChange={(selected) => onChange({
                                                        type: 'UPDATE',
                                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                                        index: j,
                                                        service: {
                                                            id: selected.value,
                                                            name: selected.label,
                                                        },
                                                    })}
                                                    placeholder={t('placeholders.select')}
                                                />
                                                <Form.Control.Feedback
                                                    className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].service && 'd-block'}
                                                    type="invalid"
                                                >
                                                    {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].service}
                                                </Form.Control.Feedback>
                                            </Column>

                                            <Column stretched gap={8}>
                                                <Label>
                                                    {t('groupSchedule.employee')}
                                                </Label>
                                                <Select
                                                    isClearable
                                                    data-testid="employee"
                                                    styles={selectStyles}
                                                    theme={theme}
                                                    options={employees ? employees.map(({ id, name }) => ({
                                                        value: id,
                                                        label: name,
                                                    })) : []}
                                                    value={session.employee?.id ? { value: session.employee.id, label: session.employee.name } : null}
                                                    onChange={(selected) => onChange({
                                                        type: 'UPDATE',
                                                        day: moment().isoWeekday(i + 1).format('dddd'),
                                                        index: j,
                                                        employee: selected ? {
                                                            id: selected.value,
                                                            name: selected.label,
                                                        } : null,
                                                    })}
                                                    placeholder={t('placeholders.select')}
                                                />
                                                <Form.Control.Feedback
                                                    className={errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].employee && 'd-block'}
                                                    type="invalid"
                                                >
                                                    {errors && errors[moment().isoWeekday(i + 1).format('dddd')] && errors[moment().isoWeekday(i + 1).format('dddd')][j] && errors[moment().isoWeekday(i + 1).format('dddd')][j].employee?.id}
                                                </Form.Control.Feedback>
                                            </Column>
                                        </Column>
                                    </Content>
                                </Row>
                            </div>
                        ))}

                        <div className="border-top border-light">
                            <Row padding={16} stretched align="center" justify="center">
                                <IconButton
                                    data-testid="add"
                                    type="button"
                                    onClick={() => {
                                        if (loading) {
                                            return;
                                        }

                                        onChange({
                                            type: 'ADD',
                                            day: moment().isoWeekday(i + 1).format('dddd'),
                                        });
                                    }}
                                >
                                    <Plus />
                                </IconButton>
                            </Row>
                        </div>
                    </div>
                </div>
            ))}
            {deleteConfirmDialog.visible && (
                <ConfirmationModal
                    isShow={deleteConfirmDialog.visible}
                    hide={deleteConfirmDialog.onReject}
                    confirmAction={deleteConfirmDialog.onConfirm}
                    titleText={`${t('groupDetailsModal.delete')} ${t(
                        'groupDetailsModal.groupSession',
                    )}`}
                    bodyText={t('groupDetailsModal.areYouSureYouWantToDeleteGroupSession')}
                    deleteText={t('groupDetailsModal.delete')}
                />
            )}
        </div>
    );
};

export default GroupSchedule;
