// extracted by mini-css-extract-plugin
var _1 = "Avatar_avatar__4HxN1";
var _2 = "Avatar_avatarContainer__jeSCC";
var _3 = "Avatar_empty__U+EJg";
var _4 = "Avatar_emptyWithText__mVlA+";
var _5 = "Avatar_large__PLk3B";
var _6 = "Avatar_link__qZJ+w";
var _7 = "Avatar_medium__K0viA";
var _8 = "Avatar_small__NVIXE";
export { _1 as "avatar", _2 as "avatarContainer", _3 as "empty", _4 as "emptyWithText", _5 as "large", _6 as "link", _7 as "medium", _8 as "small" }
