import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import InputGroup from 'react-bootstrap/InputGroup';

import { PhoneIcon } from 'components/Icon/Icon';
import { FormInput } from '../FormInput';

import { COUNTRIES_CONFIG, COUNTRY_CONFIGS_ARRAY } from './COUNTRIES_CONFIG';

import { PHONE_CODE_SELECT } from './styles';
import { dangerTheme, theme } from '../../../styles/select';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import * as styles from './PhoneInput.module.scss';

const DEFAULT_MASK = '9'.repeat(24);

const PhoneInput = (props) => {
    const {
        id,
        name,
        value,
        disabled,
        isInvalid,
        defaultCountry,
        onChange,
    } = props;

    const [country, setCountry] = useState(defaultCountry);
    const [innerValue, setInnerValue] = useState('');

    const countryValue = useMemo(() => ({
        value: country.countryCode.toUpperCase(),
        label: `+${country.phoneCode}`,
    }), [country.countryCode, country.phoneCode]);

    const countriesOptions = useMemo(() => COUNTRY_CONFIGS_ARRAY.map((c) => ({
        value: c.countryCode.toUpperCase(),
        label: `${c.country}: +${c.phoneCode}`,
    })), []);

    const handleChange = useCallback(({ phoneCode, phone }) => {
        const phoneCodeInner = phoneCode || country.phoneCode;
        const phoneInner = phone || innerValue;

        const mockEvent = {
            target: {
                id,
                name,
                value: (phone !== '') ? `+${phoneCodeInner}${phoneInner}` : '',
            },
        };
        onChange(mockEvent);
    }, [
        country.phoneCode,
        innerValue,
        id,
        name,
        onChange,
    ]);

    const handleCountryChange = useCallback((selected) => {
        const newCountry = COUNTRIES_CONFIG[selected.value];
        setCountry(newCountry);
        handleChange({ phoneCode: newCountry.phoneCode });
    }, [handleChange]);

    const handlePhoneChange = useCallback((e) => {
        const { target } = e;
        const { value: phoneValue } = target;
        setInnerValue(phoneValue);
        handleChange({ phone: phoneValue });
    }, [country.phoneCode, handleChange]);

    useEffect(() => {
        const startsWithCode = value.startsWith('+');
        const newCountry = startsWithCode
            ? COUNTRY_CONFIGS_ARRAY.find((c) => value.startsWith(`+${c.phoneCode}`))
            : defaultCountry;
        setCountry(newCountry);
        const newInnerValue = startsWithCode
            ? value.slice(newCountry.phoneCode.length + 1)
            : value;
        setInnerValue(newInnerValue);
    }, [value, defaultCountry]);

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <Select
                    theme={isInvalid ? dangerTheme : theme}
                    value={countryValue}
                    options={countriesOptions}
                    styles={PHONE_CODE_SELECT}
                    isDisabled={disabled}
                    onChange={handleCountryChange}
                />
            </InputGroup.Prepend>
            <InputMask
                id={id}
                name={name}
                mask={country.phoneMask || DEFAULT_MASK}
                value={innerValue}
                disabled={disabled}
                isInvalid={isInvalid}
                maskPlaceholder={null}
                className={styles.phoneInput}
                onChange={handlePhoneChange}
            >
                <FormInput />
            </InputMask>
            <InputGroup.Append>
                <InputGroup.Text
                    className={classNames(
                        styles.append,
                        {
                            [styles.invalid]: isInvalid,
                        },
                    )}
                >
                    <PhoneIcon
                        className={classNames(
                            styles.phoneIcon,
                            {
                                [styles.invalid]: isInvalid,
                            },
                        )}
                    />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );
};

PhoneInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    defaultCountry: PropTypes.shape({
        country: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
        phoneCode: PropTypes.string.isRequired,
        phoneMask: PropTypes.string,
    }),
    onChange: PropTypes.func,
};

PhoneInput.defaultProps = {
    id: '',
    name: '',
    value: '',
    disabled: false,
    isInvalid: false,
    defaultCountry: COUNTRIES_CONFIG.NL,
    onChange: emptyFunc,
};

export default PhoneInput;
