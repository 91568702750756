import React from 'react';

const Sent = ({ className, width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        className={className}
    >
        <path d="M22.7104 11.6936C23.0276 11.8889 23.0276 12.3501 22.7102 12.5453L6.86385 22.292C6.19758 22.7019 5.33994 22.2225 5.33994 21.4403L5.33994 17.1599C5.33994 15.1661 6.80828 13.4767 8.78265 13.1989L15.9857 12.1855C16.2168 12.153 16.2145 11.8184 15.983 11.789L8.83581 10.881C6.83765 10.6271 5.33994 8.9271 5.33994 6.91288L5.33994 2.78992C5.33994 2.00752 6.19792 1.52819 6.86419 1.93835L22.7104 11.6936Z" fill="currentColor" />
    </svg>
);

export default Sent;
