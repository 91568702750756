import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { THIRD_LEVEL_NAV_HEIGHT } from 'const/navigation/DESKTOP_NAV';
import { Row } from 'components/Common/Row';
import { useSubRoute } from 'hooks/useSubRoute';
import NavigationService from '../../../../services/navigationService';

import { SubNavigation } from '../../../../components';

import * as BUNDLES_ACTIONS from '../../../../store/actions/bundles';

import routes from './scenes';

import * as styles from './page.module.scss';

const subNavigationVisibleRoutes = ['list', 'sold'];

const Bundles = () => {
    const subNavigation = NavigationService.getBundlesSubNavigation();
    const [currentSubRoute] = useSubRoute();

    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(BUNDLES_ACTIONS.setBundlesPageFirst());
        dispatch(BUNDLES_ACTIONS.setSoldBundlesPageFirst());
        dispatch(BUNDLES_ACTIONS.setBundlesSearch({ search: '' }));
        dispatch(BUNDLES_ACTIONS.setSoldBundlesSearch({ search: '' }));
    }, []);

    return (
        <React.Fragment>
            {
                subNavigationVisibleRoutes.includes(currentSubRoute) && (
                    <div className={styles.withBorderBottom}>
                        <Row spacing={32} gap={32} style={{ height: THIRD_LEVEL_NAV_HEIGHT }}>
                            <SubNavigation
                                isDataLoaded
                                subRoutes={subNavigation}
                                withQuery={false}
                            />
                        </Row>
                    </div>
                )
            }
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            component={component}
                            exact={exact}
                            path={path}
                        />
                    ))
                }

                <Redirect to={routes[0].path} />
            </Switch>
        </React.Fragment>
    );
};

export default Bundles;
