const PREFIX = '@@MULTISAFE_PAYMENTS/';

const MULTISAFE_PAYMENTS = `${PREFIX}MULTISAFE_PAYMENTS`;

export const GET_MULTISAFE_PAYMENTS = 'GET_MULTISAFE_PAYMENTS';
export const GET_MULTISAFE_PAYMENTS_TRIGGER = `${GET_MULTISAFE_PAYMENTS}.TRIGGER`;
export const GET_MULTISAFE_PAYMENTS_RESOLVED = `${GET_MULTISAFE_PAYMENTS}.RESOLVED`;
export const GET_MULTISAFE_PAYMENTS_REJECTED = `${GET_MULTISAFE_PAYMENTS}.REJECTED`;

const MULTISAFE_PAYMENTS_PAGE = `${MULTISAFE_PAYMENTS}:PAGE`;
export const MULTISAFE_PAYMENTS_PAGE_SET = `${MULTISAFE_PAYMENTS_PAGE}:SET`;
export const MULTISAFE_PAYMENTS_PAGE_SET_FIRST = `${MULTISAFE_PAYMENTS_PAGE}:FIRST`;
export const MULTISAFE_PAYMENTS_PAGE_SET_LAST = `${MULTISAFE_PAYMENTS_PAGE}:LAST`;
export const MULTISAFE_PAYMENTS_PAGE_SET_PREV = `${MULTISAFE_PAYMENTS_PAGE}:PREV`;
export const MULTISAFE_PAYMENTS_PAGE_SET_NEXT = `${MULTISAFE_PAYMENTS_PAGE}:NEXT`;
