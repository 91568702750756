import React from 'react';

const unlock = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M12 2.71664C10.6429 2.71664 9.3415 3.25572 8.38194 4.21528C7.42238 5.17484 6.8833 6.47629 6.8833 7.83331V10.2166H6.16647C4.72133 10.2166 3.5498 11.3882 3.5498 12.8333V18.6666C3.5498 20.1118 4.72133 21.2833 6.16647 21.2833H17.8331C19.2783 21.2833 20.4498 20.1118 20.4498 18.6666V12.8333C20.4498 11.3882 19.2783 10.2166 17.8331 10.2166H8.7833V7.83331C8.7833 6.9802 9.1222 6.16203 9.72544 5.55878C10.3287 4.95554 11.1469 4.61664 12 4.61664C12.8531 4.61664 13.6713 4.95554 14.2745 5.55878C14.6601 5.94441 14.9377 6.41785 15.0879 6.93222C15.2348 7.43587 15.7623 7.72501 16.266 7.57801C16.7696 7.43101 17.0588 6.90355 16.9118 6.3999C16.673 5.58191 16.2314 4.82873 15.618 4.21528C14.6584 3.25572 13.357 2.71664 12 2.71664ZM5.4498 12.8333C5.4498 12.4375 5.77067 12.1166 6.16647 12.1166H17.8331C18.2289 12.1166 18.5498 12.4375 18.5498 12.8333V18.6666C18.5498 19.0624 18.2289 19.3833 17.8331 19.3833H6.16647C5.77067 19.3833 5.4498 19.0624 5.4498 18.6666V12.8333Z" fill="currentColor" />
    </svg>

);

export default unlock;
