import { routesKeys } from './routes';

export const roles = {
    [routesKeys.activity]: ['company_owner', 'admin'],
    [routesKeys.access]: ['company_owner', 'admin'], // canOpenAccessLogs
    [routesKeys.agenda]: ['all'],
    [routesKeys.services]: ['company_owner', 'admin'], // canOpenServices
    [routesKeys.clients]: ['company_owner', 'admin'], // canOpenClients
    [routesKeys.marketing]: ['company_owner', 'admin'], // canOpenMarketing
    [routesKeys.statistics]: ['company_owner', 'admin'],
    [routesKeys.financials]: ['company_owner', 'admin'], // canOpenFinancials
    [routesKeys.settings]: ['company_owner', 'admin'], // canOpenCompanySettings
    [routesKeys.signUp]: ['all'],
    [routesKeys.signIn]: ['all'],
    [routesKeys.resetPassword]: ['all'],
    [routesKeys.resetPasswordSuccess]: ['all'],
    [routesKeys.setNewPassword]: ['all'],
};
