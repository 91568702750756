import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Badge } from 'components/Common/Badge';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { BackButton } from 'components/Common/BackButton';
import { ConfirmDialog } from 'components/Common/Dialogs/ConfirmDialog';
import { useSubRoute } from 'hooks/useSubRoute';

import {
    SubscriptionInfoFormGroup,
    SubscriptionPricingFormGroup,
    SubscriptionServicesFormGroup,
    SubscriptionAddEditFormFooter,
} from 'components';
import { SubscriptionAvailabilityToggle } from 'components/subscriptions/SubscriptionAvailabityToggle';
import { SubscriptionBuyableToggle } from 'components/subscriptions/SubscriptionBuyableToggle';

import {
    getSubscriptionsList,
    editSubscription,
    resetSubscriptionsReducer,
} from 'store/actions';

import SubscriptionDataTransformations from 'services/subscriptionDataTransformations';
import SubscriptionsServicesOptionsService from 'services/subscriptionsServicesOptionsService';

import * as SERVICES_ACTIONS from 'store/actions/services';

import * as SERVICES_SELECTORS from 'store/selectors/services';
import * as SUBSCRIPTIONS_SELECTORS from 'store/selectors/subscriptions';

import { SUBSCRIPTION_PROP } from 'const/subscriptions/SUBSCRIPTION_PROP';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { useDialog } from 'hooks/useDialog';
import { Content } from 'components/Common/Content';
import moment from 'moment';

const T_PREFIX = 'addOrEdit';
const T_FIELDS = `${T_PREFIX}.form.fields`;
const T_SERVICES_FIELDS = `${T_FIELDS}.services.fields`;

function EditSubscription() {
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const warningPriceChangeDialog = useDialog();

    const dispatch = useDispatch();
    const history = useHistory();
    const [_, id] = useSubRoute({ withDynamic: true });
    const { subscriptions, isDataLoaded } = useSelector(SUBSCRIPTIONS_SELECTORS.editSubscriptionSelector);
    const { items: companyServices, loading: companyServicesLoading } = useSelector(SERVICES_SELECTORS.companyServicesSelector);

    const currentSubscription = useMemo(() => (
        subscriptions.find((subscription) => subscription.id === Number(id))
    ), [subscriptions, id]);

    const validationSchema = useMemo(() => yup.object({
        soldCounter: yup.number(),
        subscriptionInfo: yup.object({
            title: yup
                .string()
                .min(
                    SUBSCRIPTION_PROP.TITLE.MIN_LENGTH,
                    t(`${T_FIELDS}.title.validation.minLength`, {
                        length: SUBSCRIPTION_PROP.TITLE.MIN_LENGTH,
                    }),
                )
                .max(
                    SUBSCRIPTION_PROP.TITLE.MAX_LENGTH,
                    t(`${T_FIELDS}.title.validation.maxLength`, {
                        length: SUBSCRIPTION_PROP.TITLE.MAX_LENGTH,
                    }),
                )
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: tc('subscriptions.infoFormGroup.title'),
                })),
            contractLength: yup
                .number()
                .max(
                    SUBSCRIPTION_PROP.CONTRACT_LENGTH.MAX,
                    t(`${T_FIELDS}.contractLength.validation.max`, {
                        value: SUBSCRIPTION_PROP.CONTRACT_LENGTH.MAX,
                    }),
                )
                .positive(tc('validationErrors.mustBePositiveNumber', {
                    name: tc('subscriptions.infoFormGroup.contractLength'),
                }))
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: tc('subscriptions.infoFormGroup.contractLength'),
                })),
            description: yup
                .string()
                .min(
                    SUBSCRIPTION_PROP.DESCRIPTION.MIN_LENGTH,
                    t(`${T_FIELDS}.description.validation.minLength`, {
                        length: SUBSCRIPTION_PROP.DESCRIPTION.MIN_LENGTH,
                    }),
                )
                .max(
                    SUBSCRIPTION_PROP.DESCRIPTION.MAX_LENGTH,
                    t(`${T_FIELDS}.description.validation.maxLength`, {
                        length: SUBSCRIPTION_PROP.DESCRIPTION.MAX_LENGTH,
                    }),
                ),
        }),
        pricing: yup.object({
            priceWithVAT: yup
                .number()
                .when('$soldCounter', (soldCounter, schema) => {
                    if (!soldCounter || currentSubscription.pricing.priceWithVAT === 0) {
                        return schema.min(
                            SUBSCRIPTION_PROP.PRICE.MIN,
                            tc('validationErrors.mustBeAboveOrEqualZero', {
                                name: tc('subscriptions.pricingFormGroup.priceWithVAT'),
                            }),
                        );
                    }
                    return schema.moreThan(
                        SUBSCRIPTION_PROP.PRICE.MIN,
                        tc('validationErrors.mustBeAboveZero', {
                            name: tc('subscriptions.pricingFormGroup.priceWithVAT'),
                        }),
                    );
                })
                .max(
                    SUBSCRIPTION_PROP.PRICE.MAX,
                    t(`${T_FIELDS}.price.validation.max`, {
                        value: SUBSCRIPTION_PROP.PRICE.MAX,
                    }),
                )
                .required(tc('validationErrors.cannotBeEmpty', {
                    name: tc('subscriptions.pricingFormGroup.priceWithVAT'),
                })),
        }),
        services: yup.array().of(
            yup.object({
                name: yup.array().of(
                    yup.object({
                        label: yup.string(),
                        value: yup.number(),
                    }),
                ).required(tc('validationErrors.cannotBeEmpty', {
                    name: t('addOrEdit.form.fields.services.fields.name.label'),
                })),
                maximumUsages: yup
                    .number().when('unlimitedUsages', (unlimitedUsages, schema) => {
                        if (unlimitedUsages) {
                            return schema.nullable();
                        }
                        return schema
                            .max(
                                SUBSCRIPTION_PROP.MAX_USAGES.MAX,
                                t(`${T_FIELDS}.maximumUsages.validation.max`, {
                                    value: SUBSCRIPTION_PROP.MAX_USAGES.MAX,
                                }),
                            )
                            .required(tc('validationErrors.cannotBeEmpty', {
                                name: t('addOrEdit.form.fields.services.fields.maxUsages.value.label'),
                            }))
                            .test({
                                test: (value) => value > 0 || value === -1,
                                message: tc('validationErrors.mustBePositiveNumber', {
                                    name: t('addOrEdit.form.fields.services.fields.maxUsages.value.label'),
                                }),
                            });
                    }),
                period: yup.string()
                    .required(tc('validationErrors.cannotBeEmpty')),
                unlimitedUsages: yup.bool(),
                missedUsages: yup.object({
                    selected: yup.bool().required(),
                    value: yup
                        .number()
                        .when('selected', (selected, schema) => {
                            if (!selected) {
                                return schema.nullable();
                            }
                            return schema
                                .min(
                                    SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MIN,
                                    t(`${T_SERVICES_FIELDS}.missedUsages.validation.min`, {
                                        value: SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MIN,
                                    }),
                                )
                                .max(
                                    SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MAX,
                                    t(`${T_SERVICES_FIELDS}.missedUsages.validation.max`, {
                                        value: SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.MAX,
                                    }),
                                )
                                .required(t(`${T_SERVICES_FIELDS}.missedUsages.validation.required`));
                        }),
                }).required(),
            }).required('at least one service should be added'),
        ),
    }), [t, tc]);

    useEffect(() => {
        dispatch(getSubscriptionsList());
        dispatch(SERVICES_ACTIONS.getCompanyServices());

        return () => dispatch(resetSubscriptionsReducer());
    }, []);

    const formik = useFormik({
        initialValues: currentSubscription,
        enableReinitialize: true,
        validationSchema,
        onSubmit: useCallback((values, { setSubmitting }) => {
            setSubmitting(true);
            const payload = SubscriptionDataTransformations.transformFormDataIntoPayload(values);
            const onFinal = () => setSubmitting(false);
            dispatch(editSubscription(payload, currentSubscription.id, history, onFinal));
        }, [
            dispatch,
            currentSubscription.id,
            history,
        ]),
    });

    const {
        values, errors, setFieldValue, isSubmitting,
    } = formik;

    const handleToggleActive = useCallback((value) => {
        setFieldValue('active', value);
    }, [setFieldValue]);

    const handleBuyableToggle = useCallback((value) => {
        setFieldValue('buyable', value);
    }, [setFieldValue]);

    const availableServicesOptions = useMemo(() => (
        SubscriptionsServicesOptionsService.getAvailableServicesOptions(companyServices, values)
    ), [companyServices, values]);

    const handleYesWarningPriceChangeDialog = useCallback(() => {
        warningPriceChangeDialog.onClose();
        formik.handleSubmit();
    }, [formik, warningPriceChangeDialog]);

    const hasAnySold = Boolean(currentSubscription.soldCounter);
    const isFourOrFewerDaysUntilThisMonth = moment().clone().endOf('month').diff(moment(), 'day') < 4;
    const needToWarn = hasAnySold && (currentSubscription.pricing.priceWithVAT !== values.pricing.priceWithVAT || currentSubscription.pricing.VAT.value !== values.pricing.VAT.value);
    const noErrors = Object.keys(errors).length;

    const handleSubmit = useCallback((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (needToWarn) {
            if (!noErrors) {
                warningPriceChangeDialog.onShow();
            }
        } else {
            formik.handleSubmit();
        }
    }, [needToWarn, warningPriceChangeDialog, formik, noErrors]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={tc('subscriptions.pageTitle.editSubscription')}
                before={(
                    <BackButton
                        href="/services/subscriptions/list"
                    />
                )}
                after={(
                    <Badge
                        size="small"
                        color={formik?.values?.active ? 'green' : 'red'}
                    >
                        {tc(`subscriptions.status.${formik?.values?.active ? 'enabled' : 'disabled'}`)}
                    </Badge>
                )}
            />
            <Form onSubmit={handleSubmit}>
                <Content loading={!isDataLoaded || companyServicesLoading}>
                    <div className="d-flex flex-column px-lg-5 align-self-stretch">
                        <SubscriptionInfoFormGroup formik={formik} />
                        <SubscriptionServicesFormGroup
                            formik={formik}
                            servicesOptions={availableServicesOptions}
                        />
                        <SubscriptionPricingFormGroup formik={formik} isPriceChangeDisabled={isFourOrFewerDaysUntilThisMonth} />
                        <SubscriptionAvailabilityToggle
                            values={values}
                            onToggleActive={handleToggleActive}
                        />
                        <SubscriptionBuyableToggle
                            values={values}
                            onToggleBuyable={handleBuyableToggle}
                        />
                    </div>
                    <SubscriptionAddEditFormFooter
                        withDynamic
                        services={formik.values.services}
                        isSubmitting={isSubmitting}
                    />
                </Content>
            </Form>
            {
                warningPriceChangeDialog.visible && (
                    <ConfirmDialog
                        size="md"
                        title={tc('subscriptions.pricingFormGroup.warningAnySoldDialog.title')}
                        text={tc('subscriptions.pricingFormGroup.warningAnySoldDialog.text')}
                        visible={warningPriceChangeDialog.visible}
                        onClose={warningPriceChangeDialog.onClose}
                        onReject={warningPriceChangeDialog.onClose}
                        onConfirm={handleYesWarningPriceChangeDialog}
                        rejectProps={{ label: tc('placeholders.no') }}
                        confirmProps={{
                            label: tc('placeholders.yes'),
                        }}
                    />
                )
            }
        </React.Fragment>
    );
}

export default EditSubscription;
