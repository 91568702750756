import moment from 'moment';
import { store } from 'store/createStore';

export default function momentGMT0Time(externalDate) {
    const state = store.getState();
    const { locale } = state.locales;
    if (externalDate) {
        return moment.unix(externalDate).clone().tz('Etc/GMT0').locale(locale);
    }
    return moment().clone().add(moment().clone().tz('Etc/GMT0').utcOffset(), 'minute').locale(locale);
}
