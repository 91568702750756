import Payouts from './payouts';
import MultisafePayouts from './multisafePayouts';
import Clearings from './clearings';

const routes = [
    {
        component: MultisafePayouts,
        exact: true,
        multisafe: true,
        path: '/financials/accounting/multisafe-payouts',
    },
    {
        component: Clearings,
        exact: true,
        multisafe: false,
        path: '/financials/accounting/clearings',
    },
    {
        component: Payouts,
        exact: true,
        multisafe: false,
        path: '/financials/accounting/payouts',
    },
];

export default routes;
