import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loaded: false,
    avatar: null,
    name: null,
    defaultTab: null,
    soundNotificationsEnabled: null,
    canEditOwnAgenda: false,
    canOpenAccessLogs: false,
    canOpenClients: false,
    canOpenCompanySettings: false,
    canOpenFinancials: false,
    canOpenMarketing: false,
    canOpenServices: false,
    canOpenActivity: false,
};

export const user = (state = initialState, { type, payload }) => {
    switch (type) {
    case actionTypes.GET_USER_DETAILS_RESOLVED: {
        const {
            avatarUrl: avatar,
            firstName,
            lastName,
            email,
            defaultTab,
            soundNotificationsEnabled,
            intercomHash,
            canEditOwnAgenda,
            canOpenAccessLogs,
            canOpenClients,
            canOpenCompanySettings,
            canOpenFinancials,
            canOpenMarketing,
            canOpenServices,
            canOpenActivity,
        } = payload;
        return {
            ...state,
            loaded: true,
            avatar,
            name: firstName,
            fullName: (`${firstName || ''} ${lastName || ''}`).trim(),
            email,
            defaultTab,
            soundNotificationsEnabled,
            loginDate: Date.now(),
            intercomHash,
            canEditOwnAgenda,
            canOpenAccessLogs,
            canOpenClients,
            canOpenCompanySettings,
            canOpenFinancials,
            canOpenMarketing,
            canOpenServices,
            canOpenActivity,
        };
    }
    case actionTypes.SET_DEFAULT_TAB_RESOLVED: {
        return {
            ...state,
            ...payload,
        };
    }
    case actionTypes.TOGGLE_SOUND_NOTIFICATIONS_RESOLVED: {
        return {
            ...state,
            ...payload,
        };
    }
    case actionTypes.GET_USER_DETAILS_REJECTED:
    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    default:
        return state;
    }
};
