import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { GatesTableHeader } from 'components/Access/Gates/GatesTableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { GatesTableBodyRow } from 'components/Access/Gates/GatesTableBodyRow';
import * as ACCESS_ACTIONS from 'store/actions/access';
import * as ACCESS_SELECTORS from 'store/selectors/access';
import TextSubHeader from 'components/Layout/TextSubHeader/TextSubHeader';
import { Button } from 'components/Common/Button';
import { ConfirmDialog } from 'components/Common/Dialogs/ConfirmDialog';
import { Trans, useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ACCESS_GATE_EDIT_ITEM_PAGE } from 'const/CLIENT_URL';

const COLUMNS_COUNT = 5;

const GatesList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const history = useHistory();

    const addGateDialog = useConfirmDialog();

    const {
        page,
        items: gates,
        showAll,
        isLoading,
        itemsCount,
        pagesCount,
        itemsPerPage,
        search,
        sortOptions,
    } = useSelector(ACCESS_SELECTORS.accessGatesListSelector);

    const handlePrevPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessGatesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessGatesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessGatesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessGatesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(ACCESS_ACTIONS.showAllAccessGates());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(ACCESS_ACTIONS.setAccessGatesPageFirst());
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(ACCESS_ACTIONS.setAccessGatesPage({ page: nextPage }));
    }, [dispatch]);

    const handleShowDetails = useCallback(({ gate }) => {
        history.push(ACCESS_GATE_EDIT_ITEM_PAGE({ id: gate.id }));
    }, []);

    useEffect(() => {
        dispatch(ACCESS_ACTIONS.getAccessGates());
    }, [
        dispatch,
        page,
        search,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    useEffect(() => () => dispatch(ACCESS_ACTIONS.clearAccessGates()), []);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('gates.title')}
                rightActions={(
                    <Button onClick={addGateDialog.onShow}>
                        {t('gates.action.add')}
                    </Button>
                )}
            />
            <Container>
                <Content loading={isLoading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <GatesTableHeader />
                        </TableHeader>
                        <TableBody>
                            {gates?.map((gate) => (
                                <GatesTableBodyRow
                                    key={gate.id}
                                    gate={gate}
                                    onClick={handleShowDetails}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </Content>
            </Container>
            <ConfirmDialog
                size="md"
                text={(
                    <Trans ns={LOCALE_NAMESPACE.ACCESS} i18nKey="gates.addGateModal.text">
                        <a target="_blank" rel="noreferrer" href={t('gates.addGateModal.email')} />
                    </Trans>
                )}
                visible={addGateDialog.visible}
                confirmProps={{
                    label: t('gates.addGateModal.submit'),
                }}
                rejectProps={{
                    label: t('gates.addGateModal.cancel'),
                }}
                onClose={addGateDialog.onReject}
                onConfirm={addGateDialog.onConfirm}
                onConfirmHref={t('gates.addGateModal.email')}
                onReject={addGateDialog.onReject}
            />
        </React.Fragment>

    );
};

export default GatesList;
