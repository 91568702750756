import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { Dropdown, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { MoreHorizontalIcon, Bell, PinIcon } from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import Switch from 'components/Switch/Switch';

function MobileAgendaActions({
    isDataLoaded,
    avaliableDefaultTabs,
    onDefaultTabSelect,
    defaultTab,
    soundNotificationsEnabled,
    onSoundNotificationsToggle,
}) {
    const { t } = useTranslation();
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const handleSoundNotificationsToggle = useCallback(debounce((value) => {
        onSoundNotificationsToggle(value);
    }, 500), []);

    const handleDefaultTabSelect = useCallback(debounce((value) => {
        onDefaultTabSelect(value);
    }, 500), []);

    if (!isDataLoaded) {
        return (
            <span
                className="mr-4 mb-1"
            >
                <Skeleton
                    height={16}
                    width={40}
                />
            </span>
        );
    }

    // mobile view
    return (
        <Dropdown
            className="mr-4 mb-1"
            data-testid="defaultTabDropdown"
            onToggle={(isOpen) => setIsDropdownOpened(isOpen)}
        >
            <Dropdown.Toggle
                bsPrefix="dropdown-toggle-no-arrow"
                variant="outline-light"
                className={`d-flex align-items-center justify-content-center border-0 p-0 no-hover ${isDropdownOpened ? 'text-primary' : 'text-dark'}`}
                style={{ height: '40px', width: '40px', backgroundColor: 'transparent' }}
            >
                <MoreHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
                <React.Fragment>
                    <Dropdown.ItemText>
                        <Row noGutters className="flex-nowrap justify-content-between" style={{ width: 310, padding: '5px 0' }}>
                            <Row noGutters className="flex-nowrap">
                                <Bell className="mr-2 text-dark" width={24} />
                                {t('agendaRoute.notificationsBell.text')}
                            </Row>
                            <Switch
                                className="ml-2"
                                checked={soundNotificationsEnabled}
                                onChange={handleSoundNotificationsToggle}
                            />
                        </Row>
                    </Dropdown.ItemText>
                    <Dropdown.Divider />
                </React.Fragment>
                <Dropdown.ItemText>
                    <Row noGutters className="flex-nowrap py-2">
                        <PinIcon className="mr-2 text-dark" width={24} />
                        {t('agendaRoute.setDefaultTab.text')}
                    </Row>
                </Dropdown.ItemText>
                {avaliableDefaultTabs.map((tab) => (
                    <Dropdown.Item
                        key={`route-${tab}`}
                        active={tab === defaultTab}
                        eventKey={tab}
                        onClick={() => handleDefaultTabSelect(tab)}
                    >
                        {t(`agendaRoute.${tab}`)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

MobileAgendaActions.propTypes = {
    isDataLoaded: PropTypes.bool.isRequired,
    avaliableDefaultTabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    onDefaultTabSelect: PropTypes.func.isRequired,
    defaultTab: PropTypes.string.isRequired,
    soundNotificationsEnabled: PropTypes.bool.isRequired,
    onSoundNotificationsToggle: PropTypes.func.isRequired,
};

export default MobileAgendaActions;
