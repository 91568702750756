import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getActivityList = createPromiseAction(actionTypes.GET_CLIENTS_ACTIVITY_LIST);

export const setActivityListSortOptions = ({ sortOptions }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_SORT_OPTIONS_SET,
    payload: { sortOptions },
});

export const showAllActivityList = () => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_SHOW_ALL,
});

export const setActivityListPage = ({ page }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_PAGE_SET,
    payload: { page },
});

export const setActivityListPageFirst = () => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_PAGE_SET_FIRST,
});

export const setActivityListPageLast = () => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_PAGE_SET_LAST,
});

export const setActivityListPagePrev = () => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_PAGE_SET_PREV,
});

export const setActivityListPageNext = () => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_PAGE_SET_NEXT,
});

export const clearActivityList = () => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_CLEAR,
});

export const setActivityListSearch = ({ search }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_SEARCH_SET,
    payload: { search },
});

export const setActivityListLocation = ({ location }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_LOCATION_SET,
    payload: { location },
});

export const setActivityListTypes = ({ types }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_TYPES_SET,
    payload: { types },
});

export const setActivityListActions = ({ actions }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_ACTIONS_SET,
    payload: { actions },
});

export const setActivityListSelectedDate = ({ dateFrom, dateTo, period }) => ({
    type: actionTypes.CLIENTS_ACTIVITY_LIST_SELECTED_DATE_SET,
    payload: { dateFrom, dateTo, period },
});
