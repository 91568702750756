import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'components/Common/Button';
import { useDialog } from '../../../../../../hooks/useDialog';

import AddNewLocationDialog from '../../../../../../components/CompanySettingsLocations/AddNewLocationDialog';

import { ArrowSmallRight, EmptyTableIcon, Plus } from '../../../../../../components/Icon/Icon';

import * as styles from './page.module.scss';

import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';
import {
    SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE,
} from '../../../../../../const/CLIENT_URL';

const T_PREFIX = 'locations';

const Locations = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SETTINGS);
    const { shops } = useSelector((state) => state.company);

    const addNewLocationDialog = useDialog(false);

    return (
        <div className={styles.locationsContainer}>
            {shops.length
                ? shops.map((shop) => (
                    <Link
                        key={shop.id}
                        to={SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE({ id: shop.id })}
                        className={styles.locationCard}
                    >
                        <div className={styles.locationCardInfo}>
                            <p className={styles.locationCardInfoTitle}>
                                {shop.name}
                            </p>

                            <p className={styles.locationCardInfoAddress}>
                                {[shop.country, shop.city, shop.street, shop.zip].join(', ')}
                            </p>
                        </div>

                        <ArrowSmallRight />
                    </Link>
                ))
                : (
                    <div className={styles.emptyListContainer}>
                        <EmptyTableIcon width={188} height={188} />

                        {t(`${T_PREFIX}.noLocationYet`)}
                    </div>
                )}

            <Button
                type="button"
                color={shops.length ? 'outline' : 'yellow'}
                onClick={addNewLocationDialog.onShow}
                before={<Plus />}
                stretched
            >

                {t(`${T_PREFIX}.actions.addNewLocation.label`)}
            </Button>

            <AddNewLocationDialog
                isVisible={addNewLocationDialog.visible}
                onClose={addNewLocationDialog.onClose}
            />
        </div>
    );
};

export default Locations;
