const CLIENTS_ACCESS_LOGS = 'CLIENTS_ACCESS_LOGS';
export const CLIENTS_ACCESS_LOGS_CLEAR = `${CLIENTS_ACCESS_LOGS}:CLEAR`;

export const GET_CLIENTS_ACCESS_LOGS = 'GET_CLIENTS_ACCESS_LOGS';
export const GET_CLIENTS_ACCESS_LOGS_TRIGGER = `${GET_CLIENTS_ACCESS_LOGS}.TRIGGER`;
export const GET_CLIENTS_ACCESS_LOGS_RESOLVED = `${GET_CLIENTS_ACCESS_LOGS}.RESOLVED`;
export const GET_CLIENTS_ACCESS_LOGS_REJECTED = `${GET_CLIENTS_ACCESS_LOGS}.REJECTED`;

const CLIENTS_ACCESS_LOGS_PAGE = `${CLIENTS_ACCESS_LOGS}:PAGE`;
export const CLIENTS_ACCESS_LOGS_PAGE_SET = `${CLIENTS_ACCESS_LOGS_PAGE}:SET`;
export const CLIENTS_ACCESS_LOGS_PAGE_SET_FIRST = `${CLIENTS_ACCESS_LOGS_PAGE_SET}:FIRST`;
export const CLIENTS_ACCESS_LOGS_PAGE_SET_LAST = `${CLIENTS_ACCESS_LOGS_PAGE_SET}:LAST`;
export const CLIENTS_ACCESS_LOGS_PAGE_SET_PREV = `${CLIENTS_ACCESS_LOGS_PAGE_SET}:PREV`;
export const CLIENTS_ACCESS_LOGS_PAGE_SET_NEXT = `${CLIENTS_ACCESS_LOGS_PAGE_SET}:NEXT`;
export const CLIENTS_ACCESS_LOGS_SHOW_ALL = `${CLIENTS_ACCESS_LOGS}:SHOW_ALL`;
const CLIENTS_ACCESS_LOGS_SEARCH = `${CLIENTS_ACCESS_LOGS}:SEARCH`;
export const CLIENTS_ACCESS_LOGS_SEARCH_SET = `${CLIENTS_ACCESS_LOGS_SEARCH}:SET`;
const CLIENTS_ACCESS_LOGS_SORT_OPTIONS = `${CLIENTS_ACCESS_LOGS}:SORT_OPTIONS`;
export const CLIENTS_ACCESS_LOGS_SORT_OPTIONS_SET = `${CLIENTS_ACCESS_LOGS_SORT_OPTIONS}:SET`;

const CLIENTS_ACCESS_LOGS_SELECTED_DATE = `${CLIENTS_ACCESS_LOGS}:SELECTED_DATE`;
export const CLIENTS_ACCESS_LOGS_SELECTED_DATE_SET = `${CLIENTS_ACCESS_LOGS_SELECTED_DATE}:SET`;

const CLIENTS_ACCESS_LOGS_FILTER = `${CLIENTS_ACCESS_LOGS}:FILTER`;
export const CLIENTS_ACCESS_LOGS_FILTER_SET = `${CLIENTS_ACCESS_LOGS_FILTER}:SET`;
