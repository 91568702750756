import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import camelize from 'camelize';
import { get } from 'lodash';

import { rejectPromiseAction, resolvePromiseAction } from '@adobe/redux-saga-promise';
import axios from '../../../services/axios';
import { getDefaultHeaders } from '../../../helpers/http/getDefaultHeaders';
import { retryWithRefreshToken } from '../../../helpers/sagas/retryWithRefreshToken';

import * as MULTISAFE_PAYOUTS_ACTIONS from '../../actions/financials/multisafePayouts';
import * as TOAST_ACTIONS from '../../actions/toast';
import * as FINANCIALS_SELECTORS from '../../selectors/financilas';
import { HTTP_METHOD } from '../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../const/http/HTTP_STATUS_CODE';
import { API_ADMIN_COMPANY_MULTISAFEPAY_PAYOUTS_LIST_ROUTE } from '../../../const/API_URL';

function* getMultisafePayouts(action) {
    const defaultHeaders = yield getDefaultHeaders();
    const { page, showAll } = yield select(FINANCIALS_SELECTORS.multisafePayoutsListSelector);

    try {
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_COMPANY_MULTISAFEPAY_PAYOUTS_LIST_ROUTE,
            headers: defaultHeaders,
            params: {
                page: showAll ? 0 : page,
            },
        });

        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const { items, itemsCount, pagesCount } = result;
            const payments = items.map(camelize);
            yield call(resolvePromiseAction, action, { payments, itemsCount, pagesCount });
        }
    } catch (error) {
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: get(error, 'response.data.result.message', '') ?? 'multisafePayouts.get.error',
                appearance: 'error',
            }));
        }

        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
        yield call(rejectPromiseAction, action, error);
    }
}

export const multisafePayoutsListSaga = [
    takeLatest(MULTISAFE_PAYOUTS_ACTIONS.getMultisafePayouts, getMultisafePayouts),
];
