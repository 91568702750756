import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { BUNDLES_ADD_PAGE } from 'const/CLIENT_URL';
import { CopyIcon, Pen2Icon, Trash3Icon } from '../../Icon/Icon';

import { Switch } from '../../index';
import { Tooltip } from '../../Common/Tooltip';

import { emptyFunc } from '../../../helpers/function/emptyFunc';
import { formatPrice } from '../../../helpers/formatters/formatPrice';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './BundlesListTableRow.module.scss';

const UNBREAKABLE_SPACE = '\xa0';

const INFINITY_SYMBOL = '∞';

const T_PREFIX = 'list.table.row';

const BundlesListTableRow = (props) => {
    const {
        bundle,
        onEdit,
        onClick,
        onArchive,
        onToggleStatus,
    } = props;

    const history = useHistory();
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const maxUsages = useMemo(() => {
        if (!bundle?.maxUsages) {
            return '';
        }
        const { value, unlimited } = bundle.maxUsages;
        return unlimited ? INFINITY_SYMBOL : value;
    }, [bundle?.maxUsages]);

    const validity = useMemo(() => {
        if (!bundle?.validity) {
            return '';
        }
        const { value, unit, unlimited } = bundle.validity;
        if (unlimited) {
            return INFINITY_SYMBOL;
        }
        const unitString = t(`${T_PREFIX}.fields.validity.units.${unit}`, {
            count: value,
        });
        return `${value}${UNBREAKABLE_SPACE}${unitString}`;
    }, [bundle?.validity, t]);

    const handleClick = useCallback(() => {
        if (!bundle?.id) {
            return;
        }
        onClick({ bundleId: bundle.id });
    }, [onClick, bundle?.id]);

    const handleEdit = useCallback((e) => {
        e.stopPropagation();
        if (!bundle?.id) {
            return;
        }
        onEdit({ bundleId: bundle.id });
    }, [onEdit, bundle?.id]);

    const handleArchive = useCallback((e) => {
        e.stopPropagation();
        if (!bundle?.id) {
            return;
        }
        onArchive({ bundleId: bundle.id });
    }, [onArchive, bundle?.id]);

    const handleToggleStatus = useCallback((_val, e) => {
        e.stopPropagation();
        if (!bundle?.id) {
            return;
        }
        onToggleStatus({ bundleId: bundle.id });
    }, [onToggleStatus, bundle?.id]);

    const handleClone = useCallback(() => {
        if (!bundle?.id) {
            return;
        }
        history.push(BUNDLES_ADD_PAGE, { bundleId: bundle.id });
    }, [history.push, bundle?.id]);

    return (
        <ListTableBodyRow
            onClick={handleClick}
        >
            <ListTableBodyCell>
                {bundle?.name}
            </ListTableBodyCell>
            <ListTableBodyCell className={styles.services}>
                {bundle?.services?.map?.((s) => (
                    <div key={s.id}>
                        <Tooltip
                            tooltip={s.name}
                            placement="top-start"
                        >
                            <span className="w-100">
                                {s.name}
                            </span>
                        </Tooltip>
                        <br />
                    </div>
                ))}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                {maxUsages}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                {validity}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-muted">
                <Switch
                    checked={bundle?.status}
                    onChange={handleToggleStatus}
                />
            </ListTableBodyCell>
            <ListTableBodyCell align="right">
                {formatPrice.toEuroWithComma({ amount: bundle?.price })}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <Row gap={8}>
                    <Tooltip
                        tooltip={t(`${T_PREFIX}.actions.edit`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            color="gray"
                            size={32}
                            disabled={!bundle?.id}
                            onClick={handleEdit}
                        >
                            <Pen2Icon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        tooltip={t(`${T_PREFIX}.actions.archive`)}
                        placement="top"
                        forButton
                    >
                        <IconButton
                            color="gray"
                            size={32}
                            disabled={!bundle?.id}
                            onClick={handleArchive}
                        >
                            <Trash3Icon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip forButton tooltip={tc('placeholders.duplicate')} placement="bottom">
                        <IconButton
                            onClick={handleClone}
                            color="gray"
                            size={32}
                        >
                            <CopyIcon height={24} width={24} />
                        </IconButton>
                    </Tooltip>
                </Row>
            </ListTableBodyCell>
        </ListTableBodyRow>
    );
};

BundlesListTableRow.propTypes = {
    bundle: PropTypes.object,
    isInteractive: PropTypes.bool,
    onEdit: PropTypes.func,
    onClick: PropTypes.func,
    onArchive: PropTypes.func,
    onToggleStatus: PropTypes.func,
};

BundlesListTableRow.defaultProps = {
    bundle: null,
    isInteractive: false,
    onEdit: emptyFunc,
    onClick: emptyFunc,
    onArchive: emptyFunc,
    onToggleStatus: emptyFunc,
};

export default BundlesListTableRow;
