import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ClockIcon } from '../../Icon/Icon';

import * as styles from './SoldSubscriptionBookingsListItem.module.scss';

const SoldSubscriptionBookingsListItem = (props) => {
    const { booking } = props;

    const date = booking?.date && moment.unix(booking.date);

    return (
        <li className={styles.bookingsListItem}>
            <span
                title={booking?.objectName}
                className={styles.objectName}
            >
                {booking?.objectName}
            </span>
            <div className={styles.dateInfo}>
                {booking?.isRecurring && (
                    <ClockIcon
                        width={18}
                        className={styles.clockIcon}
                    />
                )}
                <time dateTime={date?.format?.()}>
                    {date?.format?.('DD MMM YYYY, hh:mm')}
                </time>
            </div>
        </li>
    );
};

SoldSubscriptionBookingsListItem.propTypes = {
    booking: PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.number,
        isRecurring: PropTypes.bool,
        objectName: PropTypes.string,
    }),
};

SoldSubscriptionBookingsListItem.defaultProps = {
    booking: null,
};

export default SoldSubscriptionBookingsListItem;
