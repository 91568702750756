import {
    put,
    select,
    call,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import {
    resolvePromiseAction,
    rejectPromiseAction,
} from '@adobe/redux-saga-promise';

// Actions

// Utils
import axios from 'services/axios';
import qs from 'querystring-es3';
import camelize from 'camelize';
import moment from 'moment';
import sentry from 'services/sentry';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions';

const ENUMS = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 4,
    Thursday: 8,
    Friday: 16,
    Saturday: 32,
    Sunday: 64,
};

export function* getBookings(action) {
    const { from, to, productType } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/product-bookings/${productType}`,
            params: {
                from,
                to,
            },
            headers: defaultHeaders,
        });

        const bookings = Object.values(resp.data.result).reduce(
            (bookings, product) => [
                ...bookings,
                ...product.bookings.map(
                    ({
                        id,
                        service: serviceName,
                        timestamp: from,
                        add_time: addTime,
                        minutes,
                        price,
                        client: {
                            id: clientID,
                            name,
                            firstName,
                            lastName,
                            phone: contactNumber,
                            email,
                            note,
                        },
                        paid,
                        payment_type: paymentType,
                        payment_info: paymentInfo,
                        payment_system: paymentSystem,
                        final_cost: finalCost,
                        is_recurring: isRecurring,
                        number_of_weeks: recurrencePeriod,
                        group_session_id: groupSessionID,
                        is_infinite_recurring: infinite,
                        recurrence_end: recurrenceEnd,
                        recurrence_days: recurrenceDays,
                        is_bought_with_bundle: boughtWithBundle,
                        seats_amount: seats,
                        in_payment_process: isInProgress,
                        feedback,
                        has_already_started: alreadyStarted,
                        not_present: notPresent,
                        is_editable: isEditable,
                        has_past: hasPast,
                        cancellation_policy: cancellationPolicy,
                        made_from_app: madeFromApp,
                        made_from_widget: madeFromWidget,
                        length,
                    }) => ({
                        id,
                        service: {
                            name: serviceName,
                        },
                        product: {
                            id: product.id,
                            name: product.name,
                            type: productType === 'basic' ? 'object' : productType,
                        },
                        groupSession: groupSessionID
                            ? {
                                id: groupSessionID,
                            }
                            : null,
                        time: {
                            from: moment(from * 1000).valueOf(),
                            to: moment(from * 1000)
                                .add(minutes, 'minutes')
                                .valueOf(),
                            extraMinutes: Number(addTime) ?? 0,
                            length,
                        },
                        price,
                        client: {
                            id: clientID,
                            name,
                            firstName,
                            lastName,
                            contactNumber,
                            email,
                            note,
                        },
                        payment: {
                            paymentInfo,
                            paidUpfront: paid ? finalCost : 0,
                            finalCost,
                            remainingCost: paid ? 0 : finalCost,
                            paid,
                            isInProgress,
                            usedBundle: boughtWithBundle,
                            type: paymentType,
                            paymentSystem,
                        },
                        recurrence: {
                            enabled: isRecurring,
                            period: recurrencePeriod,
                            infinite,
                            end: recurrenceEnd * 1000,
                            days: recurrenceDays.map((day) => day.replace(/^\w/, (c) => c.toUpperCase())),
                        },
                        boughtWithBundle,
                        seats,
                        feedback: feedback && {
                            rating: feedback.rated,
                            comment: feedback.comment,
                        },
                        alreadyStarted,
                        notPresent,
                        isEditable,
                        hasPast,
                        cancellationPolicy,
                        madeFromApp,
                        madeFromWidget,
                    }),
                ),
            ],
            [],
        );

        yield put(actions.getBookingsSucceeded(bookings, productType));
        yield put(actions.setBookingsFetchRange(from, to));
        // yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.getBookingsFailed(productType));
            yield call(rejectPromiseAction, action, message);
            return;
        }

        sentry.error(err);
        yield put(actions.getBookingsFailed(productType));
        yield call(rejectPromiseAction, action);
    }
}

export function* addBooking(action) {
    const { booking } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const recurrenceDays = booking.recurrence.days.reduce(
            (sum, day) => sum + ENUMS[day],
            0,
        );

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/booking/add/${shopID}/${booking.product.id}`,
            data: qs.stringify({
                timestamp: booking.time.from.unix(),
                clientName: booking.client?.clientName,
                clientId: booking.client.clientId,
                clientPhone: booking.client.clientPhone.replace(/\s/g, ''),
                clientEmail: booking.client.clientEmail,
                serviceId: booking.subOptionService?.id ?? booking.service.id,
                eventDayId: booking.product.eventId,
                groupSessionId: booking.product.groupSessionId,
                recurring: Number(booking.recurrence.enabled),
                recurrencePeriod: booking.recurrence.period,
                recurrenceEnd: booking.recurrence.end?.unix(),
                recurrenceDays,
                indefiniteRecurrence: Number(booking.recurrence.infinite),
                paymentStatus: Number(booking.paid),
                paymentType: booking.paymentType,
                paymentEntityId: booking.paymentEntityId,
                notNotifyClient: booking.notNotifyClient,
                note: booking.description,
            }),
            headers: defaultHeaders,
        });

        const result = camelize(resp.data.result);

        const resultBooking = {
            id: result.id,
            service: {
                id: booking.service.id,
                name: result.service,
            },
            product: {
                id: result.solariumId,
                name: result.solariumName,
                type: booking.product.type,
                groupSessionId: booking.product.groupSessionId,
            },
            time: {
                from: result.timestamp * 1000,
                to: moment(result.timestamp * 1000)
                    .add(result.minutes, 'minute')
                    .valueOf(),
                extraMinutes: result.addTime ?? 0,
            },
            client: {
                name: result.client.name,
                contactNumber: result.client.phone,
                email: result.client.email,
                firstName: booking.client.firstName,
                lastName: booking.client.lastName,
                note: booking.description,
            },
            payment: {
                paidUpfront: result.paid ? result.finalCost : 0,
                finalCost: result.finalCost,
                remainingCost: result.paid ? 0 : result.finalCost,
                paid: result.paid,
                type: result.paymentType,
            },
            recurrence: {
                ...booking.recurrence,
                enabled: result.isRecurring,
            },
            alreadyStarted: result.hasAlreadyStarted,
            notPresent: result.notPresent,
            isEditable: result.isEditable,
            seats: result.seatsAmount,
            hasPast: result.hasPast,
        };

        yield put(actions.addBookingSucceeded(resultBooking));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;
            const message = err.response.data.result.errors.solarium;

            yield put(actions.addBookingFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    time: {
                        from: errors.datetime,
                    },
                    client: {
                        clientName: errors.clientName ?? errors['client.firstName'],
                        iban: errors['client.iban'],
                        contactNumber: errors.clientPhone,
                        email: errors.clientEmail,
                    },
                    service: errors.serviceId,
                    recurrence: {
                        enabled: errors.recurring,
                        period: errors.recurrencePeriod,
                        end: errors.recurrenceEnd,
                        days: errors.recurrenceDays,
                        infinite: errors.indefiniteRecurrence,
                    },
                },
                message,
            });
            return;
        } if (err.response?.data) {
            const { message } = err.response.data.result;

            yield put(actions.addBookingFailed());
            yield call(rejectPromiseAction, action, {
                message,
            });
            return;
        }
        sentry.error(err);

        yield put(actions.addBookingFailed());
        yield call(rejectPromiseAction, action, {
            message: null,
            errors: null,
        });
    }
}

export function* getBookingPaymentOptions(action) {
    try {
        const {
            payload: { booking },
        } = action;

        const defaultHeaders = yield getDefaultHeaders();

        const recurrenceDays = booking.recurrence.days.reduce(
            (sum, day) => sum + ENUMS[day],
            0,
        );

        const bookingData = {
            solarium_id: booking.product.id,
            package_id: booking.subOptionService?.id ?? booking.service.id,
            timestamp: booking.time.from.unix(),
            clientId: booking.client.clientId,
            clientPhone: booking.client.clientPhone.replace(/\s/g, ''),
            clientEmail: booking.client.clientEmail,
            recurring: Number(booking.recurrence.enabled),
            recurrencePeriod: booking.recurrence.period,
            recurrenceDays,
            indefiniteRecurrence: Number(booking.recurrence.infinite),
        };

        if (bookingData.recurring && !bookingData.indefiniteRecurrence) {
            bookingData.recurrenceEnd = booking.recurrence.end?.unix();
        }

        const { data } = yield axios.request({
            _action: action,
            method: 'POST',
            url: '/api/admin/booking/options/get',
            data: qs.stringify(bookingData),
            headers: defaultHeaders,
        });

        yield put(actions.getBookingPaymentOptionsSucceeded());
        yield call(resolvePromiseAction, action, data.result.options);
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;
            const message = err.response.data.result.errors.solarium;

            yield put(actions.getBookingPaymentOptionsFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    time: {
                        from: errors.datetime,
                    },
                    client: {
                        contactNumber: errors.clientPhone,
                        email: errors.clientEmail,
                    },
                    service: errors.package_id,
                    recurrence: {
                        period: errors.recurrencePeriod,
                        end: errors.recurrenceEnd,
                        days: errors.recurrenceDays,
                    },
                },
                message,
            });
            return;
        }
        if (err.response?.data) {
            const { message } = err.response.data.result;

            yield put(actions.getBookingPaymentOptionsFailed());
            yield call(rejectPromiseAction, action, {
                message,
            });
            return;
        }

        yield put(actions.getBookingPaymentOptionsFailed());
        yield call(rejectPromiseAction, action, {
            message: null,
            errors: null,
        });
    }
}

export function* editBooking(action) {
    const {
        payload: { booking },
    } = action;

    try {
        const defaultHeaders = yield getDefaultHeaders();

        const recurrenceDays = booking.recurrence.days.reduce(
            (sum, day) => sum + ENUMS[day],
            0,
        );

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/booking/${booking.id}/edit`,
            data: qs.stringify({
                timestamp: booking.time.from.unix(),
                recurring: Number(booking.recurrence.enabled),
                recurrencePeriod: booking.recurrence.period,
                recurrenceEnd: booking.recurrence.end?.unix(),
                recurrenceDays,
                indefiniteRecurrence: Number(booking.recurrence.infinite),
            }),
            headers: defaultHeaders,
        });

        const result = camelize(resp.data.result.booking);

        const resultBooking = {
            id: result.id,
            service: {
                id: booking.service.id,
                name: result.service,
            },
            product: {
                id: result.solariumId,
                name: result.solariumName,
            },
            time: {
                from: result.timestamp * 1000,
                to: moment(result.timestamp * 1000)
                    .add(result.minutes, 'minute')
                    .valueOf(),
                extraMinutes: result.addTime ?? 0,
            },
            client: {
                name: result.client.name,
                contactNumber: result.client.phone,
                email: result.client.email,
            },
            payment: {
                ...booking.payment,
                paidUpfront: result.paid ? result.finalCost : 0,
                finalCost: result.finalCost,
                remainingCost: result.paid ? 0 : result.finalCost,
                paid: result.paid,
                type: result.paymentType,
            },
            recurrence: {
                ...booking.recurrence,
                enabled: result.isRecurring,
            },
            alreadyStarted: result.hasAlreadyStarted,
            notPresent: result.notPresent,
            isEditable: result.isEditable,
            hasPast: result.hasPast,
        };

        yield put(actions.editBookingSucceeded(resultBooking));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (
            err.response
            && err.response.data
            && err.response.data.result
            && err.response.data.result.errors
        ) {
            const { errors } = err.response.data.result;

            yield put(actions.addBookingFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    time: { from: errors.timestamp },
                    recurrence: { end: errors.recurrenceEnd },
                },
            });
            return;
        }

        if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.editBookingFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }
        sentry.error(err);

        yield put(actions.editBookingFailed());
        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

export function* deleteBooking(action) {
    const { booking, timestamp, isWithCancellationFee } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = {
            timestamp,
        };
        if (isWithCancellationFee !== undefined) {
            data.isWithCancellationFee = Number(isWithCancellationFee);
        }

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/booking/delete/${shopID}/${booking.id}`,
            data: qs.stringify(data),
            headers: defaultHeaders,
        });

        yield put(actions.deleteBookingSucceeded(booking, timestamp * 1000));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.deleteBookingFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        sentry.error(err);

        yield put(actions.deleteBookingFailed());
        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

export function* omitBooking(action) {
    const { bookingID, timestamp } = action.payload;
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/booking/set-present-status/${shopID}/${bookingID}`,
            params: { timestamp, notPresent: 0 },
            headers: defaultHeaders,
        });

        yield put(actions.omitBookingSucceeded(bookingID, timestamp * 1000));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.omitBookingFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        sentry.error(err);

        yield put(actions.omitBookingFailed());
        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

export function* changePayStatus(action) {
    const { bookingID, timestamp, paymentStatus } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/booking/set-payment-status/${shopID}/${bookingID}`,
            params: { timestamp, paymentStatus },
            headers: defaultHeaders,
        });

        yield put(
            actions.changePayStatusSucceeded(
                bookingID,
                timestamp * 1000,
                paymentStatus,
            ),
        );
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.changePayStatusFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        sentry.error(err);

        yield put(actions.changePayStatusFailed());
        yield call(rejectPromiseAction, action, {
            errors: null,
            message: null,
        });
    }
}

export const bookingsSaga = [
    takeEvery(actionTypes.GET_BOOKINGS, getBookings),
    takeEvery(actions.addBooking, addBooking),
    takeEvery(actions.getBookingPaymentOptions, getBookingPaymentOptions),
    takeEvery(actions.editBooking, editBooking),
    takeEvery(actions.deleteBooking, deleteBooking),
    takeEvery(actions.omitBooking, omitBooking),
    takeLatest(actions.changePayStatus, changePayStatus),
];
