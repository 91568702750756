import * as MULTISAFE_PAYOUTS_TYPES from '../../actions/financials/multisafePayouts/types';

const INITIAL_STATE = {
    page: 1,
    items: [],
    showAll: false,
    loading: false,
    itemsCount: 0,
    pagesCount: 0,
};

export const multisafePayoutsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (MULTISAFE_PAYOUTS_TYPES.GET_MULTISAFE_PAYMENTS_RESOLVED): {
        const { payments, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items: payments,
            page,
            itemsCount,
            pagesCount,
            loading: false,
        };
    }
    case (MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: page === 0,
        };
    }
    case (MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (MULTISAFE_PAYOUTS_TYPES.MULTISAFE_PAYMENTS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (MULTISAFE_PAYOUTS_TYPES.GET_MULTISAFE_PAYMENTS_TRIGGER): {
        return {
            ...state,
            loading: true,
        };
    }

    case (MULTISAFE_PAYOUTS_TYPES.GET_MULTISAFE_PAYMENTS_REJECTED): {
        return {
            ...INITIAL_STATE,
        };
    }
    default:
        return state;
    }
};
