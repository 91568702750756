/* istanbul ignore file */

import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import { app } from './app';
import { accessReducer } from './access';
import { auth } from './auth';
import { bookings } from './bookings';
import { categories } from './categories';
import { company } from './company';
import { employees } from './employees';
import { events } from './events';
import { groups } from './groups';
import { locales } from './locales';
import { objects } from './objects';
import { shop } from './shop';
import { subscriptionsReducer } from './subscriptions';
import { toast } from './toast';
import { user } from './user';
import { permissions } from './permissions';
import { bundlesReducer } from './bundles';
import { servicesReducer } from './services';
import { clientsReducer } from './clients';
import { financialsReducer } from './financials';
import { messagesReducer } from './messages';
import { productsReducer } from './products';
import { activityReducer } from './activity';

export const reducer = combineReducers({
    app: persistReducer({
        key: 'app',
        storage,
        whitelist: ['date'],
    }, app),
    auth: persistReducer({
        key: 'auth',
        storage,
        version: 1,
        migrate: createMigrate(
            { 1: () => ({}) },
            { debug: false },
        ),
    }, auth),
    bookings,
    categories,
    products: productsReducer,
    bundles: bundlesReducer,
    company: persistReducer({
        key: 'company',
        storage,
        blacklist: ['loaded'],
    }, company),
    employees: persistReducer({
        key: 'employees',
        storage,
        blacklist: ['loaded'],
    }, employees),
    events: persistReducer({
        key: 'events',
        storage,
        blacklist: ['loaded'],
    }, events),
    groups: persistReducer({
        key: 'groups',
        storage,
        blacklist: ['loaded', 'getGroupsLoading'],
    }, groups),
    locales: persistReducer({
        key: 'locales',
        storage,
        stateReconciler: (inboundState, state) => (
            {
                ...inboundState,
                locales: state.locales,
            }),
    }, locales),
    objects: persistReducer({
        key: 'objects',
        storage,
        blacklist: ['loaded'],
    }, objects),
    services: persistReducer({
        key: 'services',
        storage,
        blacklist: ['loaded'],
    }, servicesReducer),
    shop: persistReducer({
        key: 'shop',
        storage,
        blacklist: ['loaded'],
    }, shop),
    subscriptions: persistReducer({
        key: 'subscriptions',
        storage,
        whiteList: ['currentPage'],
        blacklist: ['statusFilter', 'idFilter'],
    }, subscriptionsReducer),
    toast,
    user: persistReducer({
        key: 'user',
        storage,
        blacklist: ['loaded'],
    }, user),
    permissions: persistReducer({
        key: 'permissions',
        storage,
    }, permissions),
    clients: clientsReducer,
    financials: financialsReducer,
    messages: messagesReducer,
    access: accessReducer,
    activity: activityReducer,
});
