import * as actionTypes from '../actions/actionTypes';
import { constPreset } from '../actions/permissions/consts';

const initialState = {
    type: null,
    preset: null,
};

export const permissions = (state = initialState, { type, payload }) => {
    const transformPreset = (role, type) => {
        const typedPreset = role === 'employee'
            ? constPreset[role][type]
            : constPreset[role];

        return {
            section: {
                object: typedPreset.objectSection,
                employee: typedPreset.employeeSection,
                group: typedPreset.groupSection,
                event: typedPreset.eventSection,
            },
            booking: {
                add: typedPreset.addBooking,
                edit: typedPreset.editBooking,
                deleteRegular: typedPreset.cancelRegular,
                deleteOneRecurring: typedPreset.cancelOccurrence,
                deleteWholeRecurring: typedPreset.cancelWholeRecurring,
                view: typedPreset.viewBooking,
                setPaid: typedPreset.setPaid,
                setPresent: typedPreset.setPresent,
                newClient: typedPreset.addClient,
            },
            groupSession: {
                addBooking: typedPreset.addBookingGroupSession,
                cancel: typedPreset.cancelGroupSession,
                override: typedPreset.overrideGroupSession,
                sendNotification: typedPreset.sendNotificationGroupSession,
            },
            event: {
                addBooking: typedPreset.addBookingEvent,
                cancel: typedPreset.cancelEvent,
                sendNotification: typedPreset.sendNotificationEvent,
            },
            resource: {
                add: typedPreset.addResource,
                edit: typedPreset.editResource,
                delete: typedPreset.deleteResource,
            },
            block: {
                edit: typedPreset.editDeleteBlock,
                add: typedPreset.addBlock,
                addToResource: typedPreset.addBlockChooseResource,
            },
            shop: {
                switch: typedPreset.switchShop,
            },
        };
    };

    switch (type) {
    case actionTypes.SET_PERMISSIONS:
        const { role, type: permissionType } = payload;

        return {
            type: permissionType,
            preset: transformPreset(role, permissionType),
        };
    case actionTypes.CLEAR_PERMISSIONS:
        return initialState;
    default:
        return state;
    }
};
