import React from 'react';

const Pin = ({ width = '25', height = '24', className }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3328 21.23C15.108 19.2341 19.6294 13.8011 19.6294 10.2381C19.6294 5.62381 16.0419 3 12.6294 3C9.21689 3 5.62939 5.62381 5.62939 10.2381C5.62939 13.8011 10.1507 19.2341 11.926 21.23C12.305 21.6561 12.9538 21.6561 13.3328 21.23ZM12.6294 12C14.0101 12 15.1294 10.8807 15.1294 9.5C15.1294 8.11929 14.0101 7 12.6294 7C11.2487 7 10.1294 8.11929 10.1294 9.5C10.1294 10.8807 11.2487 12 12.6294 12Z" fill="currentColor" />
    </svg>

);

export default Pin;
