import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { debounce } from 'lodash';
import { TailSpin } from 'react-loader-spinner';
import * as styles from './Button.module.scss';

const colorClasses = {
    yellow: styles.yellow,
    black: styles.black,
    gray: styles.gray,
    outline: styles.outline,
    red: styles.red,
    green: styles.green,
    white: styles.white,
    outlineRed: styles.outlineRed,
};

const colorLoaderNames = {
    yellow: 'white',
    black: 'white',
    gray: 'black',
    outline: 'black',
    red: 'white',
    green: 'white',
    white: 'black',
    outlineRed: 'red',
};

const colorBeforeNames = {
    yellow: styles.beforeWhite,
    black: styles.beforeWhite,
    gray: styles.beforeBlack,
    outline: styles.beforeBlack,
    red: styles.beforeWhite,
    green: styles.beforeWhite,
    white: styles.beforeBlack,
    outlineRed: styles.beforeRed,
};

const sizeClasses = {
    small: styles.small,
    medium: styles.medium,
    large: styles.large,
    'extra-large': styles.extraLarge,
};

const Button = (props) => {
    const isDisabledStyle = (props.disabled || props.loading);

    const Content = useMemo(() => (props.loading ? (
        <TailSpin
            color={isDisabledStyle ? 'black' : colorLoaderNames[props.color]}
            width={18}
            height={18}
        />
    )
        : (
            <React.Fragment>
                {props.before && (
                    <div className={classNames(styles.before, colorBeforeNames[props.color])}>
                        { props.before }
                    </div>
                )}
                {props.children}
                {props.after && (
                    <div className={classNames(styles.before, colorBeforeNames[props.color])}>
                        { props.after }
                    </div>
                )}
            </React.Fragment>
        )), [props.children, props.before, props.loading, props.color]);

    const className = useMemo(() => classNames(
        props.className,
        styles.button,
        colorClasses[props.color],
        isDisabledStyle ? styles.disabled : '',
        props.stretched ? styles.stretched : '',
        props.uppercase ? styles.uppercase : '',
        props.group ? styles.group : '',
        sizeClasses[props.size],
        props.href ? styles.link : '',
    ), [props.className, props.color, isDisabledStyle, props.stretched, props.uppercase, props.group, props.size, props.href]);

    const debouncedOnClick = useCallback(
        props.onClick ? debounce(props.onClick, 300, { leading: true, trailing: false }) : () => {},
        [props.onClick],
    );

    if ((props.download || props.external) && props.href) {
        return (
            <a
                className={className}
                style={props.style}
                href={props.href}
                name={props.name}
                download={props.download}
                data-testid={props['data-testid']}
                form={props.form}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                target={props.target}
                rel={props.rel}
            >
                { Content }
            </a>
        );
    }

    if (props.href) {
        return (
            <Link
                className={className}
                style={props.style}
                to={props.href}
                name={props.name}
                data-testid={props['data-testid']}
                form={props.form}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick?.();
                }}
                target={props.target}
                rel={props.rel}
            >
                { Content }
            </Link>
        );
    }

    return (
        <button
            onClick={debouncedOnClick}
            disabled={props.disabled}
            type={props.type}
            style={props.style}
            name={props.name}
            data-testid={props['data-testid']}
            form={props.form}
            className={className}
        >
            { Content }
        </button>
    );
};

Button.propTypes = {
    color: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    stretched: PropTypes.bool,
    group: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    size: PropTypes.string,
    download: PropTypes.string,
    external: PropTypes.bool,
    'data-testid': PropTypes.string,
    form: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    uppercase: PropTypes.bool,
    before: PropTypes.node,
    after: PropTypes.node,
};

Button.defaultProps = {
    color: 'yellow',
    type: 'button',
    size: 'medium',
    uppercase: true,
    'data-testid': 'data-test-button',
};

export default Button;
