import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LayoutContainer } from 'components';
import routes from './scenes';

function Settings() {
    return (
        <LayoutContainer>
            <Switch>
                {
                    routes.map(({ component, exact, path }) => (
                        <Route
                            key={path}
                            path={path}
                            exact={exact}
                            component={component}
                        />
                    ))
                }
                <Redirect to={routes[0].path} />
            </Switch>
        </LayoutContainer>
    );
}

export default Settings;
