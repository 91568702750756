import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ClockIcon } from 'components/Icon/Icon';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from 'components/Common/Button';
import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import * as styles from './CancelSoldSubscriptionDialog.module.scss';

const T_PREFIX = 'sold.dialogs.cancelBookings';

const CancelSoldSubscriptionDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { locale } = useSelector((state) => state.locales);

    const {
        visible,
        bookings,
        onClose,
        onConfirm,
    } = props;

    return (
        <Dialog
            visible={visible}
            onClose={onClose}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.header.title`)}
                </DialogTitle>
            </DialogHeader>
            <DialogBody className="pb-4">
                <p className="font-size-20 font-weight-600 mb-3">
                    {t(`${T_PREFIX}.body.title`)}
                </p>
                <ul className={styles.bookingsList}>
                    {bookings.map(({
                        id, solarium_name: bookingName, is_recurring: isRecurring, datetime,
                    }) => (
                        <li
                            key={id}
                            className="d-sm-flex justify-content-between mb-2"
                        >
                            <span className={styles.booking}>{bookingName}</span>
                            <div className="d-sm-flex align-items-center ml-sm-3 text-muted">
                                {isRecurring && (
                                    <ClockIcon
                                        className="mr-1"
                                        width={14}
                                        height={14}
                                    />
                                )}
                                <time dateTime={moment.unix(datetime).locale(locale).format()}>
                                    {moment.unix(datetime).locale(locale).format('DD MMM YYYY, hh:mm')}
                                </time>
                            </div>
                        </li>
                    ))}
                </ul>
            </DialogBody>
            <DialogFooter className="justify-content-between border-top border-light m-0 p-4">
                <Button
                    type="button"
                    color="outline"
                    onClick={onClose}
                >
                    {t(`${T_PREFIX}.footer.actions.back`)}
                </Button>
                <Button
                    type="submit"
                    color="red"
                    onClick={onConfirm}
                >
                    {t(`${T_PREFIX}.footer.actions.yes`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

CancelSoldSubscriptionDialog.propTypes = {
    visible: PropTypes.bool,
    bookings: PropTypes.array,
    soldSubId: PropTypes.number,
    newSubId: PropTypes.number,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};

CancelSoldSubscriptionDialog.defaultProps = {
    visible: false,
    bookings: [],
    onClose: emptyFunc,
    onConfirm: emptyFunc,
};

export default CancelSoldSubscriptionDialog;
