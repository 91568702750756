import React from 'react';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import moment from 'moment';
import { permissionsGroupSessionSelector, permissionsResourceSelector } from 'store/selectors/permissions';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Button } from 'components/Common/Button';
import { Row } from 'components/Common/Row';
import { Label } from 'components/Common/Typography/Label';
import { Header } from 'components/Common/Typography/Header';
import { Column } from 'components/Common/Column';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Panel } from 'components/Common/Panel';
import { useMobile } from 'hooks/useMobile';
import {
    ClockIcon, PinIcon, UserStar,
} from '../Icon/Icon';
import { GROUPS_EDIT_ID_PAGE } from '../../const/CLIENT_URL';

const GroupDetailsPanel = ({ session, onOverride, onCancel }) => {
    const { locale } = useSelector((state) => state.locales);
    const isMobile = useMobile();
    const {
        banCancel,
        banOverride,
    } = useSelector(permissionsGroupSessionSelector);
    const { banEdit } = useSelector(permissionsResourceSelector);

    const { t } = useTranslation();

    return (
        <Panel color="secondary" padding={24} stretched>
            <Row gap={24} stretched justify="between" wrap={isMobile}>
                <Column gap={10} stretched>
                    <Header>
                        {session?.time?.from && moment(session.time.from).locale(locale).format('D MMM YYYY')}
                    </Header>
                    <Column gap={0}>
                        {session?.group?.name && (
                            <SubHeader weight="light">{session.group.name}</SubHeader>
                        )}
                        {session?.service?.name && (
                            <Label weight="bold" color="gray">{session.service.name}</Label>
                        )}
                    </Column>
                    <Column gap={4}>
                        <Row gap={24}>
                            <Label weight="bold" color="gray">
                                {formatPrice.toEuroWithComma({ amount: session?.price })}
                            </Label>
                            <Label weight="bold" color="gray">
                                <Row gap={4}>
                                    <ClockIcon width={14} height={14} strokeWidth={2} />
                                    {session && `${moment(session.time.from).format('HH:mm')} - ${moment(session.time.to).format('HH:mm')}`}
                                </Row>
                            </Label>
                            <Label weight="bold" color="gray">
                                <Row gap={4}>
                                    <UserStar width={14} height={14} strokeWidth={2} />
                                    {session?.product?.name ?? '-'}
                                </Row>
                            </Label>
                        </Row>
                        <Row gap={24}>
                            <Label weight="bold" color="gray">
                                <Row gap={4}>
                                    <PinIcon width={16} height={16} />
                                    {`${session?.address.street}, ${session?.address.zip} ${session?.address.city}`}
                                </Row>
                            </Label>
                        </Row>
                    </Column>
                </Column>
                <Column gap={10} stretched={isMobile}>
                    <Button
                        stretched
                        size="small"
                        color="outline"
                        href={GROUPS_EDIT_ID_PAGE({ groupId: session?.group.id })}
                        disabled={banEdit}
                    >
                        {t('groupDetailsModal.edit')}
                    </Button>
                    <Button
                        stretched
                        color="outline"
                        size="small"
                        onClick={() => onOverride(true)}
                        disabled={banOverride || session?.isCancelled}
                    >

                        {t('groupDetailsModal.override')}
                    </Button>
                    <Button
                        stretched
                        color={session?.isCancelled ? 'green' : 'red'}
                        size="small"
                        onClick={() => onCancel(true)}
                        disabled={banCancel}
                    >
                        {t(`groupDetailsModal.${session?.isCancelled ? 'reactivate' : 'cancel'}`)}
                    </Button>
                </Column>
            </Row>
        </Panel>
    );
};

export default GroupDetailsPanel;
