import { createSelector } from 'reselect';

export const clientsSelector = (state) => state.clients;

export const clientsListSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clients,
);

export const clientItemSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientItem,
);

export const clientBookingsSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientBookings,
);

export const clientBundlesSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientBundles,
);

export const clientAccessLogsListSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientAccessLogs,
);

export const clientSubscriptionsSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientSubscriptions,
);

export const clientInvoicesSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientInvoices,
);

export const clientReviewsSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientReviews,
);

export const clientNotesSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientNotes,
);

export const clientNotesCategoriesSelector = createSelector(
    clientNotesSelector,
    (clientNotes) => clientNotes.categories,
);

export const clientActivityListSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientActivity,
);

export const clientInvoicesListSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientInvoices,
);

export const clientEmailsListSelector = createSelector(
    clientsSelector,
    (clientsState) => clientsState.clientEmails,
);
