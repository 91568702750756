export const EMAIL_STATUS = {
    SENT: 'SENT',
    DELIVERED: 'DELIVERED',
    OPENED: 'OPENED',
    CLICKED: 'CLICKED',
    BOUNCED: 'BOUNCED',
    COMPLAINED: 'COMPLAINED',
    REJECTED: 'REJECTED', // Optional: if your integration reports pre-send rejections
    DELIVERY_DELAYED: 'DELIVERY_DELAYED',
    RENDERING_FAILED: 'RENDERING_FAILED',
    UNSUBSCRIBED: 'UNSUBSCRIBED',
};
