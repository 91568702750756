import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/Common/Button';
import { emptyFunc } from 'components/../helpers/function/emptyFunc';

import { Dialog } from 'components/Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from 'components/Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from 'components/Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from 'components/Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from 'components/Common/Dialogs/Dialog/DialogFooter';
import { PeriodPickerPanel } from 'components/Common/PeriodPicker';
import * as ACTIVITY_ACTIONS from 'store/actions/activity';
import * as ACTIVITY_SELECTORS from 'store/selectors/activity';

import { LOCALE_NAMESPACE } from 'components/../const/translations/LOCALE_NAMESPACE';
import { Column } from 'components/Common/Column';
import { Header } from 'components/Common/Typography/Header';
import * as styles from './ActivityMobileFiltersDialog.module.scss';
import LocationsSelect from '../LocationsSelect/LocationsSelect';
import { TypesWindowedSelect } from '../TypesWindowedSelect';
import ActionsWindowedSelect from '../ActionsWindowedSelect/ActionsWindowedSelect';

const ActivityMobileFiltersDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const dispatch = useDispatch();

    const {
        isVisible,
        onClose,
        formik,
    } = props;

    const {
        location: externalLocation,
        date: externalDate,
    } = useSelector(ACTIVITY_SELECTORS.activityListSelector);

    const [location, setLocation] = useState(externalLocation);
    const [date, setDate] = useState(externalDate);

    const onApplyHandle = () => {
        dispatch(ACTIVITY_ACTIONS.setActivityListLocation({ location: location?.value || null }));
        dispatch(ACTIVITY_ACTIONS.setActivityListSelectedDate(date));
        onClose();
    };

    return (
        <Dialog
            visible={isVisible}
            onClose={onClose}
            size="md"
            className={styles.dialog}
            dialogClassName={styles.dialog}
        >
            <DialogHeader>
                <DialogTitle>
                    {tc('placeholders.filters')}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                <Column gap={16} stretched>
                    <LocationsSelect placeholder={t('activity.header.locationSelect.placeholder')} onSelect={setLocation} defaultSelected={location} stretched />
                    <PeriodPickerPanel
                        initDateFrom={date.dateFrom}
                        initDateTo={date.dateTo}
                        initPeriod={date.period}
                        onChange={setDate}
                        withoutConfirm
                    />

                    <Column gap={8} stretched>
                        <Header noWrap>
                            {t('activity.header.filters.title')}
                        </Header>
                        <TypesWindowedSelect
                            formik={formik}
                            stretched
                        />
                        <ActionsWindowedSelect formik={formik} stretched />
                    </Column>
                </Column>
            </DialogBody>

            <DialogFooter className={styles.dialogFooter}>
                <Button
                    onClick={onApplyHandle}
                    color="yellow"
                    data-testid="data-test-apply-button"
                >
                    {tc('placeholders.apply')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

ActivityMobileFiltersDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    formik: PropTypes.object,
};

ActivityMobileFiltersDialog.defaultProps = {
    isVisible: false,
    onClose: emptyFunc,
    formik: {},
};

export default ActivityMobileFiltersDialog;
