import React, { useCallback, useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { Content } from 'components/Common/Content';

import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { PayoutsTableHeader } from 'components/Financials/Payouts/PayoutsTableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { PayoutsTableBodyRow } from 'components/Financials/Payouts/PayoutsTableBodyRow';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as FINANCIALS_SELECTORS from 'store/selectors/financilas';
import * as PAYOUTS_ACTIONS from 'store/actions/financials/payouts';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 5;

function Payouts() {
    const { t } = useTranslation(LOCALE_NAMESPACE.PAYOUTS);
    const dispatch = useDispatch();

    const {
        page,
        items,
        showAll,
        loading,
        itemsCount,
        pagesCount,
    } = useSelector(FINANCIALS_SELECTORS.payoutsListSelector);

    const payoutsList = useMemo(() => (
        (!showAll && items.length > ITEMS_PER_PAGE)
            ? items.slice(0, ITEMS_PER_PAGE)
            : items
    ), [showAll, items]);

    const handlePrevPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(PAYOUTS_ACTIONS.getPayouts());
    }, [dispatch, page, showAll]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t('list.header.title')}
            />
            <Container
                fluid
                className="d-flex flex-column flex-grow-1 px-3 px-lg-5 "
            >
                <Content loading={loading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <PayoutsTableHeader />
                        </TableHeader>

                        <TableBody>
                            {payoutsList.map((payout) => (
                                <ListTableBodyRow key={payout.id}>
                                    <PayoutsTableBodyRow
                                        payout={payout}
                                    />
                                </ListTableBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                </Content>
            </Container>
        </React.Fragment>

    );
}

export default Payouts;
