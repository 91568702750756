import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { isAuthedSelector, roleSelector } from 'store/selectors/auth';
import { userAccessKeysSelector } from 'store/selectors/user';
import NavigationService from 'services/navigationService';
import { SIGN_IN } from 'const/CLIENT_URL';
import { useLocation } from 'react-router';

const EXCEPTIONS_PATH = ['/financials', '/marketing'];
const EXCEPTIONS_SUB_ROUTE = ['add', 'edit'];

function AuthRoute(props) {
    const {
        component: Component, path, exact = false, roles, accessKey,
    } = props;
    const { pathname } = useLocation();
    const isAuthed = useSelector(isAuthedSelector);
    const { role } = useSelector(roleSelector);
    const accessKeys = useSelector(userAccessKeysSelector);
    const pathParts = pathname.split('/');

    const isExeption = EXCEPTIONS_PATH.includes(path) && (pathParts.includes(EXCEPTIONS_SUB_ROUTE[0]) || pathParts.includes(EXCEPTIONS_SUB_ROUTE[1]));

    return (
        <Route
            exact={exact}
            path={path}
            render={(props) => {
                if (!isAuthed) {
                    return <Redirect to={SIGN_IN} />;
                }

                const canShowRoute = NavigationService.checkRolesAndAccessKeys({
                    role,
                    accessKeys,
                    roles,
                    accessKey,
                });
                if (canShowRoute || isExeption) {
                    return <Component {...props} roles={roles} accessKey={accessKey} />;
                }

                return <Redirect to="/agenda" />;
            }}
        />
    );
}

export default AuthRoute;
