import React from 'react';

const Attention = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_8445_35867)">
            <circle cx="10" cy="10" r="8.33333" transform="rotate(-180 10 10)" fill="#FF1100" fillOpacity="0.15" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10V6.66668C10.8333 6.20644 10.4602 5.83334 9.99996 5.83334C9.53972 5.83334 9.16663 6.20644 9.16663 6.66668V10C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333ZM9.99996 12.5C9.53972 12.5 9.16663 12.8731 9.16663 13.3333C9.16663 13.7936 9.53972 14.1667 9.99996 14.1667C10.4602 14.1667 10.8333 13.7936 10.8333 13.3333C10.8333 12.8731 10.4602 12.5 9.99996 12.5Z" fill="#A8130A" />
        <defs>
            <filter id="filter0_b_8445_35867" x="-16.2333" y="-16.2333" width="52.4666" height="52.4667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.95" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8445_35867" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8445_35867" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default Attention;
