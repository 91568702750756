import React from 'react';
import {
    CardIcon, ManualIcon, RefundIcon, SubscriptionIcon,
} from 'components/Icon/Icon';

export const PAYMENT_TYPE_ICONS = {
    refund: <RefundIcon />,
    manual: <ManualIcon />,
    ideal: <CardIcon />,
    sepa: <SubscriptionIcon />,
};
