// extracted by mini-css-extract-plugin
var _1 = "TextButton_before__KRV-D";
var _2 = "TextButton_black__z4lwn";
var _3 = "TextButton_button__Y3-N1";
var _4 = "TextButton_capitalize__2+iFg";
var _5 = "TextButton_disabled__sQfKn";
var _6 = "TextButton_gray__5uW8K";
var _7 = "TextButton_green__Lrdwm";
var _8 = "TextButton_link__mOZLK";
var _9 = "TextButton_noPadding__C+67k";
var _a = "TextButton_red__3CJxI";
var _b = "TextButton_strong__cqhav";
var _c = "TextButton_uppercase__I+96k";
var _d = "TextButton_yellow__ipMYu";
export { _1 as "before", _2 as "black", _3 as "button", _4 as "capitalize", _5 as "disabled", _6 as "gray", _7 as "green", _8 as "link", _9 as "noPadding", _a as "red", _b as "strong", _c as "uppercase", _d as "yellow" }
