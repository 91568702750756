export const LOCALE_NAMESPACE = {
    COMMON: 'common',
    USER: 'user',
    BUNDLES: 'bundles',
    SUBSCRIPTIONS: 'subscriptions',
    SOLD_SUBSCRIPTIONS: 'sold-subscriptions',
    TOASTS: 'toasts',
    TABLE: 'table',
    CLIENTS: 'clients',
    INVOICES: 'invoices',
    PAYOUTS: 'payouts',
    MESSAGES: 'messages',
    SETTINGS: 'settings',
    CLEARINGS: 'clearings',
    ACCESS: 'access',
    ACTIVITY: 'activity',
};
