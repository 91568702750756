import React from 'react';

import * as styles from './SubHeader.module.scss';
import Typography from '../Typography/Typography';

const SubHeader = (props) => (
    <Typography data-testid="subHeader" {...props} className={styles.subHeader} />
);

SubHeader.propTypes = Typography.propTypes;

SubHeader.defaultProps = Typography.defaultProps;

export default SubHeader;
