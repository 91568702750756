import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const InvoicesTableHeader = ({ hideClearingStatus }) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    return (
        <React.Fragment>
            <TableHeaderCell
                minWidth={200}
            >
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.number.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell
                minWidth={200}
            >
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.client.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.date.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.amount.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.vat.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.type.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.status.label`)}
                />
            </TableHeaderCell>
            {!hideClearingStatus && (
                <TableHeaderCell>
                    <ListTableHeaderCell
                        align="center"
                        name={t(`${T_FIELDS}.clearingStatus.label`)}
                    />
                </TableHeaderCell>
            )}
            <TableHeaderCell colSpan={2}>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.actions.label`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
};

InvoicesTableHeader.propTypes = {
    hideClearingStatus: PropTypes.bool,
};

InvoicesTableHeader.defaultProps = {
    hideClearingStatus: false,
};

export default InvoicesTableHeader;
