import React from 'react';

import * as styles from './Header.module.scss';
import Typography from '../Typography/Typography';

const Header = (props) => (
    <Typography data-testid="header" {...props} className={styles.header} />
);

Header.propTypes = Typography.propTypes;

Header.defaultProps = Typography.defaultProps;

export default Header;
