import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';

import { Tooltip } from 'components/Common/Tooltip';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Common/IconButton/IconButton';
import momentAmsterdamTime from 'helpers/time/momentAmsterdamTime';
import {
    ArrowSmallLeft, ArrowSmallLeftDoubleIcon, ArrowSmallRightDoubleIcon, ArrowSmallRight,
} from 'components/Icon/Icon';
import { Row } from 'components/Common/Row';
import { Label } from 'components/Common/Typography/Label';
import * as styles from './Calendar.module.scss';

const Calendar = (
    {
        loading,
        selectTimespan = 'day',
        month,
        year,
        selectedDate,
        onSelectDate,
        onChangeTimespan,
        bookings,
        products,
        workingDays,
        noDisplayYear,
        maxYear,
        minYear,
        minDate = null,
        maxDate = null,
    },
) => {
    const { locale } = useSelector((state) => state.locales);
    const { t } = useTranslation();
    const monthDate = useMemo(() => moment().locale(locale).month(month).year(year), [locale, month, year]);

    const dates = useMemo(() => {
        const date = monthDate.clone().startOf('month').clone().startOf('isoWeek');
        const end = monthDate.clone().endOf('month').clone().endOf('isoWeek');
        end.hour(0);
        end.minute(0);
        end.second(0);
        end.millisecond(0);
        // eslint-disable-next-line no-underscore-dangle
        const _dates = [];

        do {
            const day = date.clone();
            let isShopClosed = false;

            const dots = new Set();

            if (bookings) {
                bookings.forEach((booking) => {
                    if (day.isSame(moment(booking.time.from), 'day')) {
                        if (booking.notPresent) {
                            return dots.add('dark');
                        }

                        dots.add(booking.payment.paid
                            ? 'success'
                            : 'danger');
                    }
                });
            }

            if (products) {
                products.forEach((product) => {
                    if (day.isSame(moment(product.time.from), 'day')) {
                        if (product.isCancelled) {
                            return dots.add('danger');
                        }

                        if (product.isFull) {
                            return dots.add('success');
                        }

                        dots.add('secondary');
                    }
                });
            }

            if (workingDays) {
                Object.entries(workingDays).forEach(([key, openingHours]) => {
                    if (day.format('dddd').toLowerCase() === key) {
                        isShopClosed = Boolean(!openingHours.from && !openingHours.to);
                    }
                });
            }

            const isBeforeMinDate = minDate && day.isBefore(minDate, 'day');
            const isAfterMaxDate = maxDate && day.isAfter(maxDate, 'day');

            const isDateOutOfRange = isBeforeMinDate || isAfterMaxDate;

            if (date.weekday() === 0) {
                _dates.push(
                    <Tooltip key={`weekday-${day.week()}-${day.year()}`} placement="bottom" tooltip={t('calendar.week.tooltip', { week: day.week() })}>
                        <div
                            key={`weekday-${day.month()}-${day.date()}-${day.year()}`}
                            className={styles.weekday}
                            data-testid={`data-test-calendar-weekday-${day.week()}`}
                        >
                            {day.week()}
                        </div>
                    </Tooltip>,
                );
            }
            _dates.push(
                <div
                    key={`day-${day.month()}-${day.date()}`}
                    className={styles.dayContainer}
                >
                    <button
                        type="button"
                        className={classNames(
                            styles.day,
                            (isShopClosed || isDateOutOfRange) && styles.disabled,
                            day.month() !== month ? styles.disabled : styles.available,
                            day.isSame(selectedDate, 'day') && !workingDays ? styles.selected : undefined,
                            day.isSame(momentAmsterdamTime(), 'day') && styles.today,
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (day.month() !== month || !onSelectDate || isDateOutOfRange) {
                                return;
                            }

                            onSelectDate(day.locale('en'));
                        }}
                        data-testid={`data-test-calendar-day-${day.format('YYYY-MM-DD')}`}
                    >
                        <span>
                            {day.date()}
                        </span>
                        {(bookings || products) && (
                            <div className={styles.dots}>
                                {[...dots].map((dot) => <span key={dot} className={`text-${dot}`}>&bull;</span>)}
                            </div>
                        )}
                    </button>
                </div>,
            );
            date.add(1, 'day');
        } while (!date.isAfter(end));
        return _dates;
    }, [monthDate, products, bookings, selectedDate, t]);

    const isDecrementDisabled = Boolean(minYear && +minYear === year && month === 0);
    const isDecrementYearDisabled = Boolean(minYear && +minYear === year);

    const isIncrementDisabled = Boolean(maxYear && +maxYear === year && month === 11);
    const isIncrementYearDisabled = Boolean(maxYear && +maxYear === year);

    const decrementMonth = useCallback((e) => {
        e.stopPropagation();
        const newMonthValue = month - 1 < 0 ? 11 : month - 1;
        const newYearValue = year - (month - 1 < 0 ? 1 : 0);

        onChangeTimespan(newMonthValue, newYearValue);
    }, [month, year, onChangeTimespan]);

    const incrementMonth = useCallback((e) => {
        e.stopPropagation();
        const newMonthValue = month + 1 > 11 ? 0 : month + 1;
        const newYearValue = year + (month + 1 > 11 ? 1 : 0);

        onChangeTimespan(newMonthValue, newYearValue);
    }, [month, year, onChangeTimespan]);

    const incrementYear = useCallback((e) => {
        e.stopPropagation();
        onChangeTimespan(month, year + 1);
    }, [month, year, onChangeTimespan]);

    const decrementYear = useCallback((e) => {
        e.stopPropagation();
        onChangeTimespan(month, year - 1);
    }, [month, year, onChangeTimespan]);

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Row justify="between" spacing={8} padding={16} stretched>
                <Row gap={8}>
                    {!noDisplayYear
                && (
                    <IconButton
                        size={24}
                        data-testid="backYearArrow"
                        color="transparent"
                        onClick={decrementYear}
                        disabled={isDecrementYearDisabled}
                    >
                        <ArrowSmallLeftDoubleIcon />
                    </IconButton>
                )}
                    <IconButton
                        size={24}
                        data-testid="backArrow"
                        color="transparent"
                        onClick={decrementMonth}
                        disabled={isDecrementDisabled}
                    >
                        <ArrowSmallLeft />
                    </IconButton>
                </Row>

                <Row gap={8}>
                    <Label weight="bold" data-testid="data-test-calendar-month">
                        {monthDate.format('MMMM')}
                    </Label>
                    {
                        !noDisplayYear && (
                            <Label weight="bold" data-testid="data-test-calendar-year">
                                {monthDate.format('YYYY')}
                            </Label>
                        )
                    }
                </Row>

                <Row gap={8}>
                    <IconButton
                        size={24}
                        data-testid="nextArrow"
                        color="transparent"
                        onClick={incrementMonth}
                        disabled={isIncrementDisabled}
                    >
                        <ArrowSmallRight />
                    </IconButton>
                    {!noDisplayYear && (
                        <IconButton
                            size={24}
                            data-testid="nextYearArrow"
                            color="transparent"
                            onClick={incrementYear}
                            disabled={isIncrementYearDisabled}
                        >
                            <ArrowSmallRightDoubleIcon />
                        </IconButton>
                    )}
                </Row>
            </Row>

            {loading === true && (
                <div className="d-flex justify-content-center align-items-center w-100 mt-5">
                    <TailSpin
                        color="black"
                        width={50}
                        height={50}
                    />
                </div>

            )}

            {!loading && (
                <div className={styles.content}>
                    <span />
                    {
                        _.times(7, (i) => (
                            <span key={`weekday-${i}`} className={styles.weekdayHeader} data-testid={`data-test-calendar-weekday-${i}`}>
                                {moment().locale(locale).isoWeekday(i + 1).format('dddd')[0]}
                            </span>
                        ))
                    }

                    <div className={styles.line} />

                    {dates}

                    {(selectTimespan === 'week' || selectTimespan === 'planning') && (
                        <div className={styles.weeks}>
                            {_.times(Math.ceil(dates.length / 7), (i) => (
                                <div
                                    key={`week-${i}`}
                                    className={classNames(styles.week, {
                                        [styles.highlighted]: selectedDate.isSame(moment().month(month).year(year).startOf('month')
                                            .add(i, 'isoWeek'), 'isoWeek'),
                                    })}
                                    data-testid={`data-test-calendar-week-${i}`}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Calendar;
