import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
    CheckIcon,
    Close, EditIcon, LockIcon, MailIcon, PhoneIcon, PinIcon, Trash3Icon, UnlockIcon,
} from 'components/Icon/Icon';

import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { IconButton } from 'components/Common/IconButton';
import { Row } from 'components/Common/Row';
import { Avatar } from '../../Common/Avatar';
import { Tooltip } from '../../Common/Tooltip';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ClientsListTableRow.module.scss';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;

const ClientsListTableRow = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const {
        client,
        onInfo,
        onEdit,
        onBlockToggle,
        isSelected,
        onSelect,
        onDeselect,
        onDelete,
    } = props;

    const {
        contactInfo,
        isRegistered,
        isBlocked,
    } = client;
    const { phone, email, address } = contactInfo;

    const nameLabel = t(`${T_FIELDS}.name.label`, {
        firstName: client.fullName.firstName,
        lastName: client.fullName.lastName,
        interpolation: { escapeValue: false },
    });

    const phoneLabel = phone || t(`${T_FIELDS}.phone.notAvailable`);

    const emailLabel = email || t(`${T_FIELDS}.email.notAvailable`);

    const addressLabel = (address.street && address.zip && address.city)
        ? t(`${T_FIELDS}.address.label`, {
            street: address.street,
            zip: address.zip,
            city: address.city,
        })
        : t(`${T_FIELDS}.address.notAvailable`);

    const blockageTooltip = t(`${T_FIELDS}.actions.${isBlocked ? 'unblock' : 'block'}.tooltip`);

    const handleInfo = useCallback(() => {
        if (!client.id) {
            return;
        }
        onInfo({ clientId: client.id });
    }, [client.id, onInfo]);

    const handleSelect = useCallback(() => {
        if (!client.id) {
            return;
        }
        if (isSelected) {
            onDeselect({ clientId: client.id });
        } else {
            onSelect({
                clientId: client.id,
                isRegistered: client.isRegistered,
                email: client.contactInfo.email,
            });
        }
    }, [
        client?.id,
        isSelected,
        onSelect,
        onDeselect]);

    const handleToggleLock = useCallback((e) => {
        e.stopPropagation();
        if (!client.id) {
            return null;
        }
        return onBlockToggle({ clientId: client.id });
    }, [client.id, onBlockToggle]);

    const handleEdit = useCallback((e) => {
        e.stopPropagation();
        if (!client.id) {
            return;
        }
        onEdit({ clientId: client.id });
    }, [client.id, onEdit]);

    const handleDelete = useCallback((e) => {
        e.stopPropagation();
        if (!client.id) {
            return;
        }
        onDelete({ clientId: client.id });
    }, [client.id, onDelete]);

    return (
        <ListTableBodyRow
            onClick={handleInfo}
            data-testid={`data-test-client-list-row-${emailLabel}`}
            select-data-testid="data-test-client-list-row-checkbox"
            selected={isSelected}
            onSelect={handleSelect}
        >
            <ListTableBodyCell
                className={classNames(
                    styles.cell,
                    styles.avatarCell,
                )}
            >
                <Avatar
                    src={client.avatar}
                    data-testid="data-test-client-list-row-avatar"
                    name={nameLabel}
                />
            </ListTableBodyCell>
            <ListTableBodyCell
                className={classNames(
                    styles.cell,
                    styles.nameCell,
                    styles.shrinkable,
                )}
            >
                <Tooltip
                    tooltip={nameLabel}
                    placement="top-start"
                >
                    <span data-testid="data-test-client-list-row-name">
                        {nameLabel}
                    </span>
                </Tooltip>
            </ListTableBodyCell>
            <ListTableBodyCell className={styles.cell}>
                <div className={styles.infoWithIcon}>
                    <PhoneIcon className={styles.icon} />
                    <span data-testid="data-test-client-list-row-phone">
                        {phoneLabel}
                    </span>
                </div>
            </ListTableBodyCell>
            <ListTableBodyCell
                className={classNames(
                    styles.cell,
                    styles.emailCell,
                )}
            >
                <div className={styles.infoWithIcon}>
                    <MailIcon className={styles.icon} />
                    <Tooltip
                        tooltip={emailLabel}
                        placement="top-start"
                    >
                        <span className={styles.shrinkable} data-testid="data-test-client-list-row-email">
                            {emailLabel}
                        </span>
                    </Tooltip>
                </div>
            </ListTableBodyCell>
            <ListTableBodyCell
                className={classNames(
                    styles.cell,
                    'pr-3',
                )}
            >
                <div
                    className={classNames(
                        styles.infoWithIcon,
                        styles.centeredContent,
                    )}
                    data-testid={`data-test-client-list-row-is-registered-${isRegistered}`}
                >
                    {isRegistered
                        ? (
                            <CheckIcon className={styles.greenField} />
                        ) : (
                            <Close className={styles.redField} />
                        )}
                </div>
            </ListTableBodyCell>
            <ListTableBodyCell
                className={classNames(
                    styles.cell,
                    styles.addressCell,
                )}
            >
                <div className={styles.infoWithIcon}>
                    <PinIcon className={styles.icon} />
                    <Tooltip
                        tooltip={addressLabel}
                        placement="top-start"
                    >
                        <span className={styles.shrinkable} data-testid="data-test-client-list-row-address">
                            {addressLabel}
                        </span>
                    </Tooltip>
                </div>
            </ListTableBodyCell>
            <ListTableBodyCell
                isLast
                className={styles.cell}
            >
                <Row gap={8}>
                    <Tooltip
                        placement="top"
                        tooltip={blockageTooltip}
                        forButton
                    >
                        <IconButton
                            type="button"
                            color={isBlocked ? 'outlineRed' : 'gray'}
                            onClick={handleToggleLock}
                            data-testid={`data-test-client-list-row-${isBlocked ? 'unblocked' : 'blocked'}`}
                        >
                            {isBlocked ? (
                                <LockIcon />
                            ) : (
                                <UnlockIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        tooltip={t(`${T_FIELDS}.actions.edit.tooltip`)}
                        forButton
                    >
                        <IconButton
                            type="button"
                            color="gray"
                            onClick={handleEdit}
                            data-testid="data-test-client-list-row-edit"
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        tooltip={t(`${T_FIELDS}.actions.delete.tooltip`)}
                        forButton
                    >
                        <IconButton
                            type="button"
                            color="gray"
                            onClick={handleDelete}
                            data-testid="data-test-client-list-row-delete"
                        >
                            <Trash3Icon />
                        </IconButton>
                    </Tooltip>
                </Row>
            </ListTableBodyCell>
        </ListTableBodyRow>
    );
};

ClientsListTableRow.propTypes = {
    client: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onInfo: PropTypes.func,
    onEdit: PropTypes.func,
    onBlock: PropTypes.func,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    onDelete: PropTypes.func,
    onUnblock: PropTypes.func,
};

ClientsListTableRow.defaultProps = {
    isSelected: false,
    onInfo: emptyFunc,
    onEdit: emptyFunc,
    onBlock: emptyFunc,
    onSelect: emptyFunc,
    onDeselect: emptyFunc,
    onDelete: emptyFunc,
    onUnblock: emptyFunc,
};

export default ClientsListTableRow;
