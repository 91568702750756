import React from 'react';

const UnarchiveIcon = ({ height = 24, width = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.5681 5.61233C20.8478 5.96565 21 6.40309 21 6.85373V18.1579C21 19.7275 19.6569 21 18 21H6C4.34315 21 3 19.7275 3 18.1579V6.85373C3 6.40309 3.15219 5.96565 3.43191 5.61233L4.89945 3.75859C5.27879 3.27943 5.85639 3 6.46754 3H17.5325C18.1436 3 18.7212 3.27943 19.1006 3.75859L20.5681 5.61233ZM18.7434 6.46533C18.8472 6.59645 18.7538 6.78947 18.5866 6.78947H5.41342C5.24619 6.78947 5.15281 6.59645 5.25661 6.46533L6.34986 5.08439C6.4447 4.96459 6.5891 4.89474 6.74188 4.89474H17.2581C17.4109 4.89474 17.5553 4.96459 17.6501 5.08439L18.7434 6.46533ZM12.3625 10.1204L15.6978 13.6312C16.0004 13.9497 15.7746 14.4756 15.3353 14.4756H14C13.8895 14.4756 13.8 14.5652 13.8 14.6756V16.7177C13.8 17.0491 13.5314 17.3177 13.2 17.3177L10.8 17.3177C10.4686 17.3177 10.2 17.0491 10.2 16.7177V14.6756C10.2 14.5652 10.1105 14.4756 10 14.4756H8.66466C8.22536 14.4756 7.99959 13.9497 8.30216 13.6312L11.6375 10.1204C11.8346 9.91285 12.1654 9.91285 12.3625 10.1204Z" fill="currentColor" />
    </svg>

);

export default UnarchiveIcon;
