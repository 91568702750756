import React, { cloneElement, useMemo } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { useMobile } from 'hooks/useMobile';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { Header } from 'components/Common/Typography/Header';
import { Row } from 'components/Common/Row';
import * as styles from './TextSubHeader.module.scss';

function TextSubHeader(props) {
    const {
        text,
        description,
        before,
        after,
        rightActions,
    } = props;

    const isMobile = useMobile();

    const textComponent = useMemo(() => (
        <Header
            data-testid="data-test-text-sub-header-text"
            after={
                isMobile && !!description && <InfoTooltip text={description} placement="bottom" />
            }
        >
            {text}
        </Header>
    ), [text, isMobile, description]);

    //
    return (
        <div className={styles.column} data-testid="data-test-text-sub-header">
            <Row gap={32} justify="between" wrap stretched>
                <div
                    className={styles.actionsContainer}
                    data-testid="data-test-text-sub-header-left-actions"
                >
                    {before}
                    {!isMobile && textComponent}
                    {Array.isArray(after) ? after.map((action, index) => (
                        action && cloneElement(action, { key: index })
                    )) : after}
                </div>
                <Row gap={8} data-testid="data-test-text-sub-header-right-actions" wrap>
                    {/* if rightActions is an array, render each element separately */}
                    {Array.isArray(rightActions) ? rightActions.map((action, index) => (
                        action && cloneElement(action, { key: index })
                    )) : rightActions}
                </Row>
            </Row>
            {!isMobile && !!description && (
                <div className={styles.description} data-testid="data-test-text-sub-header-description">
                    {description}
                </div>
            )}
            {!!isMobile && textComponent}
        </div>
    );
}

TextSubHeader.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.string,
    before: PropTypes.node,
    after: PropTypes.node,
    rightActions: PropTypes.oneOfType([arrayOf(PropTypes.node), PropTypes.node]),
};

export default TextSubHeader;
