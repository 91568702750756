import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Row from 'react-bootstrap/Row';
import { newSelectStyles, theme } from 'styles/select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    EMAIL_STATUS,
} from 'const/email/EMAIL_STATUS';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as styles from './ClientEmailsStatusFilter.module.scss';

const animatedComponents = makeAnimated();

const T_PREFIX = 'clientEmailsTable.statuses';

const ClientEmailsStatusFilter = ({ onChange }) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.USER);

    const options = useMemo(() => Object.values(EMAIL_STATUS).map((v) => ({
        value: v,
        label: t(`${T_PREFIX}.values.${v.toLowerCase()}`),
    })), [t]);
    const handleChange = useCallback((selected) => {
        onChange(selected?.value);
    }, [onChange]);

    return (
        <Row className={styles.filtersContainer}>
            <Select
                name="soldSubscriptionStatuses"
                isSearchable={false}
                theme={theme}
                styles={newSelectStyles}
                isClearable
                className={styles.filter}
                components={animatedComponents}
                onChange={handleChange}
                placeholder={t(`${T_PREFIX}.placeholder`)}
                options={options}
            />
        </Row>
    );
};

ClientEmailsStatusFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
};

ClientEmailsStatusFilter.defaultProps = {
};

export default ClientEmailsStatusFilter;
