import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { companyAppColorSelector } from 'store/selectors/company';
import { getContrastColor } from 'helpers/color/getContrastColor';
import Skeleton from 'react-loading-skeleton';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './Avatar.module.scss';
import { Image } from '../Image';

const T_PREFIX = 'avatar';

const sizeStyleModifier = {
    small: styles.small,
    medium: styles.medium,
    large: styles.large,
};

const EmptyComponent = ({ name }) => {
    if (name) {
        return <div className={styles.emptyWithText}>{name[0]}</div>;
    }

    return <div className={styles.empty} />;
};

const AvatarComponent = ({
    src, size, name, color, ...restProps
}) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.USER);
    const appColor = useSelector(companyAppColorSelector);

    const isDefaultPng = useMemo(() => src && src.includes('default.png'), [src]);
    const isPrimaryColor = color === 'primary';
    const style = useMemo(() => ({
        color: isPrimaryColor ? getContrastColor(appColor) : '#A4AAB7',
        backgroundColor: isPrimaryColor ? appColor : '#E5EBF0',
    }), [appColor, isPrimaryColor]);

    return (
        <div
            className={classNames(styles.avatarContainer, sizeStyleModifier[size])}
            style={style}
            data-testid={restProps['data-testid']}
        >
            {(src && !isDefaultPng) ? (
                <Image
                    src={src}
                    alt={t(`${T_PREFIX}.alt.default`)}
                    className={styles.avatar}
                />
            ) : <EmptyComponent name={name} />}
        </div>
    );
};

const Avatar = (props) => {
    const {
        src,
        size,
        name,
        href,
        color,
        loading,
        ...restProps
    } = props;

    if (loading) {
        <div
            className={classNames(styles.avatarContainer, sizeStyleModifier[size])}
            data-testid={restProps['data-testid']}
        >
            <Skeleton
                width="100%"
                height="100%"
                circle
            />
        </div>;
    }

    if (href) {
        return (
            <Link to={href} className={styles.link}>
                <AvatarComponent src={src} size={size} name={name} color={color} {...restProps} />
            </Link>
        );
    }

    return <AvatarComponent src={src} size={size} name={name} color={color} {...restProps} />;
};

Avatar.propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    'data-testid': PropTypes.string,
    loading: PropTypes.bool,
    name: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'gray']),
};

Avatar.defaultProps = {
    'data-testid': 'data-test-avatar',
    size: 'medium',
    color: 'gray',
    loading: false,
    name: '',
    href: '',
};

export default Avatar;
