import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { CheckBox } from 'components/Common/CheckBox';
import * as styles from './TableHeader.module.scss';
import { TableHeaderCell } from '../TableHeaderCell';
import { ListTableHeaderCell } from '../ListTableHeaderCell';

const TableHeader = (props) => {
    const {
        children,
        onSelect,
        selected,
        'data-testid': dataTestId,
        'select-data-testid': selectDataTestId,
    } = props;

    return (
        <thead className={classNames(styles.headerRow, props.align && styles[props.align])}>
            <tr data-testid={dataTestId}>
                {onSelect && (
                    <TableHeaderCell>
                        <ListTableHeaderCell name={(
                            <CheckBox
                                checked={selected}
                                onChange={onSelect}
                                data-testid={selectDataTestId}
                            />
                        )}
                        />
                    </TableHeaderCell>
                )}
                {children}
            </tr>
        </thead>
    );
};

TableHeader.propTypes = {
    children: PropTypes.any,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    'data-testid': PropTypes.string,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    'select-data-testid': PropTypes.string,
};

TableHeader.defaultProps = {
    children: null,
    align: 'left',
    'data-testid': 'data-test-table-header',
    onSelect: null,
    selected: false,
    'select-data-testid': 'data-test-table-header-select',
};

export default TableHeader;
