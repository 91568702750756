import {
    put, take, select, call, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

// Actions
import camelize from 'camelize';
import moment from 'moment';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';
import * as EVENTS_TYPES from '../actions/events/actionTypes';

// Utils
import axios from '../../services/axios';

const mapEventResponseToEvent = ({
    id,
    resourceId,
    groupName: name,
    description,
    photoUrl: image,
    cost,
    serviceId: serviceID,
    serviceName,
    time,
    lengthInMinutes,
    total: totalPlaces,
    street,
    zip,
    city,
    country,
    latitude,
    longitude,
    bookings: participants,
    isCancelled,
    widgetUrl,
}) => ({
    id,
    name,
    widgetUrl,
    description,
    image,
    resourceId,
    price: parseFloat(cost),
    service: {
        id: serviceID,
        name: serviceName,
    },
    time: {
        from: time * 1000,
        to: moment(time * 1000).add(lengthInMinutes, 'minutes').valueOf(),
    },
    totalPlaces,
    address: {
        street,
        zip,
        city,
        country,
        latitude,
        longitude,
    },
    participants: participants?.map(({
        id,
        client: {
            id: clientID,
            name,
            lastName,
            phone: contactNumber,
            email,
        },
        isBoughtWithBundle,
        paid,
        inPaymentProcess,
        feedback,
        service,
        timestamp,
        minutes,
        finalCost,
        isRecurring,
        numberOfWeeks,
        isInfiniteRecurring,
        recurrenceEnd,
        recurrenceDays,
        seatsAmount,
        hasAlreadyStarted,
        notPresent,
        isEditable,
        paymentType,
        paymentSystem,
        paymentInfo,
    }) => ({
        id,
        service: {
            name: service,
        },
        groupSession: {
            id: null,
        },
        time: {
            from: moment(timestamp * 1000).valueOf(),
            to: moment(timestamp * 1000).add(minutes, 'minutes').valueOf(),
        },
        client: {
            id: clientID,
            name,
            lastName,
            contactNumber,
            email,
        },
        payment: {
            paidUpfront: paid ? finalCost : 0,
            finalCost,
            remainingCost: paid ? 0 : finalCost,
            paid,
            isInProgress: inPaymentProcess,
            usedBundle: isBoughtWithBundle,
            type: paymentType,
            paymentSystem,
            paymentInfo,
        },
        boughtWithBundle: isBoughtWithBundle,
        recurrence: {
            enabled: isRecurring,
            period: numberOfWeeks,
            infinite: isInfiniteRecurring,
            end: recurrenceEnd ? recurrenceEnd * 1000 : null,
            days: recurrenceDays?.map((day) => day.replace(/^\w/, (c) => c.toUpperCase())) ?? [],
        },
        seats: seatsAmount,
        feedback: feedback ? {
            rating: feedback.rating,
            message: feedback.message,
        } : null,
        alreadyStarted: hasAlreadyStarted,
        notPresent,
        isEditable,
    })) ?? [],
    isCancelled,
});

const mapBookingsResponseToEvent = (booking) => ({
    id: booking.id,
    service: {
        name: booking.service,
    },
    groupSession: {
        id: null,
    },
    time: {
        from: moment(booking.timestamp * 1000).valueOf(),
        to: moment(booking.timestamp * 1000).add(booking.minutes, 'minutes').valueOf(),
        extraMinutes: booking.add_time ?? 0,
        length: booking.length,
    },
    client: {
        id: booking.client.id,
        name: booking.client.name,
        firstName: booking.client.firstName,
        lastName: booking.client.lastName,
        contactNumber: booking.client.phone,
        email: booking.client.email,
        note: booking.client.note,
    },
    boughtWithBundle: booking.is_bought_with_bundle,
    payment: {
        paidUpfront: booking.paid ? booking.final_cost : 0,
        finalCost: booking.final_cost,
        remainingCost: booking.paid ? 0 : booking.final_cost,
        paid: booking.paid,
        isInProgress: booking.in_payment_process,
        usedBundle: booking.is_bought_with_bundle,
        type: booking.payment_type,
        paymentSystem: booking.payment_system,
        paymentInfo: booking.payment_info,
    },
    cancellationPolicy: booking.cancellation_policy,
    recurrence: {
        enabled: booking.is_recurring,
        period: booking.number_of_weeks,
        infinite: booking.is_infinite_recurring,
        end: booking.recurrence_end ? booking.recurrence_end * 1000 : null,
        days: booking.recurrence_days.map((day) => day.replace(/^\w/, (c) => c.toUpperCase())),
    },
    seats: booking.seats_amount,
    feedback: booking.feedback && {
        rating: booking.feedback.rated,
        comment: booking.feedback.comment,
    },
    alreadyStarted: booking.has_already_started,
    notPresent: booking.not_present,
    isEditable: booking.is_editable,
});

export function* getEvents(action) {
    try {
        const defaultHeaders = yield getDefaultHeaders();
        const { page, showAll } = yield select((state) => ({ page: state.events.page, showAll: state.events.showAll }));
        const shopID = yield select((state) => state.shop.id);

        yield put(actions.setEventsLoading({ loadingEvents: true }));

        const resp = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/product/event`,
            headers: defaultHeaders,
            params: {
                page: showAll ? 0 : page,
            },
        });

        const events = Object.values(camelize(resp.data.result.events)).map(mapEventResponseToEvent);
        const { pagesCount, itemsCount } = resp.data.result;

        yield put(actions.getEventsSucceeded({ events, pagesCount, itemsCount }));
    } catch (err) {
        yield put(actions.getEventsFailed());
    } finally {
        yield put(actions.setEventsLoading({ loadingEvents: false }));
    }
}

export function* addEvent(action) {
    const { event } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();
        data.append('name', event.name);
        data.append('description', event.description);
        data.append('image', event.image);
        data.append('videoUrl', event.videoURL);
        data.append('maintenanceMode', Number(event.isMaintenanceMode));
        data.append('canSendEmail', Number(event.canSendEmail));
        data.append('timestamp', event.time.from.unix());
        data.append('service', event.service.id);
        data.append('maxRegistrations', event.totalPlaces);
        data.append('showSessionsLeft', (event.showNumberOfRegistrations ? 1 : 0).toString());
        data.append('contactNumber', event.contactNumber);
        data.append('street', event.address.street);
        data.append('zip', event.address.zip);
        data.append('city', event.address.city);
        data.append('country', event.address.country);
        data.append('latitude', event.address.latitude);
        data.append('longitude', event.address.longitude);

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/add/event`,
            data,
            headers: defaultHeaders,
        });

        const result = camelize(resp.data.result);

        const resultEvent = {
            id: result.id,
            name: result.groupName,
            resourceId: result.resourceId,
            description: result.description,
            maintenanceMode: event.isMaintenanceMode,
            canSendEmail: event.canSendEmail,
            image: result.photoUrl,
            price: parseFloat(result.cost),
            service: {
                id: result.serviceId,
                name: result.serviceName,
            },
            time: {
                from: result.time * 1000,
                to: moment(result.time * 1000).add(result.lengthInMinute, 'minutes').valueOf(),
            },
            totalPlaces: result.total,
            address: {
                street: result.street,
                zip: result.zip,
                city: result.city,
                country: result.country,
                latitude: result.latitude,
                longitude: result.longitude,
            },
            participants: [],
            isCancelled: result.isCancelled,
        };

        yield put(actions.addEventSucceeded(resultEvent));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response && err.response.data && err.response.data.result && err.response.data.result.errors) {
            const { errors } = err.response.data.result;

            yield put(actions.addEventFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    name: errors.name,
                    description: errors.description,
                    isMaintenanceMode: errors.maintenanceMode,
                    canSendEmail: errors.canSendEmail,
                    image: errors.image,
                    videoURL: errors.videoUrl,
                    time: {
                        from: errors.timestamp,
                    },
                    service: errors.service,
                    maxRegistrations: errors.maxRegistrations,
                    showNumberOfRegistrations: errors.showSessionsLeft,
                    contactNumber: errors.contactNumber,
                    address: {
                        street: errors.street,
                        zip: errors.zip,
                        city: errors.city,
                        country: errors.country,
                        latitude: errors.latitude,
                        longitude: errors.longitude,
                    },
                },
            });
            return;
        }
        if (err.response && err.response.data) {
            const { message } = err.response.data.result;

            yield put(actions.addEventFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.addEventFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* editEvent(action) {
    const { event } = action.payload;

    try {
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const data = new FormData();

        data.append('productId', event.id);
        if (event.image) {
            data.append('image', event.image);
        }
        data.append('name', event.name);
        if (event.videoURL) {
            data.append('videoUrl', event.videoURL);
        }
        data.append('description', event.description);
        data.append('maintenanceMode', Number(event.isMaintenanceMode));
        data.append('canSendEmail', Number(event.canSendEmail));
        data.append('timestamp', event.time.from.unix());
        data.append('service', event.service.id);
        data.append('maxRegistrations', event.totalPlaces);
        data.append('showSessionsLeft', Number(event.showRegistrationsNumber));
        data.append('contactNumber', event.contactNumber);
        data.append('street', event.address.street);
        data.append('zip', event.address.zip);
        data.append('city', event.address.city);
        data.append('country', event.address.country);
        data.append('latitude', event.address.latitude);
        data.append('longitude', event.address.longitude);

        const resp = yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/edit/event`,
            data,
            headers: defaultHeaders,
        });

        const result = camelize(resp.data.result);

        yield put(actions.editEventSucceeded(mapEventResponseToEvent(result)));
        yield call(resolvePromiseAction, action);
        yield put(actions.getEvents());
    } catch (err) {
        if (err.response?.data?.result?.errors) {
            const { errors } = err.response.data.result;

            yield put(actions.editEventFailed());
            yield call(rejectPromiseAction, action, {
                errors: {
                    name: errors.name,
                    description: errors.description,
                    isMaintenanceMode: errors.maintenanceMode,
                    canSendEmail: errors.canSendEmail,
                    image: errors.image,
                    videoURL: errors.videoUrl,
                    time: {
                        from: errors.timestamp,
                    },
                    service: errors.service,
                    maxRegistrations: errors.maxRegistrations,
                    showNumberOfRegistrations: errors.showSessionsLeft,
                    contactNumber: errors.contactNumber,
                    address: {
                        street: errors.street,
                        zip: errors.zip,
                        city: errors.city,
                        country: errors.country,
                        latitude: errors.latitude,
                        longitude: errors.longitude,
                    },
                },
            });
            return;
        }

        if (err.response?.data?.result) {
            const { message } = err.response.data.result;

            yield put(actions.editEventFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.editEventFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* cancelEvent(action) {
    try {
        const { id } = action.payload;

        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/set-status/event/${id}`,
            headers: defaultHeaders,
        });

        yield put(actions.cancelEventSucceeded(id));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.status === 401) {
            yield put(actions.refreshToken());
            yield take(actionTypes.REFRESH_TOKEN_RESOLVED);
            yield put(action);
            return;
        }

        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.cancelEventFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.cancelEventFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* deleteEvent(action) {
    try {
        const { payload } = action;
        const { id, history } = payload;

        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        yield axios.request({
            _action: action,
            method: 'POST',
            url: `/api/admin/shop/${shopID}/product/delete/${id}`,
            headers: defaultHeaders,
        });

        history.push('/agenda/events');
        yield put(actions.deleteEventSucceeded(id));
        yield call(resolvePromiseAction, action);
    } catch (err) {
        if (err.response?.data?.result?.message) {
            const { message } = err.response.data.result;

            yield put(actions.deleteEventFailed());
            yield call(rejectPromiseAction, action, { message });
            return;
        }

        yield put(actions.deleteEventFailed());
        yield call(rejectPromiseAction, action);
    }
}

export function* getEventBookings(action) {
    try {
        const { id } = action.payload;
        const defaultHeaders = yield getDefaultHeaders();
        const shopID = yield select((state) => state.shop.id);

        const response = yield axios.request({
            _action: action,
            method: 'GET',
            url: `/api/admin/shop/${shopID}/event-bookings/${id}`,
            headers: defaultHeaders,
        });
        const bookings = response.data.result.map(mapBookingsResponseToEvent);

        yield call(resolvePromiseAction, action, bookings);
    } catch (err) {
        yield call(rejectPromiseAction, action);
    }
}

export const eventsSaga = [
    takeLatest(actionTypes.GET_EVENTS, getEvents),
    takeEvery(EVENTS_TYPES.SET_EVENTS_PAGE, getEvents),
    takeEvery(EVENTS_TYPES.SET_EVENTS_SHOW_ALL, getEvents),
    takeEvery(actions.addEvent, addEvent),
    takeEvery(actions.editEvent, editEvent),
    takeEvery(actions.cancelEvent, cancelEvent),
    takeEvery(actions.deleteEvent, deleteEvent),
    takeEvery(actions.getEventBookings, getEventBookings),
];
