import { createSelector } from 'reselect';

export const companySelector = (state) => state.company;

export const companyIsRequiredClientAddressSelector = createSelector(
    companySelector,
    (companyState) => companyState.isRequiredClientAddress,
);

export const companySettingsGeneralSelector = createSelector(
    companySelector,
    (companyState) => ({
        isSavingProcess: companyState.isSavingProcess,
        loaded: companyState.loaded,
        logo: companyState.logo,
        name: companyState.name,
        description: companyState.description,
        appColor: companyState.appColor,
        licenseLink: companyState.licenseLink,
        instagramLink: companyState.instagramLink,
        facebookLink: companyState.facebookLink,
        website: companyState.website,
        homeGallery: companyState.homeGallery,
    }),
);

export const posterSelector = createSelector(
    companySelector,
    (companyState) => ({
        loaded: companyState.loaded,
        logo: companyState.logo,
        name: companyState.name,
        appColor: companyState.appColor,
    }),
);

export const companyAppColorSelector = createSelector(
    companySelector,
    (companyState) => companyState.appColor,
);

export const companySettingsLoadedSelector = createSelector(
    companySelector,
    (companyState) => companyState.companySettingsLoaded,
);

export const companySettingsDisplayAndNotificationsSelector = createSelector(
    companySelector,
    (companyState) => ({
        isSavingProcess: companyState.isSavingProcess,
        loaded: companyState.loaded,
        enableInvoiceSendingForServices: companyState.enableInvoiceSendingForServices,
        enableInvoiceSendingForBundles: companyState.enableInvoiceSendingForBundles,
        enableInvoiceSendingForAutomaticPayments: companyState.enableInvoiceSendingForAutomaticPayments,
        showReviews: companyState.showReviews,
        rateEnabled: companyState.rateEnabled,
        enableBookingNotifications: companyState.enableBookingNotifications,
        enableBookingEmployeeNotifications: companyState.enableBookingEmployeeNotifications,
        deltaTime: companyState.deltaTime,
        overwriteProductNames: companyState.overwriteProductNames,
        servicesTabName: companyState.servicesTabName,
        groupsTabName: companyState.groupsTabName,
        eventsTabName: companyState.eventsTabName,
        bundlesTabName: companyState.bundlesTabName,
        subscriptionTabName: companyState.subscriptionTabName,
        timezone: companyState.timezone,
    }),
);

export const companySettingsDiscountAndLoyaltySelector = createSelector(
    companySelector,
    (companyState) => ({
        isSavingProcess: companyState.isSavingProcess,
        loaded: companyState.loaded,
        enableBirthdayModule: companyState.enableBirthdayModule,
        birthdayDiscount: companyState.birthdayDiscount,
        beforePeriod: companyState.beforePeriod,
        afterPeriod: companyState.afterPeriod,
        enableLoyaltyProgram: companyState.enableLoyaltyProgram,
        discountPercent: companyState.discountPercent,
        pointsForDiscount: companyState.pointsForDiscount,
        pointsForOnline: companyState.pointsForOnline,
    }),
);

export const companyBuisnessModeSelector = createSelector(
    companySelector,
    (companyState) => ({
        isSavingProcess: companyState.isSavingProcess,
        loaded: companyState.loaded,
        enableBusinessMode: companyState.enableBusinessMode,
    }),
);

export const companyMspSelector = createSelector(
    companySelector,
    (companyState) => ({
        multisafepayLink: companyState.multisafepayLink,
        showMultisafepayBanner: companyState.showMultisafepayBanner,
    }),
);

export const companyAccessModeSelector = createSelector(
    companySelector,
    (companyState) => ({
        isSavingProcess: companyState.isSavingProcess,
        loaded: companyState.loaded,
        accessEnabled: companyState.accessEnabled,
        accessCardsEnabled: companyState.accessCardsEnabled,
    }),
);

export const companyWaitlistEnabled = createSelector(
    companySelector,
    (companyState) => companyState.waitlistEnabled,
);

export const companyQRCodeLoadedSelector = createSelector(
    companySelector,
    (companyState) => companyState.companyQRCodeLoaded,
);

export const companyQRCodeSelector = createSelector(
    companySelector,
    (companyState) => ({
        qrCode: companyState.qrCode,
    }),
);

export const companySlugSelector = createSelector(
    companySelector,
    (companyState) => ({
        slug: companyState.slug,
    }),
);
