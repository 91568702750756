import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { Label } from 'components/Common/Typography/Label';

import NumberInput from 'components/Common/NumberInput';
import { isInvalid, getError } from '../../../../services/validationService';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { SUBSCRIPTION_PROP } from '../../../../const/subscriptions/SUBSCRIPTION_PROP';

import {
    styles as selectStyles,
    theme as selectTheme,
    dangerTheme as selectDangerTheme,
} from '../../../../styles/select';

import * as styles from './SubscriptionServiceFormGroup.module.scss';

const T_PREFIX = 'addOrEdit.form.fields.services';
const T_FIELDS = `${T_PREFIX}.fields`;
const DEFAULT_MAXIMUN_USAGES_WITH_UNLIMITED = -1;

function SubscriptionServiceFormGroup(props) {
    const {
        index,
        service,
        servicesOptions,
        errors,
        touched,
        setFieldValue,
        isDisabled,
        onDelete,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.SUBSCRIPTIONS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const handleServicesSelect = useCallback((selected) => {
        setFieldValue(`services.${index}.name`, selected || []);
    }, [setFieldValue]);

    const handleMaxUsagesValueChange = useCallback((value) => {
        if (service.unlimitedUsages) {
            setFieldValue(`services[${index}].maximumUsages`, DEFAULT_MAXIMUN_USAGES_WITH_UNLIMITED);
            return;
        }
        setFieldValue(`services[${index}].maximumUsages`, value);
    }, [setFieldValue]);

    const handleMaxUsagesUnlimitedChange = useCallback((e) => {
        const { target } = e;
        const { checked } = target;

        setFieldValue(`services[${index}].unlimitedUsages`, checked);
        setFieldValue(`services[${index}].maximumUsages`, checked ? DEFAULT_MAXIMUN_USAGES_WITH_UNLIMITED : 1);
    }, [setFieldValue]);

    const handlePeriodSelect = useCallback((selected) => {
        setFieldValue(`services[${index}].period`, selected.value);
    }, [setFieldValue]);

    const handleDelete = useCallback(() => {
        onDelete({ id: service.id });
    }, [onDelete, service.id]);

    const handleMissedUsagesSelectedChange = useCallback((e) => {
        const { target } = e;
        const { checked } = target;
        setFieldValue(`services[${index}].missedUsages.selected`, checked);
    }, [onDelete, service.id]);

    const handleMissedUsagesValueChange = useCallback((value) => {
        setFieldValue(`services[${index}].missedUsages.value`, value);
    }, [onDelete, service.id]);

    const nameIsInvalid = isInvalid(`services[${index}].name`, errors, touched);
    const maximumUsagesIsInvalid = isInvalid(`services[${index}].maximumUsages`, errors, touched);
    const missedUsagesIsInvalid = isInvalid(`services[${index}].missedUsages.value`, errors, touched);

    const periodValue = useMemo(() => ({
        value: service.period,
        label: t(`${T_FIELDS}.period.units.${service.period}`),
    }), [service.period, t]);

    const periodOptions = useMemo(() => (
        SUBSCRIPTION_PROP.SERVICES.PERIOD.ENUM.map((p) => ({
            value: p,
            label: t(`${T_FIELDS}.period.units.${p}`),
        }))
    ), [t]);

    return (
        <Row
            className={classNames(
                'mt-3 pb-3',
                styles.serviceForm,
            )}
        >
            <Form.Group
                as={Col}
                sm={12}
                xl={4}
            >
                <Form.Label className="font-size-15">
                    {t(`${T_FIELDS}.name.label`)}
                </Form.Label>
                <Select
                    name="name"
                    styles={selectStyles}
                    isMulti
                    theme={nameIsInvalid ? selectDangerTheme : selectTheme}
                    isClearable
                    options={servicesOptions}
                    value={service.name}
                    onChange={handleServicesSelect}
                    placeholder={tc('placeholders.select')}
                />
                <Form.Control.Feedback
                    type="invalid"
                    className={classNames({
                        'd-flex': nameIsInvalid,
                    })}
                >
                    {getError(`services[${index}].name`, errors)}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
                as={Col}
                sm={12}
                lg={3}
                xl={2}
            >
                <Form.Label className="font-size-15">
                    {t(`${T_FIELDS}.maxUsages.value.label`)}
                </Form.Label>
                <NumberInput
                    disabled={service.unlimitedUsages || isDisabled}
                    value={service.unlimitedUsages ? '' : service.maximumUsages}
                    onChange={handleMaxUsagesValueChange}
                    isInvalid={maximumUsagesIsInvalid}
                    min={0}
                />
                <Form.Control.Feedback
                    type="invalid"
                    className={classNames({
                        'd-flex': maximumUsagesIsInvalid,
                    })}
                >
                    {getError(`services[${index}].maximumUsages`, errors)}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
                as={Col}
                sm={12}
                lg={3}
                xl={2}
            >
                <div
                    className={classNames(
                        'd-flex align-items-center',
                        styles.unlimitedUsagesContainer,
                    )}
                >
                    <CheckBox
                        checked={service.unlimitedUsages}
                        name={`services[${index}].unlimitedUsages`}
                        onChange={handleMaxUsagesUnlimitedChange}
                        disabled={isDisabled}
                        label={t(`${T_FIELDS}.maxUsages.unlimited.label`)}
                    />
                </div>
            </Form.Group>
            <Form.Group
                as={Col}
                sm={12}
                lg={3}
                xl={2}
            >
                <Form.Label className="font-size-15">
                    {t(`${T_FIELDS}.period.label`)}
                </Form.Label>
                <Select
                    styles={selectStyles}
                    theme={selectTheme}
                    options={periodOptions}
                    name={`services[${index}].period`}
                    value={periodValue}
                    onChange={handlePeriodSelect}
                    placeholder={tc('placeholders.select')}
                    isDisabled={isDisabled}
                />
                <Form.Control.Feedback>
                    {getError(`services[${index}].period`, errors)}
                </Form.Control.Feedback>
            </Form.Group>
            <Col
                sm={12}
                lg={3}
                xl={2}
                className={classNames(
                    'd-lg-flex flex-column',
                    styles.deleteButtonContainer,
                )}
            >
                <Button
                    type="button"
                    color="red"
                    onClick={handleDelete}
                    disabled={isDisabled}
                    className="align-self-lg-end"
                >
                    {t(`${T_PREFIX}.actions.delete`)}
                </Button>
            </Col>
            {!service.unlimitedUsages && (
                <Form.Group
                    as={Col}
                    sm={12}
                    className="mt-3 mt-lg-0"
                >
                    <div className="d-flex flex-wrap align-items-center">
                        <CheckBox
                            checked={service.missedUsages.selected}
                            name={`services[${index}].missedUsages.selected`}
                            onChange={handleMissedUsagesSelectedChange}
                            disabled={isDisabled}
                            label={t(`${T_FIELDS}.missedUsages.pre`)}
                        />
                        <div className={styles.missedUsagesWeeksContainer}>
                            <NumberInput
                                disabled={!service.missedUsages.selected || isDisabled}
                                value={(
                                    service.missedUsages.selected
                                        ? service.missedUsages.value
                                        : ''
                                )}
                                onChange={handleMissedUsagesValueChange}
                                isInvalid={missedUsagesIsInvalid}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className={classNames({
                                    'd-flex': missedUsagesIsInvalid,
                                })}
                            >
                                {getError(`services[${index}].missedUsages.value`, errors)}
                            </Form.Control.Feedback>
                        </div>
                        <Label noWrap>
                            {t(`${T_FIELDS}.missedUsages.post`, {
                                count: service.missedUsages.selected
                                    ? Number(service.missedUsages.value)
                                    : SUBSCRIPTION_PROP.SERVICES.MISSED_USAGES.VALUE.DEFAULT,
                                unit: t(`${T_FIELDS}.missedUsages.units.${service.period}`),
                            })}
                        </Label>
                    </div>
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-flex': missedUsagesIsInvalid,
                        })}
                    >
                        {getError(`services[${index}].missedUsages.value`, errors)}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </Row>
    );
}

export default SubscriptionServiceFormGroup;
