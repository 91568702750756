import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { EMAILS_SORT_BY_TYPE } from 'const/email/EMAILS_SORT_BY_TYPE';
import { ENVS } from 'services/envs';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'clientEmailsTable.header';

const ClientEmailsTableHeaderRow = ({ onCellClick, sortOptions }) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.USER);

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-emails-table-header-created-at"
                    name={t(`${T_PREFIX}.emailSent`)}
                    active={sortOptions.sortBy === EMAILS_SORT_BY_TYPE.CREATED_AT}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(EMAILS_SORT_BY_TYPE.CREATED_AT)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-emails-table-header-updated-at"
                    name={t(`${T_PREFIX}.lastUpdated`)}
                    active={sortOptions.sortBy === EMAILS_SORT_BY_TYPE.UPDATED_AT}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(EMAILS_SORT_BY_TYPE.UPDATED_AT)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-emails-table-header-status"
                    name={t(`${T_PREFIX}.deliveryStatus`)}
                    active={sortOptions.sortBy === EMAILS_SORT_BY_TYPE.STATUS}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(EMAILS_SORT_BY_TYPE.STATUS)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-emails-table-header-details"
                    name={t(`${T_PREFIX}.details`)}
                    active={sortOptions.sortBy === EMAILS_SORT_BY_TYPE.REASON}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(EMAILS_SORT_BY_TYPE.REASON)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    data-testid="data-test-emails-table-header-subject"
                    name={t(`${T_PREFIX}.emailSubject`)}
                    active={sortOptions.sortBy === EMAILS_SORT_BY_TYPE.SUBJECT}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => onCellClick(EMAILS_SORT_BY_TYPE.SUBJECT)}
                />
            </TableHeaderCell>
            {ENVS.isDevelopment && (
                <TableHeaderCell>
                    <ListTableHeaderCell
                        data-testid="data-test-emails-table-header-email-id"
                        name="Email ID"
                        active={sortOptions.sortBy === EMAILS_SORT_BY_TYPE.MESSAGE_ID}
                        ascending={sortOptions.orderBy === -1}
                        onClick={() => onCellClick(EMAILS_SORT_BY_TYPE.MESSAGE_ID)}
                    />
                </TableHeaderCell>
            )}
        </React.Fragment>
    );
};

ClientEmailsTableHeaderRow.propTypes = {};

ClientEmailsTableHeaderRow.defaultProps = {};

export default ClientEmailsTableHeaderRow;
