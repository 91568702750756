import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const ClearingsTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLEARINGS);

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.number.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.date.label`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.totalAmount.label`)}
                />
            </TableHeaderCell>

            <TableHeaderCell />
        </React.Fragment>
    );
};

ClearingsTableHeader.propTypes = {};

ClearingsTableHeader.defaultProps = {};

export default ClearingsTableHeader;
