import React from 'react';

const ArrowDiagonalUp = ({ width = '24', height = '24' }) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.633 9.41475C10.633 9.78294 10.9315 10.0814 11.2997 10.0814C11.6678 10.0814 11.9663 9.78294 11.9663 9.41475V4.70071C11.9663 4.33252 11.6678 4.03404 11.2997 4.03404L6.58561 4.03404C6.21742 4.03404 5.91894 4.33252 5.91894 4.70071C5.91894 5.0689 6.21742 5.36737 6.58561 5.36737L9.69015 5.36737L4.22856 10.829C3.96821 11.0893 3.96821 11.5114 4.22856 11.7718C4.48891 12.0321 4.91102 12.0321 5.17137 11.7718L10.633 6.31016L10.633 9.41475Z" fill="currentColor" />
    </svg>

);

export default ArrowDiagonalUp;
