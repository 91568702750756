import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { getGatesForSelect } from 'store/actions';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';

import * as SERVICES_ACTIONS from 'store/actions/services';
import WindowedSelect, { components } from 'react-windowed-select';
import TextButton from 'components/Common/TextButton/TextButton';
import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';
import { CheckBox } from 'components/Common/CheckBox';
import * as styles from './GatesWindowedSelect.module.scss';

const GatesWindowedSelect = ({ formik }) => {
    const dispatch = useDispatch();

    const {
        values, setFieldValue, errors, touched,
    } = formik;

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [
        dispatch,
    ]);

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const [value, setValue] = useState(null);
    const [accessGates, setAccessGates] = useState([]);

    const selectGatesOptions = useMemo(() => [
        { value: null, label: '' },
        ...accessGates.map((gate, index) => ({
            value: gate.id,
            label: gate.name,
            index,
        })),
    ], [accessGates]);

    const valuesGatesIdsSet = useMemo(() => new Set(values?.gates?.map((c) => c.id)), [values?.gates?.length]);

    useEffect(() => {
        dispatch(getGatesForSelect())
            .then((gates) => setAccessGates(gates?.items || []));
    }, []);

    const isAllGatesSelected = values?.gates.length === accessGates.length;

    const handleSetValues = useCallback((gates) => {
        const valies = gates.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (gates.length > 100) {
            valies.push({ value: -1, label: tc('weekRoute.more', { count: gates.length - 100 }) });
        }
        setValue(valies);
    }, []);

    useEffect(() => {
        if (values.gates.length) {
            handleSetValues(values.gates);
        }
    }, [!!values.gates.length]);

    const handleChangeAllGatesSelect = useCallback(({ clear }) => {
        let gates = [];
        if (isAllGatesSelected || clear) {
            setFieldValue('gates', gates);
        } else {
            gates = accessGates.map((c) => ({
                id: c.id,
                name: c.name,
            }));
            setFieldValue('gates', gates);
        }
        handleSetValues(gates);
    }, [isAllGatesSelected, accessGates]);

    const handleChangeGateSelect = useCallback((gate) => {
        if (!gate.value) {
            return;
        }
        let gates = [];
        const isChecked = valuesGatesIdsSet.has(gate.value);
        if (!isChecked) {
            gates = [...values.gates, { id: gate.value, name: gate.label }];
            setFieldValue('gates', gates);
        } else {
            gates = values.gates.filter((c) => c.id !== gate.value);
            setFieldValue('gates', gates);
        }

        handleSetValues(gates);
    }, [values?.gates.length, valuesGatesIdsSet]);

    const handleChangeGatesSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllGatesSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeGateSelect(triggeredAction.removedValue);
                return;
            }
            const gates = values.gates.slice(0, 100);
            setFieldValue('gates', gates);
            handleSetValues(gates);
        }
        handleChangeGateSelect(triggeredAction.option);
    }, [handleChangeGateSelect, handleChangeAllGatesSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllGatesSelected) {
            e.preventDefault();
            setFieldValue('gates', []);
        }
    }, [isAllGatesSelected]);

    const isGatesSelectInvalid = !!touched.gates && !!errors.gates;

    return (
        <React.Fragment>
            <WindowedSelect
                id="gates"
                name="gates"
                styles={selectWithGrayItems}
                theme={isGatesSelectInvalid ? dangerTheme : theme}
                options={selectGatesOptions}
                value={value}
                onChange={handleChangeGatesSelect}
                isMulti
                isClearable
                backspaceRemovesValue
                onKeyDown={onKeyDownHandle}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={t('profiles.add.gates.placeholder')}
                components={{
                    MultiValue: values.gates.length === accessGates.length
                        ? (props) => (
                            props.index === 0
                                ? t('profiles.add.gates.allGates.label')
                                : null
                        )
                    // eslint-disable-next-line react/no-unstable-nested-components
                        : (props) => <components.MultiValue {...props} />,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Option: ({
                        innerRef, innerProps, data,
                    }) => {
                        const isChecked = valuesGatesIdsSet.has(data.value);

                        return (
                            <div
                                ref={innerRef}
                                {...innerProps}
                                className={styles.option}
                            >
                                {data.value === null
                                    ? (
                                        <TextButton color="yellow" onClick={handleChangeAllGatesSelect}>
                                            {t('profiles.add.gates.allGates.label')}
                                        </TextButton>
                                    )
                                    : (
                                        <CheckBox
                                            checked={isChecked}
                                            label={data.label}
                                        />
                                    )}
                            </div>
                        );
                    },
                }}
            />

            <Form.Control.Feedback
                className={isGatesSelectInvalid && 'd-block'}
                type="invalid"
            >
                {errors.gates}
            </Form.Control.Feedback>
        </React.Fragment>
    );
};

export default GatesWindowedSelect;
