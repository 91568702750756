import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';
import { GET_BOOKING_PAYMENT_OPTIONS_FAILED, GET_BOOKING_PAYMENT_OPTIONS_SUCCEEDED } from './actionTypes';

export const getBookings = (from, to, productType) => ({
    type: actionTypes.GET_BOOKINGS,
    payload: {
        from,
        to,
        productType,
    },
});
export const getBookingsSucceeded = (bookings, productType) => ({
    type: actionTypes.GET_BOOKINGS_SUCCEEDED,
    bookings,
    productType,
});
export const getBookingsFailed = (productType) => ({
    type: actionTypes.GET_BOOKINGS_FAILED,
    productType,
});

export const addBooking = createPromiseAction(actionTypes.ADD_BOOKING);
export const addBookingSucceeded = (booking) => ({
    type: actionTypes.ADD_BOOKING_SUCCEEDED,
    booking,
});
export const addBookingFailed = () => ({
    type: actionTypes.ADD_BOOKING_FAILED,
});

export const getBookingPaymentOptions = createPromiseAction(actionTypes.GET_BOOKING_PAYMENT_OPTIONS);
export const getBookingPaymentOptionsSucceeded = (booking) => ({
    type: GET_BOOKING_PAYMENT_OPTIONS_SUCCEEDED,
    payment: booking,
});
export const getBookingPaymentOptionsFailed = () => ({
    type: GET_BOOKING_PAYMENT_OPTIONS_FAILED,
});

export const editBooking = createPromiseAction(actionTypes.EDIT_BOOKING);
export const editBookingSucceeded = (booking) => ({
    type: actionTypes.EDIT_BOOKING_SUCCEEDED,
    booking,
});
export const editBookingFailed = () => ({
    type: actionTypes.EDIT_BOOKING_FAILED,
});

export const deleteBooking = createPromiseAction(actionTypes.DELETE_BOOKING);
export const deleteBookingSucceeded = (booking, timestamp) => ({
    type: actionTypes.DELETE_BOOKING_SUCCEEDED,
    booking,
    timestamp,
});
export const deleteBookingFailed = () => ({
    type: actionTypes.DELETE_BOOKING_FAILED,
});

export const omitBooking = createPromiseAction(actionTypes.OMIT_BOOKING);
export const omitBookingSucceeded = (bookingID, timestamp) => ({
    type: actionTypes.OMIT_BOOKING_SUCCEEDED,
    bookingID,
    timestamp,
});
export const omitBookingFailed = () => ({
    type: actionTypes.OMIT_BOOKING_FAILED,
});

export const changePayStatus = createPromiseAction(actionTypes.CHANGE_PAY_STATUS_BOOKING);
export const changePayStatusSucceeded = (bookingID, timestamp, paymentStatus) => ({
    type: actionTypes.CHANGE_PAY_STATUS_BOOKING_SUCCEEDED,
    bookingID,
    timestamp,
    paymentStatus,
});
export const changePayStatusFailed = () => ({
    type: actionTypes.CHANGE_PAY_STATUS_BOOKING_FAILED,
});

export const setAddBookingDetails = ({
    time, product, service, client, subOptionService,
}) => ({
    type: actionTypes.SET_ADD_BOOKING_DETAILS,
    time,
    product,
    service,
    client,
    subOptionService,
});

export const clearAddBookingDetails = () => ({
    type: actionTypes.CLEAR_ADD_BOOKING_DETAILS,
});
