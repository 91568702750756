// extracted by mini-css-extract-plugin
var _1 = "Booking_clickable__t62mv";
var _2 = "Booking_clientName__GduWh";
var _3 = "Booking_compact__a4wrZ";
var _4 = "Booking_container__mZElp";
var _5 = "Booking_content__CJzbM";
var _6 = "Booking_extraTimeContainer__z-oPf";
var _7 = "Booking_inProgress__Q6kFi";
var _8 = "Booking_madeFromIconWrapper__fcfed";
var _9 = "Booking_notPresent__wNygy";
var _a = "Booking_paid__7aVJI";
var _b = "Booking_paidAfterwards__ZHC0h";
var _c = "Booking_serviceName__2SlZX";
export { _1 as "clickable", _2 as "clientName", _3 as "compact", _4 as "container", _5 as "content", _6 as "extraTimeContainer", _7 as "inProgress", _8 as "madeFromIconWrapper", _9 as "notPresent", _a as "paid", _b as "paidAfterwards", _c as "serviceName" }
