import React from 'react';

// Hooks
import { useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Actions

// Components
import {
    Modal, Row, Col, InputGroup, Form,

} from 'react-bootstrap';

// Utils
import moment from 'moment';
import _ from 'lodash';
import * as yup from 'yup';
import classNames from 'classnames';
import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { Column } from 'components/Common/Column';
import { HourInput } from 'components/Common/HourInput';
import NumberInput from 'components/Common/NumberInput';
import { CalendarIcon, ClockIcon } from 'components/Icon/Icon';
import DateInput from '../DateInput/DateInput';
import * as actions from '../../store/actions/bookings';

const EditBookingModal = ({
    bookingIDWithTimestamp, show, onHide, productType, onBookingClick,
}) => {
    const { t } = useTranslation();
    /* istanbul ignore next */
    const schema = useRef(yup.object({
        time: yup.object({
            from: yup.mixed().required(t('validationErrors.cannotBeEmpty', { name: t('editBookingModal.date') })),
        }).required(),
        recurrence: yup.object({
            enabled: yup.bool(),
            period: yup
                .number()
                .when('enabled', {
                    is: true,
                    then: (period) => period
                        .positive(t('validationErrors.mustBePositiveNumber', { name: t('editBookingModal.chooseEveryNumberOfWeeks') }))
                        .typeError(t('validationErrors.cannotBeEmpty', { name: t('editBookingModal.chooseEveryNumberOfWeeks') }))
                        .required(),
                    otherwise: (period) => period
                        .nullable(),
                }),
            infinite: yup.bool().when('enabled', { is: true, then: (infinite) => infinite.required() }),
            end: yup.mixed()
                .when(['enabled', 'infinite'], {
                    is: (enabled, infinite) => enabled && !infinite,
                    then: (end) => end.required(t('validationErrors.cannotBeEmpty', { name: t('editBookingModal.endRecurring') })),
                }),
            days: yup.array().of(yup.string().oneOf([
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ])),
        }).required(),
    })).current;

    const shortFormBooking = productType === 'group' || productType === 'event';

    const { locale } = useSelector((state) => state.locales);

    const bookings = useSelector((state) => {
        if (productType === 'event') {
            return state.events?.events?.map((item) => item.participants).flat();
        }
        return state[`${productType}s`].bookings;
    });

    const products = useSelector((state) => {
        if (shortFormBooking) return null;
        return state[`${productType}s`][`${productType}s`];
    });

    const workingHours = useSelector((state) => state.shop?.workingDays);

    const dispatch = useDispatch();

    const booking = bookings && bookings.find(({ id, time }) => bookingIDWithTimestamp?.id === id && bookingIDWithTimestamp.timestamp === time.from);

    const initialValues = useMemo(() => ({
        time: {
            from: booking && moment(booking.time.from),
        },
        recurrence: booking ? {
            ...booking.recurrence,
            end: !booking.recurrence.end ? null : moment(booking.recurrence.end),
            infinite: booking.recurrence.infinite ?? !booking.recurrence.end,
        } : {
            enabled: false,
            end: null,
            infinite: false,
        },
    }), [booking]);

    const {
        handleSubmit, handleChange, setFieldValue, values, isSubmitting, errors, touched, resetForm,
    } = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setSubmitting, setErrors, resetForm }) => {
            dispatch(actions.editBooking({
                booking: {
                    ...booking,
                    ...values,
                },
            }))
                .then(() => {
                    if (onBookingClick) {
                        onBookingClick((prevBooking) => ({
                            ...prevBooking,
                            timestamp: values.time.from.unix() * 1000,
                        }));
                    }
                    onHide();
                    resetForm();
                    addToast(t('editBookingModal.bookingUpdatedSuccessfully'), {
                        appearance: 'success',
                    });
                })
                .catch(({ message, errors }) => {
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                        });
                    } else {
                        setErrors(errors || {});
                    }
                })
                .finally(() => setSubmitting(false));
        },
        enableReinitialize: true,
    });

    const { addToast } = useToasts();

    const checkHourInputAvailability = () => {
        const time = values.time?.from ?? (booking && moment(booking.time.from));
        const product = booking?.product;

        const defaultFrom = workingHours?.[time ? time.format('dddd').toLowerCase() : moment().format('dddd').toLowerCase()]?.from;
        const defaultTo = workingHours?.[time ? time.format('dddd').toLowerCase() : moment().format('dddd').toLowerCase()]?.to;

        if (!product?.id || !time) {
            return {
                from: defaultFrom,
                to: defaultTo,
            };
        }

        const foundProduct = products.find((prod) => prod.id === product.id);

        if (foundProduct?.hasOwnSchedule) {
            const scheduleForWeekday = Object.entries(foundProduct.workingDays).find(([weekday]) => t(`date.weekDay.${weekday}`) === time.locale(locale).format('dddd').toLowerCase());

            if (scheduleForWeekday) {
                const [__, { from, to }] = scheduleForWeekday;
                return { from, to };
            }
            return { from: null, to: null };
        }

        return {
            from: defaultFrom,
            to: defaultTo,
        };
    };

    return (
        <Modal centered size="lg" show={show} onHide={onHide}>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0 font-weight-600">
                        {t('editBookingModal.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!shortFormBooking && (
                        <Row>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                        <DateInput
                                            name="time.from.date"
                                            value={values.time.from}
                                            placeholder={t('editBookingModal.selectDate')}
                                            onChange={(time) => setFieldValue('time.from', time)}
                                            isInvalid={!!touched.time && !!touched.time.from && !!errors.time && !!errors.time.from}
                                            isDisabled={!booking?.isEditable}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className={classNames({
                                                    'border-danger text-danger': !!touched.time?.from && !!errors.time?.from,
                                                    'text-muted': !touched.time?.from || !errors.time?.from,
                                                })}
                                            >
                                                <CalendarIcon width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    <Form.Control.Feedback
                                        className={!!touched.time && !!touched.time.from && !!errors.time && !!errors.time.from && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.time && errors.time.from}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                        <HourInput
                                            name="time.from.time"
                                            value={values.time.from}
                                            placeholder={t('editBookingModal.selectTime')}
                                            onChange={(time) => setFieldValue('time.from', time)}
                                            isInvalid={!!touched.time && !!touched.time.from && !!errors.time && !!errors.time.from}
                                            min={checkHourInputAvailability().from}
                                            max={checkHourInputAvailability().to}
                                            isDisabled={!booking?.isEditable}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className={classNames({
                                                    'border-danger text-danger': !!touched.time?.from && !!errors.time?.from,
                                                    'text-muted': !touched.time?.from || !errors.time?.from,
                                                })}
                                            >
                                                <ClockIcon width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}

                    {(productType !== 'event' && booking?.recurrence.enabled) && (
                        <Form.Group className="my-2" controlId="recurring">
                            <CheckBox
                                disabled
                                id="recurrence.enabled"
                                label={t('editBookingModal.recurring')}
                                name="recurrence.enabled"
                                checked={values.recurrence.enabled}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    )}

                    {values.recurrence.enabled && (
                        <React.Fragment>
                            <Form.Group className="mb-3">
                                <label
                                    className="form-label"
                                >
                                    {t('editBookingModal.chooseEveryNumberOfWeeks')}
                                </label>
                                <NumberInput
                                    name="recurrence.period"
                                    value={values.recurrence.period || ''}
                                    onChange={(value) => setFieldValue('recurrence.period', value)}
                                    isInvalid={!!touched.recurrence && !!touched.recurrence.period && !!errors.recurrence && !!errors.recurrence.period}
                                    disabled={!booking?.isEditable}
                                    min={0}
                                />
                                <Form.Control.Feedback
                                    className={!!touched.recurrence && !!touched.recurrence.period && !!errors.recurrence && !!errors.recurrence.period && 'd-block'}
                                    type="invalid"
                                >
                                    {errors.recurrence && errors.recurrence.period}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <CheckBox
                                    id="recurrence.infinite"
                                    label={t('editBookingModal.infinite')}
                                    name="recurrence.infinite"
                                    value={values.recurrence.infinite}
                                    checked={values.recurrence.infinite}
                                    onChange={handleChange}
                                    isInvalid={!!touched.recurrence && !!touched.recurrence.infinite && !!errors.recurrence && !!errors.recurrence.infinite}
                                    disabled={!booking?.isEditable}
                                />
                            </Form.Group>
                            {!values.recurrence.infinite && (
                                <Form.Group className="mb-3">
                                    <label className="form-label">{t('editBookingModal.endRecurring')}</label>
                                    <InputGroup>
                                        <DateInput
                                            name="recurrence.end"
                                            className={classNames({
                                                'border-danger': !!touched.recurrence && !!touched.recurrence.end && !!errors.recurrence && !!errors.recurrence.end,
                                            })}
                                            value={values.recurrence.end}
                                            placeholder={t('editBookingModal.selectDate')}
                                            onChange={(date) => setFieldValue('recurrence.end', date)}
                                            isInvalid={!!touched.recurrence && !!touched.recurrence.end && !!errors.recurrence && !!errors.recurrence.end}
                                            isDisabled={!booking?.isEditable}
                                            placement="top-start"
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className={classNames({
                                                'border-danger text-danger': !!touched.recurrence && !!touched.recurrence.end && !!errors.recurrence && !!errors.recurrence.end,
                                                'text-muted': !touched.recurrence || !touched.recurrence.end || !errors.recurrence || !errors.recurrence.end,
                                            })}
                                            >
                                                <CalendarIcon width={18} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback
                                        className={!!touched.recurrence && !!touched.recurrence.end && !!errors.recurrence && !!errors.recurrence.end && 'd-block'}
                                        type="invalid"
                                    >
                                        {errors.recurrence && errors.recurrence.end}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                            {!shortFormBooking && (
                                <Form.Group>
                                    <label className="form-label">{t('editBookingModal.daysRecurring')}</label>
                                    <Column gap={8}>
                                        {_.times(7, (i) => {
                                            const localeDay = moment().locale(locale).isoWeekday(i + 1).format('dddd');
                                            const day = moment().locale('en').isoWeekday(i + 1).format('dddd');

                                            return (
                                                <CheckBox
                                                    key={`day-${i}`}
                                                    id={`recurrence.days[${i}]`}
                                                    checked={values.recurrence.days.indexOf(day) !== -1}
                                                    label={localeDay}
                                                    disabled={!booking?.isEditable}
                                                    onChange={(e) => {
                                                        if (values.recurrence.days.indexOf(day) !== -1 && !e.target.checked) {
                                                            setFieldValue('recurrence.days', values.recurrence.days.filter((weekday) => weekday !== day));
                                                        } else {
                                                            setFieldValue('recurrence.days', [...values.recurrence.days, day]);
                                                        }
                                                    }}
                                                />
                                            );
                                        })}
                                    </Column>
                                </Form.Group>
                            )}
                        </React.Fragment>
                    )}

                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        type="button"
                        color="outline"
                        onClick={() => {
                            onHide();
                            resetForm();
                        }}
                    >
                        {t(
                            `editBookingModal.${!booking?.recurrence.enabled || booking?.isEditable ? 'cancel' : 'back'}`,
                        )}
                    </Button>
                    {(!booking?.recurrence.enabled || booking?.isEditable) && (
                        <Button type="submit" loading={isSubmitting}>
                            {t('editBookingModal.save')}
                        </Button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default EditBookingModal;
