/* eslint-disable import/prefer-default-export */
import { PERIODS_MAP } from 'components/Common/PeriodPicker/PeriodPicker';
import { ACCESS_LOGS_SORT_BY_TYPE } from 'const/access/ACCESS_LOGS_SORT_BY_TYPE';
import moment from 'moment';
import * as ACTIONS from '../../../actions/clients/tables/access/actionTypes';

const INITIAL_STATE = {
    page: 1,
    items: [],
    search: '',
    sortOptions: {
        sortBy: ACCESS_LOGS_SORT_BY_TYPE.START_DATE,
        orderBy: 1,
    },
    showAll: false,
    isLoading: false,
    itemsCount: 0,
    pagesCount: 0,
    itemsPerPage: 10,
    date: {
        period: PERIODS_MAP.month,
        dateFrom: moment().startOf(PERIODS_MAP.month),
        dateTo: moment().endOf(PERIODS_MAP.month),
    },
    filter: null,
    hasNewItems: false,
};

export const clientAccessLogsListReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
    case (ACTIONS.GET_CLIENTS_ACCESS_LOGS_RESOLVED): {
        const { items, itemsCount, pagesCount } = payload;
        const page = Math.min(state.page, pagesCount || 1);
        return {
            ...state,
            items,
            page,
            itemsCount,
            pagesCount,
            hasNewItems: false,
            isLoading: false,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_CLEAR): {
        return INITIAL_STATE;
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_PAGE_SET): {
        const { page } = payload;
        return {
            ...state,
            page,
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_SHOW_ALL): {
        return {
            ...state,
            page: 0,
            showAll: true,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_PAGE_SET_FIRST): {
        return {
            ...state,
            page: 1,
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_PAGE_SET_LAST): {
        return {
            ...state,
            page: state.pagesCount,
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_PAGE_SET_PREV): {
        return {
            ...state,
            page: Math.max(state.page - 1, 1),
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_PAGE_SET_NEXT): {
        return {
            ...state,
            page: Math.min(state.page + 1, state.pagesCount),
            showAll: false,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_SEARCH_SET): {
        const { search } = payload;

        return {
            ...state,
            search,
        };
    }
    case (ACTIONS.CLIENTS_ACCESS_LOGS_SELECTED_DATE_SET): {
        const { dateFrom, dateTo, period } = payload;

        return {
            ...state,
            date: {
                period,
                dateFrom,
                dateTo,
            },
        };
    }
    case ACTIONS.CLIENTS_ACCESS_LOGS_FILTER_SET: {
        const { filter } = payload;

        return {
            ...state, filter,
        };
    }
    case (ACTIONS.GET_CLIENTS_ACCESS_LOGS_TRIGGER): {
        const { silent } = payload ?? {};
        return {
            ...state,
            isLoading: !silent ?? true,
        };
    }
    case (ACTIONS.GET_CLIENTS_ACCESS_LOGS_REJECTED): {
        return {
            ...state,
            isLoading: false,
        };
    }
    case ACTIONS.CLIENTS_ACCESS_LOGS_SORT_OPTIONS_SET: {
        const { sortOptions: { sortBy, orderBy } } = payload;

        return {
            ...state,
            sortOptions: {
                sortBy: sortBy ?? state.sortOptions.sortBy,
                orderBy: orderBy ?? state.sortOptions.orderBy,
            },
        };
    }
    default:
        return state;
    }
};
