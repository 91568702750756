import React from 'react';

// Components
import { Collapse } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

// Styles

// Utils
import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'components/Common/Button';
import { CheckBox } from 'components/Common/CheckBox';
import { Column } from 'components/Common/Column';
import { ArrowSmallUpIcon, ArrowSmallDownIcon } from 'components/Icon/Icon';
import * as styles from './Sidebar.module.scss';
import Calendar from '../../Calendar/Calendar';

const SidebarBody = ({
    title,
    viewType,
    date,
    onSelectDate,
    month,
    year,
    onChangeTimespan,
    setShowFilters,
    showFilters,
    products,
    filters,
    onFilterClick,
}) => (
    <React.Fragment>
        <Calendar
            selectTimespan={viewType}
            selectedDate={date}
            onSelectDate={onSelectDate}
            month={month}
            year={year}
            onChangeTimespan={onChangeTimespan}
        />

        {(filters && products) && (
            <React.Fragment>
                <Button
                    data-testid="toggleFiltersButton"
                    color="transparent"
                    stretched
                    className="mt-4"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <span className="text-left flex-grow-1">{title}</span>
                    {showFilters ? (
                        <ArrowSmallUpIcon />
                    ) : (
                        <ArrowSmallDownIcon />
                    )}
                </Button>

                <Collapse
                    className={classNames(styles.objectsCollapse, 'mx-4 my-2 px-2')}
                    in={showFilters}
                >
                    <div>
                        <Column gap={8}>
                            {products?.map(({ id, name }) => (
                                <CheckBox
                                    key={`employee-${id}`}
                                    label={name}
                                    checked={filters[id]}
                                    onChange={({ target: { checked } }) => onFilterClick(id, checked)}
                                />
                            ))}
                            {!products
                            && _.times(5, (i) => (
                                <div key={`skeleton-${i}`} className="d-block">
                                    <Skeleton
                                        width={130 + i * 10 * (i % 2 === 0 ? -1 : 1)}
                                        height={18}
                                    />
                                </div>
                            ))}
                        </Column>
                    </div>
                </Collapse>
            </React.Fragment>
        )}
    </React.Fragment>
);

export default SidebarBody;
