/* eslint-disable no-unused-vars */
import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { formatPrice } from 'helpers/formatters/formatPrice';
import i18n from 'services/i18n';

class SubscriptionDataTransformations {
    static transformFormDataIntoPayload(data) {
        const {
            subscriptionInfo: {
                title: name,
                description,
                contractLength: duration,
            },
            services: servicesData,
            pricing: {
                priceWithVAT: price,
                VAT: { value: vatValue },
            },
            active,
            buyable,
        } = data;

        const groups = servicesData.map((service) => {
            const {
                id,
                name,
                maximumUsages,
                unlimitedUsages,
                period,
                missedUsages,
            } = service;
            const services = name.map(({ value }) => value);

            return ({
                id,
                services,
                maxUsages: unlimitedUsages ? -1 : Number(maximumUsages),
                periodicity: period,
                useMissedUsagesAfter: (!unlimitedUsages && missedUsages.selected)
                    ? missedUsages.value
                    : 0,
            });
        });

        const payload = {
            name,
            description,
            duration,
            price: Number(price),
            vatValue,
            status: Number(active),
            buyable: Number(buyable),
            groups,
        };

        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (!Array.isArray(value)) {
                formData.append(key, String(value));

                return;
            }

            value.forEach((item, i) => {
                Object.entries(item).forEach(([childKey, childValue]) => {
                    if (!Array.isArray(childValue)) {
                        formData.append(`${key}[${i}][${childKey}]`, String(childValue));

                        return;
                    }

                    childValue.forEach((item, j) => {
                        formData.append(`${key}[${i}][${childKey}][${j}]`, String(item));
                    });
                });
            });
        });

        return formData;
    }

    static transformResponseIntoFormData(data) {
        return data.map((item) => {
            const {
                description,
                duration: contractLength,
                groups,
                id,
                name: title,
                price: priceWithVAT,
                price_excluding_vat: priceWithoutVAT,
                status: active,
                vat_value,
                sold_counter: soldCounter,
                buyable,
            } = item;

            const services = groups.map((group) => {
                const {
                    id,
                    max_usages: maximumUsages,
                    periodicity,
                    services,
                    use_missed_usages_after: useMissedUsagesAfter,
                } = group;

                const unlimitedUsages = Boolean(maximumUsages < 0);
                const name = services.map(({ name, service_id }) => ({ label: name, value: service_id }));

                return ({
                    id,
                    name,
                    maximumUsages,
                    unlimitedUsages,
                    period: periodicity,
                    missedUsages: {
                        selected: useMissedUsagesAfter > 0,
                        value: useMissedUsagesAfter > 0 ? useMissedUsagesAfter : 0,
                    },
                });
            });

            const VAT = getVATRateSelectOptions(true).find(({ value }) => value === vat_value);

            return ({
                id,
                subscriptionInfo: {
                    title,
                    contractLength,
                    description,
                },
                services,
                pricing: {
                    priceWithVAT,
                    VAT,
                    priceWithoutVAT,
                },
                soldCounter,
                active,
                buyable: Boolean(buyable),
            });
        });
    }

    static transformSellSubscriptionModalDataIntoPayload(data) {
        return Object.entries(data).reduce((acc, [key, value]) => {
            if (typeof value === 'number' || typeof value === 'string') {
                acc.append(key, value);
                return acc;
            }
            if (value !== null && value !== undefined) {
                acc.append(key, String(value));
                return acc;
            }
            return acc;
        }, new FormData());
    }

    static transformSoldSubscriptionsResponse(soldSubscriptions) {
        return soldSubscriptions.reduce((acc, soldSubscription) => {
            const {
                id,
                client_id: clientId,
                client_email: clientEmail,
                client_last_name: clientLastName,
                client_name: clientName,
                client_age: clientAge,
                subscription_id: subscriptionId,
                subscription_name: subscriptionTitle,
                started_at: contractStartDate,
                ends_at: contractEndDate,
                next_debit_date: nextDebitDate,
                amount: price,
                subscription_status: subscriptionStatus,
                is_client_blocked: clientIsBlocked,
                payments: unparsedPayments,
                unpaid_counter: unpaidCounter,
                payment_type: paymentType,
                payment_status_info: paymentsStatusInfo,
                show_resend: showResend,
                paused,
                paused_from: pausedFrom,
                paused_to: pausedTo,
                updating_at: updatingAt,
                updating_subscription: updatingSubscription,
                mandate_first_payment: mandateFirstPayment,
                active,
                type,
            } = soldSubscription;

            const payments = unparsedPayments.map((payment) => {
                const {
                    id: paymentId,
                    start_date: paymentStartDate,
                    final_cost: paymentPrice,
                    paid: paymentStatus,
                    payment_status_info: paymentStatusInfo,
                    payment_info: paymentInfo,
                    status: paymentStatusTitle,
                    paused: paymentPaused,
                    is_manually_set: isManuallySet,
                    payment_system: paymentSystem,
                    pay_nl_order_id: payNlOrderId,
                    payment_hash: paymentHash,
                    pay_nl_payment_type: payNlPaymentType,
                    error_message: errorMessage,
                } = payment;

                return ({
                    paymentId,
                    paymentStartDate,
                    paymentPrice: formatPrice.toEuroWithComma({ amount: paymentPrice }),
                    paymentStatus,
                    paymentInfo,
                    paymentStatusInfo,
                    paymentStatusTitle,
                    paymentPaused,
                    isManuallySet,
                    paymentSystem,
                    payNlOrderId,
                    paymentHash,
                    payNlPaymentType,
                    errorMessage,
                });
            });

            return [...acc, {
                id,
                clientAge,
                clientId,
                clientEmail,
                clientName,
                clientLastName,
                subscriptionId,
                subscriptionTitle,
                contractStartDate,
                contractEndDate,
                nextDebitDate,
                price: formatPrice.toEuroWithComma({ amount: price }),
                subscriptionStatus,
                clientIsBlocked,
                unpaidCounter,
                payments,
                paymentType,
                paymentsStatusInfo,
                type,
                showResend,
                pause: {
                    status: paused,
                    from: pausedFrom,
                    to: pausedTo,
                    unlimited: !pausedTo,
                },
                updatingAt,
                updatingSubscription,
                active,
                mandateFirstPayment,
            }];
        }, []);
    }

    static createCancelSubscriptionPayload({ endDate, cancelBookings, notNotifyClient }) {
        const payload = new FormData();
        payload.append('endDate', endDate);

        if (cancelBookings) {
            payload.append('cancelBookings', '1');
        }

        if (notNotifyClient) {
            payload.append('notNotifyClient', '1');
        }

        return payload;
    }

    static parseSoldSubscriptionUsages(payload) {
        const { current_usages: usages } = payload;

        const parsedUsages = usages.reduce((acc, usage) => {
            const {
                id,
                start_date: startDate,
                end_date: endDate,
                max_usages: maxUsages,
                used,
                periodicity,
                subscription_group: subscriptionGroup,
            } = usage;

            const parsedSubscriptionGroup = {
                id: subscriptionGroup.id,
                maxUsages: subscriptionGroup.max_usages,
                periodicity: subscriptionGroup.periodicity,
                services: subscriptionGroup.services.reduce((acc, service) => (acc ? `${acc} + ${service.name}` : service.name), ''),
            };

            if (!acc[periodicity]) {
                acc[periodicity] = [];
            }

            acc[periodicity].push({
                id,
                startDate,
                endDate,
                maxUsages,
                used,
                periodicity,
                subscriptionGroup: parsedSubscriptionGroup,
            });

            return acc;
        }, {});

        return {
            usages: parsedUsages,
            nextMonthly: payload.next_monthly,
            nextWeekly: payload.next_weekly,
            prevMonthly: payload.prev_monthly,
            prevWeekly: payload.prev_weekly,
        };
    }

    static createSoldSubscriptionUsagesIntoFormData(usages) {
        return Object.values(usages).reduce((acc, usage) => {
            Object.entries(usage).forEach(([periodStart, services]) => {
                services.forEach((service) => {
                    const { id, periodicity } = service;

                    acc[`${periodicity}-${periodStart}-${id}`] = service;
                    acc[`${periodicity}-${periodStart}-${id}`].unlimitedUsages = service.maxUsages === -1;
                });
            });
            return acc;
        }, {});
    }

    static transformSubscriptionIntoDuplicate(data) {
        return {
            ...data,
            active: false,
            buyable: false,
            soldCounter: 0,
            subscriptionInfo: {
                ...data.subscriptionInfo,
                title: i18n.t('placeholders.duplicateTitle', { title: data.subscriptionInfo.title, interpolation: { escapeValue: false } }),
            },
        };
    }
}

export default SubscriptionDataTransformations;
