/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { ENVS } from './envs';

const { version } = require('../../package.json');

const RATE = ENVS.isDevelopment ? 1 : 0.1;

/** Init must be called before any other Sentry method
 * and only once in the application */
const init = () => {
    if (!ENVS.isLocal) {
        Sentry.init({
            dsn: ENVS.appSentryDsn,
            environment: ENVS.appEnvironment,
            integrations: [new BrowserTracing(), new Sentry.Replay()],
            release: version || '0.0.0',
            replaysOnErrorSampleRate: 1,
            replaysSessionSampleRate: RATE,
            tracesSampleRate: RATE,
            sampleRate: RATE,
            profilesSampleRate: RATE,
            beforeSend(event) {
                if (ENVS.isDevelopment) {
                    console.log('[Sentry event]:', event);
                }
                return event;
            },
        });

        window.onerror = (message, url, line, column, error) => {
            Sentry.captureException(error, {
                extra: {
                    message, url, line, column,
                },
            });
        };
    }
};

const setUser = ({ email }) => {
    if (!ENVS.isLocal) {
        Sentry.setUser({ email });
    }
};

const log = (...args) => {
    if (ENVS.isDevelopment) {
        console.log(...args);
    }
    if (!ENVS.isLocal) {
        Sentry.captureMessage(args.join(' '));
    }
};

const warn = (...args) => {
    if (ENVS.isDevelopment) {
        console.warn(...args);
    }
    if (!ENVS.isLocal) {
        Sentry.captureMessage(args.join(' '));
    }
};

const error = (...args) => {
    if (ENVS.isDevelopment) {
        console.error(...args);
    }
    if (!ENVS.isLocal) {
        Sentry.captureException(args.join(' '));
    }
};

const ignore = () => { };

export default {
    init,
    setUser,
    log,
    warn,
    error,
    ignore,
};
