import React from 'react';
import PropTypes from 'prop-types';
import { useMobile } from 'hooks/useMobile';
import { Row } from 'components/Common/Row';
import DefaultTabDropdown from './DefaultTabDropdown/DefaultTabDropdown';
import NotificationsBell from './NotificationsBell/NotificationsBell';
import MobileAgendaActions from './MobileAgendaActions/MobileAgendaActions';

function AgendaActions({
    isDataLoaded,
    avaliableDefaultTabs,
    onDefaultTabSelect,
    defaultTab,
    soundNotificationsEnabled,
    onSoundNotificationsToggle,
}) {
    const isMobile = useMobile();

    if (isMobile) {
        return (
            <MobileAgendaActions
                isDataLoaded={isDataLoaded}
                avaliableDefaultTabs={avaliableDefaultTabs}
                onDefaultTabSelect={onDefaultTabSelect}
                defaultTab={defaultTab}
                soundNotificationsEnabled={soundNotificationsEnabled}
                onSoundNotificationsToggle={onSoundNotificationsToggle}
            />
        );
    }

    // desktop view
    return (
        <Row gap={12}>
            <DefaultTabDropdown
                isDataLoaded={isDataLoaded}
                options={avaliableDefaultTabs}
                onSelect={onDefaultTabSelect}
                defaultTab={defaultTab}
            />
            <NotificationsBell
                enabled={soundNotificationsEnabled}
                onChange={onSoundNotificationsToggle}
                isDataLoaded={isDataLoaded}
            />
        </Row>
    );
}

AgendaActions.propTypes = {
    isDataLoaded: PropTypes.bool.isRequired,
    avaliableDefaultTabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    onDefaultTabSelect: PropTypes.func.isRequired,
    defaultTab: PropTypes.string.isRequired,
    soundNotificationsEnabled: PropTypes.bool.isRequired,
    onSoundNotificationsToggle: PropTypes.func.isRequired,
};

export default AgendaActions;
