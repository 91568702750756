import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import * as styles from './Label.module.scss';
import Typography from '../Typography/Typography';

const Label = (props) => (
    <Typography
        data-testid="label"
        {...props}
        className={classNames(
            styles.label,
            props.paddingBottom ? (props.tooltip ? styles.paddingBottom : styles.extraPaddingBottom) : null,
        )}
    />
);

Label.propTypes = { ...Typography.propTypes, paddingBottom: PropTypes.bool };

Label.defaultProps = { ...Typography.defaultProps, paddingBottom: false };

export default Label;
