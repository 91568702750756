import React from 'react';

export const Image = ({
    src, alt, ...props
}) => (
    <img
        src={src}
        alt={alt}
        loading="lazy"
        {...props}
    />
);
