import React, { useEffect, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Container, Dropdown } from 'react-bootstrap';
import {
    Switch, Link, Route, Redirect,
} from 'react-router-dom';
import { Footer, AddBookingModal } from 'components';

// Routes
import moment from 'moment';
import * as actions from 'store/actions';
import { permissionsResourceSelector } from 'store/selectors/permissions';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { Button } from 'components/Common/Button';
import momentAmsterdamTime from 'helpers/time/momentAmsterdamTime';
import { GROUPS_ADD_PAGE, GROUPS_MONTH_PAGE, GROUPS_ROOT_PAGE } from 'const/CLIENT_URL';
import {
    ArrowSmallLeft, ArrowSmallRight, FilterIcon, Plus,
} from 'components/Icon/Icon';
import Week from '../CalendarsGroup/Week/Week';
import Month from '../CalendarsGroup/Month/Month';

// Utils

const Calendars = ({
    viewType, date, week, month, year, setDate, setWeek, setMonth, setYear, setShowOptionsModal, setCalendarsDate,
}) => {
    const addBookingDetails = useSelector((state) => state.bookings.addBookingDetails);
    const { locale } = useSelector((state) => state.locales);
    const { banAdd } = useSelector(permissionsResourceSelector);
    const dispatch = useDispatch();

    const [showBookingModal, setShowBookingModal] = useState(false);

    useEffect(() => {
        if (!addBookingDetails) {
            return;
        }

        setShowBookingModal(true);
    }, [addBookingDetails]);

    useEffect(() => {
        setDate((date) => date.week(week).month(month).year(year));
    }, [week, month, year]);

    const getGroupSessionsHandler = async () => {
        if (viewType !== 'month') return;

        const date = moment().month(month).year(year);
        const from = date.clone().startOf('month').unix();
        const to = date.clone().endOf('month').unix();

        await dispatch(actions.getGroupSessions(from, to));
    };

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Container className="position-sticky d-flex bg-white py-3 px-0 w-100 mw-100" style={{ top: 0, zIndex: 900 }} fluid={viewType === 'planning'}>
                <div
                    className="d-flex justify-content-center justify-content-md-start align-items-center flex-grow-1"
                >
                    <Row gap={0}>
                        <IconButton
                            size={32}
                            color="outline"
                            group
                            onClick={() => {
                                const newDate = date
                                    .clone()
                                    .subtract(1, viewType === 'planning' ? 'week' : viewType);
                                setDate(newDate);
                                setMonth(newDate.month());
                                setWeek(newDate.isoWeek());
                                setYear(newDate.year());
                            }}
                        >
                            <ArrowSmallLeft />
                        </IconButton>

                        <IconButton
                            size={32}
                            color="outline"
                            group
                            onClick={() => {
                                const newDate = date
                                    .add(1, viewType === 'planning' ? 'week' : viewType);
                                setDate(newDate);
                                setWeek(newDate.isoWeek());
                                setMonth(newDate.month());
                                setYear(newDate.year());
                            }}
                        >
                            <ArrowSmallRight />
                        </IconButton>
                    </Row>

                    <h3 className="mb-0 font-weight-normal mx-2 mx-xl-4">
                        {viewType === 'day' && date.clone().locale(locale).format('D MMM YYYY')}
                        {(viewType === 'week' || viewType === 'planning') && `${date.clone().locale(locale).startOf('isoWeek').format('D')}${!date.clone().startOf('isoWeek').isSame(date.clone().endOf('isoWeek'), 'month') ? date.clone().locale(locale).startOf('isoWeek').format(' MMM') : ''} - ${date.clone().locale(locale).endOf('isoWeek').format('D MMM YYYY')}`}
                        {viewType === 'month' && date.clone().locale(locale).format('MMMM YYYY')}
                    </h3>

                    <Button
                        size="small"
                        color={
                            date.isSame(momentAmsterdamTime(), 'day') ? 'outline' : 'yellow'
                        }
                        onClick={() => {
                            const currentDate = momentAmsterdamTime();
                            setCalendarsDate(currentDate);
                            setDate(currentDate);
                            setWeek(currentDate.isoWeek());
                            setMonth(currentDate.month());
                            setYear(currentDate.year());
                        }}
                        data-testid="data-test-today-button"
                    >
                        {t('agendaRoute.today')}
                    </Button>
                </div>

                <div className="d-none d-md-flex justify-content-end flex-grow-1">
                    <Dropdown className="mx-2 mx-xl-3">
                        <Dropdown.Toggle variant="outline-light" className="text-dark px-3">
                            {t(`agendaRoute.${viewType}`)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={GROUPS_ROOT_PAGE}>{t('agendaRoute.week')}</Dropdown.Item>
                            <Dropdown.Item as={Link} to={GROUPS_MONTH_PAGE}>{t('agendaRoute.month')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {!banAdd && (
                        <Button
                            color="yellow"
                            href={GROUPS_ADD_PAGE}
                        >
                            {t('agendaRoute.addGroupSession')}
                        </Button>
                    )}
                </div>
            </Container>

            <Switch>
                <Route path={GROUPS_ROOT_PAGE} exact render={(props) => <Week {...props} globalDate={date} week={week} year={year} />} />
                <Route
                    path={GROUPS_MONTH_PAGE}
                    render={(props) => (
                        <Month
                            {...props}
                            week={week}
                            month={month}
                            year={year}
                            setMonth={setMonth}
                            setYear={setYear}
                            getGroupSessionsHandler={getGroupSessionsHandler}
                        />
                    )}
                />
                <Redirect to={GROUPS_ROOT_PAGE} />
            </Switch>

            <Footer className="d-flex d-lg-none">

                <IconButton
                    color="white"
                    size={60}
                    onClick={() => setShowOptionsModal(true)}
                >
                    <FilterIcon />
                </IconButton>

                <Dropdown className="flex-grow-1" drop="up">
                    <Dropdown.Toggle
                        variant="outline-light"
                        className="w-100 h-100 text-dark px-3 py-3 rounded-0 border-top-0 border-bottom-0"
                    >
                        {t(`agendaRoute.${viewType}`)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={GROUPS_ROOT_PAGE}>{t('agendaRoute.week')}</Dropdown.Item>
                        <Dropdown.Item as={Link} to={GROUPS_MONTH_PAGE}>{t('agendaRoute.month')}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown drop="up">
                    <Dropdown.Toggle variant="outline-light" className="border-0 text-dark px-3 px-sm-5 h-100 no-arrow" disabled={banAdd}>
                        <Plus />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <Button color="yellow" href={GROUPS_ADD_PAGE}>
                                {t('agendaRoute.addGroupSession')}
                            </Button>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Footer>

            {showBookingModal && (
                <AddBookingModal
                    show={showBookingModal}
                    onHide={() => setShowBookingModal(false)}
                    productType="group"
                    callback={getGroupSessionsHandler}
                />
            )}
        </React.Fragment>
    );
};

export default Calendars;
