export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_COMPANY_DETAILS_SUCCEEDED = 'GET_COMPANY_DETAILS_SUCCEEDED';
export const GET_COMPANY_DETAILS_FAILED = 'GET_COMPANY_DETAILS_FAILED';

export const GET_COMPANY_CLIENTS = 'GET_COMPANY_CLIENTS';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const GET_COMPANY_SETTINGS_TRIGGER = `${GET_COMPANY_SETTINGS}.TRIGGER`;
export const GET_COMPANY_SETTINGS_RESOLVED = `${GET_COMPANY_SETTINGS}.RESOLVED`;
export const GET_COMPANY_SETTINGS_REJECTED = `${GET_COMPANY_SETTINGS}.REJECTED`;

export const SET_COMPANY_SETTINGS_GENERAL = 'SET_COMPANY_SETTINGS_GENERAL';
export const SET_COMPANY_SETTINGS_GENERAL_TRIGGER = `${SET_COMPANY_SETTINGS_GENERAL}.TRIGGER`;
export const SET_COMPANY_SETTINGS_GENERAL_RESOLVED = `${SET_COMPANY_SETTINGS_GENERAL}.RESOLVED`;
export const SET_COMPANY_SETTINGS_GENERAL_REJECTED = `${SET_COMPANY_SETTINGS_GENERAL}.REJECTED`;

export const SET_COMPANY_SETTINGS_SHOP = 'SET_COMPANY_SETTINGS_SHOP';
export const SET_COMPANY_SETTINGS_SHOP_TRIGGER = `${SET_COMPANY_SETTINGS_SHOP}.TRIGGER`;
export const SET_COMPANY_SETTINGS_SHOP_RESOLVED = `${SET_COMPANY_SETTINGS_SHOP}.RESOLVED`;
export const SET_COMPANY_SETTINGS_SHOP_REJECTED = `${SET_COMPANY_SETTINGS_SHOP}.REJECTED`;
export const SET_COMPANY_SETTINGS_SHOP_ERRORS = `${SET_COMPANY_SETTINGS_SHOP}_ERRORS`;

export const SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS = 'SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS';
export const SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_TRIGGER = `${SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS}.TRIGGER`;
export const SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_RESOLVED = `${SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS}.RESOLVED`;
export const SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS_REJECTED = `${SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS}.REJECTED`;

export const SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY = 'SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY';
export const SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_TRIGGER = `${SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY}.TRIGGER`;
export const SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_RESOLVED = `${SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY}.RESOLVED`;
export const SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY_REJECTED = `${SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY}.REJECTED`;

export const GET_COMPANY_QR_CODE = 'GET_COMPANY_QR_CODE';
export const GET_COMPANY_QR_CODE_TRIGGER = `${GET_COMPANY_QR_CODE}.TRIGGER`;
export const GET_COMPANY_QR_CODE_RESOLVED = `${GET_COMPANY_QR_CODE}.RESOLVED`;
export const GET_COMPANY_QR_CODE_REJECTED = `${GET_COMPANY_QR_CODE}.REJECTED`;
