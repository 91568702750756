const CLIENTS_INVOICES = 'CLIENTS_INVOICES';
export const CLIENTS_INVOICES_SET = `${CLIENTS_INVOICES}:SET`;
const CLIENTS_INVOICES_PAGE = `${CLIENTS_INVOICES}:PAGE`;
export const CLIENTS_INVOICES_PAGE_SET = `${CLIENTS_INVOICES_PAGE}:SET`;
export const CLIENTS_INVOICES_PAGE_SET_FIRST = `${CLIENTS_INVOICES_PAGE}:FIRST`;
export const CLIENTS_INVOICES_PAGE_SET_LAST = `${CLIENTS_INVOICES_PAGE}:LAST`;
export const CLIENTS_INVOICES_PAGE_SET_PREV = `${CLIENTS_INVOICES_PAGE}:PREV`;
export const CLIENTS_INVOICES_PAGE_SET_NEXT = `${CLIENTS_INVOICES_PAGE}:NEXT`;
const CLIENTS_INVOICES_SEARCH = `${CLIENTS_INVOICES}:SEARCH`;
export const CLIENTS_INVOICES_SEARCH_SET = `${CLIENTS_INVOICES_SEARCH}:SET`;
const CLIENTS_INVOICES_LOADING = `${CLIENTS_INVOICES}:LOADING`;
export const CLIENTS_INVOICES_LOADING_SET = `${CLIENTS_INVOICES_LOADING}:SET`;
const CLIENTS_INVOICES_FILTERS = `${CLIENTS_INVOICES}:FILTERS`;
export const CLIENTS_INVOICES_FILTERS_SET = `${CLIENTS_INVOICES_FILTERS}:SET`;
export const CLIENTS_INVOICES_FILTERS_CLEAR = `${CLIENTS_INVOICES_FILTERS}:CLEAR`;
const CLIENTS_INVOICES_ITEM = `${CLIENTS_INVOICES}:ITEM`;
export const CLIENTS_INVOICES_ITEM_CHECK_CREDIT_STATUS = `${CLIENTS_INVOICES_ITEM}:CHECK_CREDIT_STATUS`;
export const CLIENTS_INVOICES_ITEM_CREATE_CREDIT = `${CLIENTS_INVOICES_ITEM}:CREATE_CREDIT`;

export const CLIENTS_INVOICES_GET = 'CLIENTS_INVOICES_GET';
export const CLIENTS_INVOICES_GET_TRIGGER = `${CLIENTS_INVOICES_GET}.TRIGGER`;
export const CLIENTS_INVOICES_GET_RESOLVED = `${CLIENTS_INVOICES_GET}.RESOLVED`;
export const CLIENTS_INVOICES_GET_REJECTED = `${CLIENTS_INVOICES_GET}.REJECTED`;
