import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'reviews.list.table.header.fields';
function ClientReviewsTableHeader() {
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.shop`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.service`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.resource`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.date`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.duration`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.review`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.comment`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
}

ClientReviewsTableHeader.propTypes = {};

ClientReviewsTableHeader.defaultProps = {};

export default ClientReviewsTableHeader;
