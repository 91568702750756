import React, { useRef } from 'react';

// Components
import {
    Form, Modal,
} from 'react-bootstrap';

// Hooks
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import * as yup from 'yup';
import { Button } from 'components/Common/Button';
import NumberInput from 'components/Common/NumberInput';
import * as actions from '../../store/actions';

const OverrideGroupModal = ({
    show, onHide, id, timestamp, registrations,
}) => {
    const { t } = useTranslation();

    const schema = useRef(
        yup.object({
            maxPeople: yup
                .number()
                .positive(
                    t('validationErrors.mustBePositiveNumber', {
                        name: t('addEventRoute.maxRegistrations'),
                    }),
                )
                .required(
                    t('validationErrors.cannotBeEmpty', {
                        name: t('addEventRoute.maxRegistrations'),
                    }),
                ),
        }),
    ).current;

    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const {
        handleSubmit,
        setFieldValue,
        values,
        isSubmitting,
        touched,
        errors,
    } = useFormik({
        validationSchema: schema,
        onSubmit: async ({ maxPeople }, { setSubmitting, setErrors }) => {
            try {
                if (registrations === maxPeople) return onHide();
                await dispatch(
                    actions.overrideGroupSession({ id, timestamp, maxPeople }),
                );
                addToast(t('groupDetailsModal.overrideGroupSessionSuccess'), {
                    appearance: 'success',
                });
                onHide();
            } catch ({ message, errors }) {
                if (message) {
                    addToast(message, {
                        appearance: 'error',
                    });
                } else {
                    setErrors(errors || {});
                }

                setSubmitting(false);
            }
        },
        initialValues: {
            maxPeople: registrations ?? 0,
        },
        enableReinitialize: true,
    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            className="d-flex flex-column "
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="mb-0 font-weight-600">
                    {t('groupDetailsModal.override')}
                    {' '}
                    {t('groupDetailsModal.groupSession')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <label className="form-label">
                        {t('addEventRoute.maxRegistrations')}
                    </label>
                    <NumberInput
                        name="maxPeople"
                        value={values.maxPeople}
                        onChange={(value) => setFieldValue('maxPeople', value)}
                        isInvalid={touched.maxPeople && !!errors.maxPeople}
                        max={9999}
                    />

                    <Form.Control.Feedback
                        className={!!touched.maxPeople && !!errors.maxPeople && 'd-block'}
                        type="invalid"
                    >
                        {errors.maxPeople}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    type="button"
                    color="outline"
                    onClick={onHide}
                >
                    {t('groupDetailsModal.cancel')}
                </Button>

                <Button
                    color="yellow"
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                >
                    {(
                        t('groupDetailsModal.save')
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OverrideGroupModal;
