import React, {
    useState, useEffect, useMemo, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Form, Row as BootstrapRow, Col, InputGroup,
} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import classNames from 'classnames';

import { generateRandomString } from 'helpers/string/generateRandomString';

import { InfoTooltip } from 'components/Common/InfoTooltip';
import {
    ConfirmationModal,
    Footer,
    DateInput,
    PhoneInput,
    FileUpload,
} from 'components';
import Map from 'components/Map/Map';
import GroupSchedule from 'components/GroupSchedule/GroupSchedule';

import { CalendarIcon, CopyIcon } from 'components/Icon/Icon';

import { permissionsResourceSelector } from 'store/selectors/permissions';
import * as actions from 'store/actions';
import * as SERVICES_ACTIONS from 'store/actions/services';
import * as SERVICES_SELECTORS from 'store/selectors/services';
import { Button } from 'components/Common/Button';
import Container from 'components/Layout/Container/Container';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { useMobile } from 'hooks/useMobile';
import { BackButton } from 'components/Common/BackButton';
import { Tooltip } from 'components/Common/Tooltip';
import { IconButton } from 'components/Common/IconButton';
import { GROUPS_ADD_PAGE, GROUPS_ROOT_PAGE } from 'const/CLIENT_URL';
import { Content } from 'components/Common/Content';
import { Row } from 'components/Common/Row';
import { CheckBox } from 'components/Common/CheckBox';
import { Label } from 'components/Common/Typography/Label';
import NumberInput from 'components/Common/NumberInput';
import { Column } from 'components/Common/Column';
import { companyWaitlistEnabled } from 'store/selectors/company';

const MAXIMUM_DAYS = 1000;

const Edit = () => {
    const { t } = useTranslation();
    const isMobile = useMobile();
    const history = useHistory();
    const waitlistEnabled = useSelector(companyWaitlistEnabled);

    /* istanbul ignore next */
    const schema = useRef(
        yup.object({
            image: yup
                .mixed()
                .required(t('addGroupRoute.pleaseSelectGroupSessionImage')),
            name: yup
                .string()
                .required(
                    t('validationErrors.cannotBeEmpty', { name: t('addGroupRoute.name') }),
                ),
            description: yup
                .string()
                .required(
                    t('validationErrors.cannotBeEmpty', {
                        name: t('addGroupRoute.description'),
                    }),
                ),
            enableWaitlist: yup.boolean().required(),
            sessions: yup
                .object(
                    _.times(7).reduce((schedule, i) => ({
                        ...schedule,
                        [moment().isoWeekday(i).format('dddd')]: yup.array(
                            yup.object({
                                id: yup.number().notRequired(),
                                from: yup
                                    .mixed()
                                    .required(
                                        t('validationErrors.cannotBeEmpty', {
                                            name: t('groupSchedule.startTime'),
                                        }),
                                    ),
                                maxRegistrations: yup
                                    .number()
                                    .required(
                                        t('validationErrors.cannotBeEmpty', {
                                            name: t('groupSchedule.maxRegistrations'),
                                        }),
                                    ),
                                showNumberOfRegistrations: yup.boolean().required(),
                                service: yup
                                    .object({
                                        id: yup
                                            .number()
                                            .required(
                                                t('validationErrors.cannotBeEmpty', {
                                                    name: t('groupSchedule.service'),
                                                }),
                                            ),
                                    })
                                    .typeError(
                                        t('validationErrors.cannotBeEmpty', {
                                            name: t('groupSchedule.service'),
                                        }),
                                    ),
                                employee: yup
                                    .object({
                                        id: yup.number().nullable(),
                                    })
                                    .nullable(),
                            }),
                        ),
                    })),
                )
                .test(
                    'is-session-defined',
                    t('addGroupRoute.pleaseDefineAtLeastOneGroupSession'),
                    function isSessionDefined(sessions) {
                        const { path, message, createError } = this;
                        if (Object.values(sessions).find((day) => day.length !== 0)) {
                            return true;
                        }
                        return createError({ path, message });
                    },
                )
                .required(),
            contactNumber: yup
                .string()
                .matches(
                    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    t('validationErrors.invalidPhoneNumber'),
                )
                .required(
                    t('validationErrors.cannotBeEmpty', {
                        name: t('addGroupRoute.contactNumber'),
                    }),
                ),
            address: yup
                .object({
                    city: yup
                        .string()
                        .required(
                            t('validationErrors.cannotBeEmpty', {
                                name: t('addGroupRoute.city'),
                            }),
                        ),
                    street: yup
                        .string()
                        .required(
                            t('validationErrors.cannotBeEmpty', {
                                name: t('addGroupRoute.streetAddress'),
                            }),
                        ),
                    zip: yup
                        .string()
                        .required(
                            t('validationErrors.cannotBeEmpty', {
                                name: t('addGroupRoute.zipCode'),
                            }),
                        ),
                    country: yup
                        .string()
                        .required(
                            t('validationErrors.cannotBeEmpty', {
                                name: t('addGroupRoute.country'),
                            }),
                        ),
                })
                .required(),
            time: yup.object({
                from: yup.mixed().when('to', (to, schema) => schema.test({
                    name: generateRandomString(),
                    test: (from) => !to || !from || from.isSameOrBefore(to, 'date'),
                    message: t('validationErrors.startBeforeEnd'),
                })),
                to: yup.mixed(),
            }),
            futureBooking: yup
                .number()
                .positive(t('validationErrors.mustBePositiveNumber', { name: t('addGroupRoute.days') }))
                .max(MAXIMUM_DAYS, t('validationErrors.mustBeNoLongerThen', { field: 'Future bookings', days: MAXIMUM_DAYS })),
        }),
    ).current;

    const params = useParams();

    const { loaded: isShopLoaded, id: shopID } = useSelector(
        (state) => state.shop,
    );

    const shop = useSelector((state) => state.shop);
    const { loaded: companyServicesLoading, items: companyServices } = useSelector(SERVICES_SELECTORS.companyServicesSelector);
    const avaliableServices = useMemo(() => companyServices.filter(({ archived }) => !archived), [companyServices]);
    const { loaded: areGroupsLoaded, groups } = useSelector(
        (state) => state.groups,
    );
    const isDuplicateMode = Boolean(history.location.state?.groupId);
    const groupId = isDuplicateMode ? history.location.state.groupId : params.id;
    const group = groups?.find((group) => parseInt(groupId) === group.id);
    const { banEdit, banDelete, banAdd } = useSelector(permissionsResourceSelector);
    const isEditMode = Boolean(params.id);

    const dispatch = useDispatch();

    const initialValues = useMemo(() => ({
        image: group?.image ?? null,
        name: isDuplicateMode ? t('placeholders.duplicateTitle', { title: group?.name, interpolation: { escapeValue: false } }) : group?.name ?? '',
        description: group?.description ?? '',
        sessions: _.times(7, (i) => moment().isoWeekday(i + 1).format('dddd')).reduce((breaks, day) => ({
            ...breaks,
            [day]: group?.sessions
                .filter(({ time: { from } }) => moment(from).format('dddd') === day)
                .map(({
                    id, time: { from }, places: { total: maxRegistrations, show: showNumberOfRegistrations }, service, product: employee,
                }) => ({
                    id,
                    from: moment(from),
                    maxRegistrations,
                    showNumberOfRegistrations,
                    service,
                    employee,
                })) ?? [],
        }), {}),
        contactNumber: group?.sessions?.[0].contactNumber ?? shop.contactNumber ?? '',
        address: {
            street: group?.street ?? shop.address.street ?? '',
            zip: group?.zip ?? shop.address.zip ?? '',
            city: group?.city ?? shop.address.city ?? '',
            country: group?.country ?? shop.address.country ?? '',
            latitude: group?.latitude ?? shop.address.latitude ?? '',
            longitude: group?.longitude ?? shop.address.longitude ?? '',
        },
        time: {
            from: group?.startDate ? moment(group?.startDate) : null,
            to: group?.endDate ? moment(group?.endDate) : null,
        },
        futureBooking: group?.futureBooking
            ? moment(group?.futureBooking).startOf('day')
                .diff(moment().startOf('day'), 'days')
            : '',
        allowLateBooking: group?.allowLateBooking ?? false,
        enableWaitlist: group?.enableWaitlist ?? waitlistEnabled,
    }), [shop, isDuplicateMode, group, waitlistEnabled]);

    const { addToast } = useToasts();

    const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setSubmitting, setErrors }) => {
            const group = isEditMode ? {
                ...values,
                id: groupId,
            } : values;
            const action = isEditMode ? actions.editGroup : actions.addGroup;
            dispatch(action({ group }))
                .then(() => {
                    const successText = isEditMode ? t('editGroupRoute.groupSessionEditedSuccessfully') : t('addGroupRoute.groupSessionAddedSuccessfully');
                    addToast(successText, {
                        appearance: 'success',
                    });
                    history.push(GROUPS_ROOT_PAGE);
                })
                .catch(({ message, errors }) => {
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                        });
                    } else {
                        setErrors(errors || {});
                    }

                    setSubmitting(false);
                });
        },
        enableReinitialize: true,
    });

    const [center, setCenter] = useState(null);
    const [isGettingAddress, setGettingAddress] = useState(false);
    const [isGettingCoordinates, setGettingCoordinates] = useState(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeletingSession, setIsDeletingSession] = useState(false);

    useEffect(() => {
        if (!shopID || !areGroupsLoaded || !isEditMode) {
            return;
        }

        dispatch(actions.getProductById({ productId: groupId, type: 'group' }));
    }, [shopID, areGroupsLoaded, isEditMode]);

    useEffect(() => {
        if (companyServicesLoading || !shopID || !isShopLoaded) {
            return;
        }

        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [shopID, isShopLoaded]);

    useEffect(() => {
        const latitude = isEditMode ? group?.latitude : shop?.address.latitude;
        const longitude = isEditMode ? group?.longitude : shop?.address.longitude;
        if (!latitude || !longitude) {
            return;
        }

        setCenter({
            latitude,
            longitude,
        });
    }, [group, shop]);

    const deleteHandler = async () => {
        if (banDelete || isDeletingSession) {
            return;
        }

        setIsDeletingSession(true);
        try {
            await dispatch(actions.deleteGroupSession({ id: groupId }));
            addToast(t('groupDetailsModal.deleteGroupSessionSuccess'), {
                appearance: 'success',
            });
            setDeleteModalVisible(false);
            history.push(GROUPS_ROOT_PAGE);
        } catch ({ message }) {
            if (message) {
                addToast(message, {
                    appearance: 'error',
                });
            }
        } finally {
            setIsDeletingSession(false);
        }
    };

    const handleGroupScheduleChange = ({
        type,
        index,
        day,
        from,
        maxRegistrations,
        showNumberOfRegistrations,
        service,
        employee,
        days,
        duplicateDay,
        duplicateSession,
    }) => {
        switch (type) {
        case 'ADD':
            return setFieldValue('sessions', {
                ...values.sessions,
                [day]: [
                    ...values.sessions[day],
                    {
                        from: moment(),
                        maxRegistrations: 10,
                        showNumberOfRegistrations: false,
                        service: avaliableServices[0] ?? null,
                        employee: null,
                    },
                ],
            });
        case 'UPDATE':
            return setFieldValue(
                'sessions',
                Object.keys(values.sessions).reduce((sessions, key) => {
                    if (key === day) {
                        return {
                            ...sessions,
                            [key]: values.sessions[key].map((schedule, i) => {
                                if (i === index) {
                                    return {
                                        id: schedule.id,
                                        from: from || schedule.from,
                                        maxRegistrations:
                        maxRegistrations !== undefined
                            ? maxRegistrations
                            : schedule.maxRegistrations,
                                        showNumberOfRegistrations:
                        showNumberOfRegistrations !== undefined
                            ? showNumberOfRegistrations
                            : schedule.showNumberOfRegistrations,
                                        service: service || schedule.service,
                                        employee:
                        employee !== undefined ? employee : schedule.employee,
                                    };
                                }

                                return schedule;
                            }),
                        };
                    }

                    return {
                        ...sessions,
                        [key]: values.sessions[key],
                    };
                }, {}),
            );
        case 'REMOVE':
            return setFieldValue(
                'sessions',
                Object.keys(values.sessions).reduce((sessions, key) => {
                    if (key === day) {
                        return {
                            ...values.sessions,
                            [key]: values.sessions[key].filter((_, i) => i !== index),
                        };
                    }

                    return {
                        ...sessions,
                        [key]: values.sessions[key],
                    };
                }, {}),
            );
        case 'DUPLICATE':
            return setFieldValue('sessions', {
                ...values.sessions,
                ...days.reduce((acc, day) => {
                    const session = values.sessions[duplicateDay][duplicateSession];
                    return {
                        ...acc,
                        [day]: [
                            ...values.sessions[day],
                            {
                                ...session,
                                id: undefined,
                            },
                        ],
                    };
                }, {}),
            });
        }
    };

    const showDeleteResourceHeaderButton = !banDelete && !isMobile && isEditMode;

    const handleDuplicate = () => {
        history.push(GROUPS_ADD_PAGE, { groupId });
    };

    if (isEditMode && banEdit || !isEditMode && banAdd) {
        return <Redirect to={GROUPS_ROOT_PAGE} />;
    }

    const loading = (isEditMode || isDuplicateMode) && !group;

    return (
        <React.Fragment>
            <TextSubHeader
                text={isEditMode ? t('editGroupRoute.editGroupSession') : t('addGroupRoute.addNewGroupSession')}
                before={<BackButton href={GROUPS_ROOT_PAGE} />}
                rightActions={[
                    isEditMode && !isMobile && (
                        <Button
                            onClick={handleDuplicate}
                            color="outline"
                            before={<CopyIcon />}
                        >
                            {t('placeholders.duplicate')}
                        </Button>
                    ),
                    showDeleteResourceHeaderButton && (
                        <Button
                            onClick={() => setDeleteModalVisible(true)}
                            loading={isDeletingSession}
                            color="red"
                            disabled={banDelete}
                        >
                            {t('agendaRoute.deleteResource')}
                        </Button>
                    ),
                ]}
            />
            <Container>
                <Content loading={loading}>
                    <Form noValidate onSubmit={handleSubmit} className="w-100">
                        <BootstrapRow>
                            <Col xs={12} md={6} lg={6} xl={3}>
                                <FileUpload
                                    label={
                                        (touched.image && errors.image)
                  || t('editGroupRoute.changeGroupSessionImage')
                                    }
                                    isInvalid={!!touched.image && !!errors.image}
                                    defaultValue={values.image}
                                    onChange={(file) => setFieldValue('image', file)}
                                    square
                                    loading={loading}
                                />
                            </Col>

                            <Col xs={12} md={6} lg={6} xl={9} className="mt-4 mt-lg-0">
                                <Form.Group>
                                    <Form.Label>{t('addGroupRoute.name')}</Form.Label>
                                    <Form.Control
                                        id="name"
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!touched.name && !!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t('addGroupRoute.description')}</Form.Label>
                                    <Form.Control
                                        id="description"
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={!!touched.description && !!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Column stretched gap={16}>
                                        <CheckBox
                                            id="allowLateBooking"
                                            name="allowLateBooking"
                                            tooltip={t('addGroupRoute.allowLateBooking.tooltip')}
                                            label={t('addGroupRoute.allowLateBooking.label')}
                                            checked={values.allowLateBooking}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback
                                            className={
                                                !!touched.allowLateBooking
                    && !!errors.allowLateBooking
                    && 'd-block'
                                            }
                                            type="invalid"
                                        >
                                            {errors.allowLateBooking}
                                        </Form.Control.Feedback>
                                        {waitlistEnabled && (
                                            <React.Fragment>
                                                <CheckBox
                                                    id="enableWaitlist"
                                                    name="enableWaitlist"
                                                    label={t('addGroupRoute.enableWaitlist.label')}
                                                    tooltip={t('addGroupRoute.enableWaitlist.tooltip')}
                                                    checked={values.enableWaitlist}
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback
                                                    className={
                                                        !!touched.enableWaitlist
                    && !!errors.enableWaitlist
                    && 'd-block'
                                                    }
                                                    type="invalid"
                                                >
                                                    {errors.enableWaitlist}
                                                </Form.Control.Feedback>
                                            </React.Fragment>
                                        )}
                                    </Column>
                                </Form.Group>

                                <BootstrapRow>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                {t('addGroupRoute.startDate.label')}
                                                <InfoTooltip
                                                    text={t('addGroupRoute.startDate.tooltip')}
                                                    placement="bottom"
                                                />
                                            </Form.Label>

                                            <InputGroup>
                                                <DateInput
                                                    name="time.from"
                                                    value={values.time.from}
                                                    placeholder={t('addGroupRoute.selectDate')}
                                                    onChange={(time) => setFieldValue('time.from', time)}
                                                    isInvalid={touched.time?.from && !!errors.time?.from}
                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text
                                                        className={classNames({
                                                            'border-danger text-danger':
                                touched.time?.from && errors.time?.from,
                                                            'text-muted':
                                !touched.time?.from && !errors.time?.from,
                                                        })}
                                                    >
                                                        <CalendarIcon width={18} />
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                            <Form.Control.Feedback
                                                className={
                                                    touched.time?.from && errors.time?.from && 'd-block'
                                                }
                                                type="invalid"
                                            >
                                                {errors.time?.from}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                {t('addGroupRoute.endDate.label')}
                                                <InfoTooltip
                                                    text={t('addGroupRoute.endDate.tooltip')}
                                                    placement="bottom"
                                                />
                                            </Form.Label>
                                            <InputGroup>
                                                <DateInput
                                                    name="time.to"
                                                    value={values.time.to}
                                                    placeholder={t('addGroupRoute.selectDate')}
                                                    onChange={(time) => setFieldValue('time.to', time)}
                                                    isInvalid={touched.time?.to && !!errors.time?.from}
                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text
                                                        className={classNames({
                                                            'border-danger text-danger':
                                touched.time?.to && errors.time?.from,
                                                            'text-muted':
                                !touched.time?.to && !errors.time?.from,
                                                        })}
                                                    >
                                                        <CalendarIcon width={18} />
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                            <Form.Control.Feedback
                                                className={
                                                    touched.time?.to && errors.time?.from && 'd-block'
                                                }
                                                type="invalid"
                                            >
                                                {t('validationErrors.endAfterStart')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </BootstrapRow>
                                <Form.Group>
                                    <Label paddingBottom tooltip={t('addGroupRoute.howLongBookingsCanBeMade.tooltip')}>
                                        {t('addGroupRoute.howLongBookingsCanBeMade.label')}
                                    </Label>

                                    <NumberInput
                                        name="futureBooking"
                                        value={values.futureBooking}
                                        onChange={(value) => setFieldValue('futureBooking', value)}
                                        isInvalid={!!touched.futureBooking && !!errors.futureBooking}
                                        prepend={t('addGroupRoute.days')}
                                        min={0}
                                        max={MAXIMUM_DAYS}
                                        step={1}
                                    />

                                    <Form.Control.Feedback
                                        className={
                                            !!touched.futureBooking
                    && !!errors.futureBooking
                    && 'd-block'
                                        }
                                        type="invalid"
                                    >
                                        {errors.futureBooking}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </BootstrapRow>

                        <h3 className="mb-4 mt-4 font-weight-normal">
                            {t('addGroupRoute.groupSessions')}
                        </h3>
                        <GroupSchedule
                            value={values.sessions}
                            services={avaliableServices}
                            onChange={handleGroupScheduleChange}
                            errors={!!touched.sessions && errors.sessions}
                            loading={loading}
                            productId={groupId}
                            shopId={shopID}
                        />
                        <Form.Control.Feedback
                            className={
                                !!touched.sessions
              && !!errors.sessions
              && typeof errors.sessions === 'string'
              && 'd-block'
                            }
                            type="invalid"
                        >
                            {typeof errors.sessions === 'string' && errors.sessions}
                        </Form.Control.Feedback>

                        <h3 className="my-4 font-weight-normal">
                            {t('addGroupRoute.address')}
                        </h3>
                        <BootstrapRow>
                            <Col xs={12} lg={8} className="d-flex flex-column">

                                {center ? (
                                    <Map
                                        name="map"
                                        className="h-100"
                                        center={center}
                                        markerPosition={{
                                            latitude: values.address.latitude,
                                            longitude: values.address.longitude,
                                        }}
                                        onMarkerPositionChange={({ latitude, longitude }) => {
                                            setCenter({ latitude, longitude });
                                            setFieldValue('address', {
                                                ...values.address,
                                                latitude,
                                                longitude,
                                            });
                                        }}
                                    />
                                ) : <div className="h-100" style={{ minHeight: 400 }} />}
                                <div>
                                    <Button
                                        type="button"
                                        name="fillAddress"
                                        className="mt-4 px-3"
                                        color="outline"
                                        size="small"
                                        onClick={() => {
                                            setGettingAddress(true);
                                            dispatch(
                                                actions.getAddressByCoordinates({
                                                    latitude: values.address.latitude,
                                                    longitude: values.address.longitude,
                                                }),
                                            )
                                                .then(({
                                                    street, zip, city, country,
                                                }) => setFieldValue('address', {
                                                    ...values.address,
                                                    street,
                                                    zip,
                                                    city,
                                                    country,
                                                }))
                                                .finally(() => setGettingAddress(false));
                                        }}
                                        loading={isGettingAddress}
                                    >
                                        {t('addGroupRoute.fillAddressByMarkerPosition')}
                                    </Button>
                                </div>
                            </Col>

                            <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                                <Form.Group className="mb-3">
                                    <label className="form-label">
                                        {t('addGroupRoute.contactNumber')}
                                    </label>
                                    <PhoneInput
                                        name="contactNumber"
                                        value={values.contactNumber}
                                        onChange={(contactNumber) => setFieldValue('contactNumber', contactNumber)}
                                        isInvalid={
                                            !!touched.contactNumber && !!errors.contactNumber
                                        }
                                    />
                                    <Form.Control.Feedback
                                        className={
                                            !!touched.contactNumber
                    && !!errors.contactNumber
                    && 'd-block'
                                        }
                                        type="invalid"
                                    >
                                        {errors.contactNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t('addGroupRoute.city')}</Form.Label>
                                    <Form.Control
                                        name="address.city"
                                        type="text"
                                        placeholder={t('addGroupRoute.enterCity')}
                                        value={values.address.city}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!touched.address
                      && !!touched.address.city
                      && !!errors.address
                      && !!errors.address.city
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address && errors.address.city}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t('addGroupRoute.streetAddress')}</Form.Label>
                                    <Form.Control
                                        name="address.street"
                                        type="text"
                                        placeholder={t('addGroupRoute.enterStreetAddress')}
                                        value={values.address.street}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!touched.address
                      && !!touched.address.street
                      && !!errors.address
                      && !!errors.address.street
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address && errors.address.street}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t('addGroupRoute.zipCode')}</Form.Label>
                                    <Form.Control
                                        name="address.zip"
                                        type="text"
                                        placeholder={t('addGroupRoute.enterZipCode')}
                                        value={values.address.zip}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!touched.address
                      && !!touched.address.zip
                      && !!errors.address
                      && !!errors.address.zip
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address && errors.address.zip}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t('addGroupRoute.country')}</Form.Label>
                                    <Form.Control
                                        name="address.country"
                                        type="text"
                                        placeholder={t('addGroupRoute.enterCountry')}
                                        value={values.address.country}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!touched.address
                      && !!touched.address.country
                      && !!errors.address
                      && !!errors.address.country
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address && errors.address.country}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button
                                    type="button"
                                    name="placeMarker"
                                    onClick={() => {
                                        setGettingCoordinates(true);
                                        dispatch(
                                            actions.getCoordinatesByAddress({
                                                street: values.address.street,
                                                zip: values.address.zip,
                                                city: values.address.city,
                                                country: values.address.country,
                                            }),
                                        )
                                            .then(({ latitude, longitude }) => {
                                                setFieldValue('address', {
                                                    ...values.address,
                                                    latitude,
                                                    longitude,
                                                });
                                                setCenter({
                                                    latitude,
                                                    longitude,
                                                });
                                            })
                                            .finally(() => setGettingCoordinates(false));
                                    }}
                                    loading={isGettingCoordinates}
                                >
                                    {t('addGroupRoute.placeMarkerOnMap')}
                                </Button>
                            </Col>
                        </BootstrapRow>

                        <div className="d-flex align-items-center border-top pt-3 mt-3">
                            <Button type="button" color="outline" href={GROUPS_ROOT_PAGE}>
                                {t('addGroupRoute.cancel')}
                            </Button>

                            <div className="d-flex justify-content-end flex-grow-1">
                                <Button type="submit" loading={isSubmitting}>
                                    {isEditMode ? t('editGroupRoute.save') : t('addGroupRoute.addGroupSession')}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Content>
            </Container>

            <Footer className="d-flex d-lg-none justify-content-end align-items-center">
                <Row gap={8} spacing={8}>
                    {isEditMode && isMobile && (
                        <Tooltip
                            forButton
                            tooltip={t('placeholders.duplicate')}
                            placement="bottom"
                        >
                            <IconButton onClick={handleDuplicate} color="gray" size={40}>
                                <CopyIcon height={24} width={24} />
                            </IconButton>
                        </Tooltip>
                    )}

                    {!banDelete && (
                        <Button
                            onClick={() => setDeleteModalVisible(true)}
                            disabled={banDelete}
                            loading={isDeletingSession}
                            color="red"
                        >
                            {t('agendaRoute.deleteResource')}
                        </Button>
                    )}
                </Row>
            </Footer>

            <ConfirmationModal
                isShow={deleteModalVisible}
                hide={() => setDeleteModalVisible(false)}
                loading={isDeletingSession}
                confirmAction={deleteHandler}
                titleText={`${t('groupDetailsModal.delete')} ${t(
                    'groupDetailsModal.groupSession',
                )}`}
                bodyText={t('groupDetailsModal.areYouSureYouWantToDeleteSession')}
                deleteText={t('groupDetailsModal.delete')}
            />
        </React.Fragment>
    );
};

export default Edit;
