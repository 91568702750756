import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDialog } from 'hooks/useDialog';

import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';

import { MessagesTableHeader } from 'components/Marketing/Messages/MessagesTableHeader';
import { MessagesTableBodyRow } from 'components/Marketing/Messages/MessagesTableBodyRow';
import MessageDetailsDialog from 'components/Marketing/Messages/MessageDetailsDialog';

import { MESSAGES_ADD_PAGE, MESSAGES_EDIT_ID_PAGE } from 'const/CLIENT_URL';
import { useMobile } from 'hooks/useMobile';
import SearchInput2 from 'components/Common/SearchInput2';
import IconButton from 'components/Common/IconButton/IconButton';
import { Footer } from 'components';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Common/Button';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import SearchInput from 'components/SearchInput/SearchInput';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { Plus } from 'components/Icon/Icon';
import * as MESSAGES_ACTIONS from '../../../../../../store/actions/messages';
import * as MESSAGES_SELECTORS from '../../../../../../store/selectors/messages';
import { getCompanyClients, setMessagesSearch } from '../../../../../../store/actions';

import * as styles from './page.module.scss';

const COLUMNS_COUNT = 7;
const T_PREFIX = 'list.header';

const Messages = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const detailsDialog = useDialog(false);

    const {
        page,
        items: messages,
        showAll,
        isLoading,
        itemsCount,
        pagesCount,
        itemsPerPage,
        search,
        sortOptions,
    } = useSelector(MESSAGES_SELECTORS.messagesListSelector);

    const [companyClients, setCompanyClients] = useState([]);

    const isMobile = useMobile();
    const { t } = useTranslation(LOCALE_NAMESPACE.MESSAGES);

    const onSearchHandler = useCallback((search) => {
        dispatch(setMessagesSearch({ search }));
    }, []);

    const handlePrevPage = useCallback(() => {
        dispatch(MESSAGES_ACTIONS.setMessagesPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(MESSAGES_ACTIONS.setMessagesPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(MESSAGES_ACTIONS.setMessagesPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(MESSAGES_ACTIONS.setMessagesPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(MESSAGES_ACTIONS.showAllMessages());
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(MESSAGES_ACTIONS.setMessagesPageFirst());
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(MESSAGES_ACTIONS.setMessagesPage({ page: nextPage }));
    }, [dispatch]);

    const handleEdit = useCallback(({ messageId }) => {
        history.push(MESSAGES_EDIT_ID_PAGE({ messageId }));
    }, [history.push]);

    const handleClone = useCallback(({ messageId }) => {
        history.push(MESSAGES_ADD_PAGE, { messageId });
    }, [history.push]);

    const handleShowDetails = useCallback(({ message }) => {
        detailsDialog.onSetShow(message);
    }, [detailsDialog.onSetShow]);

    const handleHideDetails = useCallback(() => {
        detailsDialog.onSetShow(null);
    }, [detailsDialog.onSetShow]);

    useEffect(() => {
        dispatch(MESSAGES_ACTIONS.getMessages());
    }, [
        dispatch,
        page,
        search,
        sortOptions.sortBy,
        sortOptions.orderBy,
    ]);

    useEffect(() => () => dispatch(MESSAGES_ACTIONS.clearMessages()), []);

    useEffect(() => {
        dispatch(getCompanyClients())
            .then((clients) => setCompanyClients(clients));
    }, []);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t(`${T_PREFIX}.title`)}
                after={(
                    !isMobile && (
                        <SearchInput2
                            onSearch={onSearchHandler}
                            searchValue={search}
                        />
                    )
                )}
                rightActions={(
                    !isMobile && (
                        <Button
                            href={MESSAGES_ADD_PAGE}
                        >
                            {t(`${T_PREFIX}.actions.addMessage.label`)}
                        </Button>
                    )
                )}
            />
            <Container>
                <Content loading={isLoading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <MessagesTableHeader />
                        </TableHeader>
                        <TableBody>
                            {messages?.map((message) => (
                                <MessagesTableBodyRow
                                    key={message.id}
                                    message={message}
                                    onEdit={handleEdit}
                                    onClick={handleShowDetails}
                                    companyClients={companyClients}
                                    onClone={handleClone}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    {Boolean(detailsDialog.visible) && (
                        <MessageDetailsDialog
                            message={detailsDialog.visible}
                            onClose={handleHideDetails}
                        />
                    )}
                </Content>
                <Footer>
                    <div className="d-flex d-lg-none flex-row">
                        <SearchInput
                            onSearch={onSearchHandler}
                            searchValue={search}
                        />

                        <div className={styles.divider} />

                        <IconButton
                            color="white"
                            size={60}
                            href={MESSAGES_ADD_PAGE}
                        >
                            <Plus />
                        </IconButton>
                    </div>
                </Footer>
            </Container>
        </React.Fragment>
    );
};

Messages.propTypes = {};

Messages.defaultProps = {};

export default Messages;
