import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import * as SUBSCRIPTIONS_ACTIONS from 'store/actions/subscriptions';
import * as SUBSCRIPTIONS_SELECTORS from 'store/selectors/subscriptions';
import { useBool } from 'hooks/useBool';
import { useConfirmDialog } from 'hooks/useConfirmDialog';
import { SoldSubscriptionInfoTableCells } from '../SoldSubscriptionInfoTableCells';
import { SoldSubscriptionPaymentTableCells } from '../SoldSubscriptionPaymentTableCells';

import * as styles from './SoldSubscriptionsTableRow.module.scss';
import { ChangeSoldSubscriptionDialog } from '../ChangeSoldSubscriptionDialog';
import { CancelSoldSubscriptionDialog } from '../ChangeSoldSubscriptionDialog/CancelSoldSubscription';
import { PauseSoldSubscriptionDialog } from '../PauseSoldSubscriptionDialog';
import { ResumeSoldSubscriptionDialog } from '../ResumeSoldSubscriptionDialog';
import { DeleteSoldSubscriptionDialog } from '../DeleteSoldSubscriptionDialog';
import { CancelSoldSubscriptionModal, CancelSoldSubscriptionWithBookingsModal } from '../CancelSoldSubscriptionModals';
import EditSoldSubscriptionModal from '../EditSoldSubscriptionModal';

const SoldSubscriptionsTableRow = (props) => {
    const {
        soldSubscription,
        selected,
        onSelect,
        isClientPage,
    } = props;

    const {
        cancelSoldSubscription: { showCancelModal, showCancelWithBookingsModal, subscriptionId: cancelSubscriptionId },
        editSubscriptionUsages: { showEditUsagesModal, paymentStartDate, subscriptionId: editSubscriptionId },
    } = useSelector(SUBSCRIPTIONS_SELECTORS.soldSubscriptionsListPageSelector);

    const showPayments = useBool(false);
    const dispatch = useDispatch();
    const changeSoldSubscription = useSelector(
        SUBSCRIPTIONS_SELECTORS.changeSoldSubscriptionSelector,
    );

    const { payments } = soldSubscription || {};

    const pauseDialog = useConfirmDialog({
        defaultState: false,
    });

    const resumeDialog = useConfirmDialog({
        defaultState: false,
    });

    const deleteDialog = useConfirmDialog({
        defaultState: false,
    });

    const changeDialog = useConfirmDialog({
        defaultState: false,
    });

    const handleCloseBookingsDialog = useCallback(() => {
        dispatch(SUBSCRIPTIONS_ACTIONS.resetBookingsToCanceling());
        changeDialog.onShow();
    }, [dispatch, changeDialog.onShow]);

    const handleConfirmBookingsDialog = useCallback(async () => {
        dispatch(
            SUBSCRIPTIONS_ACTIONS.changeSoldSubscription({
                soldSubscriptionId: changeDialog.data?.soldSubId,
                newSubscriptionId: changeSoldSubscription.newSubscriptionId,
                onSuccess: changeDialog.onClose,
                isConfirmed: true,
            }),
        );
    }, [
        changeDialog.data?.soldSubId,
        changeSoldSubscription.newSubscriptionId,
        dispatch,
        changeDialog.onClose,
    ]);

    const handleTogglePaymentStatus = useCallback(
        ({ soldSubscriptionId, paymentId }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.toggleSubscriptionPaymentStatus(
                    soldSubscriptionId,
                    paymentId,
                ),
            );
        },
        [dispatch],
    );

    const handleToggleUserLock = useCallback(
        ({ clientId }) => {
            dispatch(SUBSCRIPTIONS_ACTIONS.toggleUserLock(clientId));
        },
        [dispatch],
    );

    const handleCancel = useCallback(
        ({
            soldSubscriptionId, subscriptionStatus, nextDebitDate, contractStartDate, paymentType, price, payments, updatingAt, updatingSubscription,
        }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.toggleCancelSoldSubscriptionModal({
                    showCancelModal: true,
                    subscriptionId: soldSubscriptionId,
                    subscriptionStartDate: contractStartDate,
                    nextSubscriptionDate: updatingAt,
                    nextSubscriptionPrice: updatingSubscription?.price,
                    payments,
                    nextDebitDate,
                    subscriptionStatus,
                    paymentType,
                    price,
                }),
            );
        },
        [dispatch],
    );

    const handleShow = useCallback(
        ({ soldSubscriptionId, contractStartDate }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.openEditSubscriptionUsagesModal(
                    soldSubscriptionId,
                    contractStartDate,
                ),
            );
        },
        [dispatch],
    );

    const handlePause = useCallback(
        ({ soldSubscription }) => {
            pauseDialog.onSetData(soldSubscription);
            pauseDialog.onShow();
        },
        [pauseDialog.onSetData, pauseDialog.onShow],
    );

    const handleResume = useCallback(
        ({ soldSubscription }) => {
            resumeDialog.onSetData(soldSubscription);
            resumeDialog.onShow();
        },
        [resumeDialog.onSetData, resumeDialog.onShow],
    );

    const handleEdit = useCallback(
        (subscriptionData) => {
            if (subscriptionData) {
                changeDialog.onSetData(subscriptionData);
            }
            changeDialog.onShow();
        },
        [dispatch, changeDialog.onShow, changeDialog.onSetData],
    );

    const handleResend = useCallback(
        ({ soldSubscriptionId }) => {
            dispatch(
                SUBSCRIPTIONS_ACTIONS.resendSoldSubscriptionPayment({
                    soldSubscriptionId,
                }),
            );
        },
        [dispatch],
    );

    const handleDelete = useCallback(
        ({ soldSubscriptionId }) => {
            deleteDialog.onSetData(soldSubscriptionId);
            deleteDialog.onShow();
        },
        [dispatch, deleteDialog.onSetData, deleteDialog.onShow],
    );

    return (
        <React.Fragment>
            <ListTableBodyRow selected={selected} onSelect={onSelect}>
                <SoldSubscriptionInfoTableCells
                    isClientPage={isClientPage}
                    showPayments={showPayments.value}
                    soldSubscription={soldSubscription}
                    onShow={handleShow}
                    onEdit={handleEdit}
                    onPause={handlePause}
                    onResume={handleResume}
                    onCancel={handleCancel}
                    onResend={handleResend}
                    onDelete={handleDelete}
                    onToggleUserLock={handleToggleUserLock}
                    onToggleShowPayments={showPayments.onToggle}
                />
            </ListTableBodyRow>
            {showPayments.value && (
                payments?.map?.((payment) => (
                    <ListTableBodyRow
                        key={payment?.paymentId}
                        className={styles.paymentRow}
                    >
                        <SoldSubscriptionPaymentTableCells
                            isClientPage={isClientPage}
                            payment={payment}
                            soldSubscription={soldSubscription}
                            onTogglePaymentStatus={handleTogglePaymentStatus}
                        />
                    </ListTableBodyRow>
                ))
            )}

            {changeDialog.visible && (
                <ChangeSoldSubscriptionDialog
                    visible={changeDialog.visible}
                    oldSubData={changeDialog.data}
                    onClose={changeDialog.onClose}
                />
            )}
            {pauseDialog.visible && (
                <PauseSoldSubscriptionDialog
                    visible={pauseDialog.visible}
                    soldSubscription={pauseDialog.data}
                    onClose={pauseDialog.onClose}
                />
            )}
            {resumeDialog.visible && (
                <ResumeSoldSubscriptionDialog
                    visible={resumeDialog.visible}
                    soldSubscription={resumeDialog.data}
                    onClose={resumeDialog.onClose}
                />
            )}
            {deleteDialog.visible && (
                <DeleteSoldSubscriptionDialog
                    isVisible={deleteDialog.visible}
                    soldSubscriptionId={deleteDialog.data}
                    onClose={deleteDialog.onReject}
                />
            )}
            {!!changeSoldSubscription.bookingsToCanceling?.length && changeDialog.data?.soldSubId === soldSubscription.id && (
                <CancelSoldSubscriptionDialog
                    visible={!!changeSoldSubscription.bookingsToCanceling?.length}
                    bookings={changeSoldSubscription.bookingsToCanceling}
                    onClose={handleCloseBookingsDialog}
                    onConfirm={handleConfirmBookingsDialog}
                />
            )}
            { showCancelModal && cancelSubscriptionId === soldSubscription.id && (
                <CancelSoldSubscriptionModal />
            )}
            { showCancelWithBookingsModal && cancelSubscriptionId === soldSubscription.id && (
                <CancelSoldSubscriptionWithBookingsModal />
            )}
            { showEditUsagesModal && editSubscriptionId === soldSubscription.id && (
                <EditSoldSubscriptionModal startDate={paymentStartDate} />
            )}
        </React.Fragment>
    );
};

SoldSubscriptionsTableRow.propTypes = {
    soldSubscription: PropTypes.object,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    isClientPage: PropTypes.bool,
};

SoldSubscriptionsTableRow.defaultProps = {
    soldSubscription: null,
    selected: false,
    onSelect: null,
    isClientPage: false,
};

export default SoldSubscriptionsTableRow;
