import { invoicesListSaga } from './invoicesList';
import { payoutsListSaga } from './payoutsList';
import { clearingsListSaga } from './clearingsList';
import { multisafePayoutsListSaga } from './multisafePayoutsList';

export const financialsSaga = [
    ...invoicesListSaga,
    ...payoutsListSaga,
    ...clearingsListSaga,
    ...multisafePayoutsListSaga,
];
