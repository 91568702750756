import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';

import * as SERVICES_ACTIONS from 'store/actions/services';
import WindowedSelect, { components } from 'react-windowed-select';
import TextButton from 'components/Common/TextButton/TextButton';
import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';
import { getAllSubscriptions } from 'store/actions';
import { CheckBox } from 'components/Common/CheckBox';
import { Column } from 'components/Common/Column';
import { getError, isInvalid } from 'services/validationService';
import { Label } from 'components/Common/Typography/Label';
import * as styles from './ProfileSubscriptionSelect.module.scss';

const ProfileSubscriptionSelect = ({
    namespace, namespaceValues, setFieldValue, errors, touched,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(SERVICES_ACTIONS.getCompanyServices());
    }, [
        dispatch,
    ]);

    const { t } = useTranslation(LOCALE_NAMESPACE.ACCESS);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const [value, setValue] = useState(null);
    const [accessSubscriptions, setAccessSubscriptions] = useState([]);

    const selectSubscriptionsOptions = useMemo(() => [
        { value: null, label: '' },
        ...accessSubscriptions.map((subscription, index) => ({
            value: subscription.id,
            label: `${subscription.name} - ${subscription.price_formatted}`,
            index,
        })),
    ], [accessSubscriptions]);

    const valuesSubscriptionsIdsSet = useMemo(() => new Set(namespaceValues?.map((c) => c.id)), [namespaceValues?.length]);

    useEffect(() => {
        dispatch(getAllSubscriptions())
            .then((subscriptions) => setAccessSubscriptions(subscriptions || []));
    }, []);

    const isAllSubscriptionsSelected = namespaceValues?.length === accessSubscriptions.length;

    const handleSetValues = useCallback((subscriptions) => {
        const values = subscriptions.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (subscriptions.length > 100) {
            values.push({ value: -1, label: tc('weekRoute.more', { count: subscriptions.length - 100 }) });
        }
        setValue(values);
    }, []);

    useEffect(() => {
        if (namespaceValues?.length) {
            handleSetValues(namespaceValues);
        }
    }, [!!namespaceValues?.length]);

    const handleChangeAllSubscriptionsSelect = useCallback(({ clear }) => {
        let subscriptions = [];
        if (isAllSubscriptionsSelected || clear) {
            setFieldValue(namespace, subscriptions);
        } else {
            subscriptions = accessSubscriptions.map((c) => ({
                id: c.id,
                name: c.name,
            }));
            setFieldValue(namespace, subscriptions);
        }
        handleSetValues(subscriptions);
    }, [isAllSubscriptionsSelected, accessSubscriptions]);

    const handleChangeSubscriptionSelect = useCallback((subscription) => {
        if (!subscription.value) {
            return;
        }
        let subscriptions = [];
        const isChecked = valuesSubscriptionsIdsSet.has(subscription.value);
        if (!isChecked) {
            subscriptions = [...namespaceValues, { id: subscription.value, name: subscription.label }];
            setFieldValue(namespace, subscriptions);
        } else {
            subscriptions = namespaceValues.filter((c) => c.id !== subscription.value);
            setFieldValue(namespace, subscriptions);
        }

        handleSetValues(subscriptions);
    }, [namespaceValues?.length, valuesSubscriptionsIdsSet]);

    const handleChangeSubscriptionsSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllSubscriptionsSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeSubscriptionSelect(triggeredAction.removedValue);
                return;
            }
            const subscriptions = namespaceValues.slice(0, 100);
            setFieldValue(namespace, subscriptions);
            handleSetValues(subscriptions);
        }
        handleChangeSubscriptionSelect(triggeredAction.option);
    }, [handleChangeSubscriptionSelect, handleChangeAllSubscriptionsSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllSubscriptionsSelected) {
            e.preventDefault();
            setFieldValue(namespace, []);
        }
    }, [isAllSubscriptionsSelected]);

    const isSubscriptionsSelectInvalid = isInvalid(namespace, errors, touched);

    return (
        <Column gap={12} stretched>
            <Label htmlFor={namespace}>
                {t('profiles.add.accessRules.subscriptions.label')}
            </Label>
            <Column gap={8} stretched>
                <WindowedSelect
                    id={namespace}
                    name={namespace}
                    styles={selectWithGrayItems}
                    theme={isSubscriptionsSelectInvalid ? dangerTheme : theme}
                    options={selectSubscriptionsOptions}
                    value={value}
                    onChange={handleChangeSubscriptionsSelect}
                    isMulti
                    isClearable
                    backspaceRemovesValue
                    onKeyDown={onKeyDownHandle}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    placeholder={t('profiles.add.accessRules.subscriptions.placeholder')}
                    components={{
                        MultiValue: namespaceValues?.length === accessSubscriptions.length
                            ? (props) => (
                                props.index === 0
                                    ? t('profiles.add.accessRules.subscriptions.allSubscriptions.label')
                                    : null
                            )
                        // eslint-disable-next-line react/no-unstable-nested-components
                            : (props) => <components.MultiValue {...props} />,
                        // eslint-disable-next-line react/no-unstable-nested-components
                        Option: ({
                            innerRef, innerProps, data,
                        }) => {
                            const isChecked = valuesSubscriptionsIdsSet.has(data.value);

                            return (
                                <div
                                    ref={innerRef}
                                    {...innerProps}
                                    className={styles.option}
                                >
                                    {data.value === null
                                        ? (
                                            <TextButton color="yellow" onClick={handleChangeAllSubscriptionsSelect}>
                                                {t('profiles.add.accessRules.subscriptions.allSubscriptions.label')}
                                            </TextButton>
                                        )
                                        : (
                                            <CheckBox
                                                checked={isChecked}
                                                label={data.label}
                                            />
                                        )}
                                </div>
                            );
                        },
                    }}
                />

                <Form.Control.Feedback
                    className={isSubscriptionsSelectInvalid && 'd-block'}
                    type="invalid"
                >
                    {getError(namespace, errors)}
                </Form.Control.Feedback>
            </Column>
        </Column>

    );
};

export default ProfileSubscriptionSelect;
