// extracted by mini-css-extract-plugin
var _1 = "Divider_divider__jV9Os";
var _2 = "Divider_horisontal__huvJX";
var _3 = "Divider_padding0__6+VFm";
var _4 = "Divider_padding12__2wKFT";
var _5 = "Divider_padding16__eJDWL";
var _6 = "Divider_padding24__znx+3";
var _7 = "Divider_padding32__lUlV3";
var _8 = "Divider_padding4__F5-xz";
var _9 = "Divider_padding8__ICuVb";
export { _1 as "divider", _2 as "horisontal", _3 as "padding0", _4 as "padding12", _5 as "padding16", _6 as "padding24", _7 as "padding32", _8 as "padding4", _9 as "padding8" }
