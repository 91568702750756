import { createPromiseAction } from '@adobe/redux-saga-promise';

import * as actionTypes from './actionTypes';

export const getCompanyDetails = createPromiseAction(actionTypes.GET_COMPANY_DETAILS);
export const getCompanyDetailsSucceeded = (details) => ({
    type: actionTypes.GET_COMPANY_DETAILS_SUCCEEDED,
    details,
});
export const getCompanyDetailsFailed = () => ({
    type: actionTypes.GET_COMPANY_DETAILS_FAILED,
});

export const getCompanyClients = createPromiseAction(actionTypes.GET_COMPANY_CLIENTS);

export const getCompanySettings = createPromiseAction(actionTypes.GET_COMPANY_SETTINGS);
export const setCompanySettingsGeneral = createPromiseAction(actionTypes.SET_COMPANY_SETTINGS_GENERAL);
export const setCompanySettingsShop = createPromiseAction(actionTypes.SET_COMPANY_SETTINGS_SHOP);
export const setCompanySettingsShopErrors = ({ errors }) => ({
    type: actionTypes.SET_COMPANY_SETTINGS_SHOP_ERRORS,
    payload: errors,
});
export const setCompanySettingsDisplayAndNotifications = createPromiseAction(actionTypes.SET_COMPANY_SETTINGS_DISPLAY_AND_NOTIFICATIONS);
export const setCompanySettingsDiscountAndLoyalty = createPromiseAction(actionTypes.SET_COMPANY_SETTINGS_DISCOUNT_AND_LOYALTY);

export const getCompanyQRCode = createPromiseAction(actionTypes.GET_COMPANY_QR_CODE);
