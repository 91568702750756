const CLIENT_FIRSTNAME_KEYS = ['firstName', 'first_name', 'name', 'clientName'];
const CLIENT_LASTNAME_KEYS = ['lastName', 'last_name', 'clientLastName'];
const CLIENT_EMAIL_KEYS = ['email', 'clientEmail'];
const CLIENT_PHONE_KEYS = ['phone', 'clientPhone'];

const CLIENT_UNKNOWN = 'Unknown';

export const getFullName = ({ client }) => {
    if (!client) {
        return CLIENT_UNKNOWN;
    }
    const firstName = CLIENT_FIRSTNAME_KEYS.reduce((acc, key) => acc || client[key] || '', '');
    const lastName = CLIENT_LASTNAME_KEYS.reduce((acc, key) => acc || client[key] || '', '');
    const name = `${firstName} ${lastName}`.trim();
    if (name) {
        return name;
    }
    const email = CLIENT_EMAIL_KEYS.reduce((acc, key) => acc || client[key] || '', '');
    if (email) {
        return email;
    }
    const phone = CLIENT_PHONE_KEYS.reduce((acc, key) => acc || client[key] || '', '');
    if (phone) {
        return phone;
    }
    return CLIENT_UNKNOWN;
};
