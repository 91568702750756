import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import * as styles from './Divider.module.scss';

const paddingClasses = {
    0: styles.padding0,
    4: styles.padding4,
    8: styles.padding8,
    12: styles.padding12,
    16: styles.padding16,
    24: styles.padding24,
    32: styles.padding32,
};

const Divider = ({
    horisontal,
    padding,
}) => (
    <div className={classNames(
        styles.divider,
        horisontal && styles.horisontal,
        paddingClasses[padding],
    )}
    />
);

Divider.propTypes = {
    horisontal: PropTypes.bool,
    padding: PropTypes.oneOf([0, 4, 8, 12, 16, 24, 32]),
};

Divider.defaultProps = {
    horisontal: false,
    padding: 8,
};

export default Divider;
