import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Common/Button';
import { Column } from 'components/Common/Column';
import SubOptionForm from '../SubOptionForm';

import { emptyFunc } from '../../../helpers/function/emptyFunc';

const T_PREFIX = 'addServiceRoute';
const T_TABS = `${T_PREFIX}.tabs`;

const SubOptionsForm = (props) => {
    const {
        vatRate,
        formState,
        subOptions,
        onAdd,
        onDelete,
        onChangeName,
        onChangeTime,
        onChangePrice,
        onChangeSeatsAmount,
        onChangeAdditionalTime,
        onChangeBookingFrequency,
    } = props;

    const { t } = useTranslation();

    return (
        <Column stretched gap={0}>
            {!subOptions.length && (
                <p className="mt-4 mb-n3 text-muted">
                    {t(`${T_TABS}.subOptions.empty`)}
                </p>
            )}
            {subOptions.map((subOption) => (
                <SubOptionForm
                    key={subOption.key}
                    vatRate={vatRate}
                    subOption={subOption}
                    formState={formState}
                    onDelete={onDelete}
                    onChangeName={onChangeName}
                    onChangeTime={onChangeTime}
                    onChangePrice={onChangePrice}
                    onChangeSeatsAmount={onChangeSeatsAmount}
                    onChangeAdditionalTime={onChangeAdditionalTime}
                    onChangeBookingFrequency={onChangeBookingFrequency}
                />
            ))}
            <Button
                type="button"
                className="mt-5"
                size="small"
                onClick={onAdd}
            >
                {t(`${T_TABS}.subOptions.actions.add`)}
            </Button>
        </Column>
    );
};

SubOptionsForm.propTypes = {
    vatRate: PropTypes.number.isRequired,
    formState: PropTypes.object.isRequired,
    subOptions: PropTypes.array,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onChangeName: PropTypes.func,
    onChangeTime: PropTypes.func,
    onChangePrice: PropTypes.func,
    onChangeSeatsAmount: PropTypes.func,
    onChangeAdditionalTime: PropTypes.func,
    onChangeBookingFrequency: PropTypes.func,
};

SubOptionsForm.defaultProps = {
    subOptions: [],
    onAdd: emptyFunc,
    onDelete: emptyFunc,
    onChangeName: emptyFunc,
    onChangeTime: emptyFunc,
    onChangePrice: emptyFunc,
    onChangeSeatsAmount: emptyFunc,
    onChangeAdditionalTime: emptyFunc,
    onChangeBookingFrequency: emptyFunc,
};

export default SubOptionsForm;
