export const CLIENT_PROP = {
    FIRST_NAME: {
        MAX_LENGTH: 24,
        MIN_LENGTH: 2,
        DEFAULT: '',
    },
    LAST_NAME: {
        MAX_LENGTH: 24,
        MIN_LENGTH: 2,
        DEFAULT: '',
    },
    BIRTH_DATE: {
        DEFAULT: null,
    },
    EMAIL: {
        MAX_LENGTH: 320,
        DEFAULT: '',
    },
    PHONE: {
        DEFAULT: '',
    },
    COMPANY_NAME: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    STREET: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    ZIP: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    CITY: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    NOTE: {
        MAX_LENGTH: 1500,
        DEFAULT: '',
    },
    AFTERWARD_PAYMENTS: {
        DEFAULT: false,
    },
    MONTHLY_INVOICING: {
        DEFAULT: true,
    },
    SEND_INVITATION: {
        DEFAULT: true,
    },
    BLOCKED: {
        DEFAULT: false,
    },
    NULA_COUNTRY: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    NULA_COC_NUMBER: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    NULA_VAT_NUMBER: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    IBAN: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    IBAN_OWNER: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
    ACCESS_CARD_CODE: {
        MAX_LENGTH: 64,
        DEFAULT: '',
    },
};
