import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card } from 'react-bootstrap';

import { copyText } from 'helpers/string/copyText';
import { useToasts } from 'react-toast-notifications';
import { CopyIcon } from 'components/Icon/Icon';
import IconButton from 'components/Common/IconButton/IconButton';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'profile.details';

const ProfileNula = (props) => {
    const { client } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);
    const { addToast } = useToasts();

    const handleCopy = useCallback((text) => {
        copyText(text);
        addToast(t(`${T_PREFIX}.copied`), {
            appearance: 'success',
            autoDismiss: true,
        });
    }, [addToast, t]);

    const {
        iban,
        cocNumber,
        vatNumber,
        country,
    } = client || {};

    return (
        <Card>
            <Card.Body>
                <Card.Text
                    className="d-flex align-items-center flex-wrap w-100"
                    style={{ gap: 10 }}
                >
                    <strong>{t(`${T_PREFIX}.fields.iban.label`)}</strong>
                    <span
                        style={{ maxWidth: '100%' }}
                    >
                        {iban}

                    </span>
                    {!!iban && (
                        <IconButton
                            color="outline"
                            onClick={() => handleCopy(iban)}
                        >
                            <CopyIcon />
                        </IconButton>
                    )}
                </Card.Text>

                <Card.Text
                    className="d-flex align-items-center flex-wrap w-100"
                    style={{ gap: 10 }}
                >
                    <strong>{t(`${T_PREFIX}.fields.country.label`)}</strong>
                    <span
                        style={{ maxWidth: '100%' }}
                    >
                        {country}

                    </span>
                    {!!country && (
                        <IconButton
                            color="outline"
                            onClick={() => handleCopy(country)}
                        >
                            <CopyIcon />
                        </IconButton>
                    )}
                </Card.Text>

                <Card.Text
                    className="d-flex align-items-center flex-wrap w-100"
                    style={{ gap: 10 }}
                >
                    <strong>{t(`${T_PREFIX}.fields.vatNumber.label`)}</strong>
                    <span
                        style={{ maxWidth: '100%' }}
                    >
                        {vatNumber}

                    </span>
                    {!!vatNumber && (
                        <IconButton
                            color="outline"
                            onClick={() => handleCopy(vatNumber)}
                        >
                            <CopyIcon />
                        </IconButton>
                    )}
                </Card.Text>

                <Card.Text
                    className="d-flex align-items-center flex-wrap w-100"
                    style={{ gap: 10 }}
                >
                    <strong>{t(`${T_PREFIX}.fields.cocNumber.label`)}</strong>
                    <span
                        style={{ maxWidth: '100%' }}
                    >
                        {cocNumber}

                    </span>
                    {!!cocNumber && (
                        <IconButton
                            color="outline"
                            onClick={() => handleCopy(cocNumber)}
                        >
                            <CopyIcon />
                        </IconButton>
                    )}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

ProfileNula.propTypes = {
    client: PropTypes.object,
};

ProfileNula.defaultProps = {
    client: null,
};

export default ProfileNula;
