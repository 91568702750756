import React from 'react';
import PropTypes from 'prop-types';
import BSTable from 'react-bootstrap/Table';

import { Badge } from 'components/Common/Badge';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Column } from 'components/Common/Column';
import { useMobile } from 'hooks/useMobile';
import * as styles from './Table.module.scss';
import { TableMultiselectPanel } from '../TableMultiselectPanel';
import { EmptyTable } from '../EmptyTable';
import { ListTableMobileFooter } from '../ListTableMobileFooter';
import { ListTableFooter } from '../ListTableFooter';

const Table = (props) => {
    const { children, footer } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.TABLE);
    const isMobile = useMobile();

    if (!footer?.itemsCount) {
        return (
            <EmptyTable />
        );
    }

    return (
        <React.Fragment>
            <Column stretched gap={8}>
                {props.multiselectPanel?.selectedCount > 0 && (
                    <TableMultiselectPanel
                        {...props.multiselectPanel}
                    />
                )}
                <div className={styles.tableContainer}>
                    {props.hasNewItems && (
                        <div className={styles.newItems}>
                            <Badge onClick={props.onNewItemsClick} color="primary" size="small">
                                {t('list.newItemsAvaliable')}
                            </Badge>
                        </div>
                    )}
                    <BSTable
                        responsive
                        className={styles.table}
                    >
                        {children}
                        {(!isMobile) && (
                            <ListTableFooter
                                page={footer.page}
                                showAll={footer.showAll}
                                itemsCount={footer.itemsCount}
                                pagesCount={footer.pagesCount}
                                columnsCount={footer.columnsCount}
                                itemsPerPage={footer.itemsPerPage}
                                onShowAll={footer.onShowAll}
                                onPrevPage={footer.onPrevPage}
                                onNextPage={footer.onNextPage}
                                onLastPage={footer.onLastPage}
                                onFirstPage={footer.onFirstPage}
                                onShowPages={footer.onShowPages}
                                onChangePage={footer.onChangePage}
                            />
                        )}
                    </BSTable>
                </div>
            </Column>
            {(isMobile) && (
                <ListTableMobileFooter
                    page={footer.page}
                    showAll={footer.showAll}
                    itemsCount={footer.itemsCount}
                    pagesCount={footer.pagesCount}
                    itemsPerPage={footer.itemsPerPage}
                    onShowAll={footer.onShowAll}
                    onPrevPage={footer.onPrevPage}
                    onNextPage={footer.onNextPage}
                    onLastPage={footer.onLastPage}
                    onFirstPage={footer.onFirstPage}
                    onShowPages={footer.onShowPages}
                    onChangePage={footer.onChangePage}
                    paginatorOnSeparateLine
                />
            )}
        </React.Fragment>
    );
};

Table.propTypes = {
    children: PropTypes.any,
    hasNewItems: PropTypes.bool,
    onNewItemsClick: PropTypes.func,
    multiselectPanel: PropTypes.shape(TableMultiselectPanel.propTypes),
    footer: PropTypes.shape(ListTableFooter.propTypes),
};

Table.defaultProps = {
    children: null,
    hasNewItems: false,
    onNewItemsClick: () => {},
    multiselectPanel: null,
    footer: null,
};

export default Table;
