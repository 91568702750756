import React from 'react';
import PropTypes from 'prop-types';

import { useBool } from 'hooks/useBool';

import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ClientBookingsTableColumns } from '../ClientBookingsTableColumns';
import { DiscountRow } from '../DiscountRow';

import * as styles from './ClientBookingsTableRow.module.scss';

const ClientBookingsTableRow = (props) => {
    const {
        booking,
    } = props;

    const showDiscounts = useBool(false);

    return (
        <React.Fragment>
            <ListTableBodyRow data-testid={`data-test-client-bookings-table-row-${booking?.id}`}>
                <ClientBookingsTableColumns
                    booking={booking}
                    showDiscounts={showDiscounts.value}
                    onToggleShowDiscounts={showDiscounts.onToggle}
                />
            </ListTableBodyRow>
            {showDiscounts.value && (
                <ListTableBodyRow className={styles.discountRow} data-testid={`data-test-client-bookings-table-row-discount-${booking?.id}`}>
                    <DiscountRow booking={booking} />
                </ListTableBodyRow>
            )}
        </React.Fragment>
    );
};

ClientBookingsTableRow.propTypes = {
    booking: PropTypes.object,
};

ClientBookingsTableRow.defaultProps = {
    booking: null,
};

export default ClientBookingsTableRow;
