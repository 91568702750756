import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Form, Modal,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import * as BUNDLES_ACTIONS from 'store/actions/bundles';
import * as COMPANY_ACTIONS from 'store/actions/company';

import * as BUNDLES_SELECTORS from 'store/selectors/bundles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getError, isInvalid } from 'services/validationService';
import { Button } from 'components/Common/Button';
import { Content } from 'components/Common/Content';
import { useDialog } from 'hooks/useDialog';
import { ConfirmDialog } from 'components/Common/Dialogs/ConfirmDialog';
import { SellBundleForm } from '../SellBundleForm';
import * as styles from './SellBundleModal.module.scss';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;
const DATA_TRANSFORMER = {
    send: (data) => {
        const formData = new FormData();
        if (data.client.id) {
            formData.append('clientId', data.client.id);
        }
        formData.append('clientName', data.client.name.trim());
        formData.append('clientPhone', data.client.phone.split(' ').join(''));
        formData.append('clientEmail', data.client.email.trim());
        formData.append('note', data.note.trim());
        formData.append('paymentType', data.paymentType.trim());
        return formData;
    },
};

function SellBundleModal({ onClose, defaultClient }) {
    const dispatch = useDispatch();
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);
    const isClientDisabled = Boolean(defaultClient?.id);
    const confirmSellSameBudleDialog = useDialog(false);

    const initialValues = useMemo(() => ({
        bundle: '',
        client: {
            id: defaultClient?.id || '',
            name: defaultClient?.fullName?.firstName || '',
            lastName: defaultClient?.fullName?.lastName || '',
            phone: defaultClient?.contactInfo?.phone || '',
            email: defaultClient?.contactInfo?.email || '',
        },
        note: '',
        paymentType: 'ideal',
    }), [defaultClient]);

    const {
        loading: companyBundlesLoading,
        items: companyBundles,
    } = useSelector(BUNDLES_SELECTORS.companyBundlesSelector);

    const validationSchema = useMemo(() => yup.object({
        bundle: yup
            .number()
            .required(t(`${T_FIELDS}.bundle.validation.required`)),
        client: yup.object({
            id: yup
                .number()
                .nullable(),
            name: yup
                .string()
                .trim()
                .required(t(`${T_FIELDS}.client.name.validation.required`)),
            phone: yup
                .string()
                .trim()
                .required(t(`${T_FIELDS}.client.phone.validation.required`)),
            email: yup
                .string()
                .email(t(`${T_FIELDS}.client.email.validation.email`))
                .nullable(),
        }),
        note: yup
            .string()
            .trim(),
        paymentType: yup
            .string()
            .trim(),
    }), [t]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            const onFinal = () => setSubmitting(false);

            const formData = new FormData();
            formData.append('clientId', values.client.id);

            dispatch(BUNDLES_ACTIONS.checkBundlesItem({
                bundleId: values.bundle,
                formData,
                onSuccess: ({ result }) => {
                    if (result) {
                        confirmSellSameBudleDialog.onShow();
                    } else {
                        handleSellBundle();
                    }
                },
                onFinal,
            }));
        },
    });

    const {
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setSubmitting,
        isSubmitting,
    } = formik;

    const handleSellBundle = useCallback(() => {
        setSubmitting(true);
        const transformedValues = DATA_TRANSFORMER.send(values);
        dispatch(BUNDLES_ACTIONS.sellBundlesItem({
            bundleId: values.bundle,
            sellInfo: transformedValues,
            history,
            onFinal: () => setSubmitting(false),
            onDone: onClose,
        }));
    }, [dispatch, history, onClose, setSubmitting, values]);

    const validation = useMemo(() => Object.keys(values).reduce((res, k) => {
        const value = values[k];
        let subValidation;
        const objects = ['client'];
        if (objects.includes(k)) {
            subValidation = Object.keys(value).reduce((res1, kk) => {
                const invalid = isInvalid(`${k}.${kk}`, errors, touched);
                return {
                    ...res1,
                    [kk]: {
                        isInvalid: invalid,
                        error: getError(`${k}.${kk}`, errors),
                    },
                };
            }, {});
        } else {
            const invalid = isInvalid(k, errors, touched);
            subValidation = {
                isInvalid: invalid,
                error: getError(k, errors),
            };
        }
        return {
            ...res,
            [k]: subValidation,
        };
    }, {}), [values, errors, touched]);

    const handleChangeBundle = useCallback(({ value }) => {
        setFieldTouched('bundle', true);
        setFieldValue('bundle', value);
    }, [setFieldTouched, setFieldValue]);

    const handleGetClients = useCallback(({ query }) => (
        dispatch(COMPANY_ACTIONS.getCompanyClients({ query }))
    ), [dispatch]);

    const handleCreateClient = useCallback((name) => {
        const { phone, email } = values.client;
        setFieldTouched('client.id', true);
        setFieldTouched('client.name', true);
        setFieldTouched('client.phone', true);
        setFieldTouched('client.email', true);
        setFieldValue('client', {
            id: '',
            name,
            phone,
            email,
        });
    }, [
        setFieldTouched,
        setFieldValue,
        values.client.phone,
        values.client.email,
    ]);

    const handleChangeClient = useCallback((selected) => {
        setFieldTouched('client.id', true);
        setFieldTouched('client.name', true);
        setFieldTouched('client.phone', true);
        setFieldTouched('client.email', true);
        if (!selected) {
            setFieldValue('client', {
                id: '',
                name: '',
                lastName: '',
                phone: '',
                email: '',
            });
            return;
        }
        const {
            value,
            name,
            lastName,
            phone,
            email,
        } = selected;
        setFieldValue('client', {
            id: value || '',
            name: name || '',
            lastName: lastName || '',
            phone: phone || '',
            email: email || '',
        });
    }, [setFieldTouched, setFieldValue]);

    const handleChangeClientPhone = useCallback((phone) => {
        setFieldTouched('client.phone', true);
        setFieldValue('client.phone', phone);
    }, [setFieldTouched, setFieldValue]);

    const handleChangeClientEmail = useCallback((e) => {
        const { target: { value } } = e;
        setFieldTouched('client.email', true);
        setFieldValue('client.email', value);
    }, [setFieldTouched, setFieldValue]);

    const handleSelectPayment = useCallback((payment) => {
        setFieldTouched('paymentType', true);
        setFieldValue('paymentType', payment);
    }, [setFieldTouched, setFieldValue]);

    const handleChangeNote = useCallback((e) => {
        const { target: { value } } = e;
        setFieldTouched('note', true);
        setFieldValue('note', value);
    }, [setFieldTouched, setFieldValue]);

    useEffect(() => {
        dispatch(BUNDLES_ACTIONS.getCompanyBundles());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Modal
                centered
                size="lg"
                onHide={onClose}
                show={!confirmSellSameBudleDialog.visible}
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header
                        className={styles.modalHeader}
                        closeButton
                    >
                        <Modal.Title className="mb-0 font-weight-700">
                            {t('sell.header.title')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Content loading={companyBundlesLoading}>
                            <SellBundleForm
                                values={values}
                                bundles={companyBundles}
                                validation={validation}
                                onGetClients={handleGetClients}
                                onChangeNote={handleChangeNote}
                                onChangeClient={handleChangeClient}
                                onCreateClient={handleCreateClient}
                                onChangeBundle={handleChangeBundle}
                                onChangeClientPhone={handleChangeClientPhone}
                                onChangeClientEmail={handleChangeClientEmail}
                                onSelectPayment={handleSelectPayment}
                                isClientDisabled={isClientDisabled}
                            />
                        </Content>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                        <Button
                            onClick={onClose}
                            color="outline"
                        >
                            {t('sell.footer.actions.cancel')}
                        </Button>
                        <Button
                            color="yellow"
                            loading={isSubmitting}
                            type="submit"
                            disabled={companyBundlesLoading}
                        >
                            {t('sell.footer.actions.sell')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {
                confirmSellSameBudleDialog.visible && (
                    <ConfirmDialog
                        size="md"
                        text={t('sell.confirmSellSameBudleDialog.text')}
                        title={t('sell.confirmSellSameBudleDialog.title')}
                        visible={confirmSellSameBudleDialog.visible}
                        onClose={confirmSellSameBudleDialog.onClose}
                        onReject={confirmSellSameBudleDialog.onClose}
                        onConfirm={handleSellBundle}
                        rejectProps={{ label: tc('placeholders.no') }}
                        confirmProps={{
                            label: tc('placeholders.yes'),
                            isLoading: isSubmitting,
                        }}
                    />
                )
            }
        </React.Fragment>
    );
}

export default SellBundleModal;
