import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content } from 'components/Common/Content';
import Container from 'components/Layout/Container/Container';
import { Table } from 'components/Common/NewTable/Table';
import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import * as MULTISAFE_PAYOUTS_ACTIONS from 'store/actions/financials/multisafePayouts';
import * as FINANCIALS_SELECTORS from 'store/selectors/financilas';
import { MultisafePayoutsTableBodyRow } from 'components/Financials/MultisafePayouts/MultisafePayoutsTableBodyRow';
import { MultisafePayoutsPageHeader } from 'components/Financials/MultisafePayouts/MultisafePayoutsPageHeader';
import { MultisafePayoutsTableHeader } from 'components/Financials/MultisafePayouts/MultisafePayoutsTableHeader';

const ITEMS_PER_PAGE = 10;
const COLUMNS_COUNT = 3;

function MultisafePayouts() {
    const dispatch = useDispatch();

    const {
        page,
        items,
        showAll,
        loading,
        itemsCount,
        pagesCount,
    } = useSelector(FINANCIALS_SELECTORS.multisafePayoutsListSelector);

    const payoutsList = useMemo(() => (
        (!showAll && items.length > ITEMS_PER_PAGE)
            ? items.slice(0, ITEMS_PER_PAGE)
            : items
    ), [showAll, items]);

    const handlePrevPage = useCallback(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.setmultisafePayoutsPage({ page: nextPage }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(MULTISAFE_PAYOUTS_ACTIONS.getMultisafePayouts());
    }, [dispatch, page]);

    return (
        <React.Fragment>
            <MultisafePayoutsPageHeader />
            <Container>
                <Content loading={loading}>
                    <Table
                        footer={{
                            page,
                            showAll,
                            itemsCount,
                            pagesCount,
                            columnsCount: COLUMNS_COUNT,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onShowAll: handleShowAll,
                            onPrevPage: handlePrevPage,
                            onNextPage: handleNextPage,
                            onLastPage: handleLastPage,
                            onFirstPage: handleFirstPage,
                            onShowPages: handleShowPages,
                            onChangePage: handleChangePage,
                        }}
                    >
                        <TableHeader>
                            <MultisafePayoutsTableHeader />
                        </TableHeader>

                        <TableBody>
                            {payoutsList.map((payout) => (
                                <ListTableBodyRow key={payout.invoiceId}>
                                    <MultisafePayoutsTableBodyRow
                                        payout={payout}
                                    />
                                </ListTableBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                </Content>
            </Container>
        </React.Fragment>
    );
}

export default MultisafePayouts;
