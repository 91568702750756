import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { formatPrice } from 'helpers/formatters/formatPrice';
import { Divider } from 'components/Common/Divider';
import { Row } from 'components/Common/Row';
import { Panel } from 'components/Common/Panel';
import { useMobile } from 'hooks/useMobile';
import { Column } from 'components/Common/Column';
import { SubHeader } from 'components/Common/Typography/SubHeader';
import { Label } from 'components/Common/Typography/Label';
import { Tooltip } from 'components/Common/Tooltip';
import { ArrowDiagonalDownIcon, ArrowDiagonalUpIcon, BalanceIcon } from '../../Icon/Icon';

import * as styles from './SalesInfo.module.scss';

const getColorByValue = (value, reverse = false) => {
    const numberValue = Number(value);
    if ((numberValue > 0 && !reverse) || (numberValue < 0 && reverse)) {
        return 'green';
    }
    if (numberValue === 0) {
        return 'gray';
    }
    if ((numberValue < 0 && !reverse) || (numberValue > 0 && reverse)) {
        return 'red';
    }
};

const getArrowByValue = (value) => {
    const numberValue = Number(value);
    if (numberValue > 0) {
        return <ArrowDiagonalUpIcon height={16} width={16} />;
    }
    if (numberValue === 0) {
        return null;
    }
    if (numberValue < 0) {
        return <ArrowDiagonalDownIcon height={16} width={16} />;
    }
};

function SalesInfo(props) {
    const {
        subscriptionsStatus,
        showSalesInfo,
    } = props;
    const { t } = useTranslation();
    const isTablet = useMobile(1300);

    if (!showSalesInfo) {
        return null;
    }

    return (
        <Panel color="primary" padding={0} stretched>
            <Row stretched align="stretch" wrap={isTablet}>
                <Row stretched gap={24} padding={24} spacing={24} align="start">
                    {!isTablet && <BalanceIcon className={styles.cellSalesInfoPanelIcon} />}
                    <Row stretched gap={32} wrap={isTablet}>
                        <Column gap={8}>
                            <SubHeader tooltip={t('subscriptions.salesInfo.totalMonthly.tooltip')} weight="light" color="gray">
                                {t('subscriptions.salesInfo.totalMonthly.label')}
                            </SubHeader>
                            <Row gap={8}>
                                {!isNaN(subscriptionsStatus?.totalMonthly)
                                    ? (
                                        <SubHeader weight="bold">
                                            {formatPrice.toEuroWithComma({ amount: subscriptionsStatus.totalMonthly })}
                                        </SubHeader>
                                    ) : <Skeleton height={16} width={100} />}
                                {!isNaN(subscriptionsStatus?.monthlyChange)
                                    && (
                                        <Tooltip
                                            tooltip={t('subscriptions.salesInfo.comparedToLastMonth.label')}
                                            placement="bottom"
                                            forButton
                                        >
                                            <Label color={getColorByValue(subscriptionsStatus.monthlyChange)} weight="light">
                                                <Row gap={4}>
                                                    {getArrowByValue(subscriptionsStatus.monthlyChange)}
                                                    {`${Number(subscriptionsStatus.monthlyChange).toFixed(2)}%`}
                                                </Row>
                                            </Label>
                                        </Tooltip>
                                    )}
                            </Row>
                        </Column>
                        <Column gap={8}>
                            <SubHeader tooltip={t('subscriptions.salesInfo.totalAnnual.tooltip')} weight="light" color="gray">
                                {t('subscriptions.salesInfo.totalAnnual.label')}
                            </SubHeader>
                            <Row gap={8}>
                                {!isNaN(subscriptionsStatus?.totalAnnual)
                                    ? (
                                        <SubHeader weight="bold">
                                            {formatPrice.toEuroWithComma({ amount: subscriptionsStatus.totalAnnual })}
                                        </SubHeader>
                                    ) : <Skeleton height={16} width={100} />}
                                {!isNaN(subscriptionsStatus?.annualChange)
                                    && (
                                        <Tooltip
                                            tooltip={t('subscriptions.salesInfo.comparedToLastYear.label')}
                                            placement="bottom"
                                            forButton
                                        >
                                            <Label color={getColorByValue(subscriptionsStatus.annualChange)} weight="light">
                                                <Row gap={4}>
                                                    {getArrowByValue(subscriptionsStatus.annualChange)}
                                                    {`${Number(subscriptionsStatus.annualChange).toFixed(2)}%`}
                                                </Row>
                                            </Label>
                                        </Tooltip>
                                    )}
                            </Row>
                        </Column>
                    </Row>
                </Row>
                <Divider padding={0} horisontal={isTablet} />

                <Row stretched gap={24} padding={24} spacing={24} align="end">
                    <Row stretched gap={32} wrap={isTablet}>
                        <Column gap={8}>
                            <SubHeader weight="light" color="gray">
                                {t('subscriptions.salesInfo.startedThisMonth.label')}
                            </SubHeader>
                            <Row gap={8}>
                                {!isNaN(subscriptionsStatus?.startedThisMonth)
                                    ? (
                                        <SubHeader weight="bold">
                                            {formatPrice.toEuroWithComma({ amount: subscriptionsStatus.startedThisMonth })}
                                        </SubHeader>
                                    ) : <Skeleton height={16} width={100} />}
                                {!isNaN(subscriptionsStatus?.startedThisMonthChange)
                                    && (
                                        <Tooltip
                                            tooltip={t('subscriptions.salesInfo.comparedToLastMonth.label')}
                                            placement="bottom"
                                            forButton
                                        >
                                            <Label color={getColorByValue(subscriptionsStatus.startedThisMonthChange)} weight="light">
                                                <Row gap={4}>
                                                    {getArrowByValue(subscriptionsStatus.startedThisMonthChange)}
                                                    {`${Number(subscriptionsStatus.startedThisMonthChange).toFixed(2)}%`}
                                                </Row>
                                            </Label>
                                        </Tooltip>
                                    )}
                            </Row>
                        </Column>
                        <Column gap={8}>
                            <SubHeader weight="light" color="gray">
                                {t('subscriptions.salesInfo.endedThisMonth.label')}
                            </SubHeader>
                            <Row gap={8}>
                                {!isNaN(subscriptionsStatus?.endedThisMonth)
                                    ? (
                                        <SubHeader weight="bold">
                                            {formatPrice.toEuroWithComma({ amount: subscriptionsStatus.endedThisMonth })}
                                        </SubHeader>
                                    ) : <Skeleton height={16} width={100} />}
                                {!isNaN(subscriptionsStatus?.endedThisMonthChange)
                                    && (
                                        <Tooltip
                                            tooltip={t('subscriptions.salesInfo.comparedToLastMonth.label')}
                                            placement="bottom"
                                            forButton
                                        >
                                            <Label color={getColorByValue(subscriptionsStatus.endedThisMonthChange, true)} weight="light">
                                                <Row gap={4}>
                                                    {getArrowByValue(subscriptionsStatus.endedThisMonthChange)}
                                                    {`${Number(subscriptionsStatus.endedThisMonthChange).toFixed(2)}%`}
                                                </Row>
                                            </Label>
                                        </Tooltip>
                                    )}
                            </Row>
                        </Column>
                    </Row>
                </Row>
            </Row>
        </Panel>
    );
}

SalesInfo.propTypes = {
    subscriptionsStatus: PropTypes.object,
    showSalesInfo: PropTypes.bool,
};

export default SalesInfo;
