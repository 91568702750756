import React from 'react';
import { Column } from 'components/Common/Column';
import { Row } from 'components/Common/Row';
import { Label } from 'components/Common/Typography/Label';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './SimpleCell.module.scss';

export const SimpleCell = (props) => {
    const {
        children, subHeader, rightAction, hoverAction,
    } = props;

    const hasHoverAction = hoverAction && rightAction;

    return (
        <div className={classNames(hasHoverAction && styles.hoverAction)}>
            <Row stretched gap={16} align="center" justify="between">
                <Column shrink>
                    <Label ellipsis>
                        {children}
                    </Label>
                    {Boolean(subHeader) && (
                        <Label color="gray">
                            {subHeader}
                        </Label>
                    )}
                </Column>
                {Boolean(rightAction) && (
                    <div className={styles.rightAction}>
                        {rightAction}
                    </div>
                )}
            </Row>
        </div>
    );
};

SimpleCell.propTypes = {
    children: PropTypes.node.isRequired,
    subHeader: PropTypes.string,
    rightAction: PropTypes.node,
    /**
     * enable show right action only on hover
     */
    hoverAction: PropTypes.bool,
};

SimpleCell.defaultProps = {
    subHeader: '',
    rightAction: null,
    hoverAction: false,
};
