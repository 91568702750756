// extracted by mini-css-extract-plugin
var _1 = "Badge_badge__qYPS2";
var _2 = "Badge_blackField__nRXgs";
var _3 = "Badge_blueField__uPMdw";
var _4 = "Badge_button__T3b2n";
var _5 = "Badge_capitalize__yg5Ya";
var _6 = "Badge_extraSmallField__8oGPa";
var _7 = "Badge_grayField__aIsPj";
var _8 = "Badge_greenField__UFet7";
var _9 = "Badge_lightGrayField__KlazC";
var _a = "Badge_lightYellowField__Uxb9Z";
var _b = "Badge_outlineField__2aWM8";
var _c = "Badge_primaryField__OS0iw";
var _d = "Badge_redField__jjU18";
var _e = "Badge_regularField__pT53y";
var _f = "Badge_smallField__RnKpI";
var _10 = "Badge_yellowField__KdMOQ";
export { _1 as "badge", _2 as "blackField", _3 as "blueField", _4 as "button", _5 as "capitalize", _6 as "extraSmallField", _7 as "grayField", _8 as "greenField", _9 as "lightGrayField", _a as "lightYellowField", _b as "outlineField", _c as "primaryField", _d as "redField", _e as "regularField", _f as "smallField", _10 as "yellowField" }
