import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';

import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import * as FINANCIALS_SELECTORS from '../../../../store/selectors/financilas';
import { setPayoutsSortOptions } from '../../../../store/actions/financials/payouts';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { PAYOUT_SORT_BY_TYPE } from '../../../../const/payouts/PAYOUT_SORT_BY_TYPE';

const T_PREFIX = 'list.table.header';
const T_FIELDS = `${T_PREFIX}.fields`;

const PayoutsTableHeader = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.PAYOUTS);

    const { sortOptions } = useSelector(FINANCIALS_SELECTORS.payoutsListSelector);
    const dispatch = useDispatch();

    const handleCellClick = useCallback((sortBy) => {
        if (sortOptions.sortBy === sortBy) {
            dispatch(setPayoutsSortOptions({
                sortOptions: {
                    orderBy: sortOptions.orderBy * -1,
                },
            }));
        } else {
            dispatch(setPayoutsSortOptions({
                sortOptions: {
                    sortBy,
                    orderBy: 1,
                },
            }));
        }
    }, [sortOptions]);

    return (
        <React.Fragment>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.number.label`)}
                    active={sortOptions.sortBy === PAYOUT_SORT_BY_TYPE.INVOICE_NUMBER}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(PAYOUT_SORT_BY_TYPE.INVOICE_NUMBER)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_FIELDS}.costTransactions.label`)}
                    active={sortOptions.sortBy === PAYOUT_SORT_BY_TYPE.COST_TRANSACTIONS}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(PAYOUT_SORT_BY_TYPE.COST_TRANSACTIONS)}
                />
            </TableHeaderCell>
            {false && (
                <TableHeaderCell>
                    <ListTableHeaderCell
                        name={t(`${T_FIELDS}.transferred.label`)}
                        active={sortOptions.sortBy === PAYOUT_SORT_BY_TYPE.TRANSFERRED}
                        ascending={sortOptions.orderBy === -1}
                        onClick={() => handleCellClick(PAYOUT_SORT_BY_TYPE.TRANSFERRED)}
                    />
                </TableHeaderCell>
            )}
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.created.label`)}
                    active={sortOptions.sortBy === PAYOUT_SORT_BY_TYPE.CREATED}
                    ascending={sortOptions.orderBy === -1}
                    onClick={() => handleCellClick(PAYOUT_SORT_BY_TYPE.CREATED)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_FIELDS}.actions.label`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
};

PayoutsTableHeader.propTypes = {};

PayoutsTableHeader.defaultProps = {};

export default PayoutsTableHeader;
