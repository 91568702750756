import {
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import axios from 'services/axios';
import { retryWithRefreshToken } from 'helpers/sagas/retryWithRefreshToken';
import { getDefaultHeaders } from 'helpers/http/getDefaultHeaders';
import SubscriptionDataTransformations from 'services/subscriptionDataTransformations';
import { setSoldIsDataLoaded, setSoldSubscriptionsList } from 'store/actions';
import * as CLIENTS_TYPES from '../../../actions/clients/tables/subscriptions/types';
import * as CLIENTS_SELECTORS from '../../../selectors/clients';

import * as TOAST_ACTIONS from '../../../actions/toast';

import { HTTP_METHOD } from '../../../../const/http/HTTP_METHOD';
import { HTTP_STATUS_CODE } from '../../../../const/http/HTTP_STATUS_CODE';
import { API_ADMIN_CLIENT_SUBSCRIPTIONS_ID_ROUTE } from '../../../../const/API_URL';

function* getClientSubscriptions(action) {
    const { payload } = action;
    const { clientId } = payload;

    const { currentPage, hideInactive } = yield select(CLIENTS_SELECTORS.clientSubscriptionsSelector);
    const defaultHeaders = yield getDefaultHeaders();

    try {
        yield put(setSoldIsDataLoaded(false));
        const res = yield call(axios.request, {
            _action: action,
            method: HTTP_METHOD.GET,
            url: API_ADMIN_CLIENT_SUBSCRIPTIONS_ID_ROUTE({ clientId }),
            headers: defaultHeaders,
            params: {
                page: currentPage,
                hideInactive: Number(hideInactive),
            },
        });
        const { data } = res;
        const { code, result } = data;
        if (code === HTTP_STATUS_CODE.OK) {
            const {
                items,
                itemsCount,
                pagesCount,
            } = result;
            const subscriptions = SubscriptionDataTransformations.transformSoldSubscriptionsResponse(
                items,
            );
            yield put(setSoldSubscriptionsList({
                subscriptions, pagesCount, itemsCount, clientId,
            }));
        }
    } catch (error) {
        // eslint-disable-next-line no-inner-declarations
        function* handleFail() {
            yield put(TOAST_ACTIONS.showToast({
                message: 'clients.getSubscriptions.error',
                appearance: 'error',
            }));
        }
        if (error?.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            yield call(retryWithRefreshToken, {
                action,
                onFail: handleFail,
                onError: handleFail,
            });
            return;
        }
        yield call(handleFail);
    } finally {
        yield put(setSoldIsDataLoaded(true));
    }
}

export const clientSubscriptionSaga = [
    takeLatest(CLIENTS_TYPES.CLIENT_ITEM_SUBSCRIPTIONS_GET, getClientSubscriptions),
];
