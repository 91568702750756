import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';
import { LOCALE_NAMESPACE } from '../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './SoldBundlesTableColumns.module.scss';

const T_PREFIX = 'sold.table.header';

const SoldBundlesTableColumns = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <React.Fragment>
            <TableHeaderCell className={styles.clientCell}>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.client`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.serviceCell}>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.service`)}
                />
            </TableHeaderCell>
            <TableHeaderCell className={styles.bundleCell}>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.bundle`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.bookings`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.purchasedAt`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.endsAt`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.paymentType`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    align="right"
                    name={t(`${T_PREFIX}.profit`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.status`)}
                />
            </TableHeaderCell>
            <TableHeaderCell>
                <ListTableHeaderCell
                    name={t(`${T_PREFIX}.actions`)}
                />
            </TableHeaderCell>
        </React.Fragment>
    );
};

SoldBundlesTableColumns.propTypes = {};

SoldBundlesTableColumns.defaultProps = {};

export default SoldBundlesTableColumns;
