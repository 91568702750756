import React from 'react';

const Phone = ({ width = '25', height = '24', className }) => (
    <svg className={className} height={height} width={width} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9913 20.1077C13.6811 20.1034 10.5078 18.7865 8.16715 16.4459C5.82652 14.1053 4.50966 10.9319 4.50537 7.62175C4.50537 6.6326 4.89831 5.68398 5.59774 4.98455C6.29716 4.28512 7.24579 3.89218 8.23493 3.89218C8.44439 3.89059 8.6535 3.9096 8.85923 3.94894C9.05812 3.97837 9.25365 4.02725 9.44299 4.09488C9.57616 4.1416 9.69482 4.22228 9.78725 4.32892C9.87968 4.43557 9.94267 4.5645 9.96999 4.70296L11.0808 9.56761C11.1107 9.69965 11.1071 9.83709 11.0703 9.96738C11.0334 10.0977 10.9646 10.2167 10.87 10.3135C10.7646 10.427 10.7564 10.4351 9.75919 10.954C10.5578 12.706 11.959 14.1129 13.7077 14.9187C14.2347 13.9134 14.2428 13.9052 14.3563 13.7998C14.4531 13.7052 14.5721 13.6364 14.7024 13.5995C14.8327 13.5627 14.9702 13.5591 15.1022 13.589L19.9668 14.6998C20.1009 14.7309 20.2248 14.7956 20.3269 14.8878C20.429 14.98 20.506 15.0967 20.5506 15.2268C20.619 15.4193 20.6706 15.6173 20.7046 15.8187C20.7373 16.0224 20.7535 16.2285 20.7533 16.4349C20.7383 17.4198 20.3344 18.3588 19.6295 19.0469C18.9247 19.735 17.9763 20.1164 16.9913 20.1077Z" fill="currentColor" />
    </svg>

);

export default Phone;
