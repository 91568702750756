import { createSelector } from 'reselect';

export const userSelector = (state) => state.user;

export const userEmailSelector = createSelector(
    userSelector,
    (userState) => userState.email,
);

export const userDefaultTabSelector = createSelector(
    userSelector,
    (userState) => userState.defaultTab,
);

export const userSoundNotificationsEnabledSelector = createSelector(
    userSelector,
    (userState) => userState.soundNotificationsEnabled,
);

export const userAccessKeysSelector = createSelector(
    userSelector,
    (userState) => ({
        canEditOwnAgenda: userState.canEditOwnAgenda,
        canOpenAccessLogs: userState.canOpenAccessLogs,
        canOpenClients: userState.canOpenClients,
        canOpenCompanySettings: userState.canOpenCompanySettings,
        canOpenFinancials: userState.canOpenFinancials,
        canOpenMarketing: userState.canOpenMarketing,
        canOpenServices: userState.canOpenServices,
    }),
);
