import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { PortalHeader } from '../PortalHeader';
import { PortalMspAlert } from '../PortalMspAlert';

function LayoutContainer({ children }) {
    return (
        <Container className="bg-white p-0 d-flex flex-column flex-grow-1 w-100 h-100" fluid style={{ overflow: 'clip' }}>
            <PortalMspAlert />
            <PortalHeader />
            {children}
        </Container>
    );
}

LayoutContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default LayoutContainer;
