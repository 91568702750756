import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';

// Utils

const initialState = {
    loaded: false,
    getGroupsLoading: false,
    groups: null,
    sessions: null,
    employees: null,
    filters: null,
    bookings: null,
    waitlist: null,
    sessionsLoading: false,
};

export const groups = (state = initialState, { type, ...payload }) => {
    switch (type) {
    case actionTypes.GET_GROUPS:
        return {
            ...state,
            getGroupsLoading: true,
        };
    case actionTypes.GET_GROUPS_SUCCEEDED:
        const { groups } = payload;
        return {
            ...state,
            loaded: true,
            getGroupsLoading: false,
            groups,
            filters: {
                ...groups.map(({ id }) => id).reduce((filters, id) => ({ ...filters, [id]: true }), {}),
                ...state.filters,
            },
        };
    case actionTypes.GET_GROUPS_FAILED:
        return {
            ...state,
            getGroupsLoading: false,
        };
    case actionTypes.GET_GROUP_SESSIONS:
        const { silent } = payload;
        return {
            ...state,
            sessions: silent ? state.sessions : [],
            sessionsLoading: !silent,
        };

    case actionTypes.GET_GROUP_SESSIONS_SUCCEEDED:
        const { groupSessions } = payload;
        return {
            ...state,
            sessionsLoading: false,
            sessions: groupSessions,
            employees: _.uniqWith(groupSessions
                .filter(({ product }) => !!product)
                .reduce((employees, { product }) => [
                    ...employees,
                    product,
                ], []), _.isEqual),
            filters: {
                ...groupSessions
                    .filter(({ product }) => !!product)
                    .reduce((filters, { product }) => ({
                        ...filters,
                        [product.id]: true,
                    }), {}),
                ...state.filters,
            },
        };

    case actionTypes.GET_GROUP_SESSIONS_FAILED:
        return {
            ...state,
            sessionsLoading: false,
        };
    case actionTypes.SET_GROUPS_FILTER:
        const { id, visible } = payload;
        return {
            ...state,
            filters: {
                ...state.filters,
                [id]: visible,
            },
        };
    case actionTypes.GET_GROUP_SESSION_BOOKINGS:
        return {
            ...state,
            bookings: null,
            waitlist: null,
        };
    case actionTypes.GET_GROUP_SESSION_BOOKINGS_SUCCEEDED:
        const { bookings, waitlist } = payload;

        return {
            ...state,
            bookings,
            waitlist,
        };
    case actionTypes.ADD_BOOKING_SUCCEEDED: {
        const { booking } = payload;

        if (booking.product.type !== 'group') {
            return state;
        }

        const bookings = Array.isArray(state.bookings)
            ? [...state.bookings, booking]
            : [booking];

        return {
            ...state,
            bookings,
            sessions: state.sessions.map((session) => (session.id === booking.product.groupSessionId ? {
                ...session,
                places: {
                    ...session.places,
                    available: session.places.available - (booking.seats ?? 1),
                },
                isFull: session.places.available - (booking.seats ?? 1) === 0,
            } : session)),
        };
    }
    case actionTypes.EDIT_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { booking } = payload;

        return {
            ...state,
            bookings: state.bookings.map((existing) => (existing.id === booking.id ? booking : existing)),
        };
    }
    case actionTypes.DELETE_BOOKING_SUCCEEDED: {
        const { booking, timestamp } = payload;

        if (!state.bookings || !booking.groupSession) {
            return state;
        }

        return {
            ...state,
            bookings: state.bookings.filter(({ id, time: { from } }) => {
                if (timestamp && booking.id === id) {
                    return timestamp !== from;
                }
                return booking.id !== id;
            }),
            sessions: state.sessions.map((session) => {
                if (timestamp && (session.id === booking.groupSession.id && timestamp === session.time.from)) {
                    return {
                        ...session,
                        places: {
                            ...session.places,
                            available: Math.min(session.places.available + (booking.seats ?? 1), session.places.total),
                        },
                        isFull: false,
                    };
                }

                if (!timestamp && (session.id === booking.groupSession.id)) {
                    return {
                        ...session,
                        places: {
                            ...session.places,
                            available: Math.min(session.places.available + (booking.seats ?? 1), session.places.total),
                        },
                        isFull: false,
                    };
                }

                return session;
            }),
        };
    }
    case actionTypes.OMIT_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { bookingID, timestamp } = payload;

        return {
            ...state,
            bookings: state.bookings.map((item) => (item.id === bookingID && timestamp === item.time.from
                ? { ...item, notPresent: true }
                : item)),
        };
    }
    case actionTypes.CHANGE_PAY_STATUS_BOOKING_SUCCEEDED: {
        if (!state.bookings) {
            return state;
        }

        const { bookingID, timestamp, paymentStatus } = payload;

        return {
            ...state,
            bookings: state.bookings.map((item) => (item.id === bookingID && timestamp === item.time.from
                ? {
                    ...item,
                    payment: {
                        ...item.payment,
                        paid: paymentStatus === 'paid',
                        paidUpfront: paymentStatus === 'paid' ? item.payment.finalCost : 0,
                        remainingCost: paymentStatus !== 'paid' ? item.payment.finalCost : 0,
                    },
                }
                : item)),
        };
    }
    case actionTypes.ADD_GROUP_SUCCEEDED:
        const { group } = payload;
        return {
            ...state,
            groups: [
                ...state.groups,
                group,
            ],
            employees: _.uniqWith([
                ...state.groups,
                payload.group,
            ].reduce((employees, group) => {
                if (!Array.isArray(group.sessions)) return [...employees];
                return [
                    ...employees,
                    ...group.sessions.reduce((employees, { product }) => {
                        if (!product) {
                            return employees;
                        }

                        return [
                            ...employees,
                            product,
                        ];
                    }, []),
                ];
            }, []), _.isEqual),
            filters: {
                ...state.filters,
                ...group.sessions.reduce((filters, { product }) => {
                    if (!product || state.filters.hasOwnProperty(product.id)) {
                        return filters;
                    }

                    return {
                        ...filters,
                        [product.id]: true,
                    };
                }, {}),
            },
        };
    case actionTypes.EDIT_GROUP_SUCCEEDED:
        return {
            ...state,
            groups: state.groups.map((group) => {
                if (group.id === payload.group.id) {
                    return payload.group;
                }

                return group;
            }),
            employees: _.uniqWith([
                ...state.groups,
                payload.group,
            ].reduce((employees, group) => {
                if (!Array.isArray(group.sessions)) return [...employees];
                return [
                    ...employees,
                    ...group.sessions.reduce((employees, { product }) => {
                        if (!product) {
                            return employees;
                        }

                        return [
                            ...employees,
                            product,
                        ];
                    }, []),
                ];
            }, []), _.isEqual),
            filters: [...state.groups, payload.group].reduce((filters, group) => {
                if (!Array.isArray(group.sessions)) return { ...filters };
                return {
                    ...filters,
                    ...group.sessions.reduce((filters, { product }) => {
                        if (!product) {
                            return filters;
                        }

                        return {
                            ...filters,
                            [product.id]: true,
                        };
                    }, {}),
                };
            }, {}),
        };
    case actionTypes.PRODUCTS_GET_BY_ID:
        return {
            ...state,
            loaded: false,
        };
    case actionTypes.PRODUCTS_GET_BY_ID_SUCCESS: {
        const { item: { type, product } } = payload;
        if (type !== 'group') return state;
        return {
            ...state,
            loaded: true,
            groups: state.groups.map((item) => (item.id === product.id ? product : item)),
        };
    }
    case actionTypes.OVERRIDE_GROUP_SESSION_SUCCEEDED: {
        const { payload: { id, timestamp, maxPeople } } = payload;

        return {
            ...state,
            sessions: state.sessions.map((session) => (session.id === id && timestamp === session.time.from ? {
                ...session,
                places: {
                    available: maxPeople - (session.places.total - session.places.available),
                    total: maxPeople,
                },
                isFull: maxPeople - (maxPeople - (session.places.total - session.places.available)) === maxPeople,
            } : session)),
        };
    }
    case actionTypes.CANCEL_GROUP_SESSION_SUCCEEDED: {
        const { id, timestamp } = payload.payload;
        return {
            ...state,
            sessions: state.sessions.map((session) => (session.id === id && session.time.from === timestamp ? {
                ...session,
                isCancelled: !session.isCancelled,
                isFull: false,
                places: {
                    ...session.places,
                    available: session.places.total,
                },
            } : session)),
        };
    }
    case actionTypes.DELETE_GROUP_SESSION_SUCCEEDED: {
        const id = payload.payload;

        return {
            ...state,
            groups: state.groups.filter((group) => Number(group.id) !== Number(id)),
            sessions: state.sessions.filter((sessions) => Number(sessions.group.id) !== Number(id)),
        };
    }
    case actionTypes.SELECT_SHOP_TRIGGER:
        return {
            ...state,
            loaded: false,
            groups: null,
            employees: null,
            bookings: null,
        };
    case actionTypes.REFRESH_TOKEN_REJECTED:
    case actionTypes.SIGN_OUT_SUCCEEDED:
        return initialState;
    default:
        return state;
    }
};
