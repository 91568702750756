import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
    Dropdown,
} from 'react-bootstrap';
import IconButton from 'components/Common/IconButton/IconButton';
import { Row } from 'components/Common/Row';
import { Column } from 'components/Common/Column';
import { copyText } from 'helpers/string/copyText';
import { useToasts } from 'react-toast-notifications';

import { Tooltip } from '../../../Common/Tooltip';

import {
    DownloadIcon, CopyIcon,
} from '../../../Icon/Icon';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './InvoicesTableInfoPopover.module.scss';

const T_PREFIX = 'list.table.body.rows';
const T_FIELDS = `${T_PREFIX}.fields`;
const T_POPOVER = 'list.table.infoPopover';

const InvoicesTableInfoPopover = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const { addToast } = useToasts();

    const { invoice, isClearingPending } = props;
    const {
        date,
        clearingUrl,
        clearingName,
        transactionId,
    } = invoice || {};

    const invoiceDate = moment(date).format('DD MMM YYYY');

    const handleCopy = useCallback((text) => {
        copyText(text);
        addToast(t(`${T_POPOVER}.copied`), {
            appearance: 'success',
            autoDismiss: true,
        });
    }, [addToast, t]);

    return (
        <div className={styles.popover}>
            <Column gap={0}>
                <Row padding={12} stretched justify="between">
                    <Column padding={12}>
                        {!isClearingPending && (
                            <span className="text-muted">
                                {[t(`${T_POPOVER}.clearing.label`), invoiceDate].join(' · ')}
                            </span>
                        )}
                        <span>{clearingName || t(`${T_POPOVER}.clearing.tooltip`)}</span>
                    </Column>
                    {clearingName && (
                        <Column padding={12}>
                            <Row gap={8}>
                                <Tooltip
                                    forButton
                                    placement="top"
                                    tooltip={t(`${T_FIELDS}.actions.download.tooltip`)}
                                >
                                    <IconButton
                                        download
                                        href={clearingUrl}
                                        color="gray"
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    forButton
                                    placement="top"
                                    tooltip={t(`${T_FIELDS}.actions.copy.tooltip`)}
                                >
                                    <IconButton
                                        color="gray"
                                        onClick={() => handleCopy(clearingName)}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </Row>
                        </Column>
                    )}
                </Row>
                <Dropdown.Divider className="w-100 m-0" />
                <Row stretched justify="between" padding={12}>
                    <Column padding={12}>
                        <span className="text-muted">{t(`${T_POPOVER}.transactionId.label`)}</span>
                        <span>{transactionId || '-'}</span>
                    </Column>

                    {transactionId && (
                        <Column padding={12}>
                            <Tooltip
                                forButton
                                placement="top"
                                tooltip={t(`${T_FIELDS}.actions.copy.tooltip`)}
                            >
                                <IconButton
                                    color="gray"
                                    onClick={() => handleCopy(transactionId)}
                                >
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                        </Column>
                    )}
                </Row>
            </Column>
        </div>
    );
};

InvoicesTableInfoPopover.propTypes = {
    invoice: PropTypes.object,
    isClearingPending: PropTypes.bool,
};

InvoicesTableInfoPopover.defaultProps = {
    invoice: null,
    isClearingPending: false,
};

export default InvoicesTableInfoPopover;
