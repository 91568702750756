import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/Common/IconButton/IconButton';
import SearchInput from 'components/SearchInput';
import { useDialog } from '../../../../hooks/useDialog';

import Footer from '../../../Layout/Footer/Footer';
import { InvoicesMobileFiltersDialog } from '../InvoicesMobileFiltersDialog';

import { FilterIcon, Plus } from '../../../Icon/Icon';

import * as styles from './InvoicesPageMobileFooter.module.scss';

const InvoicesPageMobileFooter = (props) => {
    const {
        searchValue,
        onSearch,
        filters,
        onSetInvoicesFilters,
        isClientPage,
    } = props;

    const invoicesMobileFiltersDialog = useDialog(false);

    return (
        <React.Fragment>
            <Footer className={styles.mobileButtonsContainer}>
                <SearchInput
                    searchValue={searchValue}
                    onSearch={onSearch}
                />
                <div className={styles.footerDivider} />

                <IconButton
                    color="white"
                    size={60}
                    onClick={invoicesMobileFiltersDialog.onShow}
                >
                    <FilterIcon />
                </IconButton>
                {!isClientPage && (
                    <React.Fragment>
                        <div className={styles.footerDivider} />
                        <IconButton
                            color="yellow"
                            size={60}
                            href="/financials/invoices/add"
                        >
                            <Plus />
                        </IconButton>
                    </React.Fragment>
                )}
            </Footer>
            <InvoicesMobileFiltersDialog
                isVisible={invoicesMobileFiltersDialog.visible}
                onClose={invoicesMobileFiltersDialog.onClose}
                filters={filters}
                onSetInvoicesFilters={onSetInvoicesFilters}
            />
        </React.Fragment>
    );
};

InvoicesPageMobileFooter.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    filters: PropTypes.object,
    onSetInvoicesFilters: PropTypes.func,
    isClientPage: PropTypes.bool,
};

InvoicesPageMobileFooter.defaultProps = {
    searchValue: '',
    onSearch: PropTypes.emptyFunc,
    filters: {},
    onSetInvoicesFilters: PropTypes.emptyFunc,
    isClientPage: false,
};

export default InvoicesPageMobileFooter;
