import React from 'react';

const ArrowSmallDown = ({ width = '24', height = '24' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M6.16252 8.79289C6.55305 8.40237 7.18621 8.40237 7.57674 8.79289L11.8696 13.0858L16.1625 8.7929C16.553 8.40237 17.1862 8.40237 17.5767 8.7929C17.9673 9.18342 17.9673 9.81658 17.5767 10.2071L12.5767 15.2071C12.1862 15.5976 11.553 15.5976 11.1625 15.2071L6.16252 10.2071C5.772 9.81658 5.772 9.18342 6.16252 8.79289Z" fill="currentColor" />
    </svg>

);

export default ArrowSmallDown;
