import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import range from 'lodash/range';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import BootstrapRow from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import { WindowedMenuList } from 'react-windowed-select';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { getVATRateSelectOptions } from 'components/subscriptions/SubscriptionPricingFormGroup/SubscriptionPricingFormGroup';
import { InfoTooltip } from 'components/Common/InfoTooltip';
import { Button } from 'components/Common/Button';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { SERVICES_ROOT_PAGE } from 'const/CLIENT_URL';
import { BackButton } from 'components/Common/BackButton';
import Container from 'components/Layout/Container/Container';
import { Content } from 'components/Common/Content';
import { Label } from 'components/Common/Typography/Label';
import { Row } from 'components/Common/Row';
import NumberInput from 'components/Common/NumberInput';

import { CheckBox } from 'components/Common/CheckBox';
import SubOptionsForm from '../../../../../../components/services/SubOptionsForm';

import * as SERVICES_ACTIONS from '../../../../../../store/actions/services';
import * as CATEGORIES_ACTIONS from '../../../../../../store/actions/categories';

import * as COMPANY_SELECTORS from '../../../../../../store/selectors/company';
import * as SERVICES_SELECTORS from '../../../../../../store/selectors/services';
import * as CATEGORIES_SELECTORS from '../../../../../../store/selectors/categories';

import { SERVICE_PROP } from '../../../../../../const/services/SERVICE_PROP';
import { BOOKABLE_TYPE, BOOKABLE_TYPES_ARRAY } from '../../../../../../const/services/BOOKABLE_TYPE';

import { getError, isInvalid } from '../../../../../../services/validationService';

import { generateRandomString } from '../../../../../../helpers/string/generateRandomString';

import { styles, theme } from '../../../../../../styles/select';

const T_PREFIX = 'addServiceRoute';
const T_HEADER = `${T_PREFIX}.header`;
const T_FIELDS = `${T_PREFIX}.form.fields`;
const T_TABS = `${T_PREFIX}.tabs`;
const T_ACTIONS = `${T_PREFIX}.actions`;

const PAGE_MODE = {
    ADD: 'add',
    EDIT: 'edit',
};

const SERVICE_DEFAULT_VALUES = {
    name: '',
    categoryName: null,
    time: SERVICE_PROP.TIME.DEFAULT,
    addTime: SERVICE_PROP.ADDITIONAL_TIME.DEFAULT,
    bookingFrequency: SERVICE_PROP.BOOKING_FREQUENCY.DEFAULT,
    price: SERVICE_PROP.PRICE.DEFAULT,
    vatValue: SERVICE_PROP.VAT_RATE.DEFAULT,
    seatsAmount: SERVICE_PROP.SEATS_AMOUNT.DEFAULT,
    bookableType: SERVICE_PROP.BOOKABLE.DEFAULT,
    randomlySelectEmployee: SERVICE_PROP.RANDOM_SELECT.DEFAULT,
    restrictUnpaidBookings: SERVICE_PROP.RESTRICT_UNPAID_BOOKINGS.DEFAULT,
    hidePrice: SERVICE_PROP.HIDE_PRICE.DEFAULT,
    subOptions: [],
};

const SERVICE_BOOKING_FREQUENCY_RANGE = range(
    SERVICE_PROP.BOOKING_FREQUENCY.MIN,
    SERVICE_PROP.BOOKING_FREQUENCY.MAX + 1,
    SERVICE_PROP.BOOKING_FREQUENCY.STEP,
);

const BOOKING_FREQUENCY_OPTIONS = SERVICE_BOOKING_FREQUENCY_RANGE
    .map((v) => ({ value: v, label: v }));

const transformData = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        if (key === 'categoryName') {
            const category = value?.value && value.label;
            if (category) {
                formData.append(key, category);
            }
        } else if (key === 'subOptions') {
            value.map((so, i) => (
                Object.keys(so).map((kk) => (
                    formData.append(`${key}[${i}][${kk}]`, so[kk])
                ))
            ));
        } else if (['restrictUnpaidBookings', 'randomlySelectEmployee', 'hidePrice'].includes(key)) {
            formData.append(key, Number(value));
        } else {
            formData.append(key, value);
        }
    });
    return formData;
};

const AddService = () => {
    const { t } = useTranslation();

    const params = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const { categories, loading: categoriesLoading } = useSelector(CATEGORIES_SELECTORS.categoriesSelector);
    const { item: service, loading: serviceLoading } = useSelector(SERVICES_SELECTORS.serviceItemSelector);
    const { enableBusinessMode } = useSelector(COMPANY_SELECTORS.companyBuisnessModeSelector);

    const isEditMode = Boolean(params.id);
    const pageMode = isEditMode ? PAGE_MODE.EDIT : PAGE_MODE.ADD;
    const isDuplicateMode = Boolean(history.location.state?.serviceId);
    const serviceId = isDuplicateMode ? history.location.state.serviceId : params.id;

    const validationSchema = useMemo(() => yup.object({
        name: yup
            .string()
            .trim()
            .min(
                SERVICE_PROP.NAME.MIN_LENGTH,
                t(`${T_FIELDS}.name.validation.minLength`, {
                    length: SERVICE_PROP.NAME.MIN_LENGTH,
                }),
            )
            .max(
                SERVICE_PROP.NAME.MAX_LENGTH,
                t(`${T_FIELDS}.name.validation.maxLength`, {
                    length: SERVICE_PROP.NAME.MAX_LENGTH,
                }),
            )
            .required(t(`${T_FIELDS}.name.validation.required`)),
        time: yup
            .number()
            .min(
                SERVICE_PROP.TIME.MIN,
                t(`${T_FIELDS}.time.validation.min`, {
                    value: SERVICE_PROP.TIME.MIN,
                }),
            )
            .required(t(`${T_FIELDS}.time.validation.required`)),
        addTime: yup
            .number()
            .min(
                SERVICE_PROP.ADDITIONAL_TIME.MIN,
                t(`${T_FIELDS}.addTime.validation.min`, {
                    value: SERVICE_PROP.ADDITIONAL_TIME.MIN,
                }),
            )
            .max(
                SERVICE_PROP.ADDITIONAL_TIME.MAX,
                t(`${T_FIELDS}.addTime.validation.max`, {
                    value: SERVICE_PROP.ADDITIONAL_TIME.MAX,
                }),
            )
            .required(t(`${T_FIELDS}.addTime.validation.required`)),
        bookingFrequency: yup
            .number()
            .oneOf(
                SERVICE_BOOKING_FREQUENCY_RANGE,
                t(`${T_FIELDS}.bookingFrequency.validation.enum`),
            )
            .required(t(`${T_FIELDS}.bookingFrequency.validation.required`)),
        price: yup
            .number()
            .min(
                SERVICE_PROP.PRICE.MIN,
                t(`${T_FIELDS}.price.validation.min`, {
                    value: SERVICE_PROP.PRICE.MIN,
                }),
            )
            .max(
                SERVICE_PROP.PRICE.MAX,
                t(`${T_FIELDS}.price.validation.max`, {
                    value: SERVICE_PROP.PRICE.MAX,
                }),
            )
            .required(t(`${T_FIELDS}.price.validation.required`)),
        vatValue: yup
            .number()
            .oneOf(
                SERVICE_PROP.VAT_RATE.ENUM,
                t(`${T_FIELDS}.vatRate.validation.enum`),
            )
            .required(t(`${T_FIELDS}.vatRate.validation.required`)),
        seatsAmount: yup
            .number()
            .min(
                SERVICE_PROP.SEATS_AMOUNT.MIN,
                t(`${T_FIELDS}.seatsAmount.validation.min`, {
                    value: SERVICE_PROP.SEATS_AMOUNT.MIN,
                }),
            )
            .max(
                SERVICE_PROP.PRICE.MAX,
                t(`${T_FIELDS}.seatsAmount.validation.max`, {
                    value: SERVICE_PROP.SEATS_AMOUNT.MAX,
                }),
            )
            .required(t(`${T_FIELDS}.seatsAmount.validation.required`)),
        bookableType: yup
            .string()
            .oneOf(
                BOOKABLE_TYPES_ARRAY,
                t(`${T_FIELDS}.bookable.validation.enum`),
            )
            .required(t(`${T_FIELDS}.bookable.validation.required`)),
        randomlySelectEmployee: yup
            .bool(),
        restrictUnpaidBookings: yup.bool(),
        hidePrice: yup
            .bool(),
        subOptions: yup
            .array()
            .of(yup.object({
                id: yup.number(),
                name: yup
                    .string()
                    .trim()
                    .min(
                        SERVICE_PROP.NAME.MIN_LENGTH,
                        t(`${T_FIELDS}.name.validation.minLength`, {
                            length: SERVICE_PROP.NAME.MIN_LENGTH,
                        }),
                    )
                    .max(
                        SERVICE_PROP.NAME.MAX_LENGTH,
                        t(`${T_FIELDS}.name.validation.maxLength`, {
                            length: SERVICE_PROP.NAME.MAX_LENGTH,
                        }),
                    )
                    .required(t(`${T_FIELDS}.name.validation.required`)),
                time: yup
                    .number()
                    .min(
                        SERVICE_PROP.TIME.MIN,
                        t(`${T_FIELDS}.time.validation.min`, {
                            value: SERVICE_PROP.TIME.MIN,
                        }),
                    )
                    .required(t(`${T_FIELDS}.time.validation.required`)),
                price: yup
                    .number()
                    .min(
                        SERVICE_PROP.PRICE.MIN,
                        t(`${T_FIELDS}.price.validation.min`, {
                            value: SERVICE_PROP.PRICE.MIN,
                        }),
                    )
                    .max(
                        SERVICE_PROP.PRICE.MAX,
                        t(`${T_FIELDS}.price.validation.max`, {
                            value: SERVICE_PROP.PRICE.MAX,
                        }),
                    )
                    .required(t(`${T_FIELDS}.price.validation.required`)),
                seatsAmount: yup
                    .number()
                    .min(
                        SERVICE_PROP.SEATS_AMOUNT.MIN,
                        t(`${T_FIELDS}.seatsAmount.validation.min`, {
                            value: SERVICE_PROP.SEATS_AMOUNT.MIN,
                        }),
                    )
                    .max(
                        SERVICE_PROP.PRICE.MAX,
                        t(`${T_FIELDS}.seatsAmount.validation.max`, {
                            value: SERVICE_PROP.SEATS_AMOUNT.MAX,
                        }),
                    )
                    .required(t(`${T_FIELDS}.seatsAmount.validation.required`)),
                addTime: yup
                    .number()
                    .min(
                        SERVICE_PROP.ADDITIONAL_TIME.MIN,
                        t(`${T_FIELDS}.addTime.validation.min`, {
                            value: SERVICE_PROP.ADDITIONAL_TIME.MIN,
                        }),
                    )
                    .max(
                        SERVICE_PROP.ADDITIONAL_TIME.MAX,
                        t(`${T_FIELDS}.addTime.validation.max`, {
                            value: SERVICE_PROP.ADDITIONAL_TIME.MAX,
                        }),
                    )
                    .required(t(`${T_FIELDS}.addTime.validation.required`)),
                bookingFrequency: yup
                    .number()
                    .oneOf(
                        SERVICE_BOOKING_FREQUENCY_RANGE,
                        t(`${T_FIELDS}.bookingFrequency.validation.enum`),
                    )
                    .required(t(`${T_FIELDS}.bookingFrequency.validation.required`)),
            })),
    }), [t]);

    const formik = useFormik({
        initialValues: SERVICE_DEFAULT_VALUES,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            const serviceData = transformData(values);
            if (serviceId && service && !isDuplicateMode) {
                dispatch(SERVICES_ACTIONS.editServicesItem({
                    service: serviceData,
                    serviceId: service.id,
                    history,
                    onFinal: () => setSubmitting(false),
                }));
            } else {
                dispatch(SERVICES_ACTIONS.addServicesItem({
                    service: serviceData,
                    history,
                    onFinal: () => setSubmitting(false),
                }));
            }
        },
    });

    const {
        values: {
            name,
            time,
            price,
            vatValue,
            subOptions,
            seatsAmount,
            categoryName,
            addTime,
            bookingFrequency,
            bookableType,
            randomlySelectEmployee,
            restrictUnpaidBookings,
            hidePrice,
        },
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
    } = formik;

    const vatRateValue = useMemo(() => ({
        value: vatValue,
        label: `${vatValue}%`,
    }), [vatValue]);

    const priceWithoutVat = (price / (1 + vatValue / 100))
        .toFixed(SERVICE_PROP.PRICE.TO_FIXED);

    const bookingFrequencyValue = useMemo(() => ({
        value: bookingFrequency,
        label: bookingFrequency,
    }), [bookingFrequency]);

    const categoryOptions = useMemo(() => (
        categories.map((c) => ({
            value: c.id,
            label: c.name,
        }))
    ), [categories]);

    const subOptionsList = useMemo(() => (
        subOptions.map((so, i) => ({
            ...so,
            key: i,
        }))
    ), [subOptions]);

    const handleAddSubOption = useCallback(() => {
        const copy = subOptions.concat({
            name: name.trim(),
            time,
            price,
            addTime,
            seatsAmount,
            bookingFrequency,
        });
        setFieldValue('subOptions', copy);
    }, [
        subOptions,
        setFieldValue,
        name,
        time,
        price,
        addTime,
        seatsAmount,
        bookingFrequency,
    ]);

    const handleDeleteSubOption = useCallback(({ key }) => {
        const subOption = subOptions[key];
        if (!subOption) {
            return;
        }
        const copy = subOptions.slice();
        copy.splice(key, 1);
        setFieldValue('subOptions', copy);
    }, [subOptions, setFieldValue]);

    const handleUpdateSubOption = useCallback(({ key, ...updates }) => {
        const subOption = subOptions[key];
        if (!subOption) {
            return;
        }
        const subOptionCopy = {
            ...subOption,
            ...updates,
        };
        const copy = subOptions.slice();
        copy[key] = subOptionCopy;
        // eslint-disable-next-line array-callback-return
        Object.keys(updates).map((k) => {
            setFieldTouched(`subOptions[${key}].${k}`, true);
        });
        setFieldValue('subOptions', copy);
    }, [subOptions, setFieldValue, setFieldTouched]);

    const handleChangeTime = useCallback((value) => {
        setFieldTouched('time', true);
        setFieldValue('time', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeAdditionalTime = useCallback((value) => {
        setFieldTouched('addTime', true);
        setFieldValue('addTime', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangePrice = useCallback((value) => {
        setFieldTouched('price', true);
        setFieldValue('price', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeSeatsAmount = useCallback((value) => {
        setFieldTouched('seatsAmount', true);
        setFieldValue('seatsAmount', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeVatRate = useCallback(({ value }) => {
        setFieldTouched('vatValue', true);
        setFieldValue('vatValue', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeBookingFrequency = useCallback(({ value }) => {
        setFieldTouched('bookingFrequency', true);
        setFieldValue('bookingFrequency', value);
    }, [setFieldValue, setFieldTouched]);

    const handleChangeCategory = useCallback((selected) => {
        setFieldTouched('categoryName', true);
        setFieldValue('categoryName', selected);
    }, [setFieldValue, setFieldTouched]);

    const handleCreateCategory = useCallback((label) => {
        const value = generateRandomString();
        const newCategory = { value, label };
        setFieldTouched('categoryName', true);
        setFieldValue('categoryName', newCategory);
    }, [setFieldValue, setFieldTouched]);

    const handleFormatCreateLabel = useCallback((label) => (
        `${t(`${T_FIELDS}.category.createLabel`)}: ${label}`
    ), [t]);

    useEffect(() => {
        dispatch(CATEGORIES_ACTIONS.getCategories());
        if (serviceId) {
            dispatch(SERVICES_ACTIONS.getServiceItem({ serviceId }));
        }
    }, [serviceId]);

    useEffect(() => {
        if (serviceId && service) {
            Object.entries(service).forEach(([key, value]) => {
                if (isDuplicateMode) {
                    if (key === 'name') {
                        setFieldValue('name', t('placeholders.duplicateTitle', { title: value, interpolation: { escapeValue: false } }));
                        return;
                    }
                    if (key === 'bookableType') {
                        setFieldValue(key, BOOKABLE_TYPE.NEVER_AND_HIDDEN);
                        return;
                    }
                }
                if (key === 'categoryName') {
                    return;
                }
                if (['randomlySelectEmployee', 'hidePrice', 'restrictUnpaidBookings'].includes(key)) {
                    setFieldValue(key, Boolean(value));
                    return;
                }
                setFieldValue(key, value);
            });
        }
    }, [serviceId, service]);

    useEffect(() => {
        if (!categories.length || !(serviceId && service?.categoryName)) {
            return;
        }
        const initialCategory = categories.find((c) => c.name === service.categoryName);
        if (initialCategory) {
            setFieldValue('categoryName', {
                label: initialCategory.name,
                value: initialCategory.id,
            });
        }
    }, [
        categories,
        service?.categoryName,
        setFieldValue,
        serviceId,
    ]);

    const loading = (serviceId && serviceLoading) || categoriesLoading;

    const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

    return (
        <React.Fragment>
            <TextSubHeader
                text={t(`${T_HEADER}.title.${pageMode}`)}
                before={(
                    <BackButton
                        href={SERVICES_ROOT_PAGE}
                    />
                )}
            />
            <Form onSubmit={formik.handleSubmit}>
                <Container>
                    <Content loading={loading}>
                        <BootstrapRow>
                            <Col
                                xs={12}
                                lg={6}
                            >
                                <Form.Group>
                                    <Label
                                        paddingBottom
                                        htmlFor="name"
                                        tooltip={t(`${T_FIELDS}.name.tooltip`)}
                                    >
                                        {t(`${T_FIELDS}.name.label`)}
                                    </Label>
                                    <Form.Control
                                        value={name}
                                        id="name"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={isInvalid('name', errors, touched)}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': isInvalid('name', errors, touched),
                                        })}
                                    >
                                        {getError('name', errors)}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col
                                xs={12}
                                lg={6}
                            >
                                <Form.Group>
                                    <Label paddingBottom htmlFor="categoryName" tooltip={t(`${T_FIELDS}.category.tooltip`)}>
                                        {t(`${T_FIELDS}.category.label`)}
                                    </Label>
                                    <CreatableSelect
                                        components={{ MenuList: WindowedMenuList }}
                                        isCreatable
                                        isClearable
                                        id="categoryName"
                                        name="categoryName"
                                        styles={styles}
                                        theme={theme}
                                        options={categoryOptions}
                                        value={categoryName}
                                        onChange={handleChangeCategory}
                                        onCreateOption={handleCreateCategory}
                                        formatCreateLabel={handleFormatCreateLabel}
                                        placeholder={t('placeholders.select')}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': isInvalid('categoryName', errors, touched),
                                        })}
                                    >
                                        {getError('categoryName', errors)}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {!subOptions.length && (
                                <React.Fragment>
                                    <Col
                                        xs={12}
                                        lg={4}
                                    >
                                        <Label paddingBottom tooltip={t(`${T_FIELDS}.time.tooltip`)}>
                                            {t(`${T_FIELDS}.time.label`)}
                                        </Label>

                                        <NumberInput
                                            name="time"
                                            value={time}
                                            onChange={handleChangeTime}
                                            isInvalid={isInvalid('time', errors, touched)}
                                            min={SERVICE_PROP.TIME.MIN}
                                            step={SERVICE_PROP.TIME.STEP}
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        lg={4}
                                    >
                                        <Label paddingBottom tooltip={t(`${T_FIELDS}.addTime.tooltip`)}>
                                            {t(`${T_FIELDS}.addTime.label`)}
                                        </Label>
                                        <NumberInput
                                            name="addTime"
                                            value={addTime}
                                            onChange={handleChangeAdditionalTime}
                                            isInvalid={isInvalid('addTime', errors, touched)}
                                            min={SERVICE_PROP.ADDITIONAL_TIME.MIN}
                                            max={SERVICE_PROP.ADDITIONAL_TIME.MAX}
                                            step={SERVICE_PROP.ADDITIONAL_TIME.STEP}
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        lg={4}
                                    >
                                        <Form.Group>
                                            <Label paddingBottom tooltip={t(`${T_FIELDS}.bookingFrequency.tooltip`)}>
                                                {t(`${T_FIELDS}.bookingFrequency.label`)}
                                            </Label>
                                            <Select
                                                styles={styles}
                                                theme={theme}
                                                options={BOOKING_FREQUENCY_OPTIONS}
                                                value={bookingFrequencyValue}
                                                onChange={handleChangeBookingFrequency}
                                                placeholder={t('placeholders.select')}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid"
                                                className={classNames({
                                                    'd-block': isInvalid('bookingFrequency', errors, touched),
                                                })}
                                            >
                                                {getError('bookingFrequency', errors)}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        lg={4}
                                    >
                                        <Label paddingBottom>
                                            {t(`${T_FIELDS}.price.label`)}
                                        </Label>
                                        <NumberInput
                                            name="price"
                                            value={price}
                                            onChange={handleChangePrice}
                                            isInvalid={isInvalid('price', errors, touched)}
                                            min={SERVICE_PROP.PRICE.MIN}
                                            max={SERVICE_PROP.PRICE.MAX}
                                            step={SERVICE_PROP.PRICE.STEP}
                                            toFixed={SERVICE_PROP.PRICE.TO_FIXED}
                                        />
                                    </Col>
                                </React.Fragment>
                            )}
                            <Col
                                xs={12}
                                lg={4}
                            >
                                <Form.Group>
                                    <Label paddingBottom>
                                        {t(`${T_FIELDS}.vatRate.label`)}
                                    </Label>
                                    <Select
                                        styles={styles}
                                        theme={theme}
                                        options={getVATRateSelectOptions(enableBusinessMode)}
                                        value={vatRateValue}
                                        onChange={handleChangeVatRate}
                                        placeholder={t('placeholders.select')}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': isInvalid('vatValue', errors, touched),
                                        })}
                                    >
                                        {getError('vatValue', errors)}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {!subOptions.length && (
                                <React.Fragment>
                                    <Col
                                        xs={12}
                                        lg={4}
                                    >
                                        <Form.Group>
                                            <Label paddingBottom>
                                                {t(`${T_FIELDS}.priceWithoutVat.label`)}
                                            </Label>
                                            <Form.Control
                                                value={priceWithoutVat}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        lg={4}
                                    >
                                        <Label paddingBottom tooltip={t(`${T_FIELDS}.seatsAmount.tooltip`)}>
                                            {t(`${T_FIELDS}.seatsAmount.label`)}
                                        </Label>
                                        <NumberInput
                                            name="seatsAmount"
                                            value={seatsAmount}
                                            onChange={handleChangeSeatsAmount}
                                            isInvalid={isInvalid('seatsAmount', errors, touched)}
                                            min={SERVICE_PROP.SEATS_AMOUNT.MIN}
                                            step={SERVICE_PROP.SEATS_AMOUNT.STEP}
                                        />
                                    </Col>
                                </React.Fragment>
                            )}
                            <Col
                                xs={12}
                                lg={8}
                            >
                                <Form.Group>
                                    <Label paddingBottom>
                                        {t(`${T_FIELDS}.bookable.label`)}
                                    </Label>
                                    <div className="d-flex flex-column">
                                        <BootstrapRow>
                                            <Col
                                                xs="auto"
                                                className="mt-2"
                                            >
                                                <Form.Check
                                                    id="addServiceRoute.always"
                                                    className="mx-3"
                                                    type="radio"
                                                    name="bookableType"
                                                    value={BOOKABLE_TYPE.ALWAYS}
                                                    label={(
                                                        <Label htmlFor="addServiceRoute.always" tooltip={t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.ALWAYS}.tooltip`)}>
                                                            {t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.ALWAYS}.label`)}
                                                        </Label>
                                                    )}
                                                    checked={bookableType === BOOKABLE_TYPE.ALWAYS}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <Form.Check
                                                    id="addServiceRoute.onlyThroughBundles"
                                                    className="mx-3 mt-2"
                                                    type="radio"
                                                    name="bookableType"
                                                    value={BOOKABLE_TYPE.BUNDLE_OR_SUBSCRIPTION}
                                                    label={(
                                                        <Label htmlFor="addServiceRoute.onlyThroughBundles" tooltip={t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.BUNDLE_OR_SUBSCRIPTION}.tooltip`)}>
                                                            {t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.BUNDLE_OR_SUBSCRIPTION}.label`)}
                                                        </Label>
                                                    )}
                                                    checked={bookableType === BOOKABLE_TYPE.BUNDLE_OR_SUBSCRIPTION}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                            <Col
                                                xs="auto"
                                                className="mt-2"
                                            >
                                                <Form.Check
                                                    id="addServiceRoute.never"
                                                    className="mx-3"
                                                    type="radio"
                                                    name="bookableType"
                                                    value={BOOKABLE_TYPE.NEVER}
                                                    label={(
                                                        <Label htmlFor="addServiceRoute.never" tooltip={t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.NEVER}.tooltip`)}>
                                                            {t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.NEVER}.label`)}
                                                        </Label>
                                                    )}
                                                    checked={bookableType === BOOKABLE_TYPE.NEVER}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <Form.Check
                                                    id="addServiceRoute.neverAndHiddenForClient"
                                                    className="mx-3 mt-2"
                                                    type="radio"
                                                    name="bookableType"
                                                    value={BOOKABLE_TYPE.NEVER_AND_HIDDEN}
                                                    label={(
                                                        <Label htmlFor="addServiceRoute.neverAndHiddenForClient" tooltip={t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.NEVER_AND_HIDDEN}.tooltip`)}>
                                                            {t(`${T_FIELDS}.bookable.options.${BOOKABLE_TYPE.NEVER_AND_HIDDEN}.label`)}
                                                        </Label>
                                                    )}
                                                    checked={bookableType === BOOKABLE_TYPE.NEVER_AND_HIDDEN}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                        </BootstrapRow>
                                    </div>
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className={classNames({
                                            'd-block': isInvalid('bookableType', errors, touched),
                                        })}
                                    >
                                        {getError('bookableType', errors)}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group
                                    className="mb-3"
                                >
                                    <CheckBox
                                        id="randomlySelectEmployee"
                                        name="randomlySelectEmployee"
                                        tooltip={t(`${T_FIELDS}.randomlySelectEmployee.tooltip`)}
                                        label={t(`${T_FIELDS}.randomlySelectEmployee.label`)}
                                        checked={randomlySelectEmployee}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group
                                    className="mb-3"
                                >
                                    <CheckBox
                                        name="hidePrice"
                                        tooltip={t(`${T_FIELDS}.hidePrice.tooltip`)}
                                        label={t(`${T_FIELDS}.hidePrice.label`)}
                                        checked={hidePrice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group
                                    className="mb-3"
                                >
                                    <CheckBox
                                        id="restrictUnpaidBookings"
                                        name="restrictUnpaidBookings"
                                        tooltip={t(`${T_FIELDS}.restrictUnpaidBookings.tooltip`)}
                                        label={t(`${T_FIELDS}.restrictUnpaidBookings.label`)}
                                        checked={restrictUnpaidBookings}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Form.Group>
                            </Col>
                        </BootstrapRow>
                        <h3 className="mt-4 mb-0 font-weight-normal d-flex align-items-center">
                            {t(`${T_TABS}.subOptions.label`)}
                            <InfoTooltip
                                text={t(`${T_TABS}.subOptions.tooltip`)}
                                placement="top"
                            />
                        </h3>
                        <SubOptionsForm
                            vatRate={vatValue}
                            formState={formik}
                            subOptions={subOptionsList}
                            onAdd={handleAddSubOption}
                            onDelete={handleDeleteSubOption}
                            onChangeName={handleUpdateSubOption}
                            onChangeTime={handleUpdateSubOption}
                            onChangePrice={handleUpdateSubOption}
                            onChangeSeatsAmount={handleUpdateSubOption}
                            onChangeAdditionalTime={handleUpdateSubOption}
                            onChangeBookingFrequency={handleUpdateSubOption}
                        />
                        <Row stretched justify="between" padding={16}>
                            <Button
                                href="/services/"
                                color="outline"
                            >
                                {t(`${T_ACTIONS}.cancel`)}
                            </Button>
                            <Button
                                type="submit"
                                color="yellow"
                                loading={isSubmitting}
                                disabled={isSubmitting || hasErrors}
                            >
                                {t(`${T_ACTIONS}.${pageMode}`)}
                            </Button>
                        </Row>
                    </Content>
                </Container>
            </Form>
        </React.Fragment>
    );
};

export default AddService;
