import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { Form } from 'react-bootstrap';

import WindowedSelect, { components } from 'react-windowed-select';
import TextButton from 'components/Common/TextButton/TextButton';
import { dangerTheme, theme } from 'styles/select';
import { selectWithGrayItems } from 'styles/selectStyles/selectWithGrayItems';
import { CheckBox } from 'components/Common/CheckBox';
import * as styles from './ActionsWindowedSelect.module.scss';

const PRODUCT_TYPES = ['booked', 'cancelled', 'edited', 'purchased', 'paused', 'resumed', 'ended', 'deleted', 'registered'];

const ActionsWindowedSelect = ({ formik, stretched }) => {
    const {
        values, setFieldValue, errors, touched,
    } = formik;

    const { t } = useTranslation(LOCALE_NAMESPACE.ACTIVITY);
    const { t: tc } = useTranslation(LOCALE_NAMESPACE.COMMON);

    const [value, setValue] = useState(null);

    const selectActionsOptions = useMemo(() => [
        { value: null, label: '' },
        ...PRODUCT_TYPES.map((action, index) => ({
            value: action,
            label: t(`activity.header.filters.actions.${action}`),
            index,
        })),
    ], [t]);

    const valuesActionsIdsSet = useMemo(() => new Set(values?.actions?.map((c) => c.id)), [values?.actions?.length]);

    const isAllItemsSelected = values?.actions.length === PRODUCT_TYPES.length;

    const handleSetValues = useCallback((actions) => {
        const valies = actions.slice(0, 100).map(({ id, name }) => ({
            value: id,
            label: name,
        }));

        if (actions.length > 100) {
            valies.push({ value: -1, label: tc('weekRoute.more', { count: actions.length - 100 }) });
        }
        setValue(valies);
    }, []);

    useEffect(() => {
        handleSetValues(values.actions);
    }, [values.actions.length]);

    const handleChangeAllActionsSelect = useCallback(({ clear }) => {
        let actions = [];
        if (isAllItemsSelected || clear) {
            setFieldValue('actions', actions);
        } else {
            actions = PRODUCT_TYPES.map((c) => ({
                id: c,
                name: t(`activity.header.filters.actions.${c}`),
            }));
            setFieldValue('actions', actions);
        }
        handleSetValues(actions);
    }, [isAllItemsSelected]);

    const handleChangeActionSelect = useCallback((action) => {
        if (!action.value) {
            return;
        }
        let actions = [];
        const isChecked = valuesActionsIdsSet.has(action.value);
        if (!isChecked) {
            actions = [...values.actions, { id: action.value, name: action.label }];
            setFieldValue('actions', actions);
        } else {
            actions = values.actions.filter((c) => c.id !== action.value);
            setFieldValue('actions', actions);
        }

        handleSetValues(actions);
    }, [values?.actions.length, valuesActionsIdsSet]);

    const handleChangeActionsSelect = useCallback((_, triggeredAction) => {
        if (triggeredAction.action === 'clear') {
            handleChangeAllActionsSelect({ clear: true });
            return;
        }
        if (triggeredAction.action === 'remove-value' || triggeredAction.action === 'pop-value') {
            if (triggeredAction.removedValue.value !== -1) {
                handleChangeActionSelect(triggeredAction.removedValue);
                return;
            }
            const actions = values.actions.slice(0, 100);
            setFieldValue('actions', actions);
            handleSetValues(actions);
        }
        handleChangeActionSelect(triggeredAction.option);
    }, [handleChangeActionSelect, handleChangeAllActionsSelect]);

    const onKeyDownHandle = useCallback((e) => {
        if (e.key === 'Backspace' && isAllItemsSelected) {
            e.preventDefault();
            setFieldValue('actions', []);
        }
    }, [isAllItemsSelected]);

    const isActionsSelectInvalid = !!touched.actions && !!errors.actions;

    return (
        <div className={styles.root} style={{ width: stretched ? '100%' : 'auto' }}>
            <WindowedSelect
                id="actions"
                name="actions"
                styles={selectWithGrayItems}
                theme={isActionsSelectInvalid ? dangerTheme : theme}
                options={selectActionsOptions}
                value={value}
                onChange={handleChangeActionsSelect}
                isMulti
                isClearable
                backspaceRemovesValue
                onKeyDown={onKeyDownHandle}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                placeholder={t('activity.header.filters.actions.placeholder')}
                components={{
                    MultiValue: values.actions.length === PRODUCT_TYPES.length
                        ? (props) => (
                            props.index === 0
                                ? t('activity.header.filters.actions.placeholder')
                                : null
                        )
                    // eslint-disable-next-line react/no-unstable-nested-components
                        : (props) => <components.MultiValue {...props} />,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Option: ({
                        innerRef, innerProps, data,
                    }) => {
                        const isChecked = valuesActionsIdsSet.has(data.value);

                        return (
                            <div
                                ref={innerRef}
                                {...innerProps}
                                className={styles.option}
                            >
                                {data.value === null
                                    ? (
                                        <TextButton color="yellow" onClick={handleChangeAllActionsSelect}>
                                            {t('activity.header.filters.actions.placeholder')}
                                        </TextButton>
                                    )
                                    : (
                                        <CheckBox
                                            checked={isChecked}
                                            label={data.label}
                                        />
                                    )}
                            </div>
                        );
                    },
                }}
            />

            <Form.Control.Feedback
                className={isActionsSelectInvalid && 'd-block'}
                action="invalid"
            >
                {errors.actions}
            </Form.Control.Feedback>
        </div>
    );
};

export default ActionsWindowedSelect;
