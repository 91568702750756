import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Avatar } from 'components/Common/Avatar';
import { Row } from 'components/Common/Row';
import { CLIENTS_LIST_ID_ACCESS_PAGE } from 'const/CLIENT_URL';
import * as LOCALES_SELECTORS from 'store/selectors/locales';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { safeJsonParse } from 'helpers/json/safeJsonParse';
import { Link } from 'react-router-dom';
import { Label } from 'components/Common/Typography/Label';
import momentGMT0Time from 'helpers/time/momentGMT0Time';
import LogsBadge from '../LogsBadge/LogsBadge';

import * as styles from './LogsTableBodyRow.module.scss';

const LogsTableBodyRow = (props) => {
    const { log, isClientPage } = props;

    const {
        client, createdAt, details, gate,
    } = log || {};

    const shortLocale = useSelector(LOCALES_SELECTORS.shortLocaleSelector);
    const detailsObjetct = useMemo(() => safeJsonParse(details), [details]);

    const name = `${client?.firstName || ''} ${client?.lastName || ''}`.trim() || '-';
    const href = !isClientPage && client?.id ? CLIENTS_LIST_ID_ACCESS_PAGE({ clientId: client.id }) : undefined;

    return (
        <ListTableBodyRow>
            <ListTableBodyCell className="text-nowrap">
                {momentGMT0Time(createdAt).format('DD MMM YYYY · HH:mm:ss')}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">
                <Row gap={8}>
                    <Avatar
                        src={client?.avatarUrl || ''}
                        name={name}
                        href={href}
                    />
                    {href ? (
                        <Link to={href} className={styles.name}>
                            {name}
                        </Link>
                    ) : <Label>{name}</Label>}
                </Row>
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">
                {gate.name}
            </ListTableBodyCell>
            <ListTableBodyCell className="text-nowrap">
                {gate.shop.name}
            </ListTableBodyCell>
            <ListTableBodyCell>
                <LogsBadge log={log} />
            </ListTableBodyCell>

            <ListTableBodyCell className="text-nowrap">{detailsObjetct?.[shortLocale] || ''}</ListTableBodyCell>
        </ListTableBodyRow>
    );
};

LogsTableBodyRow.propTypes = {
    log: PropTypes.object,
    isClientPage: PropTypes.bool,
};

LogsTableBodyRow.defaultProps = {
    log: null,
    isClientPage: false,
};

export default LogsTableBodyRow;
