import React from 'react';

// Components
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { ClockIcon, Time } from 'components/Icon/Icon';
import { BookingPaymentType, Feedback } from 'components';

// Styles

// Utils
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';
import { formatPrice } from 'helpers/formatters/formatPrice';
import * as styles from './PlanningList.module.scss';

const PlanningList = ({ bookings, onBookingClick, loading }) => (
    <div className={`d-flex flex-column w-100 ${loading ? 'align-items-center' : ''}`}>
        {loading && (
            <div className="mt-5">
                <TailSpin
                    color="black"
                    width={75}
                    height={75}
                />
            </div>

        )}
        {!loading && bookings
        && Object.keys(bookings)
            .sort((a, b) => (moment(a, 'DD/MM/YYYY').isBefore(moment(b, 'DD/MM/YYYY')) ? -1 : 1))
            .map((formatted) => (
                <Day
                    key={`bookings-${formatted}`}
                    bookings={bookings}
                    formatted={formatted}
                    onBookingClick={onBookingClick}
                />
            ))}

        {!bookings
        && _.times(1, (i) => (
            <Day key={`bookings-${i}`} onBookingClick={onBookingClick} />
        ))}
    </div>
);

const Day = ({
    bookings, formatted, onBookingClick,
}) => {
    const date = moment(formatted, 'DD/MM/YYYY');

    return (
        <div className="mt-2 mb-4">
            <h3 className="font-weight-normal border-bottom pb-3">
                {bookings && date.format('DD.MM.YYYY')}
            </h3>

            <div className="mt-4 mb-5">
                {bookings
                && bookings[formatted]
                    .sort((a, b) => a.time.from - b.time.from)
                    .map((booking) => (
                        <Booking
                            key={`booking-${booking.id}`}
                            booking={booking}
                            onBookingClick={onBookingClick}
                        />
                    ))}
            </div>
        </div>
    );
};

const Booking = ({ booking, onBookingClick }) => (
    <Row
        className={classNames(
            styles.booking,
            'flex-nowrap border-0 bg-transparent w-100 mt-5 mt-md-4 mt-lg-3',
        )}
    >
        <Col className={styles.time}>
            <div className="d-flex flex-column justify-content-between justify-content-xl-start flex-xl-row mb-0 text-muted h-100">
                <div className="d-flex flex-column flex-xl-row align-items-xl-center">
                    <span className="text-primary font-weight-bold font-size-20">
                        {booking ? (
                            moment(booking.time.from).format('HH:mm')
                        ) : (
                            <Skeleton width={50} />
                        )}
                    </span>

                    <span className="d-none d-xl-block ml-1">{booking && ' - '}</span>

                    <span className="ml-xl-1">
                        {booking ? (
                            `${moment(booking.time.to).format('HH:mm')}`
                        ) : (
                            <Skeleton width={50} />
                        )}
                    </span>
                </div>

                {booking?.feedback ? (
                    <Feedback
                        feedback={booking.feedback}
                        size={26}
                        classNames="d-block d-xl-none mt-2"
                    />
                ) : (
                    <span className="d-block d-xl-none mt-2">
                        <div style={{ height: 26 }} />
                    </span>
                )}
            </div>
        </Col>

        <div
            className={classNames(
                {
                    'border-success': !booking?.payment.isInProgress && booking?.payment.paid,
                    'border-danger': !booking?.payment.isInProgress && !booking?.payment.paid,
                    'border-dark': booking?.notPresent,
                    'border-info': booking?.payment.isInProgress,
                },
                'd-flex flex-column flex-md-row w-100 border-left border-5',
            )}
        >
            <Col
                className={classNames(
                    styles.name,
                    'd-flex flex-column flex-xl-row justify-content-center justify-content-xl-start align-items-xl-center cursor-pointer',
                )}
                onClick={() => onBookingClick({ id: booking.id, timestamp: booking.time.from })}
            >
                <span className={classNames(styles.serviceName, 'd-flex d-md-inline justify-content-between font-weight-bold')}>
                    {booking ? booking.service.name : <Skeleton width={100} />}
                    <div className="d-flex align-items-center">
                        {booking?.payment.type && (
                            <BookingPaymentType
                                type={booking.payment.type}
                                width={18}
                                className="d-block d-md-none text-muted ml-2"
                            />
                        )}
                        {booking?.recurrence?.enabled && (
                            <Time
                                width={16}
                                height={16}
                                className="d-block d-md-none text-muted ml-2"
                            />
                        )}
                    </div>

                </span>
                <span
                    className={classNames(
                        styles.objectName,
                        'flex-basis-1 ml-xl-2 text-dark-gray',
                    )}
                >
                    {booking ? booking.product.name : <Skeleton width={100} />}
                </span>

                <div className="d-none d-md-flex align-items-center mt-1">
                    {booking?.feedback && (
                        <Feedback
                            feedback={booking.feedback}
                            size={26}
                            classNames="d-none d-xl-block ml-2"
                        />
                    )}

                    {booking?.payment.type && (
                        <BookingPaymentType
                            type={booking.payment.type}
                            width={18}
                            className="text-muted mr-2 ml-xl-2"
                        />
                    )}

                    {booking?.recurrence?.enabled && (
                        <Time
                            width={14}
                            height={14}
                            className="text-muted ml-0 ml-xl-2"
                        />
                    )}
                </div>
            </Col>

            <Col
                className={classNames(
                    styles.client,
                    'd-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-start align-items-xl-center',
                )}
            >
                <span className="font-size-14 font-weight-bold mx-md-2">
                    {booking ? booking.client.contactNumber : <Skeleton width={75} />}
                </span>
                <span className="text-muted font-size-14 font-weight-bold mx-md-2">
                    {booking ? booking.client.name : <Skeleton width={75} />}
                </span>
            </Col>

            <Col className={classNames(styles.duration, 'd-flex align-items-center')}>
                <span className="text-muted font-size-14 font-weight-bold mx-md-2">
                    {booking ? (
                        <React.Fragment>
                            <ClockIcon
                                width={14}
                                height={14}
                                strokeWidth={2.5}
                                className="position-relative mr-2"
                                style={{ top: -1 }}
                            />
                            {`${moment
                                .duration(
                                    moment(booking.time.to).diff(moment(booking.time.from)),
                                )
                                .asMinutes()} min`}
                        </React.Fragment>
                    ) : (
                        <Skeleton width={50} />
                    )}
                </span>
            </Col>

            <Col className={classNames(styles.price, 'd-flex align-items-center')}>
                <span className="text-muted font-size-14 font-weight-bold mx-md-2">
                    {booking ? (
                        formatPrice.toEuroWithComma({ amount: booking.payment.finalCost })
                    ) : (
                        <Skeleton width={50} />
                    )}
                </span>
            </Col>
        </div>
    </Row>
);

export default PlanningList;
