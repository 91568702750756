import React, { useState } from 'react';

import classNames from 'classnames';

import { Button } from 'components/Common/Button';
import { ArrowSmallLeft, ArrowSmallRight } from 'components/Icon/Icon';
import { FiltersModal, SidebarBody } from '..';
import ShopsDropdown from '../../ShopsDropdown/ShopsDropdown';

import * as styles from './Sidebar.module.scss';

const Sidebar = ({
    title,
    products,
    filters,
    onFilterClick,
    viewType,
    date,
    onSelectDate,
    month,
    year,
    onChangeTimespan,
    showFiltersModal,
    setShowFiltersModal,
    showDropdown,
    isDropdownDataLoaded,
    dropdownOptions,
    onDropdownItemSelect,
    shopId,
    shopName,
}) => {
    const [isHidden, setIsHidden] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    return (
        <React.Fragment>
            <div
                className={classNames(
                    'd-none d-lg-block',
                    styles.sidebarWrapper,
                    {
                        [styles.sidebarIsHidden]: isHidden,
                    },
                )}
            >
                <div className={styles.showSidebarControlWrapper}>
                    <Button
                        onClick={() => setIsHidden(!isHidden)}
                        className={styles.showSidebarControlButton}
                    >
                        {isHidden
                            ? <ArrowSmallRight />
                            : <ArrowSmallLeft />}
                    </Button>
                </div>

                {!isHidden && (
                    <div
                        className={classNames(
                            'position-sticky d-flex flex-column h-100',
                            styles.sidebarBodyWrapper,
                        )}
                    >
                        <div className="flex-grow-1">
                            <SidebarBody
                                title={title}
                                viewType={viewType}
                                date={date}
                                onSelectDate={onSelectDate}
                                month={month}
                                year={year}
                                onChangeTimespan={onChangeTimespan}
                                setShowFilters={setShowFilters}
                                showFilters={showFilters}
                                products={products}
                                filters={filters}
                                onFilterClick={onFilterClick}
                            />
                        </div>
                    </div>
                )}
            </div>

            <FiltersModal
                title={title}
                isShow={showFiltersModal}
                hide={() => setShowFiltersModal(false)}
            >
                {
                    showDropdown ? (
                        <div className={styles.shopsDropdown}>
                            <ShopsDropdown
                                isDataLoaded={isDropdownDataLoaded}
                                options={dropdownOptions}
                                onSelect={onDropdownItemSelect}
                                shopId={shopId}
                                shopName={shopName}
                            />
                        </div>
                    ) : null
                }
                <SidebarBody
                    title={title}
                    viewType={viewType}
                    date={date}
                    onSelectDate={onSelectDate}
                    month={month}
                    year={year}
                    onChangeTimespan={onChangeTimespan}
                    setShowFilters={setShowFilters}
                    showFilters={showFilters}
                    products={products}
                    filters={filters}
                    onFilterClick={onFilterClick}
                />
            </FiltersModal>
        </React.Fragment>
    );
};

export default Sidebar;
