// CLIENTS
export const CLIENTS_ROOT_PATH = '/clients';
export const CLIENTS_LIST_PATH = `${CLIENTS_ROOT_PATH}/list`;
export const CLIENTS_LIST_ID_PATH = `${CLIENTS_LIST_PATH}/:clientId`;
export const CLIENTS_LIST_ID_BOOKINGS_PATH = `${CLIENTS_LIST_ID_PATH}/bookings`;
export const CLIENTS_LIST_ID_BUNDLES_PATH = `${CLIENTS_LIST_ID_PATH}/bundles`;
export const CLIENTS_LIST_ID_SUBSCRIPTIONS_PATH = `${CLIENTS_LIST_ID_PATH}/subscriptions`;
export const CLIENTS_LIST_ID_ACCESS_PATH = `${CLIENTS_LIST_ID_PATH}/access`;
export const CLIENTS_LIST_ID_INVOICES_PATH = `${CLIENTS_LIST_ID_PATH}/invoices`;
export const CLIENTS_LIST_ID_EMAILS_PATH = `${CLIENTS_LIST_ID_PATH}/emails`;
export const CLIENTS_LIST_ID_ACTIVITY_PATH = `${CLIENTS_LIST_ID_PATH}/activity`;
export const CLIENTS_LIST_ID_REVIEWS_PATH = `${CLIENTS_LIST_ID_PATH}/reviews`;
export const CLIENTS_LIST_ID_COMMUNICATION_PATH = `${CLIENTS_LIST_ID_PATH}/communication`;
export const CLIENTS_LIST_ID_NOTES_PATH = `${CLIENTS_LIST_ID_PATH}/notes`;
export const CLIENTS_LIST_ID_INFO_PATH = `${CLIENTS_LIST_ID_PATH}/info`;
export const CLIENTS_ADD_PATH = `${CLIENTS_ROOT_PATH}/add`;
const CLIENTS_EDIT_PATH = `${CLIENTS_ROOT_PATH}/edit`;
export const CLIENTS_EDIT_ID_PATH = `${CLIENTS_EDIT_PATH}/:clientId`;

// SETTINGS
const SETTINGS_ROOT_PAGE = '/settings';

export const SETTINGS_COMPANY_PAGE = `${SETTINGS_ROOT_PAGE}/company`;

export const SETTINGS_COMPANY_GENERAL_PAGE = `${SETTINGS_COMPANY_PAGE}/general`;

export const SETTINGS_COMPANY_LOCATIONS_PAGE = `${SETTINGS_COMPANY_PAGE}/locations`;
export const SETTINGS_COMPANY_LOCATIONS_ADD_PAGE = `${SETTINGS_COMPANY_LOCATIONS_PAGE}/add`;
export const SETTINGS_COMPANY_LOCATIONS_ITEM_PAGE = `${SETTINGS_COMPANY_LOCATIONS_PAGE}/:id`;

export const SETTINGS_COMPANY_BIRTHDAY_AND_LOYALTY_PAGE = `${SETTINGS_COMPANY_PAGE}/birthday-and-loyalty`;

export const SETTINGS_COMPANY_DISPLAY_AND_NOTIFICATIONS_PAGE = `${SETTINGS_COMPANY_PAGE}/display-and-notifications`;

export const SETTINGS_COMPANY_DISCOUNT_AND_LOYALTY_PAGE = `${SETTINGS_COMPANY_PAGE}/discount-and-loyalty`;

export const SETTINGS_INVITE_CLIENTS_PAGE = `${SETTINGS_ROOT_PAGE}/invite-clients`;

// ACCESS
export const ACCESS_ROOT_PAGE = '/access';
export const ACCESS_GATES_PAGE = `${ACCESS_ROOT_PAGE}/gates`;
export const ACCESS_GATE_ADD_PAGE = `${ACCESS_GATES_PAGE}/add`;
export const ACCESS_GATE_EDIT_PAGE = `${ACCESS_GATES_PAGE}/edit/:gateId`;
export const ACCESS_LOGS_PAGE = `${ACCESS_ROOT_PAGE}/logs`;
export const ACCESS_PROFILES_PAGE = `${ACCESS_ROOT_PAGE}/profiles`;
export const ACCESS_PROFILE_ADD_PAGE = `${ACCESS_PROFILES_PAGE}/add`;
export const ACCESS_PROFILE_EDIT_PAGE = `${ACCESS_PROFILES_PAGE}/edit/:profileId`;

// ACTIVITY
export const ACTIVITY_ROOT_PAGE = '/activity';
export const ACTIVITY_LOGS_PAGE = `${ACTIVITY_ROOT_PAGE}/logs`;
