import React from 'react';

import PropTypes from 'prop-types';
// Styles
import classNames from 'classnames';
import { Label } from 'components/Common/Typography/Label';
import { Row } from 'components/Common/Row';
import * as styles from './Switch.module.scss';

const Switch = ({
    checked, onChange, disabled = false, id, label, tooltip, name,
}) => (
    <Row gap={16}>
        <button
            name={name}
            type="button"
            className={classNames(styles.container, { [styles.checked]: checked })}
            onClick={(e) => onChange(!checked, e)}
            disabled={disabled}
            id={id || name}
        >
            <div className={styles.knob} />
        </button>
        {label && <Label htmlFor={id || name} color={disabled ? 'gray' : 'black'} tooltip={tooltip}>{label}</Label>}
    </Row>
);

Switch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    name: PropTypes.string,
};

Switch.defaultProps = {
    disabled: false,
    id: '',
    label: '',
    tooltip: '',
    name: '',
};

export default Switch;
