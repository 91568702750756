import React from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAMESPACE } from 'const/translations/LOCALE_NAMESPACE';
import { TextSubHeader } from 'components/Layout/TextSubHeader';
import { BackButton } from 'components/Common/BackButton';
import { useSubRoute } from 'hooks/useSubRoute';
import { FINANCIALS_INVOICES_LIST_PAGE } from 'const/CLIENT_URL';

const pageTitleOptions = {
    add: 'add.title',
    edit: 'edit.title',
};

function InvoicesAddEditFormHeader(props) {
    const {
        withDynamic,
    } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);
    const [currentSubRoute] = useSubRoute({ withDynamic });

    return (
        <TextSubHeader
            text={t(pageTitleOptions[currentSubRoute])}
            before={(
                <BackButton
                    href={FINANCIALS_INVOICES_LIST_PAGE}
                />
            )}
        />
    );
}

InvoicesAddEditFormHeader.defaultProps = {
    withDynamic: false,
};

export default InvoicesAddEditFormHeader;
