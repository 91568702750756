import React from 'react';

const Check = (props) => {
    const { width = '24', height = '24', className } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L10.3071 18.1071C9.91658 18.4976 9.28342 18.4976 8.89289 18.1071L3.29289 12.5071C2.90237 12.1166 2.90237 11.4834 3.29289 11.0929C3.68342 10.7024 4.31658 10.7024 4.70711 11.0929L9.6 15.9858L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z" fill="currentColor" />
        </svg>

    );
};

export default Check;
