import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { useMemo } from 'react';
import { formatPrice } from 'helpers/formatters/formatPrice';
import TextButton from 'components/Common/TextButton/TextButton';
import { Content } from 'components/Common/Content';
import { ListTableHeaderCell } from 'components/Common/NewTable/ListTableHeaderCell';
import { TableHeaderCell } from 'components/Common/NewTable/TableHeaderCell';

import { TableHeader } from 'components/Common/NewTable/TableHeader';
import { TableBody } from 'components/Common/NewTable/TableBody';
import { ListTableBodyRow } from 'components/Common/NewTable/ListTableBodyRow';
import { ListTableBodyCell } from 'components/Common/NewTable/ListTableBodyCell';
import { Table } from 'components/Common/NewTable/Table';
import * as FINANCILAS_SELECTORS from '../../../../../store/selectors/financilas';
import * as PAYOUTS_ACTIONS from '../../../../../store/actions/financials/payouts';

import { LOCALE_NAMESPACE } from '../../../../../const/translations/LOCALE_NAMESPACE';
import { PAYOUT_TRANSACTION_TYPE } from '../../../../../const/payouts/PAYOUT_TRANSACTION_TYPE';
import { CLIENTS_LIST_ID_PAGE } from '../../../../../const/CLIENT_URL';

const T_PREFIX = 'payoutsDetailsDialog.table';
const T_FIELDS = `${T_PREFIX}.header.fields`;
const COLUMNS_COUNT = {
    [PAYOUT_TRANSACTION_TYPE.PAYMENTS]: 8,
    [PAYOUT_TRANSACTION_TYPE.DEBIT]: 6,
    [PAYOUT_TRANSACTION_TYPE.REFUNDS]: 8,
};
const ITEMS_PER_PAGE = 10;

const TransactionsTable = () => {
    const { t } = useTranslation(LOCALE_NAMESPACE.PAYOUTS);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        payoutTransactionsLoading,
        payoutTransactions,
        payoutTransactionsPage,
        payoutTransactionsShowAll,
        payoutTransactionsItemsCount,
        payoutTransactionsPagesCount,
        payoutTransactionsType,

    } = useSelector(FINANCILAS_SELECTORS.payoutsListSelector);

    const {
        isShowResourceNameColl,
        isShowServiceNameColl,
        isShowSubscriptionNameColl,
        isShowServiceDateColl,
    } = useMemo(() => {
        const isShowResourceNameColl = [PAYOUT_TRANSACTION_TYPE.PAYMENTS, PAYOUT_TRANSACTION_TYPE.REFUNDS].includes(payoutTransactionsType);
        const isShowServiceNameColl = [PAYOUT_TRANSACTION_TYPE.PAYMENTS, PAYOUT_TRANSACTION_TYPE.REFUNDS].includes(payoutTransactionsType);
        const isShowSubscriptionNameColl = [PAYOUT_TRANSACTION_TYPE.DEBIT].includes(payoutTransactionsType);
        const isShowServiceDateColl = [PAYOUT_TRANSACTION_TYPE.PAYMENTS, PAYOUT_TRANSACTION_TYPE.REFUNDS].includes(payoutTransactionsType);

        return {
            isShowResourceNameColl,
            isShowServiceNameColl,
            isShowSubscriptionNameColl,
            isShowServiceDateColl,
        };
    }, [payoutTransactionsType]);

    const handlePrevPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPagePrev());
    }, [dispatch]);

    const handleNextPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPageNext());
    }, [dispatch]);

    const handleLastPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPageLast());
    }, [dispatch]);

    const handleFirstPage = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPageFirst());
    }, [dispatch]);

    const handleShowAll = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPage({ page: 0 }));
    }, [dispatch]);

    const handleShowPages = useCallback(() => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPage({ page: 1 }));
    }, [dispatch]);

    const handleChangePage = useCallback(({ page: nextPage }) => {
        dispatch(PAYOUTS_ACTIONS.setPayoutsTransactionsPage({ page: nextPage }));
    }, [dispatch]);

    const handleClientClick = useCallback(
        ({ clientId }) => {
            history.push(CLIENTS_LIST_ID_PAGE({ clientId }));
        },
        [history.push],
    );

    return (
        <Content loading={payoutTransactionsLoading}>
            <Table
                footer={{
                    page: payoutTransactionsPage,
                    showAll: payoutTransactionsShowAll,
                    itemsCount: payoutTransactionsItemsCount,
                    pagesCount: payoutTransactionsPagesCount,
                    columnsCount: COLUMNS_COUNT[payoutTransactionsType],
                    itemsPerPage: ITEMS_PER_PAGE,
                    onShowAll: handleShowAll,
                    onPrevPage: handlePrevPage,
                    onNextPage: handleNextPage,
                    onLastPage: handleLastPage,
                    onFirstPage: handleFirstPage,
                    onShowPages: handleShowPages,
                    onChangePage: handleChangePage,
                }}
            >
                <TableHeader>
                    <TableHeaderCell>
                        <ListTableHeaderCell
                            name={t(`${T_FIELDS}.client.label`)}
                        />
                    </TableHeaderCell>
                    {isShowResourceNameColl && (
                        <TableHeaderCell>
                            <ListTableHeaderCell
                                name={t(`${T_FIELDS}.resourceName.label`)}
                            />
                        </TableHeaderCell>
                    )}
                    {isShowServiceNameColl && (
                        <TableHeaderCell>
                            <ListTableHeaderCell
                                name={t(`${T_FIELDS}.serviceName.label`)}
                            />
                        </TableHeaderCell>
                    )}
                    {isShowSubscriptionNameColl && (
                        <TableHeaderCell>
                            <ListTableHeaderCell
                                name={t(`${T_FIELDS}.subscriptionName.label`)}
                            />
                        </TableHeaderCell>
                    )}
                    <TableHeaderCell>
                        <ListTableHeaderCell
                            name={t(`${T_FIELDS}.date.label`)}
                        />
                    </TableHeaderCell>
                    {isShowServiceDateColl && (
                        <TableHeaderCell>
                            <ListTableHeaderCell
                                name={t(`${T_FIELDS}.serviceDate.label`)}
                            />
                        </TableHeaderCell>
                    )}
                    <TableHeaderCell>
                        <ListTableHeaderCell
                            name={t(`${T_FIELDS}.transactionId.label`)}
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <ListTableHeaderCell
                            name={t(`${T_FIELDS}.paymentType.label`)}
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <ListTableHeaderCell
                            name={t(`${T_FIELDS}.amount.label`)}
                        />
                    </TableHeaderCell>
                </TableHeader>

                <TableBody>
                    {payoutTransactions.map((transaction) => (
                        <ListTableBodyRow key={`${payoutTransactionsType}_${transaction.id}`}>
                            <ListTableBodyCell>
                                <TextButton
                                    color="gray"
                                    onClick={() => handleClientClick({ clientId: transaction.client.id })}
                                >
                                    {`${transaction.client.firstName} ${transaction.client.lastName}`}
                                </TextButton>
                            </ListTableBodyCell>
                            {isShowResourceNameColl && (
                                <ListTableBodyCell
                                    className="text-muted"
                                >
                                    {transaction.resourceName}
                                </ListTableBodyCell>
                            )}
                            {isShowServiceNameColl && (
                                <ListTableBodyCell
                                    className="text-muted"
                                >
                                    {transaction.serviceName}
                                </ListTableBodyCell>
                            )}
                            {isShowSubscriptionNameColl && (
                                <ListTableBodyCell
                                    className="text-muted"
                                >
                                    {transaction.subscriptionName}
                                </ListTableBodyCell>
                            )}
                            <ListTableBodyCell
                                className="text-muted"
                            >
                                {transaction.date && moment(transaction.date).isValid()
                                    ? moment(transaction.date).format('DD MMM YYYY HH:mm')
                                    : '-'}
                            </ListTableBodyCell>
                            {isShowServiceDateColl && (
                                <ListTableBodyCell
                                    className="text-muted"
                                >
                                    {transaction.serviceDate && moment(transaction.serviceDate).isValid()
                                        ? moment(transaction.serviceDate).format('DD MMM YYYY HH:mm')
                                        : '-'}
                                </ListTableBodyCell>
                            )}
                            <ListTableBodyCell
                                className="text-muted"
                            >
                                {transaction.id}
                            </ListTableBodyCell>
                            <ListTableBodyCell
                                className="text-muted"
                            >
                                {transaction.paymentType}
                            </ListTableBodyCell>
                            <ListTableBodyCell
                                className="text-muted"
                                align="right"
                            >
                                {formatPrice.toEuroWithComma({ amount: transaction.amount }) }
                            </ListTableBodyCell>
                        </ListTableBodyRow>
                    ))}
                </TableBody>
            </Table>
        </Content>
    );
};

export default TransactionsTable;
