import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import {
    Buildings,
    Cake,
    EmailIcon,
    LocationIcon,
    PhoneIcon,
} from 'components/Icon/Icon';

import { Image } from 'components/Common/Image/Image';
import { Tooltip } from '../../../../Common/Tooltip';

import { LOCALE_NAMESPACE } from '../../../../../const/translations/LOCALE_NAMESPACE';

import * as styles from './ProfileBlock.module.scss';

const T_PREFIX = 'profile.details';

const ProfileBlock = (props) => {
    const { client } = props;
    const { t } = useTranslation(LOCALE_NAMESPACE.CLIENTS);

    const {
        contactInfo,
        dateOfBirth,
        companyName,
    } = client || {};
    const { phone, email, address } = contactInfo || {};

    const phoneLabel = phone || t(`${T_PREFIX}.fields.phone.notAvailable`);

    const emailLabel = email || t(`${T_PREFIX}.fields.email.notAvailable`);

    const dateOfBirthLabel = dateOfBirth
        ? moment.unix(dateOfBirth).format('DD.MM.YYYY')
        : t(`${T_PREFIX}.fields.birthday.notAvailable`);

    const companyNameLabel = companyName || t(`${T_PREFIX}.fields.companyName.notAvailable`);

    return (
        <Row className="m-0">
            <Col className="p-0">
                <Row className={
                    classNames(
                        'justify-content-center',
                        styles.avatarContainer,
                    )
                }
                >
                    <Image
                        className={styles.avatar}
                        src={client.avatarUrl}
                        alt="profile photo"
                    />
                </Row>
            </Col>
            <Col className={
                classNames(
                    'p-0',
                    styles.profileInfoLines,
                )
            }
            >
                <Row className={styles.profileLine}>
                    <PhoneIcon
                        width="18"
                        height="18"
                        className={
                            classNames(
                                'mt-1 mr-3',
                                styles.icon,
                            )
                        }
                    />
                    {phoneLabel}
                </Row>
                <Row className={styles.profileLine}>
                    <EmailIcon className={
                        classNames(
                            'mt-1 mr-3',
                            styles.icon,
                        )
                    }
                    />
                    <Tooltip
                        tooltip={emailLabel}
                        placement="top-start"
                    >
                        <span className={styles.wideContent}>
                            {emailLabel}
                        </span>
                    </Tooltip>
                </Row>
                <Row className={styles.profileLine}>
                    <Cake className={
                        classNames(
                            'mt-1 mr-3',
                            styles.icon,
                        )
                    }
                    />
                    {dateOfBirthLabel}
                </Row>
                <Row className={styles.profileLine}>
                    <Buildings className={
                        classNames(
                            'mt-1 mr-3',
                            styles.icon,
                        )
                    }
                    />
                    <Tooltip
                        tooltip={companyNameLabel}
                        placement="top-start"
                    >
                        <span className={styles.wideContent}>
                            {companyNameLabel}
                        </span>
                    </Tooltip>
                </Row>
                <Row className={styles.profileLine}>
                    <LocationIcon className={
                        classNames(
                            'mt-1 mr-3',
                            styles.icon,
                        )
                    }
                    />
                    <Tooltip
                        tooltip={`${address?.street}\n${address?.zip}, ${address?.city}`}
                        placement="top-start"
                    >
                        {(address?.street || address?.city)
                            ? (
                                <div className={styles.address}>
                                    <p>{address?.street}</p>
                                    <p>{`${address?.zip} ${address?.city}`}</p>
                                </div>
                            )
                            : <span>{t(`${T_PREFIX}.fields.address.notAvailable`)}</span>}
                    </Tooltip>
                </Row>
            </Col>
        </Row>
    );
};

ProfileBlock.propTypes = {
    client: PropTypes.object,
};

ProfileBlock.defaultProps = {
    client: null,
};

export default ProfileBlock;
