import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'components/Common/Row';

import { ProfileBlock } from './ProfileBlock';
import { ProfileWalletInfo } from './ProfileWalletInfo';
import { ProfilePermissions } from './ProfilePermissions';

import * as styles from './ClientDataBlock.module.scss';

const ClientDataBlock = (props) => {
    const { client } = props;

    return (
        <div className={styles.clientDataContainer}>
            <Row stretched justify="between" align="start" wrap>
                <ProfileBlock client={client} />
                <ProfileWalletInfo client={client} />
                <ProfilePermissions client={client} />
            </Row>
        </div>
    );
};

ClientDataBlock.propTypes = {
    client: PropTypes.object,
};

ClientDataBlock.defaultProps = {
    client: null,
};

export default ClientDataBlock;
