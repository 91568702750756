import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowSmallLeft, ArrowSmallRight, CalendarIcon } from 'components/Icon/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionPeriodsUsages } from 'store/actions';
import moment from 'moment';
import { CheckBox } from 'components/Common/CheckBox';
import NumberInput from 'components/Common/NumberInput';
import * as styles from './SubscriptionsPerPeriods.module.scss';

const servicePeriodTableHeaders = {
    serviceName: 'subscriptions.editSoldSubscriptionUsages.serviceName',
    used: 'subscriptions.editSoldSubscriptionUsages.used',
    maxUsages: 'subscriptions.editSoldSubscriptionUsages.maxUsages',
};

const monthlyPeriodicity = 'monthly';

function SubscriptionsPerPeriods({
    usages, nextDate, prevDate, formik,
}) {
    const dispatch = useDispatch();
    const { locale } = useSelector((state) => state.locales);
    const { t } = useTranslation();
    const {
        startDate,
        endDate,
        periodicity,
    } = usages[0];
    const { values } = formik;
    const servicePeriodType = `${t(`subscriptions.editSoldSubscriptionUsages.${periodicity}`)}`;
    const datesPeriod = `${moment.unix(startDate).locale(locale).format('DD MMM YYYY')} - ${moment.unix(endDate).locale(locale).format('DD MMM YYYY')}`;
    const formGroupClass = periodicity !== monthlyPeriodicity ? styles.weeklyService : styles.monthlyService;

    if (!values) {
        return null;
    }

    const getUsagesByDate = (newDate) => {
        dispatch(getSubscriptionPeriodsUsages(newDate));
    };

    return (
        <Form.Group className={`py-3 ${formGroupClass}`}>
            <div className={`${styles.formGroupHeading}`}>
                <span>
                    {servicePeriodType}
                </span>
                <div className={styles.datesPeriod}>
                    <button
                        type="button"
                        data-testid="backArrow"
                        className={`${styles.datePikcerArrow} mr-2`}
                        disabled={!prevDate}
                        onClick={() => getUsagesByDate(prevDate)}
                    >
                        <ArrowSmallLeft />
                    </button>
                    <span className="d-flex mr-2"><CalendarIcon width={18} /></span>
                    <span>{datesPeriod}</span>
                    <button
                        type="button"
                        data-testid="nextArrow"
                        className={`${styles.datePikcerArrow} ml-2`}
                        disabled={!nextDate}
                        onClick={() => getUsagesByDate(nextDate)}
                    >
                        <ArrowSmallRight />
                    </button>
                </div>
            </div>
            <Table
                borderless
                className={styles.table}
            >
                <thead>
                    <tr>
                        {
                            Object.entries(servicePeriodTableHeaders).map(([key, value]) => (
                                <th key={key}>
                                    {t(value)}
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        usages.map((usage) => {
                            const {
                                id, used, maxUsages, subscriptionGroup: { services },
                            } = usage;
                            const unlimitedUsages = maxUsages < 0;

                            return (
                                <tr key={id}>
                                    <td data-label={t(servicePeriodTableHeaders.serviceName)} className={styles.serviceName}>
                                        <span className={styles.tdText}>
                                            {services}
                                        </span>
                                    </td>
                                    <td data-label={t(servicePeriodTableHeaders.used)} className={styles.used}>
                                        <span className={styles.tdText}>
                                            {used}
                                        </span>
                                    </td>
                                    <td data-label={t(servicePeriodTableHeaders.maxUsages)} className={styles.maxUsages}>
                                        <div className={styles.maxUsagesDiv}>
                                            <Form.Group className="mb-0">
                                                <NumberInput
                                                    disabled
                                                    value={unlimitedUsages ? '' : maxUsages}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-0">
                                                <div className="d-flex align-items-center ml-lg-4">
                                                    <CheckBox
                                                        checked={unlimitedUsages}
                                                        name={`${services}.unlimitedUsages`}
                                                        disabled
                                                        label={t('subscriptions.serviceFormGroup.unlimitedUsages')}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
        </Form.Group>
    );
}

export default SubscriptionsPerPeriods;
