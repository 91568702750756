import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Badge } from 'components/Common/Badge';
import { Button } from 'components/Common/Button';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { Dialog } from '../../../Common/Dialogs/Dialog/Dialog';
import { DialogHeader } from '../../../Common/Dialogs/Dialog/DialogHeader';
import { DialogTitle } from '../../../Common/Dialogs/Dialog/DialogTitle';
import { DialogBody } from '../../../Common/Dialogs/Dialog/DialogBody';
import { DialogFooter } from '../../../Common/Dialogs/Dialog/DialogFooter';
import { PeriodPickerPanel } from '../../../Common/PeriodPicker';

import * as styles from './InvoicesMobileFiltersDialog.module.scss';

import { setExportPeriod } from '../../../../store/actions/financials/invoices';
import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';
import { INVOICE_STATUS } from '../../../../const/financials/invoices/INVOICE_STATUS';

const T_PREFIX = 'mobileFiltersDialog';

const InvoicesMobileFiltersDialog = (props) => {
    const { t } = useTranslation(LOCALE_NAMESPACE.INVOICES);

    const dispatch = useDispatch();

    const {
        isVisible,
        onClose,
        filters,
        onSetInvoicesFilters,
    } = props;

    const [status, setStatus] = useState(filters.status);
    const [from, setFrom] = useState(filters.from);
    const [to, setTo] = useState(filters.to);
    const [period, setPeriod] = useState('month');

    const onChangeStatusHandler = useCallback((value) => {
        setStatus((prev) => (prev === value ? null : value));
    }, []);

    const onChangeDateHandler = useCallback(({ dateFrom, dateTo, period }) => {
        setFrom(dateFrom);
        setTo(dateTo);
        setPeriod(period);
        dispatch(setExportPeriod({ period }));
    }, []);

    const onApplyHandle = useCallback(() => {
        onSetInvoicesFilters({
            filters: {
                status,
                from: from.format('X'),
                to: to.format('X'),
            },
        });
        onClose();
    }, [status, from, to, onClose]);

    return (
        <Dialog
            visible={isVisible}
            onClose={onClose}
            size="md"
            className={styles.dialog}
            dialogClassName={styles.dialog}
        >
            <DialogHeader>
                <DialogTitle>
                    {t(`${T_PREFIX}.title`)}
                </DialogTitle>
            </DialogHeader>

            <DialogBody>
                <div className={styles.bodyContainer}>
                    <PeriodPickerPanel
                        initDateFrom={moment.unix(filters.from)}
                        initDateTo={moment.unix(filters.to)}
                        initPeriod={period}
                        onChange={onChangeDateHandler}
                        withoutConfirm
                    />

                    <div className={styles.statusFilterButtonsContainer}>
                        <div className={styles.statusFilterButtonsTitle}>
                            {t(`${T_PREFIX}.filterButtons.title.label`)}
                        </div>

                        <div className={styles.statusFilterButtonsWrapper}>
                            <Badge
                                onClick={() => onChangeStatusHandler(INVOICE_STATUS.PAID)}
                                size="small"
                                color={status === INVOICE_STATUS.PAID ? 'yellow' : 'gray'}
                            >
                                {t(`${T_PREFIX}.filterButtons.paid.label`)}
                            </Badge>

                            <Badge
                                onClick={() => onChangeStatusHandler(INVOICE_STATUS.UNPAID)}
                                size="small"
                                color={status === INVOICE_STATUS.UNPAID ? 'yellow' : 'gray'}
                            >
                                {t(`${T_PREFIX}.filterButtons.unpaid.label`)}
                            </Badge>

                            <Badge
                                onClick={() => onChangeStatusHandler(INVOICE_STATUS.DRAFT)}
                                size="small"
                                color={status === INVOICE_STATUS.DRAFT ? 'yellow' : 'gray'}
                            >
                                {t(`${T_PREFIX}.filterButtons.draft.label`)}
                            </Badge>
                        </div>
                    </div>
                </div>
            </DialogBody>

            <DialogFooter className={styles.dialogFooter}>
                <Button
                    onClick={onApplyHandle}
                    color="yellow"
                >
                    {t(`${T_PREFIX}.actions.apply`)}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

InvoicesMobileFiltersDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    filters: PropTypes.object,
    onSetInvoicesFilters: PropTypes.func,
};

InvoicesMobileFiltersDialog.defaultProps = {
    isVisible: false,
    onClose: emptyFunc,
    filters: {},
    onSetInvoicesFilters: emptyFunc,
};

export default InvoicesMobileFiltersDialog;
