import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';

import Switch from '../../../Switch/Switch';

import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'addOrEdit.form.fields.status';

const BundleStatusFormRow = (props) => {
    const {
        values,
        validation,
        onToggleStatus,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    return (
        <Row className="w-100">
            <Col
                xs={12}
                className="m-0 p-0"
            >
                <Form.Group as={Col}>
                    <InputGroup className="d-flex flex-wrap-reverse align-items-center">
                        <Switch
                            checked={values.status}
                            onChange={onToggleStatus}
                            label={t(`${T_PREFIX}.label`)}
                        />
                    </InputGroup>
                    <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                            'd-block': validation?.status?.isInvalid,
                        })}
                    >
                        {validation?.status?.error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

BundleStatusFormRow.propTypes = {
    values: PropTypes.object.isRequired,
    validation: PropTypes.object,
    onToggleStatus: PropTypes.func,
};

BundleStatusFormRow.defaultProps = {
    validation: null,
    onToggleStatus: emptyFunc,
};

export default BundleStatusFormRow;
