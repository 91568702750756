import React from 'react';

const LinkIcon = (props) => {
    const { width = '20', height = '20' } = props;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7765 3.6431C11.516 2.91032 12.5174 2.5 13.5599 2.5C14.6025 2.5 15.6038 2.91032 16.3433 3.6431C17.083 4.37614 17.5 5.37196 17.5 6.41193C17.5 7.4519 17.083 8.44773 16.3433 9.18076L14.146 11.3574C13.4066 12.0899 12.4054 12.5 11.3631 12.5C10.3208 12.5 9.31968 12.0899 8.58022 11.3574C8.25324 11.0335 8.25073 10.5059 8.57461 10.1789C8.8985 9.85195 9.42613 9.84944 9.75311 10.1733C10.1789 10.5951 10.7579 10.8333 11.3631 10.8333C11.9684 10.8333 12.5473 10.5951 12.9731 10.1734L15.1702 7.9969C15.5957 7.57523 15.8333 7.00495 15.8333 6.41193C15.8333 5.81891 15.5957 5.24863 15.1702 4.82697C14.7444 4.40505 14.1653 4.16667 13.5599 4.16667C12.9545 4.16667 12.3755 4.40505 11.9497 4.82697L10.8515 5.91524C10.5245 6.23919 9.99691 6.23679 9.67296 5.90987C9.34901 5.58296 9.35141 5.05533 9.67832 4.73138L10.7765 3.6431ZM5.82649 7.92794C6.55873 7.1301 7.56785 6.66667 8.63689 6.66667C9.70592 6.66667 10.715 7.1301 11.4473 7.92794C11.7585 8.26702 11.7359 8.79417 11.3968 9.10537C11.0577 9.41657 10.5306 9.39397 10.2194 9.0549C9.78639 8.58312 9.2154 8.33333 8.63689 8.33333C8.05839 8.33333 7.4874 8.58311 7.05442 9.05487M7.05442 9.05487L4.85735 11.449C4.4222 11.9233 4.16667 12.5811 4.16667 13.2802C4.16667 13.9794 4.42215 14.6371 4.85735 15.1114C5.29034 15.5834 5.86145 15.8333 6.44008 15.8333C7.01871 15.8333 7.58982 15.5834 8.02282 15.1114L9.12104 13.9143C9.43217 13.5752 9.95932 13.5525 10.2985 13.8636C10.6376 14.1748 10.6603 14.7019 10.3492 15.041L9.25096 16.2381C8.51869 17.0363 7.50937 17.5 6.44008 17.5C5.37079 17.5 4.36147 17.0363 3.62921 16.2381C2.89916 15.4424 2.5 14.3773 2.5 13.2802C2.5 12.1831 2.89916 11.1181 3.62921 10.3223L5.82649 7.92794" fill="currentColor" />
        </svg>

    );
};

export default LinkIcon;
