import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/Common/InfoTooltip';
import { emptyFunc } from '../../../../helpers/function/emptyFunc';

import { LOCALE_NAMESPACE } from '../../../../const/translations/LOCALE_NAMESPACE';

const T_PREFIX = 'sell.form';
const T_FIELDS = `${T_PREFIX}.fields`;

const BundleSelectPaymentRow = (props) => {
    const {
        values,
        selectedBundle,
        onSelectPayment,
    } = props;

    const { t } = useTranslation(LOCALE_NAMESPACE.BUNDLES);

    const isAnySelected = Boolean(values.paymentType);
    const zeroBundlePrice = selectedBundle?.price === 0;
    const isAutomaticPaymentAvailable = Boolean(!zeroBundlePrice);

    const handleSelectPayment = useCallback((e) => {
        onSelectPayment(e.target.value);
    }, []);

    return (
        <React.Fragment>
            <div style={{
                fontSize: '22px',
                fontWeight: 700,
            }}
            >
                {t(`${T_FIELDS}.paymentType.header`)}
            </div>
            <Form.Group>
                {isAutomaticPaymentAvailable && (
                    <React.Fragment>
                        {Boolean(values.client?.email) && (
                            <Form.Check
                                className="mt-3"
                                type="radio"
                                name="bookableType"
                                value="ideal"
                                id="ideal"
                                label={(
                                    <Form.Label controlId="ideal" className="d-flex align-items-center m-0 cursor-pointer">
                                        {t(`${T_FIELDS}.paymentType.ideal.label`)}
                                        <InfoTooltip
                                            text={t(`${T_FIELDS}.paymentType.ideal.tooltip`)}
                                            placement="top"
                                        />
                                    </Form.Label>
                                )}
                                checked={values.paymentType === 'ideal' || !isAnySelected}
                                onChange={handleSelectPayment}
                            />
                        )}
                        <Form.Check
                            className="mt-3"
                            type="radio"
                            name="bookableType"
                            value="cash"
                            id="cash"
                            label={(
                                <Form.Label controlId="cash" className="d-flex align-items-center m-0 cursor-pointer">
                                    {t(`${T_FIELDS}.paymentType.cash.label`)}
                                    <InfoTooltip
                                        text={t(`${T_FIELDS}.paymentType.cash.tooltip`)}
                                        placement="top"
                                    />
                                </Form.Label>
                            )}
                            checked={values.paymentType === 'cash'}
                            onChange={handleSelectPayment}
                        />
                    </React.Fragment>
                )}
                {!isAutomaticPaymentAvailable && (
                    <Form.Check
                        className="mt-3"
                        type="radio"
                        name="bookableType"
                        value="cash"
                        id="cash"
                        label={(
                            <Form.Label controlId="cash" className="d-flex align-items-center m-0 cursor-pointer">
                                {t(`${T_FIELDS}.paymentType.manual.label`)}
                                <InfoTooltip
                                    text={t(`${T_FIELDS}.paymentType.manual.tooltip`)}
                                    placement="top"
                                />
                            </Form.Label>
                        )}
                        checked
                        onChange={handleSelectPayment}
                    />
                )}
            </Form.Group>
        </React.Fragment>
    );
};

BundleSelectPaymentRow.propTypes = {
    values: PropTypes.object.isRequired,
    selectedBundle: PropTypes.object,
    onSelectPayment: PropTypes.func,
};

BundleSelectPaymentRow.defaultProps = {
    onSelectPayment: emptyFunc,
    selectedBundle: null,
};

export default BundleSelectPaymentRow;
